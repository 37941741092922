import './AboutView.css';
import { useEffect, useRef, useState } from 'react';
import tablet3 from '../../assets/tablet3.png';
import BenefitsBlock from '../../components/BenefitsBlock/BenefitsBlock';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMessageObj } from '../../store/userSlice';
import { fetchRequest, validEmail } from '../../helpers/Utils';
import { useLocation } from 'react-router-dom';
import InputPhone from '../../components/InputPhone/InputPhone';
import SeoBlock from '../../components/SeoBlock/SeoBlock';
import { setIsViewPreloader } from '../../store/homeSlice';

function AboutView() {
    const seo = useSelector(state => state.homeSlice.seo);
    const utm_source = useSelector(state => state.homeSlice.utm_source);
    const utm_medium = useSelector(state => state.homeSlice.utm_medium);
    const utm_campaign = useSelector(state => state.homeSlice.utm_campaign);
    const scrollPositionBeforeView = useSelector(state => state.homeSlice.scrollPositionBeforeView);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [description, setDescription] = useState('');
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorPhone, setIsErrorPhone] = useState(false);
    const [isErrorEmail, setIsErrorEmail] = useState(false);
    const [isErrorDescription, setIsErrorDescription] = useState(false);
    const [isSendForm, setIsSendForm] = useState(false);
    const aboutViewForm = useRef(null);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            dispatch(setIsViewPreloader(false))
        }, 200)
        setTimeout(() => {
            window.scroll({top: scrollPositionBeforeView[location.pathname] ? scrollPositionBeforeView[location.pathname] : 0})
        }, 10)
    }, [])

    useEffect(() => {
        if (name?.length) {
            setIsErrorName(false)
        }
    }, [name])
    
    useEffect(() => {
        if (phone?.length && phone.length >= 12) {
            setIsErrorPhone(false)
        }
    }, [phone])
    
    useEffect(() => {
        if (email?.length) {
            setIsErrorEmail(false)
        }
    }, [email])
    
    useEffect(() => {
        if (description?.length) {
            setIsErrorDescription(false)
        }
    }, [description])

    const hendleClean = () => {
        setName('')
        setEmail('')
        setPhone('')
        setDescription('')
    }

    const hendleSubmitFormGtm = () => {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({'event': 'submit_form326346052'});
    }

    const hendleSend = (event) => {
        event.preventDefault();
        let sendBtn = document.getElementById('aboutViewSendBtnId');
        sendBtn.style.cssText = `
            pointer-events: none;
        `;
        if (name?.length && email?.length && phone?.length && phone.length >= 12 && description?.length && validEmail(email)) {
            let data = {
                full_name: name,
                email: email,
                phone: phone,
                message: description,
                nameofpage: location,
                type: 'about',
                utm_source: utm_source?.length ? utm_source : null,
                utm_medium: utm_medium?.length ? utm_medium : null,
                utm_campaign: utm_campaign?.length ? utm_campaign : null,
            }
            fetchRequest('POST', `${process.env.REACT_APP_BASE_URL}/forms/contact`, data, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
                .then(res => {
                    if (res.success && res.data) {
                        hendleSubmitFormGtm()
                        setIsSendForm(true)
                        hendleClean()
                    } else {
                        console.log('POST AboutView:', res)
                    }
                })
            
        } else {
            sendBtn.style.cssText = ``
            setIsErrorName(!name?.length ? true : false)
            setIsErrorPhone(!phone?.length || phone.length < 12 ? true : false)
            setIsErrorEmail(!email.length || !validEmail(email) ? true : false)
            setIsErrorDescription(!description?.length ? true : false)
        }
    }
   
    return (
        <div className="about-view">
            <SeoBlock title={seo.title_about} description={seo.description_about} keywords={seo.keywords_about}/>
            <div className="about-view--wrap">
                <div className="about-view__main-wrap">
                    <div className="about-view__main container">
                        <div className="about-view__main-text-wrap">
                            <h1 className="about-view__main-title">Платформа меблів VYROBY</h1>
                            <img className="about-view__main-img-1" src={tablet3} alt='img'/>
                            <h2 className="about-view__main-text">VYROBY - це спеціалізований маркетплейс меблів для дому, де покупці можуть купити сучасні меблі для дому безпосередньо у відібраних локальних виробників України. На платформі ви також можете замовити індивідуальне виготовлення меблів під свій дизайн проект.</h2>
                            <h2 className="about-view__main-text">Наша мета створити сервіс, який допоможе вибагливим клієнтам знайти та купити в одному місці сучасні, якісні меблі для дому за розумну ціну безпосередньо у виробника. На нашій онлайн платформі ми хочемо зробити акцент на якість, стиль меблів і високий рівень сервісу.</h2>
                            <button className='main-btn-1 about-view__main-text-btn' onClick={() => aboutViewForm.current.scrollIntoView({ behavior: 'smooth' })}>Продавати на платформі</button>
                        </div>
                        <img className="about-view__main-img-2" src={tablet3} alt='img'/>
                    </div>
                </div>

                <div className="about-view__buyers-wrap">
                    <div className="about-view__buyers container">
                        
                        <BenefitsBlock isAboutView={true}/>

                        <h3 className="about-view__buyers-title">Платформа меблів VYROBY для покупців - це:</h3>
                   
                        <div className="about-view__buyers-cards">
                            <div className="about-view__buyers-card">
                                <div className="about-view__buyers-card-title">Маркетплейс меблів</div>
                                <div className="about-view__buyers-card-text">На сайті представлені всі категорії меблів для дому, які продають безпосередньо виробники. Клієнт може закрити потреби в меблях в одному місці, не втрачаючи часу на пошуки!</div>
                            </div>
                            <div className="about-view__buyers-card">
                                <div className="about-view__buyers-card-title">Сучасні меблі для дому</div>
                                <div className="about-view__buyers-card-text">В одному місці ми хочемо зібрати широкий асортимент сучасних, якісних меблів для Вашого дому, з високим сервісом від продавців, виробників.</div>
                            </div>
                            <div className="about-view__buyers-card">
                                <div className="about-view__buyers-card-title">Кастомізуй меблі під себе</div>
                                <div className="about-view__buyers-card-text">В більшості виробів ви можете змінити розмір, колір, матеріал чи інші характеристики товару за вашими побажаннями.</div>
                            </div>
                            <div className="about-view__buyers-card">
                                <div className="about-view__buyers-card-title">Українські виробники</div>
                                <div className="about-view__buyers-card-text">У нас представлені українські локальні та крафтові виробники, з якими Ви можете працювати безпосередньо.</div>
                            </div>
                            <div className="about-view__buyers-card">
                                <div className="about-view__buyers-card-title">Перевірені виробники</div>
                                <div className="about-view__buyers-card-text">Ми власноруч знайшли і відібрали надійних виробників, з якими Ви можете працювати безпосередньо.</div>
                            </div>
                            <div className="about-view__buyers-card">
                                <div className="about-view__buyers-card-title">Індивідуальне виробництво</div>
                                <div className="about-view__buyers-card-text">На нашому сайті Ви можете замовити меблі індивідуального виробництва за Вашим проєктом, фото чи ескізом.</div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="about-view__sellers-wrap">
                    <div className="about-view__sellers container">
                        <h3 className="about-view__sellers-title">Платформа меблів VYROBY для виробників - це:</h3>
                   
                        <div className="about-view__sellers-cards">
                            <div className="about-view__sellers-card">
                                <div className="about-view__sellers-card-title">Цільовий трафік клієнтів</div>
                                <div className="about-view__sellers-card-text">На платформі будуть представлені лише товари середнього сегменту для покупців, які націлені на якісний продукт.</div>
                            </div>
                            <div className="about-view__sellers-card">
                                <div className="about-view__sellers-card-title">Низький поріг входу в онлайн продажі</div>
                                <div className="about-view__sellers-card-text">Затрати на просування на маркетплейсі набагато менші в порівнянні із самостійно організованою рекламною кампанією.</div>
                            </div>
                            <div className="about-view__sellers-card">
                                <div className="about-view__sellers-card-title">Продавати напряму</div>
                                <div className="about-view__sellers-card-text">Можливість напряму продавати кінцевим споживачам, що збільшує маржинальність продажів.</div>
                            </div>
                            <div className="about-view__sellers-card">
                                <div className="about-view__sellers-card-title">Фокус на основній діяльності</div>
                                <div className="about-view__sellers-card-text">Фокус на основній діяльності — на виробництві меблів, маркетплейс бере на себе функцію просування в онлайні, що економить час та ресурси.</div>
                            </div>
                            <div className="about-view__sellers-card">
                                <div className="about-view__sellers-card-title">Додатковий канал продаж</div>
                                <div className="about-view__sellers-card-text">За допомогою маркетплейсу ви можете швидко запустити продажі вашого товару за декілька днів та просто управляти продажами на платформі.</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about-view__form-wrap" ref={aboutViewForm}>
                    <div className="about-view__form container">
                        <h3 className="about-view__form-title">Зворотний зв'язок</h3>
                        <div className="about-view__form--wrap">
                            <div className="about-view__form-info">
                                <div className="about-view__form-info-text">Якщо у вас є запитання або побажання щодо співпраці, опишіть їх у формі зворотного зв'язку</div>
                            </div>
                            <div className="about-view__form-input-wrap">
                                {
                                    isSendForm ? 
                                        <div className="contacts-view__form-success">Дякуємо за ваше звернення. Найближчим часом ми з вами зв'яжемося</div>
                                        :
                                        <>
                                            <div className='contacts-view__form-error-wrap'>
                                                <input className={`about-view__form-input ${isErrorName ? 'contacts-view__form-error' : ''}`} onChange={(e) => setName(e.target.value)} value={name} autoComplete="name" type='text' placeholder="Вкажіть ваше ім'я"/>
                                                {
                                                    isErrorName && <div className='contacts-view__form-error-text'>Обов'язкове поле</div>
                                                }
                                            </div>
                                            <div className='contacts-view__form-error-wrap'>
                                                <input className={`about-view__form-input ${isErrorName ? 'contacts-view__form-error' : ''}`} onChange={(e) => setEmail(e.target.value)} value={email} autoComplete="name" type='text' placeholder='Вкажіть ваш email'/>
                                                {
                                                    isErrorEmail && <div className='contacts-view__form-error-text'>{!email.length ? 'Обов\'язкове поле' : 'Вкажіть, будь ласка, коректний email'} </div>
                                                }
                                            </div>
                                            <div className='contacts-view__form-error-wrap'>
                                                <InputPhone phone={phone} setPhone={setPhone} isErrorPhone={isErrorPhone}/>
                                                {
                                                    isErrorPhone && <div className='contacts-view__form-error-text'>Обов'язкове поле</div>
                                                }
                                            </div>
                                            <div className='contacts-view__form-error-wrap'>
                                                <textarea className={`about-view__form-textarea ${isErrorName ? 'contacts-view__form-error' : ''}`} onChange={(e) => setDescription(e.target.value)} value={description} placeholder="Опишіть ваше питання або побажання" rows="4"></textarea>
                                                {
                                                    isErrorDescription && <div className='contacts-view__form-error-text'>Обов'язкове поле</div>
                                                }
                                            </div>
                                            {
                                                (isErrorName || isErrorDescription || isErrorEmail || isErrorPhone) &&
                                                    <div className='contacts-view__form-error-banner'>
                                                        {
                                                            (isErrorName || isErrorDescription || isErrorPhone) && <div className='contacts-view__form-error-banner-text'>Будь ласка, заповніть всі обов'язкові поля</div>
                                                        }
                                                        {
                                                            isErrorEmail && ((!isErrorName && !isErrorDescription && !isErrorPhone) || !!email.length) && <div className='contacts-view__form-error-banner-text'>{!email.length ? 'Будь ласка, заповніть всі обов\'язкові поля' : 'Вкажіть, будь ласка, коректний email'}</div>
                                                        }
                                                    </div>
                                            }
                                            <button id='aboutViewSendBtnId' className='main-btn-1 about-view__main-text-btn--1' disabled={isSendForm} onClick={hendleSend}>Надіслати звернення</button>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutView;