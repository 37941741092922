import './UserProductsFilter.css';
import { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Menu, MenuItem, Slider } from '@mui/material';
import { fetchRequest, validNumber } from '../../helpers/Utils';
import { getProducts, setProductsCount, setSearchProductTitle } from '../../store/userSlice';
import closeImg from '../../assets/closeImgBlack.svg';
import filterProductsImg from '../../assets/filterProductsImg.svg';
import search3 from '../../assets/search3.svg';
import { handleCategoriesMap } from '../../helpers/Utils';
import { useLocation, useSearchParams } from 'react-router-dom';

function UserProductsFilter({ setIsPreloader=null, page, setPage, handleCloseModal }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const newSearchParams = new URLSearchParams(searchParams);
    const categoryIdSearchParams = JSON.parse(searchParams.get('category_id'))
    const sortBySearchParams = JSON.parse(searchParams.get('sortBy'))
    const sortByHideSearchParams = JSON.parse(searchParams.get('sortByHide'))
    const typeSearchParams = JSON.parse(searchParams.get('type'))
    const selectVendorSearchParams = JSON.parse(searchParams.get('selectVendor'))
    const pageSearchParams = Number(searchParams.get('page'))
    const searchProductTitleSearchParams = searchParams.get('searchProductTitle')
    const valueSortPriceSearchParams = JSON.parse(searchParams.get('valueSortPrice'))

    const categories = useSelector(state => state.userSlice.categories);
    const vendors = useSelector(state => state.userSlice.vendors);
    const productsCount = useSelector(state => state.userSlice.productsCount);
    const [valueSortPrice, setValueSortPrice] = useState((valueSortPriceSearchParams?.length && valueSortPriceSearchParams[1] !== 0) ? valueSortPriceSearchParams : [0, 0]);
    const [selectCategory, setSelectCategory] = useState(categoryIdSearchParams?.name?.length ? categoryIdSearchParams : {});
    const [searchTitle, setSearchTitle] = useState(searchProductTitleSearchParams?.length ? searchProductTitleSearchParams : '');
    const [type, setType] = useState(typeSearchParams?.name?.length ? typeSearchParams : {});
    const [selectVendor, setSelectVendor] = useState(selectVendorSearchParams?.name?.length ? selectVendorSearchParams : {});
    const [characteristics, setCharacteristics] = useState([]);
    const [isNotFirstRender, setIsNotFirstRender] = useState(false);
    const [anchorElCategory, setAnchorElCategory] = useState(null);
    const [anchorElHide, setAnchorElHide] = useState(null);
    const [anchorElType, setAnchorElType] = useState(null);
    const [anchorElVendor, setAnchorElVendor] = useState(null);
    const [anchorElSort, setAnchorElSort] = useState(null);
    const [anchorElPrice, setAnchorElPrice] = useState(null);
    const [sortBy, setSortBy] = useState(sortBySearchParams?.name?.length ? sortBySearchParams : {});
    const [sortByHide, setSortByHide] = useState(sortByHideSearchParams?.name?.length ? sortByHideSearchParams : {} );
    const dispatch = useDispatch();
    const location = useLocation();
    
    useEffect(() => {
        setIsNotFirstRender(true)
        handleGetProducts()
        if (searchProductTitleSearchParams?.length) {
            dispatch(setSearchProductTitle(searchProductTitleSearchParams))
        }

        window.addEventListener('scroll', handleScroll, { passive: true });
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    useEffect(() => {
        if (isNotFirstRender) {
            handleGetProducts()
        }
        if (searchParams.size === 0 && isNotFirstRender) {
            handleDeleteModalFilterBy()
        }
    }, [location])

    useEffect(() => {
        if (!searchTitle?.length && isNotFirstRender) {
            dispatch(setSearchProductTitle(''))
            setPage(1)
            newSearchParams.set('page', 1);
            newSearchParams.set('searchProductTitle', '');
            setSearchParams(newSearchParams);
        }
    }, [searchTitle])

    const handleScroll = () => {
        setAnchorElCategory(null)
        setAnchorElHide(null)
        setAnchorElType(null)
        setAnchorElVendor(null)
        setAnchorElSort(null)
        setAnchorElPrice(null)
    };

    const handleDeleteModalFilterBy = () => {
        setType({})
        dispatch(setSearchProductTitle(''))
        setSearchTitle('')
        setCharacteristics([])
        setSelectVendor({})
        setSelectCategory({})
        setSortBy({})
        setValueSortPrice([0, 0])
        newSearchParams.set('searchProductTitle', '');
        setSearchParams(newSearchParams);
    }

    const handleCloseModalFilterBy = () => {
        handleCloseModal()
    }

    const handleChange = (event, newValue) => {
        setValueSortPrice(newValue);
    };

    const handleKeyDown = (e) => {
        if(e.key == 'Enter') {
            dispatch(setSearchProductTitle(searchTitle))
            setPage(1)
            newSearchParams.set('page', 1);
            newSearchParams.set('searchProductTitle', searchTitle);
            setSearchParams(newSearchParams);
        }
    }

    const handleSearchByName = () => {
        dispatch(setSearchProductTitle(searchTitle))
        setPage(1)
        newSearchParams.set('page', 1);
        newSearchParams.set('searchProductTitle', searchTitle);
        setSearchParams(newSearchParams);
    }

    const handleDeleteSortPrice = (str) => {
        if (str === 'min') {
            setValueSortPrice([0, valueSortPrice[1]]);
        } else if (str === 'max') {
            setValueSortPrice([valueSortPrice[0], valueSortPrice[0]]);
        }
    }

    const handleSortByMinPrice = (num) => {
        if (num.startsWith('0')) {
            validNumber(num.slice(1), (price) => setValueSortPrice([price === '' ? 0 : +price , valueSortPrice[1]]))
        } else {
            validNumber(num, (price) => setValueSortPrice([price === '' ? 0 : +price , valueSortPrice[1]]))
        }
    }
    
    const handleSortByMaxPrice = (num) => {
        if (num.startsWith('0')) {
            validNumber(num.slice(1), (price) => setValueSortPrice([valueSortPrice[0], price === '' ? 0 : +price ]))
        } else {
            validNumber(num, (price) => setValueSortPrice([valueSortPrice[0], price === '' ? 0 : +price ]))
        }
    }

    const handleSelectSortCategory = (obj) => {
        if (obj) {
            setSelectCategory(obj)
            newSearchParams.set('category_id', JSON.stringify(obj));
            setSearchParams(newSearchParams);
        } else {
            setSelectCategory({})
            newSearchParams.set('category_id', JSON.stringify({}));
            setSearchParams(newSearchParams);
        }
        setPage(1)
        newSearchParams.set('page', 1);
        setAnchorElCategory(null)
    }

    const handleSelectSort = (sort) => {
        setSortBy(sort)
        setAnchorElSort(null)
        setPage(1)
        newSearchParams.set('page', 1);
        newSearchParams.set('sortBy', JSON.stringify(sort));
        setSearchParams(newSearchParams);
    }

    const handleSortPrice = () => {
        setAnchorElPrice(null);
        setPage(1)
        newSearchParams.set('page', 1);
        newSearchParams.set('valueSortPrice', JSON.stringify(valueSortPrice));
        setSearchParams(newSearchParams);
        if (valueSortPrice[1] < valueSortPrice[0]) {
            setValueSortPrice([valueSortPrice[0], valueSortPrice[0]])
        }
    }
    
    const handleSelectVendor = (obj) => {
        setSelectVendor(obj)
        setAnchorElVendor(null)
        setPage(1)
        newSearchParams.set('page', 1);
        newSearchParams.set('selectVendor', JSON.stringify(obj));
        setSearchParams(newSearchParams);
    }

    const handleSelectType = (obj) => {
        setType(obj)
        setAnchorElType(null)
        setPage(1)
        newSearchParams.set('page', 1);
        newSearchParams.set('type', JSON.stringify(obj));
        setSearchParams(newSearchParams);
    }

    const handleSelectSortByHide = (sort) => {
        setSortByHide(sort)
        setAnchorElHide(null)
        setPage(1)
        newSearchParams.set('page', 1);
        newSearchParams.set('sortByHide', JSON.stringify(sort));
        setSearchParams(newSearchParams);
    }

    const handleGetProducts = (isNextPage=false) => {
        if (setIsPreloader) {
            setIsPreloader(true)
        }
        
        let data = {
            page: pageSearchParams ? pageSearchParams - 1 : 0,
            category_id: selectCategory?.value?.length ? [selectCategory?.value] : [],
            without_category: sortByHide?.value?.length && sortByHide?.value === 'null' ? true : false,
            type: type?.name?.length ? [type.value] : [],
            sort_price: sortBy?.name?.length && sortBy?.name === 'price' ? sortBy.value : '',
            sort_name: sortBy?.name?.length && sortBy?.name === 'name' ? sortBy.value : '',
            min_price: valueSortPrice[0],
            max_price: valueSortPrice[1],
            is_hidden: sortByHide?.value?.length && sortByHide?.value === 'hide' ? true : false,
            characteristics: characteristics,
            vendor_id: selectVendor?.value?.length ? selectVendor?.value : '',
            title: searchProductTitleSearchParams?.length ? searchProductTitleSearchParams.trim() : '',
            catalog: sortBy?.name?.length && sortBy?.name === 'priority' ? true : false,
            count_product_variations: true,
        }
        fetchRequest('POST', `${process.env.REACT_APP_BASE_URL}/products/admin/all`, data)
            .then(res => {
                if (res.success && res.data) {
                    if (isNextPage) {
                        dispatch(getProducts([...res.data]))
                    } else {
                        dispatch(getProducts(res.data))
                    }
                    if (productsCount !== res.count) {
                        dispatch(setProductsCount(res.count))
                    }
                } else {
                    console.log('POST UserProductsViews:', res)
                }
                if (setIsPreloader) {
                    setIsPreloader(false)
                }
            })
    };

    return (
        <div className="user-products-filter">
            <div className="user-products-filter--wrap">
                <div className="user-products-filter__filter-wrap user-products-filter__filter-wrap--desc">
                    <p className="user-products-filter__filter-lable">Пошук</p>
                    <div className='user-products-filter__search-wrap'>
                        <input className='user-products-filter__search-input' value={searchTitle} onChange={(e) => setSearchTitle(e.target.value)} onKeyDown={(e) => handleKeyDown(e)} placeholder={'Назва товару'} />
                        <img className='user-products-filter__search-img' onClick={handleSearchByName} src={search3} alt='img'/>
                        {
                            !!searchTitle?.length &&
                                <img className='layout-user__header-search-btn-close' onClick={() => setSearchTitle('')} src={closeImg} alt='img'/>
                        }
                    </div>
                </div>
                <div className="user-products-filter__filter-wrap">
                    <p className="user-products-filter__filter-lable">Категорія</p>
                    <Button
                        aria-haspopup="true"
                        onClick={(e) => setAnchorElCategory(e.currentTarget)}
                        className='user-products-filter__filter-btn--wrap'
                        disableRipple={true}
                        >
                        <div className='user-products-filter__filter-btn-wrap'>
                            <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img'/>
                            <p className='user-products-filter__filter-btn-text'>{!!selectCategory?.name?.length ? selectCategory.name : 'Всі товари'}</p>
                        </div>
                    </Button>
                    <Menu
                        anchorEl={anchorElCategory}
                        open={Boolean(anchorElCategory)}
                        onClose={() => setAnchorElCategory(null)}
                        className='user-products-filter__filter-menu--wrap'
                    >
                        <MenuItem className={`user-products-filter__filter-menu-item ${!selectCategory?.value?.length ? 'user-products-filter__filter-menu-active' : ''}`} onClick={() => handleSelectSortCategory(null)} disableRipple={true}>Всі товари</MenuItem>
                        {
                            !!categories?.length && handleCategoriesMap(categories).map((el, i) => (
                                <MenuItem className={`user-products-filter__filter-menu-item ${selectCategory?.value === el._id ? 'user-products-filter__filter-menu-active' : ''}`} onClick={() => handleSelectSortCategory({name: el.name, value: el._id})} key={el._id} disableRipple={true}>{el.name}</MenuItem>
                            ))
                        }
                    </Menu>
                </div>
                <div className="user-products-filter__filter-wrap">
                    <p className="user-products-filter__filter-lable">Сортувати</p>
                    <Button
                        aria-haspopup="true"
                        onClick={(e) => setAnchorElSort(e.currentTarget)}
                        className='user-products-filter__filter-btn--wrap'
                        disableRipple={true}
                        >
                        <div className='user-products-filter__filter-btn-wrap'>
                            <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img'/>
                            <p className='user-products-filter__filter-btn-text'>{sortBy?.name?.length ? sortBy?.text : 'Без сортування'}</p>
                        </div>
                    </Button>
                    <Menu
                        anchorEl={anchorElSort}
                        open={Boolean(anchorElSort)}
                        onClose={() => setAnchorElSort(null)}
                    >
                        <MenuItem className={`user-products-filter__filter-menu-item ${!sortBy?.value?.length ? 'user-products-filter__filter-menu-active' : ''}`} onClick={() => handleSelectSort({})} disableRipple={true}>Без сортування</MenuItem>
                        <MenuItem className={`user-products-filter__filter-menu-item ${sortBy?.name === 'price' && sortBy?.value === '1' ? 'user-products-filter__filter-menu-active' : ''}`} onClick={() => handleSelectSort({name: 'price', value: '1', text: 'По зростанню ціни'})} disableRipple={true}>По зростанню ціни</MenuItem>
                        <MenuItem className={`user-products-filter__filter-menu-item ${sortBy?.name === 'price' && sortBy?.value === '-1' ? 'user-products-filter__filter-menu-active' : ''}`} onClick={() => handleSelectSort({name: 'price', value: '-1', text: 'По спадання ціни'})} disableRipple={true}>По спадання ціни</MenuItem>
                        <MenuItem className={`user-products-filter__filter-menu-item ${sortBy?.name === 'name' && sortBy?.value === '1' ? 'user-products-filter__filter-menu-active' : ''}`} onClick={() => handleSelectSort({name: 'name', value: '1', text: 'По назві А-Я'})} disableRipple={true}>По назві А-Я</MenuItem>
                        <MenuItem className={`user-products-filter__filter-menu-item ${sortBy?.name === 'name' && sortBy?.value === '-1' ? 'user-products-filter__filter-menu-active' : ''}`} onClick={() => handleSelectSort({name: 'name', value: '-1', text: 'По назві Я-А'})} disableRipple={true}>По назві Я-А</MenuItem>
                        <MenuItem className={`user-products-filter__filter-menu-item ${sortBy?.name === 'priority' && sortBy?.value === '1' ? 'user-products-filter__filter-menu-active' : ''}`} onClick={() => handleSelectSort({name: 'priority', value: '1', text: 'По пріорітету'})} disableRipple={true}>По пріорітету</MenuItem>
                    </Menu>
                </div>
                <div className="user-products-filter__filter-wrap">
                    <p className="user-products-filter__filter-lable">Приховані товари</p>
                    <Button
                        aria-haspopup="true"
                        onClick={(e) => setAnchorElHide(e.currentTarget)}
                        className='user-products-filter__filter-btn--wrap'
                        disableRipple={true}
                        >
                        <div className='user-products-filter__filter-btn-wrap'>
                            <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img'/>
                            <p className='user-products-filter__filter-btn-text'>{sortByHide?.name?.length ? sortByHide?.name : 'Всі товари'}</p>
                        </div>
                    </Button>
                    <Menu
                        anchorEl={anchorElHide}
                        open={Boolean(anchorElHide)}
                        onClose={() => setAnchorElHide(null)}
                    >
                        <MenuItem className={`user-products-filter__filter-menu-item ${!sortByHide?.value?.length ? 'user-products-filter__filter-menu-active' : ''}`} onClick={() => handleSelectSortByHide({})} disableRipple={true}>Всі товари</MenuItem>
                        <MenuItem className={`user-products-filter__filter-menu-item ${sortByHide?.value === 'null' ? 'user-products-filter__filter-menu-active' : ''}`} onClick={() => handleSelectSortByHide({value:'null', name: 'Без категорії'})} disableRipple={true}>Без категорії</MenuItem>
                        <MenuItem className={`user-products-filter__filter-menu-item ${sortByHide?.value === 'hide' ? 'user-products-filter__filter-menu-active' : ''}`} onClick={() => handleSelectSortByHide({value:'hide', name: 'Приховані'})} disableRipple={true}>Приховані</MenuItem>
                    </Menu>
                </div>
                <div className="user-products-filter__filter-wrap">
                    <p className="user-products-filter__filter-lable">Тип виробництва</p>
                    <Button
                        aria-haspopup="true"
                        onClick={(e) => setAnchorElType(e.currentTarget)}
                        className='user-products-filter__filter-btn--wrap'
                        disableRipple={true}
                        >
                        <div className='user-products-filter__filter-btn-wrap'>
                            <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img'/>
                            <p className='user-products-filter__filter-btn-text'>{type?.name?.length ? type?.name : 'Всі типи'}</p>
                        </div>
                    </Button>
                    <Menu
                        anchorEl={anchorElType}
                        open={Boolean(anchorElType)}
                        onClose={() => setAnchorElType(null)}
                    >
                        <MenuItem className={`user-products-filter__filter-menu-item ${!type?.value?.length ? 'user-products-filter__filter-menu-active' : ''}`} onClick={() => handleSelectType({})} disableRipple={true}>Всі типи</MenuItem>
                        <MenuItem className={`user-products-filter__filter-menu-item ${type?.value === 'serial' ? 'user-products-filter__filter-menu-active' : ''}`} onClick={() => handleSelectType({name: 'Серійне', value: 'serial'})} disableRipple={true}>Серійне</MenuItem>
                        <MenuItem className={`user-products-filter__filter-menu-item ${type?.value === 'individual' ? 'user-products-filter__filter-menu-active' : ''}`} onClick={() => handleSelectType({name: 'Індивідуальне', value: 'individual'})} disableRipple={true}>Індивідуальне</MenuItem>
                    </Menu>
                </div>
                <div className="user-products-filter__filter-wrap">
                    <p className="user-products-filter__filter-lable">Виробник</p>
                    <Button
                        aria-haspopup="true"
                        onClick={(e) => setAnchorElVendor(e.currentTarget)}
                        className='user-products-filter__filter-btn--wrap'
                        disableRipple={true}
                        >
                        <div className='user-products-filter__filter-btn-wrap'>
                            <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img'/>
                            <p className='user-products-filter__filter-btn-text'>{selectVendor?.name?.length ? selectVendor?.name : 'Всі виробники'}</p>
                        </div>
                    </Button>
                    <Menu
                        anchorEl={anchorElVendor}
                        open={Boolean(anchorElVendor)}
                        onClose={() => setAnchorElVendor(null)}
                    >
                        <MenuItem className={`user-products-filter__filter-menu-item ${!selectVendor?.value?.length ? 'user-products-filter__filter-menu-active' : ''}`} onClick={() => handleSelectVendor({})} disableRipple={true}>Всі виробники</MenuItem>
                        {
                            !!vendors?.length && vendors.map((el, i) => (
                                    <MenuItem className={`user-products-filter__filter-menu-item ${selectVendor?.value === el._id ? 'user-products-filter__filter-menu-active' : ''}`} onClick={() => handleSelectVendor({name: el?.company_name, value: el._id})} key={el._id + i + el.company_name} disableRipple={true}>{el.company_name}</MenuItem>
                                ))
                        }
                    </Menu>
                </div>
                <div className="user-products-filter__filter-wrap">
                    <p className="user-products-filter__filter-lable">Ціна</p>
                    <Button
                        aria-haspopup="true"
                        onClick={(e) => setAnchorElPrice(e.currentTarget)}
                        className='user-products-filter__filter-btn--wrap'
                        disableRipple={true}
                        >
                        <div className='user-products-filter__filter-btn-wrap'>
                            <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img'/>
                            <p className='user-products-filter__filter-btn-text'>{valueSortPrice[1] !== 0 ? `${valueSortPrice[0]} - ${valueSortPrice[1]} грн` : 'Ціна'}</p>
                        </div>
                    </Button>
                    <Menu
                        anchorEl={anchorElPrice}
                        open={Boolean(anchorElPrice)}
                        onClose={() => setAnchorElPrice(null)}
                        className="user-products-filter__products-filter-slider-wrap"
                    >
                        <MenuItem disableRipple={true}>
                            <div className="user-products-filter__select-sort-price-wrap">
                                <Box>
                                    <Slider
                                        value={valueSortPrice}
                                        onChange={handleChange}
                                        valueLabelDisplay="auto"
                                        max={300000}
                                        min={0}
                                        className="user-products-filter__products-filter-slider"
                                    />
                                </Box>
                                <div className="user-products-filter__products-filter-price-result-wrap">
                                    <div className="user-products-filter__products-filter-price-result--wrap">
                                        <div className="user-products-filter__products-filter-price-result-title">Мінімальна</div>
                                        <img className="user-products-filter__products-filter-price-result-btn" onClick={() => handleDeleteSortPrice('min')} src={closeImg} alt='img'/>
                                        <input className="user-products-filter__products-filter-price-result" value={valueSortPrice[0]} onChange={(e) => handleSortByMinPrice(e.target.value)} />
                                    </div>
                                    <div className="user-products-filter__products-filter-price-result-line"></div>
                                    <div className="user-products-filter__products-filter-price-result--wrap">
                                        <div className="user-products-filter__products-filter-price-result-title">Максимальна</div>
                                        <img className="user-products-filter__products-filter-price-result-btn" onClick={() => handleDeleteSortPrice('max')} src={closeImg} alt='img'/>
                                        <input className="user-products-filter__products-filter-price-result" value={valueSortPrice[1]} onChange={(e) => handleSortByMaxPrice(e.target.value)} />
                                    </div>
                                    <div className='user-order__filter-btn-del-wrpa'>
                                        <button className='user-order__filter-btn-del' onClick={() => setValueSortPrice([0, 0])}>Очистити</button>
                                        <button className='user-order__filter-btn' onClick={handleSortPrice}>Показати</button>
                                    </div>
                                </div>    
                            </div>
                        </MenuItem>
                    </Menu>
                </div>
                
            </div>

            <div className="user-products-filter__modal-filter-btn-wrap">   
                <button className='main-btn-2 catalog-view__modal-filter-btn' onClick={handleDeleteModalFilterBy}>Очистити фільтр</button>
                <button className='main-btn-1 catalog-view__modal-filter-btn' onClick={handleCloseModalFilterBy}>Показати</button>
            </div>
        </div>
    );
}

export default memo(UserProductsFilter);