import './BreadCrumbs.css';
import { memo, useEffect, useState } from 'react';
import { NOT_DELETED_SUB_CATEGORY_ID } from '../../helpers/Config';
import { handleCategoriesMap, handleOpenNewPage } from '../../helpers/Utils';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';

function BreadCrumbs({
	collectionName = '',
	product = null,
	selectCategory = {},
	isPreloader = false,
	isTrendChildren = false,
}) {
	const categoriesShop = useSelector((state) => state.homeSlice.categoriesShop);
	const [collectionCategoryName, setCollectionCategoryName] = useState('');
	const [categoryName, setCategoryName] = useState({});
	const [subCategoryName, setSubCategoryName] = useState({});
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (selectCategory?._id?.length && categoriesShop?.length) {
			if (
				selectCategory?.parent_id === null ||
				selectCategory.parent_id === 'null'
			) {
				setCategoryName(selectCategory);
				setSubCategoryName({});
			} else {
				setSubCategoryName(selectCategory);
				let resSub = handleCategoriesMap(categoriesShop).find(
					(el) => el._id === selectCategory.parent_id
				);
				if (resSub?.name?.length) {
					setCategoryName(resSub);
				}
			}
		}
	}, [selectCategory, categoriesShop]);

	useEffect(() => {
		if (product?._id?.length && categoriesShop?.length) {
			let res = handleCategoriesMap(categoriesShop).find(
				(el) => el._id === product.category_id
			);
			if (
				res?.name?.length &&
				(res.parent_id === null || res.parent_id === 'null')
			) {
				setCategoryName(res);
			} else if (res?.name?.length && res?.parent_id?.length) {
				setSubCategoryName(res);
				let resSub = handleCategoriesMap(categoriesShop).find(
					(el) => el._id === res.parent_id
				);
				if (resSub?.name?.length) {
					setCategoryName(resSub);
				}
			}
		}

		if (
			categoriesShop?.length &&
			!collectionCategoryName?.length &&
			!product?._id?.length
		) {
			let res = handleCategoriesMap(categoriesShop).find(
				(el) => el._id === NOT_DELETED_SUB_CATEGORY_ID
			);
			if (res?.name?.length) {
				setCollectionCategoryName(res.name);
			}
		}
	}, [categoriesShop, product]);

	const handleOpenCatalog = (categories) => {
		handleOpenNewPage(categoriesShop, categories, navigate, dispatch);
	};

	return (
		<div className='bread_crumbs'>
			{isPreloader && (
				<>
					<Skeleton className='bread_crumbs__skeleton' />
					/
					<Skeleton className='bread_crumbs__skeleton' />
					/
					<Skeleton className='bread_crumbs__skeleton' />
				</>
			)}
			{!product?._id?.length &&
				!collectionName?.length &&
				!!selectCategory._id?.length &&
				!isPreloader && (
					<>
						<button onClick={() => handleOpenCatalog({})}>Каталог</button>
						<span>/</span>
						{!subCategoryName?.name?.length ? (
							<div className='bread_crumbs__last'>{categoryName.name}</div>
						) : (
							<>
								<button onClick={() => handleOpenCatalog(categoryName)}>
									{categoryName.name}
								</button>
								<span>/</span>
								<div className='bread_crumbs__last'>{subCategoryName.name}</div>
							</>
						)}
					</>
				)}
			{!!product?._id?.length &&
				!collectionName?.length &&
				!selectCategory._id?.length &&
				!isPreloader && (
					<>
						<button onClick={() => handleOpenCatalog({})}>Каталог</button>
						<span>/</span>
						{!subCategoryName?.name?.length ? (
							<>
								<button onClick={() => handleOpenCatalog(categoryName)}>
									{categoryName.name}
								</button>
								<span>/</span>
								<div className='bread_crumbs__last'>
									{product.title} ({product.code})
								</div>
							</>
						) : (
							<>
								<button onClick={() => handleOpenCatalog(categoryName)}>
									{categoryName.name}
								</button>
								<span>/</span>
								<button onClick={() => handleOpenCatalog(subCategoryName)}>
									{subCategoryName.name}
								</button>
								<span>/</span>
								<div className='bread_crumbs__last'>
									{product.title} ({product.code})
								</div>
							</>
						)}
					</>
				)}
			{!product?._id?.length &&
				!!collectionName?.length &&
				!selectCategory._id?.length &&
				!isPreloader && (
					<>
						<button onClick={() => handleOpenCatalog({})}>Каталог</button>
						<span>/</span>
						{!!collectionName?.length ? (
							<>
								<NavLink to={'/catalog/collections'}>
									{collectionCategoryName}
								</NavLink>
								<span>/</span>
								<div className='bread_crumbs__last'>{collectionName}</div>
							</>
						) : (
							<div
								className={`${
									!collectionName?.length ? 'bread_crumbs__last' : ''
								}`}
							>
								{collectionCategoryName}
							</div>
						)}
					</>
				)}
			{isTrendChildren && !isPreloader && (
				<>
					<button onClick={() => handleOpenCatalog({})}>Каталог</button>
					<span>/</span>
					<div className='bread_crumbs__last'>Для дітей</div>
				</>
			)}
		</div>
	);
}

export default memo(BreadCrumbs);
