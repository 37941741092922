import './Preloader.css';

function Preloader() {
	return (
		<div id='preloader' className='wrapper'>
			<div className='preloader' />
		</div>
	);
}

export default Preloader;
