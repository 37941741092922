import './NotificationsReadView.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGetData, fetchRequest, formatPhoneNumber, handleFormattingDate } from '../../helpers/Utils';
import letter from '../../assets/letter.svg';
import notifications1 from '../../assets/notifications1.svg';
import NotificationLogo from '../../components/NotificationLogo/NotificationLogo';
import arrowLeft from '../../assets/arrowLeft.svg';
import telImg from '../../assets/telImg.svg';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { setCountUnreadNotifications, setShowMessageObj } from '../../store/userSlice';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function NotificationsReadView() {
    const countUnreadNotifications = useSelector(state => state.userSlice.countUnreadNotifications);
    const [notification, setNotification] = useState({});
    const [isPreloader, setIsPreloader] = useState(true);
    const { notificationId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('token')

    useEffect(() => {
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/notifications/${notificationId}?token=${token}`)
            .then(res => {
                if (res.success && res.data) {
                    setNotification(res.data)
                } else {
                    console.log('GET NotificationsReadView', res)
                }
                setIsPreloader(false)
            })
    }, [])
   
    useEffect(() => {
        if (notification?._id?.length && !notification?.is_read) {
            let data ={
                ...notification,
                is_read: true
            }
    
            fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/notifications/${notificationId}?token=${token}`, data, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
                .then(res => {
                    if (res.success && res.data) {
                       setNotification(data)
                       dispatch(setCountUnreadNotifications(countUnreadNotifications - 1))
                    } else {
                        console.log('PUT NotificationsReadView', res)
                    }
                })
        }
    }, [notification])

    const handleGoBack = () => {
        navigate(-1);
    };
 
    return (
        <div className="notifications-read">
            <SeoBlock title={"Повідомлення"} description={""} keywords={""}/>
            <div className="notifications-read__header">
                <div className="notifications-read__header-title-wrap">
                    <img className="notifications-read__header-title-img" src={notifications1} alt='img'/>
                    <h1 className="notifications-read__header-title">Повідомлення</h1>
                </div>
            </div>
            
            {
                isPreloader ? <PreloaderCustom /> 
                    :
                    <>
                        {
                            !!notification?._id?.length &&
                                <div className="notifications-read__btn-wrap">
                                    <button onClick={handleGoBack} className="notifications-read__btn">
                                        <img className="notifications-read__btn-img" src={arrowLeft} alt='img'/>
                                        <NotificationLogo notification={notification} />
                                        <div className="notifications-read__btn-text"> 
                                            <div className="notifications-read__btn-text-name">{!!notification.full_name?.length ? notification.full_name : !!notification.contact_name?.length ? notification.contact_name : notification.company_name?.length ? notification.company_name : ''}</div> 
                                            {
                                                notification?.is_new_vendor && <span>(реєстрація партнера)</span>
                                            }
                                        </div>
                                    </button>
                                </div>
                        }
                        <div className="notifications-read__main">
                            <div className="notifications-read__main-wrap">
                                <div className="notifications-read__main-text-wrap">
                                    <div className="notifications-read__main-title">Дата створення:</div>
                                    <div className="notifications-read__main-text">{handleFormattingDate(notification.created_at)}</div>
                                </div>
                                {
                                    !!notification.phone?.length &&
                                        <div className="notifications-read__main-text-wrap">
                                            <div className="notifications-read__main-title">Телефон:</div>
                                            <div className="notifications-read__tel--wrap">
                                                <img className="notifications-read__tel-img" src={telImg} alt='img'/>
                                                <div className="notifications-read__tel">{formatPhoneNumber(notification.phone)}</div>
                                            </div>
                                        </div>
                                }
                                {
                                    !!notification.email?.length &&
                                        <div className="notifications-read__main-text-wrap">
                                            <div className="notifications-read__main-title">Email:</div>
                                            <div className="notifications-read__mail--wrap">
                                                <img className="notifications-read__mail-img" src={letter} alt='img'/>
                                                <div className="notifications-read__tel">{notification.email}</div>
                                            </div>
                                        </div>
                                }
                                <div className="notifications-read__main-text-wrap">
                                    <div className="notifications-read__main-title">Сторінка:</div>
                                    <div className={`user-notifications__notifications-text-type ${notification.type === 'contact' ? 'user-notifications__notifications-type-1' : notification.type === 'partner' ? 'user-notifications__notifications-type-2' :  notification.type === 'about' ? 'user-notifications__notifications-type-3' : notification.type === 'indiviual_order' ?  'user-notifications__notifications-type-4' : 'user-notifications__notifications-type-5'}`}>{notification.type === 'contact' ? 'Контакти' : notification.type === 'partner' ? 'Партнери' : notification.type === 'about' ? 'Про нас' : notification.type === 'indiviual_order' ? 'Інд виробн' : 'Виробник'}</div>
                                </div>
                                {
                                     notification.type === 'vendor' && 
                                     <div  div className="notifications-read__main-text-wrap">
                                            <div className="notifications-read__main-title">Виробник:</div>
                                            <div className="notifications-read__main-text">{notification.company_name}</div>
                                        </div>
                                }
                            </div>
                            <div className="notifications-read__message-wrap">
                                <div className="notifications-read__main-text-message-wrap">
                                    {
                                        notification.type === 'vendor' && 
                                            <>
                                                <div className="notifications-read__main-title">Повідомлення:</div>
                                                <div className="notifications-read__main-text">{notification.message}</div>
                                            </>
                                    }
                                    {
                                        notification.type === 'contact' && 
                                            <>
                                                <div className="notifications-read__main-title">Повідомлення:</div>
                                                <div className="notifications-read__main-text">{notification.message}</div>
                                            </>
                                    }
                                    {
                                        notification.type === 'about' && 
                                            <>
                                                <div className="notifications-read__main-title">Повідомлення:</div>
                                                <div className="notifications-read__main-text">{notification.message}</div>
                                            </>
                                    }
                                    {
                                        notification.type === 'partner' && 
                                            <>
                                                <div className="notifications-read__main-title-wrap">
                                                    <div className="notifications-read__main-title">Назва компанії:</div>
                                                    <div className="notifications-read__main-text">{!!notification.company_name?.length ? notification.company_name : ''}</div>
                                                </div>
                                                <div className="notifications-read__main-title-wrap">
                                                    <div className="notifications-read__main-title">Соціальні мережі:</div>
                                                    <div className="notifications-read__main-text">{!!notification.company_social_networks?.length ? notification.company_social_networks : ''}</div>
                                                </div>
                                                <div className="notifications-read__main-title-wrap">
                                                    <div className="notifications-read__main-title">Вебсайт:</div>
                                                    <div className="notifications-read__main-text">{!!notification.company_website?.length ? notification.company_website : ''}</div>
                                                </div>
                                            </>
                                    }
                                    {
                                        notification.type === 'indiviual_order' && 
                                            <>
                                                <div className="notifications-read__main-title-wrap">
                                                    <div className="notifications-read__main-title">Характеристики меблів:</div>
                                                    <div className="notifications-read__main-text">{!!notification.materials?.length ? notification.materials : ''}</div>
                                                </div>
                                                <div className="notifications-read__main-title-wrap">
                                                    <div className="notifications-read__main-title">Адреса:</div>
                                                    <div className="notifications-read__main-text">{!!notification.address?.length ? notification.address : ''}</div>
                                                </div>
                                                <div className="notifications-read__main-title-wrap">
                                                    <div className="notifications-read__main-title">Коментар:</div>
                                                    <div className="notifications-read__main-text">{!!notification.description?.length ? notification.description : ''}</div>
                                                </div>
                                                <div className="notifications-read__main-title-wrap">
                                                    <div className="notifications-read__main-title">Картинка:</div>
                                                    <div className="notifications-read__main-text-img-wrap">
                                                        {
                                                            !!notification.images?.length && notification.images.map(el => (
                                                                <img className="notifications-read__main-text-img" src={el} alt="img" key={el}/>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>
    );
}

export default NotificationsReadView;