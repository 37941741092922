import { useEffect, useMemo, useRef, useState } from 'react';
import VariationResult from '../VariationResult/VariationResult';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setIsOpenSelect } from '../../../store/homeSlice';
import { disabledScroll, enabledScroll } from '../../../helpers/Utils';
import ColorResult from '../../../components/ColorResult/ColorResult';
import MaterialResult from '../../../components/MaterialResult/MaterialResult';
import { Color, Material, Product, Variations } from '../types';
import { Modal } from '@mui/material';
//@ts-ignore
import closeImg from '../assets/close.svg';

type VariationsCardProps = {
	product: Product;
	modalScrollPosition: number;
	setModalScrollPosition: () => void;
	handleClickOpenProductVariations: (arg: any) => void;
	handleIsOpenMenuVariations: (arg: boolean) => void;
};

const VariationsCard = ({
	product,
	modalScrollPosition,
	setModalScrollPosition,
	handleClickOpenProductVariations,
	handleIsOpenMenuVariations,
}: VariationsCardProps) => {
	const dispatch = useDispatch();
	const wariatiosWrap = useRef<HTMLDivElement>(null);
	// @ts-ignore
	const isOpenSelect = useSelector((state) => state.homeSlice.isOpenSelect);

	const [openSelectName, setOpenSelectName] = useState('');
	const [variationsMaterial, setVariationsMaterial] = useState<Material[]>([]);
	const [variationsColor, setVariationsColor] = useState<Color[]>([]);
	const [isVariationsInfo, setIsVariationsInfo] = useState(false);

	const handleCloseVariationsInfo = () => {
		setIsVariationsInfo(false);
		enabledScroll(modalScrollPosition);
	};
	const handleOpenVariationsInfo = () => {
		setIsVariationsInfo(true);
		disabledScroll(setModalScrollPosition);
	};

	const handleWidthWariationsWrap = (num: number) => {
		if (wariatiosWrap.current) {
			const result = Math.floor(wariatiosWrap.current.clientWidth / 64);
			return num && num === result ? result : result - 1;
		} else return 5;
	};

	useEffect(() => {
		if (!isOpenSelect) {
			setOpenSelectName('');
		}
	}, [isOpenSelect]);

	useEffect(() => {
		if (product.variations?.length) {
			let resMaterial = product.variations.filter(
				(el) => el.name === 'Матеріал'
			);
			let resColor = product.variations.filter((el) => el.name === 'Колір');

			if (resMaterial?.length && resMaterial[0]?.value?.length) {
				// let indexRes = resMaterial[0]?.value.findIndex(
				// 	(item) => item.title === product.material.title
				// );

				// const maxVisibleVariations = handleWidthWariationsWrap(
				// 	resMaterial[0]?.value.length
				// );

				// if (maxVisibleVariations && indexRes >= maxVisibleVariations) {
				// 	const materialTypes = [
				// 		...resMaterial[0]?.value.slice(0, maxVisibleVariations - 1),
				// 		resMaterial[0]?.value.find(
				// 			(item) => item.title === product.material.title
				// 		),
				// 	];
				// 	console.log(materialTypes);
				// 	setVariationsMaterial(materialTypes);
				// } else {
				setVariationsMaterial([...resMaterial[0]?.value] as Material[]);
				// }
			}

			if (resColor?.length && resColor[0]?.value?.length) {
				// let indexRes = resColor[0]?.value.findIndex(
				// 	(item) => item.color === product.color
				// );
				// if (indexRes >= handleWidthWariationsWrap(resColor[0]?.value.length)) {
				// 	setVariationsColor([
				// 		...resColor[0]?.value.slice(
				// 			0,
				// 			handleWidthWariationsWrap(resColor[0]?.value.length) - 1
				// 		),
				// 		resColor[0]?.value.find((item) => item.color === product.color),
				// 	]);
				// } else {
				setVariationsColor([...resColor[0]?.value] as Color[]);
				// }
			}
		}
	}, [product]);

	const handleCloseSelect = () => {
		setOpenSelectName('');
		dispatch(setIsOpenSelect(false));
		enabledScroll(modalScrollPosition);
	};

	const handleOpenSelect = (str: string) => {
		setOpenSelectName(str);
		dispatch(setIsOpenSelect(true));
		disabledScroll(setModalScrollPosition);
	};

	const handleSortProductVariations = useMemo(() => {
		return (arr: Variations[]) => {
			const materialObjects = arr.filter((obj) => obj.name === 'Матеріал');
			const colorObjects = arr.filter((obj) => obj.name === 'Колір');
			const otherObjects = arr.filter(
				(obj) => obj.name !== 'Матеріал' && obj.name !== 'Колір'
			);

			otherObjects.sort((a, b) => a.name.localeCompare(b.name));

			return [...materialObjects, ...colorObjects, ...otherObjects];
		};
	}, []);

	return (
		<>
			<Modal open={isVariationsInfo} onClose={handleCloseVariationsInfo}>
				<div className='product-view__modal-form-wrap'>
					<img
						className='product-view__modal-form-btn-close product-view__modal-form-btn-close--desktop'
						onClick={handleCloseVariationsInfo}
						src={closeImg}
						alt='img'
					/>
					<div className='product-view__modal-form-text--wrap'>
						<div
							className='product-view__modal-form--wrap'
							onClick={handleCloseVariationsInfo}
						></div>
						<div className='product-view__modal-form'>
							<div className='product-view__modal-form-title-wrap'>
								<span className='product-view__text-modal-tab-title-material'>
									{product.material?.title}
								</span>
								<button
									className='product-view__text-modal-tab-btn-close-wrap'
									onClick={handleCloseVariationsInfo}
								>
									<svg
										className='product-view__text-modal-tab-btn-close'
										viewBox='0 0 14 14'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M1.27312 13.5269L0.473145 12.7269L6.20007 7L0.473145 1.27307L1.27312 0.473099L7.00004 6.20002L12.727 0.473099L13.5269 1.27307L7.80002 7L13.5269 12.7269L12.727 13.5269L7.00004 7.79997L1.27312 13.5269Z'
											fill='black'
										/>
									</svg>
								</button>
							</div>
							<div className='product-view__modal-form-text-wrap'>
								<div className='product-view__text-modal-material-result-wrap'>
									<img
										className='product-view__text-modal-material-result-img'
										src={product.material?.img}
										alt='img'
									/>
									{!!product?.material?.type?.length && (
										<div className='product-view__text-modal-material-result-title-wrap'>
											<span className='product-view__text-modal-material-result-title'>
												Тип матеріалу:
											</span>{' '}
											{product.material?.type}
										</div>
									)}
									{!!product?.material?.ingredient?.length && (
										<div className='product-view__text-modal-material-result-title-wrap'>
											<span className='product-view__text-modal-material-result-title'>
												Склад матеріалу:
											</span>{' '}
											{product.material?.ingredient}
										</div>
									)}
									{!!product?.material?.keeping?.length && (
										<div className='product-view__text-modal-material-result-title-wrap'>
											<span className='product-view__text-modal-material-result-title'>
												Догляд:
											</span>{' '}
											{product.material?.keeping}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			{!!product?.color?.length ||
				(!!product.color?.length &&
					!!product.variations?.length &&
					product.variations.every((item) => item.name !== 'Колір') && (
						<>
							<div className='product-view__variations-select-title'>
								<span>Колір:&nbsp;</span>
								<div className='product-view__variations-select-title-text'>
									{product.color_name}
								</div>
							</div>
							<ColorResult
								productColor={product.color}
								color={product.color}
								isProductView={true}
							/>
						</>
					))}

			{!!product.material?.img?.length ||
				(!!product.material?.img?.length &&
					!!product.variations?.length &&
					product.variations.every((item) => item.name !== 'Матеріал') && (
						<>
							<div className='product-view__variations-select-title'>
								<span>Матеріал:&nbsp;</span>
								<div
									className='product-view__variations-select-title-text product-view__variations-select-title-text--mobile'
									onClick={handleOpenVariationsInfo}
								>
									{product.material.title}
								</div>
								<div className='product-view__variations-select-title-text product-view__variations-select-title-text--desc'>
									{product.material.title}
								</div>
							</div>
							<MaterialResult
								productMaterial={product.material}
								variation={product.material}
								isHover={true}
							/>
						</>
					))}

			{handleSortProductVariations(product.variations).map((el) => (
				<div
					className='product-view__variations-select-wrap'
					ref={wariatiosWrap}
					key={el.name}
				>
					<div
						className={`product-view__variations-select-title ${
							el.name !== 'Матеріал' && el.name !== 'Колір'
								? 'product-view__variations-select-title-variations'
								: ''
						}`}
					>
						<span>{el.name}:&nbsp;</span>
						{el.name === 'Колір' && (
							<div className='product-view__variations-select-title-text'>
								{product?.color_name as string}
							</div>
						)}
						{el.name === 'Матеріал' && (
							<div
								className='product-view__variations-select-title-text product-view__variations-select-title-text--mobile'
								onClick={handleOpenVariationsInfo}
							>
								{product?.material?.title as string}
							</div>
						)}
						{el.name === 'Матеріал' && (
							<div className='product-view__variations-select-title-text product-view__variations-select-title-text--desc'>
								{product.material.title}
							</div>
						)}
					</div>
					{el.name === 'Колір' ? (
						<div className='product-view__variations-select-color-wrap'>
							{!!variationsColor?.length &&
								variationsColor
									.slice(0, handleWidthWariationsWrap(el.value?.length))
									.map((variation, i) => (
										<div
											onClick={() =>
												handleClickOpenProductVariations({
													name: el.name,
													value: variation,
												})
											}
											//@ts-ignore
											key={variation.color + i}
										>
											<ColorResult
												productColor={product.color}
												//@ts-ignore
												color={variation.color}
												isProductView={true}
											/>
										</div>
									))}
							{!!el.value?.length &&
								el.value?.length - handleWidthWariationsWrap(el.value?.length) >
									0 && (
									<button
										className='product-view__variations-select-color-btn-all'
										onClick={() => handleIsOpenMenuVariations(true)}
									>
										+
										{el.value?.length -
											handleWidthWariationsWrap(el.value?.length)}
									</button>
								)}
						</div>
					) : el.name === 'Матеріал' ? (
						<div className='product-view__variations-select-color-wrap'>
							{!!variationsMaterial?.length &&
								variationsMaterial
									.slice(0, handleWidthWariationsWrap(el.value?.length))
									.map((variation, i) => (
										<div
											onClick={() =>
												handleClickOpenProductVariations({
													name: el.name,
													value: variation,
												})
											}
											//@ts-ignore
											key={(variation.title as string) + i}
										>
											<MaterialResult
												productMaterial={product.material}
												variation={variation}
												isHover={true}
											/>
										</div>
									))}
							{!!el.value?.length &&
								el.value?.length - handleWidthWariationsWrap(el.value?.length) >
									0 && (
									<button
										className='product-view__variations-select-color-btn-all'
										onClick={() => handleIsOpenMenuVariations(true)}
									>
										+
										{el.value?.length -
											handleWidthWariationsWrap(el.value?.length)}
									</button>
								)}
						</div>
					) : (
						<VariationResult
							variation={el}
							characteristics={product.characteristics}
							openSelectName={openSelectName}
							handleCloseSelect={handleCloseSelect}
							handleOpenSelect={handleOpenSelect}
							handleClickOpenProductVariations={
								handleClickOpenProductVariations
							}
						/>
					)}
				</div>
			))}
		</>
	);
};

export default VariationsCard;
