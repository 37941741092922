import './NotificationLogo.css';

function NotificationLogo({notification=null}) {
    return (
        <>
           {
                !!notification.full_name?.length ?
                    <div className={`notification-logo__notifications-text-logo ${notification.type === 'contact' ? 'notification-logo__notifications-type-1' : notification.type === 'partner' ? 'notification-logo__notifications-type-2' : notification.type === 'about' ? 'notification-logo__notifications-type-3' : notification.type === 'indiviual_order' ? 'notification-logo__notifications-type-4' : 'notification-logo__notifications-type-5'}`}>
                        {notification.full_name[0]}{notification.full_name.split(' ')?.length >= 2 ? notification.full_name.split(' ')[1][0] : null}
                    </div>
                    :
                    <div className={`notification-logo__notifications-text-logo ${notification.type === 'contact' ? 'notification-logo__notifications-type-1' : notification.type === 'partner' ? 'notification-logo__notifications-type-2' : 'notification-logo__notifications-type-3'}`}>{notification.email?.length ? notification.email.slice(0, 2).toUpperCase() : 'Не вказано'}</div>
            }
        </>
    );
}

export default NotificationLogo;