import './CategoryCards.css';
import { NavLink } from 'react-router-dom';
import { handleCategoriesMap } from '../../helpers/Utils';
import { useSelector } from 'react-redux';
import categoryImg11 from '../../assets/categoryImg11.jpg';
import categoryImgMob22 from '../../assets/categoryImgMob22.jpg';
import categoryImg22 from '../../assets/categoryImg22.jpg';
import categoryImgMob33 from '../../assets/categoryImgMob33.jpg';
import categoryImg33 from '../../assets/categoryImg33.jpg';
import categoryImgMob44 from '../../assets/categoryImgMob44.jpg';
import categoryImg44 from '../../assets/categoryImg44.jpg';
import categoryImgMob55 from '../../assets/categoryImgMob55.jpg';
import categoryImg55 from '../../assets/categoryImg55.jpg';
import categoryImgMob66 from '../../assets/categoryImgMob66.jpg';
import categoryImg66 from '../../assets/categoryImg66.jpg';
import { useEffect, useState } from 'react';

function CategoryCards() {
    const categoriesShop = useSelector(state => state.homeSlice.categoriesShop);
    const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
           
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleResize = () => {
        setWindowInnerWidth(window.innerWidth)   
    }

    const handleSearchCategory = (str) => {
        let res = ''
        if (categoriesShop?.length) {
            let category = handleCategoriesMap(categoriesShop).find(el => el.name === str)
            if (category?.alias?.length) {
                res = category?.alias
            }
        }
        return res
    }

    return (
        <div className="category-cards--wrap">
            <div className='category-cards__title'>Категорії товарів</div>
            <div className="category-cards">
                <div className="category-cards__card-wrap">
                    <div className="category-cards__card--wrap">
                        <div className="category-cards__card">
                            <img className="category-cards__card-img-1" src={windowInnerWidth < 640 ? categoryImg11 : categoryImg11} alt='img'/>
                                <div className="category-cards__card-text-wrap">
                                    <div className="category-cards__card-text-title">М'які меблі</div>
                                    <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Дивани')}`}>Дивани</NavLink>
                                    <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Крісла')}`}>Крісла</NavLink>
                                </div>
                        </div>
                        <div className="category-cards__card">
                            <img className="category-cards__card-img-1" src={windowInnerWidth < 640 ? categoryImgMob22 : categoryImg22} alt='img'/>
                                <div className="category-cards__card-text-wrap">
                                    <div className="category-cards__card-text-title">Ліжка</div>
                                    <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Ліжка')}/${handleSearchCategory('Односпальні ліжка')}`}>Односпальні ліжка</NavLink>
                                    <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Ліжка')}/${handleSearchCategory('Двоспальні ліжка')}`}>Двоспальні ліжка</NavLink>
                                </div>
                        </div>
                    </div>
                    <div className="category-cards__card">
                        <img className="category-cards__card-img-3" src={windowInnerWidth < 640 ? categoryImgMob33 : categoryImg33} alt='img'/>
                            <div className="category-cards__card-text-wrap">
                                <div className="category-cards__card-text-title">Стільці, пуфи</div>
                                <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Стільці')}/${handleSearchCategory('Обідні стільці')}`}>Обідні стільці</NavLink>
                                <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Стільці')}/${handleSearchCategory('Барні стільці')}`}>Барні стільці</NavLink>
                                <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Стільці')}/${handleSearchCategory('Табурети')}`}>Табурети</NavLink>
                                <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Стільці')}/${handleSearchCategory('Пуфи')}`}>Пуфи, банкетки</NavLink>
                            </div>
                    </div>
                </div>
            
                <div className="category-cards__card-bottom-wrap">
                    <div className="category-cards__card">
                        <img className="category-cards__card-img-4" src={windowInnerWidth < 640 ? categoryImgMob44 : categoryImg44} alt='img'/>
                            <div className="category-cards__card-text-wrap">
                                <div className="category-cards__card-text-title">Столи</div>
                                <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Столи')}/${handleSearchCategory('Обідні столи')}`}>Обідні столи</NavLink>
                                <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Столи')}/${handleSearchCategory("Письмові, комп'ютерні столи")}`}>Письмові, комп'ютерні столи</NavLink>
                                <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Столи')}/${handleSearchCategory('Журнальні, кавові, приліжкові столи')}`}>Журнальні, кавові, приліжкові столи</NavLink>
                                <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Столи')}/${handleSearchCategory('Туалетні столики')}`}>Туалетні столики</NavLink>
                                <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Столи')}/${handleSearchCategory('Барні столи')}`}>Барні столи</NavLink>
                            </div>
                    </div>
                    <div className="category-cards__card-bottom--wrap">
                        <div className="category-cards__card-5">
                            <img className="category-cards__card-img-5" src={windowInnerWidth < 640 ? categoryImgMob55 : categoryImg55} alt='img'/>
                                <div className="category-cards__card-text-wrap">
                                    <div className="category-cards__card-text-title">Корпусні меблі</div>
                                    <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Кухні')}`}>Кухні</NavLink>
                                    <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Шафи, стелажі')}/${handleSearchCategory('Шафи')}`}>Шафи</NavLink>
                                    <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Шафи, стелажі')}/${handleSearchCategory('Консолі')}`}>Консолі</NavLink>
                                    <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Шафи, стелажі')}/${handleSearchCategory('Стелажі')}`}>Стелажі</NavLink>
                                    <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Шафи, стелажі')}/${handleSearchCategory('Полиці')}`}>Полиці</NavLink>
                                </div>
                        </div>
                        <div className="category-cards__card">
                            <img className="category-cards__card-img-6" src={windowInnerWidth < 640 ? categoryImgMob66 : categoryImg66} alt='img'/>
                                <div className="category-cards__card-text-wrap">
                                    <div className="category-cards__card-text-title">Тумби, комоди</div>
                                    <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Тумби, комоди')}/${handleSearchCategory('Тумби під ТБ')}`}>Тумби під ТБ</NavLink>
                                    <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Тумби, комоди')}/${handleSearchCategory('Приліжкові тумби')}`}>Приліжкові тумби</NavLink>
                                    <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Тумби, комоди')}/${handleSearchCategory('Комоди')}`}>Комоди</NavLink>
                                    <NavLink className='category-cards__card-text' to={`/catalog/${handleSearchCategory('Тумби, комоди')}/${handleSearchCategory('Тумби для ванної')}`}>Тумби для ванної</NavLink>
                                </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default CategoryCards;