import './ContextMenuVendor.css';
import { Button, Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import threedots from '../../assets/threedots.svg';

function ContextMenuVendor({vendor, handleClickMenu}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    const handleScroll = () => {
        setAnchorEl(null)
    };

    const handleClickSettings = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClick = (str) => {
        handleClickMenu(vendor, str)
        setAnchorEl(null);
    };

    return (
        <div className="context-menu-vendor">
            <Button
                id={`basic-button-${vendor._id}`}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickSettings}
                className='context-menu-vendor__btn'
                disableRipple={true}
                >
                <img className='context-menu-vendor__btn-img' src={threedots} alt='img'/>
            </Button>
            <Menu
                id={`basic-menu-${vendor._id}`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                className='context-menu-vendor__btn-select'
                MenuListProps={{
                'aria-labelledby': `basic-button-${vendor._id}`,
                }}
                disableRipple={true}
            >
                <MenuItem className='context-menu-vendor__btn-select-item' onClick={() => handleClick('edite')} disableRipple={true}>Редагувати</MenuItem>
                <MenuItem className='context-menu-vendor__btn-select-item' onClick={() => handleClick('hide')} disableRipple={true}>{ vendor.is_blocked ? 'Розблокувати' : 'Заблокувати'}</MenuItem>
                <MenuItem className='context-menu-vendor__btn-select-item' onClick={() => handleClick('delete')} disableRipple={true}>Видалити</MenuItem>
                <MenuItem className='context-menu-vendor__btn-select-item' onClick={() => handleClick('uploudProduct')} disableRipple={true}>Завантажити товари</MenuItem>
                <MenuItem className='context-menu-vendor__btn-select-item' onClick={() => handleClick('saveProduct')} disableRipple={true}>Зберегти товари</MenuItem>
                {
                    !!vendor.vendor_docs?.length &&
                        <MenuItem className='context-menu-vendor__btn-select-item' onClick={() => handleClick('docs')} disableRipple={true}>Зберегти документи</MenuItem>
                }
            </Menu>
            
        </div>
    );
}

export default ContextMenuVendor;