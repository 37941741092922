import './UserCollectionView.css';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCollections, setEditeCollection, setShowMessageObj } from '../../store/userSlice';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import editIcon1 from './../../assets/editIcon1.svg';
import deleteImg3 from './../../assets/deleteImg3.svg';
import collection1 from './../../assets/collection1.svg';
import plus from './../../assets/plus.svg';
import { fetchGetData, fetchRequest } from '../../helpers/Utils';
import { NavLink, useNavigate } from 'react-router-dom';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function UserCollectionView() {
    const collections = useSelector(state => state.userSlice.collections);
    const [isPreloader, setIsPreloader] = useState(true);
    const [isDeleteCollection, setIsDeleteCollection] = useState(false);
    const [deleteCollectionId, setDeleteCollectionId] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let token = localStorage.getItem('token')

    useEffect(() => {
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/collections/all`)
            .then(res => {
                if (res.success && res.data) {
                    dispatch(getCollections(res.data))
                } else {
                    console.log('GET UserCollectionView', res)
                }
                setIsPreloader(false)
            })
    }, [])
    
    const handleDeleteCollection = (id) => {
        setIsDeleteCollection(true)
        setDeleteCollectionId(id)
    }
    
    const handleIsDeleteCollection = (boolean) => {
        if (boolean) {
            setIsPreloader(true)
            let data = {
            }
            fetchRequest('DELETE', `${process.env.REACT_APP_BASE_URL}/collections/${deleteCollectionId}?token=${token}`, data, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(getCollections(collections.filter(el => el._id !== deleteCollectionId)))
                        dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                    } else {
                        console.log('DELETE UserCollectionView:', res)
                    }
                    setIsPreloader(false)
                })
        }
        
        setIsDeleteCollection(false)
        setDeleteCollectionId('')
    }
    
    const handleUpdateCollection = (obj) => {
        dispatch(setEditeCollection(obj))
    }
    
    return (
        <div className={`user-collection `}>
            <SeoBlock title={"Колекції"} description={""} keywords={""}/>
            {
                isDeleteCollection && <ModalWindow title={'Видалити дану колекцію?'} handleClick={handleIsDeleteCollection}/>
            }

            <div className="user-collection__header">
                <div className="user-collection__header-title-wrap">
                    <img className="user-collection__header-title-img" src={collection1} alt='img'/>
                    <h1 className="user-collection__header-title">Колекції</h1>
                </div>
                <div className="user-collection__header-btn-wrap">
                    <NavLink className="user-collection__header-btn" to='/auth/collection/create'>
                        <img className="user-collection__header-btn-img" src={plus} alt='img'/>
                        <span>Створити колекцію</span>
                    </NavLink>
                </div>
            </div>

            {
                isPreloader ? <PreloaderCustom />
                    :
                    <div className="user-collection__main-wrap">
                        <div className="user-collection__main-count-wrap">
                            <div className="user-collection__main-count-text">Кількість створених колекцій:</div>
                            <div className="user-collection__main-count">{collections?.length}</div>
                        </div>

                        {
                            !!collections?.length ? 
                                <div className="user-collection__blocks-result-wrap">
                                    {
                                        collections?.map((el, i) => (
                                            <div className="user-collection__blocks-result" key={el._id}>
                                                <div className="user-collection__blocks-result-text--wrap">
                                                    <NavLink onClick={() => handleUpdateCollection(el)} to={`/auth/collection/${el._id}`}>
                                                        <img className="user-collection__blocks-result-img" src={el.image} alt='img'/>
                                                    </NavLink>
                                                    <div className="user-collection__blocks-result-text-wrap">
                                                        <div className="user-collection__blocks-result-text-title">Колекція {i + 1}</div>
                                                        <NavLink className="user-collection__blocks-result-text" onClick={() => handleUpdateCollection(el)} to={`/auth/collection/${el._id}`}>{el.title}</NavLink>
                                                        <div className="user-collection__blocks-result-text-count"><span>Кількість товарів</span> <span>{el.products?.length}</span></div>
                                                    </div>
                                                </div>
                                                <div className="user-collection__blocks-result-btn-wrap">
                                                    <NavLink onClick={() => handleUpdateCollection(el)} to={`/auth/collection/${el._id}`}>
                                                        <img className="user-collection__blocks-result-text-btn" src={editIcon1} alt='img'/>
                                                    </NavLink>
                                                    <img className="user-collection__blocks-result-text-btn-del" onClick={() => handleDeleteCollection(el._id)} src={deleteImg3} alt='img'/>
                                                </div>
                                            </div>
                                        )) 
                                    }
                                </div>
                                :
                                <div className="user-collection__blocks-error">Нічого не знайдено</div>
                        }
                    </div>
            }
        </div>
    );
}

export default UserCollectionView;