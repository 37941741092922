import './PreloaderCustom.css';

function PreloaderCustom() {
    return (
        <div className="loader-wrap">
            <div className="loader">
            </div>
        </div>
    );
}

export default PreloaderCustom;