import './BenefitsBlock.css';
import about11 from '../../assets/about11.png';
import about21 from '../../assets/about21.png';
import about31 from '../../assets/about31.png';
import about41 from '../../assets/about41.png';
import about1 from '../../assets/about1.svg';
import about2 from '../../assets/about2.svg';
import about3 from '../../assets/about3.svg';
import about4 from '../../assets/about4.svg';

function BenefitsBlock({isAboutView=false}) {
    return (
        <div className="benefits-block__items">
            {
                isAboutView ? 
                    <>
                        <div className="benefits-block__item">
                            <img className="benefits-block__item-img" src={about11} alt='img'/>
                            <div>Сучасні меблі для дому</div>
                        </div>
                        <div className="benefits-block__item">
                            <img className="benefits-block__item-img" src={about21} alt='img'/>
                            <div>Українські виробники</div>
                        </div>
                        <div className="benefits-block__item">
                            <img className="benefits-block__item-img" src={about31} alt='img'/>
                            <div>Кастомізуй меблі під себе</div>
                        </div>
                        <div className="benefits-block__item">
                            <img className="benefits-block__item-img" src={about41} alt='img'/>
                            <div>Індивідуальне виробництво</div>
                        </div>
                    </>
                    :
                    <>
                        <div className="benefits-block__item">
                            <img className="benefits-block__item-img" src={about1} alt='img'/>
                            <div>Всі меблі на одному сайті</div>
                        </div>
                        <div className="benefits-block__item">
                            <img className="benefits-block__item-img" src={about2} alt='img'/>
                            <div>Швидко знайти і легко купити</div>
                        </div>
                        <div className="benefits-block__item">
                            <img className="benefits-block__item-img" src={about3} alt='img'/>
                            <div>Якісні меблі від українських виробників</div>
                        </div>
                        <div className="benefits-block__item">
                            <img className="benefits-block__item-img" src={about4} alt='img'/>
                            <div>Високий рівень сервісу продавців</div>
                        </div>
                    </>
            }
        </div>
    );
}

export default BenefitsBlock;