import './VariationResult.css';
import { memo, useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';

function VariationResult({
	variation,
	characteristics,
	openSelectName,
	handleCloseSelect,
	handleOpenSelect,
	handleClickOpenProductVariations,
}) {
	const [selectVariation, setSelectVariation] = useState('');

	useEffect(() => {
		const selected = characteristics.find(
			(characteristic) => characteristic.name === variation.name
		)?.value;
		setSelectVariation(selected);
	}, [variation, characteristics]);

	const handleChangeSelect = (obj) => {
		handleClickOpenProductVariations(obj);
		setSelectVariation(obj.value);
	};

	return (
		<>
			<Select
				value={selectVariation}
				open={openSelectName === variation.name ? true : false}
				onClose={handleCloseSelect}
				onOpen={() => handleOpenSelect(variation.name)}
				className='product-view__variations-select'
				onChange={(e) =>
					handleChangeSelect({ name: variation.name, value: e.target.value })
				}
				defaultValue={
					characteristics.find(
						(characteristic) => characteristic.name === variation.name
					)?.value
				}
				label=''
			>
				{!!variation.value?.length &&
					variation.value.map((ell, i) => (
						<MenuItem
							disableRipple={true}
							className='product-view__variations-select-item'
							value={ell}
							key={ell + i}
						>
							{ell}
						</MenuItem>
					))}
			</Select>
		</>
	);
}

export default memo(VariationResult);
