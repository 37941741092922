import './PartnersView.css';
import { useEffect, useRef, useState } from 'react';
import partners1 from '../../assets/partners1.jpg';
import partners2 from '../../assets/partners2.jpg';
import designSlider2 from '../../assets/designSlider2.jpg';
import designSlider3 from '../../assets/designSlider3.jpg';
import catalogueSlider1 from '../../assets/catalogueSlider1.jpg';
import catalogueSlider2 from '../../assets/catalogueSlider2.jpg';
import catalogueSlider3 from '../../assets/catalogueSlider3.jpg';
import productSlider2 from '../../assets/productSlider2.jpg';
import productSlider1 from '../../assets/productSlider1.png';
import internalOffice from '../../assets/internalOffice.jpg';
import people from '../../assets/people.svg';
import graph from '../../assets/graph.svg';
import cart from '../../assets/cart.svg';
import users from '../../assets/users.svg';
import net from '../../assets/net.svg';
import closeImg from '../../assets/closeImgBlack.svg';
import MainButton from '../../components/MainButton/MainButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination } from 'swiper/modules';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
	disabledScroll,
	enabledScroll,
	fetchRequest,
	validEmail,
} from '../../helpers/Utils';
import { setShowMessageObj } from '../../store/userSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import InputPhone from '../../components/InputPhone/InputPhone';
import {
	setIsScrollSlider,
	setIsSuccessFormPartnersView,
	setIsViewPreloader,
	setOldModalScrollPosition,
} from '../../store/homeSlice';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function PartnersView() {
	const seo = useSelector((state) => state.homeSlice.seo);
	const utm_source = useSelector((state) => state.homeSlice.utm_source);
	const utm_medium = useSelector((state) => state.homeSlice.utm_medium);
	const utm_campaign = useSelector((state) => state.homeSlice.utm_campaign);
	const scrollPositionBeforeView = useSelector(
		(state) => state.homeSlice.scrollPositionBeforeView
	);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [website, setWebsite] = useState('');
	const [social, setSocial] = useState('');
	const formRef = useRef(null);
	const whoRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [zoomImgArr, setZoomImgArr] = useState([]);
	const [isErrorName, setIsErrorName] = useState(false);
	const [isErrorPhone, setIsErrorPhone] = useState(false);
	const [isErrorEmail, setIsErrorEmail] = useState(false);
	const [isErrorCompanyName, setIsErrorCompanyName] = useState(false);
	const [modalScrollPosition, setModalScrollPosition] = useState(0);
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const [isNotFirstRender, setIsNotFirstRender] = useState(false);
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	useEffect(() => {
		setIsNotFirstRender(true);
		setTimeout(() => {
			dispatch(setIsViewPreloader(false));
		}, 200);

		setTimeout(() => {
			window.scroll({
				top: scrollPositionBeforeView[location.pathname]
					? scrollPositionBeforeView[location.pathname]
					: 0,
			});
		}, 10);

		return () => {
			dispatch(setIsScrollSlider(false));
			dispatch(setOldModalScrollPosition(0));
		};
	}, []);

	useEffect(() => {
		if (name?.length) {
			setIsErrorName(false);
		}
	}, [name]);

	useEffect(() => {
		if (phone?.length && phone.length >= 12) {
			setIsErrorPhone(false);
		}
	}, [phone]);

	useEffect(() => {
		if (email?.length) {
			setIsErrorEmail(false);
		}
	}, [email]);

	useEffect(() => {
		if (companyName?.length) {
			setIsErrorCompanyName(false);
		}
	}, [companyName]);

	const handleOpen = (imgArr) => {
		setZoomImgArr(imgArr);
		setOpen(true);
		disabledScroll(setModalScrollPosition);
	};

	const handleClose = () => {
		setZoomImgArr([]);
		setOpen(false);
		enabledScroll(modalScrollPosition);
	};

	const hendleSubmitFormGtm = () => {
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({ event: 'submit_form363703734' });
	};

	const hendleClean = () => {
		setName('');
		setEmail('');
		setPhone('');
		setCompanyName('');
		setWebsite('');
		setSocial('');
	};

	const hendleSend = (event) => {
		event.preventDefault();
		let sendBtn = document.getElementById('howBuyViewSendBtnId');
		sendBtn.style.cssText = `
            pointer-events: none;
        `;
		if (
			name?.length &&
			email?.length &&
			phone?.length &&
			phone.length >= 12 &&
			companyName?.length &&
			validEmail(email)
		) {
			let data = {
				name: name,
				email: email,
				phone: phone,
				company_name: companyName,
				company_website: website,
				company_social_networks: social,
				nameofpage: location,
				utm_source: utm_source?.length ? utm_source : null,
				utm_medium: utm_medium?.length ? utm_medium : null,
				utm_campaign: utm_campaign?.length ? utm_campaign : null,
			};
			fetchRequest(
				'POST',
				`${process.env.REACT_APP_BASE_URL}/forms/partner`,
				data,
				() =>
					dispatch(
						setShowMessageObj({
							open: true,
							status: 'error',
							message: 'Сталася помилка',
						})
					)
			).then((res) => {
				if (res.success && res.data) {
					dispatch(setIsSuccessFormPartnersView(true));
					navigate('/success');
					hendleClean();
					hendleSubmitFormGtm();
				} else {
					console.log('POST PartnersView:', res);
				}
			});
		} else {
			sendBtn.style.cssText = ``;
			setIsErrorName(!name?.length ? true : false);
			setIsErrorPhone(!phone?.length || phone.length < 12 ? true : false);
			setIsErrorEmail(!email.length || !validEmail(email) ? true : false);
			setIsErrorCompanyName(!companyName?.length ? true : false);
		}
	};

	return (
		<div className='partners-view'>
			<SeoBlock
				title={seo.title_be_seller}
				description={seo.description_be_seller}
				keywords={seo.keywords_be_seller}
			/>
			<Modal open={open} onClose={handleClose}>
				<div
					className={`how-buy-view__modal-zoom-wrap ${
						isSafari ? 'how-buy-view__modal-zoom-wrap--is-safari' : ''
					}`}
				>
					<Swiper
						loop={true}
						spaceBetween={10}
						modules={[Pagination]}
						pagination={{
							clickable: true,
						}}
						className='how-buy-view__modal-zoom-swiper-wrap'
					>
						{!!zoomImgArr?.length &&
							zoomImgArr.map((img, i) => (
								<SwiperSlide key={img + i}>
									<img
										className='how-buy-view__modal-zoom-img'
										src={img}
										alt='Зображення'
									/>
								</SwiperSlide>
							))}
					</Swiper>
					<img
						className='how-buy-view__modal-zoom-btn-close-img'
						onClick={handleClose}
						src={closeImg}
						alt='img'
					/>
				</div>
			</Modal>

			<div className='partners-view--wrap'>
				<div className='partners-view__title-wrap'>
					<div className='partners-view__title---wrap container'>
						<div className='partners-view__title--wrap container'>
							<h1 className='partners-view__title-text'>
								СПЕЦІАЛІЗОВАНИЙ МАРКЕТПЛЕЙС МЕБЛІВ
							</h1>
							<h2 className='partners-view__title-sub-text'>
								VYROBY - це спеціалізована платформа для розвитку бізнесу в
								інтернеті для виробників, за допомогою якої можна отримувати
								цільових клієнтів, продавати меблі!
							</h2>
							<button
								className='main-btn-1'
								onClick={() =>
									formRef.current.scrollIntoView({ behavior: 'smooth' })
								}
							>
								Відправити заявку
							</button>
						</div>
					</div>
					<button
						className='partners-view__title-arrow-btn'
						onClick={() =>
							whoRef.current.scrollIntoView({ behavior: 'smooth' })
						}
					>
						<div className='partners-view__title-arrow-btn-img'></div>
					</button>
				</div>

				<div className='partners-view__info-wrap' ref={whoRef}>
					<div className='partners-view__info container'>
						<div className='partners-view__info-text-wrap'>
							<div className='partners-view__info-text-title'>Хто ми?</div>
							<div className='partners-view__info-text'>
								VYROBY - це спеціалізований маркетплейс меблів для дому, який
								допомагає локальним виробникам меблів масштабувати та
								автоматизувати онлайн продажі, а покупцям меблів можливість
								купити сучасні меблі для дому в одному місці, меблі
								індивідуального виготовлення безпосередньо у перевірених
								локальних виробників!
								<br />
								<br />
								На нашій онлайн платформі ми хочемо зробити акцент на якість,
								стиль, високий рівень сервісу.
							</div>
						</div>
						<div className='partners-view__info-text-wrap'>
							<div className='partners-view__info-text-title'>
								Що ми Вам пропонуємо?
							</div>
							<div className='partners-view__info-text'>
								Ми пропонуємо виробникам меблів співпрацю - самостійно
								розміщувати і продавати товари у нас на платформі, а ми в свою
								чергу будемо відповідальними за маркетинг та цільовий трафік
								клієнтів!
								<br />
								<br />
								На нашій платформі ви можете продавати як серійні меблі так і
								меблі індивідуального виготовлення!
								<br />
								<br />
								Також ми пропонуємо додаткові сервіси - підтримку з обробки
								замовлень, контент підтримку.
							</div>
						</div>
					</div>
				</div>

				<div className='partners-view__card-wrap'>
					<div className='partners-view__card-text-title-1'>
						Маркетплейс меблів для покупців
					</div>
					<div className='partners-view__card container'>
						<div className='partners-view__card-text-wrap'>
							<div className='partners-view__card-text-title'>
								Маркетплейс меблів для покупців
							</div>
							<div className='partners-view__card-img-wrap'>
								<img
									className='partners-view__card-img'
									src={partners1}
									alt='img'
								/>
							</div>
							<div className='partners-view__card-text'>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Маркетплейс меблів
									</p>
									<p>
										Всі меблі для дому в одному місці - клієнт може закрити
										потреби в меблях в одному місці, не втрачаючи часу на пошуки
									</p>
								</div>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Сучасні меблі для дому
									</p>
									<p>
										Фокус на сучасних, якісних меблях для дому, з високим
										сервісом від продавців, виробників
									</p>
								</div>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Кастомізуй меблі під себе
									</p>
									<p>
										В більшості виробів ви можете змінити розмір, колір,
										матеріал чи інші характеристики товару за побажаннями
									</p>
								</div>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Українські перевірені виробники
									</p>
									<p>
										У нас представлені українські локальні виробники, з якими Ви
										можете працювати безпосередньо, ми власноруч відбираємо
										надійних виробників
									</p>
								</div>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Індивідуальне виробництво
									</p>
									<p>
										Ви можете замовити меблі індивідуального виробництва за
										Вашим проєктом, фото чи ескізом
									</p>
								</div>
							</div>
						</div>
						<div className='partners-view__card-img-1-wrap'>
							<img
								className='partners-view__card-img'
								src={partners1}
								alt='img'
							/>
						</div>
					</div>
				</div>

				<div className='partners-view__card-reverse-wrap'>
					<div className='partners-view__card-text-title-1'>
						Маркетплейс меблів для виробників та продавців
					</div>
					<div className='partners-view__card-reverse partners-view__card-1-slider-wrap container'>
						<div className='partners-view__card-text-wrap'>
							<div className='partners-view__card-text-title'>
								Маркетплейс меблів для виробників та продавців
							</div>
							<div className='partners-view__card-img-wrap'>
								<img
									className='partners-view__card-img'
									src={partners2}
									alt='img'
								/>
							</div>
							<div className='partners-view__card-text'>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Цільовий трафік клієнтів
									</p>
									<p>
										На платформі будуть представлені лише товари сегменту
										середній плюс для ЦА, яка націлена на якісний продукт
									</p>
								</div>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Низький поріг входу в онлайн продажі
									</p>
									<p>
										Витрати на просування на маркетплейсі набагато менші в
										порівнянні із самостійною організацію онлайн просування
									</p>
								</div>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Продавати напряму
									</p>
									<p>
										Можливість напряму продавати кінцевим споживачам, що
										збільшує маржинальність продажів
									</p>
								</div>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Фокус на основній діяльності
									</p>
									<p>
										Фокус на основній діяльності - на виробництві меблів,
										маркетплейс бере на себе функцію просування в онлайні, що
										економить час та ресурси
									</p>
								</div>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Додатковий канал продаж
									</p>
									<p>
										За допомогою маркетплейсу ви можете швидко запустити продажі
										вашого товару та просто управляти продажами на платформі
									</p>
								</div>
							</div>
						</div>
						<div className='partners-view__card-img-1-wrap'>
							<img
								className='partners-view__card-img'
								src={partners2}
								alt='img'
							/>
						</div>
					</div>
				</div>

				<div className='partners-view__how-wrap'>
					<div className='partners-view__how container'>
						<div className='partners-view__how-title'>Як це працює</div>
						<div className='partners-view__how-cards'>
							<div className='partners-view__how-card'>
								<img
									className='partners-view__how-card-img'
									src={people}
									alt='img'
								/>
								<div className='partners-view__how-card-title'>
									Для покупців
								</div>
								<ul className='partners-view__how-card-text'>
									<li>
										Покупець знаходить потрібні меблі та оформляє замовлення
										через кошик на сайті або відправляє заявку на виготовлення
										меблів на замовлення
									</li>
									<li>
										Домовляється з виробником про умови оплати та доставки,
										терміни виготовлення, монтаж
									</li>
									<li>Підписує договір з виробником</li>
									<li>
										Отримує готовий виріб доставкою або після монтажу виробу під
										замовлення
									</li>
									<li>
										Після завершення покупки задоволений клієнт залишає відгук
										та рейтинг виробнику
									</li>
								</ul>
							</div>
							<div className='partners-view__how-card'>
								<img
									className='partners-view__how-card-img'
									src={people}
									alt='img'
								/>
								<div className='partners-view__how-card-title'>
									Для виробників та продавців
								</div>
								<ul className='partners-view__how-card-text'>
									<li>Виробник створює персональну сторінку на платформі</li>
									<li>Додає товари - створює власний каталог товару</li>
									<li>
										Маркетплейс після модерації запускає в загальний доступ
										сторінку виробника
									</li>
									<li>
										Виробник отримує замовлення на готовий товар чи заявку на
										виріб індивідуального виготовлення
									</li>
									<li>
										Домовляється із клієнтом про співпрацю, підписує договір
									</li>
									<li>
										Виготовляє товар, доставляє клієнту, отримує оплату від
										клієнта
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className='partners-view__card-reverse-wrap'>
					<div className='partners-view__card-text-title-1'>
						Функціонал та дизайн маркетплейсу
					</div>
					<div className='partners-view__card-reverse container'>
						<div className='partners-view__card-text-title'>
							Функціонал та дизайн маркетплейсу
						</div>
						<div className='partners-view__card-img-wrap partners-view__card-img-slider-wrap partners-view__slider-wrap'>
							<Swiper
								className='partners-view__card-swiper'
								spaceBetween={30}
								centeredSlides={true}
								loop={true}
								autoplay={{
									delay: 3000,
									disableOnInteraction: false,
								}}
								pagination={{
									clickable: true,
								}}
								modules={[Autoplay, Pagination]}
								speed={450}
							>
								<SwiperSlide className='partners-view__card-swiper-img-wrap'>
									<img
										className='partners-view__card-swiper-img'
										onClick={() =>
											handleOpen([partners1, designSlider2, designSlider3])
										}
										src={partners1}
										alt='img'
									/>
								</SwiperSlide>
								<SwiperSlide className='partners-view__card-swiper-img-wrap'>
									<img
										className='partners-view__card-swiper-img'
										onClick={() =>
											handleOpen([designSlider2, designSlider3, partners1])
										}
										src={designSlider2}
										alt='img'
									/>
								</SwiperSlide>
								<SwiperSlide className='partners-view__card-swiper-img-wrap'>
									<img
										className='partners-view__card-swiper-img'
										onClick={() =>
											handleOpen([designSlider3, partners1, designSlider2])
										}
										src={designSlider3}
										alt='img'
									/>
								</SwiperSlide>
							</Swiper>
						</div>
						<div className='partners-view__card-text-wrap'>
							<div className='partners-view__card-text'>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Мінімалістичний дизайн
									</p>
									<p>
										Мінімалістичний дизайн сайту, щоб клієнт міг максимально
										фокусуватися на продукті, але при цьому отримував максимум
										інформації та зручності в користуванні сайтом
									</p>
								</div>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Продукт в центрі уваги
									</p>
									<p>
										Інформація в картці товару відповідає на всі запитання
										клієнта щодо продукту, що економить час клієнту і збільшує
										конверсію продажів! Картка матиме опис, характеристики
										товару, інформацію про оплату та доставку, відгуки, блок про
										виробника
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='partners-view__card-wrap'>
					<div className='partners-view__card container'>
						<div className='partners-view__card-text-wrap partners-view__card-slider-wrap partners-view__card-1-slider-wrap'>
							<div className='partners-view__card-img-wrap partners-view__card-img-slider-1-wrap partners-view__card-slider-1-wrap'>
								<Swiper
									className='partners-view__card-swiper'
									spaceBetween={30}
									centeredSlides={true}
									loop={true}
									autoplay={{
										delay: 3000,
										disableOnInteraction: false,
									}}
									pagination={{
										clickable: true,
									}}
									modules={[Autoplay, Pagination]}
									speed={450}
								>
									<SwiperSlide className='partners-view__card-swiper-img-wrap'>
										<img
											className='partners-view__card-swiper-img'
											onClick={() =>
												handleOpen([
													catalogueSlider1,
													catalogueSlider2,
													catalogueSlider3,
												])
											}
											src={catalogueSlider1}
											alt='img'
										/>
									</SwiperSlide>
									<SwiperSlide className='partners-view__card-swiper-img-wrap'>
										<img
											className='partners-view__card-swiper-img'
											onClick={() =>
												handleOpen([
													catalogueSlider2,
													catalogueSlider3,
													catalogueSlider1,
												])
											}
											src={catalogueSlider2}
											alt='img'
										/>
									</SwiperSlide>
									<SwiperSlide className='partners-view__card-swiper-img-wrap'>
										<img
											className='partners-view__card-swiper-img'
											onClick={() =>
												handleOpen([
													catalogueSlider3,
													catalogueSlider1,
													catalogueSlider2,
												])
											}
											src={catalogueSlider3}
											alt='img'
										/>
									</SwiperSlide>
								</Swiper>
							</div>
							<div className='partners-view__card-text'>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Всі меблі на одному сайті
									</p>
									<p>
										На сайті будуть представлені основні категорії меблів для
										дому, щоб клієнт міг закрити потреби в одному місці, не
										втрачаючи часу на пошуки
									</p>
								</div>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Каталог товарів, зручний пошук
									</p>
									<p>
										Дизайн, навігація, структура каталогу будуть виконані з
										максимальним фокусом на продукті для зручності клієнта.
										Розширений фільтр товару по меблях для максимальної
										відповідності пошуку товару
									</p>
								</div>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Меблі на замовлення
									</p>
									<p>
										На сайті є можливість продавати меблі на замовлення, в
										картці такого товару клієнт може замовити послугу попередньо
										відправивши запит виробнику
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='partners-view__card-reverse-wrap'>
					<div className='partners-view__card-reverse container'>
						<div className='partners-view__card-text-wrap partners-view__card-slider-wrap'>
							<div className='partners-view__card-img-wrap partners-view__card-img-slider-1-wrap partners-view__card-slider-1-wrap'>
								<Swiper
									className='partners-view__card-swiper'
									spaceBetween={30}
									centeredSlides={true}
									loop={true}
									autoplay={{
										delay: 3000,
										disableOnInteraction: false,
									}}
									pagination={{
										clickable: true,
									}}
									modules={[Autoplay, Pagination]}
									threshold={0}
									speed={450}
								>
									<SwiperSlide className='partners-view__card-swiper-img-wrap'>
										<img
											className='partners-view__card-swiper-img'
											onClick={() =>
												handleOpen([productSlider1, productSlider2])
											}
											src={productSlider1}
											alt='img'
										/>
									</SwiperSlide>
									<SwiperSlide className='partners-view__card-swiper-img-wrap'>
										<img
											className='partners-view__card-swiper-img'
											onClick={() =>
												handleOpen([productSlider2, productSlider1])
											}
											src={productSlider2}
											alt='img'
										/>
									</SwiperSlide>
								</Swiper>
							</div>
							<div className='partners-view__card-text'>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Блок про виробника
									</p>
									<p>
										В кожній картці товару буде блок про виробника, клієнт може
										перейти з картки на персональну сторінку виробника, щоб
										подивитися всі його товари
									</p>
								</div>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Персональна сторінка виробника
									</p>
									<p>
										У кожного виробника на сайті буде персональна сторінка
										(особистий міні-сайт), яка міститиме каталог товару, основну
										інформацію про виробник, відгуки, рейтинг
									</p>
								</div>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>Влог</p>
									<p>
										Розділ з корисною інформацією від виробника, де виробники
										можуть надавати корисну інформацію для клієнтів у вигляді
										відео
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='partners-view__card-reverse-wrap partners-view__card-reverse--white-wrap'>
					<div className='partners-view__card-text-title-1'>
						Внутрішній кабінет для продавців та виробників
					</div>
					<div className='partners-view__card-reverse container'>
						<div className='partners-view__card-text-title'>
							Внутрішній кабінет для продавців та виробників
						</div>
						<div className='partners-view__card-text-wrap '>
							<div className='partners-view__card-img-wrap'>
								<img
									className='partners-view__card-img'
									src={internalOffice}
									alt='img'
								/>
							</div>
							<div className='partners-view__card-text'>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Підтримка
									</p>
									<p>
										У внутрішньому кабінеті буде міститися вся необхідна
										інформація для роботи з маркетплейсом - замовлення, каталог
										товару, технічна підтримка
									</p>
								</div>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Управління каталогом
									</p>
									<p>
										Можливість у власний каталог завантажувати нові товари,
										додавати фото, опис, характеристики, ціну товару
									</p>
								</div>
								<div className='partners-view__card-text-sub-title-wrap'>
									<p className='partners-view__card-text-sub-title'>
										Замовлення та заявки
									</p>
									<p>
										Всі замовлення будуть відображатися в кабінеті для
										опрацювання, міститимуть дані про клієнта, товар, інформація
										про кількість замовлень, кількість клієнтів, сума замовлень
									</p>
								</div>
							</div>
						</div>
						<div className='partners-view__card-img-1-wrap'>
							<img
								className='partners-view__card-img'
								src={internalOffice}
								alt='img'
							/>
						</div>
					</div>
				</div>

				<div className='partners-view__form-wrap' ref={formRef}>
					<div className='partners-view__form container'>
						<div className='partners-view__form--wrap'>
							<div className='partners-view__form-info'>
								<div className='partners-view__form-info-title'>
									Співпраця з нами
								</div>
								<div className='partners-view__form-info-text'>
									Якщо Вам цікаво розміщуватися на нашій платформі та продавати
									товари, залиште свої дані, ми зв'яжемося з Вами та обговоримо
									умови співпраці.
								</div>
							</div>
							<div className='partners-view__form-input-wrap'>
								<div className='order-view__form-error-wrap'>
									<label
										className='partners-view__form-input-label'
										htmlFor='partnersViewName'
									>
										Вкажіть ваше ім`я
									</label>
									<input
										className={`partners-view__form-input ${
											isErrorName ? 'partners-view__form-error' : ''
										}`}
										onChange={(e) => setName(e.target.value)}
										value={name}
										autoComplete='name'
										id='partnersViewName'
										name='name'
										type='text'
										placeholder='Ім`я та прізвище'
									/>
									{isErrorName && (
										<div className='partners-view__form-error-text'>
											Обов'язкове поле
										</div>
									)}
								</div>

								<div
									className={`order-view__form-error-wrap ${
										isErrorPhone ? 'order-view__form-error--phone' : ''
									}`}
								>
									<label
										className='partners-view__form-input-label'
										htmlFor='partnersViewPhone'
									>
										Вкажіть ваш телефон
									</label>
									<InputPhone
										phone={phone}
										setPhone={setPhone}
										isPartnersVeiw={true}
										isErrorPhone={isErrorPhone}
									/>
									{isErrorPhone && (
										<div className='partners-view__form-error-text--phone'>
											Обов'язкове поле
										</div>
									)}
								</div>

								<div className='order-view__form-error-wrap'>
									<label
										className='partners-view__form-input-label'
										htmlFor='partnersViewEmail'
									>
										Вкажіть ваш email
									</label>
									<input
										className={`partners-view__form-input ${
											isErrorEmail ? 'partners-view__form-error' : ''
										}`}
										onChange={(e) => setEmail(e.target.value)}
										value={email}
										autoComplete='email'
										id='partnersViewEmail'
										name='email'
										type='text'
										placeholder='mail@example.com'
									/>
									{isErrorEmail && (
										<div className='partners-view__form-error-text'>
											{!email.length
												? "Обов'язкове поле"
												: 'Вкажіть, будь ласка, коректний email'}
										</div>
									)}
								</div>

								<div className='order-view__form-error-wrap'>
									<label
										className='partners-view__form-input-label'
										htmlFor='partnersViewCompanyName'
									>
										Вкажіть назву вашої компанії
									</label>
									<input
										className={`partners-view__form-input ${
											isErrorCompanyName ? 'partners-view__form-error' : ''
										}`}
										onChange={(e) => setCompanyName(e.target.value)}
										value={companyName}
										autoComplete='companyName'
										id='partnersViewCompanyName'
										name='companyName'
										type='text'
										placeholder='ТОВ, Меблі'
									/>
									{isErrorCompanyName && (
										<div className='partners-view__form-error-text'>
											Обов'язкове поле
										</div>
									)}
								</div>

								<label
									className='partners-view__form-input-label'
									htmlFor='partnersViewWebsite'
								>
									Вкажіть посилання на сайт вашої компанії
								</label>
								<input
									className='partners-view__form-input'
									onChange={(e) => setWebsite(e.target.value)}
									value={website}
									autoComplete='website'
									id='partnersViewWebsite'
									name='website'
									type='text'
									placeholder=''
								/>

								<label
									className='partners-view__form-input-label'
									htmlFor='partnersViewSocial'
								>
									Вкажіть посилання на ваші соціальні мережі
								</label>
								<input
									className='partners-view__form-input'
									onChange={(e) => setSocial(e.target.value)}
									value={social}
									autoComplete='social'
									id='partnersViewSocial'
									name='social'
									type='text'
									placeholder=''
								/>

								{(isErrorName ||
									isErrorCompanyName ||
									isErrorEmail ||
									isErrorPhone) && (
									<div className='contacts-view__form-error-banner partners-view__form-error-banner'>
										{(isErrorName || isErrorCompanyName || isErrorPhone) && (
											<div className='contacts-view__form-error-banner-text'>
												Будь ласка, заповніть всі обов'язкові поля
											</div>
										)}
										{isErrorEmail &&
											((!isErrorName && !isErrorCompanyName && !isErrorPhone) ||
												!!email.length) && (
												<div className='contacts-view__form-error-banner-text'>
													{!email.length
														? "Будь ласка, заповніть всі обов'язкові поля"
														: 'Вкажіть, будь ласка, коректний email'}
												</div>
											)}
									</div>
								)}

								<div id='howBuyViewSendBtnId' onClick={hendleSend}>
									<MainButton text={'Відправити заявку'} typeBtn={1} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='partners-view__sales-wrap'>
					<div className='partners-view__sales container'>
						<div className='partners-view__sales-title'>
							Онлайн продажі меблів
						</div>
						<div className='partners-view__sales-text'>
							Наше дослідження клієнтів відкрило нам багато інсайтів щодо того
							як споживачі шукають та купують меблі. Декілька основних
							висновків:
						</div>
						<div className='partners-view__sales-items'>
							<div className='partners-view__sales-item'>
								<img
									className='partners-view__sales-item-img'
									src={graph}
									alt='img'
								/>
								<div className='partners-view__sales-item-text'>
									Онлайн канал просування і продажів розвивається найбільше
									стрімко серед усіх каналів продажів - росте на 25% щорічно!
								</div>
							</div>
							<div className='partners-view__sales-item'>
								<img
									className='partners-view__sales-item-img'
									src={cart}
									alt='img'
								/>
								<div className='partners-view__sales-item-text'>
									Онлайн - уже основний канал реклами і просування для
									середнього і малого бізнесу
								</div>
							</div>
							<div className='partners-view__sales-item'>
								<img
									className='partners-view__sales-item-img'
									src={users}
									alt='img'
								/>
								<div className='partners-view__sales-item-text'>
									Більшість споживачів намагаються знайти меблі чи магазини
									спочатку в інтернеті для економії часу, а вже потім обирати
									наживо
								</div>
							</div>
							<div className='partners-view__sales-item'>
								<img
									className='partners-view__sales-item-img'
									src={net}
									alt='img'
								/>
								<div className='partners-view__sales-item-text'>
									Молоді споживачі переважно намагаються купувати онлайн
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PartnersView;
