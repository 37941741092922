import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const CATALOG_PAGE = '/catalog';
const PRODUCT_PAGE = '/product';

export const useScroll = () => {
	const location = useLocation();
	const savedScrollYfromLs = localStorage.getItem('scrollY');
	const savedScrollYRecords =
		savedScrollYfromLs !== null ? JSON.parse(savedScrollYfromLs) : {};

	const savedScrollY = savedScrollYRecords[location.pathname] || 0;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleScroll = () => {
		const savedScrollPositions = localStorage.getItem('scrollY')
			? JSON.parse(localStorage.getItem('scrollY') || '')
			: {};

		if (window.scrollY > 100) {
			localStorage.setItem(
				'scrollY',
				JSON.stringify({
					...savedScrollPositions,
					[location.pathname]: window.scrollY,
				})
			);
		} else {
			delete savedScrollPositions[location.pathname];
			localStorage.setItem(
				'scrollY',
				JSON.stringify({
					...savedScrollPositions,
				})
			);
		}
	};

	useEffect(() => {
		setTimeout(() => {
			window.addEventListener('scroll', handleScroll);
		}, 500);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	return { savedScrollY };
};
