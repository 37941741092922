import './UserProductsView.css';
import { useEffect, useState } from 'react';
import { Modal, Pagination, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGetData } from '../../helpers/Utils';
import UserProductsFilter from '../../components/UserProductsFilter/UserProductsFilter';
import UserProductCard from '../../components/UserProductCard/UserProductCard';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import { setIsHiddenScroll, setSearchProductTitle } from '../../store/userSlice';
import productsImg from './../../assets/products.svg';
import plus from './../../assets/plus.svg';
import filterImg from './../../assets/filterImg.svg';
import close2 from './../../assets/close2.svg';
import saveImg from './../../assets/saveImg.svg';
import search3 from './../../assets/search3.svg';
import closeImgBlack from '../../assets/closeImgBlack.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function UserProductsView() {
    const [searchParams, setSearchParams] = useSearchParams();
    const newSearchParams = new URLSearchParams(searchParams);
    const pageSearchParams = Number(searchParams.get('page'))

    const products = useSelector(state => state.userSlice.products);
    const productsCount = useSelector(state => state.userSlice.productsCount);
    const [page, setPage] = useState(pageSearchParams ? pageSearchParams : 1);
    const [isPreloader, setIsPreloader] = useState(true);
    const [isDownloadProducts, setIsDownloadProducts] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [searchTitle, setSearchTitle] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isXiaomiRedmi = /xiaomi/i.test(navigator.userAgent);

    useEffect(() => {
        if (!searchTitle?.length) {
            dispatch(setSearchProductTitle(''))
        }
    }, [searchTitle])

    const handleDownloadProducts = (boolean) => {
        if (boolean) {
            setIsPreloader(true)
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/products/csv`)
                .then(res => {
                    if (res.success && res.data) {
                        setFileUrl(res.data)
                    } else {
                        console.log('GET UserProductsViews', res)
                    }
                    setIsPreloader(false)
                })
        } else {
            deleteExcel()
        }
    }

    const handleSetPage = (num) => {
        setPage(num)
        newSearchParams.set('page', num);
        setSearchParams(newSearchParams);
    }

    const deleteExcel = () => {
        setFileUrl('')
        setIsDownloadProducts(false)
    };

    const handleOpenShoppingCart = () => {
        setIsOpenFilter(true)
        dispatch(setIsHiddenScroll(true))
    }

    const handleCloseModal = () => {
        setIsOpenFilter(false)
        dispatch(setIsHiddenScroll(false))
    }

    const handleKeyDown = (e) => {
        if(e.key == 'Enter') {
            dispatch(setSearchProductTitle(searchTitle))
        }
    }
    
    const handleSearchByName = () => {
        dispatch(setSearchProductTitle(searchTitle))
    }

    return (
        <div className="user-products">
            <SeoBlock title={"Товари"} description={""} keywords={""}/>
            {
                (isDownloadProducts && !isPreloader)  && <ModalWindow title={'Завантажити усі товари?'} handleClick={handleDownloadProducts} >
                    {!!fileUrl?.length && (
                        <a className='user-products__btn-download-link' onClick={deleteExcel} href={fileUrl} download>
                            Download File
                        </a>
                    )}
                </ModalWindow>
            }

            <Modal
                open={isOpenFilter}
                onClose={handleCloseModal}
            >
                <div className={`user-products__modal-filter-wrap ${isSafari || isXiaomiRedmi ? 'catalog-view__modal-filter-is-safari-wrap' : ''}`}>
                    <div className="catalog-view__modal-filter-header">
                        <div className="catalog-view__modal-filter-header-text">Фільтр і сортування</div>
                        <div className='user-order__modal-filter-btn-close-wrap' onClick={handleCloseModal}>
                            <img className="user-order__modal-filter-btn-close" src={close2} alt='img'/>
                        </div>
                    </div>
                    <div className={`user-products__modal-filter--wrap ${isSafari || isXiaomiRedmi ? 'catalog-view__modal-filter-is-safari-wrap' : ''}`}>
                        <UserProductsFilter setIsPreloader={setIsPreloader} page={page} setPage={setPage} handleCloseModal={handleCloseModal}/>
                    </div>
                </div>
            </Modal>

            <div className="user-products__header">
                <div className="user-products__header-title-wrap">
                    <img className="user-products__header-title-img" src={productsImg} alt='img'/>
                    <h1 className="user-products__header-title">Товари ({productsCount} товарів)</h1>
                </div>
                <div className="user-products__header-btn-wrap">
                    <button className="user-products__header-btn" onClick={() => setIsDownloadProducts(true)}>
                        <img className="creating-product__header-btn-img--save" src={saveImg} alt='img' />
                        <span>Зберегти товари</span>
                    </button>
                    <button className="user-products__header-btn" onClick={() => navigate('/auth/products/create')}>
                        <img className="user-products__header-btn-img" src={plus} alt='img'/>
                        <span>Додати товар</span>
                    </button>
                </div>
            </div>

            <div className="user-products__filter-wrap">
                <div className="user-products__filter-mob--wrap">
                    <div className="user-products__filter-mob-wrap" onClick={handleOpenShoppingCart}>
                        <img className="catalog-view__mobile-filter-btn-img" src={filterImg} alt='img'/>
                        <div className="user-order__mobile-filter-btn-text">Фільтр</div> 
                    </div>
                    <div className='user-products-filter__search-wrap'>
                        <input className='user-products-filter__search-input' value={searchTitle} onChange={(e) => setSearchTitle(e.target.value)} onKeyDown={(e) => handleKeyDown(e)} placeholder={'Назва товару'} />
                        <img className='user-products-filter__search-img' onClick={handleSearchByName} src={search3} alt='img'/>
                        {
                            !!searchTitle?.length &&
                                <img className='layout-user__header-search-btn-close' onClick={() => setSearchTitle('')} src={closeImgBlack} alt='img'/>
                        }
                    </div>
                </div>
                {
                    window.innerWidth > 768 &&
                        <div className="user-products__filter-des-wrap">
                            <UserProductsFilter setIsPreloader={setIsPreloader} page={page} setPage={setPage} />
                        </div>
                }
            </div>

            {
                !isPreloader && !!products?.length &&
                    <div className="user-products__products--wrap">
                        <div className="user-products__products-title-wrap">
                            <div className="user-products__products-title">Товари</div>
                            <div className="user-products__products-title-characteristic">Характеристики</div>
                            <div className="user-products__products-title-category">Категорія</div>
                            <div className="user-products__products-title-price">Ціна</div>
                            <div className="user-products__products-title-code">Код</div>
                        </div>
                        <div className="user-products__products-wrap">
                            {
                                products.map((el, i) => (
                                    <UserProductCard product={el} isUserProductsView={true} key={el._id}/>
                                ))
                            }
                        </div>
                        <div className="user-products__products-pagination-wrap">
                            <Stack spacing={2}>
                                <Pagination
                                    count={(productsCount % 50 === 0) ? (productsCount / 50) : Math.ceil(productsCount / 50)}
                                    defaultPage={page}
                                    page={page}
                                    onChange={(e, num) => handleSetPage(num)}
                                />
                            </Stack>
                        </div>
                    </div>
            }
            {
                isPreloader && 
                    <PreloaderCustom />
            }
            {
                !isPreloader && !products?.length &&
                    <div className="user-products__products-error">Список товарів пустий</div>
            }
        </div>
    );
}

export default UserProductsView;