import './Header.css';
import { useState, useEffect, memo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import logo from '../../assets/logo.svg';
import noPhotos from '../../assets/noPhotos.svg';
import search from '../../assets/search.svg';
import shopping11 from '../../assets/shopping11.svg';
import arrowLeft from '../../assets/arrowLeft.svg';
import arrowLeftHover from '../../assets/arrowLeftHover.svg';
import closeImg from '../../assets/closeImgBlack.svg';
import likeEllipse from '../../assets/likeEllipse.svg';
import menuImg from '../../assets/menuImg.svg';
import { NavLink, useLocation } from 'react-router-dom';
import { Drawer, Skeleton } from '@mui/material';
import SideMenu from '../SideMenu/SideMenu';
import {
	setIsOpenMenu,
	setIsOpenShoppingCart,
	setIsSearchProduct,
	setModalShoppingScrollPosition,
	setScrollPositionBeforeView,
	setSearchProducts,
	setSelectCategory,
} from '../../store/homeSlice';
import ShoppingCart from '../ShoppingCart/ShoppingCart';
import {
	disabledScroll,
	enabledScroll,
	fetchGetData,
	fetchRequest,
	handleCheckShortId,
	handleOpenNewCategories,
	handlePriceReplace,
} from '../../helpers/Utils';
import {
	NOT_DELETED_CATEGORY_ID,
	NOT_DELETED_TREND_CATEGORY_ID,
	NOT_DELETED_TREND_ALIAS,
} from '../../helpers/Config';

function Header() {
	const categoriesShop = useSelector((state) => state.homeSlice.categoriesShop);
	const shoppingProducts = useSelector(
		(state) => state.homeSlice.shoppingProducts
	);
	const headerLinks = useSelector((state) => state.homeSlice.headerLinks);
	const likeProducts = useSelector((state) => state.homeSlice.likeProducts);
	const isSearchProduct = useSelector(
		(state) => state.homeSlice.isSearchProduct
	);
	const isOpenMenu = useSelector((state) => state.homeSlice.isOpenMenu);
	const { isOpen: isOpenShoppingCart } = useSelector(
		(state) => state.homeSlice.isOpenShoppingCart
	);
	const modalShoppingScrollPosition = useSelector(
		(state) => state.homeSlice.modalShoppingScrollPosition
	);
	const [searchValue, setSearchValue] = useState('');
	const [searchValueArr, setSearchValueArr] = useState([]);
	const [historyProducts, setHistoryProducts] = useState([]);
	const [isSearchResult, setIsSearchResult] = useState(true);
	const [isOpenSubCategory, setIsOpenSubCategory] = useState(false);
	const [searchResultProducts, setSearchResultProducts] = useState([]);
	const [searchResultCategories, setSearchResultCategories] = useState([]);
	const [autocomplete, setAutocomplete] = useState([]);
	const [timerId, setTimerId] = useState(null);
	const [openCategoryId, setOpenCategoryId] = useState('');
	const [newLeft, setNewLeft] = useState(1000000);
	const [modalScrollPosition, setModalScrollPosition] = useState(0);
	const [isNotFirstRender, setIsNotFirstRender] = useState(false);
	const [isFalseSearch, setIsFalseSearch] = useState(false);
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	const isXiaomiRedmi = /xiaomi/i.test(navigator.userAgent);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const inputRef = useRef(null);

	const savedScrollYfromLs = localStorage.getItem('scrollY');
	const savedScrollYRecords =
		savedScrollYfromLs !== null ? JSON.parse(savedScrollYfromLs) : {};

	const handleRefreshSavedScroll = (path) => {
		if (savedScrollYRecords[path]) {
			delete savedScrollYRecords[path];

			localStorage.setItem('scrollY', JSON.stringify(savedScrollYRecords));
		}
	};

	useEffect(() => {
		setIsNotFirstRender(true);

		return () => {
			if (timerId) {
				clearTimeout(timerId);
			}
		};
	}, []);

	useEffect(() => {
		if (isNotFirstRender) {
			if (!isSearchProduct) {
				setSearchResultProducts([]);
				setSearchResultCategories([]);
				setAutocomplete([]);
			} else {
				let localSearchProducts = localStorage.getItem('searchProducts');
				if (localSearchProducts?.length) {
					setSearchValueArr([...JSON.parse(localSearchProducts)]);
				}

				let localHistoryProducts = localStorage.getItem('historyProducts');
				if (localHistoryProducts?.length) {
					let data = {
						ids: [...JSON.parse(localHistoryProducts).map((el) => el._id)],
					};
					fetchRequest(
						'POST',
						`${process.env.REACT_APP_BASE_URL}/products/array`,
						data
					).then((res) => {
						if (res?.success && res.data?.length) {
							setHistoryProducts(res?.data);
						}
					});
				}
			}
		}
	}, [isSearchProduct]);

	useEffect(() => {
		if (!searchValue?.length && isNotFirstRender) {
			setSearchResultProducts([]);
			setSearchResultCategories([]);
			setAutocomplete([]);
		}
	}, [searchValue]);

	useEffect(() => {
		if (isNotFirstRender) {
			setIsSearchResult(true);
		}
	}, [searchResultProducts, searchResultCategories]);

	useEffect(() => {
		if (!isOpenShoppingCart) {
			document.body.style.cssText = '';
			window.scroll({ top: modalShoppingScrollPosition });
			document.documentElement.style.scrollBehavior = '';
		}
	}, [isOpenShoppingCart]);

	const handleSortRes = (res) => {
		setSearchResultProducts([...res.filter((el) => el.type === 'products')]);
		setSearchResultCategories([
			...res.filter((el) => el.type === 'categories'),
		]);
	};

	const handleOpenCatalog = (categories) => {
		handleNullPositionCategory();
		dispatch(setSelectCategory(categories));
		handleCloseSearch();
	};

	const handleCloseSearchModal = () => {
		handleCloseSearch();
		document.body.style.overflow = 'auto';
	};

	const handleCloseSearch = () => {
		dispatch(setIsSearchProduct(false));
		enabledScroll(modalScrollPosition);
		dispatch(setScrollPositionBeforeView({}));
	};

	const handleKeyDown = (e) => {
		if (e.key == 'Enter') {
			e.preventDefault();
			handleSearch(searchValue);
		}
	};

	const handleSelectOldSearch = (str) => {
		inputRef.current.blur();
		dispatch(setIsSearchProduct(false));
		dispatch(setSearchProducts(str));
		enabledScroll(modalScrollPosition);
	};

	const handleSearch = (str) => {
		setIsSearchResult(false);
		inputRef.current.blur();
		handleCloseSearchModal();

		if (!str?.length) {
			dispatch(setSearchProducts(searchValue));
		} else {
			dispatch(setSearchProducts(str));
		}
		navigate(
			`/catalog?searchProducts=${
				!str?.length ? encodeURIComponent(searchValue) : encodeURIComponent(str)
			}`
		);
		setSearchValue('');
		setSearchResultProducts([]);
		setSearchResultCategories([]);
		setAutocomplete([]);
		setIsFalseSearch(false);
		if (str?.length) {
			if (
				searchValueArr?.length &&
				!searchValueArr.some((el) => el === str) &&
				str?.length
			) {
				localStorage.setItem(
					'searchProducts',
					JSON.stringify([str, ...searchValueArr].slice(0, 50))
				);
			} else if (!searchValueArr?.length && str?.length) {
				localStorage.setItem('searchProducts', JSON.stringify([str]));
			}
		} else {
			if (
				searchValueArr?.length &&
				!searchValueArr.some((el) => el === searchValue) &&
				searchValue?.length
			) {
				localStorage.setItem(
					'searchProducts',
					JSON.stringify([searchValue, ...searchValueArr].slice(0, 50))
				);
			} else if (!searchValueArr?.length && searchValue?.length) {
				localStorage.setItem('searchProducts', JSON.stringify([searchValue]));
			}
		}
	};

	const handleSearchResult = (str) => {
		if (str?.length) {
			fetchGetData(
				`${process.env.REACT_APP_BASE_URL}/products/search?search=${str}`
			).then((res) => {
				if (res.success && res.data) {
					handleSortRes(res.data);
					setAutocomplete(res.autocomplete);
					if (!res.data?.length && !res.autocomplete?.length) {
						setIsFalseSearch(true);
					}
				} else {
					console.log('GET Header', res);
				}
			});
		} else {
			setSearchResultProducts([]);
			setSearchResultCategories([]);
			setAutocomplete([]);
		}
	};

	const handleOpenShoppingCart = () => {
		dispatch(setIsOpenShoppingCart({ isOpen: true, isOpenByButton: true }));
		handleCloseSearchModal();
		dispatch(setModalShoppingScrollPosition(window.scrollY));
		document.body.style.cssText = `
            overflow: hidden;
            position: fixed;
            top: -${window.scrollY}px;
            left: 0;
            width: 100vw;
            padding: 0 !important;
            padding-right: ${
							window.innerWidth - document.body.offsetWidth
						}px !important
            `;
		document.documentElement.style.scrollBehavior = 'unset';
	};

	const delayedSearch = (str) => {
		setTimerId(setTimeout(() => handleSearchResult(str), 1000));
	};

	const handleChangeSearch = (str) => {
		setIsFalseSearch(false);
		setSearchValue(str);
		clearTimeout(timerId);
		delayedSearch(str);
	};

	const handleCloseDrawer = () => {
		dispatch(setIsOpenMenu(false));
		let layout = document.getElementById('layoutId');
		layout.style.cssText = ``;
	};

	const handleOpenMenu = () => {
		dispatch(setIsOpenMenu(!isOpenMenu));
		if (!isOpenMenu) {
			let layout = document.getElementById('layoutId');
			layout.style.cssText = `
                position: fixed;
                width: 100vw;
                zIndex: 1000;
                padding-right: ${
									window.innerWidth - document.body.offsetWidth
								}px !important;
                height: 100vh;
                `;
		} else {
			let layout = document.getElementById('layoutId');
			layout.style.cssText = ``;
		}
	};

	const handleOpenSearchProduct = () => {
		dispatch(setIsSearchProduct(true));
		disabledScroll(setModalScrollPosition);
	};

	const handleIsOpenSubCategory = (obj) => {
		return isOpenSubCategory &&
			openCategoryId === obj._id &&
			obj.sub_categories?.length
			? true
			: false;
	};

	const handleTouchStart = () => {
		if (isSafari && window.innerWidth < 640) {
			inputRef.current.focus();
		}
	};

	const handleBlur = () => {
		if (isSafari && window.innerWidth < 640) {
			document.activeElement.blur();
			inputRef.current.blur();
		}
	};

	const handleNullPositionCategory = () => {
		setNewLeft(1000000);
		setIsOpenSubCategory(false);
		setOpenCategoryId('');
	};

	const handlePositionCategory = (id) => {
		setIsOpenSubCategory(true);
		setOpenCategoryId(id);
		const divElement = document.getElementById(`header-${id}`);

		if (divElement) {
			const { left } = divElement.getBoundingClientRect();
			setNewLeft(left);
		}
	};

	return (
		<div
			id='headerId'
			className={`header ${isSearchProduct ? 'header--is-search' : ''}`}
		>
			{!!headerLinks?.linksValue?.length && headerLinks?.isVisible && (
				<div
					className={`header__header-links-wrap ${
						location.pathname.startsWith('/catalog')
							? 'header__header-links-wrap--catalog'
							: ''
					}`}
				>
					<div className='header__header-links--mobile--wrap'>
						<div className='header__header-links--mobile-wrap'>
							<div className='header__header-links-mobile'>
								{!!headerLinks?.linksValue[0] &&
								headerLinks.linksValue[0].links ? (
									<a href={headerLinks.linksValue[0].links} target='_blank'>
										{headerLinks.linksValue[0].text}
									</a>
								) : (
									<div>{headerLinks.linksValue[0].text}</div>
								)}
							</div>
							<div className='header__header-links-mobile header__header-links-mobile--1'>
								{!!headerLinks?.linksValue[1]?.length && (
									<>
										{!!headerLinks.linksValue[1].links?.length ? (
											<a href={headerLinks.linksValue[1].links} target='_blank'>
												{headerLinks.linksValue[1].text}
											</a>
										) : (
											<div>{headerLinks.linksValue[1].text}</div>
										)}
									</>
								)}
							</div>
						</div>
					</div>
					<div className='header__header-links--wrap'>
						{headerLinks.linksValue.map((el) => (
							<div key={el.text}>
								{!!el.links?.length ? (
									<a
										className='header__header-links'
										href={el.links}
										target='_blank'
									>
										{el.text}
									</a>
								) : (
									<div className='header__header-text'>{el.text}</div>
								)}
							</div>
						))}
					</div>
				</div>
			)}
			<div
				className={`header-wrap container ${
					location.pathname.startsWith('/catalog')
						? !!headerLinks?.linksValue?.length && headerLinks?.isVisible
							? 'header-wrap--catalog1'
							: 'header-wrap--catalog2'
						: ''
				}`}
			>
				<div
					className={`header__menu--wrap ${
						location.pathname.startsWith('/catalog')
							? !!headerLinks?.linksValue?.length && headerLinks?.isVisible
								? 'header__menu--wrap--catalog1'
								: 'header__menu--wrap--catalog2'
							: ''
					}`}
				>
					<div className='header__menu-wrap'>
						<div className='header__menu-text-wrap'>
							<div
								className={isOpenMenu ? 'header__menu' : 'no_header__menu'}
								onClick={handleOpenMenu}
							>
								<img
									className='header__menu-img-close'
									src={closeImg}
									alt='img'
								/>
							</div>
							<div
								className={isOpenMenu ? 'no_header__menu' : 'header__menu'}
								onClick={handleOpenMenu}
							>
								<img className='header__menu-img' src={menuImg} alt='img' />
							</div>
							<div className='header__menu-text'>МЕНЮ</div>
						</div>

						<div className='header__search--wrap'>
							<img
								className='header__search-img'
								onClick={handleOpenSearchProduct}
								src={search}
								alt='img'
							/>

							{isSearchProduct && (
								<div className='header__search-wrap'>
									<div
										className='header__search-close'
										onClick={handleCloseSearchModal}
									></div>
									{!!searchValue?.length && (
										<div
											className='header__search-close-btn-wrap'
											onClick={() => setSearchValue('')}
										>
											<img
												className='header__search-close-btn'
												src={closeImg}
												alt='img'
											/>
										</div>
									)}
									<div className='header__search-input-wrap'>
										<svg
											className='header__search-btn-arrow'
											onClick={handleCloseSearchModal}
											viewBox='0 0 24 24'
											xmlns='http://www.w3.org/2000/svg'
										>
											<title />
											<g id='Complete'>
												<g id='arrow-left'>
													<g>
														<polyline
															data-name='Right'
															fill='none'
															id='Right-2'
															points='7.6 7 2.5 12 7.6 17'
															stroke='#000000'
															strokeLinecap='round'
															strokeLinejoin='round'
															strokeWidth='2'
														/>
														<line
															fill='none'
															stroke='#000000'
															strokeLinecap='round'
															strokeLinejoin='round'
															strokeWidth='2'
															x1='21.5'
															x2='4.8'
															y1='12'
															y2='12'
														/>
													</g>
												</g>
											</g>
										</svg>
										<input
											className='header__search-input'
											autoComplete='off'
											value={searchValue}
											onChange={(e) => handleChangeSearch(e.target.value)}
											onKeyDown={(e) => handleKeyDown(e)}
											onFocus={() => handleTouchStart()}
											onBlur={() => handleBlur()}
											placeholder='Пошук товарів'
											ref={inputRef}
										/>
										<NavLink
											onClick={handleSearch}
											to={`/catalog?searchProducts=${encodeURIComponent(
												searchValue
											)}`}
										>
											<img
												className='header__search-input-img'
												src={search}
												alt='img'
											/>
										</NavLink>

										{isSearchResult && (
											<div
												id='searchResultWrapId'
												onTouchStart={() => handleBlur()}
												className={`header__search-history-wrap `}
											>
												{!searchValue?.length ||
												(!!searchValue?.length &&
													!autocomplete?.length &&
													!searchResultProducts?.length &&
													!searchResultCategories?.length) ? (
													<>
														{isFalseSearch ? (
															<div className='header__search-history-error'>
																За вашим запитом нічого не знайдено
															</div>
														) : (
															<>
																{!!searchValueArr?.length ? (
																	<div className='header__search-history-result-history-wrap'>
																		<div
																			className={`header__search-history-result--wrap ${
																				isSafari || isXiaomiRedmi
																					? 'header__search-history-is-safari-wrap'
																					: ''
																			}`}
																		>
																			<h4 className='header__search-history-title'>
																				Попередній пошук
																			</h4>
																			{searchValueArr.map((el, i) => (
																				<NavLink
																					className='header__search-history-result'
																					onClick={() =>
																						handleSelectOldSearch(el)
																					}
																					to={`/catalog?searchProducts=${encodeURIComponent(
																						el
																					)}`}
																					key={el + i}
																				>
																					{el}
																				</NavLink>
																			))}
																		</div>
																	</div>
																) : (
																	<div
																		className={`header__search-history-error ${
																			!!historyProducts?.length
																				? 'header__search-history-error--history-products'
																				: ''
																		}`}
																	>
																		У вас немає історії пошуку
																	</div>
																)}
																{!!historyProducts?.length && (
																	<div
																		className={`header__search-history-product--wrap ${
																			searchValueArr?.length
																				? 'header__search-history-product--wrap-full'
																				: ''
																		}`}
																	>
																		<h4 className='header__search-history-title'>
																			Переглянуті товари
																		</h4>
																		<div className='header__search-history-product-wrap'>
																			{historyProducts.map((el) => (
																				<NavLink
																					className='header__search-history-product'
																					onClick={() => handleCloseSearch()}
																					to={handleCheckShortId(el)}
																					key={el._id}
																				>
																					{!!el.images?.length ? (
																						<img
																							className='header__search-history-product-img'
																							src={el.images[0]}
																							alt='img'
																						/>
																					) : (
																						<img
																							className='header__search-history-product-img-none'
																							src={noPhotos}
																							alt='img'
																						/>
																					)}
																					<div className='header__search-history-product-text'>
																						{el.title}
																					</div>
																					<div className='header__search-history-product-text'>
																						{handlePriceReplace(el.price)}грн{' '}
																						{!!el.retail_type?.length
																							? el.retail_type
																							: ''}
																					</div>
																					{el.is_deleted && (
																						<div className='header__search-history-product-delete-text'>
																							Видалено
																						</div>
																					)}
																				</NavLink>
																			))}
																		</div>
																	</div>
																)}
															</>
														)}
													</>
												) : (
													<div
														className={`header__search-history-result-search-wrap `}
													>
														<div
															className={`header__search-history-result-wrap ${
																isSafari || isXiaomiRedmi
																	? 'header__search-history-is-safari-wrap'
																	: ''
															}`}
														>
															{!!autocomplete?.length && (
																<div className='header__search-history-result-autocomplete-wrap'>
																	{autocomplete.map((el, i) => (
																		<NavLink
																			className='header__search-history-result-autocomplete'
																			onClick={() => handleSearch(el)}
																			key={el + i}
																			to={`/catalog?searchProducts=${encodeURIComponent(
																				el
																			)}`}
																		>
																			<img
																				className='header__search-history-result-autocomplete-img'
																				src={search}
																				alt='img'
																			/>
																			<p>{el}</p>
																		</NavLink>
																	))}
																</div>
															)}
															{!!searchResultProducts?.length &&
																searchResultProducts.map((el, i) => (
																	<NavLink
																		className='header__search-history-result'
																		onClick={() => handleCloseSearch()}
																		key={el._id}
																		to={handleCheckShortId(el)}
																	>
																		{!!el.image?.length ? (
																			<img
																				className='header__search-history-result-img'
																				src={el.image}
																				alt='img'
																			/>
																		) : (
																			<img
																				className='header__search-history-result-img'
																				src={noPhotos}
																				alt='img'
																			/>
																		)}
																		<div className='header__search-history-result-text-wrap'>
																			<div className='header__search-history-result-text'>
																				{el.name}
																			</div>
																			{el.price && (
																				<div className='header__search-history-result-text'>
																					{handlePriceReplace(el.price)}грн.
																				</div>
																			)}
																		</div>
																	</NavLink>
																))}
															{!!searchResultCategories?.length && (
																<>
																	<div className='header__search-history-result-sub-title'>
																		Пошук у категоріях:
																	</div>
																	{searchResultCategories.map((el, i) => (
																		<NavLink
																			className='header__search-history-result header__search-history-result-category'
																			key={el._id}
																			onClick={() => handleOpenCatalog(el)}
																			to={handleOpenNewCategories(
																				el,
																				categoriesShop
																			)}
																		>
																			<p>{el.name}</p>
																			<img
																				className='header__search-history-result-category-arrow'
																				src={arrowLeft}
																				alt='img'
																			/>
																			<img
																				className='header__search-history-result-category-arrow-hover'
																				src={arrowLeftHover}
																				alt='img'
																			/>
																		</NavLink>
																	))}
																</>
															)}
															{!autocomplete?.length &&
																!searchResultProducts?.length &&
																!searchResultCategories?.length &&
																!!searchValue?.length && (
																	<div className='header__search-history-error'>
																		За вашим запитом нічого не знайдено
																	</div>
																)}
														</div>
													</div>
												)}
											</div>
										)}
									</div>
								</div>
							)}
						</div>
					</div>

					<div className='header__logo-wrap'>
						<NavLink
							className='header__logo-link'
							onClick={handleCloseSearch}
							to='/'
						>
							<img className='header__logo' src={logo} alt='logo' />
						</NavLink>
					</div>

					<div className='header__right'>
						<div className='header__shopping-img-wrap'>
							<NavLink
								className='header__shopping-img--wrap'
								onClick={handleCloseSearch}
								to='favorite'
							>
								<img className='header__like-img' src={likeEllipse} alt='img' />
								{!!likeProducts?.length && (
									<div className='header__count-circle header__count-like'>
										{likeProducts?.length}
									</div>
								)}
							</NavLink>
							<div
								className='header__shopping-img--wrap'
								onClick={handleOpenShoppingCart}
							>
								<img
									className='header__shopping-img'
									src={shopping11}
									alt='img'
								/>
								{!!shoppingProducts?.length && (
									<div className=' header__count-circle header__count-shopping'>
										{shoppingProducts?.length}
									</div>
								)}
							</div>
							{isOpenShoppingCart && <ShoppingCart />}
						</div>
					</div>

					<div className='header__menu-side-menu-wrap'>
						<Drawer
							anchor='left'
							open={isOpenMenu}
							onClose={() => handleCloseDrawer()}
							className={`header__menu-side-menu--wrap ${
								!!headerLinks?.linksValue?.length && headerLinks?.isVisible
									? 'header__menu-side-menu--is-link--wrap'
									: ''
							}`}
						>
							<div className='header__menu-side-menu'>
								<SideMenu />
							</div>
						</Drawer>
					</div>
				</div>
				<div className='header__categories-wrap'>
					{!!categoriesShop?.length ? (
						categoriesShop.map((el, index) => (
							<div
								key={el._id + index}
								style={{ order: `${el?.priority ? el.priority : 100}` }}
							>
								{el._id === NOT_DELETED_TREND_CATEGORY_ID ? (
									<div
										onMouseEnter={() => handlePositionCategory(el._id)}
										onMouseLeave={handleNullPositionCategory}
										className='header__categories'
										id={`header-${el._id}`}
									>
										<NavLink
											className='header__categories-link'
											to={`/catalog/trend/${NOT_DELETED_TREND_ALIAS}`}
										>
											{el.name}
										</NavLink>
									</div>
								) : (
									<div
										onMouseEnter={() => handlePositionCategory(el._id)}
										onMouseLeave={handleNullPositionCategory}
										className='header__categories'
										id={`header-${el._id}`}
									>
										{el._id === NOT_DELETED_CATEGORY_ID ? (
											<div
												className={`header__categories-link ${
													handleIsOpenSubCategory(el)
														? 'header__categories-title'
														: ''
												}`}
												onClick={() => handleNullPositionCategory()}
											>
												{el.name}
											</div>
										) : (
											<NavLink
												className={`header__categories-link ${
													handleIsOpenSubCategory(el)
														? 'header__categories-title'
														: ''
												}`}
												onClick={() => {
													handleRefreshSavedScroll(
														handleOpenNewCategories(el, categoriesShop)
													);
													handleNullPositionCategory();
												}}
												to={handleOpenNewCategories(el, categoriesShop)}
											>
												{el.name}
											</NavLink>
										)}
										{!!el.sub_categories?.length && (
											<svg
												className='header__categories-img'
												width='12'
												height='5'
												viewBox='0 0 12 5'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													id='Drop down'
													d='M6.01999 5C5.94002 5 5.82006 4.96417 5.74008 4.92834L0.141812 0.628694C-0.0181385 0.485373 -0.0581261 0.27039 0.101824 0.127069C0.261775 -0.0162529 0.501701 -0.052083 0.661651 0.0912385L6.01999 4.1759L11.3383 0.0912385C11.4983 -0.052083 11.7382 -0.0162529 11.8982 0.127069C12.0581 0.27039 12.0181 0.521203 11.8582 0.628694L6.25992 4.92834C6.21993 4.96417 6.09997 5 6.01999 5Z'
													fill={
														handleIsOpenSubCategory(el) ? '#2A7F77' : '#19191D'
													}
												/>
											</svg>
										)}
										{!!el.sub_categories?.length && (
											<div
												className='header__sub-categories--wrap'
												style={{ left: `-${newLeft}px` }}
											>
												<div className='header__sub-categories-wrap'>
													{el.sub_categories.map((ell) => (
														<NavLink
															className='header__sub-categories'
															style={{
																order: `${ell?.priority ? ell.priority : 100}`,
															}}
															onClick={() => {
																handleRefreshSavedScroll(
																	handleOpenNewCategories(ell, categoriesShop)
																);
																handleNullPositionCategory();
															}}
															to={handleOpenNewCategories(ell, categoriesShop)}
															key={ell._id}
														>
															{ell.name}
														</NavLink>
													))}
												</div>
												<div
													className='header__sub-categories-background'
													onMouseEnter={handleNullPositionCategory}
												></div>
											</div>
										)}
									</div>
								)}
							</div>
						))
					) : (
						<>
							<Skeleton className='header__skeleton-categories' />
							<Skeleton className='header__skeleton-categories' />
							<Skeleton className='header__skeleton-categories' />
							<Skeleton className='header__skeleton-categories' />
							<Skeleton className='header__skeleton-categories' />
							<Skeleton className='header__skeleton-categories' />
							<Skeleton className='header__skeleton-categories' />
							<Skeleton className='header__skeleton-categories' />
							<Skeleton className='header__skeleton-categories' />
							<Skeleton className='header__skeleton-categories' />
							<Skeleton className='header__skeleton-categories' />
							<Skeleton className='header__skeleton-categories' />
							<Skeleton className='header__skeleton-categories' />
							<Skeleton className='header__skeleton-categories' />
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default memo(Header);
