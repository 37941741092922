import './CheckboxWrap.css';
import { useEffect, useState } from 'react';

function CheckboxWrap({characteristic={}, setCharacteristicsOptions=null}) {
    const [is_new_value_blocked, setIs_new_value_blocked] = useState(typeof characteristic.is_new_value_blocked === 'undefined' ? true : characteristic.is_new_value_blocked);
    const [is_few_values_allowed, setIs_few_values_allowed] = useState(characteristic.is_few_values_allowed);
    const [is_required_input, setIs_required_input] = useState(characteristic.is_required_input);
    const [is_hide_in_filter, setIs_hide_in_filter] = useState(typeof characteristic.is_hide_in_filter === 'undefined' ? false : characteristic.is_hide_in_filter);

    useEffect(() => {
        if (setCharacteristicsOptions) {
            setCharacteristicsOptions((prevValue) => {
                let arr = prevValue.map((el, i) => {
                    if (el.name === characteristic.name) {
                        el = {...el, is_hide_in_filter: is_hide_in_filter, is_new_value_blocked: is_new_value_blocked, is_few_values_allowed: is_few_values_allowed, is_required_input: is_required_input}
                        return el;
                    }
                    return el;
                });
    
                return [...arr]
            })
        }
    }, [is_new_value_blocked, is_few_values_allowed, is_required_input, is_hide_in_filter])

    return (
        <div className='checkbox-wrap__wrap'>
            <label className="catalog-view__products-filter-label">
                <input className="catalog-view__products-filter-checkbox" type="checkbox" value={is_new_value_blocked} onChange={() => setIs_new_value_blocked(!is_new_value_blocked)} />
                <div className={`catalog-view__products-filter-checkbox-custom ${is_new_value_blocked ? 'catalog-view__products-filter-checkbox-custom-active' : ''}`}></div>
                <div>Своє значення</div>
            </label>
            <label className="catalog-view__products-filter-label">
                <input className="catalog-view__products-filter-checkbox" type="checkbox" value={is_few_values_allowed} onChange={() => setIs_few_values_allowed(!is_few_values_allowed)} />
                <div className={`catalog-view__products-filter-checkbox-custom ${is_few_values_allowed ? 'catalog-view__products-filter-checkbox-custom-active' : ''}`}></div>
                <div>Декілька значень</div>
            </label>
            <label className={`catalog-view__products-filter-label ${(!characteristic.value?.length && !is_required_input && !is_new_value_blocked) ? 'disabledContainer' : ''}`}>
                <input className="catalog-view__products-filter-checkbox" type="checkbox" value={is_required_input} onChange={() => setIs_required_input(!is_required_input)} />
                <div className={`catalog-view__products-filter-checkbox-custom ${is_required_input ? 'catalog-view__products-filter-checkbox-custom-active' : ''}`}></div>
                <div>Обов'язкове</div>
            </label>
            <label className="catalog-view__products-filter-label">
                <input className="catalog-view__products-filter-checkbox" type="checkbox" value={is_hide_in_filter} onChange={() => setIs_hide_in_filter(!is_hide_in_filter)} />
                <div className={`catalog-view__products-filter-checkbox-custom ${is_hide_in_filter ? 'catalog-view__products-filter-checkbox-custom-active' : ''}`}></div>
                <div>Приховати у фільтрі</div>
            </label>
        </div>
    );
}

export default CheckboxWrap;