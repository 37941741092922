import './ContextMenuProduct.css';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { setEditeProduct } from '../../store/userSlice';
import threedots from '../../assets/threedots.svg';
import { useDispatch } from 'react-redux';

function ContextMenuProduct({product, handleClickMenu}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])
    
    const handleScroll = () => {
        setAnchorEl(null)
    };
    
    const handleClickSettings = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClick = (str) => {
        handleClickMenu(product, str)
        setAnchorEl(null);
    };

    const handleOpenProduct = () => {
        navigate(`/auth/products/${product._id}`)
        dispatch(setEditeProduct(product))
    }
    return (
        <div className="context-menu-product">
            <Button
                id={`basic-button-${product._id}`}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickSettings}
                className='context-menu-product__btn'
                disableRipple={true}
                >
                <img className='context-menu__btn-img' src={threedots} alt='img'/>
            </Button>
            <Menu
                id={`basic-menu-${product._id}`}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                className='context-menu-product__btn-select'
                MenuListProps={{
                'aria-labelledby': `basic-button-${product._id}`,
                }}
            >
                <MenuItem className='context-menu-product__btn-select-item' disableRipple={true} onClick={handleOpenProduct} >Редагувати</MenuItem>
                <MenuItem className='context-menu-product__btn-select-item' disableRipple={true} onClick={() => handleClick('hide')}>{ product.is_hide ? 'Відновити' : 'Приховати'}</MenuItem>
                <MenuItem className='context-menu-product__btn-select-item' disableRipple={true} onClick={() => handleClick('delete')}>Видалити</MenuItem>
            </Menu>
            
        </div>
    );
}

export default ContextMenuProduct;