import './SeoBlock.css';
import { useEffect } from 'react';
import Helmet from 'react-helmet';

const PRODUCTION_URL = 'www.vyroby.com';

function SeoBlock({ title, description, keywords, isCanonical, image }) {
	const hostName = window.location.hostname;

	useEffect(() => {
		handleDeleteMeta();
	}, []);

	const handleDeleteMeta = () => {
		const titleId = document.getElementById('ogTitleId');
		const descriptionId = document.getElementById('ogDescriptionId');
		const imageId = document.getElementById('ogImageId');

		if (titleId) {
			titleId.remove();
		}
		if (descriptionId) {
			descriptionId.remove();
		}
		if (imageId) {
			imageId.remove();
		}
	};

	return (
		<Helmet defer={false}>
			<title>
				{title?.length
					? title
					: 'Платформа меблів від локальних виробників України — https://vyroby.com'}
			</title>
			<meta
				name='description'
				content={
					description?.length
						? description
						: 'VYROBY - Платформа меблів від локальних виробників України'
				}
			/>
			{PRODUCTION_URL !== hostName && (
				<meta name='robots' content='noindex, nofollow' />
			)}
			{keywords?.length && <meta name='keywords' content={keywords} />}
			{isCanonical && <link rel='canonical' href={window.location.href} />}
			<meta property='og:url' content={window.location.href} />
			<meta
				property='og:site_name'
				content='Платформа меблів від локальних виробників України — https://vyroby.com'
			/>
			<meta
				property='og:title'
				content={
					title?.length
						? title
						: 'Платформа меблів від локальних виробників України — https://vyroby.com'
				}
			/>
			<meta
				property='og:description'
				content={
					description?.length
						? description
						: 'VYROBY - Платформа меблів від локальних виробників України'
				}
			/>

			{!!image?.length && <meta property='og:image' content={image} />}
		</Helmet>
	);
}

export default SeoBlock;
