import './HomeView.css';
import { useEffect, useMemo, useState } from 'react';
import MainBanner from '../../components/MainBanner/MainBanner';
import AboutMarketplace from '../../components/AboutMarketplace/AboutMarketplace';
import { NavLink, useLocation } from 'react-router-dom';
import BeSeller from '../../components/BeSeller/BeSeller';
import CategoryCards from '../../components/CategoryCards/CategoryCards';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { fetchGetData } from '../../helpers/Utils';
import { useDispatch, useSelector } from 'react-redux';
import {
	setBanners,
	setBlocks,
	setIsScrollSlider,
	setIsViewPreloader,
	setLastUpdatedHomeView,
	setOldModalScrollPosition,
	setSelectBlock,
} from '../../store/homeSlice';
import ProductCard from '../../components/ProductCard/ProductCard';
import ProducersBlock from '../../components/ProducersBlock/ProducersBlock';
import { NOT_DELETED_TREND_ALIAS } from '../../helpers/Config';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function HomeView() {
	const lastUpdatedHomeView = useSelector(
		(state) => state.homeSlice.lastUpdatedHomeView
	);
	const seo = useSelector((state) => state.homeSlice.seo);
	const banners = useSelector((state) => state.homeSlice.banners);
	const blocks = useSelector((state) => state.homeSlice.blocks);
	const scrollPositionBeforeView = useSelector(
		(state) => state.homeSlice.scrollPositionBeforeView
	);
	const [isNotFirstRender, setIsNotFirstRender] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		setIsNotFirstRender(true);
		if (
			!lastUpdatedHomeView ||
			new Date().getTime() - lastUpdatedHomeView > 300000
		) {
			fetchGetData(
				`${process.env.REACT_APP_BASE_URL}/blocks/all?not_needed_alias=${NOT_DELETED_TREND_ALIAS}`
			).then((res) => {
				if (res.success && res.data) {
					let resArr = res.data.filter(
						(el) => el.alias !== NOT_DELETED_TREND_ALIAS
					);
					dispatch(setBlocks(resArr?.length ? [...resArr] : []));
					dispatch(setLastUpdatedHomeView(new Date().getTime()));
				} else {
					console.log('GET HomeView', res);
				}
				setTimeout(() => {
					dispatch(setIsViewPreloader(false));
				}, 10);
			});
		} else {
			dispatch(setIsViewPreloader(false));
		}

		handleGetBanners();

		localStorage.removeItem('scrollY');

		setTimeout(() => {
			window.scroll({
				top: scrollPositionBeforeView[location.pathname]
					? scrollPositionBeforeView[location.pathname]
					: 0,
			});
		}, 10);

		return () => {
			dispatch(setIsScrollSlider(false));
			dispatch(setOldModalScrollPosition(0));
		};
	}, []);

	const handleSelectBlock = (block) => {
		dispatch(setSelectBlock(block));
	};

	const handleGetBanners = () => {
		fetchGetData(
			`${process.env.REACT_APP_BASE_URL}/configurations?isBanners=${true}`
		).then((res) => {
			if (res.success && res.data) {
				dispatch(setBanners(res.data.banners?.length ? res.data.banners : []));
			} else {
				console.log('GET Layout', res);
			}
		});
	};

	const handleNextVariation = (obj, oldProductId, blocksId) => {
		if (blocks?.length) {
			let res = blocks.map((el) => {
				if (el._id === blocksId) {
					el = {
						...el,
						products: el.products.map((ell) => {
							if (ell._id === oldProductId) {
								ell = obj;
								return ell;
							}
							return ell;
						}),
					};
					return el;
				}
				return el;
			});

			dispatch(setBlocks(res));
		}
	};

	const handleNextPage = (id) => {
		let page = Math.floor(
			blocks.find((el) => el._id === id).products?.length / 4
		);

		fetchGetData(
			`${process.env.REACT_APP_BASE_URL}/blocks/${id}?page=${page}`
		).then((res) => {
			if (res.success && res.data) {
				dispatch(
					setBlocks(
						blocks.map((el) => {
							if (el._id === res.data._id) {
								el = {
									...res.data,
									products: [...el.products, ...res.data.products],
								};
							}
							return el;
						})
					)
				);
			} else {
				console.log('GET HomeView', res);
			}
		});
	};

	const handlePageCount = useMemo(() => {
		return (num) => {
			if (num !== 0) {
				return num % 4 === 0 ? num / 4 : Math.ceil(num / 4);
			}
		};
	}, [blocks]);

	return (
		<div className='home-view'>
			<SeoBlock
				title={seo.title_home}
				description={seo.description_home}
				keywords={seo.keywords_home}
			/>
			<div className='home-view__title-wrap'>
				{!!banners?.length ? (
					<Swiper
						spaceBetween={30}
						centeredSlides={true}
						pagination={{
							clickable: true,
						}}
						autoplay={{
							delay: 6000555,
						}}
						modules={[Autoplay, Navigation, Pagination]}
						className='home-view__title-banner'
						speed={450}
					>
						{banners.map((el) => (
							<SwiperSlide
								className='home-view__title-banner-slide-wrap'
								key={el.image}
							>
								<NavLink
									className='home-view__title-banner-link'
									to={!!el.link?.length ? el.link : ''}
								>
									<img
										className='home-view__title-img'
										src={el.image}
										alt='img'
									/>
									{!!el.text?.length && (
										<div className='home-view__banner-text-wrap home-view__banner-text-mob-wrap'>
											<div className='home-view__banner-text'>{el.text}</div>
											<div className='home-view__banner-text-btn'>
												Переглянути
											</div>
										</div>
									)}
								</NavLink>
							</SwiperSlide>
						))}
					</Swiper>
				) : (
					<div className='home-view__title-img'></div>
				)}
			</div>

			<div className='home-view-wrap container'>
				<div className='home-view__info-wrap'>
					<div className='home-view__info'>
						<div className='home-view__info-text-wrap'>
							<h1 className='home-view__info-title'>Сучасні меблі для дому</h1>
							<h2 className='home-view__info-text'>
								В одному місці ми зібрали широкий асортимент сучасних, якісних
								меблів для Вашого дому
							</h2>
						</div>
					</div>
					<div className='home-view__info'>
						<div className='home-view__info-text-wrap'>
							<div className='home-view__info-title'>Українські виробники</div>
							<h2 className='home-view__info-text'>
								У нас представлені українські локальні та крафтові виробники, з
								якими Ви можете працювати безпосередньо
							</h2>
						</div>
					</div>
					<div className='home-view__info'>
						<div className='home-view__info-text-wrap'>
							<div className='home-view__info-title'>
								Кастомізуй меблі під себе
							</div>
							<h2 className='home-view__info-text'>
								В більшості виробів ви можете змінити розмір, колір, матеріал чи
								інші характеристики товару за вашими побажаннями
							</h2>
						</div>
					</div>
					<div className='home-view__info'>
						<div className='home-view__info-text-wrap'>
							<div className='home-view__info-title'>
								Індивідуальне виробництво
							</div>
							<h2 className='home-view__info-text'>
								На нашому сайті Ви можете замовити меблі індивідуального
								виробництва за Вашим проєктом, фото чи ескізом
							</h2>
						</div>
					</div>
				</div>

				<div className='home-view__banner-wrap'>
					<MainBanner />
				</div>

				{!!blocks?.length && (
					<div className='home-view__cards-wrap'>
						{blocks.map((el) => (
							<div
								className={`${!el.products?.length ? 'displayNone' : ''}`}
								style={{ order: `${el.position}` }}
								key={el._id}
							>
								<div className='home-view__cards-title-wrap'>
									<div className='home-view__cards-title'>{el.title}</div>
									<NavLink
										className='home-view__cards-catalog-btn'
										onClick={() => handleSelectBlock(el)}
										to={`/catalog/trend/${el.alias}`}
									>
										В каталог
									</NavLink>
								</div>
								<div className='home-view__cards'>
									{!!el.products?.length &&
										el.products.map((ell, i) => (
											<div className='home-view__card-wrap' key={ell + i}>
												<ProductCard
													product={ell}
													handleNextVariation={handleNextVariation}
													blocksId={el._id}
												/>
											</div>
										))}
								</div>
								{handlePageCount(el.count) >
									handlePageCount(el.products?.length) && (
									<div className='home-view__card-btn'>
										<button
											className='main-btn-2'
											onClick={() => handleNextPage(el._id)}
										>
											Показати більше
										</button>
									</div>
								)}
							</div>
						))}
					</div>
				)}

				<CategoryCards />

				<div className='home-view__about-work-title'>Як купувати</div>
				<div className='home-view__about-work-items'>
					<div className='home-view__about-work-item'>
						<div className='home-view__about-work-item-title'>1</div>
						<div className='home-view__about-work-item-text'>
							Знаходьте потрібні меблі та оформляйте замовлення через кошик на
							сайті або залишайте заявку для консультації
						</div>
					</div>
					<div className='home-view__about-work-item'>
						<div className='home-view__about-work-item-title'>2</div>
						<div className='home-view__about-work-item-text'>
							Домовляєтеся з продавцем про умови оплати та доставки, терміни
							виготовлення, монтаж
						</div>
					</div>
					<div className='home-view__about-work-item'>
						<div className='home-view__about-work-item-title'>3</div>
						<div className='home-view__about-work-item-text'>
							Отримуєте готовий виріб доставкою поштою або виробником, у разі
							монтажу виробу
						</div>
					</div>
					<div className='home-view__about-work-item'>
						<div className='home-view__about-work-item-title'>4</div>
						<div className='home-view__about-work-item-text'>
							Після завершення покупки залишайте відгук та рейтинг виробнику
						</div>
					</div>
				</div>
				<div className='home-view__be-seller-des-wrap'>
					<BeSeller />
				</div>
				<div className='home-view__producers-block-des-wrap'>
					<ProducersBlock />
				</div>
				<div className='home-view__about-marketplace-wrap home-view__about-marketplace-des-wrap'>
					<AboutMarketplace />
				</div>
			</div>
			{window.innerWidth < 640 && (
				<>
					<div className='home-view__be-seller-mob-wrap'>
						<BeSeller />
					</div>
					<div className='home-view__producers-block-mob-wrap container'>
						<ProducersBlock />
					</div>
					<div className='home-view__about-marketplace-wrap home-view__about-marketplace-mob-wrap'>
						<AboutMarketplace />
					</div>
				</>
			)}
		</div>
	);
}

export default HomeView;
