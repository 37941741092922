import './UserBlocksView.css';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBlocks, setEditeBlock, setShowMessageObj } from '../../store/userSlice';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import deleteImg3 from './../../assets/deleteImg3.svg';
import plus from './../../assets/plus.svg';
import productsImg from './../../assets/products.svg';
import editIcon1 from './../../assets/editIcon1.svg';
import { fetchGetData, fetchRequest } from '../../helpers/Utils';
import { NOT_DELETED_TREND_ALIAS } from '../../helpers/Config';
import { NavLink } from 'react-router-dom';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function UserBlocksView() {
    const blocks = useSelector(state => state.userSlice.blocks);
    const [isDeleteBlock, setIsDeleteBlock] = useState(false);
    const [deleteBlockId, setDeleteBlockId] = useState('');
    const [notDeleteBlock, setNotDeleteBlock] = useState([]);
    const [isPreloader, setIsPreloader] = useState(true);
    const dispatch = useDispatch();
    let token = localStorage.getItem('token')

    useEffect(() => {
        setIsPreloader(true)
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/blocks/all?is_admin=${true}`)
            .then(res => {
                if (res.success && res.data) {
                    setNotDeleteBlock(res.data.filter(ell => ell.alias === NOT_DELETED_TREND_ALIAS))
                    dispatch(getBlocks([...res.data.filter(ell => ell.alias !== NOT_DELETED_TREND_ALIAS).sort((a,b) => a.position - b.position)]))
                } else {
                    console.log('GET UserBlocksView', res)
                }
               setIsPreloader(false)
            })
    }, [])

    const handleUpdateBlock = (obj) => {
        dispatch(setEditeBlock(obj))
    }
   
    const handleDeleteBlock = (id) => {
        setIsDeleteBlock(true)
        setDeleteBlockId(id)
    }
    
    const handleIsDeleteBlock = (boolean) => {
        if (boolean) {
            setIsPreloader(true)
            let data = {
            }
            fetchRequest('DELETE', `${process.env.REACT_APP_BASE_URL}/blocks/${deleteBlockId}?token=${token}`, data, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(getBlocks(blocks.filter(el => el._id !== deleteBlockId)))
                        dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                    } else {
                        console.log('DELETE UserBlocksView:', res)
                    }
                    setIsPreloader(false)
                })
        }
        
        setIsDeleteBlock(false)
        setDeleteBlockId('')
    }

    const handleNewPosition = (obj, i, num) => {
        if ((i === 0 && num === -1) || ((i + 1) === blocks.length && num === 1)) {
            return
        }
        let filteredItems = blocks.filter(el => el._id !== obj._id);
        filteredItems.splice(i + num, 0, obj);
        dispatch(getBlocks([...filteredItems]))
        let data = {   
            positionArr: [...filteredItems.map((el, i) => {
                return {_id: el._id, position: (i + 1)}
            })]
        }
        fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/blocks/position?token=${token}`, data, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
            .then(res => {
                if (res.success && res.data) {
                    dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                } else {
                    console.log('PUT UserBlocksView:', res)
                }
            })
    }

    return (
        <div className={`user-blocks`}>
            <SeoBlock title={"Тренди"} description={""} keywords={""}/>
            {
                isDeleteBlock && <ModalWindow title={'Видалити даний тренд?'} handleClick={handleIsDeleteBlock}/>
            }

            <div className="user-blocks__header">
                <div className="user-blocks__header-title-wrap">
                    <img className="user-blocks__header-title-img" src={productsImg} alt='img'/>
                    <h1 className="user-blocks__header-title">Тренди</h1>
                </div>
                <div className="user-blocks__header-btn-wrap">
                    <NavLink className="user-blocks__header-btn" to={'/auth/blocks/create'}>
                        <img className="user-blocks__header-btn-img" src={plus} alt='img'/>
                        <span>Створити тренд</span>
                    </NavLink>
                </div>
            </div>

            {
                isPreloader ? <PreloaderCustom />
                    :
                    <>
                        <div className="user-blocks__main-wrap">
                            <div className="user-blocks__main-count-wrap">
                                <div className="user-blocks__main-count-text">Кількість створених трендів:</div>
                                {
                                    (!!blocks?.length || !!notDeleteBlock?.length) && 
                                        <div className="user-blocks__main-count">{blocks?.length + notDeleteBlock?.length}</div>
                                }
                            </div>

                            {
                                !!blocks?.length || !!notDeleteBlock?.length ? 
                                    <div className="user-blocks__blocks-result-wrap">
                                        {
                                            !!notDeleteBlock?.length && notDeleteBlock?.map((el, i) => (
                                                <div className="user-blocks__blocks-result" key={el._id}>
                                                    <div className="user-blocks__blocks-result-text-wrap">
                                                        <div className="user-blocks__blocks-result-text-title">Тренд {i + 1}</div>
                                                        <NavLink className="user-blocks__blocks-result-text" onClick={() => handleUpdateBlock(el)} to={`/auth/blocks/${el._id}`}>{el.title}</NavLink>
                                                        <div className="user-blocks__blocks-result-text-count">Кількість товарів {el.count}</div>
                                                    </div>
                                                    <div className="user-blocks__blocks-result-btn-wrap">
                                                        <NavLink className="user-blocks__blocks-result-btn-del-wrap" onClick={() => handleUpdateBlock(el)} to={`/auth/blocks/${el._id}`}>
                                                            <img className="user-blocks__blocks-result-text-btn" src={editIcon1} alt='img'/>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            )) 
                                        }
                                        {
                                            !!blocks?.length && blocks?.map((el, i) => (
                                                <div className="user-blocks__blocks-result" key={el._id}>
                                                    <div className="user-blocks__blocks-result-text-wrap">
                                                        <div className="user-blocks__blocks-result-text-title">Тренд {i + 1}</div>
                                                        <NavLink className="user-blocks__blocks-result-text" onClick={() => handleUpdateBlock(el)} to={`/auth/blocks/${el._id}`}>{el.title}</NavLink>
                                                        <div className="user-blocks__blocks-result-text-count">Кількість товарів {el.count}</div>
                                                    </div>
                                                    <div className="user-blocks__blocks-result-btn-wrap">
                                                        <div className='user-blocks__blocks-result-btn-position-wrap'>
                                                            <button className={`user-blocks__blocks-result-btn`} disabled={i === (blocks.length - 1) ? true : false} onClick={() => handleNewPosition(el, i, +1)}>
                                                                <svg className="user-blocks__blocks-result-btn-img-down" width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <g>
                                                                        <polyline fill="none" id="Right-2" points="7.6 7 2.5 12 7.6 17" stroke={`${i === (blocks.length - 1) ? '#898989' : '#19191D'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                                                        <line fill="none" stroke={`${i === (blocks.length - 1) ? '#898989' : '#19191D'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="21.5" x2="4.8" y1="12" y2="12"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                            <button className={`user-blocks__blocks-result-btn`} disabled={i === 0 ? true : false} onClick={() => handleNewPosition(el, i, -1)}>
                                                                <svg className="user-blocks__blocks-result-btn-img-up" width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <g>
                                                                        <polyline fill="none" id="Right-2" points="7.6 7 2.5 12 7.6 17" stroke={`${i === 0 ? '#898989' : '#19191D'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                                                        <line fill="none" stroke={`${i === 0 ? '#898989' : '#19191D'}`} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="21.5" x2="4.8" y1="12" y2="12"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <div className="user-blocks__blocks-result-btn-del-wrap">
                                                            <NavLink onClick={() => handleUpdateBlock(el)} to={`/auth/blocks/${el._id}`}>
                                                                <img className="user-blocks__blocks-result-text-btn" src={editIcon1} alt='img'/>
                                                            </NavLink>
                                                            <img className='user-blocks__blocks-result-text-btn-del' onClick={() => handleDeleteBlock(el._id)} src={deleteImg3} alt='img'/>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) 
                                        }
                                    </div>
                                    :
                                    <div className="user-blocks__blocks-error">Нічого не знайдено</div>
                            }
                        </div>
                    </>
            }
        </div>
    );
}

export default UserBlocksView;