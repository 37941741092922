import './ColorResult.css';
import { memo, useCallback, useMemo } from 'react';
import multicolor2 from './../../assets/multicolor2.jpg';

function ColorResult({
	productColor,
	color,
	isProductView = false,
	isAdminView = false,
}) {
	const convertColorToRGB = useCallback((color) => {
		if (color === 'multicolor') {
			return;
		}
		if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color)) {
			color = color.replace('#', '');
			if (color.length === 3) {
				color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
			}

			const r = parseInt(color.substring(0, 2), 16);
			const g = parseInt(color.substring(2, 4), 16);
			const b = parseInt(color.substring(4, 6), 16);
			return `rgb(${r}, ${g}, ${b})`;
		} else if (/^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/.test(color)) {
			return color;
		} else if (
			/^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d*(\.\d+)?)\)$/.test(
				color
			)
		) {
			return color;
		} else {
			return color;
		}
	}, []);

	const getContrastColor = useMemo(() => {
		if (color === 'multicolor') {
			return 'black';
		}
		if (color?.length) {
			let background = color;
			if (background.startsWith('#')) {
				background = convertColorToRGB(background);
			}
			const colorValues = background.match(/\d+/g).map(Number);
			const brightness =
				(colorValues[0] * 299 + colorValues[1] * 587 + colorValues[2] * 114) /
				1000;
			return brightness > 128 ? 'black' : 'white';
		}
	}, [color, convertColorToRGB]);

	return (
		<>
			{isProductView ? (
				<span
					className={`color-result-wrap ${
						productColor === color ? 'color-result-active' : ''
					}`}
				>
					{color === 'multicolor' ? (
						<img
							className='color-result__multicolor--product-view'
							src={multicolor2}
							alt='img'
						/>
					) : (
						<span style={{ background: color }} className='color-result'></span>
					)}
				</span>
			) : (
				!!color?.length && (
					<span
						className={`color-result-wrap--card ${
							isAdminView ? 'color-result-wrap--admin-card' : ''
						} ${productColor === color ? 'color-result-wrap-active' : ''}`}
					>
						<div
							className={`color-result--card--wrap ${
								isAdminView ? 'color-result--card--wrap--is-admin' : ''
							}`}
						>
							{productColor === color && (
								<svg
									className='color-result-active--card'
									xmlns='http://www.w3.org/2000/svg'
									width='12'
									height='9'
									viewBox='0 0 12 9'
									fill='none'
								>
									<path
										d='M3.99574 6.87056L1.388 4.1626L0.5 5.07824L3.99574 8.70833L11.5 0.915641L10.6182 0L3.99574 6.87056Z'
										fill={getContrastColor}
									/>
								</svg>
							)}
							{color === 'multicolor' ? (
								<img
									className={`color-result__multicolor ${
										isAdminView ? 'color-result__multicolor--product-view' : ''
									}`}
									src={multicolor2}
									alt='img'
								/>
							) : (
								<span
									style={{ background: color }}
									className={`color-result--card ${
										isAdminView ? 'color-result--admin-card' : ''
									}`}
								></span>
							)}
						</div>
					</span>
				)
			)}
		</>
	);
}

export default memo(ColorResult);
