import './MaterialResult.css';
import { memo, useRef, useState } from 'react';
import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import MaterialHover from '../MaterialHover/MaterialHover';
import noPhotos from '../../assets/noPhotos.svg';

function MaterialResult({
	productMaterial,
	variation,
	isHover = false,
	isVariationMenu = false,
	isAdminView = false,
}) {
	const resultRef = useRef(null);
	const resultCustomRef = useRef(null);
	const [distanceToRight, setdistanceToRight] = useState(0);

	const handleMouseEnter = () => {
		const element = resultRef.current;
		if (element) {
			const rect = element.getBoundingClientRect();
			setdistanceToRight(
				window.innerWidth - rect.right < 220
					? 240 - (window.innerWidth - rect.right)
					: 0
			);
		}
	};

	const handleMouseEnterCustom = () => {
		const element = resultCustomRef.current;
		if (element) {
			const rect = element.getBoundingClientRect();
			setdistanceToRight(
				window.innerWidth - rect.right < 220
					? 240 - (window.innerWidth - rect.right)
					: 0
			);
		}
	};

	const handleWidthMaterialHover = () => {
		let res =
			variation.type?.length ||
			variation.ingredient?.length ||
			variation.keeping?.length
				? true
				: false;
		return res;
	};

	const CustomWidthTooltip = styled(({ className, ...props }) => (
		<Tooltip ref={resultCustomRef} {...props} classes={{ popper: className }} />
	))({
		[`& .${tooltipClasses.tooltip}`]: {
			maxWidth: handleWidthMaterialHover() ? 400 : 200,
		},
	});

	return (
		<>
			{isHover ? (
				<span
					className={`
                            material-result-wrap 
                            ${
															productMaterial.title === variation.title
																? 'material-result-active'
																: ''
														}
                            ${
															isVariationMenu
																? 'material-result-wrap-modal'
																: ''
														}
                        `}
				>
					{isVariationMenu ? (
						// в модалці
						<Tooltip
							ref={resultRef}
							disableInteractive={true}
							className='material-result__tooltip'
							title={
								window.innerWidth > 960 ? (
									<div>
										<MaterialHover
											distanceToRight={distanceToRight}
											variation={variation}
											hoverWidth={handleWidthMaterialHover() ? 400 : 214}
										/>
									</div>
								) : (
									<></>
								)
							}
						>
							{!!variation.img?.length ? (
								<div
									className='material-result__title-wrap'
									onMouseEnter={handleMouseEnter}
								>
									<img
										className='material-result-modal'
										src={variation.img}
										alt={variation?.title?.length ? variation?.title : 'img'}
									/>
									<div className='material-result__title'>
										{variation.title}
									</div>
								</div>
							) : (
								<img
									className='material-result__not-photo'
									src={noPhotos}
									alt='img'
								/>
							)}
						</Tooltip>
					) : (
						<CustomWidthTooltip
							className='material-result__tooltip material-result__tooltip-desc'
							title={
								<div>
									<MaterialHover
										distanceToRight={distanceToRight}
										variation={variation}
										hoverWidth={handleWidthMaterialHover() ? 400 : 214}
									/>
								</div>
							}
							placement='top'
						>
							{!!variation.img?.length ? (
								<img
									className='material-result'
									onMouseEnter={handleMouseEnterCustom}
									src={variation.img}
									alt={variation?.title?.length ? variation?.title : 'img'}
								/>
							) : (
								<img
									className='material-result__not-photo'
									src={noPhotos}
									alt='img'
								/>
							)}
						</CustomWidthTooltip>
					)}
				</span>
			) : (
				<div
					className={`material-result__card-wrap ${
						isAdminView ? 'material-result__card-wrap--admin-view' : ''
					} ${
						productMaterial?.title === variation?.title
							? 'material-result__card-wrap-active'
							: ''
					}`}
				>
					{productMaterial?.title === variation?.title && (
						<svg
							className='material-result__card-active'
							xmlns='http://www.w3.org/2000/svg'
							width='12'
							height='9'
							viewBox='0 0 12 9'
							fill='none'
						>
							<path
								d='M3.99574 6.87056L1.388 4.1626L0.5 5.07824L3.99574 8.70833L11.5 0.915641L10.6182 0L3.99574 6.87056Z'
								fill='#fff'
							/>
						</svg>
					)}
					{!!variation.img?.length ? (
						<img
							className={`material-result-circle ${
								isAdminView ? 'material-result-circle--admin-view' : ''
							}`}
							src={variation.img}
							alt={variation?.title?.length ? variation?.title : 'img'}
						/>
					) : (
						<img
							className={`material-result__not-photo ${
								isAdminView ? 'material-result-circle--admin-view' : ''
							}`}
							src={noPhotos}
							alt='img'
						/>
					)}
				</div>
			)}
		</>
	);
}

export default memo(MaterialResult);
