import './CreatingVendorView.css';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';
import {
	fetchGetData,
	fetchRequest,
	handleGetDocName,
	validNumber,
} from '../../helpers/Utils';
import MainButton from '../../components/MainButton/MainButton';
import uploadImg from '../../assets/uploadImg.svg';
import deleteImg3 from '../../assets/deleteImg3.svg';
import deleteImg2 from '../../assets/deleteImg2.svg';
import manIcon from '../../assets/manIcon.svg';
import saveImg from '../../assets/saveImg.svg';
import arrow2 from '../../assets/arrow2.svg';
import plusImg from '../../assets/plusImg.svg';
import fileImg from '../../assets/fileImg.svg';
import close2 from '../../assets/close2.svg';
import {
	getVendors,
	setEditeVendor,
	setShowMessageObj,
	updateVendors,
} from '../../store/userSlice';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toolbarOptions } from '../../helpers/Config';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import cyrillicToTranslit from 'cyrillic-to-translit-js';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function CreatingVendorView() {
	const [searchParams, setSearchParams] = useSearchParams();
	const newSearchParams = new URLSearchParams(searchParams);
	const tabCreateValueSearchParams = JSON.parse(
		searchParams.get('userVendorTabCreateValue')
	);

	const vendors = useSelector((state) => state.userSlice.vendors);
	const isOpenMenu = useSelector((state) => state.userSlice.isOpenMenu);
	const editeVendor = useSelector((state) => state.userSlice.editeVendor);
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [companyCity, setCompanyCity] = useState('');
	const [companyPhone, setCompanyPhone] = useState('');
	const [companyEmail, setCompanyEmail] = useState('');
	const [companyContact, setCompanyContact] = useState('');
	const [companySocial, setCompanySocial] = useState('');
	const [companyLink, setCompanyLink] = useState('');
	const [companyProducts, setCompanyProducts] = useState('');
	const [slogan, setSlogan] = useState('');
	const [paymentDaysLimit, setPaymentDaysLimit] = useState(0);
	const [description, setDescription] = useState('');
	const [delivery, setDelivery] = useState('');
	const [deliveryOptions, setDeliveryOptions] = useState([]);
	const [payment, setPayment] = useState('');
	const [paymentOptions, setPaymentOptions] = useState([]);
	const [address, setAddress] = useState('');
	const [workSchedule, setWorkSchedule] = useState('');
	const [seoDefaultTitle, setSeoDefaultTitle] = useState('');
	const [seoDefaultDescription, setSeoDefaultDescription] = useState('');
	const [seoFacebookTitle, setSeoFacebookTitle] = useState('');
	const [seoFacebookDescription, setSeoFacebookDescription] = useState('');
	const [seoTwitterTitle, setSeoTwitterTitle] = useState('');
	const [seoTwitterDescription, setSeoTwitterDescription] = useState('');
	const [keywords, setKeywords] = useState('');
	const [seoDefaultKeywords, setSeoDefaultKeywords] = useState([]);
	const [imageLogoFiles, setImageLogoFiles] = useState({});
	const [logoImage, setLogoImage] = useState('');
	const [backgroundImageFile, setBackgroundImageFile] = useState({});
	const [backgroundImage, setBackgroundImage] = useState('');
	const [isPreloader, setIsPreloader] = useState(false);
	const [vendorNewDocArr, setVendorNewDocArr] = useState([]);
	const [vendorDocs, setVendorDocs] = useState([]);
	const [isModalDelete, setIsModalDelete] = useState(false);
	const [isModalHide, setIsModalHide] = useState(false);
	const [hideVendor, setHideVendor] = useState({});
	const [deleteVendorId, setDeleteVendorId] = useState('');
	const [isDownloadProducts, setIsDownloadProducts] = useState(false);
	const [isUploudProducts, setIsUploudProducts] = useState(false);
	const [isDownloadDoc, setIsDownloadDoc] = useState(false);
	const [downloadVendorId, setDownloadVendorId] = useState('');
	const [fileUrl, setFileUrl] = useState('');
	const [uploudVendorId, setUploudVendorId] = useState('');
	const [fileDoc, setFileDoc] = useState({});
	const [downloadVendorDoc, setDownloadVendorDoc] = useState([]);
	const [userVendorTabCreateValue, setUserVendorTabCreateValue] = useState(
		tabCreateValueSearchParams ? tabCreateValueSearchParams : 0
	);
	const [errorName, setErrorName] = useState('');
	const [errorPhone, setErrorPhone] = useState('');
	const [errorCompanyEmail, setErrorCompanyEmail] = useState('');
	const [errorCompanyName, setErrorCompanyName] = useState('');
	const [errorSlogan, setErrorSlogan] = useState('');
	const [conditionsDelivery, setConditionsDelivery] = useState('');
	const [conditionsPayment, setConditionsPayment] = useState('');
	const [companyLegalName, setCompanyLegalName] = useState('');
	const [alias, setAlias] = useState('');
	const [errorAlias, setErrorAlias] = useState('');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const transliterator = new cyrillicToTranslit({ preset: 'uk' });
	const { userVendorId } = useParams();
	let token = localStorage.getItem('token');

	useEffect(() => {
		handleSetInputValue();

		if (userVendorId?.length) {
			fetchGetData(
				`${process.env.REACT_APP_BASE_URL}/vendors/${userVendorId}`
			).then((res) => {
				if (res.success && res.data) {
					dispatch(setEditeVendor(res.data));
				} else {
					console.log('GET CreatingVendorView', res);
				}
			});
		}

		return () => {
			dispatch(setEditeVendor({}));
			handleClearInput();
		};
	}, []);

	useEffect(() => {
		handleSetInputValue();
	}, [editeVendor]);

	useEffect(() => {
		if (
			(tabCreateValueSearchParams &&
				tabCreateValueSearchParams !== userVendorTabCreateValue) ||
			(!tabCreateValueSearchParams && userVendorTabCreateValue !== 0)
		) {
			setUserVendorTabCreateValue(
				tabCreateValueSearchParams ? tabCreateValueSearchParams : 0
			);
		}
	}, [tabCreateValueSearchParams]);

	const handleSetCompanyName = (str) => {
		setCompanyName(str);
		let res = str.replace(/[^a-zA-Zа-яА-ЯіІїЇєЄ\s]/g, '');
		if (res?.length) {
			const latinText = transliterator
				.transform(res)
				.trim()
				.split(' ')
				.join('-');
			return setAlias(latinText.toLowerCase());
		}
	};

	const handleSetInputValue = () => {
		if (editeVendor?._id?.length) {
			setAlias(editeVendor.alias?.length ? editeVendor.alias : '');
			setName(editeVendor.contact_name?.length ? editeVendor.contact_name : '');
			setPhone(
				editeVendor.contact_phone?.length ? editeVendor.contact_phone : ''
			);
			setCompanyName(
				editeVendor.company_name?.length ? editeVendor.company_name : ''
			);
			setCompanyCity(editeVendor.city?.length ? editeVendor.city : '');
			setCompanyPhone(
				editeVendor.company_phone?.length ? editeVendor.company_phone : ''
			);
			setCompanyEmail(editeVendor.email?.length ? editeVendor.email : '');
			setCompanyContact(
				editeVendor.company_owner_name?.length
					? editeVendor.company_owner_name
					: ''
			);
			setCompanySocial(
				editeVendor.company_social_networks?.length
					? editeVendor.company_social_networks
					: ''
			);
			setCompanyLink(
				editeVendor.company_website?.length ? editeVendor.company_website : ''
			);
			setCompanyProducts(
				editeVendor.company_products?.length ? editeVendor.company_products : ''
			);
			setSlogan(editeVendor.slogan?.length ? editeVendor.slogan : '');
			setPaymentDaysLimit(
				editeVendor.payment_days_limit ? editeVendor.payment_days_limit : ''
			);
			setLogoImage(
				editeVendor.logo_image?.length ? editeVendor.logo_image : ''
			);
			setBackgroundImage(
				editeVendor.background_image?.length ? editeVendor.background_image : ''
			);
			setDescription(
				editeVendor.description?.length ? editeVendor.description : ''
			);
			setVendorDocs(
				editeVendor.vendor_docs?.length ? editeVendor.vendor_docs : ''
			);
			setSeoDefaultKeywords(
				editeVendor.seo_default_keywords?.length
					? editeVendor.seo_default_keywords
					: []
			);
			setDeliveryOptions(
				editeVendor.delivery_options?.length ? editeVendor.delivery_options : []
			);
			setPaymentOptions(
				editeVendor.payment_options?.length ? editeVendor.payment_options : []
			);
			setAddress(editeVendor.address?.length ? editeVendor.address : '');
			setWorkSchedule(
				editeVendor.work_schedule?.length ? editeVendor.work_schedule : ''
			);
			setSeoDefaultTitle(
				editeVendor.seo_default_title?.length
					? editeVendor.seo_default_title
					: ''
			);
			setSeoDefaultDescription(
				editeVendor.seo_default_description?.length
					? editeVendor.seo_default_description
					: ''
			);
			setSeoFacebookTitle(
				editeVendor.seo_facebook_title?.length
					? editeVendor.seo_facebook_title
					: ''
			);
			setSeoFacebookDescription(
				editeVendor.seo_facebook_description?.length
					? editeVendor.seo_facebook_description
					: ''
			);
			setSeoTwitterTitle(
				editeVendor.seo_twitter_title?.length
					? editeVendor.seo_twitter_title
					: ''
			);
			setSeoTwitterDescription(
				editeVendor.seo_twitter_description?.length
					? editeVendor.seo_twitter_description
					: ''
			);
			setConditionsDelivery(
				editeVendor.conditions_delivery?.length
					? editeVendor.conditions_delivery
					: ''
			);
			setConditionsPayment(
				editeVendor.conditions_payment?.length
					? editeVendor.conditions_payment
					: ''
			);
			setCompanyLegalName(
				editeVendor.company_legal_name?.length
					? editeVendor.company_legal_name
					: ''
			);
		}
	};

	const handleKeywordsKeyDown = (e) => {
		if (e.key == 'Enter') {
			handleAddKeyword();
		}
	};

	const handleAddKeyword = () => {
		if (keywords?.length) {
			setSeoDefaultKeywords([...seoDefaultKeywords, keywords]);
			setKeywords('');
		}
	};

	const handleDeleteKeyword = (str) => {
		setSeoDefaultKeywords([...seoDefaultKeywords.filter((el) => el !== str)]);
	};

	const handleDeliveryKeyDown = (e) => {
		if (e.key == 'Enter') {
			handleAddDelivery();
		}
	};

	const handleAddDelivery = () => {
		if (delivery?.length) {
			setDeliveryOptions([...deliveryOptions, delivery]);
			setDelivery('');
		}
	};

	const handleDeleteDelivery = (str) => {
		setDeliveryOptions([...deliveryOptions.filter((el) => el !== str)]);
	};

	const handlePaymentKeyDown = (e) => {
		if (e.key == 'Enter') {
			handleAddPayment();
		}
	};

	const handleAddPayment = () => {
		if (payment?.length) {
			setPaymentOptions([...paymentOptions, payment]);
			setPayment('');
		}
	};

	const handleDeletePayment = (str) => {
		setPaymentOptions([...paymentOptions.filter((el) => el !== str)]);
	};

	const handleDeleteUploadLogoImg = () => {
		setImageLogoFiles({});
	};

	const handleDeleteImgLogo = () => {
		setLogoImage('');
	};

	const handleDeleteUploadBackgroundImg = () => {
		setBackgroundImageFile({});
	};

	const handleDeleteImgBackground = () => {
		setBackgroundImage('');
	};

	const handleClearInput = () => {
		setName('');
		setPhone('');
		setAlias('');
		setCompanyName('');
		setCompanyCity('');
		setCompanyPhone('');
		setCompanyEmail('');
		setCompanyContact('');
		setCompanySocial('');
		setCompanyLink('');
		setCompanyProducts('');
		setSlogan('');
		setPaymentDaysLimit(0);
		setImageLogoFiles({});
		setSeoDefaultTitle('');
		setSeoDefaultDescription('');
		setSeoFacebookTitle('');
		setSeoFacebookDescription('');
		setSeoTwitterTitle('');
		setSeoTwitterDescription('');
		setKeywords('');
		setSeoDefaultKeywords([]);
		setLogoImage('');
		setBackgroundImage('');
		setBackgroundImageFile({});
		setVendorDocs([]);
		setVendorNewDocArr([]);
		setDescription('');
		setDeliveryOptions([]);
		setPaymentOptions([]);
		setDelivery('');
		setPayment('');
		setAddress('');
		setWorkSchedule('');
		setConditionsDelivery('');
		setConditionsPayment('');
		setCompanyLegalName('');
	};

	const handleIsValidCustomId = async (strAlias) => {
		await fetchGetData(
			`${process.env.REACT_APP_BASE_URL}/vendors/check?alias=${strAlias}`
		).then((res) => {
			if (res?.success) {
				return true;
			} else {
				console.log('GET CreatingVendorView', res);
				return false;
			}
		});
	};

	const handleCreateVendor = () => {
		let formData = new FormData();

		formData.append('contact_name', name);
		formData.append('alias', alias);
		formData.append('contact_phone', phone);
		formData.append('company_name', companyName);
		formData.append('city', companyCity);
		formData.append('company_phone', companyPhone);
		formData.append('email', companyEmail);
		formData.append('company_owner_name', companyContact);
		formData.append('company_social_networks', companySocial);
		formData.append('company_website', companyLink);
		formData.append('company_products', companyProducts);
		formData.append('slogan', slogan);
		formData.append('payment_days_limit', +paymentDaysLimit);
		formData.append('fileLogo', imageLogoFiles);
		formData.append('logo_image', logoImage);
		formData.append('fileBackground', backgroundImageFile);
		formData.append('background_image', backgroundImage);
		formData.append('description', description);
		if (vendorNewDocArr?.length) {
			vendorNewDocArr.map((el) => {
				formData.append('fileDocs', el);
			});
		} else {
			formData.append('fileDocs', []);
		}
		if (vendorDocs?.length) {
			vendorDocs.map((el) => {
				formData.append('vendor_docs[]', el);
			});
		} else {
			formData.append('vendor_docs[]', []);
		}
		if (seoDefaultKeywords?.length) {
			seoDefaultKeywords.map((keywords) => {
				formData.append('seo_default_keywords[]', keywords);
			});
		} else {
			formData.append('seo_default_keywords[]', []);
		}
		if (deliveryOptions?.length) {
			deliveryOptions.map((delivery) => {
				formData.append('delivery_options[]', delivery);
			});
		} else {
			formData.append('delivery_options[]', []);
		}
		if (paymentOptions?.length) {
			paymentOptions.map((payment) => {
				formData.append('payment_options[]', payment);
			});
		} else {
			formData.append('payment_options[]', []);
		}
		formData.append('company_legal_name', companyLegalName);
		formData.append('address', address);
		formData.append('work_schedule', workSchedule);
		formData.append('conditions_delivery', conditionsDelivery);
		formData.append('conditions_payment', conditionsPayment);
		formData.append('seo_default_title', seoDefaultTitle);
		formData.append('seo_default_description', seoDefaultDescription);
		formData.append('seo_facebook_title', seoFacebookTitle);
		formData.append('seo_facebook_description', seoFacebookDescription);
		formData.append('seo_twitter_title', seoTwitterTitle);
		formData.append('seo_twitter_description', seoTwitterDescription);

		if (
			companyName?.length &&
			(alias?.length ? handleIsValidCustomId(alias) : false) &&
			name?.length &&
			companyEmail?.length &&
			phone?.length &&
			(!slogan?.length || slogan?.length <= 200)
		) {
			setIsPreloader(true);
			if (editeVendor?._id?.length) {
				fetch(
					`${process.env.REACT_APP_BASE_URL}/vendors/${editeVendor._id}?token=${token}`,
					{
						method: 'PUT',
						body: formData,
					}
				)
					.then((res) => res.json())
					.then((res) => {
						if (res.success && res.data) {
							dispatch(updateVendors(res.data));
							dispatch(
								setShowMessageObj({
									open: true,
									status: 'success',
									message: 'Дані оновлено',
								})
							);
						} else {
							console.log('PUT CreatingVendor:', res);
							dispatch(
								setShowMessageObj({
									open: true,
									status: 'error',
									message: 'Сталася помилка',
								})
							);
						}
						setIsPreloader(false);
					});
			} else {
				formData.append('is_blocked', false);

				fetch(`${process.env.REACT_APP_BASE_URL}/vendors?token=${token}`, {
					method: 'POST',
					body: formData,
				})
					.then((res) => res.json())
					.then((res) => {
						if (res.success && res.data) {
							dispatch(getVendors([...vendors, res.data]));
							dispatch(
								setShowMessageObj({
									open: true,
									status: 'success',
									message: 'Дані створено',
								})
							);
						} else {
							console.log('POST CreatingVendor:', res);
							dispatch(
								setShowMessageObj({
									open: true,
									status: 'error',
									message: 'Сталася помилка',
								})
							);
						}
						setIsPreloader(false);
					});
			}
		} else {
			setErrorAlias(
				!alias?.length || (alias?.length && handleIsValidCustomId(alias))
					? false
					: true
			);
			setErrorCompanyEmail(!companyEmail?.length ? "Обов'язкове поле" : '');
			setErrorPhone(!phone?.length ? "Обов'язкове поле" : '');
			setErrorName(!name?.length ? "Обов'язкове поле" : '');
			setErrorCompanyName(!companyName?.length ? "Обов'язкове поле" : '');
			setErrorSlogan(
				slogan?.length > 200
					? `Перевищено кількість символів на ${slogan?.length - 200}`
					: ''
			);
			if (slogan?.length > 200) {
				dispatch(
					setShowMessageObj({
						open: true,
						status: 'error',
						message: 'Перевищено кількість символів у слогані',
					})
				);
			}
			dispatch(
				setShowMessageObj({
					open: true,
					status: 'error',
					message: "Обов'язкові поля не заповнені",
				})
			);
		}
	};

	const handleImageUploadLogo = (e) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		if (file.name?.length) {
			setImageLogoFiles(file);
		}
	};

	const uploadPhotoLogo = (image_file) => {
		if (image_file?.name?.length) {
			setImageLogoFiles(image_file);
		}
	};

	const handleImageUploadBackground = (e) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		if (file.name?.length) {
			setBackgroundImageFile(file);
		}
	};

	const uploadPhotoBackground = (image_file) => {
		if (image_file?.name?.length) {
			setBackgroundImageFile(image_file);
		}
	};

	const handleUploadVendorNewDoc = (file) => {
		if (file?.name?.length) {
			setVendorNewDocArr([...vendorNewDocArr, file]);
		}
	};

	const handleAddVendorNewDoc = (obj) => {
		if (obj?.name?.length) {
			setVendorNewDocArr([...vendorNewDocArr, obj]);
		}
	};

	const handleDeleteVendorNewDoc = (name) => {
		setVendorNewDocArr([...vendorNewDocArr.filter((el) => el.name !== name)]);
	};

	const handleDeleteVendorDoc = (str) => {
		setVendorDocs([...vendorDocs.filter((el) => el !== str)]);
	};

	const handleDeleteVendor = (id) => {
		setIsModalDelete(true);
		setDeleteVendorId(id);
	};

	const handleHideVendor = (obj) => {
		setIsModalHide(true);
		setHideVendor(obj);
	};

	const handleIsDeleteVendor = (boolean) => {
		if (boolean) {
			setIsPreloader(true);
			fetchRequest(
				'DELETE',
				`${process.env.REACT_APP_BASE_URL}/vendors/${deleteVendorId}?token=${token}`,
				{},
				() =>
					dispatch(
						setShowMessageObj({
							open: true,
							status: 'error',
							message: 'Сталася помилка',
						})
					)
			).then((res) => {
				if (res.success && res.data) {
					navigate('/auth/vendors');
					dispatch(
						setShowMessageObj({
							open: true,
							status: 'success',
							message: 'Дані оновлено',
						})
					);
				} else {
					console.log('DELETE UserVendorsView:', res);
				}
				setIsPreloader(false);
			});
		}

		setIsModalDelete(false);
		setDeleteVendorId('');
	};

	const handleIsHideVendor = (boolean) => {
		if (boolean) {
			setIsPreloader(true);
			let data = {
				...hideVendor,
				is_blocked: !hideVendor.is_blocked,
			};
			fetchRequest(
				'PUT',
				`${process.env.REACT_APP_BASE_URL}/vendors/hide/${hideVendor._id}?token=${token}`,
				data,
				() =>
					dispatch(
						setShowMessageObj({
							open: true,
							status: 'error',
							message: 'Сталася помилка',
						})
					)
			).then((res) => {
				if (res.success && res.data) {
					dispatch(setEditeVendor(res.data));
					dispatch(
						setShowMessageObj({
							open: true,
							status: 'success',
							message: 'Дані оновлено',
						})
					);
				} else {
					console.log('PUT UserVendorsView:', res);
				}
				setIsPreloader(false);
			});
		}

		setIsModalHide(false);
		setHideVendor({});
	};

	const handleDownloadProducts = (id) => {
		setIsDownloadProducts(true);
		setDownloadVendorId(id);
	};

	const handleDownloadVendorDoc = (arr) => {
		setIsDownloadDoc(true);
		setDownloadVendorDoc(arr);
	};

	const handleIsDownloadDoc = (boolean) => {
		if (boolean) {
		} else {
			deleteExcel();
		}
	};

	const handleIsDownloadProducts = (boolean) => {
		if (boolean) {
			fetchGetData(
				`${process.env.REACT_APP_BASE_URL}/products/csv?vendor_id=${downloadVendorId}`
			).then((res) => {
				if (res.success && res.data) {
					setFileUrl(res.data);
				} else {
					console.log('GET UserProductsViews', res);
				}
			});
		} else {
			deleteExcel();
		}
	};

	const deleteExcel = () => {
		setFileUrl('');
		setIsDownloadProducts(false);
		setIsDownloadDoc(false);
		setDownloadVendorId('');
		setDownloadVendorDoc([]);
	};

	const handleClickSetting = (str) => {
		if (str === 'hide') {
			handleHideVendor(editeVendor);
			return;
		}
		if (str === 'delete') {
			handleDeleteVendor(editeVendor._id);
			return;
		}
		if (str === 'uploudProduct') {
			handleUploudFile(editeVendor._id);
			return;
		}
		if (str === 'saveProduct') {
			handleDownloadProducts(editeVendor._id);
			return;
		}
		if (str === 'docs') {
			handleDownloadVendorDoc(editeVendor.vendor_docs);
			return;
		}
	};

	const handleSetTab = (num) => {
		setUserVendorTabCreateValue(num);
		newSearchParams.set('userVendorTabCreateValue', JSON.stringify(num));
		setSearchParams(newSearchParams);
	};

	const handleUploudFile = (id) => {
		setIsUploudProducts(true);
		setUploudVendorId(id);
	};

	const handleIsUploudProducts = (boolean) => {
		if (boolean && fileDoc?.name?.length) {
			let formData = new FormData();

			formData.append('file', fileDoc);
			formData.append('vendor_id', uploudVendorId);
			fetch(
				`${process.env.REACT_APP_BASE_URL}/products/import?token=${token}`,
				{
					method: 'POST',
					body: formData,
				}
			)
				.then((res) => res.json())
				.then((res) => {
					if (res.success) {
						dispatch(
							setShowMessageObj({
								open: true,
								status: 'success',
								message: 'Дані оновлено',
							})
						);
					} else {
						console.log('POST UserVendorsView:', res);
					}
					setIsPreloader(false);
				});
		}
		setIsUploudProducts(false);
		setUploudVendorId('');
		setFileDoc({});
	};

	return (
		<div className={`user-vendors`}>
			<SeoBlock
				title={
					!!editeVendor?._id?.length
						? 'Редагування виробника'
						: 'Створення виробника'
				}
				description={''}
				keywords={''}
				isCanonical={
					location.pathname === '/auth/vendors/create' ? true : false
				}
			/>
			{isModalHide && (
				<ModalWindow
					title={
						hideVendor.is_blocked
							? 'Відновити виробника?'
							: 'Приховати даного виробника?'
					}
					handleClick={handleIsHideVendor}
				/>
			)}
			{isModalDelete && (
				<ModalWindow
					title={'Видалити даного виробника?'}
					handleClick={handleIsDeleteVendor}
				/>
			)}
			{isDownloadProducts && (
				<ModalWindow
					title={'Зберегти всі товари виробника?'}
					handleClick={handleIsDownloadProducts}
					notBtn={!!fileUrl?.length ? true : false}
				>
					{!!fileUrl?.length && (
						<a
							className='user-vendors__btn-download-link-btn'
							onClick={deleteExcel}
							href={fileUrl}
							download
						>
							Download File
						</a>
					)}
				</ModalWindow>
			)}
			{isDownloadDoc && (
				<ModalWindow
					title={'Зберегти документи виробника?'}
					handleClick={handleIsDownloadDoc}
					notBtn={true}
				>
					<div className='user-vendors__btn-download-link-wrap'>
						<div>Для скачування нажміть на силку</div>
						{!!downloadVendorDoc?.length &&
							downloadVendorDoc.map((el) => (
								<a
									className='user-vendors__btn-download-link user-vendors__btn-download-link-overflow'
									onClick={deleteExcel}
									href={el}
									download
									key={el}
								>
									{handleGetDocName(el)}
								</a>
							))}
					</div>
				</ModalWindow>
			)}
			{isUploudProducts && (
				<ModalWindow
					title={'Виберіть файл для завантаження'}
					handleClick={handleIsUploudProducts}
				>
					<label>
						<MainButton text={'Виберіть файл'} typeBtn={2} />
						<input
							className='user-vendors__modl-uploud-input'
							onChange={(e) => setFileDoc(e.target.files[0])}
							type='file'
							accept='.csv'
						/>
						{!!fileDoc?.name?.length && (
							<div className='user-vendors__modl-uploud-result'>
								{fileDoc?.name}
							</div>
						)}
					</label>
				</ModalWindow>
			)}

			<div className='creating-vendor__header'>
				<div className='user-vendors__header-title-wrap'>
					<img
						className='user-vendors__header-title-img'
						src={manIcon}
						alt='img'
					/>
					<h1 className='user-vendors__header-title'>Виробники</h1>
				</div>
				<div className='creating-vendors__text-tab--wrap'>
					<div
						className={`creating-vendors__text-tab-wrap ${
							!isOpenMenu
								? 'user-order__text-tab-wrap--is-open-menu'
								: 'user-order__text-tab-wrap--is-close-menu'
						}`}
					>
						<button
							className={`creating-vendors__text-tab ${
								userVendorTabCreateValue === 0
									? 'user-order__text-tab-active'
									: ''
							}`}
							onClick={() => handleSetTab(0)}
						>
							Основна інформація
						</button>
						<button
							className={`creating-vendors__text-tab ${
								userVendorTabCreateValue === 1
									? 'user-order__text-tab-active'
									: ''
							}`}
							onClick={() => handleSetTab(1)}
						>
							Доставка та оплата
						</button>
						<button
							className={`creating-vendors__text-tab ${
								userVendorTabCreateValue === 2
									? 'user-order__text-tab-active'
									: ''
							}`}
							onClick={() => handleSetTab(2)}
						>
							Лого, фон, документи
						</button>
						<button
							className={`creating-vendors__text-tab ${
								userVendorTabCreateValue === 3
									? 'user-order__text-tab-active'
									: ''
							}`}
							onClick={() => handleSetTab(3)}
						>
							SEO налаштування
						</button>
						{!!userVendorId?.length && (
							<button
								className={`creating-vendors__text-tab ${
									userVendorTabCreateValue === 4
										? 'user-order__text-tab-active'
										: ''
								}`}
								onClick={() => handleSetTab(4)}
							>
								Інші налаштування
							</button>
						)}
					</div>
					<Select
						value={userVendorTabCreateValue}
						className='creating-vendors__tab-select'
						onChange={(e) => handleSetTab(e.target.value)}
					>
						<MenuItem
							className={`product-view__variations-select-item ${
								userVendorTabCreateValue === 0
									? 'product-view__variations-select-item-active'
									: ''
							}`}
							value={0}
							disableRipple={true}
						>
							Основна інформація
						</MenuItem>
						<MenuItem
							className={`product-view__variations-select-item ${
								userVendorTabCreateValue === 1
									? 'product-view__variations-select-item-active'
									: ''
							}`}
							value={1}
							disableRipple={true}
						>
							Доставка та оплата
						</MenuItem>
						<MenuItem
							className={`product-view__variations-select-item ${
								userVendorTabCreateValue === 2
									? 'product-view__variations-select-item-active'
									: ''
							}`}
							value={2}
							disableRipple={true}
						>
							Лого, фон, документи
						</MenuItem>
						<MenuItem
							className={`product-view__variations-select-item ${
								userVendorTabCreateValue === 3
									? 'product-view__variations-select-item-active'
									: ''
							}`}
							value={3}
							disableRipple={true}
						>
							SEO налаштування
						</MenuItem>
						{!!userVendorId?.length && (
							<MenuItem
								className={`product-view__variations-select-item ${
									userVendorTabCreateValue === 4
										? 'product-view__variations-select-item-active'
										: ''
								}`}
								value={4}
								disableRipple={true}
							>
								Інші налаштування
							</MenuItem>
						)}
					</Select>
				</div>

				<div className='user-vendors__header-btn-wrap'>
					{editeVendor?._id?.length && (
						<button
							className='user-vendors__header-btn-save--1'
							onClick={() => handleCreateVendor()}
						>
							<img
								className='user-vendors__header-btn-img-save'
								src={saveImg}
								alt='img'
							/>
							<span>Зберегти зміни</span>
						</button>
					)}
					{!editeVendor?._id?.length && (
						<button
							className='user-vendors__header-btn-save'
							onClick={() => handleCreateVendor()}
						>
							<img
								className='user-vendors__header-btn-img-save'
								src={saveImg}
								alt='img'
							/>
							<span>Створити виробника</span>
						</button>
					)}
				</div>
			</div>

			<div className='creating-vendor'>
				{isPreloader ? (
					<PreloaderCustom />
				) : (
					<>
						{userVendorTabCreateValue === 4 && !!editeVendor._id?.length && (
							<div className='creating-vendor__tab-0-wrap'>
								<div className='creating-vendor__tab-4__btn-wrap'>
									<div className='creating-vendor__header-btn-wrap'>
										<div className='creating-vendor__header-btn-title'>
											Заблокувати сторінку виробника
										</div>
										<button
											className='creating-vendor__header-btn'
											onClick={() => handleClickSetting('hide')}
										>
											{editeVendor.is_blocked ? 'Розблокувати' : 'Заблокувати'}
										</button>
									</div>
									<div className='creating-vendor__header-btn-wrap'>
										<div className='creating-vendor__header-btn-title'>
											Видалити сторінку виробника
										</div>
										<button
											className='creating-vendor__header-btn'
											onClick={() => handleClickSetting('delete')}
										>
											Видалити
										</button>
									</div>
									<div className='creating-vendor__header-btn-wrap'>
										<div className='creating-vendor__header-btn-title'>
											Завантажити товари виробника на сайт
										</div>
										<button
											className='creating-vendor__header-btn'
											onClick={() => handleClickSetting('uploudProduct')}
										>
											Завантажити товари
										</button>
									</div>
									<div className='creating-vendor__header-btn-wrap'>
										<div className='creating-vendor__header-btn-title'>
											Зберегти товари виробника з сайту
										</div>
										<button
											className='creating-vendor__header-btn'
											onClick={() => handleClickSetting('saveProduct')}
										>
											Зберегти товари
										</button>
									</div>
								</div>
							</div>
						)}
						{userVendorTabCreateValue === 0 && (
							<div className='creating-vendor__tab-0-wrap'>
								<Accordion className='creating-vendor__vendor-accordion'>
									<AccordionSummary
										expandIcon={
											<p>
												<img
													className='creating-vendor__vendor-accordion-arrow'
													src={arrow2}
													alt='img'
												/>
											</p>
										}
										aria-controls='creating-vendor__vendor'
										id='creating-vendor__vendor'
									>
										<Typography>Про компанію</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<div className='creating-vendor__vendor-accordion-wrap'>
											<div className='creating-vendor__input--wrap'>
												<div className='creating-vendor__input-lable requiredFields'>
													Контактна особа
												</div>
												<input
													className={`creating-category__input ${
														!!errorName?.length
															? 'creating-category__input--error'
															: ''
													}`}
													value={name}
													onChange={(e) => setName(e.target.value)}
													placeholder='Додати назву'
													type='text'
												/>
												{!!errorName?.length && (
													<div className='creating-category__input-error-text'>
														{errorName}
													</div>
												)}
											</div>
											<div className='creating-vendor__input--wrap'>
												<div className='creating-vendor__input-lable requiredFields'>
													Назва компанії
												</div>
												<input
													className={`creating-category__input ${
														!!errorCompanyName?.length
															? 'creating-category__input--error'
															: ''
													}`}
													value={companyName}
													onChange={(e) => handleSetCompanyName(e.target.value)}
													placeholder='Додати назву'
													type='text'
												/>
												{!!errorCompanyName?.length && (
													<div className='creating-category__input-error-text'>
														{errorCompanyName}
													</div>
												)}
											</div>
											<div className='creating-vendor__input--wrap'>
												<div className='creating-vendor__input-lable requiredFields'>
													Назва url
												</div>
												<input
													className={`creating-category__input ${
														!!errorAlias?.length
															? 'creating-category__input--error'
															: ''
													}`}
													value={alias}
													onChange={(e) => setAlias(e.target.value)}
													placeholder='Додати url назву'
													type='text'
												/>
												{!!errorAlias?.length && (
													<div className='creating-category__input-error-text'>
														{errorAlias}
													</div>
												)}
											</div>
											<div className='creating-vendor__input--wrap'>
												<div className='creating-vendor__input-lable'>
													Місто
												</div>
												<input
													className='creating-category__input'
													value={companyCity}
													onChange={(e) => setCompanyCity(e.target.value)}
													placeholder='Додати назву'
													type='text'
												/>
											</div>
											<div className='creating-vendor__input--wrap'>
												<div className='creating-vendor__input-lable'>
													Адреса
												</div>
												<input
													className='creating-category__input'
													value={address}
													onChange={(e) => setAddress(e.target.value)}
													placeholder='Додати адресу'
													type='text'
												/>
											</div>
											<div className='creating-vendor__input--wrap'>
												<div className='creating-vendor__input-lable'>Опис</div>
												<textarea
													className='creating-category__textarea'
													value={description}
													onChange={(e) => setDescription(e.target.value)}
													placeholder='Додати опис'
													rows={8}
													type='text'
												/>
											</div>

											<div className='creating-vendor__input-wrap'>
												<div className='creating-vendor__input-lable'>
													Графік
												</div>
												<ReactQuill
													modules={{ toolbar: toolbarOptions }}
													theme='snow'
													value={workSchedule}
													onChange={setWorkSchedule}
													style={{ whiteSpace: 'pre-wrap' }}
													className='creating-vendor__input-work-schedule'
												/>
											</div>
											<div className='creating-vendor__input--wrap'>
												<div className='creating-vendor__input-lable'>
													Назва юридичної особи, з якої будуть здійснюватися
													розрахунки
												</div>
												<textarea
													className='creating-category__textarea'
													value={companyLegalName}
													onChange={(e) => setCompanyLegalName(e.target.value)}
													placeholder='Додати назву'
													rows={4}
													type='text'
												/>
											</div>
											<div className='creating-vendor__input--wrap'>
												<div className='creating-vendor__input-lable'>
													Товари та послуги
												</div>
												<textarea
													className='creating-category__textarea'
													value={companyProducts}
													onChange={(e) => setCompanyProducts(e.target.value)}
													placeholder='Додати назву'
													rows={8}
													type='text'
												/>
											</div>
										</div>
									</AccordionDetails>
								</Accordion>
								<Accordion className='creating-vendor__vendor-accordion'>
									<AccordionSummary
										expandIcon={
											<p>
												<img
													className='creating-vendor__vendor-accordion-arrow'
													src={arrow2}
													alt='img'
												/>
											</p>
										}
										aria-controls='creating-vendor__vendor'
										id='creating-vendor__vendor'
									>
										<Typography>Контактна інформація</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<div className='creating-vendor__vendor-accordion-wrap'>
											<div className='creating-vendor__input--wrap'>
												<div className='creating-vendor__input-lable requiredFields'>
													Телефон контакної особи
												</div>
												<input
													className={`creating-category__input ${
														!!errorPhone?.length
															? 'creating-category__input--error'
															: ''
													}`}
													value={phone}
													onChange={(e) => setPhone(e.target.value)}
													placeholder='Додати телефон'
													type='text'
												/>
												{!!errorPhone?.length && (
													<div className='creating-category__input-error-text'>
														{errorPhone}
													</div>
												)}
											</div>

											<div className='creating-vendor__input--wrap'>
												<div className='creating-vendor__input-lable'>
													Телефон компанії
												</div>
												<input
													className='creating-category__input'
													value={companyPhone}
													onChange={(e) => setCompanyPhone(e.target.value)}
													placeholder='Додати телефон'
													type='text'
												/>
											</div>
											<div className='creating-vendor__input--wrap'>
												<div className='creating-vendor__input-lable requiredFields'>
													Електронна адреса
												</div>
												<input
													className={`creating-category__input ${
														!!errorCompanyEmail?.length
															? 'creating-category__input--error'
															: ''
													}`}
													value={companyEmail}
													onChange={(e) => setCompanyEmail(e.target.value)}
													placeholder='Додати електронну адресу'
													type='text'
												/>
												{!!errorCompanyEmail?.length && (
													<div className='creating-category__input-error-text'>
														{errorCompanyEmail}
													</div>
												)}
											</div>
											<div className='creating-vendor__input--wrap'>
												<div className='creating-vendor__input-lable'>
													Контакти керівника (власника) компанії
												</div>
												<input
													className='creating-category__input'
													value={companyContact}
													onChange={(e) => setCompanyContact(e.target.value)}
													placeholder='Додати контакти'
													type='text'
												/>
											</div>
											<div className='creating-vendor__input--wrap'>
												<div className='creating-vendor__input-lable'>
													Соціальні мережі
												</div>
												<input
													className='creating-category__input'
													value={companySocial}
													onChange={(e) => setCompanySocial(e.target.value)}
													placeholder='Додати посилання'
													type='text'
												/>
											</div>
											<div className='creating-vendor__input--wrap'>
												<div className='creating-vendor__input-lable'>Сайт</div>
												<input
													className='creating-category__input'
													value={companyLink}
													onChange={(e) => setCompanyLink(e.target.value)}
													placeholder='Додати посилання'
													type='text'
												/>
											</div>

											<div className='creating-vendor__input--wrap'>
												<div className='creating-vendor__input-lable'>
													Слоган
												</div>
												<input
													className={`creating-category__input ${
														!!errorSlogan?.length
															? 'creating-category__input--error'
															: ''
													}`}
													value={slogan}
													onChange={(e) => setSlogan(e.target.value)}
													placeholder='Додати слоган'
													type='text'
												/>
												<div className='creating-vendor__input-sub-lable'>
													Кількість символів для слогану до 200
												</div>
												{!!errorSlogan?.length && (
													<div className='creating-category__input-error-text'>
														{errorSlogan}
													</div>
												)}
											</div>
											<div className='creating-vendor__input--wrap'>
												<div className='creating-vendor__input-lable'>
													Кількість днів до оплати
												</div>
												<input
													className='creating-category__input'
													value={paymentDaysLimit}
													onChange={(e) =>
														validNumber(e.target.value, setPaymentDaysLimit)
													}
													placeholder='Кількість днів до оплати'
													type='text'
												/>
											</div>
										</div>
									</AccordionDetails>
								</Accordion>
							</div>
						)}

						{userVendorTabCreateValue === 1 && (
							<div className='creating-vendor__tab-1-wrap'>
								<Accordion className='creating-vendor__vendor-accordion'>
									<AccordionSummary
										expandIcon={
											<p>
												<img
													className='creating-vendor__vendor-accordion-arrow'
													src={arrow2}
													alt='img'
												/>
											</p>
										}
										aria-controls='creating-vendor__vendor'
										id='creating-vendor__vendor'
									>
										<Typography>Доставка</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<div className='creating-vendor__add-btn-wrap'>
											<div className='creating-vendor__add-btn--wrap'>
												<div className='creating-product__input-lable'>
													Умови доставки
												</div>
												<textarea
													className={`creating-category__textarea creating-product__textarea`}
													value={conditionsDelivery}
													onChange={(e) =>
														setConditionsDelivery(e.target.value)
													}
													placeholder='Введіть умову'
													rows={2}
													type='text'
												/>
											</div>
											<div className='creating-vendor__add-btn--wrap'>
												<div className='creating-product__input-lable'>
													Варіанти доставки
												</div>
												<input
													className='creating-category__input'
													value={delivery}
													onChange={(e) => setDelivery(e.target.value)}
													onKeyDown={(e) => handleDeliveryKeyDown(e)}
													placeholder='Введіть варіант доставки'
													type='text'
												/>
												<img
													className='creating-vendor__add-btn'
													onClick={handleAddDelivery}
													src={plusImg}
													alt='img'
												/>
											</div>
											<div className='creating-vendor__add-result-wrap'>
												{!!deliveryOptions?.length &&
													deliveryOptions.map((el) => (
														<div
															className='creating-vendor__add-result'
															key={el}
														>
															<p>{el}</p>
															<button onClick={() => handleDeleteDelivery(el)}>
																<img
																	className='creating-vendor__add-result-del-btn'
																	src={deleteImg3}
																	alt='img'
																/>
															</button>
														</div>
													))}
											</div>
										</div>
									</AccordionDetails>
								</Accordion>
								<Accordion className='creating-vendor__vendor-accordion'>
									<AccordionSummary
										expandIcon={
											<p>
												<img
													className='creating-vendor__vendor-accordion-arrow'
													src={arrow2}
													alt='img'
												/>
											</p>
										}
										aria-controls='creating-vendor__vendor'
										id='creating-vendor__vendor'
									>
										<Typography>Оплата</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<div className='creating-vendor__add-btn-wrap'>
											<div className='creating-vendor__add-btn--wrap'>
												<div className='creating-product__input-lable'>
													Умови оплати
												</div>
												<textarea
													className={`creating-category__textarea creating-product__textarea`}
													value={conditionsPayment}
													onChange={(e) => setConditionsPayment(e.target.value)}
													placeholder='Введіть умову'
													rows={2}
													type='text'
												/>
											</div>
											<div className='creating-vendor__add-btn--wrap'>
												<div className='creating-product__input-lable'>
													Варіанти оплати
												</div>
												<input
													className='creating-category__input'
													value={payment}
													onChange={(e) => setPayment(e.target.value)}
													onKeyDown={(e) => handlePaymentKeyDown(e)}
													placeholder='Введіть варіант оплати'
													type='text'
												/>
												<img
													className='creating-vendor__add-btn'
													onClick={handleAddPayment}
													src={plusImg}
													alt='img'
												/>
											</div>
											<div className='creating-vendor__add-result-wrap'>
												{!!paymentOptions?.length &&
													paymentOptions.map((el) => (
														<div
															className='creating-vendor__add-result'
															key={el}
														>
															<p>{el}</p>
															<button onClick={() => handleDeletePayment(el)}>
																<img
																	className='creating-vendor__add-result-del-btn'
																	src={deleteImg3}
																	alt='img'
																/>
															</button>
														</div>
													))}
											</div>
										</div>
									</AccordionDetails>
								</Accordion>
							</div>
						)}

						{userVendorTabCreateValue === 3 && (
							<div className='creating-vendor__tab-2-wrap'>
								<div className='creating-vendor__input--wrap'>
									<div className='creating-vendor__input-lable'>SEO title</div>
									<input
										className='creating-category__input'
										value={seoDefaultTitle}
										onChange={(e) => setSeoDefaultTitle(e.target.value)}
										placeholder='SEO title'
										type='text'
									/>
								</div>
								<div className='creating-vendor__input--wrap'>
									<div className='creating-vendor__input-lable'>
										SEO description
									</div>
									<textarea
										className='creating-category__textarea'
										value={seoDefaultDescription}
										onChange={(e) => setSeoDefaultDescription(e.target.value)}
										placeholder='SEO description'
										rows={8}
										type='text'
									/>
								</div>
								<div className='creating-vendor__add-keyword-btn-wrap'>
									<div className='creating-vendor__input-lable'>
										SEO keyword
									</div>
									<div className='creating-vendor__add-btn--wrap'>
										<input
											className='creating-category__input'
											value={keywords}
											onChange={(e) => setKeywords(e.target.value)}
											onKeyDown={(e) => handleKeywordsKeyDown(e)}
											placeholder='SEO keyword'
											type='text'
										/>
										<img
											className='creating-vendor__add-btn'
											onClick={handleAddKeyword}
											src={plusImg}
											alt='img'
										/>
									</div>
									<div className='creating-vendor__add-result-keyword-wrap'>
										{!!seoDefaultKeywords?.length &&
											seoDefaultKeywords.map((el) => (
												<div className='select-characteristic__add-result-wrap'>
													<div className='creating-product__add-result'>
														<p>{el}</p>
														<button onClick={() => handleDeleteKeyword(el)}>
															<img
																className='select-characteristic__add-result-del-words-btn'
																src={close2}
																alt='img'
															/>
														</button>
													</div>
												</div>
											))}
									</div>
								</div>
								<div className='creating-vendor__input--wrap'>
									<div className='creating-vendor__input-lable'>
										SEO facebook title
									</div>
									<input
										className='creating-category__input'
										value={seoFacebookTitle}
										onChange={(e) => setSeoFacebookTitle(e.target.value)}
										placeholder='SEO facebook title'
										type='text'
									/>
								</div>
								<div className='creating-vendor__input--wrap'>
									<div className='creating-vendor__input-lable'>
										SEO facebook description
									</div>
									<input
										className='creating-category__input'
										value={seoFacebookDescription}
										onChange={(e) => setSeoFacebookDescription(e.target.value)}
										placeholder='SEO facebook description'
										type='text'
									/>
								</div>
								<div className='creating-vendor__input--wrap'>
									<div className='creating-vendor__input-lable'>
										SEO twitter title
									</div>
									<input
										className='creating-category__input'
										value={seoTwitterTitle}
										onChange={(e) => setSeoTwitterTitle(e.target.value)}
										placeholder='SEO twitter title'
										type='text'
									/>
								</div>
								<div className='creating-vendor__input--wrap'>
									<div className='creating-vendor__input-lable'>
										SEO twitter description
									</div>
									<input
										className='creating-category__input'
										value={seoTwitterDescription}
										onChange={(e) => setSeoTwitterDescription(e.target.value)}
										placeholder='SEO twitter description'
										type='text'
									/>
								</div>
							</div>
						)}

						{userVendorTabCreateValue === 2 && (
							<div className='creating-vendor__tab-3-wrap'>
								<div className='creating-vendor__create-img--wrap'>
									<div className='creating-vendor__create-img-title'>
										Додайте логотип
									</div>
									<div
										className='creating-vendor__create-img-wrap'
										onDrop={(e) => handleImageUploadLogo(e)}
										onDragOver={(e) => e.preventDefault()}
									>
										<div className='creating-vendor__create-img-text-wrap'>
											<div className='creating-vendor__create-img-text-img-wrap'>
												<img
													className='creating-vendor__create-img-text-img'
													src={uploadImg}
													alt='img'
												/>
											</div>
											<label
												className='creating-vendor__create-img-link-upload-lable'
												htmlFor='creationProductUserImages'
											>
												Клікніть завантажити
											</label>
											<span> або перетягніть зображення сюди</span>
											<p>
												Рекомендовано фото мають бути 1:1 (160 × 160 px).
												Формати які підтримуються: PNG, JPG.
											</p>
											<input
												className='creating-vendor__create-img-link-upload-input'
												onChange={(e) => uploadPhotoLogo(e.target.files[0])}
												type='file'
												accept='image/*'
												id='creationProductUserImages'
											/>
										</div>
									</div>

									{!!imageLogoFiles?.name?.length && (
										<div className='creating-vendor__upload-result-wrap'>
											<button
												className='creating-vendor__upload-result-del-btn'
												onClick={handleDeleteUploadLogoImg}
											>
												<img src={deleteImg2} alt='img' />
											</button>
											<img
												className='creating-vendor__upload-img-logo-result'
												src={URL.createObjectURL(imageLogoFiles)}
												alt='img'
											/>
										</div>
									)}
									{!imageLogoFiles?.name?.length && !!logoImage?.length && (
										<div className='creating-vendor__upload-result-wrap'>
											<button
												className='creating-vendor__upload-result-del-btn'
												onClick={handleDeleteImgLogo}
											>
												<img src={deleteImg2} alt='img' />
											</button>
											<img
												className='creating-vendor__upload-img-logo-result'
												src={logoImage}
												alt='img'
											/>
										</div>
									)}
								</div>

								<div className='creating-vendor__create-img--wrap'>
									<div className='creating-vendor__create-img-title'>
										Додайте фон
									</div>
									<div
										className='creating-vendor__create-img-wrap'
										onDrop={(e) => handleImageUploadBackground(e)}
										onDragOver={(e) => e.preventDefault()}
									>
										<div className='creating-vendor__create-img-text-wrap'>
											<div className='creating-vendor__create-img-text-img-wrap'>
												<img
													className='creating-vendor__create-img-text-img'
													src={uploadImg}
													alt='img'
												/>
											</div>
											<label
												className='creating-vendor__create-img-link-upload-lable'
												htmlFor='creationProductUserBackground'
											>
												Клікніть завантажити
											</label>
											<span> або перетягніть зображення сюди</span>
											<p>
												Рекомендовано фото мають бути 5:1 (2000 × 400 px).
												Формати які підтримуються: PNG, JPG.
											</p>
											<input
												className='creating-vendor__create-img-link-upload-input'
												onChange={(e) =>
													uploadPhotoBackground(e.target.files[0])
												}
												type='file'
												accept='image/*'
												id='creationProductUserBackground'
											/>
										</div>
									</div>

									{!!backgroundImageFile?.name?.length && (
										<div className='creating-vendor__upload-result-background-wrap'>
											<button
												className='creating-vendor__upload-result-del-background-btn'
												onClick={handleDeleteUploadBackgroundImg}
											>
												<img src={deleteImg2} alt='img' />
											</button>
											<img
												className='creating-vendor__upload-img-background-result'
												src={URL.createObjectURL(backgroundImageFile)}
												alt='img'
											/>
										</div>
									)}
									{!backgroundImageFile?.name?.length &&
										!!backgroundImage?.length && (
											<div className='creating-vendor__upload-result-background-wrap'>
												<button
													className='creating-vendor__upload-result-del-background-btn'
													onClick={handleDeleteImgBackground}
												>
													<img src={deleteImg2} alt='img' />
												</button>
												<img
													className='creating-vendor__upload-img-background-result'
													src={backgroundImage}
													alt='img'
												/>
											</div>
										)}
								</div>

								<div className='creating-vendor__create-img--wrap'>
									<div className='creating-vendor__create-img-title'>
										Додайте документи
									</div>
									<div
										className='creating-vendor__create-img-wrap'
										onDrop={(e) => handleUploadVendorNewDoc(e)}
										onDragOver={(e) => e.preventDefault()}
									>
										<div className='creating-vendor__create-img-text-wrap'>
											<div className='creating-vendor__create-img-text-img-wrap'>
												<img
													className='creating-vendor__create-img-text-img'
													src={uploadImg}
													alt='img'
												/>
											</div>
											<label
												className='creating-vendor__create-img-link-upload-lable'
												htmlFor='creationProductUserDoc'
											>
												Клікніть завантажити
											</label>
											<span> або перетягніть зображення сюди</span>
											<p>Формати які підтримуються: DOC, PDF, XLS.</p>
											<input
												className='creating-vendor__create-img-link-upload-input'
												onChange={(e) =>
													handleAddVendorNewDoc(e.target.files[0])
												}
												type='file'
												accept='image/*'
												id='creationProductUserDoc'
											/>
										</div>
									</div>

									{!!vendorNewDocArr?.length &&
										vendorNewDocArr.map((el) => (
											<div
												className='creating-vendor__upload-result-doc-wrap'
												key={el}
											>
												<div className='creating-vendor__upload-result-new-doc'>
													<img
														className='creating-vendor__upload-result-new-doc-img'
														src={fileImg}
														alt='img'
													/>
												</div>
												<p className='creating-vendor__upload-result-new-doc-name'>
													{el.name}
												</p>
												<button
													className='creating-vendor__upload-result-del-btn-doc'
													onClick={() => handleDeleteVendorNewDoc(el.name)}
												>
													<img src={deleteImg2} alt='img' />
												</button>
											</div>
										))}
									{!!vendorDocs?.length &&
										vendorDocs.map((el) => (
											<div className='creating-vendor__upload-result-doc-wrap'>
												<a
													className='creating-vendor__upload-result-new-doc'
													href={el}
													download
												>
													<img
														className='creating-vendor__upload-result-new-doc-img'
														src={fileImg}
														alt='img'
													/>
												</a>
												<p
													className={`creating-vendor__upload-result-new-doc-name ${
														!isOpenMenu
															? 'creating-vendor__upload-result-new-doc-name--is-open-menu'
															: ''
													}`}
												>
													{handleGetDocName(el)}
												</p>
												<button
													className='creating-vendor__upload-result-del-btn-doc'
													onClick={() => handleDeleteVendorDoc(el)}
												>
													<img src={deleteImg2} alt='img' />
												</button>
											</div>
										))}
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
}

export default CreatingVendorView;
