import './Footer.css';
import { memo } from 'react';
import logo from '../../assets/logo.svg';
import facebook from '../../assets/facebook.svg';
import instagram from '../../assets/instagram.svg';
import youTube from '../../assets/youTube.svg';
import { NavLink } from 'react-router-dom';
import { setScrollPositionBeforeView } from '../../store/homeSlice';
import { useDispatch } from 'react-redux';

function Footer() {
    const dispatch = useDispatch();
    
    const handleScrollTop = () => {
        dispatch(setScrollPositionBeforeView({}))
    }
    
    const handleClickLogo = () => {
        dispatch(setScrollPositionBeforeView({}))
        window.scroll({top: 0})
    }

    return (
        <div className="footer container">
            <div className="footer__bottom">
                <div className="footer__bottom-info">
                    <div className="footer__bottom-info-social">
                        <div className="footer__bottom-info-social-logo-wrap">
                            <NavLink onClick={handleClickLogo} to='/'><img className="footer__bottom-info-social-logo" src={logo} alt='img'/></NavLink> 
                        </div>
                        <div className="footer__bottom-info-social-link-wrap">
                            <a className="footer__bottom-info-social-link" href='https://www.facebook.com/vyrobyua' target='_blank'><img src={facebook} alt='facebook'/></a> 
                            <a className="footer__bottom-info-social-link" href='https://www.instagram.com/vyroby_ua/' target='_blank'><img src={instagram} alt='instagram'/></a> 
                            <a className="footer__bottom-info-social-link" href='https://www.youtube.com/@vyroby' target='_blank'><img src={youTube} alt='youtube'/></a> 
                        </div>
                    </div>
                    <div className="footer__bottom-info-link--wrap">
                        <div className="footer__bottom-info-link--left">
                            <div className="footer__bottom-info-link-wrap">
                                <div className="footer__bottom-info-link-title">Про нас</div>
                                <NavLink className="footer__bottom-info-link" onClick={handleScrollTop} to='about'>Про маркетплейс</NavLink>
                                <a className="footer__bottom-info-link" href='/offer' target='_blank'>Договір публічної оферти</a>
                                <NavLink className="footer__bottom-info-link" onClick={handleScrollTop} to='contacts'>Контакти</NavLink>
                            </div>
                            <div className="footer__bottom-info-link-wrap">
                                <div className="footer__bottom-info-link-title">Покупцям</div>
                                <NavLink className="footer__bottom-info-link" to='how-to-buy'>Як купувати на маркетплейсі</NavLink>
                                <NavLink className="footer__bottom-info-link" onClick={handleClickLogo} to='catalog'>Усі категорії товарів</NavLink>
                                <NavLink className="footer__bottom-info-link" to='delivery'>Оплата, доставка та повернення</NavLink>
                                <NavLink className="footer__bottom-info-link" to='pid-zamovlennya'>Індивідуальне виготовлення меблів</NavLink>
                            </div>
                        </div>
                        <div className="footer__bottom-info-link-wrap">
                            <div className="footer__bottom-info-link-title">Виробникам</div>
                            <NavLink className="footer__bottom-info-link" to='partners'>Продавати на платформі</NavLink>
                        </div>
                    </div>
                    <div className="footer__bottom-info-social footer__bottom-info-social-full">
                        <NavLink className="footer__bottom-info-social-logo-link" onClick={handleScrollTop} to='/'><img className="footer__bottom-info-social-logo" src={logo} alt='img'/></NavLink> 
                    </div>
                </div>
                <div className="footer__bottom-producer"><div className="footer__bottom-producer-text">© vyroby.com, 2021 - 2024. Всі права захищено </div></div>
            </div>
        </div>
    );
}

export default memo(Footer);