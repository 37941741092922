import './DeliveryView.css';
import { useSelector } from 'react-redux';
import SeoBlock from '../../components/SeoBlock/SeoBlock';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function DeliveryView() {
	const seo = useSelector((state) => state.homeSlice.seo);
	const scrollPositionBeforeView = useSelector(
		(state) => state.homeSlice.scrollPositionBeforeView
	);
	const location = useLocation();

	useEffect(() => {
		setTimeout(() => {
			window.scroll({
				top: scrollPositionBeforeView[location.pathname]
					? scrollPositionBeforeView[location.pathname]
					: 0,
			});
		}, 10);
	}, []);

	return (
		<div className='delivery-view'>
			<SeoBlock
				title={seo.title_payment}
				description={seo.description_payment}
				keywords={seo.keywords_payment}
			/>
			<div className='delivery-view--wrap'>
				<div className='delivery-view__title-wrap'>
					<h1 className='delivery-view__title container'>
						Оплата, доставка та повернення
					</h1>
				</div>

				<div className='delivery-view__info-wrap container'>
					<div className='delivery-view__info'>
						<h2 className='delivery-view__info-title'>
							Монтаж та установка виробів
						</h2>
						<div className='delivery-view__info-text'>
							Якщо ваш виріб був замовлений під індивідуальне виготовлення і
							монтаж, установку проводить сам виробник, то обов'язково приймайте
							та перевіряйте роботу, виріб одразу після закінчення робіт у
							присутності представника виробника згідно договору підписаного з
							виробником!
						</div>
					</div>
					<div className='delivery-view__info'>
						<h2 className='delivery-view__info-title'>Оплата</h2>
						<div className='delivery-view__info-text'>
							Оскільки Vyroby.com є маркетплейсом, на якому представлені різні
							виробники (продавці) меблів, то відповідно варіанти оплати та
							доставки будуть різні в залежності від виробника!
							<br />
							<br />
							Рекомендуємо обов'язково підписувати договір із виробником, якщо
							товар буде виготовлятися індивідуально, має довгий період
							виготовлення, що убезпечить вашу покупку та буде гарантією якості!
							<br />
							<br />
							Клієнт може оплатити товар одразу на сайті при оформленні
							замовлення на рахунок маркетплейсу або напряму виробнику, одним із
							способів запропонованим виробником. Найчастіше використовується:
							передоплата, при отриманні, післяплата, оплата банківською картою!
						</div>
					</div>
					<div className='delivery-view__info'>
						<h2 className='delivery-view__info-title'>Доставка</h2>
						<div className='delivery-view__info-text'>
							Використовуються такі варіанти доставки: поштовими службами,
							безпосередньо силами виробника, курʼєром.
							<br />
							<br />
							<b>Отримання товару або встановлення товару</b>
							<br />
							<br />
							При отриманні товару обов`язково перевіряйте товар на
							відповідність замовленню та якості:
							<br />
							<ul className='delivery-view__info-text-ul'>
								<li>у поштовому відділенні, якщо отримуєте у відділенні</li>
								<li>у присутності курʼєра, якщо кур'єрська доставка</li>
								<li>
									у присутності представника виробника, якщо доставка силами
									виробника
								</li>
							</ul>
							<br />
							Якщо товар пошкоджений, у прісутності співробітника служби
							доставки складіть акт про пошкодження!
							<br />
							<br />
							Якщо недоліки товару були виявлені пізніше, то ви також маєте
							повне право звернутися до продавця згідно Закону України «Про
							захист прав споживачів» щодо усунення недоліків, обміну чи
							повернення товару.
							<br />
							<br />
							Обов'язково зберігайте документи, що підтверджують покупку, вони
							необхідні для гарантійного обслуговування або для підтвердження
							факту угоди між вами і продавцем у разі виникнення проблемних
							ситуацій.
						</div>
					</div>
					<div className='delivery-view__info'>
						<h2 className='delivery-view__info-title'>Повернення</h2>
						<div className='delivery-view__info-text'>
							Статтею 9 чинного Закону України{' '}
							<a
								className='delivery-view__info-text-link'
								href='https://zakon.rada.gov.ua/laws/show/1023-12#Text'
								target='_blank'
							>
								«Про захист прав споживачів»
							</a>{' '}
							передбачено право споживача обміняти непродовольчий товар належної
							якості на аналогічний, якщо куплений товар не підійшов за формою,
							габаритами, фасоном, кольором, розміром або з інших причин не може
							бути використаний за призначенням, якщо даний товар не
							використовувався і якщо збережено його товарний вигляд, споживчі
							властивості, пломби, ярлики та розрахунковий документ, виданий
							продавцем разом з товаром.
							<br />
							<br />
							Продавець не має права відмовити в обміні (поверненні) товару, що
							не включений до переліку, якщо він відповідає всім вимогам,
							передбаченим ст. 9 Закону (збережений товарний вигляд, ярлики,
							пломби, є розрахунковий документ і т. ін.).
							<br />
							<br />
							Виключення Кабінетом Міністрів України затверджено перелік товарів
							належної якості, що не підлягають обміну або поверненню (Постанова
							КМУ від 19 березня 1994 № 172). З актуальним вичерпним Переліком
							можна ознайомитися за
							<a
								className='delivery-view__info-text-link'
								href='https://zakon.rada.gov.ua/laws/show/172-94-%D0%BF#Text'
								target='_blank'
							>
								посиланням
							</a>
							.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DeliveryView;
