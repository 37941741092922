import './ModalWindow.css';
import { memo } from 'react';

function ModalWindow({title='', text='', handleClick, leftBtn='', rightBtn='', notBtn=false, addStyles={}, children}) {
    const handleClickBtn = (boolean) => {
        handleClick(boolean)
    };
    
    const handleClose = () => {
        handleClick(false)
    };

    return (
        <div className="modal-window">
            <div className="modal-window-wrap" onClick={handleClose}></div>
            <div className='modal-window--wrap' style={addStyles} onClick={(e) => e.stopPropagation()}>
                {!!title.length && <h3 className="modal-window__title">{title}</h3>}
                {!!text?.length && <div className="modal-window__text">{text}</div>}
                {children}
                {
                    !notBtn &&
                        <div className="modal-window__btn-wrap">
                            <button onClick={() => handleClickBtn(false)} className="modal-window__btn modal-window__btn2">{!!leftBtn?.length ? leftBtn : 'Скасувати'}</button>
                            <button onClick={() => handleClickBtn(true)} className="modal-window__btn modal-window__btn1">{!!rightBtn?.length ? rightBtn : 'Підтвердити'}</button>
                        </div>
                }
            </div>
        </div>
    );
}

export default memo(ModalWindow);