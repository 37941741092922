import {
	NOT_DELETED_CATEGORY_ID,
	NOT_DELETED_SUB_CATEGORY_ID,
} from '../helpers/Config';

export const disabledScroll = (setModalScrollPosition) => {
	if (setModalScrollPosition) {
		setModalScrollPosition(window.scrollY);
		document.body.style.cssText = `
          overflow: hidden;
          position: fixed;
          top: -${window.scrollY}px;
          left: 0;
          width: 100vw;
          padding: 0 !important;
          padding-right: ${
						window.innerWidth - document.body.offsetWidth
					}px !important
          `;
		document.documentElement.style.scrollBehavior = 'unset';
	}
};

export const refreshSavedScroll = (path) => {
	window.scrollTo(0, 0);
	const savedScrollYfromLs = localStorage.getItem('scrollY');
	const savedScrollYRecords =
		savedScrollYfromLs !== null ? JSON.parse(savedScrollYfromLs) : {};
	if (savedScrollYRecords[path]) {
		delete savedScrollYRecords[path];

		localStorage.setItem('scrollY', JSON.stringify(savedScrollYRecords));
	}
};

export const enabledScroll = (modalScrollPosition) => {
	document.body.style.cssText = '';
	window.scroll({ top: modalScrollPosition });
	document.documentElement.style.scrollBehavior = '';
};

export const handleReturnItemVariantGtm = (product) => {
	const res = [];
	if (product?.variations?.length) {
		let resMaterial = product.variations.find((el) => el.name === 'Матеріал');
		let resColor = product.variations.find((el) => el.name === 'Колір');
		let otherVar = product.variations.filter(
			(el) => el.name !== 'Матеріал' && el.name !== 'Колір'
		);
		if (resMaterial?.name?.length) {
			res.push(product.material.title);
		}
		if (resColor?.name?.length) {
			res.push(product.color_name);
		}
		if (otherVar?.length) {
			otherVar.map((el) => {
				let char = product.characteristics.find((ell) => ell.name === el.name);
				if (char?.value?.length) {
					res.push(char.value);
				}
			});
		}
	}
	return res?.length ? res.join(', ') : '';
};

export const handleReturnProductCategoryAll = (product, categoriesShop) => {
	let productCat = handleCategoriesMap(categoriesShop).find(
		(el) => el._id === product.category_id
	);
	let resCat = {};
	let resSubCat = {};
	if (!productCat?.parent_id || productCat?.parent_id === 'null') {
		resCat = productCat;
	} else {
		resSubCat = productCat;
		resCat = handleCategoriesMap(categoriesShop).find(
			(el) => el._id === productCat.parent_id
		);
	}
	return { cat: resCat, subCat: resSubCat };
};

export const validNumber = (value, setValue) => {
	if (/^\d+$/.test(value)) {
		setValue(value);
	} else {
		setValue('');
	}
};

export const validEmail = (value) => {
	return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
		value
	);
};

export const fetchGetData = async (url, finallyFun = null) => {
	try {
		const response = await fetch(url);
		const data = await response.json();
		return data;
	} catch (error) {
		console.error('Error:', error);
		return null;
	} finally {
		if (finallyFun) {
			finallyFun();
		}
	}
};

export const fetchRequest = async (
	method,
	url,
	data,
	catchFunc = null,
	finallyFunc = null
) => {
	try {
		const response = await fetch(url, {
			method: method,
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
			},
		});

		const res = await response.json();
		return res;
	} catch (error) {
		console.error('Error:', error);
		if (catchFunc) {
			catchFunc(error);
		}
	} finally {
		if (finallyFunc) {
			finallyFunc();
		}
	}
};

export const handleCategoriesMap = (cat) => {
	let res = [];
	let res2 = cat.map((el) => {
		res.push(el);
		if (el.sub_categories?.length) {
			el.sub_categories.map((ell) => {
				res.push(ell);
			});
		}
	});
	return res;
};

export const handleGetDocName = (str) => {
	let arr = str.split('-');
	let res = arr[arr.length - 1];
	return res.length > 40 ? res.slice(-40) : res;
};

export const handleCapitalizeString = (string) => {
	const capitalizedString =
		string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	return capitalizedString;
};

export const handlePriceReplace = (price) => {
	if (price != null && typeof price !== 'boolean') {
		const newPrice = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
		return newPrice;
	}
	return '';
};

export const handleCheckShortId = (product) => {
	let res = '/';
	if (product?._id?.length) {
		if (product?.custom_id?.length && product?.short_id?.length) {
			res = `/product/${product.short_id + product.custom_id}`;
			return res;
		}
		if (product?.custom_id?.length && !product?.short_id?.length) {
			res = `/product/${product._id + product.custom_id}`;
			return res;
		}
		if (!product?.custom_id?.length && product?.short_id?.length) {
			res = `/product/${product.short_id}`;
			return res;
		}
		if (!product?.custom_id?.length && !product?.short_id?.length) {
			res = `/product/${product._id}`;
			return res;
		}
	}
	return res;
};

export const handleOpenNewPage = (
	categoriesArr,
	categories,
	navigate = null,
	dispatch = null
) => {
	if (!categories?._id?.length && navigate && dispatch) {
		navigate('/catalog');
		refreshSavedScroll('/catalog');
		return;
	}
	if (categories._id === NOT_DELETED_CATEGORY_ID) return;
	if (categories._id === NOT_DELETED_SUB_CATEGORY_ID) {
		navigate('/catalog/collections');
		refreshSavedScroll('/catalog/collections');
	} else {
		if (categories?.alias?.length) {
			if (categories?.parent_id === null || categories?.parent_id === 'null') {
				navigate(`/catalog/${categories.alias}`);
				refreshSavedScroll(`/catalog/${categories.alias}`);
			} else {
				if (categoriesArr?.length) {
					let res = handleCategoriesMap(categoriesArr).find(
						(el) => el._id === categories.parent_id
					);
					if (res) {
						navigate(`/catalog/${res.alias}/${categories.alias}`);
						refreshSavedScroll(`/catalog/${res.alias}/${categories.alias}`);
					} else {
						navigate('/catalog');
						refreshSavedScroll('/catalog');
					}
				} else {
					navigate('/catalog');
					refreshSavedScroll('/catalog');
				}
			}
		} else {
			navigate('/catalog');
			refreshSavedScroll('/catalog');
		}
	}
};

export const handleOpenNewCategories = (categories, categoriesArr = null) => {
	if (categories._id === NOT_DELETED_CATEGORY_ID) return;
	if (categories._id === NOT_DELETED_SUB_CATEGORY_ID) {
		return '/catalog/collections';
	} else {
		if (categories?.alias?.length) {
			if (categories?.parent_id === null || categories?.parent_id === 'null') {
				return `/catalog/${categories.alias}`;
			} else {
				if (categoriesArr?.length) {
					let res = handleCategoriesMap(categoriesArr).find(
						(el) => el._id === categories.parent_id
					);
					if (res) {
						return `/catalog/${res.alias}/${categories.alias}`;
					} else {
						return '/catalog';
					}
				} else {
					return '/catalog';
				}
			}
		} else {
			return '/catalog';
		}
	}
};

export const handleFormattingDate = (time) => {
	let res = (
		<span>
			{`${
				(new Date(time).getDate() < 10 ? '0' : '') + new Date(time).getDate()
			}.${
				(new Date(time).getMonth() + 1 < 10 ? '0' : '') +
				(new Date(time).getMonth() + 1)
			}.${new Date(time).getFullYear()}, ${new Date(time).getHours()}:${
				(new Date(time).getMinutes() < 10 ? '0' : '') +
				new Date(time).getMinutes()
			}`}
		</span>
	);
	return res;
};

export const formatPhoneNumber = (phoneNumber) => {
	if (phoneNumber?.length) {
		const strippedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');
		if (strippedPhoneNumber.length === 12) {
			let formattedPhoneNumber = '+380';
			if (strippedPhoneNumber.startsWith('380')) {
				formattedPhoneNumber += ` (${strippedPhoneNumber.substring(
					3,
					5
				)}) ${strippedPhoneNumber.substring(
					5,
					8
				)}-${strippedPhoneNumber.substring(
					8,
					10
				)}-${strippedPhoneNumber.substring(10, 12)}`;
			} else if (strippedPhoneNumber.startsWith('0')) {
				formattedPhoneNumber += ` (${strippedPhoneNumber.substring(
					1,
					3
				)}) ${strippedPhoneNumber.substring(
					3,
					6
				)}-${strippedPhoneNumber.substring(
					6,
					8
				)}-${strippedPhoneNumber.substring(8, 10)}`;
			} else {
				formattedPhoneNumber += ` (${strippedPhoneNumber.substring(
					0,
					2
				)}) ${strippedPhoneNumber.substring(
					2,
					5
				)}-${strippedPhoneNumber.substring(
					5,
					7
				)}-${strippedPhoneNumber.substring(7, 9)}`;
			}
			return formattedPhoneNumber;
		} else {
			return phoneNumber;
		}
	}
};

export const handleSortValue = (values) => {
	if (values?.length) {
		return [...values].sort((a, b) => {
			let [aStart, aEnd] = a.split('-').map((num) => parseFloat(num) || num);
			let [bStart, bEnd] = b.split('-').map((num) => parseFloat(num) || num);

			if (typeof aStart === 'number' && typeof bStart === 'number') {
				if (aStart !== bStart) {
					return aStart - bStart;
				} else if (!isNaN(aEnd) && !isNaN(bEnd)) {
					return aEnd - bEnd;
				} else {
					return 0;
				}
			} else {
				return a.localeCompare(b);
			}
		});
	}
};

export const handleSortCharacteristcs = (characteristcs) => {
	return characteristcs.slice().sort((a, b) => {
		const nameA = a.name?.toLowerCase() || '';
		const nameB = b.name?.toLowerCase() || '';
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}
		return 0;
	});
};
