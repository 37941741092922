import { useEffect, useState } from 'react';
import './PaginationSwiper.css';
import Dots from 'react-carousel-dots';

function PaginationSwiper({paginationArr, swiperActiveIndex}) {
    
    return (
        <div className={`pagination-swiper ${paginationArr?.length < 5 ? 'pagination-swiper--1' : ''}`}>
            {
                (!!paginationArr?.length && paginationArr?.length > 5) && 
                    <Dots length={paginationArr.length} visible={swiperActiveIndex === 0 || swiperActiveIndex === 1 || swiperActiveIndex === 2 ? 5 : 4} active={swiperActiveIndex} />
            }
            {
                (!!paginationArr?.length && paginationArr?.length <= 5) && paginationArr.map((el, i)=> (
                    <span className={`pagination-swiper__bullet ${swiperActiveIndex === i ? 'pagination-swiper__bullet--active' : ''}`} key={i}>
                    </span>
                ))
            }
        </div>
    );
}

export default PaginationSwiper;