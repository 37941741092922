import './CreatingCategory.css';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	MenuItem,
	Select,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	fetchGetData,
	fetchRequest,
	handleCapitalizeString,
	handleCategoriesMap,
	handleSortCharacteristcs,
	validNumber,
} from '../../helpers/Utils';
import errorCircleImg from '../../assets/errorCircleImg.svg';
import deleteImg2 from '../../assets/deleteImg2.svg';
import deleteImg4 from '../../assets/deleteImg4.svg';
import eye1 from '../../assets/eye1.svg';
import arrow2 from '../../assets/arrow2.svg';
import uploadImg from '../../assets/uploadImg.svg';
import eyeHide1 from '../../assets/eyeHide1.svg';
import category from '../../assets/category.svg';
import saveImg from '../../assets/saveImg.svg';
import plusImg from '../../assets/plusImg.svg';
import close2 from '../../assets/close2.svg';
import editIcon1 from '../../assets/editIcon1.svg';
import {
	setEditeCategory,
	setParentCategory,
	setShowMessageObj,
} from '../../store/userSlice';
import AddCharacteristics from '../AddCharacteristics/AddCharacteristics';
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import CheckboxWrap from '../CheckboxWrap/CheckboxWrap';
import { getCategories } from '../../store/userSlice';
import PreloaderCustom from '../PreloaderCustom/PreloaderCustom';
import ModalWindow from '../ModalWindow/ModalWindow';
import SeoBlock from '../SeoBlock/SeoBlock';

function CreatingCategory() {
	const [searchParams, setSearchParams] = useSearchParams();
	const tabSearchParams = Number(searchParams.get('tab'));

	const editeCategory = useSelector((state) => state.userSlice.editeCategory);
	const parentCategory = useSelector((state) => state.userSlice.parentCategory);
	const categories = useSelector((state) => state.userSlice.categories);
	const [name, setName] = useState('');
	const [alias, setAlias] = useState('');
	const [alias_old, setAlias_old] = useState('');
	const [description, setDescription] = useState('');
	const [priority, setPriority] = useState(0);
	const [commissionPercentage, setCommissionPercentage] = useState(0);
	const [seoDefaultTitle, setSeoDefaultTitle] = useState('');
	const [seoDefaultDescription, setSeoDefaultDescription] = useState('');
	const [seoFacebookTitle, setSeoFacebookTitle] = useState('');
	const [seoFacebookDescription, setSeoFacebookDescription] = useState('');
	const [seoTwitterTitle, setSeoTwitterTitle] = useState('');
	const [seoTwitterDescription, setSeoTwitterDescription] = useState('');
	const [keywords, setKeywords] = useState('');
	const [seoDefaultKeywords, setSeoDefaultKeywords] = useState([]);
	const [image, setImage] = useState({});
	const [characteristics, setCharacteristics] = useState('');
	const [characteristicsOptions, setCharacteristicsOptions] = useState([]);
	const [allCharacteristicsOptions, setAllCharacteristicsOptions] = useState(
		[]
	);
	const [oldImage, setOldImage] = useState('');
	const [tabValue, setTabValue] = useState(
		tabSearchParams ? tabSearchParams : 0
	);
	const [isPreloader, setIsPreloader] = useState(true);
	const [isEditeCharacteristics, setIsEditeCharacteristics] = useState('');
	const [editeCharacteristicsName, setEditeCharacteristicsName] = useState('');
	const [newCharacteristicsName, setNewCharacteristicsName] = useState('');
	const [focusAddCharacteristics, setFocusAddCharacteristics] = useState('');
	const [isErrorTestName, setIsErrorTestName] = useState(false);
	const [isErrorCharacteristicsName, setIsErrorCharacteristicsName] =
		useState(false);
	const [errorName, setErrorName] = useState('');
	const [errorAlias, setErrorAlias] = useState('');
	const [seo_google_product_category, setSeo_google_product_category] =
		useState('');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { userCategoryId } = useParams();
	const transliterator = new CyrillicToTranslit({ preset: 'uk' });
	let token = localStorage.getItem('token');

	useEffect(() => {
		if (!userCategoryId?.length) {
			setIsPreloader(false);
		}

		return () => {
			handleClearInput();
			dispatch(setEditeCategory({}));
			dispatch(setParentCategory({}));
		};
	}, []);

	useEffect(() => {
		if (categories?.length && userCategoryId?.length) {
			let res = handleCategoriesMap(categories).find(
				(el) => el._id === userCategoryId
			);
			dispatch(setEditeCategory(res));
			if (res?.parent_id?.length && res?.parent_id !== 'null') {
				let resArr = categories.filter((el) => el._id === res.parent_id);
				dispatch(setParentCategory(resArr[0]));
				setAllCharacteristicsOptions(resArr[0].characteristics_options);
			}
			setIsPreloader(false);
		}
	}, [categories]);

	useEffect(() => {
		if (editeCategory?._id?.length) {
			setName(editeCategory.name?.length ? editeCategory.name : '');
			setAlias(editeCategory.alias?.length ? editeCategory.alias : '');
			setAlias_old(
				editeCategory.alias_old?.length ? editeCategory.alias_old : ''
			);
			setPriority(editeCategory.priority ? editeCategory.priority : 0);
			setDescription(
				editeCategory.description?.length ? editeCategory.description : ''
			);
			setCommissionPercentage(
				editeCategory.commission_percentage
					? editeCategory.commission_percentage + ''
					: 0
			);
			setSeoDefaultTitle(
				editeCategory.seo_default_title?.length
					? editeCategory.seo_default_title
					: ''
			);
			setSeoDefaultDescription(
				editeCategory.seo_default_description?.length
					? editeCategory.seo_default_description
					: ''
			);
			setSeoFacebookTitle(
				editeCategory.seo_facebook_title?.length
					? editeCategory.seo_facebook_title
					: ''
			);
			setSeoFacebookDescription(
				editeCategory.seo_facebook_description?.length
					? editeCategory.seo_facebook_description
					: ''
			);
			setSeoTwitterTitle(
				editeCategory.seo_twitter_title?.length
					? editeCategory.seo_twitter_title
					: ''
			);
			setSeoTwitterDescription(
				editeCategory.seo_twitter_description?.length
					? editeCategory.seo_twitter_description
					: ''
			);
			setSeoDefaultKeywords(
				editeCategory.seo_default_keywords?.length
					? editeCategory.seo_default_keywords
					: []
			);
			setOldImage(editeCategory.image?.length ? editeCategory.image : '');
			setSeo_google_product_category(
				editeCategory.seo_google_product_category?.length
					? editeCategory.seo_google_product_category
					: ''
			);

			if (parentCategory.characteristics_options?.length) {
				setAllCharacteristicsOptions(parentCategory.characteristics_options);
			}
			setCharacteristicsOptions(
				editeCategory.characteristics_options?.length
					? editeCategory.characteristics_options
					: []
			);
		}
	}, [editeCategory]);

	useEffect(() => {
		if (
			(tabSearchParams && tabSearchParams !== tabValue) ||
			(!tabSearchParams && tabValue !== 0)
		) {
			setTabValue(tabSearchParams ? tabSearchParams : 0);
		}
	}, [tabSearchParams]);

	const handleKeyDownKeyword = (e) => {
		if (e.key == 'Enter') {
			handleAddKeyword();
		}
	};

	const handleAddKeyword = () => {
		if (keywords?.length) {
			setSeoDefaultKeywords([...seoDefaultKeywords, keywords]);
			setKeywords('');
		}
	};

	const handleKeyDown = (e) => {
		if (e.key == 'Enter') {
			handleAddCharacteristics();
		}
	};

	const handleAddCharacteristics = () => {
		if (characteristics?.length) {
			if (
				characteristicsOptions?.length &&
				!characteristicsOptions.find((el) => el.name === characteristics.trim())
			) {
				setCharacteristics('');
				setCharacteristicsOptions([
					...characteristicsOptions,
					{ name: characteristics, value: [] },
				]);
				setIsErrorCharacteristicsName(false);
				return;
			}
			if (
				characteristicsOptions?.length &&
				characteristicsOptions.find((el) => el.name === characteristics.trim())
					.name?.length
			) {
				setIsErrorCharacteristicsName(true);
				return;
			}
			if (!characteristicsOptions?.length) {
				setCharacteristics('');
				setCharacteristicsOptions([
					{ name: characteristics.trim(), value: [] },
				]);
			}
		}
	};

	const handleKeyDownCharacteristics = (e) => {
		if (e.key == 'Enter') {
			handleAddNewCharacteristics();
		}
	};

	const handleAddNewCharacteristics = () => {
		if (characteristics?.length) {
			if (
				!allCharacteristicsOptions?.length ||
				(allCharacteristicsOptions?.length &&
					!allCharacteristicsOptions.find(
						(el) => el.name === characteristics.trim()
					))
			) {
				setCharacteristics('');
				setCharacteristicsOptions([
					...characteristicsOptions,
					{ name: characteristics, value: [] },
				]);
				setAllCharacteristicsOptions([
					...allCharacteristicsOptions,
					{ name: characteristics, value: [] },
				]);
				setIsErrorCharacteristicsName(false);
				return;
			}
			if (
				allCharacteristicsOptions?.length &&
				allCharacteristicsOptions.find(
					(el) => el.name === characteristics.trim()
				).name?.length
			) {
				setIsErrorCharacteristicsName(true);
				return;
			}
		}
	};

	const handleAddSubCharacteristics = (str) => {
		let obj = JSON.parse(str);
		let res = characteristicsOptions.find((el) => el.name === obj.name);
		if (res) {
			setCharacteristicsOptions([
				...characteristicsOptions.filter((el) => el.name !== obj.name),
			]);
		} else {
			setCharacteristicsOptions([...characteristicsOptions, obj]);
		}
	};

	const handleDeleteKeyword = (str) => {
		setSeoDefaultKeywords([...seoDefaultKeywords.filter((el) => el !== str)]);
	};

	const handleDeleteCharacteristics = (name) => {
		setCharacteristicsOptions([
			...characteristicsOptions.filter((el) => el.name !== name),
		]);
	};

	const handleEditeCharacteristics = (name) => {
		setIsEditeCharacteristics(true);
		setEditeCharacteristicsName(name);
	};

	const handleIsEditeCharacteristics = (boolean) => {
		if (boolean) {
			let isTestName = editeCategory.characteristics_options.some(
				(el) => el.name === newCharacteristicsName
			);
			if (isTestName) {
				setIsErrorTestName(true);
			} else {
				let data = {
					_id: editeCategory._id,
					name: editeCharacteristicsName,
					new_name: newCharacteristicsName,
				};

				fetchRequest(
					'POST',
					`${process.env.REACT_APP_BASE_URL}/categories/characteritics?token=${token}`,
					data,
					() =>
						dispatch(
							setShowMessageObj({
								open: true,
								status: 'error',
								message: 'Сталася помилка',
							})
						)
				).then((res) => {
					if (res.success && res.data) {
						dispatch(setEditeCategory(res.data));
						setIsEditeCharacteristics(false);
						setEditeCharacteristicsName('');
						setNewCharacteristicsName('');
						setIsErrorTestName(false);
						dispatch(
							setShowMessageObj({
								open: true,
								status: 'success',
								message: 'Дані оновлено',
							})
						);
					} else {
						console.log('POST CreatingCategory', res);
					}
				});
			}
		} else {
			setIsEditeCharacteristics(false);
			setEditeCharacteristicsName('');
			setNewCharacteristicsName('');
			setIsErrorTestName(false);
		}
	};

	const handleHideCharacteristics = (name) => {
		let res = characteristicsOptions.map((el) => {
			if (el.name === name) {
				el = { ...el, is_hide: el.is_hide ? false : true };
				return el;
			}
			return el;
		});
		setCharacteristicsOptions(res);
	};

	const handleDeleteNewCharacteristicsValue = (characteristicsName, value) => {
		setCharacteristicsOptions([
			...characteristicsOptions.map((el) => {
				if (el.name === characteristicsName) {
					el = { ...el, value: [...el.value.filter((ell) => ell !== value)] };
					return el;
				}
				return el;
			}),
		]);
		setAllCharacteristicsOptions([
			...allCharacteristicsOptions.map((el) => {
				if (el.name === characteristicsName) {
					el = { ...el, value: [...el.value.filter((ell) => ell !== value)] };
					return el;
				}
				return el;
			}),
		]);
	};

	const handleDeleteUploadImg = () => {
		setImage({});
	};

	const handleDeleteImg = () => {
		setOldImage('');
	};

	const handleImageUpload = (e) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		if (file.name?.length) {
			setImage(file);
		}
	};

	const uploadPhoto = (image_file) => {
		if (image_file?.name?.length) {
			setImage(image_file);
		}
	};

	const handleSetNameCategory = (str) => {
		setName(str);
		let res = str.replace(/[^a-zA-Zа-яА-ЯіІїЇєЄ\s]/g, '');
		if (res?.length) {
			const latinText = transliterator
				.transform(res)
				.trim()
				.split(' ')
				.join('-');
			return setAlias(latinText);
		}
	};

	const handleVerificationAlias = () => {
		let res2 = handleCategoriesMap(categories).find(
			(el) => el.alias === alias && el._id !== editeCategory?._id
		)?._id?.length
			? false
			: true;
		let hasSpaces = /\s/g.test(alias);
		let res =
			alias?.length &&
			alias?.length < 24 &&
			!hasSpaces &&
			/^([a-zA-ZіІ']+-)*[a-zA-ZіІ']+$/.test(alias) &&
			res2
				? true
				: false;
		return res;
	};

	const handleClearInput = () => {
		setName('');
		setAlias('');
		setAlias_old('');
		setPriority(0);
		setDescription('');
		setCommissionPercentage(0);
		setSeoDefaultTitle('');
		setSeoDefaultDescription('');
		setSeoFacebookTitle('');
		setSeoFacebookDescription('');
		setSeoTwitterTitle('');
		setSeoTwitterDescription('');
		setKeywords('');
		setSeoDefaultKeywords([]);
		setCharacteristics('');
		setCharacteristicsOptions([]);
		setAllCharacteristicsOptions([]);
		setImage({});
		setOldImage('');
		setSeo_google_product_category('');
	};

	const handleCreateCategory = (str = null) => {
		let formData = new FormData();

		formData.append('name', name);
		formData.append('alias', alias);
		formData.append('alias_old', alias_old);
		formData.append('priority', Number(priority));
		formData.append('description', description?.length ? description : '');
		formData.append('commission_percentage', +commissionPercentage);
		formData.append('file', image);
		formData.append(
			'seo_default_title',
			seoDefaultTitle?.length ? seoDefaultTitle : ''
		);
		formData.append(
			'seo_default_description',
			seoDefaultDescription?.length ? seoDefaultDescription : ''
		);
		if (seoDefaultKeywords?.length) {
			seoDefaultKeywords.map((keywords) => {
				formData.append('seo_default_keywords[]', keywords);
			});
		} else {
			formData.append('seo_default_keywords[]', []);
		}
		formData.append(
			'seo_google_product_category',
			seo_google_product_category?.length ? seo_google_product_category : ''
		);
		formData.append(
			'seo_facebook_title',
			seoFacebookTitle?.length ? seoFacebookTitle : ''
		);
		formData.append(
			'seo_facebook_description',
			seoFacebookDescription?.length ? seoFacebookDescription : ''
		);
		formData.append(
			'seo_twitter_title',
			seoTwitterTitle?.length ? seoTwitterTitle : ''
		);
		formData.append(
			'seo_twitter_description',
			seoTwitterDescription?.length ? seoTwitterDescription : ''
		);
		formData.append(
			'characteristics_options',
			JSON.stringify(characteristicsOptions)
		);

		if (name?.length && handleVerificationAlias()) {
			if (editeCategory?._id?.length) {
				setIsPreloader(true);
				if (editeCategory?.parent_id) {
					formData.append('parent_id', editeCategory.parent_id);
				}
				formData.append('image', oldImage);

				fetch(
					`${process.env.REACT_APP_BASE_URL}/categories/${editeCategory._id}?token=${token}`,
					{
						method: 'PUT',
						body: formData,
					}
				)
					.then((res) => res.json())
					.then((res) => {
						if (res.success) {
							handleGetCategory();
							dispatch(
								setShowMessageObj({
									open: true,
									status: 'success',
									message: 'Дані оновлено',
								})
							);
						} else {
							console.log('PUT CreatingCategory:', res);
							dispatch(
								setShowMessageObj({
									open: true,
									status: 'error',
									message: 'Сталася помилка',
								})
							);
						}
						setIsPreloader(false);
					});
			} else {
				setIsPreloader(true);
				formData.append('is_hide', false);
				if (parentCategory?._id?.length) {
					formData.append('parent_id', parentCategory._id);
				} else {
					formData.append('parent_id', null);
				}

				fetch(`${process.env.REACT_APP_BASE_URL}/categories?token=${token}`, {
					method: 'POST',
					body: formData,
				})
					.then((res) => res.json())
					.then((res) => {
						if (res.success && res.data) {
							dispatch(
								setShowMessageObj({
									open: true,
									status: 'success',
									message: 'Дані створено',
								})
							);
							handleClearInput();
							handleGetCategory();
							setErrorName('');
							setErrorAlias('');
						} else {
							console.log('POST CreatingCategory:', res);
							dispatch(
								setShowMessageObj({
									open: true,
									status: 'error',
									message: 'Сталася помилка',
								})
							);
						}
						setIsPreloader(false);
					});
			}
		} else {
			setErrorName(!name?.length ? "Обов'язкове поле" : '');
			setErrorAlias(
				!alias?.length
					? "Обов'язкове поле"
					: handleVerificationAlias()
					? ''
					: 'Не вірне значення'
			);
			dispatch(
				setShowMessageObj({
					open: true,
					status: 'error',
					message: `${
						editeCategory?._id?.length
							? 'Помилка при оновленні'
							: 'Помилка при створенні'
					}`,
				})
			);
		}
	};

	const handleGetCategory = () => {
		fetchGetData(`${process.env.REACT_APP_BASE_URL}/categories/all`).then(
			(res) => {
				if (res.success && res.data) {
					dispatch(getCategories(res.data));
				} else {
					console.log('GET CreatingCategory', res);
				}
			}
		);
	};

	const handleSetTabValue = (num) => {
		setTabValue(num);
		if (editeCategory._id?.length) {
			navigate(`/auth/categories/${editeCategory._id}?tab=${num}`);
		} else {
			navigate(`/auth/categories/create/?tab=${num}`);
		}
	};

	return (
		<div className='creating-category'>
			<SeoBlock
				title={
					!!editeCategory?._id?.length
						? 'Редагування категорії'
						: 'Створення категорії'
				}
				description={''}
				keywords={''}
				isCanonical={
					location.pathname === '/auth/categories/create' ? true : false
				}
			/>
			{isEditeCharacteristics && (
				<ModalWindow
					title={'Введіть нову назву характеристики'}
					text={''}
					handleClick={handleIsEditeCharacteristics}
				>
					{isErrorTestName && (
						<div className='creating-category__modal-error-name'>
							Характеристика з такою назвою вже існує
						</div>
					)}
					<input
						className='creating-category__input creating-category__modal-characteristics-name'
						value={newCharacteristicsName}
						onChange={(e) => setNewCharacteristicsName(e.target.value)}
						type='text'
						placeholder='Введіть назву'
					/>
				</ModalWindow>
			)}

			<div
				className={`creating-category__header ${
					!!editeCategory?._id?.length ? 'creating-category__header--save' : ''
				}`}
			>
				<div className='creating-category__header-title-wrap'>
					<img
						className='creating-category__header-title-img'
						src={category}
						alt='img'
					/>
					<h1 className='creating-category__header-title'>Категорії</h1>
				</div>
				<div
					className={`creating-category__text-tab--wrap ${
						!!editeCategory?._id?.length
							? 'creating-category__text-tab--save-wrap'
							: ''
					}`}
				>
					<div className='creating-category__text-tab-wrap'>
						<button
							className={`creating-category__text-tab ${
								tabValue === 0 ? 'user-order__text-tab-active' : ''
							} ${
								!!editeCategory?._id?.length
									? 'creating-category__text-tab--save--wrap'
									: ''
							}`}
							onClick={() => handleSetTabValue(0)}
						>
							Основна інформація
						</button>
						<button
							className={`creating-category__text-tab ${
								tabValue === 1 ? 'user-order__text-tab-active' : ''
							} ${
								!!editeCategory?._id?.length
									? 'creating-category__text-tab--save--wrap'
									: ''
							}`}
							onClick={() => handleSetTabValue(1)}
						>
							SEO налаштування
						</button>
					</div>
					<Select
						value={tabValue}
						className='creating-category__select-tab user-order__order-select-status'
						onChange={(e) => handleSetTabValue(e.target.value)}
					>
						<MenuItem
							className={`product-view__variations-select-item creating-category__select-tab-item ${
								tabValue === 0
									? 'product-view__variations-select-item-active'
									: ''
							}`}
							value={0}
						>
							Основна інформація
						</MenuItem>
						<MenuItem
							className={`product-view__variations-select-item creating-category__select-tab-item ${
								tabValue === 1
									? 'product-view__variations-select-item-active'
									: ''
							}`}
							value={1}
						>
							SEO налаштування
						</MenuItem>
					</Select>
				</div>

				<div
					className={`creating-category__header-btn-wrap ${
						!!editeCategory?._id?.length
							? 'creating-category__header-btn-save-wrap'
							: ''
					}`}
				>
					{!editeCategory?._id?.length && (
						<div className='creating-category__header-btn--wrap'>
							<button
								className='creating-category__header-btn'
								onClick={() => handleCreateCategory('end')}
							>
								<img
									className='creating-category__header-btn-img'
									src={saveImg}
									alt='img'
								/>
								<span>Створити та завершити</span>
							</button>
						</div>
					)}
					{!!editeCategory?._id?.length && (
						<button
							className='creating-category__header-btn-save'
							onClick={() => handleCreateCategory()}
						>
							<img
								className='creating-category__header-btn-img'
								src={saveImg}
								alt='img'
							/>
							<span>Зберегти зміни</span>
						</button>
					)}
				</div>
			</div>

			{isPreloader ? (
				<PreloaderCustom />
			) : (
				<>
					{tabValue === 0 && (
						<div className='creating-category__tab-0-wrap'>
							<div className='creating-category__input-wrap'>
								<div className='creating-category__input-text-wrap'>
									<div className='creating-category__input--wrap'>
										<div className='creating-category__input-lable requiredFields'>
											Назва категорії
										</div>
										<input
											className={`creating-category__input ${
												!!errorName?.length
													? 'creating-category__input--error'
													: ''
											}`}
											value={name}
											onChange={(e) => handleSetNameCategory(e.target.value)}
											type='text'
											placeholder='Додати назву'
										/>
										<div className='creating-category__input-sub-title'>
											Не перевищуйте 20 символів під час введення назви
											категорії
										</div>
										{!!errorName?.length && (
											<div className='creating-category__input-error-text'>
												{errorName}
											</div>
										)}
									</div>
									<div className='creating-category__input--wrap'>
										<div className='creating-category__input-lable requiredFields'>
											Назва url категорії
										</div>
										<input
											className={`creating-category__input ${
												!!errorAlias?.length
													? 'creating-category__input--error'
													: ''
											}`}
											value={alias}
											onChange={(e) => setAlias(e.target.value)}
											type='text'
											placeholder='Додати url назву'
										/>
										<div className='creating-category__input-sub-title'>
											Тільки англійські букви без цифр і відмінне від інших
											категорій. Не перевищуйте 24 символів під час введення
											назви категорії.
										</div>
										{!!errorAlias?.length && (
											<div className='creating-category__input-error-text'>
												{errorAlias}
											</div>
										)}
									</div>
									<div className='creating-category__input--wrap'>
										<div className='creating-category__input-lable'>
											Url категорії до переходу
										</div>
										<input
											className='creating-category__input'
											value={alias_old}
											onChange={(e) => setAlias_old(e.target.value)}
											type='text'
											placeholder='Додати назву'
										/>
									</div>
									<div className='creating-category__input--wrap'>
										<div className='creating-category__input-lable'>
											Пріорітет категорії
										</div>
										<input
											className='creating-category__input'
											value={priority}
											onChange={(e) => validNumber(e.target.value, setPriority)}
											type='text'
											placeholder='Введіть значення'
										/>
									</div>
									<div className='creating-category__input--wrap'>
										<div className='creating-category__input-lable'>
											Опис категорії
										</div>
										<textarea
											className='creating-category__textarea'
											value={description}
											onChange={(e) => setDescription(e.target.value)}
											placeholder='Опис категорії'
											rows={8}
										/>
									</div>
									<div className='creating-category__input--wrap'>
										<div className='creating-category__input-lable'>
											Комісія
										</div>
										<input
											className='creating-category__input'
											value={commissionPercentage}
											onChange={(e) =>
												validNumber(e.target.value, setCommissionPercentage)
											}
											type='text'
											placeholder='Введіть %'
										/>
									</div>
								</div>

								<div className='creating-category__create-img--wrap'>
									<div
										className='creating-category__create-img-wrap'
										onDrop={(e) => handleImageUpload(e)}
										onDragOver={(e) => e.preventDefault()}
									>
										<div className='creating-category__create-img-text-wrap'>
											<div className='creating-category__create-img-text-img-wrap'>
												<img
													className='creating-category__create-img-text-img'
													src={uploadImg}
													alt='img'
												/>
											</div>
											<label
												className='creating-category__create-img-link-upload-lable'
												htmlFor='creationProductUserImages'
											>
												Клікніть завантажити
											</label>
											<span> або перетягніть зображення сюди</span>
											<p>Формати які підтримуються: PNG, JPG</p>
											<input
												className='creating-category__create-img-link-upload-input'
												onChange={(e) => uploadPhoto(e.target.files[0])}
												type='file'
												accept='image/*'
												id='creationProductUserImages'
											/>
										</div>
									</div>

									{!!image?.name?.length && (
										<div className='creating-category__upload-result-wrap'>
											<button onClick={handleDeleteUploadImg}>
												<img
													className='creating-category__upload-result-del-btn'
													src={deleteImg2}
													alt='img'
												/>
											</button>
											<img
												className='creating-category__upload-img-result'
												src={URL.createObjectURL(image)}
												alt='img'
											/>
										</div>
									)}
									{!image?.name?.length && !!oldImage?.length && (
										<div className='creating-category__upload-result-wrap'>
											<button onClick={handleDeleteImg}>
												<img
													className='creating-category__upload-result-del-btn'
													src={deleteImg2}
													alt='img'
												/>
											</button>
											<img
												className='creating-category__upload-img-result'
												src={oldImage}
												alt='img'
											/>
										</div>
									)}
								</div>
							</div>

							{!parentCategory?._id?.length &&
							(!editeCategory?.parent_id ||
								editeCategory?.parent_id == 'null') ? (
								<div className='creating-category__add-btn-wrap'>
									<div className='creating-category__input-title'>
										Характеристики
									</div>
									<div className='creating-category__input-lable creating-category__input-lable-add'>
										Додати характеристику
									</div>
									<div className='creating-category__add-btn--wrap'>
										<input
											className='creating-category__input'
											value={characteristics}
											onChange={(e) =>
												setCharacteristics(
													handleCapitalizeString(e.target.value)
												)
											}
											onKeyDown={(e) => handleKeyDown(e)}
											placeholder='Введіть назву характеристики'
											type='text'
										/>
										<img
											className='creating-category__add-btn'
											onClick={handleAddCharacteristics}
											src={plusImg}
											alt='img'
										/>
									</div>
									{isErrorCharacteristicsName && (
										<div className='creating-category__input-error-text'>
											Така назва характеристики вже існує
										</div>
									)}
									{!!characteristicsOptions?.length && (
										<div className='creating-category__characteristics-result-wrap'>
											{handleSortCharacteristcs([
												...characteristicsOptions,
											]).map((el, i) => (
												<div
													className='creating-category__characteristics-result--wrap'
													key={el.name + i}
												>
													<div className='creating-category__characteristics-result-name-wrap'>
														<div className='creating-category__characteristics-result-name'>
															<div
																className={`creating-category__characteristics-result-name-title ${
																	el.is_hide
																		? 'creating-category__characteristics-is-hide'
																		: ''
																} ${
																	el.name === focusAddCharacteristics
																		? 'creating-category__characteristics-is-focus'
																		: ''
																}`}
															>
																{el.name}
															</div>
														</div>
														<div
															className={`catalog-view__products-filter-checkbox-wrap ${
																el.is_hide
																	? 'creating-category__characteristics-is-hide'
																	: ''
															}`}
														>
															{!!characteristicsOptions?.length &&
																characteristicsOptions.find(
																	(ell) => ell.name === el.name
																)?.name?.length && (
																	<CheckboxWrap
																		characteristic={characteristicsOptions.find(
																			(ell) => ell.name === el.name
																		)}
																		setCharacteristicsOptions={
																			setCharacteristicsOptions
																		}
																	/>
																)}
														</div>
														<div className='creating-category__characteristics-result-characteristics-wrap'>
															<div
																className={`creating-category__add-characteristics-wrap ${
																	el.is_hide
																		? 'creating-category__characteristics-is-hide'
																		: ''
																}`}
															>
																<AddCharacteristics
																	characteristicsName={el.name}
																	setCharacteristicsOptions={
																		setCharacteristicsOptions
																	}
																	characteristicsOptions={
																		characteristicsOptions
																	}
																	setFocusAddCharacteristics={
																		setFocusAddCharacteristics
																	}
																/>
															</div>
															<div className='creating-category__add-result-del-btn-wrap'>
																<button
																	onClick={() =>
																		handleEditeCharacteristics(el.name)
																	}
																>
																	<img
																		className='creating-category__add-result-del-btn creating-category__add-result-del-btn--edite'
																		src={editIcon1}
																		alt='img'
																	/>
																</button>
																<button
																	onClick={() =>
																		handleHideCharacteristics(el.name)
																	}
																>
																	<img
																		className='creating-category__add-result-del-btn'
																		src={el.is_hide ? eyeHide1 : eye1}
																		alt='img'
																	/>
																</button>
																<button
																	onClick={() =>
																		handleDeleteCharacteristics(el.name)
																	}
																>
																	<img
																		className='creating-category__add-result-del-btn'
																		src={deleteImg4}
																		alt='img'
																	/>
																</button>
															</div>
														</div>
													</div>
													<Accordion
														className={`creating-category__accordion ${
															el.is_hide
																? 'creating-category__characteristics-is-hide'
																: ''
														}`}
													>
														<AccordionSummary
															expandIcon={
																<p>
																	<img
																		className='creating-category__produc-accordion-arrow'
																		src={arrow2}
																		alt='img'
																	/>
																</p>
															}
															aria-controls='creating-product__produc'
															id='creating-product__produc'
														>
															<Typography>Доступні значення</Typography>
														</AccordionSummary>
														<AccordionDetails>
															<div className='creating-category__accordion-wrap'>
																{!!el.value?.length ? (
																	el.value.map((ell, index) => (
																		<div
																			className='select-characteristic__add-result-wrap'
																			key={ell + index}
																		>
																			<div className='creating-category__characteristic-add-result'>
																				<p>{ell}</p>
																				<button
																					onClick={() =>
																						handleDeleteNewCharacteristicsValue(
																							el.name,
																							ell
																						)
																					}
																				>
																					<img
																						className='select-characteristic__add-result-del-words-btn'
																						src={close2}
																						alt='img'
																					/>
																				</button>
																			</div>
																		</div>
																	))
																) : (
																	<div className='select-characteristic__add-result-error'>
																		Список значень пустий
																	</div>
																)}
															</div>
														</AccordionDetails>
													</Accordion>
												</div>
											))}
										</div>
									)}
								</div>
							) : (
								<div className='creating-category__add-btn-wrap'>
									<div className='creating-category__input-title'>
										Характеристики
									</div>
									<div className='creating-category__input-lable creating-category__input-lable-add'>
										Додати характеристику
									</div>
									<div className='creating-category__add-btn--wrap'>
										<input
											className='creating-category__input'
											value={characteristics}
											onChange={(e) => setCharacteristics(e.target.value)}
											onKeyDown={(e) => handleKeyDownCharacteristics(e)}
											placeholder='Введіть назву характеристики'
										/>
										<img
											className='creating-category__add-btn'
											onClick={handleAddNewCharacteristics}
											src={plusImg}
											alt='img'
										/>
									</div>
									{isErrorCharacteristicsName && (
										<div className='creating-category__input-error-text'>
											Така назва характеристики вже існує
										</div>
									)}
									{!!allCharacteristicsOptions?.length && (
										<div className='creating-category__characteristics-result-wrap'>
											{handleSortCharacteristcs([
												...allCharacteristicsOptions,
											]).map((el, i) => (
												<div
													className={`creating-category__characteristics-result--wrap`}
													key={el.name + i}
												>
													<div className='creating-category__characteristics-result-name-wrap'>
														<div className='creating-category__characteristics-result-name creating-category__characteristics-result-name--sub'>
															<div className='catalog-view__products-filter-label-wrap'>
																<div
																	className={`creating-category__characteristics-result-name-title ${
																		el.name === focusAddCharacteristics
																			? 'creating-category__characteristics-is-focus'
																			: ''
																	}`}
																>
																	<div
																		className={`${
																			el.is_hide
																				? 'creating-category__characteristics-is-hide'
																				: ''
																		}`}
																	>
																		{el.name}
																	</div>
																	{el.is_hide && (
																		<>
																			<Tooltip
																				placement='top'
																				className=''
																				title={
																					<div className='catalog-view__popover-text-wrap'>
																						<div className='catalog-view__popover-text'>
																							Дана характеристика прихована в
																							головній категорії
																						</div>
																					</div>
																				}
																			>
																				<img
																					className='catalog-view__popover-img'
																					src={errorCircleImg}
																					alt='img'
																				/>
																			</Tooltip>
																		</>
																	)}
																</div>
															</div>
															<div
																className={`catalog-view__products-filter-checkbox-wrap ${
																	el.is_hide
																		? 'creating-category__characteristics-is-hide'
																		: ''
																}`}
															>
																<label className='creating-category__products-filter-label'>
																	<input
																		className='catalog-view__products-filter-checkbox'
																		type='checkbox'
																		value={JSON.stringify(el)}
																		onChange={(e) =>
																			handleAddSubCharacteristics(
																				e.target.value
																			)
																		}
																	/>
																	<div
																		className={`catalog-view__products-filter-checkbox-custom ${
																			characteristicsOptions.find(
																				(ell) => ell.name === el.name
																			)
																				? 'catalog-view__products-filter-checkbox-custom-active'
																				: ''
																		}`}
																	></div>
																	<div>Відобразити</div>
																</label>
																{!!characteristicsOptions?.length &&
																	characteristicsOptions.find(
																		(ell) => ell.name === el.name
																	)?.name?.length && (
																		<CheckboxWrap
																			characteristic={characteristicsOptions.find(
																				(ell) => ell.name === el.name
																			)}
																			setCharacteristicsOptions={
																				setCharacteristicsOptions
																			}
																		/>
																	)}
															</div>
														</div>
														<div
															className={`${
																el.is_hide
																	? 'creating-category__characteristics-is-hide'
																	: ''
															}`}
														>
															{characteristicsOptions.find(
																(ell) => ell.name === el.name
															) && (
																<AddCharacteristics
																	characteristicsName={el.name}
																	setCharacteristicsOptions={
																		setCharacteristicsOptions
																	}
																	characteristicsOptions={
																		characteristicsOptions
																	}
																	allCharacteristicsOptions={
																		allCharacteristicsOptions
																	}
																	setAllCharacteristicsOptions={
																		setAllCharacteristicsOptions
																	}
																	isSubCategory={true}
																	setFocusAddCharacteristics={
																		setFocusAddCharacteristics
																	}
																/>
															)}
														</div>
													</div>
													<Accordion
														className={`creating-category__accordion ${
															el.is_hide
																? 'creating-category__characteristics-is-hide'
																: ''
														}`}
													>
														<AccordionSummary
															expandIcon={
																<p>
																	<img
																		className='creating-category__produc-accordion-arrow'
																		src={arrow2}
																		alt='img'
																	/>
																</p>
															}
															aria-controls='creating-product__produc'
															id='creating-product__produc'
														>
															<Typography>Доступні значення</Typography>
														</AccordionSummary>
														<AccordionDetails>
															<div className='creating-category__accordion-wrap'>
																{!!el.value?.length ? (
																	el.value.map((ell, index) => (
																		<div
																			className='select-characteristic__add-result-wrap'
																			key={ell + index}
																		>
																			<div className='creating-category__characteristic-add-result'>
																				<p>{ell}</p>
																				<button
																					onClick={() =>
																						handleDeleteNewCharacteristicsValue(
																							el.name,
																							ell
																						)
																					}
																				>
																					<img
																						className='select-characteristic__add-result-del-words-btn'
																						src={close2}
																						alt='img'
																					/>
																				</button>
																			</div>
																		</div>
																	))
																) : (
																	<div className='select-characteristic__add-result-error'>
																		Список значень пустий
																	</div>
																)}
															</div>
														</AccordionDetails>
													</Accordion>
												</div>
											))}
										</div>
									)}
								</div>
							)}
						</div>
					)}
					{tabValue === 1 && (
						<div className='creating-category__tab-1-wrap'>
							<div className='creating-category__input--wrap'>
								<div className='creating-category__input-lable'>
									SEO заголовок
								</div>
								<input
									className='creating-category__input'
									value={seoDefaultTitle}
									onChange={(e) => setSeoDefaultTitle(e.target.value)}
									placeholder='SEO title'
									type='text'
								/>
							</div>
							<div className='creating-category__input--wrap'>
								<div className='creating-category__input-lable'>SEO опис</div>
								<textarea
									className='creating-category__textarea'
									value={seoDefaultDescription}
									onChange={(e) => setSeoDefaultDescription(e.target.value)}
									placeholder='SEO description'
									rows={8}
									type='text'
								/>
							</div>
							<div className='creating-category__add-seo-wrap'>
								<div className='creating-category__input-lable'>
									SEO ключові слова
								</div>
								<div className='creating-category__add-btn--wrap'>
									<input
										className='creating-category__input'
										value={keywords}
										onChange={(e) => setKeywords(e.target.value)}
										onKeyDown={(e) => handleKeyDownKeyword(e)}
										placeholder='SEO keyword'
										type='text'
									/>
									<img
										className='creating-category__add-btn'
										onClick={handleAddKeyword}
										src={plusImg}
										alt='img'
									/>
								</div>
								<div className='creating-category__add-result-wrap'>
									{!!seoDefaultKeywords?.length &&
										seoDefaultKeywords.map((el, i) => (
											<div
												className='creating-category__add-result'
												key={el + i}
											>
												<p>{el}</p>
												<button onClick={() => handleDeleteKeyword(el)}>
													<img
														className='creating-category__add-result-del-words-btn'
														src={close2}
														alt='img'
													/>
												</button>
											</div>
										))}
								</div>
							</div>
							<div className='creating-category__input--wrap'>
								<div className='creating-category__input-lable'>
									SEO google категорія продукту
								</div>
								<input
									className='creating-category__input'
									value={seo_google_product_category}
									onChange={(e) =>
										setSeo_google_product_category(e.target.value)
									}
									placeholder='SEO google'
									type='text'
								/>
							</div>
							<div className='creating-category__input--wrap'>
								<div className='creating-category__input-lable'>
									SEO Facebook заголовок
								</div>
								<input
									className='creating-category__input'
									value={seoFacebookTitle}
									onChange={(e) => setSeoFacebookTitle(e.target.value)}
									placeholder='SEO facebook title'
									type='text'
								/>
							</div>
							<div className='creating-category__input--wrap'>
								<div className='creating-category__input-lable'>
									SEO Facebook опис
								</div>
								<input
									className='creating-category__input'
									value={seoFacebookDescription}
									onChange={(e) => setSeoFacebookDescription(e.target.value)}
									placeholder='SEO facebook description'
									type='text'
								/>
							</div>
							<div className='creating-category__input--wrap'>
								<div className='creating-category__input-lable'>
									SEO Twitter заголовок
								</div>
								<input
									className='creating-category__input'
									value={seoTwitterTitle}
									onChange={(e) => setSeoTwitterTitle(e.target.value)}
									placeholder='SEO twitter title'
									type='text'
								/>
							</div>
							<div className='creating-category__input--wrap'>
								<div className='creating-category__input-lable'>
									SEO Twitter опис
								</div>
								<input
									className='creating-category__input'
									value={seoTwitterDescription}
									onChange={(e) => setSeoTwitterDescription(e.target.value)}
									placeholder='SEO twitter description'
									type='text'
								/>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default CreatingCategory;
