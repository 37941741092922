import './InputPhone.css';
import React, { useEffect, useRef, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

function InputPhone({ setPhone, phone, isErrorPhone=false, isCheckoutView=false, isOrderVeiw=false, isPartnersVeiw=false, isProductView=false, isVendorView=false, isPartnerRegistration=false }) {
    const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);
    const [isNotFirstRender, setIsNotFirstRender] = useState(false);
    const input = useRef(null)

    useEffect(() => {
      handleBlur()
      setIsNotFirstRender(true)
    }, []);

    useEffect(() => {
      if (isNotFirstRender) {
        handleBlur()
      }
    }, [phone]);

    const handleBlur = () => {
        if (!phone || (phone.startsWith('380') && phone.length <= 3)) {
            setIsPlaceholderVisible(true);
        } else {
            setIsPlaceholderVisible(false);
        }
    }
    
    const handlePlaceholderVisible = () => {
        setIsPlaceholderVisible(false);
        if (input?.current) {
            input.current.numberInputRef.focus()
        }
    }

    const handlePhoneChange = (value) => {
        setPhone(value);

        if (!value || (value.startsWith('380') && value.length <= 3)) {
            setIsPlaceholderVisible(true);
        } else {
            setIsPlaceholderVisible(false);
        }
    };

  return (
    <div className={`input-phone ${isCheckoutView ? 'input-phone__checkout' : ''} ${isOrderVeiw ? 'input-phone__order' : ''} ${isPartnerRegistration ? 'input-phone__partner-registration' : ''} ${isPartnersVeiw ? 'input-phone__partners' : ''} ${isProductView ? 'input-phone__product-view' : ''} ${isVendorView ? 'input-phone__vendor-view' : ''}`}>
      <PhoneInput
        ref={input}
        country={'ua'}
        value={!phone?.length ? '380' : phone}
        onChange={handlePhoneChange}
        onBlur={handleBlur}
        className={`${isErrorPhone ? 'input-phone__error' : ''} ${(isErrorPhone && isCheckoutView ) ? 'input-phone__checkout-view-error' : ''} ${(isErrorPhone && isProductView ) ? 'input-phone__product-view-error' : ''} ${(isErrorPhone && isOrderVeiw )? 'input-phone__order-veiw-error' : ''} ${(isErrorPhone && isPartnersVeiw )? 'input-phone__partners-veiw-error' : ''}`}
      />
      {isPlaceholderVisible && (
        <div className={`input-phone__placeholder ${isPartnerRegistration ? 'input-phone__placeholder--partner-registration' : ''} ${isVendorView ? 'input-phone__placeholder--vendor-view' : ''} ${isProductView ? 'input-phone__placeholder--product-view' : ''} ${isCheckoutView ? 'input-phone__placeholder--checkout-view' : ''}`} onClick={() => handlePlaceholderVisible(false)}>(99) 999-99-99</div>
      )}
    </div>
  );
}

export default InputPhone;
