import './ShoppingComment.css';
import { useState, useEffect } from 'react';

function ShoppingComment({vendorId, setComment, comment}) {
    const [commentText, setCommentText] = useState("");
    const [isNotFirstRender, setIsNotFirstRender] = useState(false);
    
    useEffect(() => {
        setIsNotFirstRender(true)
    }, [])

    useEffect(() => {
        if (isNotFirstRender) {
            if (comment?.length && comment.find(el => el.vendor_id === vendorId)) {
                let res = comment.map(el => {
                    if (el.vendor_id === vendorId) {
                        el = {...el, comment: commentText}
                    }
                    return el
                })
                setComment(res)
            } else {
                setComment([...comment, {vendor_id: vendorId, comment: commentText}])
            }
        }
    }, [commentText])

    return (
        <div className="shopping-comment-wrap">
            <label className="shopping-comment__label" htmlFor='shopping-ShoppingComment'>Напишіть коментар до замовлення</label>
            <textarea className="shopping-comment__textarea" onChange={(e) => setCommentText(e.target.value)} value={commentText}  rows="5" autoComplete="comment" id='shopping-ShoppingComment' name='shopping-ShoppingComment' type='text' placeholder=''/>
        </div>
    );
}

export default ShoppingComment;