import './SeoSettings.css';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import listImg from '../../assets/listImg.svg';
import { fetchGetData, fetchRequest } from '../../helpers/Utils';
import { setShowMessageObj } from '../../store/userSlice';
import { useSearchParams } from 'react-router-dom';

function SeoSettings({isClickSave=false, setIsClickSave=null}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const newSearchParams = new URLSearchParams(searchParams);
    const tabValueSearchParams = Number(JSON.parse(searchParams.get('seoTabValue')))

    const dispatch = useDispatch();
    const [tabValue, setTabValue] = useState(tabValueSearchParams ? tabValueSearchParams : 0);
    const [titleHome, setTitleHome] = useState('');
    const [descriptionHome, setDescriptionHome] = useState('');
    const [keywordsHome, setKeywordsHome] = useState('');
    const [titleAbout, setTitleAbout] = useState('');
    const [descriptionAbout, setDescriptionAbout] = useState('');
    const [keywordsAbout, setKeywordsAbout] = useState('');
    const [titleContacts, setTitleContacts] = useState('');
    const [descriptionContacts, setDescriptionContacts] = useState('');
    const [keywordsContacts, setKeywordsContacts] = useState('');
    const [titleHowToBuy, setTitleHowToBuy] = useState('');
    const [descriptionHowToBuy, setDescriptionHowToBuy] = useState('');
    const [keywordsHowToBuy, setKeywordsHowToBuy] = useState('');
    const [titleCatalog, setTitleCatalog] = useState('');
    const [descriptionCatalog, setDescriptionCatalog] = useState('');
    const [keywordsCatalog, setKeywordsCatalog] = useState('');
    const [titlePayment, setTitlePayment] = useState('');
    const [descriptionPayment, setDescriptionPayment] = useState('');
    const [keywordsPayment, setKeywordsPayment] = useState('');
    const [titleBeSeller, setTitleBeSeller] = useState('');
    const [descriptionBeSeller, setDescriptionBeSeller] = useState('');
    const [keywordsBeSeller, setKeywordsBeSeller] = useState('');
    const [titleCollections, setTitleCollections] = useState('');
    const [descriptionCollections, setDescriptionCollections] = useState('');
    const [keywordsCollections, setKeywordsCollections] = useState('');
    const [titleRegistration, setTitleRegistration] = useState('');
    const [descriptionRegistration, setDescriptionRegistration] = useState('');
    const [keywordsRegistration, setKeywordsRegistration] = useState('');
    const [titleRates, setTitleRates] = useState('');
    const [descriptionRates, setDescriptionRates] = useState('');
    const [keywordsRates, setKeywordsRates] = useState('');
    const [titleIndivIdualOrder, setTitleIndivIdualOrder] = useState('');
    const [descriptionIndivIdualOrder, setDescriptionIndivIdualOrder] = useState('');
    const [keywordsIndivIdualOrder, setKeywordsIndivIdualOrder] = useState('');
    const [titleFavorite, setTitleFavorite] = useState('');
    const [descriptionFavorite, setDescriptionFavorite] = useState('');
    const [keywordsFavorite, setKeywordsFavorite] = useState('');
    const [seo, setSeo] = useState({});
    let token = localStorage.getItem('token')

    useEffect(() => {
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/seo/all`)
            .then(res => {
                if (res.success && res.data) {
                    if (res.data?.length) {
                        setSeo(res.data[0])
                        handleSeoSetInInput(res.data[0])
                    }
                } else {
                    console.log('GET SeoSettings', res)
                }
            })
    }, [])
    
    useEffect(() => {
        if (isClickSave) {
            handleSaveSeo()
        }
    }, [isClickSave])

    const handleSeoSetInInput = (obj) => {
        setTitleHome(obj.title_home?.length ? obj.title_home : '')
        setDescriptionHome(obj.description_home?.length ? obj.description_home : '')
        setKeywordsHome(obj.keywords_home?.length ? obj.keywords_home : '')
        setTitleAbout(obj.title_about?.length ? obj.title_about : '')
        setDescriptionAbout(obj.description_about?.length ? obj.description_about : '')
        setKeywordsAbout(obj.keywords_about?.length ? obj.keywords_about : '')
        setTitleContacts(obj.title_contacts?.length ? obj.title_contacts : '')
        setDescriptionContacts(obj.description_contacts?.length ? obj.description_contacts : '')
        setKeywordsContacts(obj.keywords_contacts?.length ? obj.keywords_contacts : '')
        setTitleHowToBuy(obj.title_how_to_buy?.length ? obj.title_how_to_buy : '')
        setDescriptionHowToBuy(obj.description_how_to_buy?.length ? obj.description_how_to_buy : '')
        setKeywordsHowToBuy(obj.keywords_how_to_buy?.length ? obj.keywords_how_to_buy : '')
        setTitleCatalog(obj.title_catalog?.length ? obj.title_catalog : '')
        setDescriptionCatalog(obj.description_catalog?.length ? obj.description_catalog : '')
        setKeywordsCatalog(obj.keywords_catalog?.length ? obj.keywords_catalog : '')
        setTitlePayment(obj.title_payment?.length ? obj.title_payment : '')
        setDescriptionPayment(obj.description_payment?.length ? obj.description_payment : '')
        setKeywordsPayment(obj.keywords_payment?.length ? obj.keywords_payment : '')
        setTitleBeSeller(obj.title_be_seller?.length ? obj.title_be_seller : '')
        setDescriptionBeSeller(obj.description_be_seller?.length ? obj.description_be_seller : '')
        setKeywordsBeSeller(obj.keywords_be_seller?.length ? obj.keywords_be_seller : '')
        setTitleCollections(obj.title_collections?.length ? obj.title_collections : '')
        setDescriptionCollections(obj.description_collections?.length ? obj.description_collections : '')
        setKeywordsCollections(obj.keywords_collections?.length ? obj.keywords_collections : '')
        setTitleRegistration(obj.title_registration?.length ? obj.title_registration : '')
        setDescriptionRegistration(obj.description_registration?.length ? obj.description_registration : '')
        setKeywordsRegistration(obj.keywords_registration?.length ? obj.keywords_registration : '')
        setTitleRates(obj.title_rates?.length ? obj.title_rates : '')
        setDescriptionRates(obj.description_rates?.length ? obj.description_rates : '')
        setKeywordsRates(obj.keywords_rates?.length ? obj.keywords_rates : '')
        setTitleIndivIdualOrder(obj.title_indivIdual_order?.length ? obj.title_indivIdual_order : '')
        setDescriptionIndivIdualOrder(obj.description_indivIdual_order?.length ? obj.description_indivIdual_order : '')
        setKeywordsIndivIdualOrder(obj.keywords_indivIdual_order?.length ? obj.keywords_indivIdual_order : '')
        setTitleFavorite(obj.title_favorite?.length ? obj.title_favorite : '')
        setDescriptionFavorite(obj.description_favorite?.length ? obj.description_favorite : '')
        setKeywordsFavorite(obj.keywords_favorite?.length ? obj.keywords_favorite : '')
    }

    const handleSaveSeo = () => {
        let data = {}
        if (tabValue === 0) {
            data = {
                title_home: titleHome, 
                description_home: descriptionHome, 
                keywords_home: keywordsHome, 
            }
        } else if (tabValue === 1) {
            data = {
                title_about: titleAbout, 
                description_about: descriptionAbout, 
                keywords_about: keywordsAbout, 
            }
        } else if (tabValue === 2) {
            data = {
                title_contacts: titleContacts, 
                description_contacts: descriptionContacts, 
                keywords_contacts: keywordsContacts, 
            }
        } else if (tabValue === 3) {
            data = {
                title_how_to_buy: titleHowToBuy, 
                description_how_to_buy: descriptionHowToBuy, 
                keywords_how_to_buy: keywordsHowToBuy, 
            }
        } else if (tabValue === 4) {
            data = {
                title_catalog: titleCatalog, 
                description_catalog: descriptionCatalog, 
                keywords_catalog: keywordsCatalog, 
            }
        } else if (tabValue === 5) {
            data = {
                title_payment: titlePayment, 
                description_payment: descriptionPayment, 
                keywords_payment: keywordsPayment, 
            }
        } else if (tabValue === 6) {
            data = {
                title_be_seller: titleBeSeller, 
                description_be_seller: descriptionBeSeller, 
                keywords_be_seller: keywordsBeSeller, 
            }
        } else if (tabValue === 7) {
            data = {
                title_collections: titleCollections, 
                description_collections: descriptionCollections, 
                keywords_collections: keywordsCollections, 
            }
        } else if (tabValue === 8) {
            data = {
                title_registration: titleRegistration, 
                description_registration: descriptionRegistration, 
                keywords_registration: keywordsRegistration, 
            }
        } else if (tabValue === 9) {
            data = {
                title_rates: titleRates, 
                description_rates: descriptionRates, 
                keywords_rates: keywordsRates, 
            }
        } else if (tabValue === 10) {
            data = {
                title_indivIdual_order: titleIndivIdualOrder, 
                description_indivIdual_order: descriptionIndivIdualOrder, 
                keywords_indivIdual_order: keywordsIndivIdualOrder, 
            }
        } else if (tabValue === 11) {
            data = {
                title_favorite: titleFavorite, 
                description_favorite: descriptionFavorite, 
                keywords_favorite: keywordsFavorite, 
            }
        }

        if (!seo._id?.length) {
            fetchRequest('POST', `${process.env.REACT_APP_BASE_URL}/seo?token=${token}`, data, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
                .then(res => {
                    if (res.success && res.data) {
                            dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                    } else {
                        console.log('PUT SeoSettings:', res)
                    }
                })
        } else {
            fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/seo/${seo._id}?token=${token}`, data, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
                .then(res => {
                    if (res.success && res.data) {
                        setSeo({...seo, ...data})
                            dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                    } else {
                        console.log('PUT SeoSettings:', res)
                    }
                })
        }
        if (setIsClickSave) {
            setIsClickSave(false)
        }
    }

    const handleSetTabValue = (num) => {
        setTabValue(num)
        newSearchParams.set('seoTabValue', JSON.stringify(num));
        setSearchParams(newSearchParams);
    }

    return (
        <div className='seo-settings'>
            <div>
                <div className="seo-settings__text-tab--wrap">
                    <div className={`seo-settings__text-tab-wrap `}>
                        <button className={`seo-settings__text-tab ${tabValue === 0 ? 'user-order__text-tab-active' : ''}`} onClick={() => handleSetTabValue(0)}><img className="seo-settings__tab-img" src={listImg} alt='img'/> <span>Головна сторінка</span></button>
                        <button className={`seo-settings__text-tab ${tabValue === 1 ? 'user-order__text-tab-active' : ''}`} onClick={() => handleSetTabValue(1)}><img className="seo-settings__tab-img" src={listImg} alt='img'/> <span>Про маркетплейс</span></button>
                        <button className={`seo-settings__text-tab ${tabValue === 2 ? 'user-order__text-tab-active' : ''}`} onClick={() => handleSetTabValue(2)}><img className="seo-settings__tab-img" src={listImg} alt='img'/> <span>Контакти</span></button>
                        <button className={`seo-settings__text-tab ${tabValue === 3 ? 'user-order__text-tab-active' : ''}`} onClick={() => handleSetTabValue(3)}><img className="seo-settings__tab-img" src={listImg} alt='img'/> <span>Як купувати на маркетплейсі</span></button>
                        <button className={`seo-settings__text-tab ${tabValue === 4 ? 'user-order__text-tab-active' : ''}`} onClick={() => handleSetTabValue(4)}><img className="seo-settings__tab-img" src={listImg} alt='img'/> <span>Каталог</span></button>
                        <button className={`seo-settings__text-tab ${tabValue === 5 ? 'user-order__text-tab-active' : ''}`} onClick={() => handleSetTabValue(5)}><img className="seo-settings__tab-img" src={listImg} alt='img'/> <span>Оплата, доставка та повернення</span></button>
                        <button className={`seo-settings__text-tab ${tabValue === 10 ? 'user-order__text-tab-active' : ''}`} onClick={() => handleSetTabValue(10)}><img className="seo-settings__tab-img" src={listImg} alt='img'/> <span>Індивідуальне виготовлення меблів</span></button>
                        <button className={`seo-settings__text-tab ${tabValue === 6 ? 'user-order__text-tab-active' : ''}`} onClick={() => handleSetTabValue(6)}><img className="seo-settings__tab-img" src={listImg} alt='img'/> <span>Продавати на платформі</span></button>
                        <button className={`seo-settings__text-tab ${tabValue === 7 ? 'user-order__text-tab-active' : ''}`} onClick={() => handleSetTabValue(7)}><img className="seo-settings__tab-img" src={listImg} alt='img'/> <span>Колекції меблів</span></button>
                        <button className={`seo-settings__text-tab ${tabValue === 8 ? 'user-order__text-tab-active' : ''}`} onClick={() => handleSetTabValue(8)}><img className="seo-settings__tab-img" src={listImg} alt='img'/> <span>Реєстрація партнерів</span></button>
                        <button className={`seo-settings__text-tab ${tabValue === 9 ? 'user-order__text-tab-active' : ''}`} onClick={() => handleSetTabValue(9)}><img className="seo-settings__tab-img" src={listImg} alt='img'/> <span>Сторінка тарифів</span></button>
                        <button className={`seo-settings__text-tab ${tabValue === 11 ? 'user-order__text-tab-active' : ''}`} onClick={() => handleSetTabValue(11)}><img className="seo-settings__tab-img" src={listImg} alt='img'/> <span>Сторінка обраних</span></button>
                    </div>
                </div>
            </div>

            {
                tabValue === 0 && 
                    <div className="seo-settings__result-tab-wrap">
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO заголовок</div>
                            <input className='creating-category__input' value={titleHome} onChange={(e) => setTitleHome(e.target.value)} placeholder='SEO заголовок' type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO опис</div>
                            <textarea className='creating-category__textarea' value={descriptionHome} onChange={(e) => setDescriptionHome(e.target.value)} placeholder='SEO опис' rows={8} type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO ключові слова</div>
                            <textarea className='creating-category__textarea seo-settings__textarea' value={keywordsHome} onChange={(e) => setKeywordsHome(e.target.value)} placeholder='SEO ключові слова' rows={4} type='text' />
                        </div>
                    </div>
            }
            {
                tabValue === 1 && 
                    <div className="seo-settings__result-tab-wrap">
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO заголовок</div>
                            <input className='creating-category__input' value={titleAbout} onChange={(e) => setTitleAbout(e.target.value)} placeholder='SEO заголовок' type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO опис</div>
                            <textarea className='creating-category__textarea' value={descriptionAbout} onChange={(e) => setDescriptionAbout(e.target.value)} placeholder='SEO опис' rows={8} type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO ключові слова</div>
                            <textarea className='creating-category__textarea seo-settings__textarea' value={keywordsAbout} onChange={(e) => setKeywordsAbout(e.target.value)} placeholder='SEO ключові слова' rows={4} type='text' />
                        </div>
                    </div>
            }
            {
                tabValue === 2 && 
                    <div className="seo-settings__result-tab-wrap">
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO заголовок</div>
                            <input className='creating-category__input' value={titleContacts} onChange={(e) => setTitleContacts(e.target.value)} placeholder='SEO заголовок' type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO опис</div>
                            <textarea className='creating-category__textarea' value={descriptionContacts} onChange={(e) => setDescriptionContacts(e.target.value)} placeholder='SEO опис' rows={8} type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO ключові слова</div>
                            <textarea className='creating-category__textarea seo-settings__textarea' value={keywordsContacts} onChange={(e) => setKeywordsContacts(e.target.value)} placeholder='SEO ключові слова' rows={4} type='text' />
                        </div>
                    </div>
            }
            {
                tabValue === 3 && 
                    <div className="seo-settings__result-tab-wrap">
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO заголовок</div>
                            <input className='creating-category__input' value={titleHowToBuy} onChange={(e) => setTitleHowToBuy(e.target.value)} placeholder='SEO заголовок' type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO опис</div>
                            <textarea className='creating-category__textarea' value={descriptionHowToBuy} onChange={(e) => setDescriptionHowToBuy(e.target.value)} placeholder='SEO опис' rows={8} type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO ключові слова</div>
                            <textarea className='creating-category__textarea seo-settings__textarea' value={keywordsHowToBuy} onChange={(e) => setKeywordsHowToBuy(e.target.value)} placeholder='SEO ключові слова' rows={4} type='text' />
                        </div>
                    </div>
            }
            {
                tabValue === 4 && 
                    <div className="seo-settings__result-tab-wrap">
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO заголовок</div>
                            <input className='creating-category__input' value={titleCatalog} onChange={(e) => setTitleCatalog(e.target.value)} placeholder='SEO заголовок' type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO опис</div>
                            <textarea className='creating-category__textarea' value={descriptionCatalog} onChange={(e) => setDescriptionCatalog(e.target.value)} placeholder='SEO опис' rows={8} type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO ключові слова</div>
                            <textarea className='creating-category__textarea seo-settings__textarea' value={keywordsCatalog} onChange={(e) => setKeywordsCatalog(e.target.value)} placeholder='SEO ключові слова' rows={4} type='text' />
                        </div>
                    </div>
            }
            {
                tabValue === 5 && 
                    <div className="seo-settings__result-tab-wrap">
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO заголовок</div>
                            <input className='creating-category__input' value={titlePayment} onChange={(e) => setTitlePayment(e.target.value)} placeholder='SEO заголовок' type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO опис</div>
                            <textarea className='creating-category__textarea' value={descriptionPayment} onChange={(e) => setDescriptionPayment(e.target.value)} placeholder='SEO опис' rows={8} type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO ключові слова</div>
                            <textarea className='creating-category__textarea seo-settings__textarea' value={keywordsPayment} onChange={(e) => setKeywordsPayment(e.target.value)} placeholder='SEO ключові слова' rows={4} type='text' />
                        </div>
                    </div>
            }
            {
                tabValue === 6 && 
                    <div className="seo-settings__result-tab-wrap">
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO заголовок</div>
                            <input className='creating-category__input' value={titleBeSeller} onChange={(e) => setTitleBeSeller(e.target.value)} placeholder='SEO заголовок' type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO опис</div>
                            <textarea className='creating-category__textarea' value={descriptionBeSeller} onChange={(e) => setDescriptionBeSeller(e.target.value)} placeholder='SEO опис' rows={8} type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO ключові слова</div>
                            <textarea className='creating-category__textarea seo-settings__textarea' value={keywordsBeSeller} onChange={(e) => setKeywordsBeSeller(e.target.value)} placeholder='SEO ключові слова' rows={4} type='text' />
                        </div>
                    </div>
            }
            {
                tabValue === 7 && 
                    <div className="seo-settings__result-tab-wrap">
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO заголовок</div>
                            <input className='creating-category__input' value={titleCollections} onChange={(e) => setTitleCollections(e.target.value)} placeholder='SEO заголовок' type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO опис</div>
                            <textarea className='creating-category__textarea' value={descriptionCollections} onChange={(e) => setDescriptionCollections(e.target.value)} placeholder='SEO опис' rows={8} type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO ключові слова</div>
                            <textarea className='creating-category__textarea seo-settings__textarea' value={keywordsCollections} onChange={(e) => setKeywordsCollections(e.target.value)} placeholder='SEO ключові слова' rows={4} type='text' />
                        </div>
                    </div>
            }
            {
                tabValue === 8 && 
                    <div className="seo-settings__result-tab-wrap">
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO заголовок</div>
                            <input className='creating-category__input' value={titleRegistration} onChange={(e) => setTitleRegistration(e.target.value)} placeholder='SEO заголовок' type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO опис</div>
                            <textarea className='creating-category__textarea' value={descriptionRegistration} onChange={(e) => setDescriptionRegistration(e.target.value)} placeholder='SEO опис' rows={8} type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO ключові слова</div>
                            <textarea className='creating-category__textarea seo-settings__textarea' value={keywordsRegistration} onChange={(e) => setKeywordsRegistration(e.target.value)} placeholder='SEO ключові слова' rows={4} type='text' />
                        </div>
                    </div>
            }
            {
                tabValue === 9 && 
                    <div className="seo-settings__result-tab-wrap">
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO заголовок</div>
                            <input className='creating-category__input' value={titleRates} onChange={(e) => setTitleRates(e.target.value)} placeholder='SEO заголовок' type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO опис</div>
                            <textarea className='creating-category__textarea' value={descriptionRates} onChange={(e) => setDescriptionRates(e.target.value)} placeholder='SEO опис' rows={8} type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO ключові слова</div>
                            <textarea className='creating-category__textarea seo-settings__textarea' value={keywordsRates} onChange={(e) => setKeywordsRates(e.target.value)} placeholder='SEO ключові слова' rows={4} type='text' />
                        </div>
                    </div>
            }
            {
                tabValue === 10 && 
                    <div className="seo-settings__result-tab-wrap">
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO заголовок</div>
                            <input className='creating-category__input' value={titleIndivIdualOrder} onChange={(e) => setTitleIndivIdualOrder(e.target.value)} placeholder='SEO заголовок' type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO опис</div>
                            <textarea className='creating-category__textarea' value={descriptionIndivIdualOrder} onChange={(e) => setDescriptionIndivIdualOrder(e.target.value)} placeholder='SEO опис' rows={8} type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO ключові слова</div>
                            <textarea className='creating-category__textarea seo-settings__textarea' value={keywordsIndivIdualOrder} onChange={(e) => setKeywordsIndivIdualOrder(e.target.value)} placeholder='SEO ключові слова' rows={4} type='text' />
                        </div>
                    </div>
            }
            {
                tabValue === 11 && 
                    <div className="seo-settings__result-tab-wrap">
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO заголовок</div>
                            <input className='creating-category__input' value={titleFavorite} onChange={(e) => setTitleFavorite(e.target.value)} placeholder='SEO заголовок' type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO опис</div>
                            <textarea className='creating-category__textarea' value={descriptionFavorite} onChange={(e) => setDescriptionFavorite(e.target.value)} placeholder='SEO опис' rows={8} type='text' />
                        </div>
                        <div className='seo-settings__result-tab-input-wrap'>
                            <div className='seo-settings__result-tab-input-lable'>SEO ключові слова</div>
                            <textarea className='creating-category__textarea seo-settings__textarea' value={keywordsFavorite} onChange={(e) => setKeywordsFavorite(e.target.value)} placeholder='SEO ключові слова' rows={4} type='text' />
                        </div>
                    </div>
            }
        </div>
    );
}

export default SeoSettings;