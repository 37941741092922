import './ContactsView.css';
import { useEffect, useState } from 'react';
import aboutMain from '../../assets/aboutMain.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRequest, validEmail } from '../../helpers/Utils';
import { setShowMessageObj } from '../../store/userSlice';
import { useLocation } from 'react-router-dom';
import InputPhone from '../../components/InputPhone/InputPhone';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function ContactsView() {
	const seo = useSelector((state) => state.homeSlice.seo);
	const utm_source = useSelector((state) => state.homeSlice.utm_source);
	const utm_medium = useSelector((state) => state.homeSlice.utm_medium);
	const utm_campaign = useSelector((state) => state.homeSlice.utm_campaign);
	const scrollPositionBeforeView = useSelector(
		(state) => state.homeSlice.scrollPositionBeforeView
	);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [description, setDescription] = useState('');
	const [isErrorName, setIsErrorName] = useState(false);
	const [isErrorPhone, setIsErrorPhone] = useState(false);
	const [isErrorEmail, setIsErrorEmail] = useState(false);
	const [isErrorDescription, setIsErrorDescription] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		setTimeout(() => {
			window.scroll({
				top: scrollPositionBeforeView[location.pathname]
					? scrollPositionBeforeView[location.pathname]
					: 0,
			});
		}, 10);
	}, []);

	useEffect(() => {
		if (name?.length) {
			setIsErrorName(false);
		}
	}, [name]);

	useEffect(() => {
		if (phone?.length && phone.length >= 12) {
			setIsErrorPhone(false);
		}
	}, [phone]);

	useEffect(() => {
		if (email?.length) {
			setIsErrorEmail(false);
		}
	}, [email]);

	useEffect(() => {
		if (description?.length) {
			setIsErrorDescription(false);
		}
	}, [description]);

	const hendleClean = () => {
		setName('');
		setEmail('');
		setPhone('');
		setDescription('');
	};

	const hendleSubmitFormGtm = () => {
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({ event: 'submit_form362347816' });
	};

	const hendleSend = (event) => {
		event.preventDefault();
		let sendBtn = document.getElementById('contactsViewSendBtnId');
		sendBtn.style.cssText = `
            pointer-events: none;
        `;
		if (
			name?.length &&
			email?.length &&
			phone?.length &&
			phone.length >= 12 &&
			description?.length &&
			validEmail(email)
		) {
			let data = {
				full_name: name,
				email: email,
				phone: phone,
				message: description,
				nameofpage: location,
				type: 'contact',
				utm_source: utm_source?.length ? utm_source : null,
				utm_medium: utm_medium?.length ? utm_medium : null,
				utm_campaign: utm_campaign?.length ? utm_campaign : null,
			};
			fetchRequest(
				'POST',
				`${process.env.REACT_APP_BASE_URL}/forms/contact`,
				data,
				() =>
					dispatch(
						setShowMessageObj({
							open: true,
							status: 'error',
							message: 'Сталася помилка',
						})
					)
			).then((res) => {
				if (res.success && res.data) {
					hendleSubmitFormGtm();
					setIsSendForm(true);
					hendleClean();
				} else {
					console.log('POST ContactsView:', res);
				}
			});
		} else {
			sendBtn.style.cssText = ``;
			setIsErrorName(!name?.length ? true : false);
			setIsErrorPhone(!phone?.length || phone.length < 12 ? true : false);
			setIsErrorEmail(!email.length || !validEmail(email) ? true : false);
			setIsErrorDescription(!description?.length ? true : false);
		}
	};

	return (
		<div className='contacts-view'>
			<SeoBlock
				title={seo.title_contacts}
				description={seo.description_contacts}
				keywords={seo.keywords_contacts}
			/>
			<div className='contacts-view--wrap'>
				<div className='contacts-view__title-wrap'>
					<div className='contacts-view__title container'>Наші контакти</div>
				</div>

				<div className='contacts-view__main-wrap'>
					<div className='contacts-view__main container'>
						<h1 className='contacts-view__main-text'>
							<b>Vyroby</b> - онлайн-майданчик, на якому виробники меблів
							самостійно опрацьовують замовлення та продають меблі клієнтам.
							Якщо у вас є питання щодо товару або бажаєте консультацію - на
							сторінці товару надсилайте запит виробнику за допомогою кнопки
							Задати питання або Адаптуй виріб під себе.
						</h1>
						<h2 className='contacts-view__main-title'>
							Як отримати консультацію щодо товару
						</h2>
						<img
							className='contacts-view__main-img'
							src={aboutMain}
							alt='img'
						/>
					</div>
				</div>

				<div className='contacts-view__form-wrap'>
					<div className='contacts-view__form container'>
						<div className='contacts-view__form--wrap'>
							<div className='contacts-view__form-info'>
								<div className='contacts-view__form-info-title'>
									Контактна інформація:
								</div>
								<p className='contacts-view__form-info-text'>
									02132, м. Київ, Дніпровська набережна, 26Ж <br />{' '}
									<span>
										(Будь ласка, зверніть увагу,{' '}
										<b>за цією адресою немає шоу-руму</b> з товарами)
									</span>
								</p>
								<div className='contacts-view__form-info-text'>
									<p className='contacts-view__form-info-text-phon'>
										Тел.: +38 (095) 837 70 57
									</p>
									<p>
										{' '}
										Email:{' '}
										<a
											className='contacts-view__form-info-text-link'
											href='info.vyroby@gmail.com'
											target='_blank'
										>
											info.vyroby@gmail.com
										</a>
									</p>
								</div>
								<div className='contacts-view__form-info-text-title'>
									Графік роботи: Пн - Пт: з 09:00 до 19:00
								</div>
								<p className='contacts-view__form-info-text contacts-view__form-info-text-1'>
									Пропозиції щодо співробітництва та питання щодо роботи
									майданчика можна надіслати на пошту або заповнити форму нижче
								</p>
							</div>
							<div className='contacts-view__form-input-wrap'>
								{isSendForm ? (
									<div className='contacts-view__form-success'>
										Дякуємо за ваше звернення. Найближчим часом ми з вами
										зв'яжемося
									</div>
								) : (
									<>
										<div className='contacts-view__form-error-wrap'>
											<input
												className={`contacts-view__form-input ${
													isErrorName ? 'contacts-view__form-error' : ''
												}`}
												onChange={(e) => setName(e.target.value)}
												value={name}
												autoComplete='name'
												type='text'
												placeholder="Вкажіть ваше ім'я"
											/>
											{isErrorName && (
												<div className='contacts-view__form-error-text'>
													Обов'язкове поле
												</div>
											)}
										</div>
										<div className='contacts-view__form-error-wrap'>
											<input
												className={`contacts-view__form-input ${
													isErrorEmail ? 'contacts-view__form-error' : ''
												}`}
												onChange={(e) => setEmail(e.target.value)}
												value={email}
												autoComplete='name'
												type='text'
												placeholder='Вкажіть ваш email'
											/>
											{isErrorEmail && (
												<div className='contacts-view__form-error-text'>
													{!email.length
														? "Обов'язкове поле"
														: 'Вкажіть, будь ласка, коректний email'}
												</div>
											)}
										</div>
										<div className='contacts-view__form-error-wrap'>
											<InputPhone
												phone={phone}
												setPhone={setPhone}
												isErrorPhone={isErrorPhone}
											/>
											{isErrorPhone && (
												<div className='contacts-view__form-error-text'>
													Обов'язкове поле
												</div>
											)}
										</div>
										<div className='contacts-view__form-error-wrap'>
											<textarea
												className={`contacts-view__form-textarea ${
													isErrorDescription ? 'contacts-view__form-error' : ''
												}`}
												onChange={(e) => setDescription(e.target.value)}
												value={description}
												placeholder='Опишіть ваше питання або побажання'
												rows='4'
											></textarea>
											{isErrorDescription && (
												<div className='contacts-view__form-error-text'>
													Обов'язкове поле
												</div>
											)}
										</div>
										{(isErrorName ||
											isErrorDescription ||
											isErrorEmail ||
											isErrorPhone) && (
											<div className='contacts-view__form-error-banner'>
												{(isErrorName ||
													isErrorDescription ||
													isErrorPhone) && (
													<div className='contacts-view__form-error-banner-text'>
														Будь ласка, заповніть всі обов'язкові поля
													</div>
												)}
												{isErrorEmail &&
													((!isErrorName &&
														!isErrorDescription &&
														!isErrorPhone) ||
														!!email.length) && (
														<div className='contacts-view__form-error-banner-text'>
															{!email.length
																? "Будь ласка, заповніть всі обов'язкові поля"
																: 'Вкажіть, будь ласка, коректний email'}
														</div>
													)}
											</div>
										)}
										<button
											id='contactsViewSendBtnId'
											className='main-btn-1 about-view__main-text-btn--1'
											disabled={isSendForm}
											onClick={hendleSend}
										>
											Надіслати звернення
										</button>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ContactsView;
