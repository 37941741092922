import './MainButton.css';
import { memo } from 'react';

function MainButton({text, typeBtn=1}) {
    return (
        <div className={`main-button-wrap ${typeBtn === 1 ? 'main-button-1-wrap' : ''}`}>
            {
                typeBtn === 1 && 
                    <div className="main-button main-button--white main-button--1">
                        <div className="main-button__text">{text}</div>
                    </div>
            }
            {
                typeBtn === 2 &&  
                <div className="main-button main-button--green">
                        <div className="main-button__text">{text}</div>
                    </div>
            }
            {
                typeBtn === 4 && 
                    <div className="main-button main-button--white main-button--green-border">
                        <div className="main-button__text">{text}</div>
                    </div>
            }
            {
                typeBtn === 5 && 
                    <div className="main-button main-button--white main-button--no-hover">
                        <div className="main-button__text">{text}</div>
                    </div>
            }
        </div>
    );
}

export default memo(MainButton);