import './CreatingProduct.css';
import { useState, useEffect, memo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	MenuItem,
	Select,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	fetchGetData,
	validNumber,
	handleCategoriesMap,
	handleSortCharacteristcs,
} from '../../helpers/Utils';
import plusImg from '../../assets/plusImg.svg';
import deleteImg2 from '../../assets/deleteImg2.svg';
import productsImg from '../../assets/products.svg';
import arrow2 from './../../assets/arrow2.svg';
import uploadImg from './../../assets/uploadImg.svg';
import saveImg from './../../assets/saveImg.svg';
import close2 from './../../assets/close2.svg';
import close3 from './../../assets/close3.svg';
import deleteImg4 from './../../assets/deleteImg4.svg';
import arrowLeftHover from './../../assets/arrowLeftHover.svg';
import materialPopoverImg from './../../assets/materialPopoverImg.jpg';
import materialPopoverImg2 from './../../assets/materialPopoverImg2.jpg';
import errorCircleImg from './../../assets/errorCircleImg.svg';
import {
	setShowMessageObj,
	setUpdateCategories,
	updateProducts,
	setEditeProduct,
} from '../../store/userSlice';
import SelectCharacteristic from '../SelectCharacteristic/SelectCharacteristic';
import ColorPicker from 'react-best-gradient-color-picker';
import ProductVariations from '../ProductVariations/ProductVariations';
import ColorResult from '../ColorResult/ColorResult';
import {
	NOT_DELETED_CATEGORY_ID,
	NOT_DELETED_SUB_CATEGORY_ID,
	NOT_DELETED_TREND_CATEGORY_ID,
	standardColors,
} from '../../helpers/Config';
import {
	useParams,
	useNavigate,
	useSearchParams,
	useLocation,
} from 'react-router-dom';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PreloaderCustom from '../PreloaderCustom/PreloaderCustom';
import SeoBlock from '../SeoBlock/SeoBlock';

function CreatingProduct() {
	const [searchParams, setSearchParams] = useSearchParams();
	const newSearchParams = new URLSearchParams(searchParams);
	const tabSearchParams = Number(searchParams.get('tab'));

	const categories = useSelector((state) => state.userSlice.categories);
	const vendors = useSelector((state) => state.userSlice.vendors);
	const editeProduct = useSelector((state) => state.userSlice.editeProduct);
	const isOpenMenu = useSelector((state) => state.userSlice.isOpenMenu);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [markText, setMarkText] = useState('');
	const [backgroundMark, setBackgroundMark] = useState('#19191D');
	const [productionTime, setProductionTime] = useState('');
	const [price, setPrice] = useState('');
	const [type, setType] = useState('serial');
	const [selectCategory, setSelectCategory] = useState({});
	const [vendorId, setVendorId] = useState('');
	const [code, setCode] = useState('');
	const [old_id, setOld_id] = useState('');
	const [seoFacebookTitle, setSeoFacebookTitle] = useState('');
	const [seoFacebookDescription, setSeoFacebookDescription] = useState('');
	const [seoTwitterTitle, setSeoTwitterTitle] = useState('');
	const [seoTwitterDescription, setSeoTwitterDescription] = useState('');
	const [seoDefaultTitle, setSeoDefaultTitle] = useState('');
	const [seoDefaultDescription, setSeoDefaultDescription] = useState('');
	const [images, setImages] = useState([]);
	const [newImages, setNewImages] = useState([]);
	const [characteristics, setCharacteristics] = useState([]);
	const [keywords, setKeywords] = useState('');
	const [seoDefaultKeywords, setSeoDefaultKeywords] = useState([]);
	const [isPreloader, setIsPreloader] = useState(false);
	const [selectColor, setSelectColor] = useState({});
	const [color, setColor] = useState('');
	const [color_name, setColor_name] = useState('');
	const [color_filter, setColor_filter] = useState('');
	const [retail_type, setRetail_type] = useState('');
	const [custom_id, setCustom_id] = useState('');
	const [priority, setPriority] = useState(0);
	const [videoLink1, setVideoLink1] = useState('');
	const [videoLink2, setVideoLink2] = useState('');
	const [model_3d, setModel_3d] = useState('');
	const [materialImg, setMaterialImg] = useState('');
	const [materialNewImg, setMaterialNewImg] = useState({});
	const [materialTitle, setMaterialTitle] = useState('');
	const [materialType, setMaterialType] = useState('');
	const [materialIngredient, setMaterialIngredient] = useState('');
	const [materialKeeping, setMaterialKeeping] = useState('');
	const [tabValue, setTabValue] = useState(
		tabSearchParams ? tabSearchParams : 0
	);
	const [timerId, setTimerId] = useState(null);
	const [isCreateError, setIsCreateError] = useState(false);
	const [isNewBackgroundMark, setIsNewBackgroundMark] = useState(false);
	const [isNewColor, setIsNewColor] = useState(false);
	const [isOpenTooltipMaterial, setIsOpenTooltipMaterial] = useState(false);
	const [isOpenTooltipColor, setIsOpenTooltipColor] = useState(false);

	const [isOpenSelectTabValue, setIsOpenSelectTabValue] = useState(false);
	const [isOpenSelectType, setIsOpenSelectType] = useState(false);
	const [isOpenSelectBackgroundMark, setIsOpenSelectBackgroundMark] =
		useState(false);
	const [isOpenSelectVendorId, setIsOpenSelectVendorId] = useState(false);
	const [isOpenSelectSelectCategory, setIsOpenSelectSelectCategory] =
		useState(false);
	const [isOpenSelectColor_filter, setIsOpenSelectColor_filter] =
		useState(false);
	const [isOpenSelectSelectColor, setIsOpenSelectSelectColor] = useState(false);
	const [isOrderImg, setIsOrderImg] = useState(false);
	const [errorTitle, setErrorTitle] = useState('');
	const [errorVendorId, setErrorVendorId] = useState('');
	const [errorCode, setErrorCode] = useState('');
	const [errorSelectCategory, setErrorSelectCategory] = useState('');
	const [errorPrice, setErrorPrice] = useState('');
	const [errorCustom_id, setErrorCustom_id] = useState('');
	const dispatch = useDispatch();
	const scrollRef = useRef(null);
	const { userProductId } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	let token = localStorage.getItem('token');
	const transliterator = new CyrillicToTranslit({ preset: 'uk' });

	useEffect(() => {
		if (userProductId?.length && !editeProduct?._id?.length) {
			handleGetProduct();
		}

		handleUpdateEditeProduct(editeProduct);

		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			handleClearInput();
			dispatch(setEditeProduct({}));
			window.removeEventListener('scroll', handleScroll);
			if (timerId) {
				clearTimeout(timerId);
			}
		};
	}, []);

	const handleScroll = () => {
		setIsOpenSelectTabValue(false);
		setIsOpenSelectType(false);
		setIsOpenSelectBackgroundMark(false);
		setIsOpenSelectVendorId(false);
		setIsOpenSelectSelectCategory(false);
		setIsOpenSelectColor_filter(false);
		setIsOpenSelectSelectColor(false);
	};

	useEffect(() => {
		if (editeProduct._id?.length) {
			handleUpdateEditeProduct(editeProduct);
		}
	}, [editeProduct]);

	const handleUpdateEditeProduct = (editeProduct) => {
		if (editeProduct?._id?.length) {
			setTitle(editeProduct.title?.length ? editeProduct.title : '');
			setCustom_id(
				editeProduct.custom_id?.length ? editeProduct.custom_id.slice(1) : ''
			);
			setMarkText(
				editeProduct.mark?.text?.length ? editeProduct.mark.text : ''
			);
			setBackgroundMark(
				editeProduct.mark?.background?.length
					? editeProduct.mark.background
					: ''
			);
			setProductionTime(
				editeProduct.production_time?.length ? editeProduct.production_time : ''
			);
			setDescription(
				editeProduct.description?.length ? editeProduct.description : ''
			);
			setPrice(editeProduct.price ? editeProduct.price : '');
			setRetail_type(
				editeProduct.retail_type?.length ? editeProduct.retail_type : ''
			);
			setType(editeProduct.type?.length ? editeProduct.type : '');
			setVendorId(editeProduct.vendor_id?.length ? editeProduct.vendor_id : '');
			setCode(editeProduct.code?.length ? editeProduct.code : '');
			setPriority(editeProduct.priority ? editeProduct.priority : 0);
			setOld_id(editeProduct.old_id?.length ? editeProduct.old_id : '');
			setColor(editeProduct.color?.length ? editeProduct.color : '');
			setColor_name(
				editeProduct.color_name?.length ? editeProduct.color_name : ''
			);
			setSelectColor(
				editeProduct.color_name?.length
					? { name: editeProduct.color_name, value: editeProduct.color }
					: {}
			);
			setColor_filter(
				editeProduct.color_filter?.length ? editeProduct.color_filter : ''
			);
			setSeoDefaultTitle(
				editeProduct.seo_default_title?.length
					? editeProduct.seo_default_title
					: ''
			);
			setSeoDefaultDescription(
				editeProduct.seo_default_description?.length
					? editeProduct.seo_default_description
					: ''
			);
			setSeoFacebookTitle(
				editeProduct.seo_facebook_title?.length
					? editeProduct.seo_facebook_title
					: ''
			);
			setSeoFacebookDescription(
				editeProduct.seo_facebook_description?.length
					? editeProduct.seo_facebook_description
					: ''
			);
			setSeoTwitterTitle(
				editeProduct.seo_twitter_title?.length
					? editeProduct.seo_twitter_title
					: ''
			);
			setSeoTwitterDescription(
				editeProduct.seo_twitter_description?.length
					? editeProduct.seo_twitter_description
					: ''
			);
			setSeoDefaultKeywords(
				editeProduct.seo_default_keywords?.length
					? editeProduct.seo_default_keywords
					: []
			);
			setImages(editeProduct.images?.length ? editeProduct.images : []);
			setMaterialTitle(
				editeProduct.material?.title?.length ? editeProduct.material?.title : ''
			);
			setMaterialType(
				editeProduct.material?.type?.length ? editeProduct.material?.type : ''
			);
			setMaterialIngredient(
				editeProduct.material?.ingredient?.length
					? editeProduct.material?.ingredient
					: ''
			);
			setMaterialKeeping(
				editeProduct.material?.keeping?.length
					? editeProduct.material?.keeping
					: ''
			);
			setMaterialImg(
				editeProduct.material?.img?.length ? editeProduct.material?.img : ''
			);
			setVideoLink1(
				editeProduct.video_link_1?.length ? editeProduct.video_link_1 : ''
			);
			setVideoLink2(
				editeProduct.video_link_2?.length ? editeProduct.video_link_2 : ''
			);
			setModel_3d(editeProduct.model_3d?.length ? editeProduct.model_3d : '');

			let res = handleCategoriesMap(categories).find(
				(el) => el._id === editeProduct.category_id
			);
			if (res) {
				setSelectCategory({ ...res });
				if (
					editeProduct.characteristics?.length &&
					res.characteristics_options?.length
				) {
					const arr = editeProduct.characteristics.filter((el) =>
						res.characteristics_options.some((ell) => ell.name === el.name)
					);
					setCharacteristics([...arr]);
				}
			}
		}
	};

	useEffect(() => {
		if (editeProduct?._id?.length) {
			let res = handleCategoriesMap(categories).find(
				(el) => el._id === editeProduct.category_id
			);
			if (res) {
				setSelectCategory({ ...res });
				if (
					editeProduct.characteristics?.length &&
					res.characteristics_options?.length
				) {
					const arr = editeProduct.characteristics.filter((el) =>
						res.characteristics_options.some((ell) => ell.name === el.name)
					);
					setCharacteristics([...arr]);
				}
			}
		}
	}, [categories]);

	useEffect(() => {
		if (selectColor.name === 'ownerColor') {
			setIsNewColor(true);
			setColor('');
			setColor_name('');
		}
	}, [selectColor]);

	useEffect(() => {
		if (!markText?.length) {
			setBackgroundMark('#19191D');
			setIsNewBackgroundMark(false);
		}
	}, [markText]);

	useEffect(() => {
		if (
			(tabSearchParams && tabSearchParams !== tabValue) ||
			(!tabSearchParams && tabValue !== 0)
		) {
			setTabValue(tabSearchParams ? tabSearchParams : 0);
		}
	}, [tabSearchParams]);

	useEffect(() => {
		if (
			backgroundMark === '#2A7F77' ||
			backgroundMark === '#932906' ||
			backgroundMark === '#19191D'
		) {
			setIsNewBackgroundMark(false);
		}
	}, [backgroundMark]);

	const handleAddCode = (str) => {
		setCode(str.toUpperCase().trim());
		clearTimeout(timerId);
		delayedSearch(str.toUpperCase().trim());
	};

	const delayedSearch = (str) => {
		setTimerId(setTimeout(() => handleSearchResult(str), 1000));
	};

	const handleSearchResult = (str) => {
		if (str?.length) {
			fetchGetData(
				`${process.env.REACT_APP_BASE_URL}/products/code?code=${str}`
			).then((res) => {
				if (res.success && res.data) {
					setIsCreateError(true);
				} else {
					setIsCreateError(false);
					console.log('GET CreatingProduct', res);
				}
			});
		}
	};

	const handleUploadMaterialImg = (img) => {
		if (img.name?.length) {
			setMaterialNewImg(img);
		}
	};

	const handleImageUploadMaterial = (e) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		if (file.name?.length) {
			setMaterialNewImg(file);
		}
	};

	const handleUploadImg = (img = null) => {
		let valuesArray = [];
		for (let key in img) {
			if (img.hasOwnProperty(key)) {
				valuesArray.push(img[key]);
			}
		}
		setNewImages([...newImages, ...valuesArray]);
	};

	const handleImageUpload = (e) => {
		e.preventDefault();
		const file = e.dataTransfer.files;
		let valuesArray = [];
		for (let key in file) {
			if (file.hasOwnProperty(key)) {
				valuesArray.push(file[key]);
			}
		}
		setNewImages([...newImages, ...valuesArray]);
	};

	const handleDeleteUploadImg = (name) => {
		setNewImages([...newImages.filter((el) => el.name !== name)]);
	};

	const handleDeleteImg = (img) => {
		let res = [...images.filter((el) => el !== img)];
		setImages(res);
	};

	const handleDeleteColor = () => {
		setIsNewColor(false);
		setColor('');
		setColor_name('');
		setSelectColor({});
	};

	const handleCloseColorPicker = () => {
		setSelectColor({});
		setIsNewColor(!isNewColor);
	};

	const handleSetColor = (res) => {
		let obj = JSON.parse(res);
		setIsNewColor(false);
		setSelectColor(obj);
		setColor(obj.value?.length ? obj.value : '');
		setColor_name(obj.name?.length ? obj.name : '');
	};

	const handleKeywordsKeyDown = (e) => {
		if (e.key == 'Enter') {
			handleAddKeyword();
		}
	};

	const handleAddKeyword = () => {
		if (keywords?.length) {
			setSeoDefaultKeywords([...seoDefaultKeywords, keywords]);
			setKeywords('');
		}
	};

	const handleDeleteKeyword = (str) => {
		setSeoDefaultKeywords([...seoDefaultKeywords.filter((el) => el !== str)]);
	};

	const handleSelectCategory = (str) => {
		let res = JSON.parse(str);
		setSelectCategory({ ...res });
		if (
			editeProduct.characteristics?.length &&
			res.characteristics_options?.length
		) {
			const arr = editeProduct.characteristics.filter((el) =>
				res.characteristics_options.some((ell) => ell.name === el.name)
			);
			setCharacteristics([...arr]);
		}
	};

	const handleChangeCharacteristics = (name, value) => {
		if (value === null || value === '' || value === ' ') {
			setCharacteristics([...characteristics.filter((el) => el.name !== name)]);
			return;
		}

		let obj = characteristics.find((el) => el.name === name);
		if (obj) {
			let res = characteristics.map((el) => {
				if (el.name === name) {
					el = { ...el, name: name, value: value };
					return el;
				}
				return el;
			});
			setCharacteristics([...res]);
		} else {
			setCharacteristics([...characteristics, { name: name, value: value }]);
		}
	};

	const handleIsValidCustomId = (inputString) => {
		const validFormat = /^([a-zA-ZіІ']+-)*[a-zA-ZіІ']+$/;
		return validFormat.test(inputString) && !inputString.includes(' ');
	};

	const handleClearInput = () => {
		setTitle('');
		setMarkText('');
		setBackgroundMark('#19191D');
		setProductionTime('');
		setDescription('');
		setPrice('');
		setSeoDefaultTitle('');
		setSeoDefaultDescription('');
		setSeoFacebookTitle('');
		setSeoFacebookDescription('');
		setSeoTwitterTitle('');
		setSeoTwitterDescription('');
		setKeywords('');
		setSeoDefaultKeywords([]);
		setNewImages([]);
		setCharacteristics([]);
		setCode('');
		setPriority(0);
		setOld_id('');
		setRetail_type('');
		setColor('');
		setColor_name('');
		setColor_filter('');
		setVendorId('');
		setType('serial');
		setSelectCategory({});
		setCustom_id('');
		setVideoLink1('');
		setVideoLink2('');
		setModel_3d('');
		setMaterialImg('');
		setMaterialTitle('');
		setMaterialType('');
		setMaterialIngredient('');
		setMaterialKeeping('');
		setMaterialNewImg({});
		setIsCreateError(false);
		dispatch(setEditeProduct({}));
	};

	const handleCheckCategoryIsHide = () => {
		let res = false;
		if (selectCategory._id) {
			res = handleCategoriesMap(categories).find(
				(el) => el._id === selectCategory._id
			).is_hide;
			if (!res) {
				res = vendors.find((el) => el._id === vendorId)?.is_blocked;
			}
		}
		return res;
	};

	const handleCheckCharacteristics = () => {
		if (selectCategory.characteristics_options?.length) {
			let characteristicsArr = selectCategory.characteristics_options.filter(
				(el) => el.is_required_input === true
			);
			if (characteristicsArr?.length) {
				if (characteristics?.length) {
					const uniqueNames = characteristicsArr.map((el) => el.name);
					const allNamesExist = uniqueNames.every((name) =>
						characteristics.some((char) => char.name === name)
					);
					return allNamesExist;
				} else {
					return false;
				}
			} else {
				return true;
			}
		} else {
			return true;
		}
	};

	const handleCreateProduct = () => {
		let formData = new FormData();

		formData.append('title', title);
		formData.append('description', description);
		formData.append(
			'mark',
			JSON.stringify({
				text: markText,
				background: backgroundMark,
			})
		);
		formData.append('production_time', productionTime);
		formData.append('price', +price);
		formData.append(
			'category_id',
			selectCategory._id?.length ? selectCategory._id : ''
		);
		formData.append(
			'category_name',
			selectCategory.name?.length ? selectCategory.name : ''
		);

		if (images.length) {
			images.map((img) => {
				formData.append('images[]', img);
			});
		} else {
			formData.append('images[]', []);
		}
		formData.append('vendor_id', vendorId);
		formData.append(
			'vendor_name',
			vendors.find((el) => el._id === vendorId)?.company_name
		);
		formData.append('type', type);
		formData.append('retail_type', retail_type);
		formData.append('code', code);
		formData.append('priority', priority ? Number(priority) : 0);
		formData.append('old_id', old_id);
		formData.append('color', color);
		formData.append('color_name', color_name);
		formData.append('color_filter', color_filter);
		formData.append('seo_default_title', seoDefaultTitle);
		formData.append('seo_default_description', seoDefaultDescription);
		if (seoDefaultKeywords.length) {
			seoDefaultKeywords.map((keywords) => {
				formData.append('seo_default_keywords[]', keywords);
			});
		} else {
			formData.append('seo_default_keywords[]', []);
		}
		formData.append('video_link_1', videoLink1);
		formData.append('video_link_2', videoLink2);
		formData.append('model_3d', model_3d);
		formData.append('seo_facebook_title', seoFacebookTitle);
		formData.append('seo_facebook_description', seoFacebookDescription);
		formData.append('seo_twitter_title', seoTwitterTitle);
		formData.append('seo_twitter_description', seoTwitterDescription);
		formData.append('characteristics', JSON.stringify(characteristics));
		if (custom_id?.length) {
			formData.append('custom_id', ('-' + custom_id).toLowerCase());
		} else {
			formData.append('custom_id', '');
		}

		formData.append(
			'material',
			JSON.stringify({
				img: materialImg,
				title: materialTitle,
				type: materialType,
				ingredient: materialIngredient,
				keeping: materialKeeping,
			})
		);
		if (materialNewImg?.name?.length) {
			formData.append('materialFile', materialNewImg);
		}

		if (
			title?.length &&
			(materialNewImg?.name?.length ? !hendleTestMaterialNewImg() : true) &&
			vendorId?.length &&
			code?.length &&
			selectCategory?._id?.length &&
			price &&
			!isCreateError &&
			(custom_id?.length ? handleIsValidCustomId(custom_id) : true) &&
			handleCheckCharacteristics()
		) {
			setIsPreloader(true);
			if (editeProduct?._id?.length) {
				formData.append('is_hide', editeProduct.is_hide ? true : false);
				fetch(
					`${process.env.REACT_APP_BASE_URL}/products/${editeProduct._id}?token=${token}`,
					{
						method: 'PUT',
						body: formData,
					}
				)
					.then((res) => res.json())
					.then((res) => {
						if (res.success && res.data) {
							if (newImages?.length) {
								handleUpdateProductImg(res.data, true);
							} else {
								dispatch(setEditeProduct(res.data));
								handleUpdateEditeProduct(res.data);
								if (res.category?._id?.length) {
									dispatch(setUpdateCategories(res.category));
								}
								dispatch(
									setShowMessageObj({
										open: true,
										status: 'success',
										message: 'Дані оновлено',
									})
								);
								setMaterialNewImg({});
								setIsPreloader(false);
								handleCheckError();
							}
						} else {
							console.log('PUT CreatingProduct:', res);
							dispatch(
								setShowMessageObj({
									open: true,
									status: 'error',
									message: 'Сталася помилка',
								})
							);
							setIsPreloader(false);
						}
					});
			} else {
				formData.append('is_hide', handleCheckCategoryIsHide());
				fetch(`${process.env.REACT_APP_BASE_URL}/products/?token=${token}`, {
					method: 'POST',
					body: formData,
				})
					.then((res) => res.json())
					.then((res) => {
						if (res.success && res.data) {
							if (newImages?.length) {
								handleUpdateProductImg(res.data);
							} else {
								if (res.category?._id?.length) {
									dispatch(setUpdateCategories(res.category));
								}
								dispatch(setEditeProduct(res.data));
								navigate(
									`/auth/products/${res.data._id}/?tab=${
										tabSearchParams ? tabSearchParams : 0
									}`
								);
								dispatch(
									setShowMessageObj({
										open: true,
										status: 'success',
										message: 'Товар створено',
									})
								);
								setIsPreloader(false);
								handleCheckError();
							}
						} else {
							console.log('POST CreatingProduct:', res);
							dispatch(
								setShowMessageObj({
									open: true,
									status: 'error',
									message: 'Сталася помилка',
								})
							);
						}
					});
			}
		} else {
			handleCheckError();
			dispatch(
				setShowMessageObj({
					open: true,
					status: 'error',
					message: `${
						editeProduct?._id?.length
							? 'Помилка при оновленні'
							: 'Помилка при створенні'
					}`,
				})
			);
		}
	};

	const handleCheckError = () => {
		setErrorTitle(!title?.length ? "Обов'язкове поле" : '');
		setErrorVendorId(!vendorId?.length ? "Обов'язкове поле" : '');
		setErrorCode(!code?.length ? "Обов'язкове поле" : '');
		setErrorSelectCategory(
			!selectCategory?._id?.length ? "Обов'язкове поле" : ''
		);
		setErrorPrice(!price || price === 0 ? "Обов'язкове поле" : '');
		setIsPreloader(false);
		setErrorCustom_id(
			!custom_id?.length ||
				(custom_id?.length && handleIsValidCustomId(custom_id))
				? false
				: true
		);
	};

	const handleUpdateProductImg = async (product, isUpdate = false) => {
		if (
			newImages.length &&
			(newImages.length + editeProduct.images?.length <= 35 ||
				newImages.length + product.images?.length <= 35)
		) {
			for (let index = 0; index < newImages.length; index++) {
				const img = newImages[index];
				let formData = new FormData();
				formData.append('files', img);

				try {
					const response = await fetch(
						`${process.env.REACT_APP_BASE_URL}/products/image/${product._id}?token=${token}`,
						{
							method: 'PUT',
							body: formData,
						}
					);
					const res = await response.json();
					const isLastImage = index === newImages.length - 1;

					if (isLastImage) {
						if (res.success && res.data) {
							if (isUpdate) {
								dispatch(
									setShowMessageObj({
										open: true,
										status: 'success',
										message: 'Товар оновлено',
									})
								);
							} else {
								dispatch(
									setShowMessageObj({
										open: true,
										status: 'success',
										message: 'Товар створено',
									})
								);
							}
							setIsPreloader(false);
							dispatch(updateProducts(res.data));
							if (res.category?._id?.length) {
								dispatch(setUpdateCategories(res.category));
							}
							setNewImages([]);
							dispatch(setEditeProduct(res.data));
							if (!editeProduct?._id?.length) {
								navigate(
									`/auth/products/${res.data._id}/?tab=${
										tabSearchParams ? tabSearchParams : 0
									}`
								);
							}
							handleCheckError();
						} else {
							console.log('PUT CreatingProduct:', res);
							dispatch(
								setShowMessageObj({
									open: true,
									status: 'error',
									message: 'Сталася помилка',
								})
							);
							setIsPreloader(false);
						}
					}
				} catch (error) {
					console.error('Error during fetch:', error);
					dispatch(
						setShowMessageObj({
							open: true,
							status: 'error',
							message: 'Помилка під час запиту',
						})
					);
					setIsPreloader(false);
				}
			}
		}
	};

	const handleGetProduct = () => {
		setIsPreloader(true);
		fetchGetData(
			`${
				process.env.REACT_APP_BASE_URL
			}/products/${userProductId}?is_admin=${true}`
		).then((res) => {
			if (res?.success && res?.data) {
				dispatch(setEditeProduct(res.data));
				handleUpdateEditeProduct(res.data);
			} else {
				handleClearInput();
				console.log('GET ProductView', res);
			}
			setIsPreloader(false);
		});
	};

	const handleMarkColorDelete = () => {
		setMarkText('');
		setBackgroundMark('#19191D');
	};

	const hendleTestMaterialNewImg = () => {
		return materialNewImg.size && materialNewImg.size > 150000 ? true : false;
	};

	const handleSetTabValue = (num) => {
		setTabValue(num);
		newSearchParams.set('tab', JSON.stringify(num));
		setSearchParams(newSearchParams);
	};

	const handleSetTitle = (str) => {
		setTitle(str);
		let res = str.replace(/[^a-zA-Zа-яА-ЯіІїЇєЄ\s]/g, '');
		if (res?.length) {
			const latinText = transliterator
				.transform(res)
				.trim()
				.split(' ')
				.join('-');
			return setCustom_id(latinText.toLowerCase());
		}
	};

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}
		const sourceIndex = result.source.index;
		const destinationIndex = result.destination.index;
		const newImagesArr = [...images];
		const [movedImage] = newImagesArr.splice(sourceIndex, 1);
		newImagesArr.splice(destinationIndex, 0, movedImage);
		setImages(newImagesArr);
	};

	return (
		<div className='creating-product' ref={scrollRef}>
			<SeoBlock
				title={
					!!editeProduct?._id?.length
						? 'Редагування товарів'
						: 'Створення товарів'
				}
				description={''}
				keywords={''}
				isCanonical={
					location.pathname === '/auth/products/create' ? true : false
				}
			/>
			{isPreloader ? (
				<PreloaderCustom />
			) : (
				<>
					<div className='creating-product__header'>
						<div className='creating-product__header-title-wrap'>
							<img
								className='creating-product__header-title-img'
								src={productsImg}
								alt='img'
							/>
							<h1 className='creating-product__header-title'>Товари</h1>
						</div>
						<div
							className={`creating-product__text-tab--wrap ${
								!editeProduct._id?.length
									? 'creating-product__text-tab-wrap--criete'
									: ''
							}`}
						>
							<div
								className={`user-order__text-tab-wrap ${
									!isOpenMenu
										? 'user-order__text-tab-wrap--is-open-menu'
										: 'user-order__text-tab-wrap--is-close-menu'
								}`}
							>
								<button
									className={`creating-product__text-tab ${
										!editeProduct._id?.length
											? 'creating-product__text-tab--criete'
											: ''
									} ${tabValue === 0 ? 'user-order__text-tab-active' : ''}`}
									onClick={() => handleSetTabValue(0)}
								>
									Основна інформація
								</button>
								<button
									className={`creating-product__text-tab ${
										!editeProduct._id?.length
											? 'creating-product__text-tab--criete'
											: ''
									} ${tabValue === 1 ? 'user-order__text-tab-active' : ''}`}
									onClick={() => handleSetTabValue(1)}
								>
									SEO налаштування
								</button>
								<button
									className={`creating-product__text-tab ${
										!editeProduct._id?.length
											? 'creating-product__text-tab--criete'
											: ''
									} ${tabValue === 2 ? 'user-order__text-tab-active' : ''}`}
									onClick={() => handleSetTabValue(2)}
								>
									Фото, відео, 3D
								</button>
							</div>
							<Select
								value={tabValue}
								className='product-view__variations-select creating-product__select-tabs'
								onChange={(e) => handleSetTabValue(e.target.value)}
								open={isOpenSelectTabValue}
								onClose={() => setIsOpenSelectTabValue(false)}
								onOpen={() => setIsOpenSelectTabValue(true)}
							>
								<MenuItem
									className={`product-view__variations-select-item ${
										tabValue === 0
											? 'product-view__variations-select-item-active'
											: ''
									}`}
									disableRipple={true}
									value={0}
								>
									Основна інформація
								</MenuItem>
								<MenuItem
									className={`product-view__variations-select-item ${
										tabValue === 1
											? 'product-view__variations-select-item-active'
											: ''
									}`}
									disableRipple={true}
									value={1}
								>
									SEO налаштування
								</MenuItem>
								<MenuItem
									className={`product-view__variations-select-item ${
										tabValue === 2
											? 'product-view__variations-select-item-active'
											: ''
									}`}
									disableRipple={true}
									value={2}
								>
									Фото, відео, 3D
								</MenuItem>
							</Select>
						</div>
						<div className='creating-product__header-btn-wrap'>
							{!userProductId?.length ? (
								<>
									<button
										className='creating-product__header-btn'
										onClick={() => navigate('/auth/products')}
									>
										<div className='creating-product__header-btn-img-all--wrap'>
											<img
												className='creating-product__header-btn-img-all'
												src={arrowLeftHover}
												alt='img'
											/>
										</div>
										<span>До усіх товарів</span>
									</button>
									<button
										className='creating-product__header-btn'
										onClick={() => handleCreateProduct()}
									>
										<img
											className='creating-product__header-btn-img'
											src={saveImg}
											alt='img'
										/>
										<span>Зберегти товар</span>
									</button>
								</>
							) : (
								<button
									className='creating-product__header-btn creating-product__header-btn--update'
									onClick={() => handleCreateProduct()}
								>
									<img
										className='creating-product__header-btn-img'
										src={saveImg}
										alt='img'
									/>
									<span>Оновити</span>
								</button>
							)}
						</div>
					</div>

					<div className='creating-product--wrap'>
						<div
							className={`creating-product__main-wrap ${
								tabValue !== 0 ? 'creating-product__main-wrap--hidden' : ''
							}`}
							key={0}
						>
							<div className='creating-product__input-wrap'>
								<div className='creating-product__input-top'>
									<div className='creating-product__input--wrap'>
										<div className='creating-product__input-lable requiredFields'>
											Назва товару
										</div>
										<textarea
											className={`creating-category__textarea creating-product__textarea ${
												!!errorTitle?.length
													? 'creating-category__input--error'
													: ''
											}`}
											value={title}
											onChange={(e) => handleSetTitle(e.target.value)}
											placeholder='Додати назву'
											rows={2}
											type='text'
										/>
										<div className='creating-product__input-sub-title'>
											Не перевищуйте 20 символів під час введення назви продукту
										</div>
										{!!errorTitle?.length && (
											<div className='creating-category__input-error-text'>
												{errorTitle}
											</div>
										)}
									</div>
									<div className='creating-product__input--wrap'>
										<div className='creating-product__input-lable'>
											Додати hash
										</div>
										<textarea
											className={`creating-category__textarea creating-product__textarea ${
												errorCustom_id ? 'creating-product__input-error' : ''
											}`}
											value={custom_id}
											onChange={(e) => setCustom_id(e.target.value.trim())}
											placeholder='Введіть hash'
											rows={2}
											type='text'
										/>
										{errorCustom_id && (
											<div className='creating-category__input-error-text'>
												Не правильне значення
											</div>
										)}
									</div>
									<div className='creating-product__input--wrap'>
										<div className='creating-product__input-lable'>
											Тип виробництва
										</div>
										<Select
											value={type}
											onChange={(e) => setType(e.target.value)}
											open={isOpenSelectType}
											onClose={() => setIsOpenSelectType(false)}
											onOpen={() => setIsOpenSelectType(true)}
											className='creating-product__select-mark--wrap creating-product__select-mark--type-wrap'
										>
											<MenuItem
												disableRipple={true}
												className={`creating-product__select-mark-item ${
													type === 'serial'
														? 'creating-product__select-mark-item-active'
														: ''
												}`}
												value={'serial'}
											>
												Серійне
											</MenuItem>
											<MenuItem
												disableRipple={true}
												className={`creating-product__select-mark-item ${
													type === 'individual'
														? 'creating-product__select-mark-item-active'
														: ''
												}`}
												value={'individual'}
											>
												Індивідуальне
											</MenuItem>
										</Select>
									</div>
									<div className='creating-product__input--wrap'>
										<div className='creating-product__input-lable requiredFields'>
											Ціна товару
										</div>
										<input
											className={`creating-category__input ${
												!!errorPrice?.length
													? 'creating-category__input--error'
													: ''
											}`}
											value={price}
											onChange={(e) => validNumber(e.target.value, setPrice)}
											placeholder='Введіть ціну'
											type='text'
										/>
										{!!errorPrice?.length && (
											<div className='creating-category__input-error-text'>
												{errorPrice}
											</div>
										)}
									</div>
									<div className='creating-product__input--wrap'>
										<div className='creating-product__input-lable'>
											Одиниця виміру
										</div>
										<input
											className='creating-category__input'
											value={retail_type}
											onChange={(e) => setRetail_type(e.target.value)}
											placeholder='Приклад: за пог. м.'
											type='text'
										/>
									</div>
									<div className='creating-product__input--wrap'>
										<div className='creating-product__input-lable requiredFields'>
											Код товару
										</div>
										<input
											className={`creating-category__input ${
												isCreateError ? 'creating-product__create-error' : ''
											} ${
												!!errorCode?.length
													? 'creating-category__input--error'
													: ''
											}`}
											value={code}
											onChange={(e) => handleAddCode(e.target.value)}
											placeholder='Код товару'
											type='text'
										/>
										{!!errorCode?.length && (
											<div className='creating-category__input-error-text'>
												{errorCode}
											</div>
										)}
									</div>

									<div className='creating-product__mark--wrap'>
										<div className='creating-product__input--wrap creating-product__input-mark--wrap'>
											<div className='creating-product__input-lable'>
												Позначка товару
											</div>
											<div className='creating-product__input-color-wrap'>
												<input
													className='creating-category__input creating-product__input-color'
													value={markText}
													onChange={(e) => setMarkText(e.target.value)}
													placeholder='"Акція" або "Хіт сезону"'
													type='text'
												/>
												{!!markText?.length && (
													<img
														className='creating-product__input-color-btn-close'
														onClick={handleMarkColorDelete}
														src={close3}
														alt='img'
													/>
												)}
											</div>
										</div>
										<div
											className={`creating-product__mark-wrap ${
												!markText?.length ? 'disabledContainer' : ''
											}`}
										>
											<div className='creating-product__input-lable'>
												Колір фону позначки товару
											</div>
											<Select
												value={backgroundMark?.length ? backgroundMark : ' '}
												onChange={(e) =>
													setBackgroundMark(
														e.target.value === ' ' ? '' : e.target.value
													)
												}
												open={isOpenSelectBackgroundMark}
												onClose={() => setIsOpenSelectBackgroundMark(false)}
												onOpen={() => setIsOpenSelectBackgroundMark(true)}
												className='creating-product__select-mark--wrap creating-product__select-mark--type-wrap'
											>
												{backgroundMark !== '#2A7F77' &&
													backgroundMark !== '#932906' &&
													backgroundMark !== '#19191D' &&
													!!backgroundMark?.length && (
														<MenuItem
															disableRipple={true}
															className={`creating-product__select-mark-item ${
																backgroundMark !== '#2A7F77' &&
																backgroundMark !== '#932906' &&
																backgroundMark !== '#19191D' &&
																!!backgroundMark?.length
																	? 'creating-product__select-mark-item-active'
																	: ''
															}`}
															value={backgroundMark}
														>
															<div className='creating-product__select-mark-wrap'>
																<ColorResult color={backgroundMark} />{' '}
																<span>Вибраний колір</span>
															</div>
														</MenuItem>
													)}
												<MenuItem
													disableRipple={true}
													className={`creating-product__select-mark-item ${
														backgroundMark === '#19191D'
															? 'creating-product__select-mark-item-active'
															: ''
													}`}
													value={'#19191D'}
												>
													<div className='creating-product__select-mark-wrap'>
														<ColorResult color={'#19191D'} />{' '}
														<span>Чорний</span>
													</div>
												</MenuItem>
												<MenuItem
													disableRipple={true}
													className={`creating-product__select-mark-item ${
														backgroundMark === '#932906'
															? 'creating-product__select-mark-item-active'
															: ''
													}`}
													value={'#932906'}
												>
													<div className='creating-product__select-mark-wrap'>
														<ColorResult color={'#932906'} />{' '}
														<span>Червоний</span>
													</div>
												</MenuItem>
												<MenuItem
													disableRipple={true}
													className={`creating-product__select-mark-item ${
														backgroundMark === '#2A7F77'
															? 'creating-product__select-mark-item-active'
															: ''
													}`}
													value={'#2A7F77'}
												>
													<div className='creating-product__select-mark-wrap'>
														<ColorResult color={'#2A7F77'} />{' '}
														<span>Зелений</span>
													</div>
												</MenuItem>
												<MenuItem
													disableRipple={true}
													className={`creating-product__select-mark-item ${
														backgroundMark === ''
															? 'creating-product__select-mark-item-active'
															: ''
													}`}
													value={' '}
												>
													<div
														className='creating-product__select-mark-wrap'
														onClick={() => setIsNewBackgroundMark(true)}
													>
														<span>Свій варіант</span>
													</div>
												</MenuItem>
											</Select>
											{isNewBackgroundMark && (
												<div className='creating-product__mark-color-picker-wrap'>
													<ColorPicker
														className='creating-product__mark-color-picker'
														value={backgroundMark}
														onChange={setBackgroundMark}
													/>
													<button
														className='creating-product__mark-btn '
														onClick={() => setIsNewBackgroundMark(false)}
													>
														Обрати
													</button>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className='creating-product__input-bottom'>
									<div className='creating-product__input--wrap'>
										<div className='creating-product__input-lable'>
											Опис Товару
										</div>
										<textarea
											className='creating-category__textarea'
											value={description}
											onChange={(e) => setDescription(e.target.value)}
											placeholder='Опис товару'
											rows={window.innerWidth >= 1284 ? 16 : 8}
											type='text'
										/>
									</div>
								</div>
							</div>
							<Accordion className='creating-product__produc-accordion'>
								<AccordionSummary
									expandIcon={
										<p>
											<img
												className='creating-product__produc-accordion-arrow'
												src={arrow2}
												alt='img'
											/>
										</p>
									}
									aria-controls='creating-product__produc'
									id='creating-product__produc'
								>
									<Typography className='creating-product__produc-accordion-title'>
										Характеристики
									</Typography>
								</AccordionSummary>
								<AccordionDetails className='creating-product__produc-accordion--main'>
									<div className='creating-product__produc-accordion-wrap'>
										<div className='creating-product__characteristics-result-color-wrap'>
											<div className='select-characteristic__input-characteristics-lable '>
												Наявність товару
											</div>
											<input
												className='creating-category__input creating-product__input-characteristic'
												value={productionTime}
												onChange={(e) => setProductionTime(e.target.value)}
												placeholder='Введіть термін виготовлення'
												type='text'
											/>
										</div>

										<div className='creating-product__characteristics-result-color-wrap'>
											<div className='select-characteristic__input-characteristics-lable'>
												Пріорітет товару
											</div>
											<input
												className='creating-category__input creating-product__input-characteristic'
												value={priority}
												onChange={(e) =>
													validNumber(e.target.value, setPriority)
												}
												placeholder='Введіть значення'
												type='text'
											/>
										</div>

										{!!vendors?.length && (
											<div className='creating-product__characteristics-result-color-wrap'>
												<div className='select-characteristic__input-characteristics-lable'>
													<span className='requiredFields'>Виробник</span>
												</div>
												<div className='creating-product__select--wrap creating-product__select-category--wrap'>
													<Select
														className={`creating-product__select-mark--wrap ${
															!!errorVendorId?.length
																? 'creating-product__select--error'
																: ''
														}`}
														value={!vendorId?.length ? ' ' : vendorId}
														onChange={(e) => setVendorId(e.target.value)}
														open={isOpenSelectVendorId}
														onClose={() => setIsOpenSelectVendorId(false)}
														onOpen={() => setIsOpenSelectVendorId(true)}
													>
														{!editeProduct._id?.length && (
															<MenuItem
																disableRipple={true}
																className={`creating-product__select-mark-item ${
																	!vendorId?.length
																		? 'creating-product__select-mark-item-active'
																		: ''
																}`}
																value=' '
															>
																Оберіть виробника
															</MenuItem>
														)}
														{!!vendors?.length &&
															vendors.map((el) => (
																<MenuItem
																	disableRipple={true}
																	className={`creating-product__select-mark-item ${
																		vendorId === el._id
																			? 'creating-product__select-mark-item-active'
																			: ''
																	}`}
																	value={el._id}
																	key={el._id}
																>
																	{el.company_name}
																</MenuItem>
															))}
													</Select>
													{!!errorVendorId?.length && (
														<div className='creating-category__input-error-text'>
															{errorVendorId}
														</div>
													)}
												</div>
											</div>
										)}

										{!!categories?.length && (
											<div
												className={`creating-product__characteristics-result-color-wrap`}
											>
												<div className='select-characteristic__input-characteristics-lable'>
													<span className='requiredFields'>Категорія</span>
												</div>
												<div className='creating-product__select--wrap creating-product__select-category--wrap'>
													<Select
														className={`creating-product__select-mark--wrap ${
															!!errorSelectCategory?.length
																? 'creating-product__select--error'
																: ''
														}`}
														value={JSON.stringify(selectCategory)}
														onChange={(e) =>
															handleSelectCategory(e.target.value)
														}
														label=''
														open={isOpenSelectSelectCategory}
														onClose={() => setIsOpenSelectSelectCategory(false)}
														onOpen={() => setIsOpenSelectSelectCategory(true)}
													>
														{!editeProduct.category_id?.length && (
															<MenuItem
																disableRipple={true}
																className={`creating-product__select-mark-item ${
																	selectCategory === JSON.stringify({})
																		? 'creating-product__select-mark-item-active'
																		: ''
																}`}
																value={JSON.stringify({})}
															>
																Оберіть категорію
															</MenuItem>
														)}
														{!!categories?.length &&
															handleCategoriesMap(categories).map((el) => (
																<MenuItem
																	disableRipple={true}
																	className={`creating-product__select-mark-item ${
																		selectCategory === JSON.stringify(el)
																			? 'creating-product__select-mark-item-active'
																			: ''
																	} ${
																		el._id === NOT_DELETED_CATEGORY_ID ||
																		el._id === NOT_DELETED_SUB_CATEGORY_ID ||
																		el._id === NOT_DELETED_TREND_CATEGORY_ID
																			? 'displayNone'
																			: ''
																	} ${
																		(el.parent_id === null ||
																			el.parent_id === 'null') &&
																		!!el.sub_categories?.length
																			? 'disabledContainer'
																			: ''
																	}`}
																	value={JSON.stringify(el)}
																	key={el._id}
																>
																	{el.name}
																</MenuItem>
															))}
													</Select>
													{!!errorSelectCategory?.length && (
														<div className='creating-category__input-error-text'>
															{errorSelectCategory}
														</div>
													)}
												</div>
											</div>
										)}

										{!!standardColors?.length && (
											<div className='creating-product__characteristics--wrap'>
												<Accordion
													defaultExpanded
													className='creating-product__characteristics-wrap'
												>
													<AccordionSummary
														expandIcon={
															<p>
																<img
																	className='creating-product__produc-accordion-arrow'
																	src={arrow2}
																	alt='img'
																/>
															</p>
														}
													>
														<div className='creating-product__input-characteristics-lable'>
															Характеристики товару вибраної категорії
														</div>
													</AccordionSummary>
													<AccordionDetails>
														<div className='creating-product__characteristics'>
															{!!selectCategory.characteristics_options
																?.length &&
																handleSortCharacteristcs([
																	...selectCategory.characteristics_options,
																]).map((el, i) => (
																	<div
																		className={`creating-product__characteristics-result ${
																			el.is_hide ? 'displayNone' : ''
																		}`}
																		key={el.name + i}
																	>
																		<SelectCharacteristic
																			oldCharacteristic={
																				editeProduct?.characteristics?.length
																					? editeProduct.characteristics.find(
																							(ell) => ell.name === el.name
																					  )
																					: null
																			}
																			characteristic={el}
																			handleChangeCharacteristics={
																				handleChangeCharacteristics
																			}
																		/>
																	</div>
																))}
															{!!standardColors?.length && (
																<div className='creating-product__characteristics-result'>
																	<div className='creating-product__characteristics-result-color-wrap'>
																		<div className='creating-product__characteristics-result-color-title'>
																			<div>Колір для фільтру</div>
																			<div className='creating-product__input-characteristics-title'>
																				По вибраному кольору товар
																				відображатиметься у фільтрі
																			</div>
																		</div>
																		<Select
																			value={
																				!color_filter?.length
																					? ' '
																					: color_filter
																			}
																			onChange={(e) =>
																				setColor_filter(e.target.value)
																			}
																			className='creating-product__select-mark--wrap creating-product__select-color--wrap'
																			open={isOpenSelectColor_filter}
																			onClose={() =>
																				setIsOpenSelectColor_filter(false)
																			}
																			onOpen={() =>
																				setIsOpenSelectColor_filter(true)
																			}
																		>
																			<MenuItem
																				disableRipple={true}
																				className={`creating-product__select-mark-item ${
																					color_filter === ' '
																						? 'creating-product__select-mark-item-active'
																						: ''
																				}`}
																				value=' '
																			>
																				<div className='creating-product__select-mark-wrap'>
																					<span>Без кольору</span>
																				</div>
																			</MenuItem>
																			{!!standardColors?.length &&
																				standardColors.map((el, i) => (
																					<MenuItem
																						disableRipple={true}
																						className={`creating-product__select-mark-item ${
																							color_filter === el.value
																								? 'creating-product__select-mark-item-active'
																								: ''
																						}`}
																						value={el.value}
																						key={el.value + i}
																					>
																						<div className='creating-product__select-mark-wrap'>
																							<ColorResult color={el.value} />{' '}
																							<span>{el.name}</span>
																						</div>
																					</MenuItem>
																				))}
																		</Select>
																	</div>
																</div>
															)}
														</div>
													</AccordionDetails>
												</Accordion>
											</div>
										)}
										<div className='creating-product__input-characteristics-lable-wrap'>
											<div className='creating-product__input-characteristics-lable'>
												Матеріал та колір товару
											</div>
											<div className='creating-product__input-sub-title'>
												Для відображення на сторінці товару можна вибрати тільки
												один параметр: Матеріал або Колір
											</div>
										</div>

										<div
											className={`creating-product__material-wrap ${
												!!color?.length ? 'disabledContainer' : ''
											}`}
										>
											<div className='creating-product__input-lable creating-product__input-lable-material'>
												<span>Матеріал</span>
												<Tooltip
													open={isOpenTooltipMaterial}
													onClose={() => setIsOpenTooltipMaterial(false)}
													placement='right-end'
													className=''
													title={
														<div className='creating-product__create-material-popover-img-wrap'>
															<img
																className='creating-product__create-material-popover-img'
																src={materialPopoverImg}
																alt='img'
															/>
														</div>
													}
												>
													<img
														className='catalog-view__popover-img'
														onClick={() => setIsOpenTooltipMaterial(true)}
														src={errorCircleImg}
														alt='img'
													/>
												</Tooltip>
											</div>
											<div className='creating-product__input-sub-title'>
												Ви можете обрати параметр Матеріал товару, щоб показати
												реальну текстуру, колір матеріалу і характеристики
												матеріалу
											</div>

											<div className='creating-product__material-img--wrap'>
												<div className='creating-product__material-label-wrap'>
													<div
														className='creating-product__create-img-wrap creating-product__create-img-material-wrap'
														onDrop={(e) => handleImageUploadMaterial(e)}
														onDragOver={(e) => e.preventDefault()}
													>
														<div className='creating-product__create-img-text-wrap'>
															<div className='creating-product__create-img-text-img-wrap'>
																<img
																	className='creating-product__create-img-text-img'
																	src={uploadImg}
																	alt='img'
																/>
															</div>
															<label
																className='creating-product__create-img-link-upload-lable'
																htmlFor='creationProductUserMaterialImages'
															>
																Клікніть завантажити
															</label>
															<span> або перетягніть зображення сюди</span>
															<p>Формати які підтримуються: PNG, JPG</p>
															<input
																className='creating-product__create-img-link-upload-input'
																onChange={(e) =>
																	handleUploadMaterialImg(e.target.files[0])
																}
																type='file'
																accept='image/*'
																id='creationProductUserMaterialImages'
															/>
														</div>
													</div>
													{!!materialNewImg.name?.length && (
														<div className='creating-product__material-result-wrap'>
															<button onClick={() => setMaterialNewImg({})}>
																<img
																	className='creating-product__upload-result-del-btn'
																	src={deleteImg4}
																	alt='img'
																/>
															</button>
															<img
																className='creating-product__material-result'
																src={URL.createObjectURL(materialNewImg)}
																alt='img'
															/>
															{hendleTestMaterialNewImg() && (
																<div className='creating-product__material-result-img-error'>
																	Розмір картинки перевищує 150kb
																</div>
															)}
														</div>
													)}
													{!!materialImg?.length &&
														!materialNewImg.name?.length && (
															<div className='creating-product__material-result-wrap'>
																<button onClick={() => setMaterialImg('')}>
																	<img
																		className='creating-product__upload-result-del-btn'
																		src={deleteImg4}
																		alt='img'
																	/>
																</button>
																<img
																	className='creating-product__material-result'
																	src={materialImg}
																	alt='img'
																/>
															</div>
														)}
												</div>
												<div className='creating-product__material-input-wrap'>
													<div className='creating-product__characteristics-result-color-wrap'>
														<div className='select-characteristic__input-characteristics-lable'>
															Назва матеріалу
														</div>
														<input
															className='creating-category__input creating-category__input-material'
															value={materialTitle}
															onChange={(e) => setMaterialTitle(e.target.value)}
															placeholder='Введіть назву матеріалу'
															type='text'
														/>
													</div>
													<div className='creating-product__characteristics-result-color-wrap'>
														<div className='select-characteristic__input-characteristics-lable'>
															Тип матеріалу
														</div>
														<input
															className='creating-category__input creating-category__input-material'
															value={materialType}
															onChange={(e) => setMaterialType(e.target.value)}
															placeholder='Введіть тип матеріалу'
															type='text'
														/>
													</div>
													<div className='creating-product__characteristics-result-color-wrap'>
														<div className='select-characteristic__input-characteristics-lable'>
															Склад матеріалу
														</div>
														<input
															className='creating-category__input creating-category__input-material'
															value={materialIngredient}
															onChange={(e) =>
																setMaterialIngredient(e.target.value)
															}
															placeholder='Введіть склад матеріалу'
															type='text'
														/>
													</div>
													<div className='creating-product__characteristics-result-color-wrap'>
														<div className='select-characteristic__input-characteristics-lable select-characteristic__input-characteristics-lable-textarea'>
															Догляд
														</div>
														<textarea
															className='creating-category__textarea creating-category__input-material'
															value={materialKeeping}
															onChange={(e) =>
																setMaterialKeeping(e.target.value)
															}
															placeholder='Введіть догляд за матеріалом'
															rows={5}
															type='text'
														/>
													</div>
												</div>
											</div>
										</div>

										<div
											className={`creating-product__select-color-wrap ${
												!!materialNewImg.name?.length ||
												!!materialImg?.length ||
												!!materialTitle?.length ||
												!!materialType?.length ||
												!!materialIngredient?.length ||
												!!materialKeeping?.length ||
												(editeProduct.variations?.length &&
													editeProduct.variations.find(
														(el) => el.name === 'Матеріал'
													)?.name?.length)
													? 'disabledContainer'
													: ''
											}`}
										>
											<div className='creating-product__select-wrap'>
												<div>
													<div className='creating-product__input-lable creating-product__input-lable-material'>
														<span>Колір</span>
														<Tooltip
															open={isOpenTooltipColor}
															onClose={() => setIsOpenTooltipColor(false)}
															placement='right-end'
															className=''
															title={
																<div className='creating-product__create-material-popover-img-wrap'>
																	<img
																		className='creating-product__create-material-popover-img'
																		src={materialPopoverImg2}
																		alt='img'
																	/>
																</div>
															}
														>
															<img
																className='catalog-view__popover-img'
																onClick={() => setIsOpenTooltipColor(true)}
																src={errorCircleImg}
																alt='img'
															/>
														</Tooltip>
													</div>
													<div className='creating-product__input-sub-title'>
														Ви можете обрати параметр Колір товару, якщо колір
														меблів монохромний
													</div>
												</div>
												<div className='creating-product__characteristics-result-color-wrap creating-product__characteristics-color-criete-wrap'>
													<div className='select-characteristic__input-characteristics-lable-wrap'>
														<div className='select-characteristic__input-characteristics-lable'>
															<span>Варіант кольору</span>
															{!selectColor?.name?.length && !color?.length ? (
																<img
																	className='select-characteristic__characteristics-delete-btn creating-product__characteristics-delete-btn--mob'
																	src={deleteImg2}
																	alt='img'
																/>
															) : (
																<img
																	className='select-characteristic__characteristics-delete-btn creating-product__characteristics-delete-btn--mob'
																	onClick={handleDeleteColor}
																	src={deleteImg4}
																	alt='img'
																/>
															)}
														</div>
														<Select
															value={JSON.stringify(selectColor)}
															onChange={(e) => handleSetColor(e.target.value)}
															className='creating-product__select-mark--wrap creating-product__select-color-criete--wrap'
															open={isOpenSelectSelectColor}
															onClose={() => setIsOpenSelectSelectColor(false)}
															onOpen={() => setIsOpenSelectSelectColor(true)}
														>
															<MenuItem
																disableRipple={true}
																className={`creating-product__select-mark-item ${
																	selectColor === JSON.stringify({})
																		? 'creating-product__select-mark-item-active'
																		: ''
																}`}
																value={JSON.stringify({})}
															>
																<div className='creating-product__select-mark-wrap'>
																	<span>Вибрати колір</span>
																</div>
															</MenuItem>
															<MenuItem
																disableRipple={true}
																className={`creating-product__select-mark-item ${
																	selectColor ===
																	JSON.stringify({
																		name: 'ownerColor',
																		value: '',
																	})
																		? 'creating-product__select-mark-item-active'
																		: ''
																}`}
																value={JSON.stringify({
																	name: 'ownerColor',
																	value: '',
																})}
															>
																<div className='creating-product__select-mark-wrap'>
																	<span>Свій колір</span>
																</div>
															</MenuItem>
															{JSON.stringify({
																name: editeProduct.color_name,
																value: editeProduct.color,
															}) === JSON.stringify(selectColor) &&
																JSON.stringify(selectColor) !==
																	JSON.stringify({}) && (
																	<MenuItem
																		disableRipple={true}
																		className={`creating-product__select-mark-item ${
																			selectColor ===
																			JSON.stringify(selectColor)
																				? 'creating-product__select-mark-item-active'
																				: ''
																		}`}
																		value={JSON.stringify(selectColor)}
																	>
																		<div className='creating-product__select-mark-wrap'>
																			<ColorResult color={selectColor.value} />
																			<span>Вибраний свій колір</span>
																		</div>
																	</MenuItem>
																)}
															{!!standardColors?.length &&
																standardColors.map((el, i) => (
																	<MenuItem
																		disableRipple={true}
																		className={`creating-product__select-mark-item ${
																			selectColor === JSON.stringify(el)
																				? 'creating-product__select-mark-item-active'
																				: ''
																		}`}
																		value={JSON.stringify(el)}
																		key={el.value + i}
																	>
																		<div className='creating-product__select-mark-wrap'>
																			<ColorResult color={el.value} />{' '}
																			<span>{el.name}</span>
																		</div>
																	</MenuItem>
																))}
														</Select>
													</div>
													{!selectColor?.name?.length && !color?.length ? (
														<img
															className='select-characteristic__characteristics-delete-btn creating-product__characteristics-delete-btn--desk'
															src={deleteImg2}
															alt='img'
														/>
													) : (
														<img
															className='select-characteristic__characteristics-delete-btn creating-product__characteristics-delete-btn--desk'
															onClick={handleDeleteColor}
															src={deleteImg4}
															alt='img'
														/>
													)}
												</div>
												{!!color?.length &&
													!!standardColors?.length &&
													!standardColors.find((el) => el.value === color) && (
														<div className='creating-product__color-result--wrap'>
															<div className='creating-product__characteristics-result-color-wrap creating-product__characteristics-color-wrap'>
																<div className='select-characteristic__input-characteristics-lable'>
																	Назва кольору
																</div>
																<input
																	className='creating-category__input creating-category__input-characteristic'
																	value={color_name}
																	onChange={(e) =>
																		setColor_name(e.target.value)
																	}
																	placeholder='Введіть назву кольору'
																	type='text'
																/>
																<div className='creating-category__characteristic-add-result creating-product__characteristic-add-result'>
																	<ColorResult color={color} />
																	{!!color_name?.length && (
																		<span>{color_name}</span>
																	)}
																	<img
																		className='select-characteristic__add-result-del-words-btn creating-product__color-result-btn'
																		onClick={() => setColor('')}
																		src={close2}
																		alt='img'
																	/>
																</div>
															</div>
														</div>
													)}
												{isNewColor && (
													<div className='creating-product__color-picker-wrap'>
														<ColorPicker value={color} onChange={setColor} />
														<button
															className='creating-category__header-btn creating-product__select-color-btn'
															onClick={() => handleCloseColorPicker()}
														>
															Обрати
														</button>
													</div>
												)}
											</div>
										</div>
									</div>
								</AccordionDetails>
							</Accordion>
							{!!editeProduct?._id?.length && (
								<div className='creating-product__variations-wrap'>
									<ProductVariations />
								</div>
							)}
						</div>

						<div
							className={`creating-product__seo-wrap ${
								tabValue !== 1 ? 'creating-product__main-wrap--hidden' : ''
							}`}
							key={1}
						>
							<div className='creating-product__input--wrap'>
								<div className='creating-product__input-lable'>SEO title</div>
								<input
									className='creating-category__input'
									value={seoDefaultTitle}
									onChange={(e) => setSeoDefaultTitle(e.target.value)}
									placeholder='SEO title'
									type='text'
								/>
							</div>
							<div className='creating-product__input--wrap'>
								<div className='creating-product__input-lable'>
									SEO description
								</div>
								<textarea
									className='creating-category__textarea'
									value={seoDefaultDescription}
									onChange={(e) => setSeoDefaultDescription(e.target.value)}
									placeholder='SEO description'
									rows={8}
									type='text'
								/>
							</div>
							<div className='creating-product__add-btn-wrap'>
								<div className='creating-product__add-btn--wrap'>
									<input
										className='creating-category__input'
										value={keywords}
										onChange={(e) => setKeywords(e.target.value)}
										onKeyDown={(e) => handleKeywordsKeyDown(e)}
										placeholder='SEO keyword'
										type='text'
									/>
									<img
										className='creating-product__add-btn'
										onClick={handleAddKeyword}
										src={plusImg}
										alt='img'
									/>
								</div>
								{!!seoDefaultKeywords?.length && (
									<div className='creating-product__add-result-wrap'>
										{seoDefaultKeywords.map((el, i) => (
											<div className='creating-category__add-result' key={el}>
												<p>{el}</p>
												<button onClick={() => handleDeleteKeyword(el)}>
													<img
														className='creating-category__add-result-del-words-btn'
														src={close2}
														alt='img'
													/>
												</button>
											</div>
										))}
									</div>
								)}
							</div>
							<div className='creating-product__input--wrap'>
								<div className='creating-product__input-lable'>
									SEO facebook title
								</div>
								<input
									className='creating-category__input'
									value={seoFacebookTitle}
									onChange={(e) => setSeoFacebookTitle(e.target.value)}
									placeholder='SEO facebook title'
									type='text'
								/>
							</div>
							<div className='creating-product__input--wrap'>
								<div className='creating-product__input-lable'>
									SEO facebook description
								</div>
								<input
									className='creating-category__input'
									value={seoFacebookDescription}
									onChange={(e) => setSeoFacebookDescription(e.target.value)}
									placeholder='SEO facebook description'
									type='text'
								/>
							</div>

							<div className='creating-product__input--wrap'>
								<div className='creating-product__input-lable'>
									SEO twitter title
								</div>
								<input
									className='creating-category__input'
									value={seoTwitterTitle}
									onChange={(e) => setSeoTwitterTitle(e.target.value)}
									placeholder='SEO twitter title'
									type='text'
								/>
							</div>
							<div className='creating-product__input--wrap'>
								<div className='creating-product__input-lable'>
									SEO twitter description
								</div>
								<input
									className='creating-category__input'
									value={seoTwitterDescription}
									onChange={(e) => setSeoTwitterDescription(e.target.value)}
									placeholder='SEO twitter description'
									type='text'
								/>
							</div>
							<div className='creating-product__input--wrap'>
								<div className='creating-product__input-lable'>Old id</div>
								<input
									className='creating-category__input'
									value={old_id}
									onChange={(e) => setOld_id(e.target.value)}
									placeholder='Введіть id'
									type='text'
								/>
							</div>
						</div>

						<div
							className={`${
								tabValue !== 2 ? 'creating-product__main-wrap--hidden' : ''
							}`}
						>
							<div className='creating-product__input-video-wrap'>
								<div className='creating-product__input--wrap'>
									<div className='creating-product__input-lable'>Відео №1</div>
									<input
										className='creating-category__input'
										value={videoLink1}
										onChange={(e) => setVideoLink1(e.target.value)}
										placeholder='Посилання на відео № 1'
										type='text'
									/>
								</div>
								<div className='creating-product__input--wrap'>
									<div className='creating-product__input-lable'>Відео №2</div>
									<input
										className='creating-category__input'
										value={videoLink2}
										onChange={(e) => setVideoLink2(e.target.value)}
										placeholder='Посилання на відео № 2'
										type='text'
									/>
								</div>
								<div className='creating-product__input--wrap'>
									<div className='creating-product__input-lable'>3D модель</div>
									<input
										className='creating-category__input'
										value={model_3d}
										onChange={(e) => setModel_3d(e.target.value)}
										placeholder='Посилання на 3D модель'
										type='text'
									/>
								</div>
							</div>

							<div className='creating-product__create-img--wrap'>
								<div
									className='creating-product__create-product-img-wrap'
									onDrop={(e) => handleImageUpload(e)}
									onDragOver={(e) => e.preventDefault()}
								>
									<div className='creating-product__create-img-text-wrap'>
										<div className='creating-product__create-img-text-img-wrap'>
											<img
												className='creating-product__create-img-text-img'
												src={uploadImg}
												alt='img'
											/>
										</div>
										<label
											className='creating-product__create-img-link-upload-lable'
											htmlFor='creationProductUserImages'
										>
											Клікніть завантажити
										</label>
										<span> або перетягніть зображення сюди</span>
										<p>Формати які підтримуються: PNG, JPG</p>
										<input
											className='creating-product__create-img-link-upload-input'
											onChange={(e) => handleUploadImg(e.target.files)}
											multiple
											type='file'
											accept='image/*'
											id='creationProductUserImages'
										/>
									</div>
								</div>

								<div className='creating-product__input-sub-title'>
									Усі фото мають бути 1:1 квадратні
								</div>
								{images?.length === 35 && (
									<div>
										<b>Досягнуто максимальний ліміт 35 фотографій</b>
									</div>
								)}

								<div className='creating-product__upload-result--wrap'>
									{!!newImages?.length &&
										newImages.map((el) => (
											<div
												className='creating-product__upload-result-wrap'
												key={el.name}
											>
												<button
													className='creating-product__upload-result-del-img-btn'
													onClick={() => handleDeleteUploadImg(el.name)}
												>
													<img
														className='creating-product__upload-result-del-img-btn-img'
														src={deleteImg4}
														alt='img'
													/>
												</button>
												<img
													className='creating-product__upload-img-result'
													src={URL.createObjectURL(el)}
													alt='img'
												/>
											</div>
										))}
								</div>

								<div className='creating-product__result-img--wrap'>
									<button
										className='creating-product__result-btn'
										onClick={() => setIsOrderImg(!isOrderImg)}
									>
										{isOrderImg
											? 'Переглянути упорядкування'
											: 'Упорядкувати фотографії'}
									</button>
									{!isOrderImg ? (
										<div className='creating-product__result-img--container-img-wrap'>
											{images.map((image, index) => (
												<div
													key={image}
													className='creating-product__result-img--item-class'
												>
													<button
														className='creating-product__upload-result-del-img-btn'
														onClick={() => handleDeleteImg(image)}
													>
														<img
															className='creating-product__upload-result-del-img-btn-img'
															src={deleteImg4}
															alt='img'
														/>
													</button>
													<img
														className='creating-product__upload-img-result'
														src={image}
														alt='img'
													/>
												</div>
											))}
										</div>
									) : (
										<DragDropContext onDragEnd={onDragEnd}>
											<Droppable droppableId='droppable' direction='vertical'>
												{(provided) => (
													<div
														{...provided.droppableProps}
														ref={provided.innerRef}
														className='creating-product__result-img--container-class'
													>
														{images.map((image, index) => (
															<Draggable
																key={image + index}
																draggableId={image + index}
																index={index}
															>
																{(provided) => (
																	<div
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		className='creating-product__result-img--item-class'
																	>
																		<button
																			className='creating-product__upload-result-del-img-btn--droppable'
																			onClick={() => handleDeleteImg(image)}
																		>
																			<img
																				className='creating-product__upload-result-del-img-btn-img'
																				src={deleteImg4}
																				alt='img'
																			/>
																		</button>
																		<span>{index + 1}</span>
																		<img
																			className='creating-product__container-img-result'
																			src={image}
																			alt='img'
																		/>
																	</div>
																)}
															</Draggable>
														))}
														{provided.placeholder}
													</div>
												)}
											</Droppable>
										</DragDropContext>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default memo(CreatingProduct);
