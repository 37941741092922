import './UserSettingsView.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import deleteImg3 from '../../assets/deleteImg3.svg';
import uploadImg from '../../assets/uploadImg.svg';
import settings3 from '../../assets/settings3.svg';
import plus from '../../assets/plus.svg';
import saveImg from '../../assets/saveImg.svg';
import { setShowMessageObj } from '../../store/userSlice';
import { fetchGetData, fetchRequest } from '../../helpers/Utils';
import { FormControlLabel, MenuItem, Select, Switch } from '@mui/material';
import SeoSettings from '../../components/SeoSettings/SeoSettings';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import BannerResult from '../../components/BannerResult/BannerResult';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { NavLink, useSearchParams } from 'react-router-dom';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function UserSettingsView() {
    const [searchParams, setSearchParams] = useSearchParams();
    const newSearchParams = new URLSearchParams(searchParams);
    const tabValueSearchParams = Number(JSON.parse(searchParams.get('tabValue')))

    const isOpenMenu = useSelector(state => state.userSlice.isOpenMenu);
    const [tabValue, setTabValue] = useState(tabValueSearchParams ? tabValueSearchParams : 0);
    const [banners, setBanners] = useState([]);
    const [bannersCopy, setBannersCopy] = useState([]);
    const [bannersFile, setBannersFile] = useState([]);
    const [isPreloader, setIsPreloader] = useState(true);
    const [headerLinks, setHeaderLinks] = useState({});
    const [headerText, setHeaderText] = useState('');
    const [headerLink, setHeaderLink] = useState('');
    const [deleteLinkText, setDeleteLinkText] = useState('');
    const [isDeleteLink, setIsDeleteLink] = useState(false);
    const [isHeaderLink, setIsHeaderLink] = useState(false);
    const [isClickSave, setIsClickSave] = useState(false);
    const [isOpenSelectTabValue, setIsOpenSelectTabValue] = useState(false);
    const [isDownloadFeed, setIsDownloadFeed] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const dispatch = useDispatch();
    let token = localStorage.getItem('token')

    useEffect(() => {
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/configurations`)
            .then(res => {
                if (res.success && res.data) {
                    if (res.data.banners?.length) {
                        setBanners(res.data.banners)
                        setBannersCopy(res.data.banners)
                    }
                    setHeaderLinks(res.data.header_links?.linksValue?.length ? res.data.header_links : {})
                    setIsHeaderLink(res.data.header_links?.linksValue?.length ? res.data.header_links.isVisible : false)
                } else {
                    console.log('GET UserSettingsView', res)
                }
                setIsPreloader(false)
        })
        window.addEventListener('scroll', handleScroll, { passive: true });
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    useEffect(() => {
        if ((tabValueSearchParams && tabValueSearchParams !== tabValue) || (!tabValueSearchParams && tabValue !== 0)) {
            setTabValue(tabValueSearchParams ? tabValueSearchParams : 0)
        }
    }, [tabValueSearchParams])

    const handleScroll = () => {
        setIsOpenSelectTabValue(false)
    };
    
    const handleChangeVisible = () => {
        let data = {
            header_links: {
                isVisible: isHeaderLink,
                linksValue: headerLinks.linksValue
            }
        } 
        handleUpdateHeaderLink(data)
    }

    const handleUpdateSlider = () => {
        if (banners.length) {
            setIsPreloader(true)
            banners.map((el, i) => {
                if (JSON.stringify(bannersCopy[i]) !== JSON.stringify(banners[i])) {
                    let formData = new FormData();
            
                    formData.append('text', el.text)
                    formData.append('link', el.link)
                    formData.append('file', el.file)
                    formData.append('image', el.image)
            
                    fetch(`${process.env.REACT_APP_BASE_URL}/banners/${el._id}?token=${token}`, {
                        method: 'PUT',
                        body: formData
                    })
                        .then(res => res.json())
                        .then(res => {
                            if (res.success && res.data) {
                                setBanners((prevBanners) =>
                                    prevBanners.map((ell) => (ell._id === res.data._id ? res.data : ell))
                                );
                                dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                            } else {
                                console.log('PUT UserSettingsView:', res)
                                dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'}))
                            }
                        })
                }
                if (i === (banners.length - 1)) {
                    setIsPreloader(false)
                }
            })
        }
    }
        
    const handleDeleteLink = (str) => {
        setIsDeleteLink(true)
        setDeleteLinkText(str)
    }
    
    const handleIsDeleteLink = (boolean) => {
        if (boolean) {
            let data = {
                header_links: {
                    isVisible: isHeaderLink,
                    linksValue: [...headerLinks.linksValue.filter(el => el.text !== deleteLinkText)]
                },
            } 
            handleUpdateHeaderLink(data)
        }
        setIsDeleteLink(false)
        setDeleteLinkText('')
    }

    const handleUpdateHeaderLink = (data) => {
        setIsPreloader(true)
        fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/configurations?token=${token}`, data, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
            .then(res => {
                if (res.success && res.data) {
                    setHeaderLinks(data.header_links)
                    dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                } else {
                    console.log('PUT UserSettingsView:', res)
                }
                setIsPreloader(false)
            })
    }

    const handleAddHeaderLink = () => {
        if (headerText?.length && (!headerLinks?.linksValue?.length || headerLinks.linksValue?.length < 3)) {
            let res = headerLinks.linksValue?.length ? [...headerLinks.linksValue, {text: headerText, links: headerLink}] : [{text: headerText, links: headerLink}]
            let data = {
                header_links: {
                    isVisible: isHeaderLink,
                    linksValue: res
                }
            } 
            if (headerLinks?.linksValue?.length || banners?.length) {
                handleUpdateHeaderLink(data)
            } else {
                setIsPreloader(true)
                fetchRequest('POST', `${process.env.REACT_APP_BASE_URL}/configurations?token=${token}`, data, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
                    .then(res => {
                        if (res.success && res.data) {
                            setHeaderLinks(data.header_links)
                            dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                        } else {
                            console.log('POST UserSettingsView:', res)
                        }
                        setIsPreloader(false)
                    })
            }
            setHeaderLink('')
            setHeaderText('')
        }
    }
        
    const handleCreateSlider = () => {
        if (bannersFile?.length) {
            setIsPreloader(true)
            bannersFile.map((el, i) => {
                let formData = new FormData();
                formData.append('text', el.text)
                formData.append('link', el.link)
                formData.append('file', el.file)
             
                fetch(`${process.env.REACT_APP_BASE_URL}/banners?token=${token}`, {
                    method: 'POST',
                    body: formData
                })
                    .then(res => res.json())
                    .then(res => {
                        if (res.success && res.data) {
                            setBanners((prevBanners) => [res.data, ...prevBanners]);
                            dispatch(setShowMessageObj({open: true, status: 'success', message: 'Слайдер створено'}))
                        } else {
                            console.log('POST UserSettingsView:', res)
                            dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'}))
                        }
                    if (i === (bannersFile.length - 1)) {
                        setIsPreloader(false)
                        setBannersFile([])
                    }
                    })
            })

        }
    }

    const handleImageUpload = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files;
        let valuesArray = [];
            for (let key in file) {
                if (file.hasOwnProperty(key)) {
                    valuesArray.push({text: '', link: '', img: '', file: file[key]});
                }
            }
        if (valuesArray?.length) {
            if (banners?.length) {
                setBanners([...valuesArray, ...banners])
            } else {
                setBanners([...valuesArray])
            }
        }
    }

    const handleUploadImg = (img=null) => {
        let valuesArray = [];
            for (let key in img) {
                if (img.hasOwnProperty(key)) {
                    valuesArray.push({text: '', link: '', img: '', file: img[key]});
                }
            }
        if (valuesArray?.length) {
            if (bannersFile?.length) {
                setBannersFile([...valuesArray, ...bannersFile])
            } else {
                setBannersFile([...valuesArray])
            }
        }
    }

    const handleSetTabValue = (num) => {
        setTabValue(num)
        newSearchParams.set('tabValue', JSON.stringify(num));
        newSearchParams.set('seoTabValue', JSON.stringify(0));
        setSearchParams(newSearchParams);
    }

    const handleClickUpdate = () => {
        if (tabValue === 0) {
            if (!!banners?.length) {
                handleUpdateSlider()
            } 
            if (!!bannersFile?.length) {
                handleCreateSlider()
            } 
        }
        if (tabValue === 1) {
            setIsClickSave(true)
        }
        if (tabValue === 2) {
            handleChangeVisible()
        }
    }

    const deleteExcel = () => {
        setFileUrl('')
        setIsDownloadFeed(false)
    };

    const handleDownloadFeed = (boolean) => {
        if (boolean) {
            setIsPreloader(true)
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/products/csvgoogle?token=${token}`)
                .then(res => {
                    if (res.success && res.data) {
                        setFileUrl(res.data)
                    } else {
                        console.log('GET UserProductsViews', res)
                    }
                    setIsPreloader(false)
                })
        } else {
            deleteExcel()
        }
    }
    
    return (
        <div className={`user-settings ${!isOpenMenu ? 'user-order__orders--is-open-menu' : 'user-order__orders--is-close-menu'}`}>
            <SeoBlock title={"Налаштування"} description={""} keywords={""}/>
            {
                (isDownloadFeed && !isPreloader) && <ModalWindow title={'Завантажити feed?'} handleClick={handleDownloadFeed} >
                    {!!fileUrl?.length && (
                        <a className='user-products__btn-download-link' onClick={deleteExcel} href={fileUrl} download>
                            Download File
                        </a>
                    )}
                </ModalWindow>
            }

            {
                isDeleteLink && <ModalWindow title={'Видалити дане посилання?'} handleClick={handleIsDeleteLink} />
            }  

            <div className="user-settings__header">
                <div className="user-settings__header-title-wrap">
                    <img className="user-settings__header-title-img" src={settings3} alt='img'/>
                    <h1 className="user-settings__header-title">Налаштування</h1>
                </div>
                <div className="user-notifications__text-tab--wrap">
                    <div className={`user-notifications__text-tab-wrap ${!isOpenMenu ? 'user-order__text-tab-wrap--is-open-menu' : 'user-order__text-tab-wrap--is-close-menu'}`}>
                        <button className={`user-notifications__text-tab ${tabValue === 0 ? 'user-order__text-tab-active' : ''}`} onClick={() => handleSetTabValue(0)}>Головний слайдер</button>
                        <button className={`user-notifications__text-tab ${tabValue === 1 ? 'user-order__text-tab-active' : ''}`} onClick={() => handleSetTabValue(1)}>SEO налаштування</button>
                        <button className={`user-notifications__text-tab ${tabValue === 2 ? 'user-order__text-tab-active' : ''}`} onClick={() => handleSetTabValue(2)}>Шапка сайту</button>
                        <button className={`user-notifications__text-tab ${tabValue === 3 ? 'user-order__text-tab-active' : ''}`} onClick={() => handleSetTabValue(3)}>Інші налаштування</button>
                    </div>
                    <Select 
                        value={tabValue}
                        className='product-view__variations-select user-notifications__select-wrap' 
                        onChange={(e) => handleSetTabValue(e.target.value)} 
                        open={isOpenSelectTabValue}
                        onClose={() => setIsOpenSelectTabValue(false)}
                        onOpen={() => setIsOpenSelectTabValue(true)}
                    >
                        <MenuItem className={`product-view__variations-select-item ${tabValue === 0 ? 'product-view__variations-select-item-active' : ''}`} value={0} disableRipple={true}>Головний слайдер</MenuItem>
                        <MenuItem className={`product-view__variations-select-item ${tabValue === 1 ? 'product-view__variations-select-item-active' : ''}`} value={1} disableRipple={true}>SEO налаштування</MenuItem>
                        <MenuItem className={`product-view__variations-select-item ${tabValue === 2 ? 'product-view__variations-select-item-active' : ''}`} value={2} disableRipple={true}>Шапка сайту</MenuItem>
                        <MenuItem className={`product-view__variations-select-item ${tabValue === 3 ? 'product-view__variations-select-item-active' : ''}`} value={3} disableRipple={true}>Інші налаштування</MenuItem>
                    </Select>
                </div>
                <button className="user-settings__header-btn" onClick={handleClickUpdate}>
                    <img className="user-settings__header-btn-img" src={saveImg} alt='img'/>
                    <span>Оновити</span>
                </button>
            </div> 

            {
                tabValue === 1 && <SeoSettings isClickSave={isClickSave} setIsClickSave={setIsClickSave}/>
            }

            {
                tabValue === 3 && 
                    <div className='user-settings__tab-3'>
                        {
                            isPreloader ? <PreloaderCustom /> :
                                <div className='user-settings__tab-3-btn-wrap'>
                                    <button className="main-btn-1 user-settings__tab-3-btn" onClick={() => setIsDownloadFeed(true)}>
                                        <span>Завантажити feed</span>
                                    </button>
                                    <a className="main-btn-1 user-settings__tab-3-btn" href={'/partner-registration'} target='_blank'>
                                        <span>Сторінка реєтрації партнера</span>
                                    </a>
                                    <a className="main-btn-1 user-settings__tab-3-btn" href={'/pricevyroby'} target='_blank'>
                                        <span>Сторінка тарифів</span>
                                    </a>
                                </div>
                        }
                    </div>
            }

            {
                tabValue === 2 && 
                    <div className="user-settings__create-header--wrap">
                        <div className="user-settings__create-header-wrap">
                            <FormControlLabel className="user-settings__create-header-form-control" control={<Switch value={isHeaderLink} checked={isHeaderLink} onChange={() => setIsHeaderLink(!isHeaderLink)} />} label="Додати текст або посилання над шапкою сайта" />
                            <div className='user-settings__create-header-input-wrap'>
                                <div className='user-settings__create-header-input-lable'>Текст</div>
                                <input className='creating-category__input' value={headerText} onChange={(e) => setHeaderText(e.target.value)} placeholder='Введіть текст' type='text' />
                                <div className='user-settings__create-header-input-sub-lable'>Рекомендовано до 30 символів</div>
                            </div>
                            <div className='user-settings__create-header-input-wrap'>
                                <div className='user-settings__create-header-input-lable'>Посилання (максимально 2 посилання)</div>
                                <input className='creating-category__input' value={headerLink} onChange={(e) => setHeaderLink(e.target.value)} placeholder='Введіть посилання' type='text' />
                            </div>
                            <div className="user-settings__create-header-btn-wrap">
                                <button className="user-settings__create-header-btn" onClick={handleAddHeaderLink}>
                                    <img className="user-settings__create-header-btn-img" src={plus} alt='img'/>
                                    <span>Додати</span>
                                </button>
                            </div>
                            <div className='user-settings__create-header-result-wrap'>
                                {
                                    !!headerLinks?.linksValue?.length && headerLinks.linksValue.map((el, i) => (
                                        <div className='user-settings__create-header-result' key={el + i}>
                                            <div className='user-settings__create-header-result-text-wrap'>
                                                <div><span className='user-settings__create-header-result-text-title'>Текст:</span> {el.text}</div>
                                                <div><span className='user-settings__create-header-result-text-title'>Посилання:</span> <a className='user-settings__create-header-result-text-link' href={el.links} target='_blank' >{el.links}</a></div>
                                            </div>
                                            <img className='user-settings__create-header-result-btn' onClick={() => handleDeleteLink(el.text)} src={deleteImg3} alt='img'/>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            {
                tabValue === 0 &&
                    <div className="user-settings__slider--wrap">
                        {
                            isPreloader ? <PreloaderCustom /> :
                                <div className="user-settings__slider-wrap">
                                    <div className='user-settings__slider-input-lable'>Додайте банер</div>
                                    <div className='user-settings__create-img--wrap'>
                                        <div
                                            className='user-settings__create-img-wrap'
                                            onDrop={(e) => handleImageUpload(e)}
                                            onDragOver={(e) => e.preventDefault()}
                                        >   
                                            <div className='user-settings__create-img-text-wrap'>
                                                <div className='user-settings__create-img-text-img-wrap'>
                                                    <img className='user-settings__create-img-text-img' src={uploadImg} alt='img'/>
                                                </div>
                                                <label className='user-settings__create-img-link-upload-lable' htmlFor='creationProductUserImages'>Клікніть завантажити</label>
                                                <span> або перетягніть зображення сюди</span>
                                                <p>Рекомендовано фото мають бути 156:55. Формати які підтримуються: PNG, JPG</p>
                                                <input className="user-settings__create-img-link-upload-input" onChange={(e) => handleUploadImg(e.target.files)} multiple type="file" accept="image/*" id="creationProductUserImages"/>
                                            </div>
                                        </div>

                                        <div className="user-settings__result-img--wrap">
                                            {
                                                !!bannersFile?.length && bannersFile.map((el, i) => (
                                                    <BannerResult obj={el} setBannersFile={setBannersFile} bannersFile={bannersFile} key={el.img + i}/>
                                                ))
                                            }
                                            {
                                                !!banners?.length && banners.map((el, i) => (
                                                    <BannerResult obj={el} setBanners={setBanners} banners={banners} key={el._id}/>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
            }
        </div>
    );
}

export default UserSettingsView;