import './SelectCharacteristic.css';
import { useState, useEffect, memo } from 'react';
import { MenuItem, OutlinedInput } from '@mui/material';
import deleteImg2 from '../../assets/deleteImg2.svg';
import plusImg from '../../assets/plusImg.svg';
import deleteImg4 from '../../assets/deleteImg4.svg';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import { handleSortValue } from '../../helpers/Utils';

function SelectCharacteristic({ oldCharacteristic=null, characteristic, handleChangeCharacteristics}) {
    const isOpenMenu = useSelector(state => state.userSlice.isOpenMenu);
    const [selectedValue, setSelectedValue] = useState('');
    const [newValue, setNewValue] = useState('');
    const [newValueArr, setNewValueArr] = useState([]);
    const [newSelectValue, setNewSelectValue] = useState('');
    const [isNewFewValue, setIsNewFewValue] = useState(false);
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        if (oldCharacteristic) {
            if (characteristic.is_few_values_allowed) {
                let res = oldCharacteristic.value.split(', ')
                setSelectedValue('')
                setNewValueArr([...res])
            } else {
                setSelectedValue(oldCharacteristic.value)
            }
        }
    }, [])
   
    useEffect(() => {
        if (oldCharacteristic) {
            if (characteristic.is_few_values_allowed) {
                let res = oldCharacteristic.value.split(', ')
                setSelectedValue('')
                setNewValueArr([...res])
            } else {
                setSelectedValue(oldCharacteristic.value)
            }
        } else {
            setSelectedValue('')
            setNewValueArr([])
        }
    }, [oldCharacteristic])

    const handleNewValueKeyDown = (e) => {
        if(e.key == 'Enter') {
            handleAddNewFew()
        }
    }

    const handleAddNewFew = () => {
        if (newValue?.length) {
            setNewValueArr([...newValueArr, newValue])
            handleChangeCharacteristics(characteristic.name, newValue?.length ? [...newValueArr, newValue].join(', ') : newValue)
            setNewValue('')
            setIsNewFewValue(false)
        }
    }

    const handleSelectedValue = (str) => {
        let res = JSON.parse(str)
        if (res?.length) {
            handleChangeCharacteristics(characteristic.name, res)
            setSelectedValue(res)
        } else {
            setSelectedValue('')
            handleChangeCharacteristics(characteristic.name, null)
        }
    }
    
    const handleSelectedValueNewValue = (res) => {
        if (res?.length) {
            if (res === 'ownerSelect') {
    
                setSelectedValue('ownerSelect')
            } else {
                handleChangeCharacteristics(characteristic.name, res)
                setSelectedValue(res)
            }
        } else {
            setSelectedValue('')
        }
    }

    const handleKeyDown = (e) => {
        if(e.key == 'Enter') {
            handleAddNewValue()
        }
    }

    const handleAddNewValue = () => {
        if (newValue?.length) {
            setSelectedValue(newValue)
            handleChangeCharacteristics(characteristic.name, newValue)
            setNewValue('')
        } 
    }
    
    const handleDeleteCharacteristics = () => {
        handleChangeCharacteristics(characteristic.name, null)
        setSelectedValue('')
        setNewSelectValue('')
        setNewValueArr([])
    }

    const handleSelectedOwnerValue = () => {
        setSelectedValue('ownerSelect')
        setNewSelectValue('')
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setNewValueArr(
        typeof value === 'string' ? value.split(',') : value,
        );
        handleChangeCharacteristics(characteristic.name, value.join(', '))
        
        if(value.length) {
            setIsSelected(true);
        } else {
            setIsSelected(false);
        }
    };

    return (
        <div className='select-characteristic'>
            <div className='select-characteristic__input-characteristics-lable'>
                <span className='select-characteristic__input-characteristics-lable-text'><span className={`${characteristic.is_required_input ? 'requiredFields' : ''}`}>{characteristic.name}</span></span>
                <div className='select-characteristic__input-characteristics-lable-btn-wrap'>
                    {
                        (characteristic.is_new_value_blocked && !characteristic.is_few_values_allowed)  && 
                            <>
                                {
                                    selectedValue === 'ownerSelect' && !newSelectValue?.length ?
                                        <></>
                                        :
                                        <>
                                            <img className={`select-characteristic__add-btn-owner-select`} onClick={handleSelectedOwnerValue} src={plusImg} alt='img'/>
                                        </>
                                }
                            </>
                    }
                    {
                            (characteristic.is_new_value_blocked && characteristic.is_few_values_allowed)  && 
                                <>
                                    {
                                        isNewFewValue ?
                                            <></>
                                            :
                                            <>
                                                <img className={`select-characteristic__add-btn-owner-select`} onClick={() => setIsNewFewValue(true)} src={plusImg} alt='img'/>
                                            </>
                                    }
                                </>
                        }
                    {
                        (selectedValue === ' ' || selectedValue === '') && !newValueArr?.length ?
                        <img className='select-characteristic__characteristics-delete-btn' src={deleteImg2} alt='img'/>
                        :
                        <img className='select-characteristic__characteristics-delete-btn' onClick={handleDeleteCharacteristics} src={deleteImg4} alt='img'/>
                    }
                </div>
            </div>
            <div className='select-characteristic__accordion-wrap'>
                <div className='select-characteristic__accordion-select--wrap'>
                    <div className='select-characteristic__accordion-select-wrap'>
                        { // зі своїми значень
                            characteristic.is_new_value_blocked && 
                                <>
                                    { // з декількома значенями
                                        characteristic.is_few_values_allowed ?
                                            <div className='select-characteristic__select-mark-placeholder-wrap'>
                                                {
                                                    !isSelected && !newValueArr?.length && <div className='select-characteristic__select-mark-placeholder'>Виберіть значення</div>
                                                }
                                                <Select
                                                    multiple
                                                    value={newValueArr}
                                                    onChange={handleChange}
                                                    input={<OutlinedInput label="" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    className='select-characteristic__select-mark--few_values'
                                                >
                                                    {
                                                        !!newValueArr?.length && !!characteristic?.value?.length && newValueArr.filter(item => !characteristic.value.includes(item))?.length &&
                                                            newValueArr.filter(item => !characteristic.value.includes(item)).map((name, index) => (
                                                                <MenuItem 
                                                                    className={`select-characteristic__select-mark-item ${newValueArr === name ? 'creating-product__select-mark-item-active' : ''}`}
                                                                    value={name} 
                                                                    disableRipple={true}
                                                                    key={name + index}
                                                                >
                                                                    <label className={`catalog-view__products-filter-label`}>
                                                                        <div className={`catalog-view__products-filter-checkbox-custom ${newValueArr.includes(name) ? 'catalog-view__products-filter-checkbox-custom-active' : ''}`}></div>
                                                                        <span className="catalog-view__products-filter-text">{name}</span>
                                                                    </label>
                                                                </MenuItem>
                                                            ))
                                                    }
                                                    
                                                    {!!characteristic?.value?.length && handleSortValue(characteristic.value).map((name, index) => (
                                                        <MenuItem 
                                                            className={`select-characteristic__select-mark-item ${newValueArr === name ? 'creating-product__select-mark-item-active' : ''}`} 
                                                            value={name} 
                                                            disableRipple={true}
                                                            key={name + index}
                                                        >
                                                            <label className={`catalog-view__products-filter-label`}>
                                                                <div className={`catalog-view__products-filter-checkbox-custom ${newValueArr.includes(name) ? 'catalog-view__products-filter-checkbox-custom-active' : ''}`}></div>
                                                                <span className="catalog-view__products-filter-text">{name}</span>
                                                            </label>
                                                        </MenuItem>
                                                    ))}
                                                </Select> 
                                            </div>
                                            :
                                            <Select
                                                value={selectedValue?.length ? selectedValue : ' '}
                                                onChange={(e) => handleSelectedValueNewValue(e.target.value)}
                                                className='select-characteristic__select-mark--wrap'
                                            >
                                                <MenuItem className={`select-characteristic__select-mark-item ${selectedValue === ' ' ? 'creating-product__select-mark-item-active' : ''}`} value={' '} disableRipple={true}>Виберіть значення</MenuItem>
                                                <MenuItem className={`select-characteristic__select-mark-item ${selectedValue === 'ownerSelect' ? 'creating-product__select-mark-item-active' : ''}`} value={'ownerSelect'} disableRipple={true}>Свій варіант</MenuItem>
                                                {
                                                    (!!selectedValue?.length && selectedValue !== 'ownerSelect' && selectedValue !== ' ') && (!characteristic?.value?.length || (!!characteristic?.value?.length && !characteristic?.value.includes(selectedValue))) &&
                                                        <MenuItem className={`select-characteristic__select-mark-item ${selectedValue === selectedValue ? 'creating-product__select-mark-item-active' : ''}`} value={selectedValue} disableRipple={true}>{selectedValue}</MenuItem>
                                                }
                                                {
                                                    !!characteristic?.value?.length && handleSortValue(characteristic.value).map((ell, index) => (
                                                            <MenuItem className={`select-characteristic__result ${selectedValue === ell ? 'creating-product__select-mark-item-active' : ''}`} value={ell} key={ell + index} disableRipple={true}>{ell}</MenuItem>
                                                        ))
                                                }
                                            </Select>  
                                    }
                                </>
                        }

                        {   // без своїх значень
                            !characteristic.is_new_value_blocked &&
                                <>
                                    { // з декількома значенями
                                        characteristic.is_few_values_allowed ?
                                            <div className='select-characteristic__select-mark-placeholder-wrap'>
                                                {
                                                    !isSelected && <div className='select-characteristic__select-mark-placeholder'>Виберіть значення</div>
                                                }
                                                <Select
                                                    multiple
                                                    value={newValueArr}
                                                    onChange={handleChange}
                                                    input={<OutlinedInput label="" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    className='select-characteristic__select-mark--few_values'
                                                >
                                                    {!!characteristic?.value?.length && handleSortValue(characteristic.value).map((name, index) => (
                                                        <MenuItem 
                                                            className={`select-characteristic__select-mark-item ${newValueArr === name ? 'creating-product__select-mark-item-active' : ''}`}
                                                            value={name} 
                                                            disableRipple={true}
                                                            key={name + index}
                                                        >
                                                            <label className={`catalog-view__products-filter-label`}>
                                                                <div className={`catalog-view__products-filter-checkbox-custom ${newValueArr.includes(name) ? 'catalog-view__products-filter-checkbox-custom-active' : ''}`}></div>
                                                                <span className="catalog-view__products-filter-text">{name}</span>
                                                            </label>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                            :
                                            <Select
                                                value={JSON.stringify(selectedValue)}
                                                onChange={(e) => handleSelectedValue(e.target.value)}
                                                className='select-characteristic__select-mark--wrap'
                                            >
                                                <MenuItem className={`select-characteristic__select-mark-item ${selectedValue === JSON.stringify('') ? 'creating-product__select-mark-item-active' : ''}`} value={JSON.stringify('')} disableRipple={true}>Виберіть значення</MenuItem>
                                                {
                                                    !!characteristic?.value?.length && handleSortValue(characteristic.value).map((ell, index) => (
                                                            <MenuItem className={`select-characteristic__result ${selectedValue === JSON.stringify(ell) ? 'creating-product__select-mark-item-active' : ''}`} value={JSON.stringify(ell)} key={ell + index} disableRipple={true}>{ell}</MenuItem>
                                                        ))
                                                }
                                            </Select>   
                                    }
                                </>      
                        }

                        {
                            (characteristic.is_new_value_blocked && !characteristic.is_few_values_allowed)  && 
                                <>
                                    {
                                        selectedValue === 'ownerSelect' && !newSelectValue?.length ?
                                            <div className={`select-characteristic__add-btn--wrap ${!isOpenMenu ? 'select-characteristic__add-btn--wrap--is-open-menu' : ''}`}>
                                                <input className={`creating-category__input select-characteristic__input`} value={newValue} onChange={(e) => setNewValue(e.target.value)} onKeyDown={(e) => handleKeyDown(e)} placeholder='Нове значення' type='text'/>
                                                <img className='creating-category__add-btn' onClick={handleAddNewValue} src={plusImg} alt='img'/>
                                            </div>
                                            :
                                            <>
                                                <img className='select-characteristic__add-btn-owner-select select-characteristic__add-btn-owner-select--desk' onClick={handleSelectedOwnerValue} src={plusImg} alt='img'/>
                                            </>
                                    }
                                </>
                        }
                        {
                            (characteristic.is_new_value_blocked && characteristic.is_few_values_allowed)  && 
                                <>
                                    {
                                        isNewFewValue ?
                                            <div className={`select-characteristic__add-btn--wrap ${!isOpenMenu ? 'select-characteristic__add-btn--wrap--is-open-menu' : ''}`}>
                                                <input className='creating-category__input select-characteristic__input' value={newValue} onChange={(e) => setNewValue(e.target.value)} onKeyDown={(e) => handleNewValueKeyDown(e)} placeholder='Нове значення' type='text'/>
                                                <img className='creating-category__add-btn' onClick={handleAddNewFew} src={plusImg} alt='img'/>
                                            </div>
                                            :
                                            <>
                                                <img className='select-characteristic__add-btn-owner-select select-characteristic__add-btn-owner-select--desk' onClick={() => setIsNewFewValue(true)} src={plusImg} alt='img'/>
                                            </>
                                    }
                                </>
                        }
                    </div>      
                </div>      

                {
                    (selectedValue === ' ' || selectedValue === '') && !newValueArr?.length ?
                    <img className='select-characteristic__characteristics-delete-btn select-characteristic__characteristics-delete-btn--desk' src={deleteImg2} alt='img'/>
                    :
                    <img className='select-characteristic__characteristics-delete-btn select-characteristic__characteristics-delete-btn--desk' onClick={handleDeleteCharacteristics} src={deleteImg4} alt='img'/>
                }
            </div>
        </div>
    );
}

export default memo(SelectCharacteristic);