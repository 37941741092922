import './SkeletonWrap.css';
import { memo } from 'react';
import { Skeleton } from '@mui/material';

function SkeletonWrap({pageSearchParams=null}) {
    const data = Array.from({ length: pageSearchParams ? (pageSearchParams + 1)*40 : 40 }, (_, index) => index + 1);

    return (
        <div className='skeleton-wrap__wrap'>
            {
                !!data?.length && data.map(el => (
                    <div className='skeleton-wrap' key={el}>
                        <Skeleton className='skeleton-wrap__img' variant="rectangular"  />
                        <Skeleton className='skeleton-wrap__variations'/>
                        <Skeleton className='skeleton-wrap__text skeleton-wrap__text-1' />
                        <Skeleton className='skeleton-wrap__text skeleton-wrap__text-3' />
                        <Skeleton className='skeleton-wrap__text skeleton-wrap__text-2' />
                        <Skeleton className='skeleton-wrap__text skeleton-wrap__text-3' />
                    </div>
                ))
            }
        </div>
    );
}

export default memo(SkeletonWrap);