// import { Suspense } from 'react';
import { Route, Routes } from 'react-router';
import './App.css';
// import Preloader from './components/Preloader/Preloader';
import ShowMessage from './components/ShowMessage/ShowMessage';
import Layout from './Layout/Layout';
import LayoutUser from './Layout/LayoutUser';
import AboutView from './views/AboutView/AboutView';
import CatalogView from './views/CatalogView/CatalogView';
import CollectionProducts from './views/CollectionProducts/CollectionProducts';
import CollectionsView from './views/CollectionsView/CollectionsView';
import ContactsView from './views/ContactsView/ContactsView';
import DeliveryView from './views/DeliveryView/DeliveryView';
import HomeView from './views/HomeView/HomeView';
import HowBuyView from './views/HowBuyView/HowBuyView';
import NotFoundView from './views/NotFoundView/NotFoundView';
import OfferView from './views/OfferView/OfferView';
import OrderReadView from './views/OrderReadView/OrderReadView';
import OrderView from './views/OrderView/OrderView';
import PartnerRegistrationView from './views/PartnerRegistrationView/PartnerRegistrationView';
import PartnersView from './views/PartnersView/PartnersView';
import PriceVyrobyView from './views/PriceVyrobyView/PriceVyrobyView';
import ProductView from './views/ProductView/ProductView';
import SignInView from './views/SignInView/SignInView';
import UserBlocksView from './views/UserBlocksView/UserBlocksView';
import UserCategoriesView from './views/UserCategoriesView/UserCategoriesView';
import UserCollectionView from './views/UserCollectionView/UserCollectionView';
import UserOrderView from './views/UserOrderView/UserOrderView';
import UserNotificationsView from './views/UserNotificationsView/UserNotificationsView';
import UserProductsView from './views/UserProductsView/UserProductsView';
import UserSettingsView from './views/UserSettingsView/UserSettingsView';
import UserVendorsView from './views/UserVendorsView/UserVendorsView';
import VendorView from './views/VendorView/VendorView';
import NotificationsReadView from './views/NotificationsReadView/NotificationsReadView';
import CheckoutView from './views/CheckoutView/CheckoutView';
import FavoriteView from './views/FavoriteView/FavoriteView';
import CreatingProduct from './components/CreatingProduct/CreatingProduct';
import CreatingCategory from './components/CreatingCategory/CreatingCategory';
import CreatingVendorView from './views/CreatingVendorView/CreatingVendorView';
import CreatingBlockView from './views/CreatingBlockView/CreatingBlockView';
import CreatingCollectionView from './views/CreatingCollectionView/CreatingCollectionView';
import UserRequestsView from './views/UserRequestsView/UserRequestsView';
import SuccessView from './views/SuccessView/SuccessView';
import { Navigate } from 'react-router-dom';

/* форми на таких сторінках: about, contacts, partners, order, partner-registration, ProductView */

function App() {
	return (
		<div className='app'>
			{/* <Suspense fallback={<Preloader />}> */}
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route index element={<HomeView />} />
					<Route path='about' element={<AboutView />} />
					<Route path='contacts' element={<ContactsView />} />
					<Route path='how-to-buy' element={<HowBuyView />} />
					<Route path='delivery' element={<DeliveryView />} />
					<Route path='partners' element={<PartnersView />} />
					<Route path='pid-zamovlennya' element={<OrderView />} />
					<Route path='favorite' element={<FavoriteView />} />
					{/* відвідати сторінку login можне через url */}
					<Route path='login' element={<SignInView />} />
					{/* відвідати сторінку pricevyroby можне через url */}
					<Route path='pricevyroby' element={<PriceVyrobyView />} />
					{/* відвідати сторінку partner-registration можне через url */}
					<Route
						path='partner-registration'
						element={<PartnerRegistrationView />}
					/>
					<Route path='catalog' element={<CatalogView />} key='catalog' />
					<Route
						path='catalog/trend/:trendId'
						element={<CatalogView />}
						key='catalogTrend'
					/>
					<Route
						path='catalog/:categotyId'
						element={<CatalogView />}
						key='category'
					/>
					<Route
						path='catalog/:categotyId/:subCategotyId'
						element={<CatalogView />}
						key='subCategory'
					/>
					<Route path='catalog/collections' element={<CollectionsView />} />
					<Route
						path='catalog/collections/:collectionId'
						element={<CollectionProducts />}
					/>
					<Route path='product/:productId' element={<ProductView />} />
					<Route path='vendor/:vendorId' element={<VendorView />} />
					<Route path='success' element={<SuccessView />} />
					<Route path='*' element={<Navigate to='/not-found' />} />
					<Route path='/not-found' element={<NotFoundView />} />
				</Route>
				<Route path='checkout' element={<CheckoutView />} />
				<Route path='/offer' element={<OfferView />} />

				<Route path='/auth' element={<LayoutUser />}>
					<Route index element={<UserCategoriesView />} />
					<Route path='categories/' element={<UserCategoriesView />} />
					<Route
						path='categories/:userCategoryId'
						element={<CreatingCategory />}
					/>
					<Route path='categories/create' element={<CreatingCategory />} />
					<Route path='products' element={<UserProductsView />} />
					<Route path='products/create' element={<CreatingProduct />} />
					<Route path='products/:userProductId' element={<CreatingProduct />} />
					<Route path='blocks' element={<UserBlocksView />} />
					<Route path='blocks/create' element={<CreatingBlockView />} />
					<Route path='blocks/:userBlockId' element={<CreatingBlockView />} />
					<Route path='collection' element={<UserCollectionView />} />
					<Route
						path='collection/create'
						element={<CreatingCollectionView />}
					/>
					<Route
						path='collection/:userCollectionId'
						element={<CreatingCollectionView />}
					/>
					<Route path='vendors' element={<UserVendorsView />} />
					<Route path='vendors/create' element={<CreatingVendorView />} />
					<Route
						path='vendors/create/:userVendorId'
						element={<CreatingVendorView />}
					/>
					<Route path='order' element={<UserOrderView />} />
					<Route path='requests' element={<UserRequestsView />} />
					<Route
						path='requests/:orderId'
						element={<OrderReadView isRequestsView={true} />}
					/>
					<Route path='order/:orderId' element={<OrderReadView />} />
					<Route path='notifications' element={<UserNotificationsView />} />
					<Route
						path='notifications/:notificationId'
						element={<NotificationsReadView />}
					/>
					<Route path='settings' element={<UserSettingsView />} />
				</Route>
			</Routes>

			<div className='app__show-message-wrap'>
				<ShowMessage />
			</div>
			{/* </Suspense> */}
		</div>
	);
}

export default App;
