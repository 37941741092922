import './VendorView.css';
import { useEffect, useMemo, useState } from 'react';
import arrow2 from '../../assets/arrow2.svg';
import close2 from '../../assets/close2.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { disabledScroll, enabledScroll, fetchGetData, fetchRequest, validEmail } from '../../helpers/Utils';
import NotFoundView from '../NotFoundView/NotFoundView';
import ProductCard from '../../components/ProductCard/ProductCard';
import { useDispatch } from 'react-redux';
import { setShowMessageObj } from '../../store/userSlice';
import InputPhone from '../../components/InputPhone/InputPhone';
import { useSelector } from 'react-redux';
import PreloaderProductCustom2 from '../../components/PreloaderProductCustom2/PreloaderProductCustom2';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function VendorView() {
    const utm_source = useSelector(state => state.homeSlice.utm_source);
    const utm_medium = useSelector(state => state.homeSlice.utm_medium);
    const utm_campaign = useSelector(state => state.homeSlice.utm_campaign);
    const [vendor, setVendor] = useState({});
    const [vendorProducts, setVendorProducts] = useState([]);
    const [tab, setTab] = useState(1);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [description, setDescription] = useState('');
    const [productsCount, setProductsCount] = useState(0);
    const [page, setPage] = useState(0);
    const [isOpenProductInfo, setIsOpenProductInfo] = useState(false);
    const [openProductInfo, setOpenProductInfo] = useState(null);
    const [isNotFirstRender, setIsNotFirstRender] = useState(false);
    const [modalScrollPosition, setModalScrollPosition] = useState(0);
    const [isErrorName, setIsErrorName] = useState(false);
    const [isErrorPhone, setIsErrorPhone] = useState(false);
    const [isErrorEmail, setIsErrorEmail] = useState(false);
    const [isErrorDescription, setIsErrorDescription] = useState(false);
    const [isSendForm, setIsSendForm] = useState(false);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isXiaomiRedmi = /xiaomi/i.test(navigator.userAgent);
    const [isPreloader, setIsPreloader] = useState(true);
    const { vendorId } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/vendors/${vendorId}?is_name=${'1'}`)
            .then(res => {
                if (res.success && res.data) {
                    setVendor(res.data)
                } else {
                    navigate('/not-found');
                    console.log('GET VendorView', res)
                }
                setIsPreloader(false)
                window.scroll({top: 0})
            })
        
        setIsNotFirstRender(true)
    }, [])
    
    useEffect(() => {
        if (vendor?._id?.length && isNotFirstRender) {
            handleGetProducts()
        }
    }, [vendor])

    useEffect(() => {
        if (name?.length) {
            setIsErrorName(false)
        }
    }, [name])
    
    useEffect(() => {
        if (phone?.length && phone.length >= 12) {
            setIsErrorPhone(false)
        }
    }, [phone])
    
    useEffect(() => {
        if (email?.length) {
            setIsErrorEmail(false)
        }
    }, [email])
    
    useEffect(() => {
        if (description?.length) {
            setIsErrorDescription(false)
        }
    }, [description])
    
    const handleNexPages = () => {
        setPage(page + 1)
        handleGetProducts(true)
    }

    const handleGetProducts = (isNextPage=false) => {
        let data = {
            page: isNextPage ? page + 1 : page,
            vendor_id: vendor._id,
            type: [],
            perPages: 24,
            count_product_variations: false,
            catalog: true,
        } 
        
        fetchRequest('POST', `${process.env.REACT_APP_BASE_URL}/products/admin/all`, data)
            .then(res => {
                if (res.success && res.data) {
                    if (isNextPage) {
                        setVendorProducts([...vendorProducts, ...res.data])
                    } else {
                        setVendorProducts(res.data)
                    }
                    setProductsCount(res.count)
                } else {
                    console.log('POST VendorView:', res)
                }
            })
    }

    const handlePageCount = useMemo(() => {
        return (num) => {
            if (num !== 0) {
                return num % 24 === 0 ? num / 24 : Math.ceil(num / 24);
            }
        };
    }, [productsCount]);

    const handleOpenProductInfo = (num) => {
        setIsOpenProductInfo(true)
        setOpenProductInfo(num)
        disabledScroll(setModalScrollPosition)
    }
    
    const handleCloseProductInfo = () => {
        setIsOpenProductInfo(false)
        setOpenProductInfo(null)
        enabledScroll(modalScrollPosition)
    }

    const hendleClean = () => {
        setName('')
        setPhone('')
        setEmail('')
        setDescription('')
    }  

    const hendleSubmitFormGtm = () => {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({'event': 'submit_form_manufacturer'});
    }

    const hendleSend = (strId) => {
        let sendBtn = document.getElementById(strId);
        sendBtn.style.cssText = `
            pointer-events: none;
        `;
        if (name?.length && email?.length && phone?.length && phone.length >= 12 && description?.length && validEmail(email)) {
            let data = {
                full_name: name,
                phone: phone,
                email: email,
                message: description,
                nameofpage: location.pathname,
                vendor_id: vendor._id,
                request_type: 'vendor',
                utm_source: utm_source?.length ? utm_source : null,
                utm_medium: utm_medium?.length ? utm_medium : null,
                utm_campaign: utm_campaign?.length ? utm_campaign : null,
            }
            
            fetchRequest('POST', `${process.env.REACT_APP_BASE_URL}/forms/question_order`, data, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
                .then(res => {
                    if (res.success && res.data) {
                        hendleSubmitFormGtm()
                        setIsSendForm(true)
                        hendleClean()
                    } else {
                        console.log('POST VendorView:', res)
                    }
                })
        } else {
            sendBtn.style.cssText = ``;
            setIsErrorName(!name?.length ? true : false)
            setIsErrorPhone(!phone?.length || phone.length < 12 ? true : false)
            setIsErrorEmail(!email.length || !validEmail(email) ? true : false)
            setIsErrorDescription(!description?.length ? true : false)
        }
    }

    const handleNextVariation = (obj, oldProductId) => {
        if (vendorProducts?.length) {
            let res = vendorProducts.map(ell => {
                if (ell._id === oldProductId) {
                    ell = obj
                    return ell
                 }
                 return ell
            })

            setVendorProducts(res)
        }
    }
   
    return (
        <div className="vendor-view">
            <SeoBlock title={vendor.seo_default_title?.length ? vendor.seo_default_title : "Виробник"} description={vendor.seo_default_description} keywords={vendor.seo_default_keywords}/>
            {
                isOpenProductInfo &&
                    <div
                        className="vendor-view__modal-wrap"
                    >
                        <div>
                            <div className="vendor-view__modal-form--wrap" onClick={handleCloseProductInfo}></div>
                            <div className={`vendor-view__text-modal-tab-wrap ${isSafari || isXiaomiRedmi ? 'product-view__text-modal-tab-is-safari-wrap' : ''}`}>
                                <div className="vendor-view__text-modal-tab-title-wrap">
                                    {
                                        openProductInfo === 1 && <>Про компанію</>
                                    }
                                    {
                                        openProductInfo === 2 && <>Доставка та оплата</>
                                    }
                                    {
                                        openProductInfo === 3 && <>Контакти виробника</>
                                    }
                                    <button className='vendor-view__text-modal-tab-btn-close-wrap'>
                                        <img className="vendor-view__text-modal-tab-btn-close" onClick={handleCloseProductInfo} src={close2} alt='img'/>
                                    </button>
                                </div>
                                {
                                    openProductInfo === 1 && 
                                        <pre className={`vendor-view__text-tab-1 ${isSafari || isXiaomiRedmi ? 'vendor-view__text-tab-is-safari' : '' }`}>{vendor.description}</pre>
                                }
                                {
                                    openProductInfo === 3 && 
                                        <div className={`vendor-view__text-tab-3 ${isSafari || isXiaomiRedmi ? 'vendor-view__text-tab-is-safari' : '' }`}>
                                            <div className="vendor-view__text-tab-3-info">
                                                <div className="vendor-view__text-tab-3-info-title">Контактна адреса:</div>
                                                <div className="vendor-view__text-tab-3-info-text">{vendor.address}</div>
                                                <div className="vendor-view__text-tab-3-info-title">Графік роботи</div>
                                                <div className="vendor-view__text-tab-3-info-text" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: vendor.work_schedule }}></div>
                                                <div className="vendor-view__text-tab-3-info-text">Якщо у вас є питання і побажання - пишіть нам. Наші менеджери оперативно вам допоможуть.</div>

                                            </div>

                                            <div className="vendor-view__text-tab-3-input-wrap">
                                                {
                                                    isSendForm ? 
                                                        <div className="contacts-view__form-success">Дякуємо за ваше звернення. Найближчим часом ми з вами зв'яжемося</div>
                                                        :
                                                        <>
                                                            <div className='contacts-view__form-error-wrap'>
                                                                <input className={`vendor-view__text-tab-3-input ${isErrorName ? 'contacts-view__form-error' : ''}`} onChange={(e) => setName(e.target.value)} value={name} autoComplete="name" type='text' placeholder="Вкажіть ваше ім'я"/>
                                                                {
                                                                    isErrorName && <div className='contacts-view__form-error-text'>Обов'язкове поле</div>
                                                                }
                                                            </div>
                                                            <div className='contacts-view__form-error-wrap'>
                                                                <input className={`vendor-view__text-tab-3-input ${isErrorEmail ? 'contacts-view__form-error' : ''}`} onChange={(e) => setEmail(e.target.value)} value={email} autoComplete="name" type='text' placeholder='Вкажіть ваш email'/>
                                                                {
                                                                    isErrorEmail && <div className='contacts-view__form-error-text'>{!email.length ? 'Обов\'язкове поле' : 'Вкажіть, будь ласка, коректний email'}</div>
                                                                }
                                                            </div>
                                                            <div className='contacts-view__form-error-wrap'>
                                                                <InputPhone phone={phone} setPhone={setPhone} isVendorView={true} isErrorPhone={isErrorPhone}/>
                                                                {
                                                                    isErrorPhone && <div className='contacts-view__form-error-text'>Обов'язкове поле</div>
                                                                }
                                                            </div>
                                                            <div className='contacts-view__form-error-wrap'>
                                                                <textarea className={`vendor-view__text-tab-3-textarea ${isErrorDescription ? 'contacts-view__form-error' : ''}`} onChange={(e) => setDescription(e.target.value)} value={description} placeholder="Опишіть ваше питання або побажання" rows="4"></textarea>
                                                                {
                                                                    isErrorDescription && <div className='contacts-view__form-error-text'>Обов'язкове поле</div>
                                                                }
                                                            </div>
                                                            {
                                                                (isErrorName || isErrorDescription || isErrorEmail || isErrorPhone) &&
                                                                    <div className='contacts-view__form-error-banner'>
                                                                        {
                                                                            (isErrorName || isErrorDescription || isErrorPhone) && <div className='contacts-view__form-error-banner-text'>Будь ласка, заповніть всі обов'язкові поля</div>
                                                                        }
                                                                        {
                                                                            isErrorEmail && ((!isErrorName && !isErrorDescription && !isErrorPhone) || !!email.length) && <div className='contacts-view__form-error-banner-text'>{!email.length ? 'Будь ласка, заповніть всі обов\'язкові поля' : 'Вкажіть, будь ласка, коректний email'}</div>
                                                                        }
                                                                    </div>
                                                            }
                                                            <button id='vendorViewSentBtn1' className='main-btn-1 vendor-view__text-tab-3-btn' disabled={isSendForm} onClick={() => hendleSend('vendorViewSentBtn1')}>Написати виробнику</button> 
                                                        </>
                                                }
                                            </div>
                                        </div>
                                }
                                {
                                    openProductInfo === 2 && 
                                        <div className={`vendor-view__text-tab-2 ${isSafari || isXiaomiRedmi ? 'vendor-view__text-tab-is-safari' : '' }`}>
                                            <div className="vendor-view__text-tab-2-item">
                                                <svg className="vendor-view__text-tab-2-item-img" xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92" fill="none"> <circle cx="46" cy="46" r="46" fill="#2A7F77"></circle> <path d="M63.6204 45.7854H60.5277V40.5662C60.5277 40.2358 60.2599 39.9684 59.9299 39.9684H50.2437V34.76C50.2437 34.4296 49.9759 34.1621 49.6459 34.1621H28.2392C27.9088 34.1621 27.6414 34.4296 27.6414 34.76V54.3646C27.6414 54.6946 27.9088 54.9625 28.2392 54.9625H33.2368C33.444 56.5038 34.7641 57.6972 36.3609 57.6972C37.9576 57.6972 39.2778 56.5038 39.485 54.9625H49.6459H53.5066C53.7139 56.5038 55.0348 57.6972 56.6331 57.6972C58.2315 57.6972 59.5524 56.5038 59.7601 54.9625H63.6208C63.9508 54.9625 64.2187 54.6946 64.2187 54.3646V46.3832C64.2183 46.0528 63.9504 45.7854 63.6204 45.7854ZM36.3609 56.5014C35.2803 56.5014 34.401 55.6217 34.401 54.5404C34.401 53.459 35.2803 52.5793 36.3609 52.5793C37.4415 52.5793 38.3208 53.459 38.3208 54.5404C38.3208 55.6217 37.4419 56.5014 36.3609 56.5014ZM36.3609 51.3835C34.8881 51.3835 33.6517 52.3996 33.3053 53.7667H28.8371V35.3579H49.048V40.5662V46.3832V53.7663H39.4169C39.0705 52.3996 37.8337 51.3835 36.3609 51.3835ZM56.6331 56.5014C55.551 56.5014 54.6709 55.6217 54.6709 54.5404C54.6709 53.459 55.551 52.5793 56.6331 52.5793C57.7153 52.5793 58.5958 53.459 58.5958 54.5404C58.5958 55.6217 57.7149 56.5014 56.6331 56.5014ZM63.0225 53.7663H59.6915C59.3447 52.3992 58.1071 51.3831 56.6331 51.3831C55.1591 51.3831 53.9215 52.3992 53.5752 53.7663H50.2441V46.3832V41.1641H59.3324V46.3832C59.3324 46.7133 59.6002 46.9811 59.9303 46.9811H63.0229V53.7663H63.0225Z" fill="white"></path> </svg>
                                                <div className="vendor-view__text-tab-2-item-text-wrap">
                                                    <div className="vendor-view__text-tab-2-item-text-title">Варіанти доставки</div>
                                                        {
                                                            !!vendor.conditions_delivery?.length &&
                                                                <div className="vendor-view__text-tab-1-conditions_delivery">{vendor.conditions_delivery}</div>
                                                        }
                                                        {
                                                            !!vendor.delivery_options?.length && <ul className="vendor-view__text-tab-2-item-text">
                                                                {
                                                                    vendor.delivery_options.map(el => (
                                                                        <li className="vendor-view__text-tab-2-item-text-li" key={el}>{el}</li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        }
                                                </div>
                                            </div>
                                            <div className="vendor-view__text-tab-2-item">
                                                <svg className="vendor-view__text-tab-2-item-img" xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92" fill="none"> <circle cx="46" cy="46" r="46" fill="#2A7F77"></circle> <path d="M42.6528 55.1896C45.712 55.1896 48.2012 52.7004 48.2012 49.6412C48.2012 46.582 45.712 44.0928 42.6528 44.0928C39.5936 44.0928 37.1044 46.582 37.1044 49.6412C37.1044 52.7004 39.5936 55.1896 42.6528 55.1896ZM42.6528 45.2886C45.0527 45.2886 47.0054 47.2409 47.0054 49.6412C47.0054 52.0415 45.0531 53.9938 42.6528 53.9938C40.2525 53.9938 38.3001 52.0415 38.3001 49.6412C38.3001 47.2409 40.2525 45.2886 42.6528 45.2886Z" fill="white"></path> <path d="M31.5743 44.6904H30.3785V54.5915H31.5743V44.6904Z" fill="white"></path> <path d="M54.7394 44.6904H53.5436V54.5915H54.7394V44.6904Z" fill="white"></path> <path d="M64.5371 33.6572H34.1448V37.2039H30.876V41.0806H27.3225V58.2018H57.7148V54.3251H61.2683V50.7784H64.5367V33.6572H64.5371ZM56.519 57.006H28.5183V42.2764H56.519V57.006ZM60.0725 53.1297H57.7148V41.0806H32.0717V38.3997H60.0729L60.0725 53.1297ZM63.3414 49.5826H61.2687V37.2039H35.3406V34.853H63.3414V49.5826Z" fill="white"></path> </svg> 
                                                <div className="vendor-view__text-tab-2-item-text-wrap">
                                                    <div className="vendor-view__text-tab-2-item-text-title">Варіанти оплати</div>
                                                        {
                                                            !!vendor.conditions_payment?.length &&
                                                                <div className="vendor-view__text-tab-1-conditions_delivery">{vendor.conditions_payment}</div>
                                                        }
                                                        {
                                                            !!vendor.payment_options?.length && <ul className="vendor-view__text-tab-2-item-text">
                                                                {
                                                                    vendor.payment_options.map(el => (
                                                                        <li className="vendor-view__text-tab-2-item-text-li" key={el}>{el}</li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        }
                                                </div>
                                            </div>

                                        </div>
                                }
                            </div>
                        </div>
                    </div>
            }

            {
                isPreloader ? <PreloaderProductCustom2 /> : 
                    <>
                        {
                            !!vendor._id?.length ?
                                <div className="vendor-view-wrap">
                                    <div className="vendor-view__img-wrap">
                                        {
                                            !!vendor.background_image?.length && <img className="vendor-view__img-background" src={vendor.background_image} alt='img'/>
                                        }
                                        {
                                            !!vendor.logo_image?.length && <img className="vendor-view__img-logo" src={vendor.logo_image} alt={vendor.company_name}/>
                                        }
                                    </div> 
                                    
                                    <div className='vendor-view__text-title-wrap container'>
                                        <h1 className="vendor-view__text-title">{vendor.company_name}</h1>
                                        <h2 className="vendor-view__text-sub-title">{vendor.slogan}</h2>
                                    </div>

                                    {/* <div className='vendor-view__rating--wrap'>
                                        <div className='vendor-view__rating-number'>4.6</div>
                                        <Box
                                            sx={{
                                                width: 200,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            className='vendor-view__rating-wrap'
                                        >
                                            <Rating
                                                className='vendor-view__rating'
                                                size="large"
                                                name="simple-controlled"
                                                precision={0.5}
                                                value={rating}
                                                onChange={(event, newValue) => handleRating(event, newValue)}
                                            />
                                        </Box>
                                        <div className="vendor-view__rating-text">22 Відгуки</div>
                                    </div> */}

                                    <div className="vendor-view__text-wrap container">
                                        <div className="vendor-view__text-mobile-tab-wrap">
                                            <button className="vendor-view__text-mobile-tab-btn" onClick={() => handleOpenProductInfo(1)}>
                                                <div className="vendor-view__text-mobile-tab-btn-text">Про компанію</div>
                                                <img className="vendor-view__text-mobile-tab-btn-text-img-arrow" src={arrow2} alt='img'/> 
                                            </button>
                                            <button className="vendor-view__text-mobile-tab-btn" onClick={() => handleOpenProductInfo(2)}>
                                                <div className="vendor-view__text-mobile-tab-btn-text">Доставка та оплата</div>
                                                <img className="vendor-view__text-mobile-tab-btn-text-img-arrow" src={arrow2} alt='img'/> 
                                            </button>
                                            <button className="vendor-view__text-mobile-tab-btn" onClick={() => handleOpenProductInfo(3)}>
                                                <div className="vendor-view__text-mobile-tab-btn-text">Контакти виробника</div>
                                                <img className="vendor-view__text-mobile-tab-btn-text-img-arrow" src={arrow2} alt='img'/> 
                                            </button>
                                        </div>

                                        <div className="vendor-view__text-tab--wrap">
                                            <div className="vendor-view__text-tab-wrap">
                                                <button className={`vendor-view__text-tab ${tab === 1 ? 'vendor-view__text-tab-active' : ''}`} onClick={() => setTab(1)}>Про компанію</button>
                                                <button className={`vendor-view__text-tab ${tab === 2 ? 'vendor-view__text-tab-active' : ''}`} onClick={() => setTab(2)}>Доставка та оплата</button>
                                                <button className={`vendor-view__text-tab ${tab === 3 ? 'vendor-view__text-tab-active' : ''}`} onClick={() => setTab(3)}>Контакти виробника</button>
                                            </div>
                                            {
                                                tab === 1 && <pre className="vendor-view__text-tab-1">{vendor.description}</pre>
                                            }
                                            {
                                                tab === 2 && 
                                                    <div className="vendor-view__text-tab-2">
                                                        <div className="vendor-view__text-tab-2-item">
                                                            <svg className="vendor-view__text-tab-2-item-img" xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92" fill="none"> <circle cx="46" cy="46" r="46" fill="#2A7F77"></circle> <path d="M63.6204 45.7854H60.5277V40.5662C60.5277 40.2358 60.2599 39.9684 59.9299 39.9684H50.2437V34.76C50.2437 34.4296 49.9759 34.1621 49.6459 34.1621H28.2392C27.9088 34.1621 27.6414 34.4296 27.6414 34.76V54.3646C27.6414 54.6946 27.9088 54.9625 28.2392 54.9625H33.2368C33.444 56.5038 34.7641 57.6972 36.3609 57.6972C37.9576 57.6972 39.2778 56.5038 39.485 54.9625H49.6459H53.5066C53.7139 56.5038 55.0348 57.6972 56.6331 57.6972C58.2315 57.6972 59.5524 56.5038 59.7601 54.9625H63.6208C63.9508 54.9625 64.2187 54.6946 64.2187 54.3646V46.3832C64.2183 46.0528 63.9504 45.7854 63.6204 45.7854ZM36.3609 56.5014C35.2803 56.5014 34.401 55.6217 34.401 54.5404C34.401 53.459 35.2803 52.5793 36.3609 52.5793C37.4415 52.5793 38.3208 53.459 38.3208 54.5404C38.3208 55.6217 37.4419 56.5014 36.3609 56.5014ZM36.3609 51.3835C34.8881 51.3835 33.6517 52.3996 33.3053 53.7667H28.8371V35.3579H49.048V40.5662V46.3832V53.7663H39.4169C39.0705 52.3996 37.8337 51.3835 36.3609 51.3835ZM56.6331 56.5014C55.551 56.5014 54.6709 55.6217 54.6709 54.5404C54.6709 53.459 55.551 52.5793 56.6331 52.5793C57.7153 52.5793 58.5958 53.459 58.5958 54.5404C58.5958 55.6217 57.7149 56.5014 56.6331 56.5014ZM63.0225 53.7663H59.6915C59.3447 52.3992 58.1071 51.3831 56.6331 51.3831C55.1591 51.3831 53.9215 52.3992 53.5752 53.7663H50.2441V46.3832V41.1641H59.3324V46.3832C59.3324 46.7133 59.6002 46.9811 59.9303 46.9811H63.0229V53.7663H63.0225Z" fill="white"></path> </svg>
                                                            <div className="vendor-view__text-tab-2-item-text-wrap">
                                                                <div className="vendor-view__text-tab-2-item-text-title">Варіанти доставки</div>
                                                                    {
                                                                        !!vendor.conditions_delivery?.length &&
                                                                            <div className="vendor-view__text-tab-1-conditions_delivery">{vendor.conditions_delivery}</div>
                                                                    }
                                                                    {
                                                                        !!vendor.delivery_options?.length && <ul className="vendor-view__text-tab-2-item-text">
                                                                            {
                                                                                vendor.delivery_options.map(el => (
                                                                                    <li className="vendor-view__text-tab-2-item-text-li" key={el}>{el}</li>
                                                                                ))
                                                                            }
                                                                        </ul>
                                                                    }
                                                            </div>
                                                        </div>
                                                        <div className="vendor-view__text-tab-2-item">
                                                             <svg className="vendor-view__text-tab-2-item-img" xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92" fill="none"> <circle cx="46" cy="46" r="46" fill="#2A7F77"></circle> <path d="M42.6528 55.1896C45.712 55.1896 48.2012 52.7004 48.2012 49.6412C48.2012 46.582 45.712 44.0928 42.6528 44.0928C39.5936 44.0928 37.1044 46.582 37.1044 49.6412C37.1044 52.7004 39.5936 55.1896 42.6528 55.1896ZM42.6528 45.2886C45.0527 45.2886 47.0054 47.2409 47.0054 49.6412C47.0054 52.0415 45.0531 53.9938 42.6528 53.9938C40.2525 53.9938 38.3001 52.0415 38.3001 49.6412C38.3001 47.2409 40.2525 45.2886 42.6528 45.2886Z" fill="white"></path> <path d="M31.5743 44.6904H30.3785V54.5915H31.5743V44.6904Z" fill="white"></path> <path d="M54.7394 44.6904H53.5436V54.5915H54.7394V44.6904Z" fill="white"></path> <path d="M64.5371 33.6572H34.1448V37.2039H30.876V41.0806H27.3225V58.2018H57.7148V54.3251H61.2683V50.7784H64.5367V33.6572H64.5371ZM56.519 57.006H28.5183V42.2764H56.519V57.006ZM60.0725 53.1297H57.7148V41.0806H32.0717V38.3997H60.0729L60.0725 53.1297ZM63.3414 49.5826H61.2687V37.2039H35.3406V34.853H63.3414V49.5826Z" fill="white"></path> </svg> 
                                                            <div className="vendor-view__text-tab-2-item-text-wrap">
                                                                <div className="vendor-view__text-tab-2-item-text-title">Варіанти оплати</div>
                                                                    {
                                                                        !!vendor.conditions_payment?.length &&
                                                                            <div className="vendor-view__text-tab-1-conditions_delivery">{vendor.conditions_payment}</div>
                                                                    }
                                                                    {
                                                                        !!vendor.payment_options?.length && <ul className="vendor-view__text-tab-2-item-text">
                                                                            {
                                                                                vendor.payment_options.map(el => (
                                                                                    <li className="vendor-view__text-tab-2-item-text-li" key={el}>{el}</li>
                                                                                ))
                                                                            }
                                                                        </ul>
                                                                    }
                                                            </div>
                                                        </div>

                                                    </div>
                                            }
                                            {
                                                tab === 3 && 
                                                    <div className="vendor-view__text-tab-3">
                                                        <div className="vendor-view__text-tab-3-info">
                                                            <div className="vendor-view__text-tab-3-info-title vendor-view__text-tab-3-info-title-addres">
                                                                <p>Контактна адреса:</p> 
                                                                <span className="vendor-view__text-tab-3-info-text">{vendor.address}</span>
                                                            </div>
                                                            <div className="vendor-view__text-tab-3-info-title">Графік роботи:</div>
                                                            <div className="vendor-view__text-tab-3-info-text" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: vendor.work_schedule }}></div>
                                                            <div className="vendor-view__text-tab-3-info-text">Якщо у вас є питання і побажання - пишіть нам. Наші менеджери оперативно вам допоможуть.</div>
                                                        </div>

                                                        <div className="vendor-view__text-tab-3-input-wrap">
                                                        {
                                                            isSendForm ? 
                                                                <div className="contacts-view__form-success">Дякуємо за ваше звернення. Найближчим часом ми з вами зв'яжемося</div>
                                                                :
                                                                <>
                                                                    <div className='contacts-view__form-error-wrap'>
                                                                        <input className={`vendor-view__text-tab-3-input ${isErrorName ? 'contacts-view__form-error' : ''}`} onChange={(e) => setName(e.target.value)} value={name} autoComplete="name" type='text' placeholder="Вкажіть ваше ім'я"/>
                                                                        {
                                                                            isErrorName && <div className='contacts-view__form-error-text'>Обов'язкове поле</div>
                                                                        }
                                                                    </div>
                                                                    <div className='contacts-view__form-error-wrap'>
                                                                        <input className={`vendor-view__text-tab-3-input ${isErrorEmail ? 'contacts-view__form-error' : ''}`} onChange={(e) => setEmail(e.target.value)} value={email} autoComplete="name" type='text' placeholder='Вкажіть ваш email'/>
                                                                        {
                                                                            isErrorEmail && <div className='contacts-view__form-error-text'>{!email.length ? 'Обов\'язкове поле' : 'Вкажіть, будь ласка, коректний email'}</div>
                                                                        }
                                                                    </div>
                                                                    <div className='contacts-view__form-error-wrap'>
                                                                        <InputPhone phone={phone} setPhone={setPhone} isVendorView={true} isErrorPhone={isErrorPhone}/>
                                                                        {
                                                                            isErrorPhone && <div className='contacts-view__form-error-text'>Обов'язкове поле</div>
                                                                        }
                                                                    </div>
                                                                    <div className='contacts-view__form-error-wrap'>
                                                                        <textarea className={`vendor-view__text-tab-3-textarea ${isErrorDescription ? 'contacts-view__form-error' : ''}`} onChange={(e) => setDescription(e.target.value)} value={description} placeholder="Опишіть ваше питання або побажання" rows="4"></textarea>
                                                                        {
                                                                            isErrorDescription && <div className='contacts-view__form-error-text'>Обов'язкове поле</div>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        (isErrorName || isErrorDescription || isErrorEmail || isErrorPhone) &&
                                                                            <div className='contacts-view__form-error-banner'>
                                                                                {
                                                                                    (isErrorName || isErrorDescription || isErrorPhone) && <div className='contacts-view__form-error-banner-text'>Будь ласка, заповніть всі обов'язкові поля</div>
                                                                                }
                                                                                {
                                                                                    isErrorEmail && ((!isErrorName && !isErrorDescription && !isErrorPhone) || !!email.length) && <div className='contacts-view__form-error-banner-text'>{!email.length ? 'Будь ласка, заповніть всі обов\'язкові поля' : 'Вкажіть, будь ласка, коректний email'}</div>
                                                                                }
                                                                            </div>
                                                                    }
                                                                    <button id='vendorViewSentBtn2' className='main-btn-1 vendor-view__text-tab-3-btn' onClick={() => hendleSend('vendorViewSentBtn2')}>Написати виробнику</button> 
                                                                </>
                                                        }
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                        
                                        {
                                            !!vendorProducts?.length &&
                                            <>
                                                    <h3 className="vendor-view__products-title">Товари виробника</h3>
                                                    <div className="vendor-view__products">
                                                        {
                                                            !!vendorProducts?.length && vendorProducts.map(el => (
                                                                <div className='vendor-view__product' key={el._id}>
                                                                    <ProductCard product={el} handleNextVariation={handleNextVariation}/>
                                                                </div>
                                                            ))
                                                            
                                                        }
                                                    </div>
                                                    {
                                                        handlePageCount(productsCount) > page + 1 &&
                                                        <div className="vendor-view__products-pagination" onClick={() => handleNexPages()}>
                                                            <button className="main-btn-2" >Показати більше</button>
                                                        </div>
                                                    }
                                                </>
                                        }
                                    </div>
                                </div>
                                :
                                <NotFoundView /> 
                        }
                    </>
            }
        </div>
    );
}

export default VendorView;