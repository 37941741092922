import './AboutMarketplace.css';
import tablet3 from '../../assets/tablet3.png';
import { NavLink } from 'react-router-dom';
import { setIsViewPreloader, setScrollPositionBeforeView } from '../../store/homeSlice';
import { useDispatch } from 'react-redux';

function AboutMarketplace() {
    const dispatch = useDispatch();

    const handleClickMore = () => {
        dispatch(setIsViewPreloader(true))
        dispatch(setScrollPositionBeforeView({}))
    }

    return (
        <div className="about-marketplace">
            <div className="about-marketplace__text-wrap">
                <div className="about-marketplace__text-title">Про маркетплейс VYROBY</div>
                <img className="about-marketplace__img about-marketplace__img-mobile" src={tablet3} alt='img' />
                <div className="about-marketplace__text">VYROBY - це спеціалізований маркетплейс меблів для дому, де покупці можуть купити сучасні меблі безпосередньо у відібраних, надійних локальних виробників України, з можливістю індивідуального виготовлення меблів під свій дизайн проект.</div>
                <div className="about-marketplace__text about-marketplace__text--1">Наша мета створити сервіс, який допоможе вимогливим клієнтам знайти та купити в одному місці якісні меблі для дому безпосередньо у виробника. На нашій онлайн платформі ми хочемо зробити акцент на якість, стиль і високий рівень сервісу.</div>
                <NavLink className="main-btn-3 about-marketplace__btn-wrap" onClick={() => handleClickMore()} to='/about'>
                    Дізнатись більше
                </NavLink>
            </div>
            <img className="about-marketplace__img about-marketplace__img-des" src={tablet3} alt='img' />
            <NavLink className="main-btn-3 about-marketplace__btn-mobile-wrap" onClick={() => handleClickMore()} to='/about'>
                Дізнатись більше
            </NavLink>
        </div>
    );
}

export default AboutMarketplace;