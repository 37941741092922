import './CreatingCollectionView.css';
import React, { useState, useEffect, useRef } from 'react';
import UserProductCard from '../../components/UserProductCard/UserProductCard';
import { useSelector, useDispatch } from 'react-redux';
import {
	setNewCollectionProductsId,
	setShowMessageObj,
	setEditeCollection,
	getCollections,
	getProducts,
	setProductsCount,
} from '../../store/userSlice';
import deleteImg2 from '../../assets/deleteImg2.svg';
import uploadImg from '../../assets/uploadImg.svg';
import saveImg from './../../assets/saveImg.svg';
import collection1 from './../../assets/collection1.svg';
import close2 from './../../assets/close2.svg';
import plusImg from './../../assets/plusImg.svg';
import closeImg from './../../assets/closeImg.svg';
import search from './../../assets/search.svg';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { MenuItem, Pagination, Select, Stack } from '@mui/material';
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import {
	fetchGetData,
	fetchRequest,
	handleCategoriesMap,
	validNumber,
} from '../../helpers/Utils';
import { NOT_DELETED_SUB_CATEGORY_ID } from '../../helpers/Config';
import cyrillicToTranslit from 'cyrillic-to-translit-js';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function CreatingCollectionView() {
	const [searchParams, setSearchParams] = useSearchParams();
	const newSearchParams = new URLSearchParams(searchParams);
	const tabSearchParams = Number(searchParams.get('tab'));

	const vendorIdSearchParams = JSON.parse(searchParams.get('vendorId'));
	const typeSearchParams = JSON.parse(searchParams.get('type'));
	const valueSortPriceSearchParams = JSON.parse(
		searchParams.get('valueSortPrice')
	);
	const titleSearchParams = searchParams.get('title');
	const setSortValueSearchParams = JSON.parse(searchParams.get('setSortValue'));
	const selectCategoryIdSearchParams = searchParams.get('selectCategoryId');
	const hideSortValueSearchParams = searchParams.get('hideSortValue');
	const pageSearchParams = Number(JSON.parse(searchParams.get('page')));

	const products = useSelector((state) => state.userSlice.products);
	const productsCount = useSelector((state) => state.userSlice.productsCount);
	const collections = useSelector((state) => state.userSlice.collections);
	const editeCollection = useSelector(
		(state) => state.userSlice.editeCollection
	);
	const newCollectionProductsId = useSelector(
		(state) => state.userSlice.newCollectionProductsId
	);
	const vendors = useSelector((state) => state.userSlice.vendors);
	const categories = useSelector((state) => state.userSlice.categories);
	const [newCollection, setnewCollection] = useState('');
	const [alias, setAlias] = useState('');
	const [oldImage, setOldImage] = useState('');
	const [image, setImage] = useState({});
	const [isPreloader, setIsPreloader] = useState(true);
	const [page, setPage] = useState(0);
	const [isCollectionProducts, setIsCollectionProducts] = useState(true);
	const [seoDefaultTitle, setSeoDefaultTitle] = useState('');
	const [seoDefaultDescription, setSeoDefaultDescription] = useState('');
	const [keywords, setKeywords] = useState('');
	const [seoDefaultKeywords, setSeoDefaultKeywords] = useState([]);
	const [tabValue, setTabValue] = useState(
		tabSearchParams ? tabSearchParams : 0
	);
	const productWrapRef = useRef(null);
	const { userCollectionId } = useParams();
	const [errorNewCollection, setErrorNewCollection] = useState('');
	const [errorAlias, setEerrorAlias] = useState('');
	const [errorImage, setErrorImage] = useState('');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const [valueSortPrice, setValueSortPrice] = useState(
		valueSortPriceSearchParams?.length && valueSortPriceSearchParams[1] !== 0
			? valueSortPriceSearchParams
			: [0, 0]
	);
	const [selectCategoryId, setSelectCategoryId] = useState(
		selectCategoryIdSearchParams?.length ? selectCategoryIdSearchParams : ''
	);
	const [searchTitle, setSearchTitle] = useState(
		titleSearchParams?.length ? titleSearchParams : ''
	);
	const [type, setType] = useState(
		typeSearchParams?.length ? typeSearchParams : []
	);
	const [vendorId, setVendorId] = useState(
		vendorIdSearchParams?.length ? vendorIdSearchParams : ''
	);
	const [mainMaxPrice, setMainMaxPrice] = useState(0);
	const [sortValue, setSortValue] = useState(
		setSortValueSearchParams?.name?.length ? setSortValueSearchParams : {}
	);
	const [title, setTitle] = useState(
		titleSearchParams?.length ? titleSearchParams : ''
	);
	const [isNotFirstRender, setIsNotFirstRender] = useState(false);
	const [isOpenSelectVendorId, setIsOpenSelectVendorId] = useState(false);
	const [isOpenSelectType, setIsOpenSelectType] = useState(false);
	const [isOpenSelectSortValue, setIsOpenSelectSortValue] = useState(false);
	const [isOpenSelectSelectValue, setIsOpenSelectSelectValue] = useState(false);
	const transliterator = new cyrillicToTranslit({ preset: 'uk' });
	let token = localStorage.getItem('token');

	useEffect(() => {
		setIsNotFirstRender(true);
		handleGetProductFilter();

		if (!editeCollection?._id?.length && userCollectionId?.length) {
			fetchGetData(
				`${process.env.REACT_APP_BASE_URL}/collections/${userCollectionId}`
			).then((res) => {
				if (res.success && res.data) {
					dispatch(setEditeCollection(res.data));
				} else {
					console.log('GET CreatingCollectionView', res);
				}
			});
		}

		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScroll);

			dispatch(setEditeCollection({}));
			dispatch(setNewCollectionProductsId([]));
			handleClearInput();
		};
	}, []);

	useEffect(() => {
		if (editeCollection?._id?.length) {
			setnewCollection(editeCollection.title);
			setAlias(editeCollection.alias);
			dispatch(
				setNewCollectionProductsId(
					editeCollection.products?.length
						? [...editeCollection.products.map((el) => el._id)]
						: []
				)
			);
			setOldImage(editeCollection.image);
			setSeoDefaultTitle(editeCollection.seo_default_title);
			setSeoDefaultDescription(editeCollection.seo_default_description);
			setSeoDefaultKeywords(editeCollection.seo_default_keywords);
		}
	}, [editeCollection]);

	useEffect(() => {
		if (isNotFirstRender) {
			if (tabValue === 0) {
				handleGetProducts();
			}
		}
	}, [location]);

	useEffect(() => {
		if (!searchTitle?.length && isNotFirstRender) {
			setTitle('');
			newSearchParams.set('title', '');
			setSearchParams(newSearchParams);
		}
	}, [searchTitle]);

	useEffect(() => {
		if (
			(tabSearchParams && tabSearchParams !== tabValue) ||
			(!tabSearchParams && tabValue !== 0)
		) {
			setTabValue(tabSearchParams ? tabSearchParams : 0);
		}
	}, [tabSearchParams]);

	const handleScroll = () => {
		setIsOpenSelectVendorId(false);
		setIsOpenSelectType(false);
		setIsOpenSelectSortValue(false);
		setIsOpenSelectSelectValue(false);
	};

	const handleSetNameCollection = (str) => {
		setnewCollection(str);
		let res = str.replace(/[^a-zA-Zа-яА-ЯіІїЇєЄ\s]/g, '');
		if (res?.length) {
			const latinText = transliterator
				.transform(res)
				.trim()
				.split(' ')
				.join('-');
			return setAlias(latinText);
		}
	};

	const handleIsValidAlias = async (strAlias) => {
		await fetchGetData(
			`${process.env.REACT_APP_BASE_URL}/collections/check?alias=${strAlias}`
		).then((res) => {
			if (res?.success) {
				return true;
			} else {
				console.log('GET CreatingVendorView', res);
				return false;
			}
		});
	};

	const handleCreate = () => {
		let formData = new FormData();

		formData.append('title', newCollection);
		formData.append('alias', alias);
		formData.append('seo_default_title', seoDefaultTitle);
		formData.append('seo_default_description', seoDefaultDescription);
		formData.append('file', image);
		if (seoDefaultKeywords.length) {
			seoDefaultKeywords.map((el) => {
				formData.append('seo_default_keywords[]', el);
			});
		} else {
			formData.append('seo_default_keywords[]', []);
		}
		if (newCollectionProductsId.length) {
			newCollectionProductsId.map((el) => {
				formData.append('products[]', el);
			});
		} else {
			formData.append('products[]', []);
		}
		if (
			newCollection?.length &&
			alias?.length &&
			handleIsValidAlias(alias) &&
			newCollectionProductsId?.length &&
			(image?.name?.length || oldImage?.length)
		) {
			if (editeCollection._id?.length) {
				fetch(
					`${process.env.REACT_APP_BASE_URL}/collections/${editeCollection._id}?token=${token}`,
					{
						method: 'PUT',
						body: formData,
					}
				)
					.then((res) => res.json())
					.then((res) => {
						if (res.success && res.data) {
							dispatch(
								getCollections([
									...collections.map((el) => {
										if (el._id === res.data._id) {
											el = res.data;
										}
										return el;
									}),
								])
							);
							dispatch(setEditeCollection(res.data));
							dispatch(
								setShowMessageObj({
									open: true,
									status: 'success',
									message: 'Дані оновлено',
								})
							);
						} else {
							console.log('PUT CreatingCollection:', res);
							dispatch(
								setShowMessageObj({
									open: true,
									status: 'error',
									message: 'Сталася помилка',
								})
							);
						}
					});
			} else {
				fetch(`${process.env.REACT_APP_BASE_URL}/collections?token=${token}`, {
					method: 'POST',
					body: formData,
				})
					.then((res) => res.json())
					.then((res) => {
						if (res.success && res.data) {
							dispatch(getCollections([...collections, res.data]));
							dispatch(setEditeCollection(res.data));
							dispatch(
								setShowMessageObj({
									open: true,
									status: 'success',
									message: 'Дані оновлено',
								})
							);
							navigate(`/auth/collection/${res.data._id}?tab=${0}`);
						} else {
							console.log('PUT CreatingCollection:', res);
							dispatch(
								setShowMessageObj({
									open: true,
									status: 'error',
									message: 'Сталася помилка',
								})
							);
						}
					});
			}
		} else {
			setErrorNewCollection(!newCollection?.length ? "Обов'язкове поле" : '');
			setEerrorAlias(
				!alias?.length || (alias?.length && handleIsValidAlias(alias))
					? "Обов'язкове поле"
					: ''
			);
			setErrorImage(
				!image?.name?.length && !oldImage?.length ? "Обов'язкове поле" : ''
			);
			if (!newCollectionProductsId?.length) {
				dispatch(
					setShowMessageObj({
						open: true,
						status: 'error',
						message: 'Виберіть товари',
					})
				);
			}
		}
	};

	const handleClearInput = () => {
		setnewCollection('');
		setAlias('');
		dispatch(setNewCollectionProductsId([]));
		dispatch(setEditeCollection({}));
		setOldImage('');
		setImage({});
	};

	const handleDeleteUploadImg = () => {
		setImage({});
	};

	const handleDeleteImg = () => {
		setOldImage('');
	};

	const handleImageUpload = (e) => {
		e.preventDefault();
		setImage(e.dataTransfer.files[0]);
	};

	const handleDeleteKeyword = (str) => {
		setSeoDefaultKeywords([...seoDefaultKeywords.filter((el) => el !== str)]);
	};

	const handleKeyDown = (e) => {
		if (e.key == 'Enter') {
			handleAddKeyword();
		}
	};

	const handleSetTabValue = (num) => {
		setTabValue(num);
		if (editeCollection._id?.length) {
			navigate(`/auth/collection/${editeCollection._id}?tab=${num}`);
		} else {
			navigate(`/auth/collection/create?tab=${num}`);
		}
	};

	const handleAddKeyword = () => {
		if (keywords?.length) {
			if (seoDefaultKeywords?.length) {
				setSeoDefaultKeywords([...seoDefaultKeywords, keywords]);
			} else {
				setSeoDefaultKeywords([keywords]);
			}
			setKeywords('');
		}
	};

	const handleSelectSortByCategory = (id) => {
		setSelectCategoryId(id !== ' ' ? id : '');
		newSearchParams.set('selectCategoryId', id !== ' ' ? id : '');
		handleSetPage(1);
		setSearchParams(newSearchParams);
	};

	const handleSelectSort = (obj) => {
		setSortValue(JSON.parse(obj));
		newSearchParams.set('setSortValue', obj);
		handleSetPage(1);
		setSearchParams(newSearchParams);
	};

	const handleTitleKeyDown = (e) => {
		if (e.key == 'Enter') {
			setTitle(searchTitle);
			newSearchParams.set('title', searchTitle);
			handleSetPage(1);
			setSearchParams(newSearchParams);
		}
	};

	const handleSearchByName = () => {
		setTitle(searchTitle);
		newSearchParams.set('title', searchTitle);
		handleSetPage(1);
		setSearchParams(newSearchParams);
	};

	const handleCleanSearchTitle = () => {
		setSearchTitle('');
		newSearchParams.set('title', '');
		handleSetPage(1);
		setSearchParams(newSearchParams);
	};

	const handleDeleteSortPrice = (str) => {
		if (str === 'min') {
			setValueSortPrice([0, valueSortPrice[1]]);
		} else if (str === 'max') {
			setValueSortPrice([valueSortPrice[0], mainMaxPrice]);
		}
	};

	const handleSortByMinPrice = (num) => {
		if (num.startsWith('0')) {
			validNumber(num.slice(1), (price) =>
				setValueSortPrice([price === '' ? 0 : +price, valueSortPrice[1]])
			);
		} else {
			validNumber(num, (price) =>
				setValueSortPrice([price === '' ? 0 : +price, valueSortPrice[1]])
			);
		}
	};

	const handleSortByMaxPrice = (num) => {
		if (num.startsWith('0')) {
			validNumber(num.slice(1), (price) =>
				setValueSortPrice([valueSortPrice[0], price === '' ? 0 : +price])
			);
		} else {
			validNumber(num, (price) =>
				setValueSortPrice([valueSortPrice[0], price === '' ? 0 : +price])
			);
		}
	};

	const handleSelectVendor = (id) => {
		setVendorId(JSON.parse(id));
		newSearchParams.set('vendorId', id);
		handleSetPage(1);
		setSearchParams(newSearchParams);
	};

	const handleSelectType = (str) => {
		setType(JSON.parse(str));
		newSearchParams.set('type', str);
		handleSetPage(1);
		setSearchParams(newSearchParams);
	};

	const handleSortPrice = () => {
		newSearchParams.set('valueSortPrice', JSON.stringify(valueSortPrice));
		handleSetPage(1);
		setSearchParams(newSearchParams);
	};

	const handleCleanSortPrice = () => {
		setValueSortPrice([0, mainMaxPrice]);
		newSearchParams.set('valueSortPrice', JSON.stringify([0, mainMaxPrice]));
		handleSetPage(1);
		setSearchParams(newSearchParams);
	};

	const handleSetPage = (num) => {
		setPage(num);
		newSearchParams.set('page', JSON.stringify(num));
		setSearchParams(newSearchParams);
	};

	const handleGetProductFilter = () => {
		fetchGetData(
			`${process.env.REACT_APP_BASE_URL}/products/filter?category_id=${
				selectCategoryIdSearchParams?.length
					? selectCategoryIdSearchParams
					: selectCategoryId
			}`
		).then((res) => {
			if (res.success && res.data) {
				if (res.data.price) {
					if (
						valueSortPriceSearchParams?.length &&
						valueSortPriceSearchParams[1] !== 0
					) {
						setValueSortPrice(valueSortPriceSearchParams);
						handleGetProducts(
							false,
							valueSortPriceSearchParams[0],
							valueSortPriceSearchParams[1]
						);
					} else {
						setValueSortPrice([res.data.price.min, res.data.price.max]);
						handleGetProducts(false, res.data.price.min, res.data.price.max);
					}
					setMainMaxPrice(res.data.price.max);
				}
			} else {
				console.log('GET UserProductsFilter', res);
			}
		});
	};

	const handleGetProducts = (isNextPage = false, minPriceCol, maxPriceCol) => {
		setIsPreloader(true);

		let data = {
			page: pageSearchParams ? pageSearchParams - 1 : 0,
			category_id: selectCategoryIdSearchParams?.length
				? [selectCategoryIdSearchParams]
				: [selectCategoryId],
			without_category:
				hideSortValueSearchParams?.length &&
				hideSortValueSearchParams === 'null'
					? true
					: false,
			type: type,
			sort_price: sortValue.name === 'price' ? sortValue.value : '',
			sort_name: sortValue.name === 'name' ? sortValue.value : '',
			min_price:
				valueSortPriceSearchParams?.length &&
				valueSortPriceSearchParams[0] !== 0
					? valueSortPriceSearchParams[0]
					: minPriceCol
					? minPriceCol
					: valueSortPrice[0],
			max_price:
				valueSortPriceSearchParams?.length &&
				valueSortPriceSearchParams[1] !== 0
					? valueSortPriceSearchParams[1]
					: maxPriceCol
					? maxPriceCol
					: valueSortPrice[1],
			is_hidden:
				hideSortValueSearchParams?.length &&
				hideSortValueSearchParams === 'hide'
					? true
					: false,
			vendor_id: vendorId,
			title: title,
			catalog: false,
			count_product_variations: false,
		};
		fetchRequest(
			'POST',
			`${process.env.REACT_APP_BASE_URL}/products/admin/all`,
			data
		).then((res) => {
			if (res.success && res.data) {
				if (isNextPage) {
					dispatch(getProducts([...products, ...res.data]));
				} else {
					dispatch(getProducts(res.data));
				}
				if (productsCount !== res.count) {
					dispatch(setProductsCount(res.count));
				}
			} else {
				console.log('POST UserProductsViews:', res);
			}
			setIsPreloader(false);
		});
	};

	return (
		<div className='creating-collection--wrap'>
			<SeoBlock
				title={
					!!editeCollection?._id?.length
						? 'Редагування колекції'
						: 'Створення колекції'
				}
				description={''}
				keywords={''}
				isCanonical={
					location.pathname === '/auth/collection/create' ? true : false
				}
			/>
			<div className='user-collection__header'>
				<div className='user-collection__header-title-wrap'>
					<img
						className='user-collection__header-title-img'
						src={collection1}
						alt='img'
					/>
					<h1 className='user-collection__header-title'>Колекції</h1>
				</div>
				<div
					className={`creating-category__text-tab--wrap ${
						!!editeCollection?._id?.length
							? 'creating-category__text-tab--save-wrap'
							: ''
					}`}
				>
					<div className='creating-category__text-tab-wrap'>
						<button
							className={`user-collection__text-tab ${
								tabValue === 0 ? 'user-order__text-tab-active' : ''
							}`}
							onClick={() => handleSetTabValue(0)}
						>
							Основна інформація
						</button>
						<button
							className={`user-collection__text-tab ${
								tabValue === 1 ? 'user-order__text-tab-active' : ''
							}`}
							onClick={() => handleSetTabValue(1)}
						>
							SEO налаштування
						</button>
					</div>
					<Select
						value={tabValue}
						className='creating-category__select-tab user-order__order-select-status'
						onChange={(e) => handleSetTabValue(e.target.value)}
					>
						<MenuItem
							className={`product-view__variations-select-item creating-category__select-tab-item ${
								tabValue === 0 ? 'creating-collection__select-item-active' : ''
							}`}
							value={0}
						>
							Основна інформація
						</MenuItem>
						<MenuItem
							className={`product-view__variations-select-item creating-category__select-tab-item ${
								tabValue === 1 ? 'creating-collection__select-item-active' : ''
							}`}
							value={1}
						>
							SEO налаштування
						</MenuItem>
					</Select>
				</div>
				<div className='user-collection__header-btn-wrap'>
					<button
						className='user-collection__header-btn--save'
						onClick={handleCreate}
					>
						<img
							className='user-collection__header-btn-img--save'
							src={saveImg}
							alt='img'
						/>
						<span>Зберегти колекцію</span>
					</button>
				</div>
			</div>
			{tabValue === 1 && (
				<>
					{isPreloader ? (
						<PreloaderCustom />
					) : (
						<div className='creating-category__seo-wrap'>
							<div className='creating-category__input--wrap'>
								<div className='creating-category__input-lable'>
									SEO заголовок
								</div>
								<input
									className='creating-category__input'
									value={seoDefaultTitle}
									onChange={(e) => setSeoDefaultTitle(e.target.value)}
									placeholder='SEO title'
									type='text'
								/>
							</div>
							<div className='creating-category__input--wrap'>
								<div className='creating-category__input-lable'>SEO опис</div>
								<textarea
									className='creating-category__textarea'
									value={seoDefaultDescription}
									onChange={(e) => setSeoDefaultDescription(e.target.value)}
									placeholder='SEO description'
									rows={8}
									type='text'
								/>
							</div>
							<div className='creating-category__add-seo-wrap'>
								<div className='creating-category__input-lable'>
									SEO ключові слова
								</div>
								<div className='creating-category__add-btn--wrap'>
									<input
										className='creating-category__input'
										value={keywords}
										onChange={(e) => setKeywords(e.target.value)}
										onKeyDown={(e) => handleKeyDown(e)}
										placeholder='SEO keyword'
										type='text'
									/>
									<img
										className='creating-category__add-btn'
										onClick={handleAddKeyword}
										src={plusImg}
										alt='img'
									/>
								</div>
								<div className='creating-category__add-result-wrap'>
									{!!seoDefaultKeywords?.length &&
										seoDefaultKeywords.map((el, i) => (
											<div
												className='creating-category__add-result'
												key={el + i}
											>
												<p>{el}</p>
												<button onClick={() => handleDeleteKeyword(el)}>
													<img
														className='creating-category__add-result-del-words-btn'
														src={close2}
														alt='img'
													/>
												</button>
											</div>
										))}
								</div>
							</div>
						</div>
					)}
				</>
			)}
			{tabValue === 0 && (
				<>
					{isPreloader ? (
						<PreloaderCustom />
					) : (
						<div className='creating-collection'>
							<div className='creating-collection__input-wrap'>
								<div className='creating-collection__input-lable'>
									<span className='requiredFields'>Назва колекції</span>
								</div>
								<input
									className={`creating-collection__input ${
										!!errorNewCollection?.length
											? 'creating-category__input--error'
											: ''
									}`}
									placeholder='Введіть назву колекції'
									value={newCollection}
									onChange={(e) => handleSetNameCollection(e.target.value)}
									type='text'
								/>
								<div className='creating-collection__input-sub-title'>
									<span>Не перевищуйте 20 символів під час введення назви</span>
									{!!errorNewCollection?.length && (
										<div className='creating-category__input-error-text'>
											{errorNewCollection}
										</div>
									)}
								</div>
							</div>
							<div className='creating-collection__input-wrap creating-collection__input-wrap--alias'>
								<div className='creating-collection__input-lable'>
									<span className='requiredFields'>Назва url колекції</span>
								</div>
								<input
									className={`creating-collection__input ${
										!!errorAlias?.length
											? 'creating-category__input--error'
											: ''
									}`}
									placeholder='Додати url колекції'
									value={alias}
									onChange={(e) => setAlias(e.target.value)}
									type='text'
								/>
								<div className='creating-collection__input-sub-title'>
									<span>
										Тільки англійські букви без цифр і відмінне від інших
										категорій. Не перевищуйте 24 символів під час введення назви
										категорії.
									</span>
									{!!errorAlias?.length && (
										<div className='creating-category__input-error-text'>
											{errorAlias}
										</div>
									)}
								</div>
							</div>

							<div className='creating-collection__uploud-lable'>
								<span className='requiredFields'>Додайте фото</span>
								{!!errorImage?.length && (
									<div className='creating-category__input-error-text'>
										{errorImage}
									</div>
								)}
							</div>
							<div className='creating-collection__create-img--wrap'>
								<div
									className={`creating-collection__create-img-wrap ${
										!!errorImage?.length
											? 'creating-category__input--error'
											: ''
									}`}
									onDrop={(e) => handleImageUpload(e)}
									onDragOver={(e) => e.preventDefault()}
								>
									<div className='creating-product__create-img-text-wrap'>
										<div className='creating-product__create-img-text-img-wrap'>
											<img
												className='creating-product__create-img-text-img'
												src={uploadImg}
												alt='img'
											/>
										</div>
										<label
											className='creating-product__create-img-link-upload-lable'
											htmlFor='creationProductUserImages'
										>
											Клікніть завантажити
										</label>
										<span> або перетягніть зображення сюди</span>
										<p>Формати які підтримуються: PNG, JPG</p>
										<input
											className='creating-product__create-img-link-upload-input'
											onChange={(e) => setImage(e.target.files[0])}
											type='file'
											accept='image/*'
											id='creationProductUserImages'
										/>
									</div>
								</div>

								{!!image?.name?.length && (
									<div className='creating-collection__upload-result-wrap'>
										<button onClick={handleDeleteUploadImg}>
											<img
												className='creating-collection__upload-result-del-btn'
												src={deleteImg2}
												alt='img'
											/>
										</button>
										<img
											className='creating-collection__upload-img-result'
											src={URL.createObjectURL(image)}
											alt='img'
										/>
									</div>
								)}
								{!image?.name?.length && !!oldImage?.length && (
									<div className='creating-collection__upload-result-wrap'>
										<button onClick={handleDeleteImg}>
											<img
												className='creating-collection__upload-result-del-btn'
												src={deleteImg2}
												alt='img'
											/>
										</button>
										<img
											className='creating-collection__upload-img-result'
											src={oldImage}
											alt='img'
										/>
									</div>
								)}
							</div>

							<div className='creating-collection__filter'>
								<div className='creating-collection-wrap'>
									<div className='creating-collection__select-wrap'>
										<p className='creating-collection__input-lable'>Виробник</p>
										<Select
											className='creating-collection__select'
											value={JSON.stringify(vendorId)}
											onChange={(e) => handleSelectVendor(e.target.value)}
											open={isOpenSelectVendorId}
											onClose={() => setIsOpenSelectVendorId(false)}
											onOpen={() => setIsOpenSelectVendorId(true)}
											label=''
										>
											<MenuItem
												className={`creating-collection__select-item ${
													JSON.stringify('')
														? 'creating-collection__select-item-active'
														: ''
												}`}
												value={JSON.stringify('')}
											>
												Всі виробники
											</MenuItem>
											{!!vendors?.length &&
												vendors.map((el, i) => (
													<MenuItem
														className={`creating-collection__select-item ${
															vendorId === el._id
																? 'creating-collection__select-item-active'
																: ''
														}`}
														value={JSON.stringify(el._id)}
														key={el._id + i + el.company_name}
													>
														{el.company_name}
													</MenuItem>
												))}
										</Select>
									</div>
									<div className='creating-collection__select-wrap'>
										<p className='creating-collection__input-lable'>
											Тип виробництва
										</p>
										<Select
											className='creating-collection__select'
											value={JSON.stringify(type)}
											onChange={(e) => handleSelectType(e.target.value)}
											open={isOpenSelectType}
											onClose={() => setIsOpenSelectType(false)}
											onOpen={() => setIsOpenSelectType(true)}
											label=''
										>
											<MenuItem
												className={`creating-collection__select-item ${
													!type?.length
														? 'creating-collection__select-item-active'
														: ''
												}`}
												value={JSON.stringify([])}
											>
												Всі типи
											</MenuItem>
											<MenuItem
												className={`creating-collection__select-item ${
													type[0] === 'serial'
														? 'creating-collection__select-item-active'
														: ''
												}`}
												value={JSON.stringify(['serial'])}
											>
												Серійне
											</MenuItem>
											<MenuItem
												className={`creating-collection__select-item ${
													type[0] === 'individual'
														? 'creating-collection__select-item-active'
														: ''
												}`}
												value={JSON.stringify(['individual'])}
											>
												Індивідуальне
											</MenuItem>
										</Select>
									</div>
									<div className='creating-collection__select-wrap'>
										<div className='creating-collection__input-lable-wrap'>
											<div className='creating-collection__input-lable'>
												<div className='creating-collection__input-lable-price-wrap'>
													<div className='creating-collection__input-lable-price'>
														Ціна
													</div>
													<div className='creating-collection__products-filter-price-result-input-wrap'>
														<div className='creating-collection__products-filter-price-result--wrap'>
															<div className='creating-collection__products-filter-price-result-title creating-collection__products-filter-price-result-title--mob'>
																Мін
															</div>
															<div className='creating-collection__products-filter-price-result-title creating-collection__products-filter-price-result-title--des'>
																Мінімальна
															</div>
															<img
																className='creating-collection__products-filter-price-result-btn'
																onClick={() => handleDeleteSortPrice('min')}
																src={closeImg}
																alt='img'
															/>
															<input
																className='creating-collection__products-filter-price-result'
																min={0}
																value={valueSortPrice[0]}
																onChange={(e) =>
																	handleSortByMinPrice(e.target.value)
																}
															/>
														</div>
														<div className='creating-collection__products-filter-price-result-line'></div>
														<div className='creating-collection__products-filter-price-result--wrap'>
															<div className='creating-collection__products-filter-price-result-title creating-collection__products-filter-price-result-title--mob'>
																Макс
															</div>
															<div className='creating-collection__products-filter-price-result-title creating-collection__products-filter-price-result-title--des'>
																Максимальна
															</div>
															<img
																className='creating-collection__products-filter-price-result-btn'
																onClick={() => handleDeleteSortPrice('max')}
																src={closeImg}
																alt='img'
															/>
															<input
																className='creating-collection__products-filter-price-result'
																value={valueSortPrice[1]}
																onChange={(e) =>
																	handleSortByMaxPrice(e.target.value)
																}
															/>
														</div>
													</div>
												</div>
											</div>
											<div className='creating-collection__products-filter-price-result-wrap'>
												<div className='creating-collection__products-filter-price-btn-wrap'>
													<button
														className='creating-collection__products-filter-price-btn'
														onClick={() => handleCleanSortPrice()}
													>
														Очистити
													</button>
													<button
														className='creating-collection__products-filter-price-btn-sent'
														onClick={() => handleSortPrice()}
													>
														Показати
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div
									className={`creating-collection-wrap creating-collection-wrap--des`}
								>
									<div className='creating-collection__search-wrap'>
										<input
											className='creating-collection__input creating-collection__search-input'
											value={searchTitle}
											onChange={(e) => setSearchTitle(e.target.value)}
											onKeyDown={(e) => handleTitleKeyDown(e)}
											placeholder='Пошук по Назві товару'
											type='text'
										/>
										<img
											className='creating-collection__search-input-img'
											onClick={handleSearchByName}
											src={search}
											alt='img'
										/>
										{!!searchTitle?.length && (
											<img
												className='layout-user__header-search-btn-close'
												onClick={() => handleCleanSearchTitle()}
												src={closeImg}
												alt='img'
											/>
										)}
									</div>
									<div className={`creating-collection__select-wrap`}>
										<p className='creating-collection__input-lable'>
											Сортувати
										</p>
										<Select
											className='creating-collection__select'
											value={JSON.stringify(sortValue)}
											onChange={(e) => handleSelectSort(e.target.value)}
											open={isOpenSelectSortValue}
											onClose={() => setIsOpenSelectSortValue(false)}
											onOpen={() => setIsOpenSelectSortValue(true)}
											label=''
										>
											<MenuItem
												className={`creating-collection__select-item ${
													!sortValue.name?.length
														? 'creating-collection__select-item-active'
														: ''
												}`}
												value={JSON.stringify({})}
											>
												Без сортування
											</MenuItem>
											<MenuItem
												className={`creating-collection__select-item ${
													sortValue.name === 'price' && sortValue.value === '1'
														? 'creating-collection__select-item-active'
														: ''
												}`}
												value={JSON.stringify({ name: 'price', value: '1' })}
											>
												По зростанню ціни
											</MenuItem>
											<MenuItem
												className={`creating-collection__select-item ${
													sortValue.name === 'price' && sortValue.value === '-1'
														? 'creating-collection__select-item-active'
														: ''
												}`}
												value={JSON.stringify({ name: 'price', value: '-1' })}
											>
												По спаданню ціни
											</MenuItem>
											<MenuItem
												className={`creating-collection__select-item ${
													sortValue.name === 'name' && sortValue.value === '1'
														? 'creating-collection__select-item-active'
														: ''
												}`}
												value={JSON.stringify({ name: 'name', value: '1' })}
											>
												По назві А-Я
											</MenuItem>
											<MenuItem
												className={`creating-collection__select-item ${
													sortValue.name === 'name' && sortValue.value === '-1'
														? 'creating-collection__select-item-active'
														: ''
												}`}
												value={JSON.stringify({ name: 'name', value: '-1' })}
											>
												По назві Я-А
											</MenuItem>
										</Select>
									</div>
									<div
										className={`creating-collection__select-wrap creating-collection__select-wrap--des`}
									>
										<p className='creating-collection__input-lable'>
											Категорія
										</p>
										<Select
											className='creating-collection__select'
											value={selectCategoryId?.length ? selectCategoryId : ' '}
											onChange={(e) =>
												handleSelectSortByCategory(e.target.value)
											}
											open={isOpenSelectSelectValue}
											onClose={() => setIsOpenSelectSelectValue(false)}
											onOpen={() => setIsOpenSelectSelectValue(true)}
											label=''
										>
											<MenuItem
												className={`creating-collection__select-item ${
													!selectCategoryId.length
														? 'creating-collection__select-item-active'
														: ''
												}`}
												value={' '}
												disableRipple={true}
											>
												Всі категорії
											</MenuItem>
											{!!categories?.length &&
												handleCategoriesMap(categories).map((el, i) => (
													<MenuItem
														className={`creating-collection__select-item ${
															selectCategoryId === el._id
																? 'creating-collection__select-item-active'
																: ''
														} ${
															el._id === NOT_DELETED_SUB_CATEGORY_ID
																? 'displayNone'
																: ''
														}`}
														value={el._id}
														key={el._id}
														disableRipple={true}
													>
														{el.name}
													</MenuItem>
												))}
										</Select>
									</div>
								</div>
							</div>

							<label className='creating-collection__product-label'>
								<label className='catalog-view__products-filter-label'>
									<input
										className='catalog-view__products-filter-checkbox'
										type='checkbox'
										value={isCollectionProducts}
										onChange={(e) =>
											setIsCollectionProducts(!isCollectionProducts)
										}
									/>
									<div
										className={`catalog-view__products-filter-checkbox-custom ${
											isCollectionProducts
												? 'catalog-view__products-filter-checkbox-custom-active'
												: ''
										}`}
									></div>
								</label>
								<span>Показати товари колекції</span>
							</label>

							<div className='creating-collection__products--wrap'>
								<div className='creating-collection__products-header-wrap'>
									<div className='creating-collection__products-header-title'>
										Товари
									</div>
									<div className='creating-collection__products-header-category'>
										Категорія
									</div>
									<div className='creating-collection__products-header-price'>
										Ціна
									</div>
									<div className='creating-collection__products-header-code'>
										Код товару
									</div>
								</div>
								<div className='creating-collection__products-wrap'>
									{!!editeCollection._id?.length ? (
										<div ref={productWrapRef}>
											{isCollectionProducts &&
												!!editeCollection.products?.length &&
												editeCollection.products.map((el, i) => (
													<UserProductCard
														product={el}
														isHideVariations={true}
														isCreatingCollectionView={true}
														key={el._id + i}
													/>
												))}
											{!isCollectionProducts &&
												!!products?.length &&
												products.map((el, i) => (
													<UserProductCard
														product={el}
														isHideVariations={true}
														isCreatingCollectionView={true}
														key={el._id + i}
													/>
												))}
											{(!products?.length ||
												(!newCollectionProductsId?.length &&
													isCollectionProducts)) && (
												<div className='creating-collection__products-error'>
													Товарів не знайдено
												</div>
											)}
										</div>
									) : (
										<div ref={productWrapRef}>
											{isCollectionProducts &&
												!!products?.length &&
												!!newCollectionProductsId?.length &&
												!!products.filter((ell) =>
													newCollectionProductsId.includes(ell._id)
												)?.length &&
												products
													.filter((ell) =>
														newCollectionProductsId.includes(ell._id)
													)
													.map((el, i) => (
														<UserProductCard
															product={el}
															isHideVariations={true}
															isCreatingCollectionView={true}
															key={el._id + i}
														/>
													))}
											{!isCollectionProducts &&
												!!products?.length &&
												products.map((el, i) => (
													<UserProductCard
														product={el}
														isHideVariations={true}
														isCreatingCollectionView={true}
														key={el._id + i}
													/>
												))}
											{(!products?.length ||
												(!newCollectionProductsId?.length &&
													isCollectionProducts)) && (
												<div className='creating-collection__products-error'>
													Товарів не знайдено
												</div>
											)}
										</div>
									)}
								</div>
								{!isCollectionProducts &&
									productsCount !== 0 &&
									productsCount > 50 && (
										<Stack
											className='creating-collection__products-pagination-wrap'
											spacing={2}
										>
											<Pagination
												count={
													productsCount % 50 === 0
														? productsCount / 50
														: Math.ceil(productsCount / 50)
												}
												page={page}
												onChange={(e, num) => handleSetPage(num)}
											/>
										</Stack>
									)}
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default CreatingCollectionView;
