import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	productsFilter: [],
	categoriesShop: [],
	selectCategory: {},
	shoppingProducts: [],
	isOrderPlaced: false,
	searchProducts: '',
	selectBlock: {},
	lastUpdatedHomeView: '',
	banners: [],
	blocks: [],
	headerLinks: {},
	seo: {},
	likeProducts: [],
	utm_source: '',
	utm_medium: '',
	utm_campaign: '',
	isSearchProduct: false,
	isHiddenScroll: false,
	isViewPreloader: true,
	isOpenSelect: false,
	scrollPositionBeforeView: {},
	isOpenMenu: false,
	scrollPosition: 0,
	isScrollSlider: false,
	newModalScrollPosition: 0,
	oldModalScrollPosition: 0,
	isSuccessFormOrderView: false,
	isSuccessFormPartnersView: false,
	isOpenShoppingCart: { isOpen: false, isOpenByButton: false },
	modalShoppingScrollPosition: 0,
	isSuccessCheckoutView: false,
	isOld_idUrl: true,
};

export const homeSlice = createSlice({
	name: 'homeSlice',
	initialState,
	reducers: {
		getCategoriesShop: (state, action) => {
			state.categoriesShop = action.payload;
		},
		getProductsFilter: (state, action) => {
			state.productsFilter = action.payload;
		},
		setSelectCategory: (state, action) => {
			state.selectCategory = action.payload;
		},
		setShoppingProducts: (state, action) => {
			state.shoppingProducts = action.payload;
			localStorage.setItem(
				'localShoppingProducts',
				JSON.stringify(action.payload)
			);
		},
		deleteShoppingProducts: (state, action) => {
			let res = [
				...state.shoppingProducts.filter((el) => el._id !== action.payload),
			];
			state.shoppingProducts = res;
			localStorage.setItem('localShoppingProducts', JSON.stringify(res));
		},
		setIsOrderPlaced: (state, action) => {
			state.isOrderPlaced = action.payload;
		},
		setSearchProducts: (state, action) => {
			state.searchProducts = action.payload;
		},
		setSelectBlock: (state, action) => {
			state.selectBlock = action.payload;
		},
		setLastUpdatedHomeView: (state, action) => {
			state.lastUpdatedHomeView = action.payload;
		},
		setBanners: (state, action) => {
			state.banners = action.payload;
		},
		setBlocks: (state, action) => {
			state.blocks = action.payload;
		},
		setSeo: (state, action) => {
			state.seo = action.payload;
		},
		setHeaderLinks: (state, action) => {
			state.headerLinks = action.payload;
		},
		setLikeProducts: (state, action) => {
			state.likeProducts = action.payload;
		},
		setUtm_source: (state, action) => {
			state.utm_source = action.payload;
		},
		setUtm_medium: (state, action) => {
			state.utm_medium = action.payload;
		},
		setUtm_campaign: (state, action) => {
			state.utm_campaign = action.payload;
		},
		setIsSearchProduct: (state, action) => {
			state.isSearchProduct = action.payload;
		},
		setIsHiddenScroll: (state, action) => {
			state.isHiddenScroll = action.payload;
		},
		setIsViewPreloader: (state, action) => {
			state.isViewPreloader = action.payload;
		},
		setIsOpenSelect: (state, action) => {
			state.isOpenSelect = action.payload;
		},
		setScrollPosition: (state, action) => {
			state.scrollPosition = action.payload;
		},
		setIsOpenMenu: (state, action) => {
			state.isOpenMenu = action.payload;
		},
		setIsScrollSlider: (state, action) => {
			state.isScrollSlider = action.payload;
		},
		setOldModalScrollPosition: (state, action) => {
			state.oldModalScrollPosition = action.payload;
		},
		setIsSuccessFormOrderView: (state, action) => {
			state.isSuccessFormOrderView = action.payload;
		},
		setIsSuccessFormPartnersView: (state, action) => {
			state.isSuccessFormPartnersView = action.payload;
		},
		setIsOpenShoppingCart: (state, action) => {
			state.isOpenShoppingCart = {
				isOpen: action.payload.isOpen,
				isOpenByButton: action.payload.isOpenByButton,
			};
		},
		setModalShoppingScrollPosition: (state, action) => {
			state.modalShoppingScrollPosition = action.payload;
		},
		setIsSuccessCheckoutView: (state, action) => {
			state.isSuccessCheckoutView = action.payload;
		},
		setIsOld_idUrl: (state, action) => {
			state.isOld_idUrl = action.payload;
		},
		setScrollPositionBeforeView: (state, action) => {
			state.scrollPositionBeforeView = action.payload;
		},
	},
});

export const {
	setScrollPositionBeforeView,
	setIsViewPreloader,
	setIsOld_idUrl,
	setIsSuccessCheckoutView,
	setModalShoppingScrollPosition,
	setIsOpenShoppingCart,
	setIsSuccessFormPartnersView,
	setIsSuccessFormOrderView,
	setOldModalScrollPosition,
	setIsScrollSlider,
	setIsOpenMenu,
	setScrollPosition,
	setIsOpenSelect,
	setIsHiddenScroll,
	setIsSearchProduct,
	setUtm_medium,
	setUtm_source,
	setUtm_campaign,
	setLikeProducts,
	setHeaderLinks,
	setSeo,
	setBlocks,
	setBanners,
	setLastUpdatedHomeView,
	setSelectBlock,
	setSearchProducts,
	setIsOrderPlaced,
	deleteShoppingProducts,
	setShoppingProducts,
	setSelectCategory,
	getCategoriesShop,
	getProductsFilter,
} = homeSlice.actions;

export default homeSlice.reducer;
