import './UserRequestsView.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { fetchGetData, formatPhoneNumber, handlePriceReplace, validNumber } from '../../helpers/Utils';
import { Box, Button, Menu, MenuItem, Modal, Pagination, Popover, Select, Slider, Typography } from '@mui/material';
import OrderStatus from '../../components/OrderStatus/OrderStatus';
import { getOrders, setIsHiddenScroll } from '../../store/userSlice';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 
import search3 from './../../assets/search3.svg';
import filterImg from './../../assets/filterImg.svg';
import close2 from './../../assets/close2.svg';
import closeImg from './../../assets/closeImg.svg';
import requestsImg from './../../assets/requestsImg.svg';
import closeImgBlack from './../../assets/closeImgBlack.svg';
import filterProductsImg from './../../assets/filterProductsImg.svg';
import { orderStatusArr } from '../../helpers/Config';
import ukLocale from 'date-fns/locale/uk';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function UserRequestsView({isRequestsView=true}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const newSearchParams = new URLSearchParams(searchParams);
    const requestsStatusSearchParams = JSON.parse(searchParams.get('requestsStatus'))
    const valueSortPriceSearchParams = JSON.parse(searchParams.get('valueSortPrice'))
    const startDateSearchParams = JSON.parse(searchParams.get('startDate'))
    const endDateSearchParams = JSON.parse(searchParams.get('endDate'))
    const vendorIdSearchParams = searchParams.get('vendorId')
    const sortPriceSearchParams = searchParams.get('sortPrice')
    const searchProductsSearchParams = searchParams.get('searchProducts')
    const pageSearchParams = Number(searchParams.get('page'))
    const requestTypeSearchParams = searchParams.get('requestType')

    const vendors = useSelector(state => state.userSlice.vendors);
    const orders = useSelector(state => state.userSlice.orders);
    const searchOrders = useSelector(state => state.userSlice.searchOrders);
    const isOpenMenu = useSelector(state => state.userSlice.isOpenMenu);
    const windowWidth = useSelector(state => state.userSlice.windowWidth);
    const [vendorId, setVendorId] = useState(vendorIdSearchParams?.length ? vendorIdSearchParams : '');
    const [ordersPageCount, setOrdersPageCount] = useState(0);
    const [page, setPage] = useState(pageSearchParams ? pageSearchParams : 1);
    const [sortPrice, setSortPrice] = useState(sortPriceSearchParams?.length ? sortPriceSearchParams : '');
    const [requestType, setRequestType] = useState(requestTypeSearchParams?.length ? requestTypeSearchParams : '');
    const [searchProducts, setSearchProducts] = useState(!!searchProductsSearchParams?.length ? searchProductsSearchParams : '');
    const [productTitle, setProductTitle] = useState(searchProductsSearchParams?.length ? searchProductsSearchParams : '');
    const [valueSortPrice, setValueSortPrice] = useState((valueSortPriceSearchParams?.length && valueSortPriceSearchParams[1] !== 0) ? valueSortPriceSearchParams : [0, 0]);
    const [anchorVendorId, setAnchorVendorId] = useState(null);
    const [anchorSortPrice, setAnchorSortPrice] = useState(null);
    const [anchorElPrice, setAnchorElPrice] = useState(null);
    const [anchorRequestType, setAnchorRequestType] = useState(null);
    const [anchorElDate, setAnchorElDate] = useState(null);
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [isPreloader, setIsPreloader] = useState(true);
    const [requestsStatus, setRequestsStatus] = useState(requestsStatusSearchParams?.length ? requestsStatusSearchParams : []);
    const [stateDate, setStateDate] = useState([
        {
            startDate: startDateSearchParams ? new Date(startDateSearchParams) : null,
            endDate: endDateSearchParams ? new Date(endDateSearchParams) : null,
            key: 'selection'
        }
    ]);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isXiaomiRedmi = /xiaomi/i.test(navigator.userAgent);
    const [isNotFirstRender, setIsNotFirstRender] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenSelectOrderStatus, setIsOpenSelectOrderStatus] = useState(false);
    const orderWrapRef = useRef(null);
    const open = Boolean(anchorEl);
    const openId = open ? 'simple-popover' : undefined;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(() => {
        setIsNotFirstRender(true)
        
        handleGetOrders()

        window.addEventListener('scroll', handleScroll, { passive: true });
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    const handleScroll = () => {
        setAnchorVendorId(null)
        setAnchorRequestType(null)
        setAnchorSortPrice(null)
        setAnchorElPrice(null)
        setAnchorElDate(null)
        setIsOpenSelectOrderStatus(false)
    };

    useEffect(() => {
        if (isNotFirstRender) {
            handleGetOrders()
        }
    }, [location])
    
    useEffect(() => {
        if (!searchProducts?.length && isNotFirstRender) {
            setProductTitle('')
            setPage(1)
            newSearchParams.set('searchProducts', '');
            newSearchParams.set('page', 1);
            setSearchParams(newSearchParams);
        }
    }, [searchProducts])

    useEffect(() => {
        if (searchOrders?.length && searchOrders !== searchProducts && isNotFirstRender) {
            setSearchProducts(searchOrders)
            setPage(1)
            newSearchParams.set('searchProducts', searchOrders);
            newSearchParams.set('page', 1);
            setSearchParams(newSearchParams);
        }
    }, [searchOrders])

    const handlePagination = (e, num) => {
        setPage(num)
        newSearchParams.set('page', num);
        setSearchParams(newSearchParams);
    }

    const handleDelValueSortPrice = () => {
        setValueSortPrice([0, 0])
    }

    const handleSortCleanDate = () => {
        if (stateDate[0].startDate !== null) {
            setAnchorElDate(null)
            setStateDate([{
                startDate: null,
                endDate: null,
                key: 'selection'
            }])
            newSearchParams.set('startDate', JSON.stringify(null));
            newSearchParams.set('endDate', JSON.stringify(null));
            setPage(1)
            newSearchParams.set('page', 1);
            setSearchParams(newSearchParams);
        }
    }

    const handleSortDate = () => {
        if (stateDate[0].startDate) {
            setAnchorElDate(null)
            newSearchParams.set('startDate', JSON.stringify(stateDate[0].startDate));
            newSearchParams.set('endDate', JSON.stringify(stateDate[0].endDate));
            setPage(1)
            newSearchParams.set('page', 1);
            setSearchParams(newSearchParams);
        }
    }

    const handleGetOrders = () => {
        setIsPreloader(true)
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/orders/all?page=${pageSearchParams ? pageSearchParams - 1 : page - 1}&request_type=${requestTypeSearchParams?.length ? requestTypeSearchParams : requestType}&price=${sortPriceSearchParams?.length ? sortPriceSearchParams : sortPrice}&status=${JSON.stringify(requestsStatusSearchParams?.length ? requestsStatusSearchParams : requestsStatus)}&vendor_id=${vendorIdSearchParams?.length ? vendorIdSearchParams : vendorId}&min_date=${stateDate[0].startDate ? stateDate[0].startDate : ''}&max_date=${stateDate[0].endDate ? stateDate[0].endDate : ''}&min_price=${valueSortPriceSearchParams?.length ? valueSortPriceSearchParams[0] : valueSortPrice[0]}&max_price=${valueSortPriceSearchParams?.length ? valueSortPriceSearchParams[1] : valueSortPrice[1]}&title=${!!searchProductsSearchParams?.length ? searchProductsSearchParams : productTitle}&is_preorder=${isRequestsView}`)
            .then(res => {
                if (res.success && res.data) {
                    dispatch(getOrders(res.data))
                    setOrdersPageCount(res.count % 25 === 0 ? res.count / 25 : Math.ceil(res.count / 25))
                } else {
                    console.log('GET UserOrderView', res)
                }
                setIsPreloader(false)
            })
    }
    
    const handleOpenOrder = (id, boolean) => {
        if (boolean && windowWidth < 768) {
            navigate(`${id}`)
        } 
        if (!boolean && windowWidth > 768) {
            navigate(`${id}`)
        } 
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleSelectRequestType = (str) => {
        setRequestType(str)
        setAnchorRequestType(null)
        newSearchParams.set('requestType', str);
        setPage(1)
        newSearchParams.set('page', 1);
        setSearchParams(newSearchParams);
    }
    const handleSelectVendorId = (id) => {
        setVendorId(id)
        setAnchorVendorId(null)
        newSearchParams.set('vendorId', id);
        setPage(1)
        newSearchParams.set('page', 1);
        setSearchParams(newSearchParams);
    }

    const handleSelectSortPrice = (str) => {
        setSortPrice(str)
        setAnchorSortPrice(null)
        newSearchParams.set('sortPrice', str);
        setPage(1)
        newSearchParams.set('page', 1);
        setSearchParams(newSearchParams);
    }
    const handleChange = (event, newValue) => {
        setValueSortPrice(newValue);
    };

    const handleSortPrice = () => {
        setAnchorElPrice(null);
        if (valueSortPrice[1] < valueSortPrice[0]) {
            setValueSortPrice([valueSortPrice[0], valueSortPrice[0]])
        }
        newSearchParams.set('valueSortPrice', JSON.stringify(valueSortPrice));
        setPage(1)
        newSearchParams.set('page', 1);
        setSearchParams(newSearchParams);
    }

    const handleDeleteSortPrice = (str) => {
        if (str === 'min') {
            setValueSortPrice([0, valueSortPrice[1]]);
        } else if (str === 'max') {
            setValueSortPrice([valueSortPrice[0], valueSortPrice[0]]);
        }
    }
    
    const handleSortByMinPrice = (num) => {
        if (num.startsWith('0')) {
            validNumber(num.slice(1), (price) => setValueSortPrice([price === '' ? 0 : +price , valueSortPrice[1]]))
        } else {
            validNumber(num, (price) => setValueSortPrice([price === '' ? 0 : +price , valueSortPrice[1]]))
        }
    }
    
    const handleSortByMaxPrice = (num) => {
        if (num.startsWith('0')) {
            validNumber(num.slice(1), (price) => setValueSortPrice([valueSortPrice[0], price === '' ? 0 : +price ]))
        } else {
            validNumber(num, (price) => setValueSortPrice([valueSortPrice[0], price === '' ? 0 : +price ]))
        }
    }

    const handleKeyDown = (e) => {
        if(e.key == 'Enter') {
            setProductTitle(searchProducts)
            setPage(1)
            newSearchParams.set('searchProducts', searchProducts);
            newSearchParams.set('page', 1);
            setSearchParams(newSearchParams);
        }
    }

    const handleSetStatus = (value) => {
        let res = JSON.parse(value)
        setRequestsStatus(res)
        newSearchParams.set('requestsStatus', JSON.stringify(res));
        setPage(1)
        newSearchParams.set('page', 1);
        setSearchParams(newSearchParams);
    }
    
    const handleSearchByName = () => {
        setProductTitle(searchProducts)
        setPage(1)
        newSearchParams.set('searchProducts', searchProducts);
        newSearchParams.set('page', 1);
        setSearchParams(newSearchParams);
    }

    const handleFormatDate = useMemo(() => (str) => {
        let date = new Date(str);
        let day = date.getDate().toString().padStart(2, '0');
        let month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        let year = date.getFullYear();
        let hours = date.getHours().toString().padStart(2, '0');
        let minutes = date.getMinutes().toString().padStart(2, '0');

        return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes;
    } , [orders]);

    const handleOpenShoppingCart = () => {
        setIsOpenFilter(true)
        dispatch(setIsHiddenScroll(true))
    }

    const handleCloseModal = () => {
        setIsOpenFilter(false)
        dispatch(setIsHiddenScroll(false))
    }
    const handleDeleteModalFilterBy = () => {
        setRequestType('')
        setVendorId('')
        setRequestsStatus([])
        setSearchProducts('')
        setValueSortPrice([0, 0])
        setStateDate([{
            startDate: null,
            endDate: null,
            key: 'selection'
          }])

        navigate(window.location.pathname);
    }
    
    return (
        <div className="user-requests">
            <SeoBlock title={"Запити"} description={""} keywords={""}/>
            <Modal
                open={isOpenFilter}
                onClose={handleCloseModal}
            >
                <div className={`catalog-view__modal-filter-wrap ${isSafari || isXiaomiRedmi ? 'catalog-view__modal-filter-is-safari-wrap' : ''}`}>
                    <div className="catalog-view__modal-close" onClick={handleCloseModal}></div>
                    <div className="catalog-view__modal-filter-header">
                        <div className="catalog-view__modal-filter-header-text">Фільтр і сортування</div>
                        <div className='user-requests__modal-filter-btn-close-wrap' onClick={handleCloseModal}>
                            <img className="user-requests__modal-filter-btn-close" src={close2} alt='img'/>
                        </div>
                    </div>

                    <div className="user-requests__modal-filter">
                        <div className="user-products-filter__filter-wrap">
                            <p className="user-products-filter__filter-lable">Виробник</p>
                            <Button
                                aria-haspopup="true"
                                onClick={(e) => setAnchorVendorId(e.currentTarget)}
                                className='user-products-filter__filter-btn--wrap'
                                disableRipple={true}
                                >
                                <div className='user-products-filter__filter-btn-wrap'>
                                    <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img'/>
                                    <p className='user-products-filter__filter-btn-text'>{!!vendorId?.length && vendors?.length ? vendors.find(el => el._id === vendorId)?.company_name : 'Без сортування'}</p>
                                </div>
                            </Button>
                            <Menu
                                anchorEl={anchorVendorId}
                                open={Boolean(anchorVendorId)}
                                onClose={() => setAnchorVendorId(null)}
                            >
                                <MenuItem disableRipple={true} className="user-requests__modal-filter-menu-item" onClick={() => handleSelectVendorId('')} >Без сортування</MenuItem>
                                {
                                    !!vendors?.length && vendors.map(el => (
                                            <MenuItem disableRipple={true} className="user-requests__modal-filter-menu-item" value={el._id} onClick={() => handleSelectVendorId(el._id)} key={el._id}>{el.company_name}</MenuItem>
                                        ))
                                }
                            </Menu>
                        </div>
                        <div className="user-products-filter__filter-wrap">
                            <p className="user-products-filter__filter-lable">Сортування</p>
                            <Button
                                aria-haspopup="true"
                                onClick={(e) => setAnchorSortPrice(e.currentTarget)}
                                className='user-products-filter__filter-btn--wrap'
                                disableRipple={true}
                                >
                                <div className='user-products-filter__filter-btn-wrap'>
                                    <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img'/>
                                    <p className='user-products-filter__filter-btn-text'>{sortPrice === '1' ? 'По зростанню' : sortPrice === '-1' ? 'По спаданню' : 'Без сортування'}</p>
                                </div>
                            </Button>
                            <Menu
                                anchorEl={anchorSortPrice}
                                open={Boolean(anchorSortPrice)}
                                onClose={() => setAnchorSortPrice(null)}
                            >
                                <MenuItem className="user-requests__modal-filter-menu-item" onClick={() => handleSelectSortPrice('')} disableRipple={true}>Без сортування</MenuItem>
                                <MenuItem className="user-requests__modal-filter-menu-item" onClick={() => handleSelectSortPrice('1')} disableRipple={true}>По зростанню</MenuItem>
                                <MenuItem className="user-requests__modal-filter-menu-item" onClick={() => handleSelectSortPrice('-1')} disableRipple={true}>По спаданню</MenuItem>
                            </Menu>
                        </div>
                        <div className="user-products-filter__filter-wrap">
                            <p className="user-products-filter__filter-lable">Сума</p>
                            <Button
                                aria-haspopup="true"
                                onClick={(e) => setAnchorElPrice(e.currentTarget)}
                                className='user-products-filter__filter-btn--wrap'
                                disableRipple={true}
                                >
                                <div className='user-products-filter__filter-btn-wrap'>
                                    <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img'/>
                                    <p className='user-products-filter__filter-btn-text'>{valueSortPrice[1] !== 0 ? `${valueSortPrice[0]} - ${valueSortPrice[1]} грн` : 'Без сортування'}</p>
                                </div>
                            </Button>
                            <Menu
                                anchorEl={anchorElPrice}
                                open={Boolean(anchorElPrice)}
                                onClose={() => setAnchorElPrice(null)}
                            >
                                <MenuItem disableRipple={true}>
                                    <div className="user-products-filter__select-sort-price-wrap">
                                        <Box>
                                            <Slider
                                                value={valueSortPrice}
                                                onChange={handleChange}
                                                valueLabelDisplay="auto"
                                                max={200000}
                                                min={0}
                                                className="user-products-filter__products-filter-slider"
                                            />
                                        </Box>
                                        <div className="user-products-filter__products-filter-price-result-wrap">
                                            <div className="user-products-filter__products-filter-price-result--wrap">
                                                <div className="user-products-filter__products-filter-price-result-title">Мінімальна</div>
                                                <img className="user-products-filter__products-filter-price-result-btn" onClick={() => handleDeleteSortPrice('min')} src={closeImg} alt='img'/>
                                                <input className="user-products-filter__products-filter-price-result" value={valueSortPrice[0]} onChange={(e) => handleSortByMinPrice(e.target.value)} />
                                            </div>
                                            <div className="user-products-filter__products-filter-price-result-line"></div>
                                            <div className="user-products-filter__products-filter-price-result--wrap">
                                                <div className="user-products-filter__products-filter-price-result-title">Максимальна</div>
                                                <img className="user-products-filter__products-filter-price-result-btn" onClick={() => handleDeleteSortPrice('max')} src={closeImg} alt='img'/>
                                                <input className="user-products-filter__products-filter-price-result" value={valueSortPrice[1]} onChange={(e) => handleSortByMaxPrice(e.target.value)} />
                                            </div>
                                            <div className='user-requests__filter-btn-del-wrpa'>
                                                <button className='user-requests__filter-btn-del' onClick={handleDelValueSortPrice}>Очистити</button>
                                                <button className='user-requests__filter-btn' onClick={handleSortPrice}>Показати</button>
                                            </div>
                                        </div>    
                                    </div>
                                </MenuItem>
                            </Menu>
                        </div>
                        
                        <div className="user-products-filter__filter-wrap">
                            <p className="user-products-filter__filter-lable">Дата</p>
                            <Button
                                aria-haspopup="true"
                                onClick={(e) => setAnchorElDate(e.currentTarget)}
                                className='user-products-filter__filter-btn--wrap'
                                disableRipple={true}
                                >
                                <div className='user-products-filter__filter-btn-wrap'>
                                    <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img'/>
                                    <div className='user-products-filter__filter-btn-text'>
                                        {
                                            stateDate[0]?.startDate ? 
                                                <div className='user-requests__filter-date-select'>
                                                    <div>{stateDate[0]?.startDate ? new Date(stateDate[0]?.startDate).toLocaleString().split(',')[0] : 'дд.мм.рррр'}</div>
                                                    <div>/</div>
                                                    <div>{stateDate[0]?.endDate ? new Date(stateDate[0]?.endDate).toLocaleString().split(',')[0] : 'дд.мм.рррр'}</div>
                                                </div>  
                                                : <div>Без сортування</div>
                                        }
                                    </div>
                                </div>
                            </Button>
                            <Menu
                                anchorEl={anchorElDate}
                                open={Boolean(anchorElDate)}
                                onClose={() => setAnchorElDate(null)}
                            >
                                <MenuItem disableRipple={true}>
                                    <div className="user-products-filter__select-wrap">
                                        <DateRange
                                            editableDateInputs={true}
                                            onChange={item => setStateDate([item.selection])}
                                            moveRangeOnFirstSelection={false}
                                            ranges={stateDate}
                                            weekStartsOn={1}
                                            locale={ukLocale}
                                        />
                                        <div className='user-requests__filter-date-btn-wrap'>
                                            <button className='user-requests__filter-btn-del' onClick={handleSortCleanDate}>Очистити</button>
                                            <button className='user-requests__filter-btn' onClick={handleSortDate}>Показати</button>
                                        </div>
                                    </div>
                                </MenuItem>
                            </Menu>
                        </div>
                        <div className="user-products-filter__filter-wrap">
                            <p className="user-products-filter__filter-lable">Тип запиту</p>
                            <Button
                                aria-haspopup="true"
                                onClick={(e) => setAnchorRequestType(e.currentTarget)}
                                className='user-products-filter__filter-btn--wrap'
                                disableRipple={true}
                                >
                                <div className='user-products-filter__filter-btn-wrap'>
                                    <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img'/>
                                    <p className='user-products-filter__filter-btn-text'>{requestType === 'question' ? 'Задати питання' : requestType === 'adapt' ? 'Адаптувати' : 'Без сортування'}</p>
                                </div>
                            </Button>
                            <Menu
                                anchorEl={anchorRequestType}
                                open={Boolean(anchorRequestType)}
                                onClose={() => setAnchorRequestType(null)}
                            >
                                <MenuItem className="user-requests__modal-filter-menu-item" onClick={() => handleSelectRequestType('')} disableRipple={true}>Без сортування</MenuItem>
                                <MenuItem className="user-requests__modal-filter-menu-item" onClick={() => handleSelectRequestType('question')} disableRipple={true}>Задати питання</MenuItem>
                                <MenuItem className="user-requests__modal-filter-menu-item" onClick={() => handleSelectRequestType('adapt')} disableRipple={true}>Адаптувати</MenuItem>
                            </Menu>
                        </div>
                    </div>
                    
                    <div className="catalog-view__modal-filter-btn-wrap">   
                        <button className='main-btn-2 catalog-view__modal-filter-btn' onClick={handleDeleteModalFilterBy}>Очистити фільтр</button>
                        <button className='main-btn-1 catalog-view__modal-filter-btn' onClick={handleCloseModal}>Показати</button>
                    </div>
                </div>
            </Modal>

            <div className="user-requests__header">
                <div className="user-requests__header-title-wrap">
                    <img className={`user-requests__header-title-img ${isRequestsView ? 'user-requests__header-title-img--is-requests' : ''}`} src={requestsImg} alt='img'/>
                    <h1 className="user-requests__header-title">Запити</h1>
                </div>
                <div className="user-requests__text-tab--wrap">
                    <div className={`user-requests__text-tab-wrap ${!isOpenMenu ? 'user-requests__text-tab-wrap--is-open-menu' : 'user-requests__text-tab-wrap--is-close-menu'}`}>
                        <button className={`user-requests__text-tab ${!requestsStatus?.length ? 'user-requests__text-tab-active' : ''}`} onClick={() => handleSetStatus(JSON.stringify([]))}>Всі</button>
                        <button className={`user-requests__text-tab ${requestsStatus[0] === 'pending' ? 'user-requests__text-tab-active' : ''}`} onClick={() => handleSetStatus(JSON.stringify(['pending']))}>В очікуванні</button>
                        <button className={`user-requests__text-tab ${requestsStatus[0] === 'calculation' ? 'user-requests__text-tab-active' : ''}`} onClick={() => handleSetStatus(JSON.stringify(['calculation']))}>Переговори</button>
                        <button className={`user-requests__text-tab ${requestsStatus[0] === 'in_progress' ? 'user-requests__text-tab-active' : ''}`} onClick={() => handleSetStatus(JSON.stringify(['in_progress']))}>В роботі</button>
                        <button className={`user-requests__text-tab ${requestsStatus[0] === 'completed' ? 'user-requests__text-tab-active' : ''}`} onClick={() => handleSetStatus(JSON.stringify(['completed']))}>Виконано</button>
                        <button className={`user-requests__text-tab ${requestsStatus[0] === 'cancelled' ? 'user-requests__text-tab-active' : ''}`} onClick={() => handleSetStatus(JSON.stringify(['cancelled']))}>Скасовано</button>
                    </div>
                    <Select 
                        value={JSON.stringify(requestsStatus)}
                        className='product-view__variations-select user-requests__order-select-status' 
                        onChange={(e) => handleSetStatus(e.target.value)} 
                        open={isOpenSelectOrderStatus}
                        onClose={() => setIsOpenSelectOrderStatus(false)}
                        onOpen={() => setIsOpenSelectOrderStatus(true)}
                    >
                        <MenuItem className={`product-view__variations-select-item ${!requestsStatus?.length ? 'product-view__variations-select-item-active' : ''}`} value={JSON.stringify([])}>Всі</MenuItem>
                        <MenuItem className={`product-view__variations-select-item ${requestsStatus[0] === 'pending' ? 'product-view__variations-select-item-active' : ''}`} value={JSON.stringify(['pending'])}>В очікуванні</MenuItem>
                        <MenuItem className={`product-view__variations-select-item ${requestsStatus[0] === 'calculation' ? 'product-view__variations-select-item-active' : ''}`} value={JSON.stringify(['calculation'])}>Переговори</MenuItem>
                        <MenuItem className={`product-view__variations-select-item ${requestsStatus[0] === 'in_progress' ? 'product-view__variations-select-item-active' : ''}`} value={JSON.stringify(['in_progress'])}>В роботі</MenuItem>
                        <MenuItem className={`product-view__variations-select-item ${requestsStatus[0] === 'completed' ? 'product-view__variations-select-item-active' : ''}`} value={JSON.stringify(['completed'])}>Виконано</MenuItem>
                        <MenuItem className={`product-view__variations-select-item ${requestsStatus[0] === 'cancelled' ? 'product-view__variations-select-item-active' : ''}`} value={JSON.stringify(['cancelled'])}>Скасовано</MenuItem>
                    </Select>
                </div>
            </div>

            {
                isPreloader ? <PreloaderCustom />
                    :
                    <>
                        <button className="user-requests__mobile-filter-btn" >
                            <div className="user-requests__mobile-filter-btn--wrap" onClick={handleOpenShoppingCart}>
                                <img className="catalog-view__mobile-filter-btn-img" src={filterImg} alt='img'/>
                                <div className="user-requests__mobile-filter-btn-text">Фільтр</div> 
                            </div>
                            <div className="user-requests__mobile-filter-wrap">
                                <div className='user-products-filter__search-wrap'>
                                    <input className='user-requests__search-input' value={searchProducts} onChange={(e) => setSearchProducts(e.target.value)} onKeyDown={(e) => handleKeyDown(e)} placeholder={'Пошук по товару'} />
                                    <img className='user-products-filter__search-img' onClick={handleSearchByName} src={search3} alt='img'/>
                                    {
                                        !!searchProducts?.length &&
                                            <img className='layout-user__header-search-btn-close' onClick={() => setSearchProducts('')} src={closeImgBlack} alt='img'/>
                                    }
                                </div>
                            </div>
                        </button>
                        <div className={`user-requests__orders-wrap ${!isOpenMenu ? 'user-requests__orders--is-open-menu' : 'user-requests__orders--is-close-menu'}`}>
                            <div className={`user-requests__orders `}>
                                <div className="user-requests__filter-wrap">
                                    <div className="user-products-filter__filter-wrap">
                                        <p className="user-products-filter__filter-lable">Виробник</p>
                                        <Button
                                            aria-haspopup="true"
                                            onClick={(e) => setAnchorVendorId(e.currentTarget)}
                                            className='user-products-filter__filter-btn--wrap'
                                            disableRipple={true}
                                            >
                                            <div className='user-products-filter__filter-btn-wrap'>
                                                <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img'/>
                                                <p className='user-products-filter__filter-btn-text'>{!!vendorId?.length && vendors?.length ? vendors.find(el => el._id === vendorId).company_name : 'Без сортування'}</p>
                                            </div>
                                        </Button>
                                        <Menu
                                            anchorEl={anchorVendorId}
                                            open={Boolean(anchorVendorId)}
                                            onClose={() => setAnchorVendorId(null)}
                                        >
                                            <MenuItem className="user-requests__modal-filter-menu-item" onClick={() => handleSelectVendorId('')} disableRipple={true}>Без сортування</MenuItem>
                                            {
                                                !!vendors?.length && vendors.map(el => (
                                                        <MenuItem className="user-requests__modal-filter-menu-item" value={el._id} onClick={() => handleSelectVendorId(el._id)} disableRipple={true} key={el._id}>{el.company_name}</MenuItem>
                                                    ))
                                            }
                                        </Menu>
                                    </div>
                                    <div className="user-products-filter__filter-wrap">
                                        <p className="user-products-filter__filter-lable">Сортування</p>
                                        <Button
                                            aria-haspopup="true"
                                            onClick={(e) => setAnchorSortPrice(e.currentTarget)}
                                            className='user-products-filter__filter-btn--wrap'
                                            disableRipple={true}
                                            >
                                            <div className='user-products-filter__filter-btn-wrap'>
                                                <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img'/>
                                                <p className='user-products-filter__filter-btn-text'>{sortPrice === '1' ? 'По зростанню' : sortPrice === '-1' ? 'По спаданню' : 'Без сортування'}</p>
                                            </div>
                                        </Button>
                                        <Menu
                                            anchorEl={anchorSortPrice}
                                            open={Boolean(anchorSortPrice)}
                                            onClose={() => setAnchorSortPrice(null)}
                                        >
                                            <MenuItem className="user-requests__modal-filter-menu-item" onClick={() => handleSelectSortPrice('')} disableRipple={true}>Без сортування</MenuItem>
                                            <MenuItem className="user-requests__modal-filter-menu-item" onClick={() => handleSelectSortPrice('1')} disableRipple={true}>По зростанню</MenuItem>
                                            <MenuItem className="user-requests__modal-filter-menu-item" onClick={() => handleSelectSortPrice('-1')} disableRipple={true}>По спаданню</MenuItem>
                                        </Menu>
                                    </div>
                                    <div className="user-products-filter__filter-wrap">
                                        <p className="user-products-filter__filter-lable">Сума</p>
                                        <Button
                                            aria-haspopup="true"
                                            onClick={(e) => setAnchorElPrice(e.currentTarget)}
                                            className='user-products-filter__filter-btn--wrap'
                                            disableRipple={true}
                                            >
                                            <div className='user-products-filter__filter-btn-wrap'>
                                                <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img'/>
                                                <p className='user-products-filter__filter-btn-text'>{valueSortPrice[1] !== 0 ? `${valueSortPrice[0]} - ${valueSortPrice[1]} грн` : 'Без сортування'}</p>
                                            </div>
                                        </Button>
                                        <Menu
                                            anchorEl={anchorElPrice}
                                            open={Boolean(anchorElPrice)}
                                            onClose={() => setAnchorElPrice(null)}
                                        >
                                            <MenuItem disableRipple={true}>
                                                <div className="user-products-filter__select-sort-price-wrap">
                                                    <Box>
                                                        <Slider
                                                            value={valueSortPrice}
                                                            onChange={handleChange}
                                                            valueLabelDisplay="auto"
                                                            max={200000}
                                                            min={0}
                                                            className="user-products-filter__products-filter-slider"
                                                            disableRipple={true}
                                                        />
                                                    </Box>
                                                    <div className="user-products-filter__products-filter-price-result-wrap">
                                                        <div className="user-products-filter__products-filter-price-result--wrap">
                                                            <div className="user-products-filter__products-filter-price-result-title">Мінімальна</div>
                                                            <img className="user-products-filter__products-filter-price-result-btn" onClick={() => handleDeleteSortPrice('min')} src={closeImg} alt='img'/>
                                                            <input className="user-products-filter__products-filter-price-result" min={0} value={valueSortPrice[0]} onChange={(e) => handleSortByMinPrice(e.target.value)} />
                                                        </div>
                                                        <div className="user-products-filter__products-filter-price-result-line"></div>
                                                        <div className="user-products-filter__products-filter-price-result--wrap">
                                                            <div className="user-products-filter__products-filter-price-result-title">Максимальна</div>
                                                            <img className="user-products-filter__products-filter-price-result-btn" onClick={() => handleDeleteSortPrice('max')} src={closeImg} alt='img'/>
                                                            <input className="user-products-filter__products-filter-price-result" value={valueSortPrice[1]} onChange={(e) => handleSortByMaxPrice(e.target.value)} />
                                                        </div>
                                                        <div className='user-requests__filter-btn-del-wrpa'>
                                                            <button className='user-requests__filter-btn-del' onClick={handleDelValueSortPrice}>Очистити</button>
                                                            <button className='user-requests__filter-btn' onClick={handleSortPrice}>Показати</button>
                                                        </div>
                                                    </div>    
                                                </div>
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                    
                                    <div className="user-products-filter__filter-wrap">
                                        <p className="user-products-filter__filter-lable">Дата</p>
                                        <Button
                                            aria-haspopup="true"
                                            onClick={(e) => setAnchorElDate(e.currentTarget)}
                                            className='user-products-filter__filter-btn--wrap'
                                            disableRipple={true}
                                            >
                                            <div className='user-products-filter__filter-btn-wrap'>
                                                <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img'/>
                                                <div className='user-products-filter__filter-btn-text'>
                                                    {
                                                        stateDate[0]?.startDate ? 
                                                            <div className='user-requests__filter-date-select'>
                                                                <div>{stateDate[0]?.startDate ? new Date(stateDate[0]?.startDate).toLocaleString().split(',')[0] : 'дд.мм.рррр'}</div>
                                                                <div>/</div>
                                                                <div>{stateDate[0]?.endDate ? new Date(stateDate[0]?.endDate).toLocaleString().split(',')[0] : 'дд.мм.рррр'}</div>
                                                            </div>  
                                                            : <div>Без сортування</div>
                                                    }
                                                </div>
                                            </div>
                                        </Button>
                                        <Menu
                                            anchorEl={anchorElDate}
                                            open={Boolean(anchorElDate)}
                                            onClose={() => setAnchorElDate(null)}
                                        >
                                            <MenuItem disableRipple={true}>
                                                <div className="user-products-filter__select-wrap">
                                                    <DateRange
                                                        editableDateInputs={true}
                                                        onChange={item => setStateDate([item.selection])}
                                                        moveRangeOnFirstSelection={false}
                                                        ranges={stateDate}
                                                        weekStartsOn={1}
                                                        locale={ukLocale}
                                                    />
                                                    <div className='user-requests__filter-date-btn-wrap'>
                                                        <button className='user-requests__filter-btn-del' onClick={handleSortCleanDate}>Очистити</button>
                                                        <button className='user-requests__filter-btn' onClick={handleSortDate}>Показати</button>
                                                    </div>
                                                </div>
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                    <div className="user-products-filter__filter-wrap">
                                        <p className="user-products-filter__filter-lable">Тип запиту</p>
                                        <Button
                                            aria-haspopup="true"
                                            onClick={(e) => setAnchorRequestType(e.currentTarget)}
                                            className='user-products-filter__filter-btn--wrap'
                                            disableRipple={true}
                                            >
                                            <div className='user-products-filter__filter-btn-wrap'>
                                                <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img'/>
                                                <p className='user-products-filter__filter-btn-text'>{requestType === 'question' ? 'Задати питання' : requestType === 'adapt' ? 'Адаптувати' : 'Без сортування'}</p>
                                            </div>
                                        </Button>
                                        <Menu
                                            anchorEl={anchorRequestType}
                                            open={Boolean(anchorRequestType)}
                                            onClose={() => setAnchorRequestType(null)}
                                        >
                                            <MenuItem className="user-requests__modal-filter-menu-item" onClick={() => handleSelectRequestType('')} disableRipple={true}>Без сортування</MenuItem>
                                            <MenuItem className="user-requests__modal-filter-menu-item" onClick={() => handleSelectRequestType('question')} disableRipple={true}>Задати питання</MenuItem>
                                            <MenuItem className="user-requests__modal-filter-menu-item" onClick={() => handleSelectRequestType('adapt')} disableRipple={true}>Адаптувати</MenuItem>
                                        </Menu>
                                    </div>
                                    <div className="user-products-filter__filter-wrap">
                                        <p className="user-products-filter__filter-lable">Пошук</p>
                                        <div className='user-products-filter__search-wrap'>
                                            <input className='user-requests__search-input' value={searchProducts} onChange={(e) => setSearchProducts(e.target.value)} onKeyDown={(e) => handleKeyDown(e)} placeholder={'Пошук по товару'} />
                                            <img className='user-products-filter__search-img' onClick={handleSearchByName} src={search3} alt='img'/>
                                            {
                                                !!searchProducts?.length &&
                                                    <img className='layout-user__header-search-btn-close' onClick={() => setSearchProducts('')} src={closeImgBlack} alt='img'/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="user-requests__products-header--wrap">
                                    <div className="user-requests__products-header-wrap">
                                        <div className="user-requests__products-header">№ замовлення</div>
                                        <div className="user-requests__products-header-date">Дата замовлення</div>
                                        <div className="user-requests__products-header-code">Код товару</div>
                                        <div className="user-requests__products-header-name">Замовник</div>
                                        <div className="user-requests__products-header-tel">Контакти</div>
                                        <div className="user-requests__products-header-vendor">Виробник</div>
                                        <div className="user-requests__products-header-count">Сума</div>
                                        <div className="user-requests__products-header-status">Статус</div>
                                    </div>
                                </div>
                                <div className='user-requests__order-wrap' >
                                    {
                                        !!orders?.length ? orders.map(el => (
                                            <div className={`user-requests__order ${!el.is_read ? 'user-requests__notifications--unread' : ''}`} ref={orderWrapRef} key={el._id}>
                                                <div className="user-requests__order-text-wrap" onClick={() => handleOpenOrder(el._id, true)}>
                                                    <div className="user-requests__order-text user-requests__order-text-numer" onClick={() => handleOpenOrder(el._id)}>№{el.view_id}</div>
                                                    <div className="user-requests__order-text user-requests__order-text-date" onClick={() => handleOpenOrder(el._id)}>{handleFormatDate(el.created_at)}</div>
                                                    <div className="user-requests__order-text-code-wrap">
                                                        <p className="user-requests__order-text-code-title" onClick={() => handleOpenOrder(el._id)}>{el.productsCode[0]}</p>
                                                        {
                                                            el.productsCode?.length >= 2 && 
                                                                <>
                                                                    <div className="user-requests__order-text-code" aria-describedby={openId} variant="contained" onClick={handleClick}>
                                                                        {el.productsCode?.length} більше
                                                                    </div>
                                                                    <Popover
                                                                        id={openId}
                                                                        open={open}
                                                                        anchorEl={anchorEl}
                                                                        onClose={handleClose}
                                                                        anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                        }}
                                                                        disableRipple={true}
                                                                    >   
                                                                        {
                                                                            el.productsCode?.length && el.productsCode.slice(1).map((code, i) => (
                                                                                <Typography sx={{ p: 2 }} key={code + i}>{code}</Typography>
                                                                            ))
                                                                        }
                                                                    </Popover>
                                                                </>
                                                        }

                                                    </div>
                                                    <div className="user-requests__order-text--2 user-requests__order-text-title" onClick={() => handleOpenOrder(el._id)}><p className='user-requests__order-text-date-title'>Замовник</p><p>{el.full_name}</p></div>
                                                    <div className="user-requests__order-text--3 user-requests__order-text-phone" onClick={() => handleOpenOrder(el._id)}><p className='user-requests__order-text-date-title'>Контакти</p><p>{formatPhoneNumber(el.phone)}</p><p>{el.email}</p></div>
                                                    <div className="user-requests__order-text user-requests__order-text-vendor" onClick={() => handleOpenOrder(el._id)}>
                                                        <p className='user-requests__order-text-date-title'>Виробник:</p>
                                                        <p>{!!el.vendor_name?.length ? el.vendor_name : 'Не вказано'}</p>
                                                    </div>
                                                    <div className="user-requests__order-text user-requests__order-text-price" onClick={() => handleOpenOrder(el._id)}>
                                                        <p className='user-requests__order-text-date-title'>Сума:</p>
                                                        <p>{handlePriceReplace(el.total_price)} <span>{el.total_price ? 'грн' : ''}</span></p>
                                                    </div>
                                                    <div className={`user-requests__order-text user-requests__order-status user-requests__order--${el.status}`} onClick={() => handleOpenOrder(el._id)}>{orderStatusArr[el.status]}</div>
                                                </div>
                                                <div className='user-requests__order-select-wrap'>
                                                    <OrderStatus order={el}/>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div className='user-requests__order-error'>Список запитів пустий</div>
                                    }
                                </div>
                            </div>

                        </div>
                        {
                            !!orders?.length &&
                                <div className="user-requests__orders-pagination-wrap">
                                    <Pagination onChange={handlePagination} page={pageSearchParams?.length ? Number(pageSearchParams) : page} count={ordersPageCount} defaultPage={pageSearchParams?.length ? Number(pageSearchParams) : page} boundaryCount={2} />
                                </div>
                        }
                    </>
            }
            
        </div>
    );
}

export default UserRequestsView;