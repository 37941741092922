import './CatalogView.css';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import MainBanner from '../../components/MainBanner/MainBanner';
import filterImg from '../../assets/filterImg.svg';
import closeImgBlack from '../../assets/closeImgBlack.svg';
import close2 from '../../assets/close2.svg';
import ProductCard from '../../components/ProductCard/ProductCard';
import {
	fetchGetData,
	fetchRequest,
	handleCategoriesMap,
	validNumber,
	enabledScroll,
	disabledScroll,
} from '../../helpers/Utils';
import { NOT_DELETED_TREND_ALIAS } from '../../helpers/Config';
import {
	getProductsFilter,
	setIsOpenSelect,
	setSearchProducts,
	setSelectCategory,
	setSelectBlock,
} from '../../store/homeSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	FormControl,
	MenuItem,
	Modal,
	RadioGroup,
	Select,
	Skeleton,
	Slider,
} from '@mui/material';
import {
	NOT_DELETED_SUB_CATEGORY_ID,
	standardColors,
} from '../../helpers/Config';
import {
	useParams,
	useLocation,
	useSearchParams,
	useNavigate,
} from 'react-router-dom';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import SkeletonWrap from '../../components/SkeletonWrap/SkeletonWrap';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import SeoBlock from '../../components/SeoBlock/SeoBlock';
import { useScroll } from '../../hooks/useScroll';

function CatalogView() {
	const [searchParams, setSearchParams] = useSearchParams();
	const newSearchParams = new URLSearchParams(searchParams);
	const mobileFilterBySearchParams = JSON.parse(
		searchParams.get('mobileFilterBy')
	);
	const last_filter_parametrSearchParams = JSON.parse(
		searchParams.get('last_filter_parametr')
	);
	const searchProductsSearchParams = searchParams.get('searchProducts')?.length
		? decodeURIComponent(searchParams.get('searchProducts'))
		: '';
	const pageSearchParams = Number(JSON.parse(searchParams.get('page')));
	const selectSortBySearchParams = JSON.parse(searchParams.get('selectSortBy'));
	const productsFilter = useSelector((state) => state.homeSlice.productsFilter);
	const categoriesShop = useSelector((state) => state.homeSlice.categoriesShop);
	const selectCategory = useSelector((state) => state.homeSlice.selectCategory);
	const searchProducts = useSelector((state) => state.homeSlice.searchProducts);
	const selectBlock = useSelector((state) => state.homeSlice.selectBlock);
	const headerLinks = useSelector((state) => state.homeSlice.headerLinks);

	const seo = useSelector((state) => state.homeSlice.seo);
	const isOpenSelect = useSelector((state) => state.homeSlice.isOpenSelect);
	const [notBlockedFilter, setNotBlockedFilter] = useState([]);
	const [notColorsFilter, setNotColorsFilter] = useState([]);
	const [notTypeFilter, setNotTypeFilter] = useState([]);
	const [notCategoriesFilter, setNotCategoriesFilter] = useState([]);
	const [allSeeName, setAllSeeName] = useState([]);
	const [productsCount, setProductsCount] = useState(0);
	const [valueSortPrice, setValueSortPrice] = useState([0, 0]);
	const [mainMaxPrice, setMainMaxPrice] = useState(0);
	const [mobileFilterBy, setMobileFilterBy] = useState(
		mobileFilterBySearchParams?.length ? [...mobileFilterBySearchParams] : []
	);
	const [selectSortBy, setSelectSortBy] = useState(
		selectSortBySearchParams?.value?.length
			? { ...selectSortBySearchParams }
			: {}
	);
	const [isAllSeeCategories, setIsAllSeeCategories] = useState(false);
	const [isAllSeeColor, setIsAllSeeColor] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [last_filter_parametr, setLast_filter_parametr] = useState(
		last_filter_parametrSearchParams?.length
			? [...last_filter_parametrSearchParams]
			: []
	);
	const [isOpenShoppingCart, setIsOpenShoppingCart] = useState(false);
	const [isFocused1, setIsFocused1] = useState(false);
	const [isFocused2, setIsFocused2] = useState(false);
	const [isPreloader, setIsPreloader] = useState(true);
	const [scrollId, setScrollId] = useState('');
	const [openFilterArr, setOpenFilterArr] = useState([]);
	const [modalScrollPosition, setModalScrollPosition] = useState(0);
	const [productsWrapHeight, setProductsWrapHeight] = useState('');
	const [scrollPage, setScrollPage] = useState('');
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	const isXiaomiRedmi = /xiaomi/i.test(navigator.userAgent);
	let [timerId, setTimerId] = useState(null);
	let { categotyId } = useParams();
	let { subCategotyId } = useParams();
	let { trendId } = useParams();
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const productsWrapRef = useRef(null);
	const yOffset =
		!!headerLinks?.linksValue?.length && headerLinks?.isVisible ? -119 : -90; // додатковий скрол через fixed

	const { savedScrollY } = useScroll();

	useEffect(() => {
		setIsFirstRender(false);

		if (searchProductsSearchParams?.length) {
			dispatch(setSearchProducts(searchProductsSearchParams));
		}
		handleGetPrice();
		handleCloseSelect();
		window.addEventListener('resize', handleResize);
		setTimeout(() => {
			window.scroll({
				top: savedScrollY,
				behavior: 'smooth',
			});
		}, 50);

		return () => {
			window.removeEventListener('resize', handleResize);
			dispatch(setSelectBlock({}));
		};
	}, []);

	useEffect(() => {
		if (productsWrapRef.current && !isPreloader && window.innerWidth >= 960) {
			setProductsWrapHeight(productsWrapRef.current.clientHeight - 135);
		}
	});

	useEffect(() => {
		if (!isFirstRender) {
			if (!trendId?.length) {
				dispatch(setSelectBlock({}));
			}
			if (!!trendId?.length && trendId === NOT_DELETED_TREND_ALIAS) {
				dispatch(setSelectBlock({}));
			}
		}
	}, [trendId]);

	useEffect(() => {
		if (!isFirstRender) {
			handleGetPrice();
			if (!trendId?.length) {
				dispatch(setSelectBlock({}));
			}
		}
	}, [location]);

	useEffect(() => {
		setTimeout(() => {
			window.scroll({
				top: savedScrollY,
			});
		}, 10);
	}, [productsFilter]);

	const handleGetPrice = () => {
		setIsPreloader(true);
		if (subCategotyId?.length) {
			handleGetProductFilter(subCategotyId);
			return;
		}
		if (categotyId?.length && !subCategotyId?.length) {
			handleGetProductFilter(categotyId);
			return;
		}
		if (!categotyId?.length && !subCategotyId?.length) {
			handleGetProductFilter();
		}
	};

	const handleDelSearchProducts = () => {
		dispatch(setSearchProducts(''));
		newSearchParams.set('searchProducts', '');
		setSearchParams(newSearchParams);
	};

	const handleAddFilter = (obj, strId = null) => {
		setIsPreloader(true);
		let res = { ...JSON.parse(obj) };
		if (
			mobileFilterBy.filter(
				(el) =>
					el.type === 'filters' &&
					el.value === res.value &&
					el.name === res.name
			)?.length
		) {
			let resFil = mobileFilterBy.filter(
				(el) =>
					!(
						el.type === 'filters' &&
						el.value === res.value &&
						el.name === res.name
					)
			);
			setMobileFilterBy(resFil?.length ? [...resFil] : []);
			if (!resFil.some((el) => el.type === 'filters' && el.name === res.name)) {
				let resLastFil = [
					...last_filter_parametr.filter((el) => el.name !== res.name),
				];
				setLast_filter_parametr(resLastFil?.length ? [...resLastFil] : []);
				newSearchParams.set(
					'last_filter_parametr',
					JSON.stringify(resLastFil?.length ? [...resLastFil] : [])
				);
			}
			newSearchParams.set(
				'mobileFilterBy',
				JSON.stringify(resFil?.length ? [...resFil] : [])
			);
			setSearchParams(newSearchParams);
		} else {
			let resLastFil = [
				...last_filter_parametr,
				{
					name: res.name,
					value: [
						...notBlockedFilter.filter((el) => el.name === res.name)[0].value,
					],
				},
			];
			setMobileFilterBy([...mobileFilterBy, { ...res }]);
			setLast_filter_parametr(resLastFil?.length ? [...resLastFil] : []);
			newSearchParams.set(
				'last_filter_parametr',
				JSON.stringify(resLastFil?.length ? [...resLastFil] : [])
			);
			newSearchParams.set(
				'mobileFilterBy',
				JSON.stringify([...mobileFilterBy, { ...res }])
			);
			setSearchParams(newSearchParams);
		}
		handleSetPage(0);
		if (strId) {
			setScrollId(strId);
		}
	};

	const handleReturnIsFilter = (obj) => {
		let res = mobileFilterBy.filter(
			(el) =>
				el.type === 'filters' && el.name === obj.name && el.value === obj.value
		);
		return res?.length ? true : false;
	};

	const handleSelectCategory = (obj, strId = null) => {
		setIsPreloader(true);
		let res = { ...JSON.parse(obj) };
		if (
			mobileFilterBy.filter(
				(el) =>
					el.type === 'categories' &&
					el.value === res._id &&
					el.name === res.name
			)?.length
		) {
			let resCat = mobileFilterBy.filter(
				(el) =>
					!(
						el.type === 'categories' &&
						el.value === res._id &&
						el.name === res.name
					)
			);
			setMobileFilterBy(resCat?.length ? [...resCat] : []);
			if (!resCat.some((el) => el.type === 'categories')) {
				let resLastFil = [
					...last_filter_parametr.filter((el) => el.name !== 'categories'),
				];
				setLast_filter_parametr(resLastFil?.length ? [...resLastFil] : []);
				newSearchParams.set(
					'last_filter_parametr',
					JSON.stringify(resLastFil?.length ? [...resLastFil] : [])
				);
			}
			newSearchParams.set(
				'mobileFilterBy',
				JSON.stringify(resCat?.length ? [...resCat] : [])
			);
			setSearchParams(newSearchParams);
		} else {
			let resLastFil = [
				...last_filter_parametr,
				{ name: 'categories', value: [...notCategoriesFilter] },
			];
			setMobileFilterBy([
				...mobileFilterBy,
				{ type: 'categories', name: res.name, value: res._id },
			]);
			setLast_filter_parametr(resLastFil?.length ? [...resLastFil] : []);
			newSearchParams.set(
				'last_filter_parametr',
				JSON.stringify(resLastFil?.length ? [...resLastFil] : [])
			);
			newSearchParams.set(
				'mobileFilterBy',
				JSON.stringify([
					...mobileFilterBy,
					{ type: 'categories', name: res.name, value: res._id },
				])
			);
			setSearchParams(newSearchParams);
		}
		handleSetPage(0);
		if (strId) {
			setScrollId(strId);
		}
	};

	const handleReturnCategoriesIdArr = () => {
		let res = mobileFilterBy.filter((el) => el.type === 'categories');
		return res?.length ? [...res.map((el) => el.value)] : [];
	};

	const handleSelectColor = (obj, strId = null) => {
		setIsPreloader(true);
		let res = { ...JSON.parse(obj) };
		if (
			mobileFilterBy.filter(
				(el) =>
					el.type === 'colors' && el.value === res.value && el.name === res.name
			)?.length
		) {
			let resCol = mobileFilterBy.filter(
				(el) =>
					!(
						el.type === 'colors' &&
						el.value === res.value &&
						el.name === res.name
					)
			);
			setMobileFilterBy(resCol?.length ? [...resCol] : []);
			if (!resCol.some((el) => el.type === 'colors')) {
				let resLastFil = [
					...last_filter_parametr.filter((el) => el.name !== 'colors'),
				];
				setLast_filter_parametr(resLastFil?.length ? [...resLastFil] : []);
				newSearchParams.set(
					'last_filter_parametr',
					JSON.stringify(resLastFil?.length ? [...resLastFil] : [])
				);
			}
			newSearchParams.set(
				'mobileFilterBy',
				JSON.stringify(resCol?.length ? [...resCol] : [])
			);
			setSearchParams(newSearchParams);
		} else {
			let resLastFil = [
				...last_filter_parametr,
				{ name: 'colors', value: [...notColorsFilter] },
			];
			setMobileFilterBy([
				...mobileFilterBy,
				{ type: 'colors', value: res.value, name: res.name },
			]);
			setLast_filter_parametr(resLastFil?.length ? [...resLastFil] : []);
			newSearchParams.set(
				'last_filter_parametr',
				JSON.stringify(resLastFil?.length ? [...resLastFil] : [])
			);
			newSearchParams.set(
				'mobileFilterBy',
				JSON.stringify([
					...mobileFilterBy,
					{ type: 'colors', value: res.value, name: res.name },
				])
			);
			setSearchParams(newSearchParams);
		}
		handleSetPage(0);
		if (strId) {
			setScrollId(strId);
		}
	};

	const handleReturnColorsArr = () => {
		let res = mobileFilterBy.filter((el) => el.type === 'colors');
		return res?.length ? [...res.map((el) => el.value)] : [];
	};

	const handleSelectType = (obj, strId = null) => {
		setIsPreloader(true);
		let res = { ...JSON.parse(obj) };
		if (
			mobileFilterBy.filter(
				(el) =>
					el.type === 'types' && el.value === res.value && el.name === res.name
			)?.length
		) {
			let resTyp = mobileFilterBy.filter(
				(el) =>
					!(
						el.type === 'types' &&
						el.value === res.value &&
						el.name === res.name
					)
			);
			setMobileFilterBy(resTyp?.length ? [...resTyp] : []);
			if (!resTyp.some((el) => el.type === 'types')) {
				let resLastFil = [
					...last_filter_parametr.filter((el) => el.name !== 'types'),
				];
				setLast_filter_parametr(resLastFil?.length ? [...resLastFil] : []);
				newSearchParams.set(
					'last_filter_parametr',
					JSON.stringify(resLastFil?.length ? [...resLastFil] : [])
				);
			}
			newSearchParams.set(
				'mobileFilterBy',
				JSON.stringify(resTyp?.length ? [...resTyp] : [])
			);
			setSearchParams(newSearchParams);
		} else {
			let resLastFil = [
				...last_filter_parametr,
				{ name: 'types', value: [...notTypeFilter] },
			];
			setMobileFilterBy([
				...mobileFilterBy,
				{ type: 'types', value: res.value, name: res.name },
			]);
			setLast_filter_parametr(resLastFil?.length ? [...resLastFil] : []);
			newSearchParams.set(
				'last_filter_parametr',
				JSON.stringify(resLastFil?.length ? [...resLastFil] : [])
			);
			newSearchParams.set(
				'mobileFilterBy',
				JSON.stringify([
					...mobileFilterBy,
					{ type: 'types', value: res.value, name: res.name },
				])
			);
			setSearchParams(newSearchParams);
		}
		handleSetPage(0);
		if (strId) {
			setScrollId(strId);
		}
	};

	const handleReturnTypessArr = () => {
		let res = mobileFilterBy.filter((el) => el.type === 'types');
		return res?.length ? [...res.map((el) => el.value)] : [];
	};

	const handleIsOpenFilter = (str) => {
		if (openFilterArr?.length && openFilterArr.includes(str)) {
			setOpenFilterArr([...openFilterArr.filter((el) => el !== str)]);
		} else {
			setOpenFilterArr([...openFilterArr, str]);
		}
	};

	const handleCheckedFilterMemo = useMemo(() => {
		return (type, name = null) => {
			let res = [];
			if (name) {
				if (mobileFilterBy?.length) {
					res = mobileFilterBy.filter(
						(el) =>
							el.type === type && el.isVisible === true && el.name === name
					);
				}
			} else {
				if (mobileFilterBy?.length) {
					res = mobileFilterBy.filter(
						(el) => el.type === type && el.isVisible === true
					);
				}
			}
			return res?.length ? [...res] : [];
		};
	}, [mobileFilterBy]);

	const handleAddCheckedFilter = useCallback(
		(type, boolean, name = null) => {
			let res = [];
			if (mobileFilterBy?.length) {
				res = mobileFilterBy.map((el) => {
					if (name) {
						if (el.type === type && el.name === name) {
							el = { ...el, isVisible: !boolean };
							return el;
						}
						return el;
					} else {
						if (el.type === type) {
							el = { ...el, isVisible: !boolean };
							return el;
						}
						return el;
					}
				});
			}
			setMobileFilterBy(res?.length ? [...res] : []);
		},
		[mobileFilterBy]
	);

	const handleCharacteristicsResul = (obj) => {
		let resArr = [];
		if (obj.value?.length > 10) {
			if (allSeeName.includes(obj.name)) {
				resArr = [...obj.value];
			} else {
				resArr = [...obj.value.slice(0, 10)];
			}
		} else {
			resArr = [...obj.value];
		}
		return resArr?.length ? resArr : [];
	};

	const handleAllSee = (id, strId = null, strResId = null, e) => {
		if (e) {
			e.stopPropagation();
		}
		if (allSeeName.includes(id)) {
			setAllSeeName([...allSeeName.filter((el) => el !== id)]);
		} else {
			setAllSeeName([...allSeeName, id]);
		}

		if (strResId) {
			let element = document.getElementById(strResId);
			if (element) {
				element.scrollIntoView({ behavior: 'auto' });
			}
		}

		if (strId) {
			handleScrollById(strId);
		}
	};

	const handlePageCount = useMemo(() => {
		return (num) => {
			if (num !== 0) {
				if (window.innerWidth < 640) {
					return num % 20 === 0 ? num / 20 : Math.ceil(num / 20);
				} else {
					return num % 40 === 0 ? num / 40 : Math.ceil(num / 40);
				}
			}
		};
	}, [productsCount]);

	const handleDeleteSortPrice = (str) => {
		if (str === 'min') {
			setValueSortPrice([0, valueSortPrice[1]]);
		} else if (str === 'max') {
			setValueSortPrice([valueSortPrice[0], valueSortPrice[0]]);
		}
		setTimeout(() => {
			handleGetProducts(
				str === 'min' ? 0 : valueSortPrice[0],
				str === 'min' ? valueSortPrice[1] : valueSortPrice[0],
				selectCategory._id?.length ? selectCategory._id : null
			);
		}, 100);
	};

	const handleScrollByNewPage = () => {
		setTimeout(() => {
			window.scrollBy(0, scrollPage);
			setScrollPage('');
		}, 10);
	};

	const handleSetPage = (num = null) => {
		if (num === 0) {
			newSearchParams.set('page', JSON.stringify(num));
			setSearchParams(newSearchParams);
			return;
		}
		setScrollPage(window.scrollY);
		newSearchParams.set('page', JSON.stringify(pageSearchParams + 1));
		setSearchParams(newSearchParams);
	};

	const handleSelectSort = (str) => {
		let resObj = { ...JSON.parse(str) };
		setSelectSortBy(resObj);
		newSearchParams.set('selectSortBy', str);
		handleSetPage(0);
		setSearchParams(newSearchParams);
	};

	const handleAddModalFilterBy = (obj) => {
		setIsPreloader(true);
		setScrollId('');
		if (obj.type === 'types') {
			let res = mobileFilterBy.filter(
				(el) =>
					!(
						el.value === obj.value &&
						el.type === 'types' &&
						el.name === obj.name
					)
			);
			setMobileFilterBy(res?.length ? [...res] : []);
			if (!res.some((el) => el.type === 'types')) {
				let resLastFil = [
					...last_filter_parametr.filter((el) => el.name !== 'types'),
				];
				setLast_filter_parametr(resLastFil?.length ? [...resLastFil] : []);
				newSearchParams.set(
					'last_filter_parametr',
					JSON.stringify(resLastFil?.length ? [...resLastFil] : [])
				);
			}
			newSearchParams.set(
				'mobileFilterBy',
				JSON.stringify(res?.length ? [...res] : [])
			);
		}
		if (obj.type === 'categories') {
			let res = mobileFilterBy.filter(
				(el) =>
					!(
						el.value === obj.value &&
						el.type === 'categories' &&
						el.name === obj.name
					)
			);
			setMobileFilterBy(res?.length ? [...res] : []);
			if (!res.some((el) => el.type === 'categories')) {
				let resLastFil = [
					...last_filter_parametr.filter((el) => el.name !== 'categories'),
				];
				setLast_filter_parametr(resLastFil?.length ? [...resLastFil] : []);
				newSearchParams.set(
					'last_filter_parametr',
					JSON.stringify(resLastFil?.length ? [...resLastFil] : [])
				);
			}
			newSearchParams.set(
				'mobileFilterBy',
				JSON.stringify(res?.length ? [...res] : [])
			);
		}
		if (obj.type === 'colors') {
			let res = mobileFilterBy.filter(
				(el) =>
					!(
						el.value === obj.value &&
						el.type === 'colors' &&
						el.name === obj.name
					)
			);
			setMobileFilterBy(res?.length ? [...res] : []);
			if (!res.some((el) => el.type === 'colors')) {
				let resLastFil = [
					...last_filter_parametr.filter((el) => el.name !== 'colors'),
				];
				setLast_filter_parametr(resLastFil?.length ? [...resLastFil] : []);
				newSearchParams.set(
					'last_filter_parametr',
					JSON.stringify(resLastFil?.length ? [...resLastFil] : [])
				);
			}
			newSearchParams.set(
				'mobileFilterBy',
				JSON.stringify(res?.length ? [...res] : [])
			);
		}
		if (obj.type === 'filters') {
			let res = mobileFilterBy.filter(
				(el) =>
					!(
						el.value === obj.value &&
						el.type === 'filters' &&
						el.name === obj.name
					)
			);
			setMobileFilterBy(res?.length ? [...res] : []);
			if (!res.some((el) => el.type === 'filters' && el.name === obj.name)) {
				let resLastFil = [
					...last_filter_parametr.filter((el) => el.name !== obj.name),
				];
				setLast_filter_parametr(resLastFil?.length ? [...resLastFil] : []);
				newSearchParams.set(
					'last_filter_parametr',
					JSON.stringify(resLastFil?.length ? [...resLastFil] : [])
				);
			}
			newSearchParams.set(
				'mobileFilterBy',
				JSON.stringify(res?.length ? [...res] : [])
			);
		}
		handleSetPage(0);
		setSearchParams(newSearchParams);
	};

	const handleDeleteModalFilterBy = (str = null) => {
		setOpenFilterArr([]);
		setMobileFilterBy([]);
		setLast_filter_parametr([]);
		setScrollId('');
		dispatch(setSearchProducts(''));
		newSearchParams.set('page', 0);
		newSearchParams.set('searchProducts', '');
		newSearchParams.set('mobileFilterBy', JSON.stringify([]));
		newSearchParams.set('last_filter_parametr', JSON.stringify([]));
		handleSetPage(0);
		setSearchParams(newSearchParams);
	};

	const handleGetProducts = (
		min_price = null,
		max_price = null,
		selecktCatId = null
	) => {
		setIsPreloader(true);
		let isNewFilter = false;
		if (!selectSortBySearchParams?.name?.length) {
			setSelectSortBy({});
		}
		if (mobileFilterBySearchParams?.length) {
			setMobileFilterBy([...mobileFilterBySearchParams]);
			isNewFilter = false;
		} else {
			setMobileFilterBy([]);
			isNewFilter = true;
		}
		if (!searchProductsSearchParams?.length) {
			dispatch(setSearchProducts(''));
		}

		let resFilter = [];
		let resCat = [];
		let resColors = [];
		let resTypes = [];
		let resFiltersArr = [];
		if (!isNewFilter) {
			resCat = mobileFilterBySearchParams.filter(
				(el) => el.type === 'categories'
			);
			resColors = mobileFilterBySearchParams.filter(
				(el) => el.type === 'colors'
			);
			resTypes = mobileFilterBySearchParams.filter((el) => el.type === 'types');
			resFiltersArr = mobileFilterBySearchParams.filter(
				(el) => el.type === 'filters'
			);
			if (resFiltersArr?.length) {
				resFilter = resFiltersArr.reduce((acc, curr) => {
					const existingItem = acc.find((item) => item.name === curr.name);

					if (existingItem) {
						existingItem.value.push(curr.value);
					} else {
						acc.push({
							name: curr.name,
							value: [curr.value],
						});
					}

					return acc;
				}, []);
			}
		}

		let resPerPages = window.innerWidth < 640 ? 20 : 40;

		if (isFirstRender && pageSearchParams) {
			const resPages = window.innerWidth < 640 ? 20 : 40;
			resPerPages = resPages * (pageSearchParams + 1);
		}

		let resPages = pageSearchParams ? pageSearchParams : 0;

		if (isFirstRender) {
			resPages = 0;
		}

		let data = {
			page: resPages,
			category_id: resCat?.length
				? [...resCat.map((el) => el.value)]
				: selecktCatId?.length
				? [selecktCatId]
				: [],
			type: resTypes?.length ? [...resTypes.map((el) => el.value)] : [],
			color_filter: resColors?.length
				? [...resColors.map((el) => el.value)]
				: [],
			min_price: min_price !== null ? min_price : valueSortPrice[0],
			max_price: max_price !== null ? max_price : valueSortPrice[1],
			characteristics: resFilter?.length ? [...resFilter] : [],
			sort_price:
				selectSortBy.name === 'price' && selectSortBySearchParams?.name.length
					? selectSortBy.value
					: '',
			sort_name:
				selectSortBy.name === 'name' && selectSortBySearchParams?.name.length
					? selectSortBy.value
					: '',
			sort_time:
				selectSortBy.name === 'time' && selectSortBySearchParams?.name.length
					? selectSortBy.value
					: '',
			title: searchProductsSearchParams?.length
				? searchProductsSearchParams
				: '',
			is_hidden: false,
			catalog: true,
			perPages: resPerPages,
			last_filter_parametr: last_filter_parametrSearchParams?.length
				? last_filter_parametrSearchParams
				: [],
			block: !!trendId?.length ? trendId : '',
		};

		fetchRequest(
			'POST',
			`${process.env.REACT_APP_BASE_URL}/products/all`,
			data
		).then((res) => {
			if (res.success && res.data) {
				if (pageSearchParams !== 0 && !isFirstRender) {
					dispatch(getProductsFilter([...productsFilter, ...res.data]));
				} else {
					dispatch(getProductsFilter([...res.data]));
				}
				setProductsCount(res.count);
				setNotBlockedFilter(
					res.filter.characteristics?.length
						? [...res.filter.characteristics]
						: []
				);
				setNotColorsFilter(
					res.filter.colors?.length ? [...res.filter.colors] : []
				);
				setNotTypeFilter(res.filter.types?.length ? [...res.filter.types] : []);
				setNotCategoriesFilter(
					res.filter.categories?.length ? [...res.filter.categories] : []
				);

				if (res.block?.title?.length) {
					dispatch(setSelectBlock(res.block));
				}
				if (scrollPage?.length) {
					setTimeout(() => {
						handleScrollByNewPage();
					}, 25);
				} else {
					setTimeout(() => {
						handleScrollById();
					}, 25);
				}
			} else {
				console.log('POST CatalogView:', res);
			}
			setTimeout(() => {
				setIsPreloader(false);
			}, 20);
		});
	};

	const handleScrollById = (str = null) => {
		if (str?.length) {
			setTimeout(() => {
				let element = document.getElementById(str);
				if (element) {
					element.scrollIntoView({ behavior: 'auto' });
					window.scrollBy(0, yOffset);
				}
			}, 10);
			return;
		}

		if (scrollId?.length) {
			setTimeout(() => {
				let element = document.getElementById(scrollId);
				if (element) {
					element.scrollIntoView({ behavior: 'auto' });
					window.scrollBy(0, yOffset);
					setScrollId('');
				}
			}, 30);
		}
	};

	const handleGetProductFilter = (id = null) => {
		fetchGetData(
			`${process.env.REACT_APP_BASE_URL}/products/filter?category_id=${
				id ? id : ''
			}`
		).then((res) => {
			if (res.success && res.data) {
				let min_price =
					res.data.price && res.data.price.min ? res.data.price.min : 0;
				let max_price =
					res.data.price && res.data.price.max ? res.data.price.max : 0;
				if (res.data.price) {
					setValueSortPrice([min_price, max_price]);
					setMainMaxPrice(max_price);
				}

				handleGetProducts(
					min_price,
					max_price,
					res.data.category?._id?.length ? res.data.category?._id : null
				);
				dispatch(
					setSelectCategory(
						res.data.category?._id?.length ? { ...res.data.category } : {}
					)
				);
			} else {
				navigate('/catalog');
				console.log('GET CatalogView', res);
			}
		});
	};

	const handleSortByMinPrice = (num) => {
		if (num.startsWith('0')) {
			validNumber(num.slice(1), (price) =>
				setValueSortPrice([price === '' ? 0 : +price, valueSortPrice[1]])
			);
		} else {
			validNumber(num, (price) =>
				setValueSortPrice([price === '' ? 0 : +price, valueSortPrice[1]])
			);
		}

		if (/^\d+$/.test(num)) {
			if (timerId !== null) {
				clearTimeout(timerId);
			}

			setTimerId(
				setTimeout(() => {
					handleGetProducts(
						Number(num),
						valueSortPrice[1],
						selectCategory._id?.length ? selectCategory._id : null
					);
					timerId = null;
				}, 1000)
			);
		}
	};

	const handleChange = (event, newValue) => {
		setValueSortPrice(newValue);
	};

	const handleSortByMaxPrice = (num) => {
		if (num.startsWith('0')) {
			validNumber(num.slice(1), (price) =>
				setValueSortPrice([valueSortPrice[0], price === '' ? 0 : +price])
			);
		} else {
			validNumber(num, (price) =>
				setValueSortPrice([valueSortPrice[0], price === '' ? 0 : +price])
			);
		}

		if (/^\d+$/.test(num)) {
			if (timerId !== null) {
				clearTimeout(timerId);
			}

			setTimerId(
				setTimeout(() => {
					handleGetProducts(
						valueSortPrice[0],
						Number(num),
						selectCategory._id?.length ? selectCategory._id : null
					);
					timerId = null;
				}, 1000)
			);
		}
	};

	const handleNextVariation = (obj, oldProductId) => {
		if (productsFilter?.length) {
			let res = productsFilter.map((ell) => {
				if (ell._id === oldProductId) {
					ell = obj;
					return ell;
				}
				return ell;
			});

			dispatch(getProductsFilter([...res]));
		}
	};

	const handleOpenShoppingCart = () => {
		setIsOpenShoppingCart(true);
		if (mobileFilterBy?.length) {
			let res = mobileFilterBy.map((el) => {
				el = { ...el, isVisible: true };
				return el;
			});
			setMobileFilterBy([...res]);
		}

		let layout = document.getElementById('layoutId');
		layout.style.cssText = `
            position: fixed;
            width: 100vw;
            height: 100vh;
            zIndex: 1000;
            `;
	};

	const handleCloseModal = () => {
		setIsOpenShoppingCart(false);
		setOpenFilterArr([]);

		let layout = document.getElementById('layoutId');
		layout.style.cssText = ``;
	};

	const handleResize = () => {
		if (window.innerWidth >= 960) {
			handleCloseModal();
		}
	};

	const handleCloseSelect = () => {
		dispatch(setIsOpenSelect(false));
		enabledScroll(modalScrollPosition);
	};

	const handleOpenSelect = () => {
		dispatch(setIsOpenSelect(true));
		disabledScroll(setModalScrollPosition);
	};

	const handleIsAllSeeCategories = (
		strId = null,
		strResId = null,
		e = null
	) => {
		if (e) {
			e.stopPropagation();
		}
		setIsAllSeeCategories(!isAllSeeCategories);
		if (strResId) {
			let element = document.getElementById(strResId);
			if (element) {
				element.scrollTop = 0;
			}
		}
		if (strId) {
			handleScrollById(strId);
		}
	};

	const handleReturnCategory = () => {
		let res = handleCategoriesMap(categoriesShop).filter((el) =>
			notCategoriesFilter.includes(el._id)
		);
		if (isAllSeeCategories) {
			return res?.length ? [...res] : [];
		} else {
			if (res?.length > 10) {
				return [...res.slice(0, 10)];
			} else {
				return [...res];
			}
		}
	};

	const handleStandardColorsReturn = () => {
		let res = standardColors.filter((item) =>
			notColorsFilter.includes(item.value)
		);
		if (isAllSeeColor) {
			return [...res];
		} else {
			if (res?.length > 10) {
				return [...res.slice(0, 10)];
			} else {
				return [...res];
			}
		}
	};

	const handleIsAllSeeColor = (strId = null, strResId = null, e = null) => {
		if (e) {
			e.stopPropagation();
		}
		setIsAllSeeColor(!isAllSeeColor);
		if (strResId) {
			let element = document.getElementById(strResId);
			if (element) {
				element.scrollTop = 0;
			}
		}
		if (strId) {
			handleScrollById(strId);
		}
	};

	return (
		<div className='catalog-view'>
			{!!selectBlock._id?.length ? (
				<SeoBlock
					title={selectBlock.seo_default_title}
					description={selectBlock.seo_default_description}
					keywords={selectBlock.seo_default_keywords}
				/>
			) : (
				<SeoBlock
					isCanonical={location.pathname !== '/catalog' ? true : false}
					title={
						selectCategory?._id?.length &&
						selectCategory.seo_default_title?.length
							? selectCategory.seo_default_title
							: seo.title_catalog
					}
					description={
						selectCategory?._id?.length &&
						selectCategory.seo_default_description?.length
							? selectCategory.seo_default_description
							: seo.description_catalog
					}
					keywords={
						selectCategory?._id?.length &&
						selectCategory.seo_default_keywords?.length
							? selectCategory.seo_default_keywords
							: seo.keywords_catalog
					}
				/>
			)}
			<Modal open={isOpenShoppingCart} onClose={handleCloseModal}>
				<>
					{isPreloader && (
						<div className='catalog-view__modal-filter-wrap--is-preloader'>
							<PreloaderCustom />
						</div>
					)}
					<div
						className={`catalog-view__modal-filter-wrap ${
							isSafari || isXiaomiRedmi
								? 'catalog-view__modal-filter-is-safari-wrap'
								: ''
						}`}
					>
						<div
							className='catalog-view__modal-close'
							onClick={handleCloseModal}
						></div>
						<div className='catalog-view__modal-filter-header'>
							<div className='catalog-view__modal-filter-header-text'>
								Фільтр і сортування
							</div>
							<img
								className='catalog-view__modal-filter-btn-close'
								onClick={handleCloseModal}
								src={close2}
								alt='img'
							/>
						</div>
						<div
							className={`catalog-view__modal-filter ${
								isSafari || isXiaomiRedmi
									? 'catalog-view__modal-filter-is-safari'
									: ''
							}`}
						>
							<Accordion className='catalog-view__mobile-filter-accordion'>
								<AccordionSummary
									expandIcon={
										<svg
											className='catalog-view__link-category-parent-arrow-img'
											width='21'
											height='20'
											viewBox='0 0 21 20'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<g id='chevron_right_FILL0_wght400_GRAD0_opsz48 1'>
												<path
													className={`catalog-view__link-category-parent-arrow-img-path`}
													id='Vector'
													d='M11.0928 9.97915L6.96777 5.85415L7.86361 4.95831L12.8844 9.97915L7.86361 15L6.96777 14.1041L11.0928 9.97915Z'
													fill='#49454F'
												/>
											</g>
										</svg>
									}
								>
									<div className='catalog-view__products-filter-title'>
										Сортування
									</div>
								</AccordionSummary>
								<AccordionDetails>
									<div className='catalog-view__mobile-filter-accordion-sort-wrap'>
										<FormControl>
											<RadioGroup
												aria-labelledby='demo-radio-buttons-group-label'
												defaultValue={selectSortBy}
												name='radio-buttons-group'
											>
												<label className='catalog-view__products-filter-radio-label'>
													<input
														className='catalog-view__products-filter-radio-checkbox'
														type='checkbox'
														value={{}}
														onChange={() =>
															handleSelectSort(JSON.stringify({}))
														}
													/>
													<div
														className={`catalog-view__products-filter-radio-checkbox-custom ${
															!selectSortBy.name?.length
																? 'catalog-view__products-filter-radio-checkbox-custom-active'
																: ''
														}`}
													></div>
													<span className='catalog-view__products-filter-radio-text'>
														Сортування: за замовчуванням
													</span>
												</label>
												<label className='catalog-view__products-filter-radio-label'>
													<input
														className='catalog-view__products-filter-radio-checkbox'
														type='checkbox'
														value={{ name: 'price', value: '1' }}
														onChange={() =>
															handleSelectSort(
																JSON.stringify({ name: 'price', value: '1' })
															)
														}
													/>
													<div
														className={`catalog-view__products-filter-radio-checkbox-custom ${
															selectSortBy.name === 'price' &&
															selectSortBy.value === '1'
																? 'catalog-view__products-filter-radio-checkbox-custom-active'
																: ''
														}`}
													></div>
													<span className='catalog-view__products-filter-radio-text'>
														Ціна: спочатку дешеві
													</span>
												</label>
												<label className='catalog-view__products-filter-radio-label'>
													<input
														className='catalog-view__products-filter-radio-checkbox'
														type='checkbox'
														value={{ name: 'price', value: '-1' }}
														onChange={() =>
															handleSelectSort(
																JSON.stringify({ name: 'price', value: '-1' })
															)
														}
													/>
													<div
														className={`catalog-view__products-filter-radio-checkbox-custom ${
															selectSortBy.name === 'price' &&
															selectSortBy.value === '-1'
																? 'catalog-view__products-filter-radio-checkbox-custom-active'
																: ''
														}`}
													></div>
													<span className='catalog-view__products-filter-radio-text'>
														Ціна: спочатку дорогі
													</span>
												</label>
												<label className='catalog-view__products-filter-radio-label'>
													<input
														className='catalog-view__products-filter-radio-checkbox'
														type='checkbox'
														value={{ name: 'name', value: '1' }}
														onChange={() =>
															handleSelectSort(
																JSON.stringify({ name: 'name', value: '1' })
															)
														}
													/>
													<div
														className={`catalog-view__products-filter-radio-checkbox-custom ${
															selectSortBy.name === 'name' &&
															selectSortBy.value === '1'
																? 'catalog-view__products-filter-radio-checkbox-custom-active'
																: ''
														}`}
													></div>
													<span className='catalog-view__products-filter-radio-text'>
														Назва: А—Я
													</span>
												</label>
												<label className='catalog-view__products-filter-radio-label'>
													<input
														className='catalog-view__products-filter-radio-checkbox'
														type='checkbox'
														value={{ name: 'name', value: '-1' }}
														onChange={() =>
															handleSelectSort(
																JSON.stringify({ name: 'name', value: '-1' })
															)
														}
													/>
													<div
														className={`catalog-view__products-filter-radio-checkbox-custom ${
															selectSortBy.name === 'name' &&
															selectSortBy.value === '-1'
																? 'catalog-view__products-filter-radio-checkbox-custom-active'
																: ''
														}`}
													></div>
													<span className='catalog-view__products-filter-radio-text'>
														Назва: Я—А
													</span>
												</label>
												<label className='catalog-view__products-filter-radio-label'>
													<input
														className='catalog-view__products-filter-radio-checkbox'
														type='checkbox'
														value={{ name: 'time', value: '-1' }}
														onChange={() =>
															handleSelectSort(
																JSON.stringify({ name: 'time', value: '-1' })
															)
														}
													/>
													<div
														className={`catalog-view__products-filter-radio-checkbox-custom ${
															selectSortBy.name === 'time' &&
															selectSortBy.value === '-1'
																? 'catalog-view__products-filter-radio-checkbox-custom-active'
																: ''
														}`}
													></div>
													<span className='catalog-view__products-filter-radio-text'>
														Сортування: спочатку нові
													</span>
												</label>
												<label className='catalog-view__products-filter-radio-label'>
													<input
														className='catalog-view__products-filter-radio-checkbox'
														type='checkbox'
														value={{ name: 'time', value: '1' }}
														onChange={() =>
															handleSelectSort(
																JSON.stringify({ name: 'time', value: '1' })
															)
														}
													/>
													<div
														className={`catalog-view__products-filter-radio-checkbox-custom ${
															selectSortBy.name === 'time' &&
															selectSortBy.value === '1'
																? 'catalog-view__products-filter-radio-checkbox-custom-active'
																: ''
														}`}
													></div>
													<span className='catalog-view__products-filter-radio-text'>
														Сортування: спочатку старі
													</span>
												</label>
											</RadioGroup>
										</FormControl>
									</div>
								</AccordionDetails>
							</Accordion>
							<Accordion className='catalog-view__mobile-filter-accordion'>
								<AccordionSummary
									expandIcon={
										<svg
											className='catalog-view__link-category-parent-arrow-img'
											width='21'
											height='20'
											viewBox='0 0 21 20'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<g id='chevron_right_FILL0_wght400_GRAD0_opsz48 1'>
												<path
													className={`catalog-view__link-category-parent-arrow-img-path`}
													id='Vector'
													d='M11.0928 9.97915L6.96777 5.85415L7.86361 4.95831L12.8844 9.97915L7.86361 15L6.96777 14.1041L11.0928 9.97915Z'
													fill='#49454F'
												/>
											</g>
										</svg>
									}
								>
									<div className='catalog-view__products-filter-title'>
										Ціна
									</div>
								</AccordionSummary>
								<AccordionDetails>
									<Box>
										<Slider
											value={valueSortPrice}
											onChange={handleChange}
											onChangeCommitted={() =>
												handleGetProducts(
													null,
													null,
													selectCategory._id?.length ? selectCategory._id : null
												)
											}
											valueLabelDisplay='auto'
											max={mainMaxPrice}
											min={0}
											className='catalog-view__products-filter-slider'
										/>
									</Box>
									<div className='catalog-view__products-filter-price-result-wrap'>
										<div
											className={`catalog-view__products-filter-price-result--wrap ${
												isFocused1
													? 'catalog-view__products-filter-price-result--active-wrap'
													: ''
											}`}
										>
											<div className='catalog-view__products-filter-price-result-title'>
												Мінімальна
											</div>
											<img
												className='catalog-view__products-filter-price-result-btn'
												onClick={() => handleDeleteSortPrice('min')}
												src={closeImgBlack}
												alt='img'
											/>
											<input
												className='catalog-view__products-filter-price-result'
												onFocus={() => setIsFocused1(true)}
												onBlur={() => setIsFocused1(false)}
												min={0}
												value={valueSortPrice[0]}
												onChange={(e) => handleSortByMinPrice(e.target.value)}
											/>
										</div>
										<div className='catalog-view__products-filter-price-result-line'></div>
										<div
											className={`catalog-view__products-filter-price-result--wrap ${
												isFocused2
													? 'catalog-view__products-filter-price-result--active-wrap'
													: ''
											} ${
												valueSortPrice[0] > valueSortPrice[1]
													? 'catalog-view__products-filter-price-result--error'
													: ''
											}`}
										>
											<div className='catalog-view__products-filter-price-result-title'>
												Максимальна
											</div>
											<img
												className='catalog-view__products-filter-price-result-btn'
												onClick={() => handleDeleteSortPrice('max')}
												src={closeImgBlack}
												alt='img'
											/>
											<input
												className='catalog-view__products-filter-price-result'
												onFocus={() => setIsFocused2(true)}
												onBlur={() => setIsFocused2(false)}
												value={valueSortPrice[1]}
												onChange={(e) => handleSortByMaxPrice(e.target.value)}
											/>
										</div>
									</div>
								</AccordionDetails>
							</Accordion>
							{!!notTypeFilter?.length && (
								<Accordion
									className='catalog-view__mobile-filter-accordion'
									onChange={(e, boolean) =>
										handleAddCheckedFilter('types', boolean)
									}
								>
									<AccordionSummary
										expandIcon={
											<svg
												className='catalog-view__link-category-parent-arrow-img'
												width='21'
												height='20'
												viewBox='0 0 21 20'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<g id='chevron_right_FILL0_wght400_GRAD0_opsz48 1'>
													<path
														className={`catalog-view__link-category-parent-arrow-img-path`}
														id='Vector'
														d='M11.0928 9.97915L6.96777 5.85415L7.86361 4.95831L12.8844 9.97915L7.86361 15L6.96777 14.1041L11.0928 9.97915Z'
														fill='#49454F'
													/>
												</g>
											</svg>
										}
									>
										<div className='catalog-view__products-filter-title'>
											<p>Тип виробництва</p>
											{!!handleCheckedFilterMemo('types')?.length && (
												<div className='catalog-view__mobile-filter-accordion-res-wrap'>
													{handleCheckedFilterMemo('types').map((el, i) => (
														<span
															className='catalog-view__mobile-filter-accordion-res'
															key={el.value}
														>
															{el.name}
															<span
																className={`${
																	i ===
																	handleCheckedFilterMemo('types')?.length - 1
																		? 'displayNone'
																		: ''
																}`}
															>
																,
															</span>
														</span>
													))}
												</div>
											)}
										</div>
									</AccordionSummary>
									<AccordionDetails>
										<div className='catalog-view__mobile-filter-accordion-items-wrap'>
											<label
												className={`catalog-view__products-filter-label ${
													(notTypeFilter?.length &&
														!notTypeFilter.includes('individual')) ||
													!notTypeFilter?.length
														? 'displayNone'
														: ''
												}`}
											>
												<input
													className='catalog-view__products-filter-checkbox'
													type='checkbox'
													value={JSON.stringify({
														type: 'types',
														name: 'Індивідуальне',
														value: 'individual',
													})}
													onChange={(e) => handleSelectType(e.target.value)}
												/>
												<div
													className={`catalog-view__products-filter-checkbox-custom ${
														handleReturnTypessArr()?.length &&
														handleReturnTypessArr().includes('individual')
															? 'catalog-view__products-filter-checkbox-custom-active'
															: ''
													}`}
												></div>
												<span className='catalog-view__products-filter-text'>
													Індивідуальне
												</span>
											</label>
											<label
												className={`catalog-view__products-filter-label ${
													(notTypeFilter?.length &&
														!notTypeFilter.includes('serial')) ||
													!notTypeFilter?.length
														? 'displayNone'
														: ''
												}`}
											>
												<input
													className='catalog-view__products-filter-checkbox'
													type='checkbox'
													value={JSON.stringify({
														type: 'types',
														name: 'Серійне',
														value: 'serial',
													})}
													onChange={(e) => handleSelectType(e.target.value)}
												/>
												<div
													className={`catalog-view__products-filter-checkbox-custom ${
														handleReturnTypessArr()?.length &&
														handleReturnTypessArr().includes('serial')
															? 'catalog-view__products-filter-checkbox-custom-active'
															: ''
													}`}
												></div>
												<span className='catalog-view__products-filter-text'>
													Серійне
												</span>
											</label>
										</div>
									</AccordionDetails>
								</Accordion>
							)}
							{!!categoriesShop?.length && !!notCategoriesFilter?.length && (
								<Accordion
									expanded={
										openFilterArr?.length &&
										openFilterArr.includes('categories')
											? true
											: false
									}
									className='catalog-view__mobile-filter-accordion'
									onChange={(e, boolean) =>
										handleAddCheckedFilter('categories', boolean)
									}
									onClick={() => handleIsOpenFilter('categories')}
								>
									<AccordionSummary
										expandIcon={
											<svg
												className='catalog-view__link-category-parent-arrow-img'
												width='21'
												height='20'
												viewBox='0 0 21 20'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<g id='chevron_right_FILL0_wght400_GRAD0_opsz48 1'>
													<path
														className={`catalog-view__link-category-parent-arrow-img-path`}
														id='Vector'
														d='M11.0928 9.97915L6.96777 5.85415L7.86361 4.95831L12.8844 9.97915L7.86361 15L6.96777 14.1041L11.0928 9.97915Z'
														fill='#49454F'
													/>
												</g>
											</svg>
										}
									>
										<div className='catalog-view__products-filter-title'>
											<p id='scrollCategoryMobId'>Категорія</p>
											{!!handleCheckedFilterMemo('categories')?.length && (
												<div className='catalog-view__mobile-filter-accordion-res-wrap'>
													{handleCheckedFilterMemo('categories').map(
														(el, i) => (
															<span
																className='catalog-view__mobile-filter-accordion-res'
																key={el.value}
															>
																{el.name}
																<span
																	className={`${
																		i ===
																		handleCheckedFilterMemo('categories')
																			?.length -
																			1
																			? 'displayNone'
																			: ''
																	}`}
																>
																	,
																</span>
															</span>
														)
													)}
												</div>
											)}
										</div>
									</AccordionSummary>
									<AccordionDetails>
										<div>
											<div
												className={`catalog-view__mobile-filter-accordion-items-wrap ${
													isAllSeeCategories
														? 'catalog-view__mobile-filter-accordion-items-all-wrap'
														: ''
												}`}
											>
												{handleReturnCategory().map((ell, i) => (
													<label
														className={`catalog-view__products-filter-label ${
															ell._id === NOT_DELETED_SUB_CATEGORY_ID
																? 'displayNone'
																: ''
														} ${
															handleReturnCategoriesIdArr()?.length &&
															handleReturnCategoriesIdArr().includes(ell._id)
																? 'catalog-view__products-filter-label-active'
																: ''
														}`}
														key={i + ell._id + ell.name}
													>
														<input
															className='catalog-view__products-filter-checkbox'
															type='checkbox'
															value={JSON.stringify(ell)}
															onChange={(e) =>
																handleSelectCategory(
																	e.target.value,
																	'scrollCategoryMobId'
																)
															}
														/>
														<div
															className={`catalog-view__products-filter-checkbox-custom ${
																handleReturnCategoriesIdArr()?.length &&
																handleReturnCategoriesIdArr().includes(ell._id)
																	? 'catalog-view__products-filter-checkbox-custom-active'
																	: ''
															}`}
														></div>
														<span className='catalog-view__products-filter-text'>
															{ell.name}
														</span>
													</label>
												))}
											</div>
											{notCategoriesFilter.length > 10 && (
												<button
													className='catalog-view__products-filter-all-btn'
													onClick={(e) =>
														handleIsAllSeeCategories(
															'scrollCategoryMobId',
															null,
															e
														)
													}
												>
													{isAllSeeCategories ? 'Згорнути' : 'Показати всі'}
												</button>
											)}
										</div>
									</AccordionDetails>
								</Accordion>
							)}
							{!!notColorsFilter?.length && (
								<Accordion
									expanded={
										openFilterArr?.length && openFilterArr.includes('colors')
											? true
											: false
									}
									className='catalog-view__mobile-filter-accordion'
									onChange={(e, boolean) =>
										handleAddCheckedFilter('colors', boolean)
									}
									onClick={() => handleIsOpenFilter('colors')}
								>
									<AccordionSummary
										expandIcon={
											<svg
												className='catalog-view__link-category-parent-arrow-img'
												width='21'
												height='20'
												viewBox='0 0 21 20'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<g id='chevron_right_FILL0_wght400_GRAD0_opsz48 1'>
													<path
														className={`catalog-view__link-category-parent-arrow-img-path`}
														id='Vector'
														d='M11.0928 9.97915L6.96777 5.85415L7.86361 4.95831L12.8844 9.97915L7.86361 15L6.96777 14.1041L11.0928 9.97915Z'
														fill='#49454F'
													/>
												</g>
											</svg>
										}
									>
										<div className='catalog-view__products-filter-title'>
											<p id='scrollColorMobId'>Колір</p>
											{!!handleCheckedFilterMemo('colors')?.length && (
												<div className='catalog-view__mobile-filter-accordion-res-wrap'>
													{handleCheckedFilterMemo('colors').map((el, i) => (
														<span
															className='catalog-view__mobile-filter-accordion-res'
															key={el.value}
														>
															{el.name}
															<span
																className={`${
																	i ===
																	handleCheckedFilterMemo('colors')?.length - 1
																		? 'displayNone'
																		: ''
																}`}
															>
																,
															</span>
														</span>
													))}
												</div>
											)}
										</div>
									</AccordionSummary>
									<AccordionDetails>
										<div>
											<div
												className={`catalog-view__mobile-filter-accordion-items-wrap ${
													isAllSeeColor
														? 'catalog-view__mobile-filter-accordion-items-all-wrap'
														: ''
												}`}
											>
												{!!notColorsFilter?.length &&
													handleStandardColorsReturn().map((el, i) => (
														<label
															className={`catalog-view__products-filter-label ${
																handleReturnColorsArr()?.length &&
																handleReturnColorsArr().includes(el.value)
																	? 'catalog-view__products-filter-label-active'
																	: ''
															}`}
															key={el + i}
														>
															<input
																className='catalog-view__products-filter-checkbox'
																type='checkbox'
																value={JSON.stringify(el)}
																onChange={(e) =>
																	handleSelectColor(
																		e.target.value,
																		'scrollColorMobId'
																	)
																}
															/>
															<div
																className={`catalog-view__products-filter-checkbox-custom ${
																	handleReturnColorsArr()?.length &&
																	handleReturnColorsArr().includes(el.value)
																		? 'catalog-view__products-filter-checkbox-custom-active'
																		: ''
																}`}
															></div>
															<span className='catalog-view__products-filter-text'>
																{el.name}
															</span>
														</label>
													))}
											</div>
											{!!notColorsFilter?.length &&
												handleStandardColorsReturn()?.length > 10 && (
													<button
														className='catalog-view__products-filter-all-btn'
														onClick={(e) =>
															handleIsAllSeeColor('scrollColorMobId', null, e)
														}
													>
														{isAllSeeColor ? 'Згорнути' : 'Показати всі'}
													</button>
												)}
										</div>
									</AccordionDetails>
								</Accordion>
							)}
							{!!notBlockedFilter?.length &&
								notBlockedFilter.map((el, i) => (
									<div
										className='catalog-view__products-filter-label-mobile-wrap'
										key={el.name + i}
									>
										<Accordion
											expanded={
												openFilterArr?.length && openFilterArr.includes(el.name)
													? true
													: false
											}
											className='catalog-view__mobile-filter-accordion'
											onChange={(e, boolean) =>
												handleAddCheckedFilter('filters', boolean, el.name)
											}
											onClick={(e) => handleIsOpenFilter(el.name)}
										>
											<AccordionSummary
												expandIcon={
													<svg
														className='catalog-view__link-category-parent-arrow-img'
														width='21'
														height='20'
														viewBox='0 0 21 20'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<g id='chevron_right_FILL0_wght400_GRAD0_opsz48 1'>
															<path
																className={`catalog-view__link-category-parent-arrow-img-path`}
																id='Vector'
																d='M11.0928 9.97915L6.96777 5.85415L7.86361 4.95831L12.8844 9.97915L7.86361 15L6.96777 14.1041L11.0928 9.97915Z'
																fill='#49454F'
															/>
														</g>
													</svg>
												}
											>
												<div className='catalog-view__products-filter-title'>
													<p id={`characteristics${el.name}`}>{el.name}</p>
													{!!handleCheckedFilterMemo('filters', el.name)
														?.length && (
														<div className='catalog-view__mobile-filter-accordion-res-wrap'>
															{handleCheckedFilterMemo('filters', el.name).map(
																(el, i, arr) => (
																	<span
																		className='catalog-view__mobile-filter-accordion-res'
																		key={el.value}
																	>
																		{el.value}
																		{arr.length - 1 !== i && ', '}
																	</span>
																)
															)}
														</div>
													)}
												</div>
											</AccordionSummary>
											<AccordionDetails>
												<div>
													<div
														className={`catalog-view__mobile-filter-accordion-items-wrap ${
															allSeeName.includes(el.name)
																? 'catalog-view__mobile-filter-accordion-items-all-wrap'
																: ''
														}`}
													>
														{!!el.value?.length &&
															handleCharacteristicsResul(el).map(
																(ell, index) => (
																	<label
																		className={`catalog-view__products-filter-label ${
																			handleReturnIsFilter({
																				name: el.name,
																				value: ell,
																			})
																				? 'catalog-view__products-filter-label-active'
																				: ''
																		}`}
																		key={ell + index}
																	>
																		<input
																			className='catalog-view__products-filter-checkbox'
																			type='checkbox'
																			value={JSON.stringify({
																				type: 'filters',
																				name: el.name,
																				value: ell,
																			})}
																			onChange={(e) =>
																				handleAddFilter(
																					e.target.value,
																					`characteristics${el.name}`
																				)
																			}
																		/>
																		<div
																			className={`catalog-view__products-filter-checkbox-custom ${
																				handleReturnIsFilter({
																					name: el.name,
																					value: ell,
																				})
																					? 'catalog-view__products-filter-checkbox-custom-active'
																					: ''
																			}`}
																		></div>
																		<span className='catalog-view__products-filter-text'>
																			{ell}
																		</span>
																	</label>
																)
															)}
													</div>
													{el.value?.length > 10 && (
														<button
															className='catalog-view__products-filter-all-btn'
															onClick={(e) =>
																handleAllSee(
																	el.name,
																	`characteristics${el.name}`,
																	null,
																	e
																)
															}
														>
															{allSeeName.includes(el.name)
																? 'Згорнути'
																: 'Показати всі'}
														</button>
													)}
												</div>
											</AccordionDetails>
										</Accordion>
									</div>
								))}
						</div>
						<div className='catalog-view__modal-filter-btn-wrap'>
							<button
								className='main-btn-2 catalog-view__modal-filter-btn'
								onClick={() => handleDeleteModalFilterBy()}
							>
								Очистити фільтр
							</button>
							<button
								className='main-btn-1 catalog-view__modal-filter-btn'
								onClick={() => handleCloseModal()}
							>
								Показати {productsCount}
							</button>
						</div>
					</div>
				</>
			</Modal>
			<div className='catalog-view-wrap container'>
				{(!!categotyId?.length || !!subCategotyId?.length) && (
					<div className='catalog-view__bread-crumbs-wrap'>
						<BreadCrumbs
							selectCategory={selectCategory}
							isPreloader={isPreloader}
						/>
					</div>
				)}

				{trendId === NOT_DELETED_TREND_ALIAS && (
					<div className='catalog-view__bread-crumbs-wrap'>
						<BreadCrumbs isTrendChildren={true} isPreloader={isPreloader} />
					</div>
				)}

				<h1 className='catalog-view__title'>
					{isPreloader ? (
						<Skeleton className='catalog-view__skeleton-title' />
					) : (
						<>
							{!!selectCategory.name?.length
								? selectCategory.name
								: selectBlock.title?.length
								? selectBlock.title
								: !!searchProducts?.length
								? `За результатом пошуку "${searchProducts}"`
								: trendId === NOT_DELETED_TREND_ALIAS
								? 'Для дітей'
								: 'Каталог товарів'}
						</>
					)}
				</h1>

				<div className='catalog-view__filter-search-title'>
					{isPreloader ? (
						<Skeleton className='catalog-view__skeleton-sub-title' />
					) : (
						<>
							Знайдено:{' '}
							<span>
								&nbsp;{productsCount}{' '}
								{productsCount === 1 ? 'товар' : 'товарів'}
							</span>
						</>
					)}
				</div>

				{!!selectCategory.description?.length && (
					<div className='catalog-view__description'>
						{selectCategory.description}
					</div>
				)}

				<div className='catalog-view__mobile-filter-wrap'>
					<button
						className='catalog-view__mobile-filter-btn'
						onClick={handleOpenShoppingCart}
					>
						<img
							className='catalog-view__mobile-filter-btn-img'
							src={filterImg}
							alt='img'
						/>
						<div className='catalog-view__mobile-filter-btn-text'>Фільтр</div>
					</button>
					<div className='catalog-view__filter-search'>
						{!!searchProducts?.length && (
							<button
								className='catalog-view__filter-search-item'
								onClick={() => handleDelSearchProducts()}
							>
								{searchProducts}
								<svg
									className='catalog-view__filter-search-item-img'
									width='20'
									height='20'
									viewBox='0 0 23 23'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M1.41421 -0.000151038L0 1.41406L21.2132 22.6273L22.6274 21.2131L1.41421 -0.000151038Z'
										fill='#19191D'
									></path>
									<path
										d='M22.6291 1.41421L21.2148 0L0.00164068 21.2132L1.41585 22.6274L22.6291 1.41421Z'
										fill='#19191D'
									></path>
								</svg>
							</button>
						)}
						{!!mobileFilterBy?.length &&
							mobileFilterBy.map((el, i) => (
								<button
									className='catalog-view__filter-search-item'
									onClick={() => handleAddModalFilterBy(el)}
									key={el + i}
								>
									{el.type === 'filters' ? el.value : el.name}
									<svg
										className='catalog-view__filter-search-item-img'
										width='20'
										height='20'
										viewBox='0 0 23 23'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M1.41421 -0.000151038L0 1.41406L21.2132 22.6273L22.6274 21.2131L1.41421 -0.000151038Z'
											fill='#19191D'
										></path>
										<path
											d='M22.6291 1.41421L21.2148 0L0.00164068 21.2132L1.41585 22.6274L22.6291 1.41421Z'
											fill='#19191D'
										></path>
									</svg>
								</button>
							))}
						{mobileFilterBy?.length >= 2 && (
							<button
								className='catalog-view__filter-search-all-btn'
								onClick={() => handleDeleteModalFilterBy('DeleteModalFilter')}
							>
								Очистити все
							</button>
						)}
					</div>
				</div>

				<div
					className={`catalog-view__sort--wrap ${
						!!headerLinks?.linksValue?.length && headerLinks?.isVisible
							? 'catalog-view__sort--wrap1'
							: ''
					}`}
				>
					<div className='catalog-view__filter-search'>
						{!!searchProducts?.length && (
							<button
								className='catalog-view__filter-search-item'
								onClick={() => handleDelSearchProducts()}
							>
								{searchProducts}
								<svg
									className='catalog-view__filter-search-item-img'
									width='20'
									height='20'
									viewBox='0 0 23 23'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M1.41421 -0.000151038L0 1.41406L21.2132 22.6273L22.6274 21.2131L1.41421 -0.000151038Z'
										fill='#19191D'
									></path>
									<path
										d='M22.6291 1.41421L21.2148 0L0.00164068 21.2132L1.41585 22.6274L22.6291 1.41421Z'
										fill='#19191D'
									></path>
								</svg>
							</button>
						)}
						{mobileFilterBy.map((el, i) => (
							<button
								className='catalog-view__filter-search-item'
								onClick={() => handleAddModalFilterBy(el)}
								key={el + i}
							>
								{el.type === 'filters' ? el.value : el.name}
								<svg
									className='catalog-view__filter-search-item-img'
									width='20'
									height='20'
									viewBox='0 0 23 23'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M1.41421 -0.000151038L0 1.41406L21.2132 22.6273L22.6274 21.2131L1.41421 -0.000151038Z'
										fill='#19191D'
									></path>
									<path
										d='M22.6291 1.41421L21.2148 0L0.00164068 21.2132L1.41585 22.6274L22.6291 1.41421Z'
										fill='#19191D'
									></path>
								</svg>
							</button>
						))}
						{mobileFilterBy?.length >= 2 && (
							<button
								className='catalog-view__filter-search-all-btn'
								onClick={() => handleDeleteModalFilterBy('DeleteModalFilter')}
							>
								Очистити все
							</button>
						)}
					</div>
					<div className='catalog-view__sort-wrap'>
						<Select
							open={isOpenSelect}
							onClose={handleCloseSelect}
							onOpen={(e) => handleOpenSelect(e)}
							value={JSON.stringify(selectSortBy)}
							onChange={(e) => handleSelectSort(e.target.value)}
							className='catalog-view__sort-select'
							labelId='selectSortBylabelId'
						>
							<MenuItem value={JSON.stringify({})}>
								Сортування: за замовчуванням
							</MenuItem>
							<MenuItem
								value={JSON.stringify({ name: 'price', value: '1' })}
								disableRipple={true}
							>
								Ціна: спочатку дешеві
							</MenuItem>
							<MenuItem
								value={JSON.stringify({ name: 'price', value: '-1' })}
								disableRipple={true}
							>
								Ціна: спочатку дорогі
							</MenuItem>
							{/* <MenuItem
								value={JSON.stringify({ name: 'name', value: '1' })}
								disableRipple={true}
							>
								Назва: А—Я
							</MenuItem>
							<MenuItem
								value={JSON.stringify({ name: 'name', value: '-1' })}
								disableRipple={true}
							>
								Назва: Я—А
							</MenuItem> */}
							<MenuItem
								value={JSON.stringify({ name: 'time', value: '-1' })}
								disableRipple={true}
							>
								Сортування: спочатку нові
							</MenuItem>
							<MenuItem
								value={JSON.stringify({ name: 'time', value: '1' })}
								disableRipple={true}
							>
								Сортування: спочатку старі
							</MenuItem>
						</Select>
					</div>
				</div>
				<div className='catalog-view__products-wrap' ref={productsWrapRef}>
					<div className='catalog-view__products-filter-wrap'>
						<div className='catalog-view__products-filter'>
							<div className='catalog-view__products-filter-title catalog-view__products-filter-title-price'>
								Ціна
							</div>
							<Box sx={{ width: 240 }}>
								<Slider
									value={valueSortPrice}
									onChange={handleChange}
									onChangeCommitted={() =>
										handleGetProducts(
											null,
											null,
											selectCategory._id?.length ? selectCategory._id : null
										)
									}
									valueLabelDisplay='auto'
									max={mainMaxPrice}
									min={0}
									className='catalog-view__products-filter-slider'
								/>
							</Box>
							<div className='catalog-view__products-filter-price-result-wrap'>
								<div
									className={`catalog-view__products-filter-price-result--wrap ${
										isFocused1
											? 'catalog-view__products-filter-price-result--active-wrap'
											: ''
									}`}
								>
									<div className='catalog-view__products-filter-price-result-title'>
										Мінімальна
									</div>
									<img
										className='catalog-view__products-filter-price-result-btn'
										onClick={() => handleDeleteSortPrice('min')}
										src={closeImgBlack}
										alt='img'
									/>
									<input
										className='catalog-view__products-filter-price-result'
										onFocus={() => setIsFocused1(true)}
										onBlur={() => setIsFocused1(false)}
										min={0}
										value={valueSortPrice[0]}
										onChange={(e) => handleSortByMinPrice(e.target.value)}
									/>
								</div>
								<div className='catalog-view__products-filter-price-result-line'></div>
								<div
									className={`catalog-view__products-filter-price-result--wrap ${
										isFocused2
											? 'catalog-view__products-filter-price-result--active-wrap'
											: ''
									} ${
										valueSortPrice[0] > valueSortPrice[1]
											? 'catalog-view__products-filter-price-result--error'
											: ''
									}`}
								>
									<div className='catalog-view__products-filter-price-result-title'>
										Максимальна
									</div>
									<img
										className='catalog-view__products-filter-price-result-btn'
										onClick={() => handleDeleteSortPrice('max')}
										src={closeImgBlack}
										alt='img'
									/>
									<input
										className='catalog-view__products-filter-price-result'
										onFocus={() => setIsFocused2(true)}
										onBlur={() => setIsFocused2(false)}
										value={valueSortPrice[1]}
										onChange={(e) => handleSortByMaxPrice(e.target.value)}
									/>
								</div>
							</div>
						</div>
						{isPreloader && (
							<div
								className='catalog-view__products-filter-preloader'
								style={
									productsWrapHeight
										? { height: `${productsWrapHeight}px` }
										: {}
								}
							></div>
						)}
						<>
							{!!notTypeFilter?.length && (
								<div className='catalog-view__products-filter'>
									<Accordion
										defaultExpanded={true}
										onChange={(e, boolean) =>
											handleAddCheckedFilter('types', boolean)
										}
									>
										<AccordionSummary
											expandIcon={
												<svg
													className='catalog-view__link-category-parent-arrow-img'
													width='21'
													height='20'
													viewBox='0 0 21 20'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<g id='chevron_right_FILL0_wght400_GRAD0_opsz48 1'>
														<path
															className={`catalog-view__link-category-parent-arrow-img-path`}
															id='Vector'
															d='M11.0928 9.97915L6.96777 5.85415L7.86361 4.95831L12.8844 9.97915L7.86361 15L6.96777 14.1041L11.0928 9.97915Z'
															fill='#49454F'
														/>
													</g>
												</svg>
											}
										>
											<div className='catalog-view__products-filter-title'>
												<p id='scrollTypeDesId'>Тип виробництва</p>
												{!!handleCheckedFilterMemo('types')?.length && (
													<div className='catalog-view__mobile-filter-accordion-res-wrap'>
														{handleCheckedFilterMemo('types').map((el, i) => (
															<span
																className='catalog-view__mobile-filter-accordion-res'
																key={el.value}
															>
																{el.name}
																<span
																	className={`${
																		i ===
																		handleCheckedFilterMemo('types')?.length - 1
																			? 'displayNone'
																			: ''
																	}`}
																>
																	,
																</span>
															</span>
														))}
													</div>
												)}
											</div>
										</AccordionSummary>
										<AccordionDetails>
											<div>
												<div className='catalog-view__products-filter-label-wrap'>
													<label
														className={`catalog-view__products-filter-label ${
															(notTypeFilter?.length &&
																!notTypeFilter.includes('individual')) ||
															!notTypeFilter?.length
																? 'displayNone'
																: ''
														}`}
													>
														<input
															className='catalog-view__products-filter-checkbox'
															type='checkbox'
															value={JSON.stringify({
																type: 'types',
																name: 'Індивідуальне',
																value: 'individual',
															})}
															onChange={(e) =>
																handleSelectType(
																	e.target.value,
																	'scrollTypeDesId'
																)
															}
														/>
														<div
															className={`catalog-view__products-filter-checkbox-custom ${
																handleReturnTypessArr()?.length &&
																handleReturnTypessArr().includes('individual')
																	? 'catalog-view__products-filter-checkbox-custom-active'
																	: ''
															}`}
														></div>
														<span className='catalog-view__products-filter-text'>
															Індивідуальне
														</span>
													</label>
													<label
														className={`catalog-view__products-filter-label ${
															(notTypeFilter?.length &&
																!notTypeFilter.includes('serial')) ||
															!notTypeFilter?.length
																? 'displayNone'
																: ''
														}`}
													>
														<input
															className='catalog-view__products-filter-checkbox'
															type='checkbox'
															value={JSON.stringify({
																type: 'types',
																name: 'Серійне',
																value: 'serial',
															})}
															onChange={(e) =>
																handleSelectType(
																	e.target.value,
																	'scrollTypeDesId'
																)
															}
														/>
														<div
															className={`catalog-view__products-filter-checkbox-custom ${
																handleReturnTypessArr()?.length &&
																handleReturnTypessArr().includes('serial')
																	? 'catalog-view__products-filter-checkbox-custom-active'
																	: ''
															}`}
														></div>
														<span className='catalog-view__products-filter-text'>
															Серійне
														</span>
													</label>
												</div>
											</div>
										</AccordionDetails>
									</Accordion>
								</div>
							)}
							{!!categoriesShop?.length && !!notCategoriesFilter?.length && (
								<div className='catalog-view__products-filter'>
									<Accordion
										defaultExpanded={true}
										onChange={(e, boolean) =>
											handleAddCheckedFilter('categories', boolean)
										}
									>
										<AccordionSummary
											expandIcon={
												<svg
													className='catalog-view__link-category-parent-arrow-img'
													width='21'
													height='20'
													viewBox='0 0 21 20'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<g id='chevron_right_FILL0_wght400_GRAD0_opsz48 1'>
														<path
															className={`catalog-view__link-category-parent-arrow-img-path`}
															id='Vector'
															d='M11.0928 9.97915L6.96777 5.85415L7.86361 4.95831L12.8844 9.97915L7.86361 15L6.96777 14.1041L11.0928 9.97915Z'
															fill='#49454F'
														/>
													</g>
												</svg>
											}
										>
											<div className='catalog-view__products-filter-title'>
												<p id='scrollCategoryDesId'>Категорія</p>
												{!!handleCheckedFilterMemo('categories')?.length && (
													<div className='catalog-view__mobile-filter-accordion-res-wrap'>
														{handleCheckedFilterMemo('categories').map(
															(el, i) => (
																<span
																	className='catalog-view__mobile-filter-accordion-res'
																	key={el.value}
																>
																	{el.name}
																	<span
																		className={`${
																			i ===
																			handleCheckedFilterMemo('categories')
																				?.length -
																				1
																				? 'displayNone'
																				: ''
																		}`}
																	>
																		,
																	</span>
																</span>
															)
														)}
													</div>
												)}
											</div>
										</AccordionSummary>
										<AccordionDetails>
											<div>
												<div
													id='scrollCategoryResultDesId'
													className={`catalog-view__products-filter-label-wrap ${
														isAllSeeCategories
															? 'catalog-view__products-filter-label-all'
															: ''
													}`}
												>
													{handleReturnCategory().map((ell, i) => (
														<label
															className={`catalog-view__products-filter-label ${
																ell._id === NOT_DELETED_SUB_CATEGORY_ID
																	? 'displayNone'
																	: ''
															} ${
																handleReturnCategoriesIdArr()?.length &&
																handleReturnCategoriesIdArr().includes(ell._id)
																	? 'catalog-view__products-filter-label-active'
																	: ''
															}`}
															key={ell.name + ell._id + i}
														>
															<input
																className='catalog-view__products-filter-checkbox'
																type='checkbox'
																value={JSON.stringify(ell)}
																onChange={(e) =>
																	handleSelectCategory(
																		e.target.value,
																		'scrollCategoryDesId'
																	)
																}
															/>
															<div
																className={`catalog-view__products-filter-checkbox-custom ${
																	handleReturnCategoriesIdArr()?.length &&
																	handleReturnCategoriesIdArr().includes(
																		ell._id
																	)
																		? 'catalog-view__products-filter-checkbox-custom-active'
																		: ''
																}`}
															></div>
															<span className='catalog-view__products-filter-text'>
																{ell.name}
															</span>
														</label>
													))}
												</div>
												{notCategoriesFilter.length > 10 && (
													<button
														className='catalog-view__products-filter-all-btn'
														onClick={() =>
															handleIsAllSeeCategories(
																'scrollCategoryDesId',
																'scrollCategoryResultDesId'
															)
														}
													>
														{isAllSeeCategories ? 'Згорнути' : 'Показати всі'}
													</button>
												)}
											</div>
										</AccordionDetails>
									</Accordion>
								</div>
							)}
							{!!notColorsFilter?.length && (
								<div className='catalog-view__products-filter'>
									<Accordion
										defaultExpanded={true}
										onChange={(e, boolean) =>
											handleAddCheckedFilter('colors', boolean)
										}
									>
										<AccordionSummary
											expandIcon={
												<svg
													className='catalog-view__link-category-parent-arrow-img'
													width='21'
													height='20'
													viewBox='0 0 21 20'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<g id='chevron_right_FILL0_wght400_GRAD0_opsz48 1'>
														<path
															className={`catalog-view__link-category-parent-arrow-img-path`}
															id='Vector'
															d='M11.0928 9.97915L6.96777 5.85415L7.86361 4.95831L12.8844 9.97915L7.86361 15L6.96777 14.1041L11.0928 9.97915Z'
															fill='#49454F'
														/>
													</g>
												</svg>
											}
										>
											<div className='catalog-view__products-filter-title'>
												<p id='scrollColorDesId'>Колір</p>
												{!!handleCheckedFilterMemo('colors')?.length && (
													<div className='catalog-view__mobile-filter-accordion-res-wrap'>
														{handleCheckedFilterMemo('colors').map((el, i) => (
															<span
																className='catalog-view__mobile-filter-accordion-res'
																key={el.value}
															>
																{el.name}
																<span
																	className={`${
																		i ===
																		handleCheckedFilterMemo('colors')?.length -
																			1
																			? 'displayNone'
																			: ''
																	}`}
																>
																	,
																</span>
															</span>
														))}
													</div>
												)}
											</div>
										</AccordionSummary>
										<AccordionDetails>
											<div>
												<div
													id='scrollColorResultDesId'
													className={`catalog-view__products-filter-label-wrap ${
														isAllSeeColor
															? 'catalog-view__products-filter-label-all'
															: ''
													}`}
												>
													{!!notColorsFilter?.length &&
														handleStandardColorsReturn().map((el, i) => (
															<label
																className={`catalog-view__products-filter-label ${
																	handleReturnColorsArr()?.length &&
																	handleReturnColorsArr().includes(el.value)
																		? 'catalog-view__products-filter-label-active'
																		: ''
																}`}
																key={el + i}
															>
																<input
																	className='catalog-view__products-filter-checkbox'
																	type='checkbox'
																	value={JSON.stringify(el)}
																	onChange={(e) =>
																		handleSelectColor(
																			e.target.value,
																			'scrollColorDesId'
																		)
																	}
																/>
																<div
																	className={`catalog-view__products-filter-checkbox-custom ${
																		handleReturnColorsArr()?.length &&
																		handleReturnColorsArr().includes(el.value)
																			? 'catalog-view__products-filter-checkbox-custom-active'
																			: ''
																	}`}
																></div>
																<span className='catalog-view__products-filter-text'>
																	{el.name}
																</span>
															</label>
														))}
												</div>
												{!!notColorsFilter?.length &&
													handleStandardColorsReturn()?.length > 10 && (
														<button
															className='catalog-view__products-filter-all-btn'
															onClick={() =>
																handleIsAllSeeColor(
																	'scrollColorDesId',
																	'scrollColorResultDesId'
																)
															}
														>
															{isAllSeeColor ? 'Згорнути' : 'Показати всі'}
														</button>
													)}
											</div>
										</AccordionDetails>
									</Accordion>
								</div>
							)}
							{!!notBlockedFilter?.length &&
								notBlockedFilter.map((el, i) => (
									<div
										className='catalog-view__products-filter'
										key={el.name + i}
									>
										<Accordion
											defaultExpanded={true}
											onChange={(e, boolean) =>
												handleAddCheckedFilter('filters', boolean, el.name)
											}
										>
											<AccordionSummary
												expandIcon={
													<svg
														className='catalog-view__link-category-parent-arrow-img'
														width='21'
														height='20'
														viewBox='0 0 21 20'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<g id='chevron_right_FILL0_wght400_GRAD0_opsz48 1'>
															<path
																className={`catalog-view__link-category-parent-arrow-img-path`}
																id='Vector'
																d='M11.0928 9.97915L6.96777 5.85415L7.86361 4.95831L12.8844 9.97915L7.86361 15L6.96777 14.1041L11.0928 9.97915Z'
																fill='#49454F'
															/>
														</g>
													</svg>
												}
											>
												<div className='catalog-view__products-filter-title'>
													<p id={`characteristicsDes${el.name}`}>{el.name}</p>
													{!!handleCheckedFilterMemo('filters', el.name)
														?.length && (
														<div className='catalog-view__mobile-filter-accordion-res-wrap'>
															{handleCheckedFilterMemo('filters', el.name).map(
																(el, i, arr) => (
																	<span
																		className='catalog-view__mobile-filter-accordion-res'
																		key={el.value}
																	>
																		{el.value}
																		{arr.length - 1 !== i && ', '}
																	</span>
																)
															)}
														</div>
													)}
												</div>
											</AccordionSummary>
											<AccordionDetails>
												<div>
													<div
														id={`characteristicsResultDes${el.name}`}
														className={`catalog-view__products-filter-label-wrap ${
															allSeeName.includes(el.name)
																? 'catalog-view__products-filter-label-all'
																: ''
														}`}
													>
														{!!el.value?.length &&
															handleCharacteristicsResul(el).map(
																(ell, index) => (
																	<label
																		className={`catalog-view__products-filter-label ${
																			handleReturnIsFilter({
																				name: el.name,
																				value: ell,
																			})
																				? 'catalog-view__products-filter-label-active'
																				: ''
																		}`}
																		key={ell + index}
																	>
																		<input
																			className='catalog-view__products-filter-checkbox'
																			type='checkbox'
																			value={JSON.stringify({
																				type: 'filters',
																				name: el.name,
																				value: ell,
																			})}
																			onChange={(e) =>
																				handleAddFilter(
																					e.target.value,
																					`characteristicsDes${el.name}`
																				)
																			}
																		/>
																		<div
																			className={`catalog-view__products-filter-checkbox-custom ${
																				handleReturnIsFilter({
																					name: el.name,
																					value: ell,
																				})
																					? 'catalog-view__products-filter-checkbox-custom-active'
																					: ''
																			}`}
																		></div>
																		<span className='catalog-view__products-filter-text'>
																			{ell}
																		</span>
																	</label>
																)
															)}
													</div>
													{el.value?.length > 10 && (
														<button
															className='catalog-view__products-filter-all-btn'
															onClick={() =>
																handleAllSee(
																	el.name,
																	`characteristicsDes${el.name}`,
																	`characteristicsResultDes${el.name}`
																)
															}
														>
															{allSeeName.includes(el.name)
																? 'Згорнути'
																: 'Показати всі'}
														</button>
													)}
												</div>
											</AccordionDetails>
										</Accordion>
									</div>
								))}
						</>
					</div>
					<div className='catalog-view__products--wrap'>
						<div className={`catalog-view__products`}>
							{!!productsFilter?.length &&
								productsFilter.map((el, i) => (
									<div
										className={`catalog-view__product ${
											isPreloader ? 'catalog-view__product--is-preloader' : ''
										}`}
										key={el._id + i}
									>
										<ProductCard
											product={el}
											handleNextVariation={handleNextVariation}
											isPreloader={isPreloader}
										/>
									</div>
								))}
							{isPreloader && !productsFilter?.length && (
								<SkeletonWrap pageSearchParams={pageSearchParams} />
							)}
							{!isPreloader && !productsFilter?.length && (
								<div className='catalog-view__products-error'>
									Нічого не знайдено
								</div>
							)}
						</div>
						{!isPreloader &&
							handlePageCount(productsCount) > pageSearchParams + 1 && (
								<div
									className='catalog-view__products-pagination'
									onClick={() => handleSetPage()}
								>
									<button className='main-btn-2'>Завантажити ще</button>
								</div>
							)}
					</div>
				</div>

				<div
					className={`catalog-view__banner-wrap ${
						!isPreloader ? 'catalog-view__banner-wrap--active' : ''
					}`}
				>
					<MainBanner />
				</div>
			</div>
		</div>
	);
}

export default CatalogView;
