import './UserCategoriesView.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import noPhotos from './../../assets/noPhotos.svg';
import category from './../../assets/category.svg';
import plusImg from './../../assets/plusImg.svg';
import plus from './../../assets/plus.svg';
import eyeHide1 from './../../assets/eyeHide1.svg';
import { fetchGetData, fetchRequest } from '../../helpers/Utils';
import { deleteCategories, setEditeCategory, setParentCategory, setShowMessageObj, setUpdateCategories } from '../../store/userSlice';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import ContextMenu from '../../components/ContextMenu/ContextMenu';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { NOT_DELETED_TREND_CATEGORY_ID } from '../../helpers/Config';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function UserCategoriesView() {
    const categories = useSelector(state => state.userSlice.categories);
    const [isModalDelete, setIsModalDelete] = useState(false);
    const [isModalHide, setIsModalHide] = useState(false);
    const [isModalError, setIsModalError] = useState(false);
    const [hideCategory, setHideCategory] = useState({});
    const [deleteCategory, setDeleteCategory] = useState({});
    const [isErrorSubCategory, setIsErrorSubCategory] = useState(false);
    const [isPreloader, setIsPreloader] = useState(true);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    let token = localStorage.getItem('token')
        
    useEffect(() => {
        if (categories?.length) {
            setIsPreloader(false)
        }
    }, [categories]);

    const handleDeleteCategory = (category) => {
        setIsModalDelete(true)
        setDeleteCategory(category)
    }

    const handleIsDeleteCategory = (boolean) => {
        if (boolean) {
            setIsPreloader(true)
            fetchRequest('DELETE', `${process.env.REACT_APP_BASE_URL}/categories/${deleteCategory._id}?token=${token}`, {}, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(deleteCategories(deleteCategory))
                        dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                    } else {
                        console.log('DELETE UserCategoriesView:', res)
                    }
                    setIsPreloader(false)
                })
        } 

        setIsModalDelete(false)
        setDeleteCategory({})
    }

    const handleHideCategory = (category) => {
        if (category.is_hide && (category.parent_id?.length && category.parent_id !== 'null')) {
            let res = categories.filter(el => el._id === category.parent_id)[0]
            if (res.is_hide) {
                setIsModalError(true)
            } else {
                setIsModalHide(true)
                setHideCategory(category)
            }
        } else {
            setIsModalHide(true)
            setHideCategory(category)
        }
    }
    
    const handleEditeCategory = (category) => {
        dispatch(setEditeCategory(category))
        if (category.parent_id?.length && category.parent_id !== 'null') {
            let res = categories.filter(el => el._id === category.parent_id)
            dispatch(setParentCategory(res[0]))
        }
    }
    
    const handleAddSubCategory = (category) => {
        setIsPreloader(true)
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/products/checkcategory/${category._id}`)
            .then(res => {
                if (res.success && res.data.length) {
                    setIsErrorSubCategory(true)
                } else {
                    console.log('POST UserProductsViews:', res)
                    dispatch(setParentCategory(category))
                    navigate('/auth/categories/create')
                }
                setIsPreloader(false)
            })
    }
    
    const handleIsHideCategory = (boolean) => {
        if (boolean) {
            setIsPreloader(true)
            let data = {
                ...hideCategory,
                is_hide: !hideCategory.is_hide
            }
            fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/categories/hide/${hideCategory._id}?token=${token}`, data, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
                .then(res => {
                    if (res.success && res.data) {
                            dispatch(setUpdateCategories(res.data))
                            dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                    } else {
                        console.log('PUT UserCategoriesView:', res)
                    }
                    setIsPreloader(false)
                })
        } 

        setIsModalHide(false)
        setHideCategory({})
    }
    
    const handleClickMenu = (obj, str) => {
        if (str === 'hide') {
            handleHideCategory(obj)
            return
        }
        if (str === 'delete') {
            handleDeleteCategory(obj)
            return
        }
        if (str === 'edite') {
            handleEditeCategory(obj)
             navigate(`/auth/categories/${obj._id}?tab=0`)
            return
        }
    }

    return (
        <div className="user-categories">
            <SeoBlock title={"Категорії"} description={""} keywords={""} isCanonical={location.pathname === '/auth/categories/' ? true : false}/>
            {
                isErrorSubCategory && <ModalWindow title={'На даній категорії є створені товари.'} text={'Створити підкатегорію можна тільки на тій категорії на якій немає створених товарів.'} handleClick={() => setIsErrorSubCategory(false)} notBtn={true}/>
            }

            {
                isModalError && <ModalWindow title={' Щоб відновити категорію потрібно спочатку відновити головну категорію'} text={''} handleClick={() => setIsModalError(false)} notBtn={true}/>
            }

            {
                isModalHide && <ModalWindow title={hideCategory.is_hide ? 'Відновити категорію?' : 'Приховати дану категорію?'} text={hideCategory.is_hide ? '' : (hideCategory.parent_id === null || hideCategory.parent_id === "null") ? 'Товари, які відносяться до категорії і підкатегорій будуть приховані' : 'Товари, які відносяться до даної категорії будуть приховані'} handleClick={handleIsHideCategory} />
            }

            {
                isModalDelete && <ModalWindow title={'Видалити дану категорію?'} handleClick={handleIsDeleteCategory} />
            }
            
            <div className="user-categories__header">
                <div className="user-categories__header-title-wrap">
                    <img className="user-categories__header-title-img" src={category} alt='img'/>
                    <h1 className="user-categories__header-title">Категорії</h1>
                </div>
                <div className="user-categories__header-btn-wrap">
                    <button className="user-categories__header-btn" onClick={() => navigate('/auth/categories/create')}>
                        <img className="user-categories__header-btn-img" src={plus} alt='img'/>
                        <span>Додати категорію</span>
                    </button>
                </div>
            </div>

            {
                isPreloader ? <PreloaderCustom />
                :
                <>
                    <div className="user-categories__category-wrap">
                        <div className="user-categories__category-title">Категорії</div>
                        <div className="user-categories__category-card-wrap">
                            {
                                !!categories?.length && categories.map(el => (
                                    <div className="user-categories__category-card" key={el._id}>
                                        {
                                            !!el.image?.length ? 
                                                <img className="user-categories__category-card-img" src={el.image} alt='img'/>
                                                :
                                                <img className="user-categories__category-card-no-photo" src={noPhotos} alt='img'/>
                                        }
                                        <div className={`user-categories__category-card-text-wrap ${el.is_hide ? 'user-categories__category--hide' : ''}`}>
                                            <NavLink 
                                                className='user-categories__category-card-text-name'
                                                onClick={() => handleEditeCategory(el)}
                                                to={`/auth/categories/${el._id}?tab=0`}
                                            >
                                                {el.name}
                                                {
                                                    el.is_hide &&
                                                        <img className='user-categories__category-card-text-sub-category-hide-img' src={eyeHide1} alt='img'/>
                                                }
                                            </NavLink>
                                            <div className="user-categories__category-card-text-sub-category-wrap">
                                                { !!el.sub_categories?.length && 
                                                    el.sub_categories.map(subCategories => (
                                                        <div className="user-categories__category-card-text-sub-category" key={subCategories._id}>
                                                            <NavLink 
                                                                className={`user-categories__category-card-text-sub-category-name ${subCategories.is_hide && !el.is_hide ? 'user-categories__category--hide' : ''}`}
                                                                onClick={() => handleEditeCategory(subCategories)}
                                                                to={`/auth/categories/${subCategories._id}?tab=0`}
                                                            >
                                                                {subCategories.name}
                                                            </NavLink>
                                                            {
                                                                subCategories.is_hide &&
                                                                    <img className={`user-categories__category-card-text-sub-category-hide-img ${!el.is_hide && subCategories.is_hide ? 'user-categories__category--hide' : ''}`} src={eyeHide1} alt='img'/>
                                                            }
                                                            <div className="user-categories__category-card-text-btn-wrap user-categories__sub-category-card-text-btn-wrap">
                                                                <ContextMenu category={subCategories} handleClickMenu={handleClickMenu}/>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                <div className="user-categories__category-card-text-sub-category-btn-create-wrap">
                                                    <img className={`user-categories__category-card-text-sub-category-btn-create ${el._id === NOT_DELETED_TREND_CATEGORY_ID ? 'displayNone' : ''}`} onClick={() => handleAddSubCategory(el)} src={plusImg} alt='img'/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-categories__category-card-text-btn-wrap">
                                            <ContextMenu category={el} handleClickMenu={handleClickMenu}/>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </>
            }
            
        </div>
    );
}

export default UserCategoriesView;