import './SignInView.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setShowMessageObj } from '../../store/userSlice';
import React, { useState } from "react";
import { fetchRequest } from '../../helpers/Utils';

function SignInView() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isErrorEmail, setIsErrorEmail] = useState(false);
    const [isErrorPassword, setIsErrorPassword] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = () => {
        let test1 = email?.length ? true : false
        let test2 = password?.length ? true : false
        if (test1 && test2) {
            let data = {
                email: email, 
                password: password,
            }
    
            fetchRequest('POST', `${process.env.REACT_APP_BASE_URL}/auth/login`, data)
                .then(res => {
                    if (res.success && res.token?.length) {
                        localStorage.setItem("token", res.token)
                        navigate(`/auth/categories`)
                    } else {
                        dispatch(setShowMessageObj({ open: true, status: 'error', message: res.message }))
                        console.log('POST SignInView:', res)
                    }
                })
        } else {

            setIsErrorEmail(!test1)
            setIsErrorPassword(!test2)
        }
    }

    return (
        <div className="sign-in">
            <div className="sign-in-wrap">
                <div className="sign-in__form-wrap">
                    <div className="sign-in__form">
                        <div className="sign-in__form-input-wrap">
                            <div className="sign-in__form-title">Вхід</div>
                            <div className='contacts-view__form-error-wrap'>
                                <input className={`contacts-view__form-input sign-in__form-input`} onChange={(e) => setEmail(e.target.value)} value={email} autoComplete="email" type='text' placeholder="Email"/>
                                {
                                    isErrorEmail && <div className='contacts-view__form-error-text'>{email.length ? 'Не вірний email' : 'Вкажіть, будь ласка, коректний email'}</div>
                                }
                            </div>
                            <div className='contacts-view__form-error-wrap'>
                                <input className={`contacts-view__form-input sign-in__form-input`} value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => e.key == "Enter" ? handleChange() : ''} autoComplete="Password" type='password' placeholder="Пароль"/>
                                {
                                    isErrorPassword && <div className='contacts-view__form-error-text'>{!password?.length ? "Вкажіть пароль" : 'Неправельний пароль'}</div>
                                }
                            </div>
                        </div>
                        <button className='sign-in__btn' type="submit" onClick={handleChange}>Увійти</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignInView;