import './PriceVyrobyView.css';
import { useSelector } from 'react-redux';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function PriceVyrobyView() {
    const seo = useSelector(state => state.homeSlice.seo);

    return (
        <div className="price-vyroby">
            <SeoBlock title={seo.title_rates} description={seo.description_rates} keywords={seo.keywords_rates}/>
            <div className="price-vyroby--wrap container">
                <h1 className="price-vyroby__title">Умови співпраці Vyroby</h1>
                <h4 className="price-vyroby__title price-vyroby__sub-title">Тарифи</h4>
                <div className="price-vyroby__info">
                    <div className="price-vyroby__info-title">Розміщення на платформі - безкоштовно</div>
                    <div className="price-vyroby__info-text price-vyroby__info-text--1">Необхідною умовою для розміщення на платформі є дотримання єдиної роздрібної ціни на товари виробника. Для розміщення Виробнику на платформі необхідно заповнити реєстраційну форму та додати свої товари в форму для товарів.</div>
                    <div className="price-vyroby__info-title">Комісія за замовлення - 15%</div>
                    <div className="price-vyroby__info-text">Виробник (продавець) сплачує комісію 15% від вартості оформленого замовлення з платформи. За замовлення, які не продані - комісія не сплачується.</div>
                </div>
                <h4 className="price-vyroby__title price-vyroby__sub-title">Оплата послуг</h4>
                <div className="price-vyroby__info">
                    <div className="price-vyroby__info-title">Оплата комісії за замовлення - після отримання передоплати від клієнта</div>
                    <div className="price-vyroby__info-text price-vyroby__info-text--2">Виробнику необхідно оплатити послуги (комісію) по замовленням відразу після отримання передоплати від клієнта.</div>
                    <div className="price-vyroby__info-text price-vyroby__info-text--2">Підведення підсумків по замовленням відбувається до 7 числа поточного місяця за попередній місяць - необхідно в звіті по замовленням вказати статус та суму по всім замовлення.</div>
                    <div className="price-vyroby__info-text">У разі досягнення заборгованості за попередній місяць, Товарні позиції Виробника система автоматично знімає з публікації сайту.</div>
                </div>
                <h4 className="price-vyroby__title price-vyroby__sub-title">Сервіс</h4>
                <div className="price-vyroby__info">
                    <div className="price-vyroby__info-title">Просимо вас дотримуватися стандартів сервісу і максимально допомагати клієнту</div>
                    <div className="price-vyroby__info-text price-vyroby__info-text--2">Обробка запитів і замовлень клієнтів - швидка реакція на запити, відповідати клієнту не пізніше 1 години від отримання запиту/замовлення; своєвчасно комунікувати в процесі співпраці.</div>
                    <div className="price-vyroby__info-text price-vyroby__info-text--2">Виконання домовленостей - виконувати домовленості згідно договору вчасно і якісно, дотримуватись термінів.</div>
                    <div className="price-vyroby__info-text">Клієнтоорієнтованість - допомагати клієнтам вирішити їх проблему/запит, намагатися пропонувати готове рішення, давати професійні рекомендації клієнтам по меблям.</div>
                </div>
                
                <div className="price-vyroby__link-wrap">
                    <a className="price-vyroby__link" href='/offer' target='_blank'>Публічний договір оферта</a>
                </div>
            </div>
        </div>
    );
}

export default PriceVyrobyView;