import './CreatingVariations.css';
import plusImg from './../../assets/plusImg.svg';
import uploadImg from './../../assets/uploadImg.svg';
import close2 from './../../assets/close2.svg';
import close3 from './../../assets/close3.svg';
import deleteImg4 from './../../assets/deleteImg4.svg';
import arrow6 from './../../assets/arrow6.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Menu,
	MenuItem,
	Select,
} from '@mui/material';
import { handleCategoriesMap, handleSortValue } from '../../helpers/Utils';
import { setShowMessageObj } from '../../store/userSlice';
import ColorResult from '../ColorResult/ColorResult';
import ColorPicker from 'react-best-gradient-color-picker';
import { standardColors } from '../../helpers/Config';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function CreatingVariations({
	item,
	indexCharacteristic,
	newVariations,
	setNewVariations,
}) {
	const editeProduct = useSelector((state) => state.userSlice.editeProduct);
	const categories = useSelector((state) => state.userSlice.categories);
	const [newValue, setNewValue] = useState('');
	const [characteristicValue, setCharacteristicValue] = useState([]);
	const [selectCharacteristic, setSelectCharacteristic] = useState({});
	const [selectCharacteristicValue, setSelectCharacteristicValue] = useState(
		[]
	);
	const [selectNewCharacteristicValue, setSelectNewCharacteristicValue] =
		useState([]);
	const [selectCategory, setSelectCategory] = useState({});
	const [materialNewImg, setMaterialNewImg] = useState('');
	const [materialTitle, setMaterialTitle] = useState('');
	const [materialType, setMaterialType] = useState('');
	const [materialIngredient, setMaterialIngredient] = useState('');
	const [materialKeeping, setMaterialKeeping] = useState('');
	const [isNewColor, setIsNewColor] = useState(false);
	const [selectColor, setSelectColor] = useState({});
	const [color_filter, setColor_filter] = useState('');
	const [newColorName, setNewColorName] = useState('');
	const [imgFile, setImgFile] = useState({});
	const [isNotFirstRender, setIsNotFirstRender] = useState(false);
	const dispatch = useDispatch();
	let token = localStorage.getItem('token');

	useEffect(() => {
		setIsNotFirstRender(true);
	}, []);

	useEffect(() => {
		if (selectColor.name !== 'ownColor') {
			setIsNewColor(false);
		}
	}, [selectColor]);

	useEffect(() => {
		if (isNotFirstRender) {
			setNewVariations((prevValue) => {
				let arr = prevValue.map((el, i) => {
					if (i === indexCharacteristic) {
						return {
							name: el.name,
							value: {
								title: materialTitle,
								type: materialType,
								ingredient: materialIngredient,
								keeping: materialKeeping,
								img: materialNewImg,
							},
						};
					}
					return el;
				});

				return [...arr];
			});
		}
	}, [
		materialTitle,
		materialType,
		materialIngredient,
		materialKeeping,
		materialNewImg,
	]);

	useEffect(() => {
		if (editeProduct?._id?.length) {
			let res = handleCategoriesMap(categories);
			let res2 = res.find((el) => el._id === editeProduct.category_id);
			setSelectCategory(res2?._id?.length ? res2 : {});

			handleClearInput();
		}
	}, [editeProduct]);

	useEffect(() => {
		if (color_filter?.length) {
			setNewVariations((prevValue) => {
				let arr = prevValue.map((el, i) => {
					if (i === indexCharacteristic) {
						el.value = { ...el.value, color_filter: color_filter };
						return el;
					}
					return el;
				});

				return [...arr];
			});
		}
	}, [color_filter]);

	const handleSelectCharacteristics = (value) => {
		let res = JSON.parse(value);
		setSelectCharacteristic(res);
		setNewVariations((prevValue) => {
			let arr = prevValue.map((el, i) => {
				if (i === indexCharacteristic) {
					if (res.name === 'Колір' || res.name === 'Матеріал') {
						return { ...res };
					}
					return { name: res.name, value: [] };
				}
				return el;
			});

			return [...arr];
		});

		if (
			res?.name !== 'Колір' &&
			res?.name !== 'Матеріал' &&
			selectCategory?.characteristics_options?.length
		) {
			let obj = selectCategory?.characteristics_options.find(
				(el) => el.name === res.name
			);
			setCharacteristicValue(obj?.value?.length ? [...obj?.value] : []);
			let resValue = editeProduct.characteristics.find(
				(el) => el.name === res.name
			);
			let resVariation = editeProduct.variations.find(
				(el) => el.name === res.name
			);
			if (resVariation?.value?.length) {
				setSelectCharacteristicValue([
					...selectCharacteristicValue,
					...resVariation.value,
				]);
				return;
			}
			if (resValue.value?.length) {
				setSelectCharacteristicValue([
					...selectCharacteristicValue,
					resValue.value,
				]);
				return;
			}
		} else {
			setCharacteristicValue([]);
		}
	};

	const handleAddNewCharacteristics = (value) => {
		if (selectNewCharacteristicValue.includes(value)) {
			let res = selectNewCharacteristicValue.filter((el) => el !== value);
			setSelectNewCharacteristicValue(res?.length ? [...res] : []);
			setNewVariations((prevValue) => {
				let arr = prevValue.map((el, i) => {
					if (i === indexCharacteristic) {
						return { ...el, value: res?.length ? [...res] : [] };
					}
					return el;
				});
				return [...arr];
			});
		} else {
			setSelectNewCharacteristicValue([...selectNewCharacteristicValue, value]);
			setNewVariations((prevValue) => {
				let arr = prevValue.map((el, i) => {
					if (i === indexCharacteristic) {
						return { ...el, value: [...selectNewCharacteristicValue, value] };
					}
					return el;
				});
				return [...arr];
			});
		}
	};

	const handleKeyDown = (e) => {
		if (e.key == 'Enter') {
			handleAddNewValue();
		}
	};

	const handleAddNewValue = () => {
		if (newValue?.length) {
			if (
				characteristicValue?.length &&
				!characteristicValue.includes(newValue)
			) {
				setCharacteristicValue([...characteristicValue, newValue]);
				setSelectNewCharacteristicValue([
					...selectNewCharacteristicValue,
					newValue,
				]);
				setNewVariations((prevValue) => {
					let arr = prevValue.map((el, i) => {
						if (i === indexCharacteristic) {
							return {
								...el,
								value: [...selectNewCharacteristicValue, newValue],
							};
						}
						return el;
					});
					return [...arr];
				});
				setNewValue('');
			}
		}
	};

	const handleClearInput = () => {
		setCharacteristicValue([]);
		setSelectCharacteristicValue([]);
		setSelectNewCharacteristicValue([]);
		setSelectCharacteristic({});
		setNewValue('');
		setMaterialNewImg('');
		setImgFile({});
		setMaterialTitle('');
		setMaterialType('');
		setMaterialIngredient('');
		setMaterialKeeping('');
		setNewColorName('');
		setIsNewColor(false);
	};

	const handleImageUpload = (e) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		if (file?.name?.length) {
			handleSaveMaterialImg(file);
		}
	};

	const handleUploadMaterialImg = (img) => {
		if (img?.name?.length) {
			if (!hendleTestMaterialNewImg(img)) {
				handleSaveMaterialImg(img);
				setImgFile({});
			} else {
				setImgFile(img);
			}
		}
	};

	const handleSaveMaterialImg = (file) => {
		let formData = new FormData();
		formData.append('file', file);

		fetch(`${process.env.REACT_APP_BASE_URL}/images?token=${token}`, {
			method: 'POST',
			body: formData,
		})
			.then((res) => res.json())
			.then((res) => {
				if (res?.success && res?.data?.length) {
					setMaterialNewImg(res.data);
				} else {
					dispatch(
						setShowMessageObj({
							open: true,
							status: 'error',
							message: 'Сталася помилка',
						})
					);
				}
			});
	};

	const handleCloseSelect = () => {
		setNewVariations((prevValue) => {
			let arr = prevValue.filter((el, i) => i !== indexCharacteristic);
			return [...arr];
		});
	};

	const handleSetColor = (obj) => {
		let res = JSON.parse(obj);
		setSelectColor({ ...res });
		if (res.name === 'ownColor') {
			setIsNewColor(true);
		}
		setNewVariations((prevValue) => {
			let arr = prevValue.map((el, i) => {
				if (i === indexCharacteristic) {
					if (res.name === 'ownColor') {
						el.value = {
							name: '',
							value: '',
							color_filter: el.value.color_filter,
						};
					} else {
						el.value = { ...res, color_filter: el.value.color_filter };
					}
					return el;
				}
				return el;
			});

			return [...arr];
		});
	};

	const setColorPickerValue = (value) => {
		setNewVariations((prevValue) => {
			let arr = prevValue.map((el, i) => {
				if (i === indexCharacteristic) {
					el.value.value = value;
					return el;
				}
				return el;
			});

			return [...arr];
		});
	};

	const setColorName = (value) => {
		setNewColorName(value);
		setNewVariations((prevValue) => {
			let arr = prevValue.map((el, i) => {
				if (i === indexCharacteristic) {
					el.value.name = value;
					return el;
				}
				return el;
			});
			return [...arr];
		});
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClickSettings = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const hendleTestMaterialNewImg = (img) => {
		return img.size && img.size > 150000 ? true : false;
	};

	return (
		<div>
			<Accordion className='creating-variations__characteristics-wrap'>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<div className='creating-variations__input-characteristics-lable'>
						{item?.name?.length ? item?.name : 'Вибрати характеристику'}
						<img
							className='creating-variations__select-close-btn'
							onClick={handleCloseSelect}
							src={close3}
							alt='img'
						/>
					</div>
				</AccordionSummary>
				<AccordionDetails className='creating-variations__characteristics-accordion-details'>
					<div className='creating-variations__select-label-wrap'>
						<div className='creating-variations__select-label'>
							Вибрати варіацію
						</div>
					</div>
					<Select
						value={JSON.stringify(selectCharacteristic)}
						onChange={(e) => handleSelectCharacteristics(e.target.value)}
						className='creating-product__select-mark--wrap creating-variations__select-mark--wrap'
					>
						<MenuItem
							className={`creating-product__select-mark-item ${
								JSON.stringify(selectCharacteristic) === JSON.stringify({})
									? 'creating-collection__select-item-active'
									: ''
							}`}
							value={JSON.stringify({})}
						>
							Характеристика
						</MenuItem>
						{!!editeProduct.color?.length ? (
							<MenuItem
								className={`creating-product__select-mark-item ${
									JSON.stringify(selectCharacteristic) ===
									JSON.stringify({ name: 'Колір', value: '' })
										? 'creating-collection__select-item-active'
										: ''
								}`}
								value={JSON.stringify({ name: 'Колір', value: '' })}
							>
								Колір
							</MenuItem>
						) : !!editeProduct.material?.img?.length ? (
							<MenuItem
								className={`creating-product__select-mark-item ${
									JSON.stringify(selectCharacteristic) ===
									JSON.stringify({ name: 'Матеріал', value: {} })
										? 'creating-collection__select-item-active'
										: ''
								}`}
								value={JSON.stringify({ name: 'Матеріал', value: {} })}
							>
								Матеріал
							</MenuItem>
						) : null}
						{!!editeProduct?.characteristics?.length &&
							editeProduct?.characteristics.map((el) => (
								<MenuItem
									className={`creating-product__select-mark-item ${
										JSON.stringify(selectCharacteristic) === JSON.stringify(el)
											? 'creating-collection__select-item-active'
											: ''
									}`}
									key={el.name}
									value={JSON.stringify(el)}
									disabled={
										newVariations.find((ell) => ell.name === el?.name)?.name
											?.length
											? true
											: false
									}
								>
									{el.name}
								</MenuItem>
							))}
					</Select>

					<div className='creating-variations__result--wrap'>
						{!!characteristicValue?.length &&
							item?.name !== 'Колір' &&
							item?.name !== 'Матеріал' && (
								<>
									<div className='creating-variations__select-label creating-variations__select-label-color'>
										Додати нове значення
									</div>
									<div className='creating-variations__btn-select-wrap'>
										<button
											id={`basic-button-${item?.name}`}
											aria-controls={open ? 'basic-menu' : undefined}
											aria-haspopup='true'
											aria-expanded={open ? 'true' : undefined}
											onClick={handleClickSettings}
											className='creating-variations__btn-select'
											disableRipple={true}
										>
											<img
												className={`creating-variations__btn-select-img ${
													open
														? 'creating-variations__btn-select-img--open'
														: ''
												}`}
												src={arrow6}
												alt='img'
											/>
										</button>
										<Menu
											id={`basic-menu-${item?.name}`}
											anchorEl={anchorEl}
											open={open}
											onClose={() => setAnchorEl(null)}
											className='creating-variations__result-wrap'
											disableRipple={true}
										>
											{!!characteristicValue?.length &&
												handleSortValue([...characteristicValue]).map((el) => (
													<MenuItem
														onClick={() => handleAddNewCharacteristics(el)}
														className={`creating-variations__result-label ${
															selectCharacteristicValue.find(
																(ell) => ell === el
															) ||
															(selectNewCharacteristicValue?.length &&
																selectNewCharacteristicValue.find(
																	(ell) => ell === el
																))
																? 'disabledContainer creating-variations__result-label--disabled'
																: ''
														}`}
														disableRipple={true}
														key={el}
													>
														<span className='creating-variations__result-label-item'>
															{el}
														</span>
													</MenuItem>
												))}
										</Menu>

										<div className='creating-category__add-btn--wrap creating-variations__add-btn--wrap'>
											<input
												className='creating-category__input creating-variations__input'
												value={newValue}
												onChange={(e) => setNewValue(e.target.value)}
												onKeyDown={(e) => handleKeyDown(e)}
												placeholder='Додати нове значення'
												type='text'
											/>
											<img
												className='creating-category__add-btn'
												onClick={handleAddNewValue}
												src={plusImg}
												alt='img'
											/>
										</div>
									</div>
									<div className='creating-variations__result-characteristic--wrap'>
										{!!selectNewCharacteristicValue?.length &&
											selectNewCharacteristicValue.map((el) => (
												<div
													className={`creating-variations__result-characteristic-wrap ${
														selectCharacteristicValue.find((ell) => ell === el)
															? 'displayNone'
															: ''
													}`}
													key={el}
												>
													<span>{el}</span>
													<img
														className='creating-variations__result-characteristic-btn'
														onClick={() => handleAddNewCharacteristics(el)}
														src={deleteImg4}
														alt='img'
													/>
												</div>
											))}
									</div>
								</>
							)}
						{item?.name === 'Матеріал' && (
							<>
								<div className='creating-variations__material-result-wrap'>
									<div className='creating-variations__material-create'>
										<div className='creating-variations__material-create-input-wrap'>
											<div className='creating-variations__material-create-lable-wrap'>
												<div className='creating-variations__material-create-lable requiredFields'>
													Назва матеріалу
												</div>
												<input
													className='creating-category__input'
													value={materialTitle}
													onChange={(e) => setMaterialTitle(e.target.value)}
													placeholder='Введіть назву матеріалу'
													type='text'
												/>
											</div>
											<div className='creating-variations__material-create-lable-wrap'>
												<div className='creating-variations__material-create-lable'>
													Тип матеріалу
												</div>
												<input
													className='creating-category__input'
													value={materialType}
													onChange={(e) => setMaterialType(e.target.value)}
													placeholder='Введіть тип матеріалу, наприклад "Поліестер"'
													type='text'
												/>
											</div>
											<div className='creating-variations__material-create-lable-wrap'>
												<div className='creating-variations__material-create-lable'>
													Склад матеріалу
												</div>
												<input
													className='creating-category__input'
													value={materialIngredient}
													onChange={(e) =>
														setMaterialIngredient(e.target.value)
													}
													placeholder='Введіть склад матеріалу'
													type='text'
												/>
											</div>
											<div className='creating-variations__material-create-lable-wrap'>
												<div className='creating-variations__material-create-lable'>
													Догляд матеріалу
												</div>
												<textarea
													className='creating-category__textarea creating-variations__textarea'
													value={materialKeeping}
													onChange={(e) => setMaterialKeeping(e.target.value)}
													placeholder='Введіть догляд за матеріалом'
													rows={8}
													type='text'
												/>
											</div>
										</div>
										<div className='creating-variations__material-create-label-wrap'>
											{!!materialNewImg?.length && !imgFile.name?.length && (
												<>
													<div className='creating-variations__material-result-img-wrap'>
														<img
															className='creating-variations__material-result-img'
															src={materialNewImg}
															alt='img'
														/>
													</div>
												</>
											)}
											{!!imgFile.name?.length && (
												<>
													<div className='creating-variations__material-result-img-wrap'>
														<img
															className='creating-variations__material-result-img'
															src={URL.createObjectURL(imgFile)}
															alt='img'
														/>
														<div className='creating-product__material-result-img-error'>
															Розмір картинки перевищує 150kb
														</div>
													</div>
												</>
											)}
											<div
												className='creating-variations__create-img-wrap'
												onDrop={(e) => handleImageUpload(e)}
												onDragOver={(e) => e.preventDefault()}
											>
												<div className='creating-product__create-img-text-wrap'>
													<div className='creating-product__create-img-text-img-wrap'>
														<img
															className='creating-product__create-img-text-img'
															src={uploadImg}
															alt='img'
														/>
													</div>
													<label
														className='creating-product__create-img-link-upload-lable'
														htmlFor={`creationProductUserImages${indexCharacteristic}`}
													>
														Клікніть завантажити
													</label>
													<span> або перетягніть зображення сюди</span>
													<p>Формати які підтримуються: PNG, JPG</p>
													<input
														className='creating-product__create-img-link-upload-input'
														onChange={(e) =>
															handleUploadMaterialImg(e.target.files[0])
														}
														multiple
														type='file'
														accept='image/*'
														id={`creationProductUserImages${indexCharacteristic}`}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
						{item?.name === 'Колір' && (
							<>
								<div className='creating-variations__color-result--wrap'>
									{!!standardColors?.length && (
										<div className='creating-variations__characteristics-result'>
											<div className='creating-variations__characteristics-result-color-title'>
												Колір для фільтру
											</div>
											<Select
												value={color_filter?.length ? color_filter : ' '}
												onChange={(e) => setColor_filter(e.target.value)}
												className='creating-product__select-mark--wrap creating-variations__select-mark--wrap'
												MenuProps={{
													classes: {
														root: 'creating-variations__menu-item-wrap',
													},
												}}
											>
												<MenuItem value=' '>
													<div className='creating-product__select-mark-wrap'>
														<span>Без кольору</span>
													</div>
												</MenuItem>
												{!!standardColors?.length &&
													standardColors.map((el, i) => (
														<MenuItem value={el.value} key={el.value + i}>
															<div className='creating-product__select-mark-wrap'>
																<ColorResult color={el.value} />{' '}
																<span>{el.name}</span>
															</div>
														</MenuItem>
													))}
											</Select>
										</div>
									)}
									<div className='creating-variations__characteristics-result-color-title'>
										Колір виробу
									</div>
									<Select
										value={JSON.stringify(selectColor)}
										onChange={(e) => handleSetColor(e.target.value)}
										className='creating-product__select-mark--wrap creating-variations__select-mark--wrap'
										MenuProps={{
											classes: {
												root: 'creating-variations__menu-item-wrap',
											},
										}}
									>
										<MenuItem value={JSON.stringify({})}>
											<div className='creating-product__select-mark-wrap'>
												<span>Без кольору</span>
											</div>
										</MenuItem>
										<MenuItem
											value={JSON.stringify({ name: 'ownColor', value: '' })}
										>
											<div className='creating-product__select-mark-wrap'>
												<span>Свій колір</span>
											</div>
										</MenuItem>
										{!!standardColors?.length &&
											standardColors.map((el, i) => (
												<MenuItem value={JSON.stringify(el)} key={el.value + i}>
													<div className='creating-product__select-mark-wrap'>
														<ColorResult color={el.value} />{' '}
														<span>{el.name}</span>
													</div>
												</MenuItem>
											))}
									</Select>
									{isNewColor && (
										<div className='creating-variations__color-variations-result--wrap'>
											<input
												className='creating-category__input'
												value={newColorName}
												onChange={(e) => setColorName(e.target.value)}
												placeholder='Введіть назву кольору'
												type='text'
											/>
											{!!item?.value?.value?.length && (
												<div className='creating-category__characteristic-add-result creating-variations__characteristic-add-result'>
													<ColorResult color={item?.value?.value} />
													{!!newColorName?.length && (
														<span>{newColorName}</span>
													)}
													<img
														className='select-characteristic__add-result-del-words-btn creating-product__color-result-btn'
														onClick={() => setColorName('')}
														src={close2}
														alt='img'
													/>
												</div>
											)}
										</div>
									)}
									{isNewColor && (
										<div className='creating-variations__color-picker-wrap'>
											<ColorPicker
												value={item?.value?.value}
												onChange={setColorPickerValue}
											/>
										</div>
									)}
								</div>
							</>
						)}
					</div>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}

export default CreatingVariations;
