import './ProductCard.css';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import noPhotos from './../../assets/noPhotos.svg';
import likeEllipse from './../../assets/likeEllipse.svg';
import likeEllipseHover from './../../assets/likeEllipseHover.svg';
import { Skeleton } from '@mui/material';
import {
	fetchRequest,
	handleCheckShortId,
	handlePriceReplace,
	handleReturnItemVariantGtm,
	handleReturnProductCategoryAll,
	refreshSavedScroll,
} from '../../helpers/Utils';
import { setLikeProducts } from '../../store/homeSlice';
import ColorResult from '../ColorResult/ColorResult';
import MaterialResult from '../MaterialResult/MaterialResult';
import { useEffect, useMemo, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { setShowMessageObj } from '../../store/userSlice';

function ProductCard({
	product,
	handleNextVariation = null,
	blocksId = null,
	isPreloader = false,
	onCardClick = () => {},
}) {
	const categoriesShop = useSelector((state) => state.homeSlice.categoriesShop);
	const likeProducts = useSelector((state) => state.homeSlice.likeProducts);
	const [amountVariations, setAmountVariations] = useState(0);
	const [isTouchStart, setIsTouchStart] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const containerRef = useRef(null);
	const cardRef = useRef(null);

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		if (containerRef.current && amountVariations === 0) {
			setAmountVariations(Math.floor(containerRef.current.clientWidth / 45));
		}
	}, [containerRef]);

	const handleResize = () => {
		if (containerRef.current) {
			setAmountVariations(Math.floor(containerRef.current.clientWidth / 45));
		}
	};

	const handleOpenProduct = () => {
		if (
			product.variations?.length &&
			product.variations.find(
				(el) => el.name === 'Колір' || el.name === 'Матеріал'
			)
		) {
			return;
		}
		return navigate(handleCheckShortId(product));
	};

	const handleVariationsCount = useMemo(() => {
		let res = 0;
		if (product.variations?.length) {
			product.variations.forEach((el) => {
				if (el.name !== 'Колір' && el.name !== 'Матеріал') {
					res = res + (el.value?.length - 1);
				}
			});
		}
		return res;
	}, [product.variations]);

	const handleLikeGtm = (product) => {
		let res = handleReturnProductCategoryAll(product, categoriesShop);
		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			event: 'add_to_wishlist',
			ecommerce: {
				value: product.price.toFixed(2),
				currency: 'UAH',
				items: [
					{
						item_id: product._id,
						google_business_vertical: 'retail',
						id: product._id,
						item_name: product.title,
						price: product.price.toFixed(2),
						item_category: res?.cat?.name?.length ? res?.cat?.name : '',
						item_category2: res?.subCat?.name ? res?.subCat?.name : '',
						item_variant: handleReturnItemVariantGtm(product),
						item_brand: product.vendor_name || '',
						quantity: 1,
					},
				],
			},
		});
	};

	const handleLike = (obj) => {
		if (likeProducts?.length) {
			let res = likeProducts.find((el) => el._id === obj._id);
			if (res) {
				let res2 = likeProducts.filter((el) => el._id !== obj._id);
				dispatch(setLikeProducts(res2));
				localStorage.setItem(
					'likeProductsIds',
					JSON.stringify([...res2.map((el) => el._id)])
				);
			} else {
				handleLikeGtm(obj);
				dispatch(setLikeProducts([...likeProducts, obj]));
				localStorage.setItem(
					'likeProductsIds',
					JSON.stringify([...likeProducts.map((el) => el._id), obj._id])
				);
			}
		} else {
			handleLikeGtm(obj);
			dispatch(setLikeProducts([obj]));
			localStorage.setItem('likeProductsIds', JSON.stringify([obj._id]));
		}
	};

	const handleSelectVariation = (obj) => {
		let resArr = [];
		let res = [
			...product.characteristics.filter((el) =>
				product.variations.find((ell) => ell.name === el.name)
			),
		];
		if (res?.length) {
			resArr = [
				...res.map((el) => {
					if (el.name === obj.name) {
						return obj;
					}
					return el;
				}),
			];
		}
		if (obj.name === 'Колір' || obj.name === 'Матеріал') {
			resArr = [...resArr, obj];
		} else {
			if (product.color?.length) {
				resArr.push({
					name: 'Колір',
					value: {
						color: product.color,
						color_name: product.color_name,
						color_filter: product.color_filter,
					},
				});
			}
			if (product.material?.title?.length) {
				resArr.push({ name: 'Матеріал', value: { ...product.material } });
			}
		}
		let data = {
			_id: product._id,
			variations: resArr,
		};

		fetchRequest(
			'POST',
			`${process.env.REACT_APP_BASE_URL}/products_variations/gettest`,
			data,
			() =>
				dispatch(
					setShowMessageObj({
						open: true,
						status: 'error',
						message: 'Сталася помилка',
					})
				)
		).then((res) => {
			if (res.success && res.data) {
				if (blocksId && handleNextVariation) {
					handleNextVariation(res.data, product._id, blocksId);
					return;
				}
				if (handleNextVariation) {
					handleNextVariation(res.data, product._id);
				}
			} else {
				console.log('GET ProductCard', res);
			}
		});
	};

	return (
		<div
			className='product-card'
			ref={cardRef}
			onTouchStart={() => setIsTouchStart(true)}
			onTouchEnd={() => setIsTouchStart(false)}
			onClick={() => refreshSavedScroll(handleCheckShortId(product))}
		>
			{isPreloader && (
				<div className='product-card__skeleton-wrap'>
					<Skeleton className='skeleton-wrap__img' variant='rectangular' />
					<Skeleton className='skeleton-wrap__variations' />
					<Skeleton className='skeleton-wrap__text skeleton-wrap__text-1' />
					<Skeleton className='skeleton-wrap__text skeleton-wrap__text-3' />
					<Skeleton className='skeleton-wrap__text skeleton-wrap__text-2' />
					<Skeleton className='skeleton-wrap__text skeleton-wrap__text-3' />
				</div>
			)}
			<div
				className={`product-card-wrap ${
					isPreloader ? 'product-card-wrap--is-preloader' : ''
				}`}
			>
				<div className='product-card__img-wrap' onClick={onCardClick}>
					{!!product.images?.length && product.images?.length >= 2 ? (
						<NavLink
							className='product-card__img-link'
							to={handleCheckShortId(product)}
						>
							<img
								className='product-card__img product-card__img--1'
								src={product.images[0]}
								alt={product.title}
							/>
							{!!product.images[1]?.length && (
								<img
									className='product-card__img product-card__img--2'
									src={product.images[1]}
									alt={product.title}
								/>
							)}
						</NavLink>
					) : !!product.images?.length ? (
						<NavLink
							className='product-card__img-link'
							to={handleCheckShortId(product)}
						>
							<img
								className='product-card__img'
								src={product.images[0]}
								alt={product.title}
							/>
						</NavLink>
					) : (
						<NavLink
							className='product-card__img-link'
							to={handleCheckShortId(product)}
						>
							<img
								className='product-card__img'
								src={noPhotos}
								alt={product.title}
							/>
						</NavLink>
					)}
					{!!product.mark?.text?.length && (
						<div
							className='product-card__mark'
							style={{
								background: `${
									product.mark.background?.length
										? product.mark.background
										: '#19191D'
								}`,
							}}
						>
							{product.mark.text}
						</div>
					)}

					{likeProducts.find((ell) => ell._id === product._id) ? (
						<button
							key={product._id + 1}
							className={`product-card__like-btn-wrap product-card__like-btn-wrap--active`}
							onClick={(e) => {
								e.stopPropagation();
								handleLike(product);
							}}
						>
							<img
								className={`product-card__like-btn`}
								src={likeEllipseHover}
								alt='img'
							/>
						</button>
					) : (
						<button
							style={isTouchStart ? { display: 'flex' } : {}}
							key={product._id + 2}
							className='product-card__like-btn-wrap'
							onClick={(e) => {
								e.stopPropagation();
								handleLike(product);
							}}
						>
							<img
								className={`product-card__like-btn`}
								src={likeEllipse}
								alt='img'
							/>
						</button>
					)}
					{product.is_deleted && (
						<div className='product-card__product-delete-text'>Видалено</div>
					)}
				</div>

				<NavLink
					onClick={onCardClick}
					className='product-card__title-wrap'
					to={handleCheckShortId(product)}
				>
					<div className='product-card__title'>{product.title}</div>
					<div className='product-card__text'>
						{handlePriceReplace(product.price)} грн{' '}
						{!!product.retail_type?.length ? product.retail_type : ''}
					</div>
					{!!product.variations?.length && handleVariationsCount > 0 && (
						<div className='product-card__variations-count'>
							{' '}
							+{handleVariationsCount}{' '}
							<span className='product-card__variations-count-text'>
								{handleVariationsCount === 1
									? 'Варіант'
									: handleVariationsCount >= 2 && handleVariationsCount <= 4
									? 'Варіанти'
									: 'Варіантів'}
							</span>
						</div>
					)}
				</NavLink>

				<div
					className='product-card__variations-select-wrap'
					onClick={() => handleOpenProduct()}
				>
					{!!product.variations?.length &&
						product.variations.map((el, i) => (
							<div key={el.name + i} ref={containerRef}>
								{el.name === 'Колір' ? (
									<>
										<div className='product-card__variations-select-color-wrap'>
											{!!el.value?.length &&
											(el.value?.length <= 3 ||
												el.value?.length < amountVariations)
												? el.value.map((variation, inde) => (
														<div
															onClick={(e) => {
																e.stopPropagation();
																handleSelectVariation({
																	name: el.name,
																	value: variation,
																});
															}}
															key={variation.color + inde}
														>
															<ColorResult
																productColor={product.color}
																color={variation.color}
															/>
														</div>
												  ))
												: !!el.value?.length &&
												  el.value?.length > 3 && (
														<>
															{amountVariations !== 0 &&
																el.value
																	.slice(0, amountVariations - 1)
																	.map((variation, inde) => (
																		<div
																			onClick={(e) => {
																				e.stopPropagation();
																				handleSelectVariation({
																					name: el.name,
																					value: variation,
																				});
																			}}
																			key={variation.color + inde}
																		>
																			<ColorResult
																				productColor={product.color}
																				color={variation.color}
																			/>
																		</div>
																	))}
															<NavLink
																className='product-card__variations-select-color-all-btn'
																to={handleCheckShortId(product)}
															>
																+{el.value?.length - amountVariations + 1}
															</NavLink>
														</>
												  )}
										</div>
									</>
								) : el.name === 'Матеріал' ? (
									<>
										<div className='product-card__variations-select-color-wrap'>
											{!!el.value?.length &&
											(el.value?.length <= 3 ||
												el.value?.length < amountVariations)
												? el.value.map((variation, inde) => (
														<div
															onClick={(e) => {
																e.stopPropagation();
																handleSelectVariation({
																	name: el.name,
																	value: variation,
																});
															}}
															key={variation.title + inde}
														>
															<MaterialResult
																productMaterial={product.material}
																variation={variation}
															/>
														</div>
												  ))
												: !!el.value?.length &&
												  el.value?.length > 3 && (
														<>
															{amountVariations !== 0 &&
																el.value
																	.slice(0, amountVariations - 1)
																	.map((variation, inde) => (
																		<div
																			onClick={(e) => {
																				e.stopPropagation();
																				handleSelectVariation({
																					name: el.name,
																					value: variation,
																				});
																			}}
																			key={variation.title + inde}
																		>
																			<MaterialResult
																				productMaterial={product.material}
																				variation={variation}
																			/>
																		</div>
																	))}
															<NavLink
																className='product-card__variations-select-color-all-btn'
																to={handleCheckShortId(product)}
															>
																+{el.value?.length - amountVariations + 1}
															</NavLink>
														</>
												  )}
										</div>
									</>
								) : (
									<></>
								)}
							</div>
						))}
				</div>
			</div>
			<NavLink to={handleCheckShortId(product)} onClick={onCardClick}>
				<button className='product-card__btn-by'>Переглянути</button>
			</NavLink>
		</div>
	);
}

export default ProductCard;
