import './SuccessView.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsSuccessCheckoutView, setIsSuccessFormOrderView, setIsSuccessFormPartnersView } from '../../store/homeSlice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function SuccessView() {
    const isSuccessFormOrderView = useSelector(state => state.homeSlice.isSuccessFormOrderView);
    const isSuccessFormPartnersView = useSelector(state => state.homeSlice.isSuccessFormPartnersView);
    const isSuccessCheckoutView = useSelector(state => state.homeSlice.isSuccessCheckoutView);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isSuccessFormOrderView && !isSuccessFormPartnersView && !isSuccessCheckoutView) {
            navigate('/')
        }

        return () => {
            dispatch(setIsSuccessFormOrderView(false))
            dispatch(setIsSuccessFormPartnersView(false))
            dispatch(setIsSuccessCheckoutView(false))
        }
    }, [])

    return (
        <div className="success-view">
            {
                isSuccessCheckoutView &&
                    <>
                        <div className="success-view__title">Дякуємо за замовлення!</div>
                        <div className="success-view__text">Найближчим часом з Вами звʼяжеться продавець щодо замовлення!</div>
                    </>
            }
            {
                isSuccessFormOrderView &&
                    <>
                        <div className="success-view__title">Дякуємо за Ваш запит!</div>
                        <div className="success-view__text">Найближчим часом з Вами зв'яжеться виробник і надасть консультацію</div>
                    </>
            }
            {
                isSuccessFormPartnersView &&
                    <>
                        <div className="success-view__title">Дякуємо за реєстрацію!</div>
                        <div className="success-view__text">Найближчим часом ми з вами зв'яжемося</div>
                    </>
            }
        </div>
    );
}

export default SuccessView;