import './ShoppingCart.css';
import { useState, useEffect, memo, useMemo } from 'react';
import closeImg from '../../assets/closeImg.svg';
import close from '../../assets/close.svg';
import closeImgBlack from '../../assets/closeImgBlack.svg';
import noPhotos from '../../assets/noPhotos.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@mui/material';
import {
	deleteShoppingProducts,
	setIsOpenShoppingCart,
	setShoppingProducts,
} from '../../store/homeSlice';
import { NavLink, useNavigate } from 'react-router-dom';
import {
	handleCheckShortId,
	handlePriceReplace,
	handleReturnItemVariantGtm,
	handleReturnProductCategoryAll,
} from '../../helpers/Utils';
import MaterialResult from '../MaterialResult/MaterialResult';
import ColorResult from '../ColorResult/ColorResult';

function ShoppingCart() {
	const shoppingProducts = useSelector(
		(state) => state.homeSlice.shoppingProducts
	);
	const { isOpen: isOpenShoppingCart, isOpenByButton } = useSelector(
		(state) => state.homeSlice.isOpenShoppingCart
	);
	const modalShoppingScrollPosition = useSelector(
		(state) => state.homeSlice.modalShoppingScrollPosition
	);
	const categoriesShop = useSelector((state) => state.homeSlice.categoriesShop);
	const [totalPrice, setTotalPrice] = useState('');
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleViewCartGtm = (products) => {
		const totalAmount = shoppingProducts.reduce(
			(total, obj) => total + obj.count * obj.price,
			0
		);

		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			event: 'view_cart',
			ecommerce: {
				value: totalAmount.toFixed(2),
				currency: 'UAH',
				items: products.map((product) => ({
					item_id: product._id,
					item_name: product.title,
					price: product.price.toFixed(2),
					item_brand: product.vendor_name,
					item_category: handleReturnProductCategoryAll(product, categoriesShop)
						?.cat?.name?.length
						? handleReturnProductCategoryAll(product, categoriesShop)?.cat?.name
						: '',
					item_category2: handleReturnProductCategoryAll(
						product,
						categoriesShop
					)?.subCat?.name
						? handleReturnProductCategoryAll(product, categoriesShop)?.subCat
								?.name
						: '',
					item_variant: handleReturnItemVariantGtm(product),
					quantity: product.count,
				})),
			},
		});
	};

	useEffect(() => {
		let res = shoppingProducts.reduce(
			(total, obj) => total + obj.count * obj.price,
			0
		);
		setTotalPrice(res);
		if (isOpenByButton && !!shoppingProducts.length)
			handleViewCartGtm(shoppingProducts);
	}, [shoppingProducts, isOpenByButton]);

	const handleEditeProductCount = (str, id) => {
		let res = shoppingProducts.map((el) => {
			if (el._id === id) {
				if (str === '-1') {
					el = { ...el, count: el.count - 1 > 0 ? el.count - 1 : 1 };
				} else {
					el = { ...el, count: el.count + 1 };
				}
			}
			return el;
		});

		dispatch(setShoppingProducts(res));
	};

	const handleaRemoveFromCartGtm = (product) => {
		let res = handleReturnProductCategoryAll(product, categoriesShop);

		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push({
			event: 'remove_from_cart',
			ecommerce: {
				items: [
					{
						item_name: product.title,
						item_id: product._id,
						price: product.price.toFixed(2),
						item_brand: product.vendor_name,
						item_category: res?.cat?.name?.length ? res?.cat?.name : '',
						item_category2: res?.subCat?.name ? res?.subCat?.name : '',
						item_variant: handleReturnItemVariantGtm(product),
						quantity: Number(product.count),
					},
				],
			},
		});
	};

	const handleDeleteProduct = (obj) => {
		dispatch(deleteShoppingProducts(obj._id));
		handleaRemoveFromCartGtm(obj);
	};

	const handleTotalProductPrice = (obj) => {
		return obj.count * obj.price;
	};

	const handleToOrder = () => {
		navigate('/checkout');
		handleCloseModal();
	};

	const handleCloseModal = () => {
		dispatch(setIsOpenShoppingCart({ isOpen: false, isOpenByButton: false }));
		document.body.style.cssText = '';
		window.scroll({ top: modalShoppingScrollPosition });
		document.documentElement.style.scrollBehavior = '';
	};

	const handleCheckVariations = useMemo(() => {
		const check = (product) => {
			let res = [];
			if (!!product.characteristics?.length) {
				let resArr = product.characteristics.filter((ell) =>
					product.variations.some(
						(characteristic) => characteristic.name === ell.name
					)
				);
				res = resArr?.length ? [...resArr] : [];
			}
			return res;
		};
		return check;
	}, [shoppingProducts]);

	return (
		<div className='shopping-cart'>
			<Modal open={isOpenShoppingCart} onClose={handleCloseModal}>
				<div className='shopping-cart__modal-form-wrap'>
					<div
						className='shopping-cart__modal-form--wrap'
						onClick={handleCloseModal}
					></div>
					<img
						className='shopping-cart__modal-form-btn-close shopping-cart__modal-form-btn-close--desktop'
						onClick={handleCloseModal}
						src={closeImg}
						alt='img'
					/>
					<div
						className={`shopping-cart__modal-form ${
							isSafari ? 'shopping-cart__modal-form--is-safari' : ''
						}`}
					>
						<div className='shopping-cart__modal-form-title'>
							<span>Кошик</span>
							<button className='shopping-cart__modal-form-btn-close--mobile-wrap'>
								<img
									className='shopping-cart__modal-form-btn-close shopping-cart__modal-form-btn-close--mobile'
									onClick={handleCloseModal}
									src={closeImgBlack}
									alt='img'
								/>
							</button>
						</div>
						<div className='shopping-cart__modal-products'>
							{!!shoppingProducts?.length ? (
								shoppingProducts.map((el, i) => (
									<div
										className='shopping-cart__modal-product-wrap'
										key={el._id}
									>
										<div className='shopping-cart__modal-product--wrap'>
											<div className='shopping-cart__modal-product'>
												<NavLink
													to={handleCheckShortId(el)}
													onClick={() => handleCloseModal()}
												>
													<img
														className='shopping-cart__modal-product-img'
														src={!!el.images?.length ? el.images[0] : noPhotos}
														alt='img'
													/>
												</NavLink>
												<div className='shopping-cart__modal-product-info'>
													<div className='shopping-cart__modal-product-info-name-wrap'>
														<NavLink
															className='shopping-cart__modal-product-info-name'
															onClick={handleCloseModal}
															to={handleCheckShortId(el)}
														>
															{el.title}
														</NavLink>
														<div className='shopping-cart__modal-product-info-name-code'>
															{el.code}
														</div>
														{!!el.variations?.length &&
															el.variations.some(
																(ell) => ell.name === 'Колір'
															) && (
																<div className='shopping-cart__modal-product-info-variations'>
																	<span>{el.color_name}:</span>
																	<ColorResult
																		productColor={el.color}
																		color={el.color}
																	/>
																</div>
															)}
														{!!el.variations?.length &&
															el.variations.some(
																(ell) => ell.name === 'Матеріал'
															) && (
																<div className='shopping-cart__modal-product-info-variations'>
																	<span>{el.material.title}:</span>
																	<MaterialResult
																		productMaterial={el.material}
																		variation={el.material}
																	/>
																</div>
															)}
														{!!el.variations?.length &&
															!!handleCheckVariations(el)?.length &&
															handleCheckVariations(el).map((charact) => (
																<div
																	className='shopping-cart__modal-product-info-variations'
																	key={charact.name}
																>
																	<span>{charact.name}:</span>
																	<span>{charact.value}</span>
																</div>
															))}
													</div>
													<div className='shopping-cart__modal-product-info-price--wrap'>
														<div className='shopping-cart__modal-product-info-price-wrap'>
															<button
																className='shopping-cart__modal-product-info-price-count-btn'
																onClick={() =>
																	handleEditeProductCount('-1', el._id)
																}
															>
																-
															</button>
															{el.count}
															<button
																className='shopping-cart__modal-product-info-price-count-btn'
																onClick={() =>
																	handleEditeProductCount('+1', el._id)
																}
															>
																+
															</button>
														</div>
														<div className='shopping-cart__modal-product-info-price'>
															{handlePriceReplace(handleTotalProductPrice(el))}{' '}
															грн
														</div>
													</div>
												</div>
												<button
													className='shopping-cart__modal-product-delete-btn'
													onClick={() => handleDeleteProduct(el)}
												>
													<img src={close} alt='img' />
												</button>
											</div>
										</div>
									</div>
								))
							) : (
								<div className='shopping-cart__product-error'>
									Ваш кошик пустий!!!
								</div>
							)}
						</div>
						{!!shoppingProducts?.length && (
							<div className='shopping-cart__form-input-wrap'>
								<div className='shopping-cart__form-price'>
									Сума: {handlePriceReplace(totalPrice)} грн
								</div>

								<div className='shopping-cart__form-send-btn--wrap'>
									<button
										className='shopping-cart__form-btn-back'
										onClick={() => handleCloseModal()}
									>
										Продовжити покупку
									</button>
									<button
										className='shopping-cart__form-send-btn-buy'
										onClick={handleToOrder}
									>
										Оформити замовлення
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			</Modal>
		</div>
	);
}

export default memo(ShoppingCart);
