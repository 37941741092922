export let toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],       
    ['blockquote', 'code-block'],
  
    [{ 'header': 1 }, { 'header': 2 }],               
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      
    [{ 'indent': '-1'}, { 'indent': '+1' }],       
    [{ 'direction': 'rtl' }],                       
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],         
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean']                                     
  ];

export let NOT_DELETED_CATEGORY_ID = "658585f3a7f366f95c567f37";
export let NOT_DELETED_SUB_CATEGORY_ID = "65a517a715f61d0b244d626c";
export let NOT_DELETED_TREND_CATEGORY_ID = "65858254a7f366f95c567ea4";
export let NOT_DELETED_TREND_ID = "65ba4040ef3f0ceeeac44777";
export let NOT_DELETED_TREND_ALIAS = "dlya-ditei";

export let standardColors = [
  {name: 'Сірий', value: '#929292'},
  {name: 'Бежевий', value: '#f4f3d7'},
  {name: 'Білий', value: '#ffffff'},
  {name: 'Чорний', value: '#111111'},
  {name: 'Коричневий', value: '#814820'},
  {name: 'Синій', value: '#0058a3'},
  {name: 'Зелений', value: '#3b7d22'},
  {name: 'Червоний', value: '#ff0000'},
  {name: 'Жовтий', value: '#ffdb00'},
  {name: 'Оранжевий', value: '#ff9a02'},
  {name: 'Рожевий', value: '#ffb8c3'},
  {name: 'Фіолетовий', value: '#a96ecc'},
  {name: 'Бірюзовий', value: '#77ddd0'},
  {name: 'Мультиколір', value: 'multicolor'},
];

export let orderStatusArr = {
  pending: 'В очікуванні',
  calculation: 'Переговори',
  in_progress: 'В роботі',
  completed: 'Виконано',
  cancelled: 'Скасовано',
};