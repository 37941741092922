import './Layout.css';
import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import ButtonScroll from '../components/ButtonScroll/ButtonScroll';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import {
	fetchGetData,
	fetchRequest,
	handleCheckShortId,
} from '../helpers/Utils';
import {
	getCategoriesShop,
	setIsOrderPlaced,
	setSeo,
	setShoppingProducts,
	setHeaderLinks,
	setLikeProducts,
	setUtm_source,
	setUtm_medium,
	setIsOld_idUrl,
	setUtm_campaign,
	setIsViewPreloader,
	setScrollPositionBeforeView,
} from '../store/homeSlice';
import { Alert, Snackbar, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import PreloaderProductCustom2 from '../components/PreloaderProductCustom2/PreloaderProductCustom2';

function Layout() {
	const isOrderPlaced = useSelector((state) => state.homeSlice.isOrderPlaced);
	const isSearchProduct = useSelector(
		(state) => state.homeSlice.isSearchProduct
	);
	const isViewPreloader = useSelector(
		(state) => state.homeSlice.isViewPreloader
	);
	const [showButton, setShowButton] = useState(false);
	const [windowScrollY, setWindowScrollY] = useState(0);
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const scrollBtn = useRef(null);
	const [previousPathname, setPreviousPathname] = useState(location.pathname);

	useEffect(() => {
		fetchGetData(
			`${process.env.REACT_APP_BASE_URL}/categories/all?catalog=1`
		).then((res) => {
			if (res.success && res.data) {
				dispatch(getCategoriesShop(res.data));
			} else {
				console.log('GET Layout', res);
			}
			dispatch(setIsViewPreloader(false));
		});

		fetchGetData(`${process.env.REACT_APP_BASE_URL}/seo/all`).then((res) => {
			if (res.success && res.data) {
				if (res.data?.length) {
					dispatch(setSeo(res.data[0]));
				}
			} else {
				console.log('GET Layout', res);
			}
		});

		fetchGetData(
			`${process.env.REACT_APP_BASE_URL}/configurations?isBanners=${false}`
		).then((res) => {
			if (res.success && res.data) {
				dispatch(
					setHeaderLinks(
						res.data.header_links?.linksValue?.length
							? res.data.header_links
							: {}
					)
				);
			} else {
				console.log('GET Layout', res);
			}
		});

		let localshoppingProducts = localStorage.getItem('localShoppingProducts');
		if (localshoppingProducts?.length) {
			dispatch(setShoppingProducts(JSON.parse(localshoppingProducts)));
		}

		let likeProductsIds = localStorage.getItem('likeProductsIds');
		if (likeProductsIds?.length) {
			let data = {
				ids: [...JSON.parse(likeProductsIds)],
			};
			fetchRequest(
				'POST',
				`${process.env.REACT_APP_BASE_URL}/products/array`,
				data
			).then((res) => {
				if (res?.success && res.data?.length) {
					localStorage.setItem(
						'likeProductsIds',
						JSON.stringify([...res.data.map((el) => el._id)])
					);
					dispatch(setLikeProducts(res.data));
				}
				if (res?.success && !res.data?.length) {
					localStorage.removeItem('likeProductsIds');
				}
			});
		}

		let localUtm_source = localStorage.getItem('localUtm_source');
		if (localUtm_source?.length) {
			dispatch(setUtm_source(JSON.parse(localUtm_source)));
		}

		let localUtm_medium = localStorage.getItem('localUtm_medium');
		if (localUtm_medium?.length) {
			dispatch(setUtm_medium(JSON.parse(localUtm_medium)));
		}

		let localUtm_campaign = localStorage.getItem('localUtm_campaign');
		if (localUtm_campaign?.length) {
			dispatch(setUtm_campaign(JSON.parse(localUtm_campaign)));
		}

		handleQueryParam();

		if (location?.pathname?.length && location?.pathname.includes('tproduct')) {
			handleGetOldProduct();
		} else {
			dispatch(setIsOld_idUrl(false));
		}

		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScroll);
			// window.removeEventListener('popstate', handlePopState);
		};
	}, []);

	useEffect(() => {
		dispatch(
			setScrollPositionBeforeView({ [previousPathname]: windowScrollY })
		);
		setPreviousPathname(location.pathname);
	}, [location.pathname]);

	const handleQueryParam = () => {
		const queryParams = new URLSearchParams(location.search);
		const query_utm_source = queryParams.get('utm_source');
		const query_utm_medium = queryParams.get('utm_medium');
		const query_utm_campaign = queryParams.get('utm_campaign');

		if (query_utm_source) {
			localStorage.setItem('localUtm_source', JSON.stringify(query_utm_source));
			dispatch(setUtm_source(query_utm_source));
		}
		if (query_utm_medium) {
			localStorage.setItem('localUtm_medium', JSON.stringify(query_utm_medium));
			dispatch(setUtm_medium(query_utm_medium));
		}
		if (query_utm_campaign) {
			localStorage.setItem(
				'localUtm_campaign',
				JSON.stringify(query_utm_campaign)
			);
			dispatch(setUtm_campaign(query_utm_campaign));
		}
	};

	const handleScroll = (e) => {
		setWindowScrollY(window.scrollY);
		if (window.pageYOffset > 700) {
			setShowButton(true);
		} else {
			setShowButton(false);
		}
	};

	const handleGetOldProduct = () => {
		dispatch(setIsOld_idUrl(true));
		fetchGetData(
			`${
				process.env.REACT_APP_BASE_URL
			}/products/old_id?old_id=${location?.pathname.substring(1)}`
		).then((res) => {
			if (res?.success && res?.data) {
				navigate(handleCheckShortId(res?.data));
			} else {
				navigate('/');
				console.log('GET ProductView', res);
			}
			dispatch(setIsOld_idUrl(false));
		});
	};

	const handleTopPadding = () => {
		const header = document.getElementById('headerId');
		if (header) {
			return header.clientHeight;
		}
	};

	return (
		<>
			<div
				id='layoutId'
				ref={scrollBtn}
				className={`layout`}
				style={{ paddingTop: `${isSearchProduct ? handleTopPadding() : 0}px` }}
			>
				{isViewPreloader && <PreloaderProductCustom2 />}
				<Header />
				<Outlet />
				<Footer />
				<Stack spacing={2} sx={{ width: '100%' }}>
					<Snackbar
						open={isOrderPlaced}
						autoHideDuration={6000}
						onClose={() => dispatch(setIsOrderPlaced(false))}
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					>
						<Alert
							className='show-message__success'
							onClose={() => dispatch(setIsOrderPlaced(false))}
							severity='success'
							sx={{ width: '100%' }}
						>
							Замовлення відправлено на опрацювання
						</Alert>
					</Snackbar>
				</Stack>
				{showButton &&
					location?.pathname.startsWith('/catalog') &&
					!location?.pathname.startsWith('/catalog/collections') && (
						<ButtonScroll scrollBtn={scrollBtn} />
					)}
			</div>
		</>
	);
}

export default Layout;
