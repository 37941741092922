import './SideMenu.css';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/logo.svg';
import arrow2 from '../../assets/arrow2.svg';
import closeImg from '../../assets/closeImgBlack.svg';
import { NavLink } from 'react-router-dom';
import { setIsOpenMenu, setScrollPositionBeforeView, setSelectCategory } from '../../store/homeSlice';
import { NOT_DELETED_CATEGORY_ID, NOT_DELETED_TREND_CATEGORY_ID, NOT_DELETED_TREND_ALIAS } from '../../helpers/Config';
import { handleOpenNewCategories } from '../../helpers/Utils';

function SideMenu() {
    const categoriesShop = useSelector(state => state.homeSlice.categoriesShop);
    const [openCategory, setOpenCategory] = useState({});
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isXiaomiRedmi = /xiaomi/i.test(navigator.userAgent);
    const dispatch = useDispatch();
    const scrollRef = useRef(null)

    const handleOpenCategory = (categories) => {
        if (openCategory._id === categories._id) {
            setOpenCategory({})
        } else {
            setOpenCategory(categories)
            if (scrollRef.current) {
                scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    const handleOpenCatalog = (categories) => {
        dispatch(setSelectCategory(categories))
        dispatch(setIsOpenMenu(false))

        let layout = document.getElementById('layoutId');
        layout.style.cssText = ``
    }
    
    const handleCloseSideMenu = (isTopScroll=false) => {
        dispatch(setIsOpenMenu(false))
        
        let layout = document.getElementById('layoutId');
        layout.style.cssText = ``

        if (isTopScroll) {
            dispatch(setScrollPositionBeforeView({}))
        }
    }
    
    const handleGoCatalog = () => {
        handleCloseSideMenu()
        dispatch(setSelectCategory({}))
    }

    return (
        <div className="side-menu" ref={scrollRef}>
            <div className="side-menu__logo-wrap">
                <NavLink className="side-menu__logo-link" to='/'><img className="side-menu__logo-img" src={logo} alt='img'/></NavLink>
                <button className="side-menu__close-btn" onClick={handleCloseSideMenu}><img className="side-menu__close-btn-img" src={closeImg} alt='img'/></button> 
            </div>

            <div className="side-menu__link--wrap">
                <div className={`side-menu__link-wrap ${(!!openCategory?._id?.length && (window.innerWidth < 960)) ? 'side-menu__link-wrap--mob' : ''}`}>
                    <div className="side-menu__link-category-wrap">
                        <div className="side-menu__link-category-title">Категорії</div>
                        {
                            !!categoriesShop?.length && categoriesShop.map((el, i) =>(
                                <div key={el._id}>
                                    {
                                        el._id === NOT_DELETED_TREND_CATEGORY_ID ? 
                                            <div className='side-menu__link-category-parent-wrap'>
                                                <NavLink className='side-menu__link-category' onClick={() => handleOpenCatalog({})} to={`/catalog/trend/${NOT_DELETED_TREND_ALIAS}`}>{el.name}</NavLink>
                                            </div>
                                            :
                                            <div className='side-menu__link-category-parent-wrap'>
                                                {
                                                    !!el.sub_categories?.length ? 
                                                        <button className={`side-menu__link-category-parent ${el._id === openCategory?._id ? 'side-menu__link-category-parent--active' : ''}`} onClick={() => handleOpenCategory(el)}>
                                                            <span>{el.name}</span>
                                                            <svg className="side-menu__link-category-parent-arrow-img" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g id="chevron_right_FILL0_wght400_GRAD0_opsz48 1">
                                                                    <path className={`side-menu__link-category-parent-arrow-img-path ${el._id === openCategory?._id ? 'side-menu__link-category-parent--active' : ''}`} id="Vector" d="M11.0928 9.97915L6.96777 5.85415L7.86361 4.95831L12.8844 9.97915L7.86361 15L6.96777 14.1041L11.0928 9.97915Z"/>
                                                                </g>
                                                            </svg>
                                                        </button>
                                                        :
                                                        <NavLink className='side-menu__link-category' onClick={() => handleOpenCatalog(el)} to={handleOpenNewCategories(el, categoriesShop)}>{el.name}</NavLink>
                                                    }
                                            </div>
                                    }
                                </div>
                            ))
                        }
                    </div>

                    <div className={`side-menu__link-page-wrap ${isSafari || isXiaomiRedmi ? 'side-menu__link-page-is-safari-wrap' : ''}`}>
                        <NavLink className="side-menu__link-page" onClick={() => handleCloseSideMenu(true)} to='about'>Про маркетплейс</NavLink>
                        <a className="side-menu__link-page" href='/offer' target='_blank'>Договір публічної оферти</a>
                        <NavLink className="side-menu__link-page" onClick={() => handleCloseSideMenu(true)} to='contacts'>Контакти</NavLink>
                        <NavLink className="side-menu__link-page" onClick={() => handleCloseSideMenu(true)} to='how-to-buy'>Як купувати на маркетплейсі</NavLink>
                        <NavLink className="side-menu__link-page-all-category" onClick={handleGoCatalog} to='catalog'>Усі категорії товарів</NavLink>
                    </div>
                </div>
                
                {
                    !!openCategory?._id?.length &&
                        <div className='side-menu__link-sub-category-wrap'>
                            <div className='side-menu__link-category-btn-back-wrap'>
                                <div className='side-menu__link-category-btn-back-text' onClick={() => setOpenCategory({})}>{openCategory.name}</div>
                                <NavLink className={`side-menu__link-category-btn-all ${openCategory._id === NOT_DELETED_CATEGORY_ID ? 'displayNone' : ''}`} onClick={() => handleOpenCatalog(openCategory)} to={handleOpenNewCategories(openCategory, categoriesShop)}>Переглянути всі</NavLink>
                            </div>
                            {
                                !!openCategory?.sub_categories?.length && openCategory?.sub_categories.map(el => (
                                    <NavLink className='side-menu__link-category-right' style={{order: `${el?.priority ? el.priority : 100}`}} onClick={() => handleOpenCatalog(el)} to={handleOpenNewCategories(el, categoriesShop)} key={el._id}>{el.name}</NavLink>
                                ))
                            }
                        </div>
                }
                {
                    !!openCategory?._id?.length &&
                        <div className='side-menu__link-sub-category-mobile-wrap'>
                            <div className='side-menu__link-sub-category-mobile-back' onClick={() => setOpenCategory({})}>
                                <img className="side-menu__link-sub-category-mobile-arrow-img" src={arrow2} alt='img'/> 
                                <div className='side-menu__link-sub-category-mobile-back-text'>Назад до <b>Меню</b></div>
                            </div>
                            <div className='side-menu__link-category-mobile-btn-back-wrap'>
                                <div className='side-menu__link-category-mobile-btn-back-text'>{openCategory.name}</div>
                                <NavLink className={`side-menu__link-category-btn-all ${openCategory._id === NOT_DELETED_CATEGORY_ID ? 'displayNone' : ''}`} onClick={() => handleOpenCatalog(openCategory)} to={handleOpenNewCategories(openCategory, categoriesShop)}>Всі</NavLink>
                            </div>
                            {
                                !!openCategory?.sub_categories?.length && openCategory?.sub_categories.map(el => (
                                    <NavLink className='side-menu__link-category-mobile' style={{order: `${el?.priority ? el.priority : 100}`}} onClick={() => handleOpenCatalog(el)} to={handleOpenNewCategories(el, categoriesShop)} key={el._id}>{el.name}</NavLink>
                                ))
                            }
                        </div>
                }
            </div>
        </div>
    );
}

export default SideMenu;