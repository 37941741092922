import './ContextMenu.css';
import { Button, Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import threedots from '../../assets/threedots.svg';
import { NOT_DELETED_CATEGORY_ID, NOT_DELETED_SUB_CATEGORY_ID, NOT_DELETED_TREND_CATEGORY_ID } from '../../helpers/Config';

function ContextMenu({category, handleClickMenu}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    const handleScroll = () => {
        setAnchorEl(null)
    };

    const handleClickSettings = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClick = (str) => {
        handleClickMenu(category, str)
        setAnchorEl(null);
    };
    return (
        <div className="context-menu">
            <Button
                id={`basic-button-${category._id}`}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickSettings}
                className='context-menu__btn'
                disableRipple={true}
                >
                <img className='context-menu__btn-img' src={threedots} alt='img'/>
            </Button>
            <Menu
                id={`basic-menu-${category._id}`}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                className='context-menu__btn-select'
                MenuListProps={{
                'aria-labelledby': `basic-button-${category._id}`,
                }}
                disableRipple={true}
            >
                <MenuItem className='context-menu__btn-select-item' onClick={() => handleClick('edite')} disableRipple={true}>Редагувати</MenuItem>
                <MenuItem className='context-menu__btn-select-item' onClick={() => handleClick('hide')} disableRipple={true}>{ category.is_hide ? 'Відновити' : 'Приховати'}</MenuItem>
                {
                    (NOT_DELETED_CATEGORY_ID !== category._id && NOT_DELETED_SUB_CATEGORY_ID !== category._id && NOT_DELETED_TREND_CATEGORY_ID !== category._id) &&
                        <MenuItem className='context-menu__btn-select-item' onClick={() => handleClick('delete')} disableRipple={true}>Видалити</MenuItem>
                }
            </Menu>
            
        </div>
    );
}

export default ContextMenu;