import './AddCharacteristics.css';
import { useState} from 'react';
import plusImg from '../../assets/plusImg.svg';

function AddCharacteristics({characteristicsName, setCharacteristicsOptions, characteristicsOptions, setAllCharacteristicsOptions=null, allCharacteristicsOptions=null, isSubCategory=false, setFocusAddCharacteristics=null }) {
    const [options, setOptions] = useState('');
    const [isOptionsErro, setIsOptionsErro] = useState(false);
    
    const handleCheckIsFewValues = () => {
        let res =false
        if (characteristicsOptions?.length) {
            let char = characteristicsOptions.find(el => el.name === characteristicsName)
            res = char?.is_few_values_allowed ? true :false
        }
        return res
    }

    const handleCheckCharacteristics = () => {
        let res = characteristicsOptions.find(el => el.name === characteristicsName).value
        if (res && res?.length) {
            let isTest = res.includes(options.trim())
            setIsOptionsErro(isTest)
            return !isTest
        }
        return true
    }

    const handleKeyDown = (e) => {
        if(e.key == 'Enter') {
            handleAddCharacteristics()
        }
    }

    const handleAddCharacteristics = () => {
        if (options?.length && options !== 'Колір' && options !== 'Матеріал' && handleCheckCharacteristics()) {
            let res = characteristicsOptions.map(el => {
                if (el.name === characteristicsName) {
                    el = {name: characteristicsName, value: [...el.value, options.trim()]}
                }
                return el
            })
            setCharacteristicsOptions(res)
            setOptions('')

            if (setAllCharacteristicsOptions) {
                let res2 = allCharacteristicsOptions.map(el => {
                    if (el.name === characteristicsName) {
                        el = {name: characteristicsName, value: [...el.value, options.trim()]}
                    }
                    return el
                })
                setAllCharacteristicsOptions(res2)
            }
        }
    }

    return (
        <div className={`add-characteristics ${isSubCategory ? 'add-characteristics--is-sub-category' : ''}`}>
            <div className='creating-category__add-btn--wrap'>
                <input className='creating-category__input' value={options} onChange={(e) => setOptions(e.target.value)} onKeyDown={(e) => handleKeyDown(e)} onFocus={() => setFocusAddCharacteristics(characteristicsName)} onBlur={() => setFocusAddCharacteristics('')} placeholder='Додати значення' type='text' />
                <img className='creating-category__add-btn' onClick={handleAddCharacteristics} src={plusImg} alt='img'/>
            </div>
            {
                handleCheckIsFewValues()  && <div className='creating-category__input-sub-title'>При створенні різних значень розділіть їх комою і пробілом, приклад: "100, 200"</div>
            }
            {
                isOptionsErro && <div className='creating-category__input-error-text'>Таке значення вже існує</div>
            }
        </div>
    );
}

export default AddCharacteristics;