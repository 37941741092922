import './MaterialHover.css';
import { memo } from 'react';

function MaterialHover({variation, hoverWidth=400, distanceToRight=0}) {

    return (
        <div 
            className='material-hover'
            style={{width: hoverWidth, transform: `translateX(${-distanceToRight}px)`}}
            >
            <img 
                className='material-hover__img' 
                style={{width: hoverWidth === 400 ? '50%' : '100%'}}
                src={variation.img} 
                alt='img'
            />
            {
                hoverWidth === 400 &&
                    <div className='material-hover__text-wrap'>
                        <div className='material-hover__text-title'>{variation.title}</div>
                        {
                            !!variation?.type?.length &&
                                <div className='material-hover__text'><span className='material-hover__text-sub-title'>Тип матеріалу: </span>{variation.type}</div>
                        }
                        {
                            !!variation?.ingredient?.length && 
                                <div className='material-hover__text'><span className='material-hover__text-sub-title'>Склад матеріалу: </span>{variation.ingredient}</div>
                        }
                        {
                            !!variation?.keeping?.length &&
                                <div className='material-hover__text-keeping'><span className='material-hover__text-sub-title'>Догляд: </span>{variation.keeping}</div>
                        }
                    </div>
            }
        </div>
    );
}

export default memo(MaterialHover);