import './ProducersBlock.css';
import { memo, useEffect, useRef, useState } from 'react';
import man1 from '../../assets/man1.png';
import man2 from '../../assets/man2.png';
import man3 from '../../assets/man3.jpg';
import man4 from '../../assets/man4.png';
import man5 from '../../assets/man5.jpg';
import arrow4 from '../../assets/arrow4.svg';
import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { setIsScrollSlider, setOldModalScrollPosition } from '../../store/homeSlice';
import { useDispatch } from 'react-redux';

function ProducersBlock() {
    const [vendorAlias1, setVendorAlias1] = useState('cubito');
    const [vendorAlias2, setVendorAlias2] = useState('tekstura-home');
    const [vendorAlias3, setVendorAlias3] = useState('lovko');
    const [vendorAlias4, setVendorAlias4] = useState('settee');
    const [vendorAlias5, setVendorAlias5] = useState('4corners');
    const [vendorsArr, setVendorsArr] = useState([1, 2, 3, 4]);
    const swiperRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setIsScrollSlider(false))
            dispatch(setOldModalScrollPosition(0))
        }
    }, [])

    const handlePrevSleder = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
          swiperRef.current.swiper.slidePrev(); 
        }
    }
    
    const handleNextSleder = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
          swiperRef.current.swiper.slideNext(); 
        }
    }

    return (
        <div className="producers-block">
            <div className="producers-block__top">
                <div className="producers-block__top-title">Про наших виробників</div>
                <button className="producers-block__top-prev-btn" onClick={handlePrevSleder}>
                    <img className="producers-block__top-prev-btn-img" src={arrow4} alt='img'/>
                </button>
                <button className="producers-block__top-next-btn" onClick={handleNextSleder}>
                    <img className="producers-block__top-next-btn-img" src={arrow4} alt='img'/>
                </button>
                <Swiper 
                    ref={swiperRef}
                    loop={true}
                    spaceBetween={60}
                    slidesPerView={1}
                    navigation={true}
                    modules={[Navigation]}
                    
                    speed={450}
                    breakpoints={{
                        640: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                        },
                        870: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                        },
                        960: {
                        slidesPerView: 3,
                        spaceBetween: 80,
                        },
                        1200: {
                        slidesPerView: 3,
                        spaceBetween: 100,
                        },
                        1400: {
                        slidesPerView: 4,
                        spaceBetween: 54,
                        },
                        1480: {
                        slidesPerView: 4,
                        spaceBetween: 84,
                        },
                        1594: {
                        slidesPerView: 4,
                        spaceBetween: 124,
                        },
                        1680: {
                        slidesPerView: 5,
                        spaceBetween: 30,
                        },
                    }}
                    className="producers-block__top-swiper "
                >
                    {
                        !!vendorsArr?.length && vendorsArr.map((el, i) => (
                            <div key={i}>
                                <SwiperSlide key={i + '_1'} className='producers-block__top-slider-wrap'>
                                    <div className="producers-block__top-card">
                                        <div className="producers-block__top-card-img-wrap">
                                            <img className="producers-block__top-card-img" src={man1} alt='Cubito'/>
                                            <div className="producers-block__top-card-img-text-wrap">
                                                <div className="producers-block__top-card-img-text-title">Тарас Матвійчук - Засновник майстерні <div className="producers-block__top-card-img-text-sub-title">Cubito</div></div>
                                            </div>
                                        </div>
                                        <div className="producers-block__top-card-text">CUBITO підтримує засади екологічно чистого, сталого життя шляхом створення сучасних мінімалістичних меблів, архітектурних та інтер'єрних елементів, вироблених із повалених дерев</div>
                                        <div className="producers-block__btn-wrap">
                                        <NavLink to={`vendor/${vendorAlias1}`} className="main-btn-2" target="_blank">Детальніше</NavLink>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide key={i + '_2'} className='producers-block__top-slider-wrap'>
                                    <div className="producers-block__top-card">
                                        <div className="producers-block__top-card-img-wrap">
                                            <img className="producers-block__top-card-img" src={man2} alt='Tekstura Home'/>
                                            <div className="producers-block__top-card-img-text-wrap">
                                                <div className="producers-block__top-card-img-text-title">Антон Молчанов - Засновник виробництва <div className="producers-block__top-card-img-text-sub-title">Tekstura Home</div></div>
                                            </div>
                                        </div>
                                        <div className="producers-block__top-card-text">Нам подобається ідея зберегти навички деревообробки та зайняти місцевих майстрів, а також можливість адаптувати наш дизайн до ваших конкретних потреб</div>
                                        <div className="producers-block__btn-wrap">
                                            <NavLink to={`vendor/${vendorAlias2}`} className="main-btn-2" target="_blank">Детальніше</NavLink>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide key={i + '_3'} className='producers-block__top-slider-wrap'>
                                    <div className="producers-block__top-card">
                                        <div className="producers-block__top-card-img-wrap">
                                            <img className="producers-block__top-card-img" src={man3} alt='LOVKO'/>
                                            <div className="producers-block__top-card-img-text-wrap">
                                                <div className="producers-block__top-card-img-text-title">Брати Євген і Андрій Стратулати - Співзасновники бренду <div className="producers-block__top-card-img-text-sub-title">LOVKO</div></div>
                                            </div>
                                        </div>
                                        <div className="producers-block__top-card-text">Ми любимо свою справу і вже не уявляємо свого життя без столів та стільців. А створення компанії з виробництва меблів стало черговим кроком нашого професійного життя</div>
                                        <div className="producers-block__btn-wrap">
                                            <NavLink to={`vendor/${vendorAlias3}`} className="main-btn-2" target="_blank">Детальніше</NavLink>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide key={i + '_4'} className='producers-block__top-slider-wrap'>
                                    <div className="producers-block__top-card">
                                        <div className="producers-block__top-card-img-wrap">
                                            <img className="producers-block__top-card-img" src={man4} alt='Settee'/>
                                            <div className="producers-block__top-card-img-text-wrap">
                                                <div className="producers-block__top-card-img-text-title">Тетяна та Іван Височанські - Засновники бренду <div className="producers-block__top-card-img-text-sub-title">Settee</div></div>
                                            </div>
                                        </div>
                                        <div className="producers-block__top-card-text">Settee – це більше, ніж просто предмет інтер'єру, більше ніж м'який конструктор. Це унікальний інструмент для гри, розвитку та відпочинку. Для дітей і дорослих. Для щасливих спогадів про дитинство</div>
                                        <div className="producers-block__btn-wrap">
                                            <NavLink to={`vendor/${vendorAlias4}`} className="main-btn-2" target="_blank">Детальніше</NavLink>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide key={i + '_5'} className='producers-block__top-slider-wrap'>
                                    <div className="producers-block__top-card">
                                        <div className="producers-block__top-card-img-wrap">
                                            <img className="producers-block__top-card-img" src={man5} alt='4Corners'/>
                                            <div className="producers-block__top-card-img-text-wrap">
                                                <div className="producers-block__top-card-img-text-title">Ми український бренд-виробник меблів <div className="producers-block__top-card-img-text-sub-title">4Corners</div></div>
                                            </div>
                                        </div>
                                        <div className="producers-block__top-card-text">Історія розпочалася у 2015 році, коли чотирьох друзів з різних сфер діяльності об’єднало одне бажання - створювати сучасні українські меблі, поєднати дизайн та комфорт у кожній моделі!</div>
                                        <div className="producers-block__btn-wrap">
                                            <NavLink to={`vendor/${vendorAlias5}`} className="main-btn-2" target="_blank">Детальніше</NavLink>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </div>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    );
}

export default memo(ProducersBlock);