import './FavoriteView.css';
import ProductCard from '../../components/ProductCard/ProductCard';
import { useDispatch, useSelector } from 'react-redux';
import { setLikeProducts } from '../../store/homeSlice';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function FavoriteView() {
    const likeProducts = useSelector(state => state.homeSlice.likeProducts);
    const seo = useSelector(state => state.homeSlice.seo);
    const dispatch = useDispatch();

    const handleNextVariation = (obj, oldProductId) => {
        if (likeProducts?.length) {
            let res = likeProducts.map(ell => {
                if (ell._id === oldProductId) {
                    ell = obj
                    return ell
                 }
                 return ell
             })

             dispatch(setLikeProducts(res))
        }
    }

    return (
        <div className="favorite_view">
            <SeoBlock title={seo.title_favorite} description={seo.description_favorite} keywords={seo.keywords_favorite}/>
            <div className="favorite_view-wrap container">
                <h1 className="favorite_view__title">Обрані товари</h1>
                <div className="favorite_view__products">
                    {
                        !!likeProducts?.length ? likeProducts.map(el => (
                            <div className='favorite_view__product' key={el._id}>
                                <ProductCard product={el} handleNextVariation={handleNextVariation}/>
                            </div>
                        ))
                        :
                        <div className="favorite_view__products-error">Список обраних товарів пустий</div>
                    }
                </div>
            </div>
        </div>
    );
}

export default FavoriteView;