import './BeSeller.css';
import { NavLink } from 'react-router-dom';
import man2 from '../../assets/man2.png';
import { setIsViewPreloader, setScrollPositionBeforeView } from '../../store/homeSlice';
import { useDispatch } from 'react-redux';

function BeSeller() {
    const dispatch = useDispatch();

    const handleClickMore = () => {
        dispatch(setIsViewPreloader(true))
        dispatch(setScrollPositionBeforeView({}))
    }

    return (
        <div className="be-seller">
            <div className="be-seller-text-wrap">
                <div className="be-seller-text-title">Продавати на платформі VYROBY</div>
                <img className="be-seller-text-img" src={man2} alt='img'/>
                <div className="be-seller-text-text">Ви виготовляєте стильні та якісні меблі й шукаєте сервіс, де можна продавати меблі? Приєднуйтеся до платформи VYROBY і станьте нашим партнером.</div>
                <div className="be-seller-text-btn--wrap">
                    <NavLink className="be-seller-text-btn-wrap" onClick={() => handleClickMore()} to='/partners'>
                        <button className='main-btn-1'>Дізнатись більше</button>
                    </NavLink>
                </div>
            </div>
        </div>
    );
}

export default BeSeller;