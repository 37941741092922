import './OrderReadView.css';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchGetData, fetchRequest, formatPhoneNumber, handleFormattingDate, handlePriceReplace } from '../../helpers/Utils';
import OrderStatus from '../../components/OrderStatus/OrderStatus';
import UserProductCard from '../../components/UserProductCard/UserProductCard';
import cartUser2 from '../../assets/cartUser2.svg';
import telImg from '../../assets/telImg.svg';
import letter from '../../assets/letter.svg';
import closeImgBlack from '../../assets/closeImgBlack.svg';
import requestsImg from './../../assets/requestsImg.svg';
import { useSelector } from 'react-redux';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { setCount_unread_orders, setCount_unread_preorders, setShowMessageObj } from '../../store/userSlice';
import { useDispatch } from 'react-redux';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function OrderReadView({isRequestsView=false}) {
    const isOpenMenu = useSelector(state => state.userSlice.isOpenMenu);
    const count_unread_orders = useSelector(state => state.userSlice.count_unread_orders);
    const count_unread_preorders = useSelector(state => state.userSlice.count_unread_preorders);
    const [isPreloader, setIsPreloader] = useState(true);
    const [order, setOrder] = useState({});
    const { orderId } = useParams();
    const navigate = useNavigate();
    const token = localStorage.getItem('token')
    const productWrapRef = useRef(null);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/orders/${orderId}`)
            .then(res => {
                if (res.success && res.data) {
                    setOrder(res.data)
                } else {
                    console.log('GET OrderReadView', res)
                }
                setIsPreloader(false)
            })
    }, [])
    
    useEffect(() => {
        if (order?._id?.length && !order?.is_read) {
            let data ={
                ...order,
                is_read: true
            }
    
            fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/orders/${orderId}?token=${token}`, data, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
                .then(res => {
                    if (res.success && res.data) {
                        setOrder(data)
                        if (order.is_preorder) {
                            dispatch(setCount_unread_preorders(count_unread_preorders - 1))
                        } else {
                            dispatch(setCount_unread_orders(count_unread_orders - 1))
                        }
                    } else {
                        console.log('PUT OrderReadView', res)
                    }
                })
        }
    }, [order])

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className={`order-read ${!isOpenMenu ? 'user-order__orders--is-open-menu' : 'user-order__orders--is-close-menu'}`}>
            <SeoBlock title={"Замовлення"} description={""} keywords={""} isCanonical={location.pathname === `order/${orderId}` ? true : false}/>
            <div className="order-read__header">
                <div className="order-read__header-title-wrap">
                    <img className={`user-order__header-title-img ${isRequestsView ? 'user-order__header-title-img--is-requests' : ''}`} src={isRequestsView ? requestsImg : cartUser2} alt='img'/>
                    <h1 className="user-order__header-title">{isRequestsView ? `Запит ${order.request_type === 'adapt' ? '(адаптувати виріб)' : order.request_type === 'question' ? '(задати питання)' : `${order?.vendor_name?.length ? order?.vendor_name + ' ' : ''}(сторінка виробника)` }` : 'Замовлення'}  - № {!!order.view_id?.length ? order.view_id : ''}</h1>
                </div>
                <div className="order-read__header-btn-wrap">
                    <button onClick={handleGoBack} className="order-read__header-btn">
                        <img className="order-read__header-btn-img" src={closeImgBlack} alt='img'/>
                        <span className="order-read__header-btn-text">Закрити замовлення</span>
                    </button>
                </div>
            </div>

            {
                isPreloader ? <PreloaderCustom /> 
                    :
                    <>
                        <div className="order-read-wrap">
                            <div className="order-read__main">
                                <div className="order-read__main-top">
                                    <div className="order-read__main-top-text--wrap">
                                        <div className="order-read__main-top-text-wrap">
                                            <p className="order-read__main-top-text">Дата створення:</p>
                                            <p>{handleFormattingDate(order.created_at)}</p>
                                        </div>
                                        {
                                            !!order.full_name?.length &&
                                                <div className="order-read__main-top-text-wrap">
                                                    <p className="order-read__main-top-text">Замовник:</p>
                                                    <p>{order.full_name}</p>
                                                </div>
                                        }
                                        {
                                            !!order.phone?.length &&
                                                <div className="order-read__main-top-text-wrap">
                                                    <p className="order-read__main-top-text">Телефон:</p>
                                                    <div className='order-read__main-top-tel-img-wrap'>
                                                        <img className="order-read__main-top-tel-img" src={telImg} alt='img'/>
                                                        <p>{formatPhoneNumber(order.phone)}</p>
                                                    </div>
                                                </div>
                                        }
                                        {
                                            !!order.email?.length &&
                                                <div className="order-read__main-top-text-wrap">
                                                    <p className="order-read__main-top-text">Email:</p>
                                                    <div className='order-read__main-top-tel-img-wrap'>
                                                        <img className="order-read__main-top-email-img" src={letter} alt='img'/>
                                                        <p>{order.email}</p>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    <div className="order-read__top-select-wrap">
                                        <OrderStatus order={order} str={order.status}/>
                                    </div>
                                </div>
                                {
                                    (!!order.payment_method?.length || !!order.delivery_method?.length || !!order.delivery_address?.length) &&
                                        <div className="order-read__main-bottom">
                                            {
                                                !!order.payment_method?.length &&
                                                <div className="order-read__main-bottom-text order-read__main-bottom-text--payment">
                                                    <div className="order-read__main-bottom-text-title">Оплата:</div>
                                                    <div><span className="order-read__main-bottom-text-title-mob">Оплата:</span> {order.payment_method}</div>
                                                </div>
                                            }
                                            {
                                                !!order.delivery_method?.length &&
                                                    <div className="order-read__main-bottom-text order-read__main-bottom-text--delivery">
                                                        <div className="order-read__main-bottom-text-title">Доставка:</div>
                                                        <div><span className="order-read__main-bottom-text-title-mob">Доставка:</span> {order.delivery_method}</div>
                                                    </div>
                                            }
                                            {
                                                !!order.delivery_address?.length &&
                                                    <div className="order-read__main-bottom-text">
                                                        <div className="order-read__main-bottom-text-title">Адреса:</div>
                                                        <div><span className="order-read__main-bottom-text-title-mob">Адреса:</span> {order.delivery_address}</div>
                                                    </div>
                                            }
                                        </div>
                                }
                                <div className="order-read__main-comment">
                                    <div className="order-read__main-bottom-text-comment">
                                        <div className="order-read__main-bottom-text-title">Коментар:</div>
                                        <div><span className="order-read__main-bottom-text-title-mob">Коментар:</span> {!!order.comment?.length ? order.comment : ''}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {
                            !!order.products?.length &&
                                <>
                                    <div className="order-read__products-wrap">
                                        <div className="order-read__products--wrap">
                                            <div className="order-read__products-header--wrap">
                                                <div className="order-read__products-header-wrap">
                                                    <div className="order-read__products-header-title">Товари</div>
                                                    <div className="order-read__products-header-category">Категорія</div>
                                                    <div className="order-read__products-header-vendor">Виробник</div>
                                                    <div className="order-read__products-header-price">Ціна</div>
                                                    <div className="order-read__products-header-count">Кількість</div>
                                                    <div className="order-read__products-header-code">Код товару</div>
                                                    <div className="order-read__products-header-total">Сума</div>
                                                </div>
                                            </div>
                                            {
                                                !!order.products?.length && order.products.map((el, i) => (
                                                    <div className="order-read__product-wrap" ref={productWrapRef} key={el._id + i}>
                                                        {
                                                            !!el.title?.length ? 
                                                                <UserProductCard product={el} isHideVariations={true} isOrderReadView={true}/>
                                                                : 
                                                                <div className="order-read__product-error">{i + 1}. Даний товар видалений. Було замовлено {el.count}</div>
                                                        }

                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="order-read__total-price-wrap">
                                        <p className="order-read__total-price">Сума замовлення: &nbsp;</p>
                                        <p className="order-read__total-price-count">{handlePriceReplace(order.total_price)} грн</p>
                                    </div>
                                </>
                        }
                    </>
            }
        </div>
    );
}

export default OrderReadView;