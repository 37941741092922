import './LayoutUser.css';
import { useEffect, useRef, useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import avatar from '../assets/avatar.svg';
import logo from '../assets/logo.svg';
import houseImg from '../assets/houseImg.svg';
import arrow2Green from '../assets/arrow2Green.svg';
import category from '../assets/category.svg';
import category1 from '../assets/category1.svg';
import products from '../assets/products.svg';
import products1 from '../assets/products1.svg';
import products3 from '../assets/products3.svg';
import products4 from '../assets/products4.svg';
import cartUser from '../assets/cartUser.svg';
import exitImg from '../assets/exitImg.svg';
import exitImgHover from '../assets/exitImgHover.svg';
import collection1 from '../assets/collection1.svg';
import collection2 from '../assets/collection2.svg';
import cartUser1 from '../assets/cartUser1.svg';
import closeImgBlack from '../assets/closeImgBlack.svg';
import notifications1 from '../assets/notifications1.svg';
import notifications2 from '../assets/notifications2.svg';
import settings3 from '../assets/settings3.svg';
import settings4 from '../assets/settings4.svg';
import manIcon from '../assets/manIcon.svg';
import manIcon1 from '../assets/manIcon1.svg';
import arrow3 from '../assets/exit-outline.svg';
import requestsImgHover from '../assets/requestsImgHover.svg';
import requestsImg from '../assets/requestsImg.svg';
import notifications from '../assets/notifications.svg';
import { fetchGetData } from '../helpers/Utils';
import { getCategories, getProducts, getVendors, setSearchOrders, setIsOpenMenu, setWindowWidth, setCountUnreadNotifications, setCount_unread_orders, setCount_unread_preorders } from '../store/userSlice';
import { Button } from '@mui/material';

function LayoutUser() {
    const isOpenMenu = useSelector(state => state.userSlice.isOpenMenu);
    const isHiddenScroll = useSelector(state => state.userSlice.isHiddenScroll);
    const countUnreadNotifications = useSelector(state => state.userSlice.countUnreadNotifications);
    const count_unread_orders = useSelector(state => state.userSlice.count_unread_orders);
    const count_unread_preorders = useSelector(state => state.userSlice.count_unread_preorders);
    const [searchTel, setSearchTel] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [isPreloader, setIsPreloader] = useState(true);
    const outletWrapRef = useRef(null);
    const sidenavWrapRef = useRef(null);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isXiaomiRedmi = /xiaomi/i.test(navigator.userAgent);
    let token = localStorage.getItem('token')
    
    useEffect(() => {
        if (token?.length) {
            verifyToken()

            window.addEventListener("resize", handleResize);
        } else {
            navigate('/login')
        }

        dispatch(setWindowWidth(window.innerWidth));

        handleViewport()

        handleGetNotificationCount()
        const intervalId = setInterval(() => {
            handleGetNotificationCount();
        }, 30000);

        return () => {
            window.removeEventListener("resize", handleResize)
            clearInterval(intervalId)
        };
    }, [])

    useEffect(() => {
        dispatch(getProducts([]))
        if (window.innerWidth < 768) {
            dispatch(setIsOpenMenu(true))
        }

        window.scrollTo(0, 0);

    }, [location])
    
    const handleGetNotificationCount = () => {
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/notifications/unread?token=${token}`)
        .then(res => {
                if (res.success && res.count_unread_notifications !== null && res.count_unread_notifications !== undefined) {
                    dispatch(setCountUnreadNotifications(res.count_unread_notifications))
                } 
                if (res.success && res.count_unread_orders !== null && res.count_unread_orders !== undefined) {
                    dispatch(setCount_unread_orders(res.count_unread_orders))
                } 
                if (res.success && res.count_unread_preorders !== null && res.count_unread_preorders !== undefined) {
                    dispatch(setCount_unread_preorders(res.count_unread_preorders))
                } 
            })
    }
        
    const handleResize = () => {
        dispatch(setWindowWidth(window.innerWidth));

        handleViewport()
    }

    const handleViewport = () => {
        const viewportTag = document.querySelector('meta[name="viewport"]');
        
        if (viewportTag) {
          const isMobile = window.innerWidth <= 768;
          viewportTag.content = isMobile
            ? 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
            : 'width=device-width, initial-scale=1';
        }
    };

    const openNav = () => {
        dispatch(setIsOpenMenu(!isOpenMenu))
    };

    const handleSearchTel = (e) => {
        if(e.key == 'Enter' && searchTel?.length) {
            navigate('/auth/order')
            dispatch(setSearchOrders(searchTel))
            setSearchTel('')
        }
    }
    
    const handleSeachPurchases = () => {
        if (searchTel?.length) {
            dispatch(setSearchOrders(searchTel))
            navigate('/auth/order')
            setSearchTel('')
        }
    }

    const handleExit = () => {
        localStorage.removeItem('token')
        window.location.assign('/login')
    }

    const handleCloseTelSearch = () => {
        setSearchTel('')
    }

    const handleGetData = () => {
        setIsPreloader(true)
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/categories/all`)
            .then(res => {
                if (res.success && res.data) {
                    dispatch(getCategories(res.data))
                    setIsPreloader(false)
                } else {
                    console.log('GET LayoutUser', res)
                }
            })
            
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/vendors/all`)
            .then(res => {
                if (res.success && res.data) {
                    dispatch(getVendors(res.data))
                    setIsPreloader(false)
                } else {
                    console.log('GET LayoutUser', res)
                }
            })
    }
    
    const verifyToken = async () => {
        const oldToken = localStorage.getItem('token');
        if (oldToken) {
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/auth/verify?token=${oldToken}`)
                .then(res => {
                    if (res.success && res.data === "Valid") {
                        handleGetData()
                    } else {
                        localStorage.removeItem('token')
                        window.location.assign('/login')
                    }
                })
        } else {
            localStorage.removeItem('token')
            window.location.assign('/login')
        }
    }

    return (
        <div className={`layout-user ${isHiddenScroll ? 'hiddenScroll' : ''}`}>
            <div className='layout-user--wrpa'>
                <div className='layout-user__header-wrap'>
                    <div className={`layout-user__header container ${!isOpenMenu ? 'layout-user__header--is-open-menu' : ''}`}>
                        <div className={`layout-user__header-menu ${isOpenMenu ? 'layout-user__header-menu--close' : ''}`} onClick={() => openNav()}>
                            <div className='layout-user__header-side-menu'>
                                <span className={`layout-user__header-menu-span ${!isOpenMenu ? "layout-user__header-menu-span--open1" : ""}`}></span>
                                <span className='layout-user__header-menu-span layout-user__header-menu-span2'></span>
                                <span className={`layout-user__header-menu-span ${!isOpenMenu ? "layout-user__header-menu-span--open3" : ""}`}></span>
                            </div>
                        </div>

                        <NavLink className='layout-user__header-logo-wrap' to={`/auth`}><img className='layout-user__header-logo' src={logo} alt='img'/></NavLink>

                        <div className={`layout-user__header-search-wrap layout-user__header-search--desc-wrap`}>
                            <div className='layout-user__header-search--wrap'>
                                <input className='layout-user__header-search' value={searchTel} onChange={(e) => setSearchTel(e.target.value)} onKeyDown={(e) => handleSearchTel(e)} label='Пошук по Номеру замовлення чи Імені'/>
                                <svg onClick={handleSeachPurchases} className='layout-user__header-search-img' fill='#545D69' version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 487.95 487.95" xmlSpace="preserve">
                                    <g>
                                        <g>
                                            <path d="M481.8,453l-140-140.1c27.6-33.1,44.2-75.4,44.2-121.6C386,85.9,299.5,0.2,193.1,0.2S0,86,0,191.4s86.5,191.1,192.9,191.1
                                                c45.2,0,86.8-15.5,119.8-41.4l140.5,140.5c8.2,8.2,20.4,8.2,28.6,0C490,473.4,490,461.2,481.8,453z M41,191.4
                                                c0-82.8,68.2-150.1,151.9-150.1s151.9,67.3,151.9,150.1s-68.2,150.1-151.9,150.1S41,274.1,41,191.4z"/>
                                        </g>
                                    </g>
                                </svg>
                                {
                                    !!searchTel?.length &&
                                        <img className='layout-user__header-search-btn-close' onClick={handleCloseTelSearch} src={closeImgBlack} alt='img'/>
                                }
                            </div>
                        </div>

                        <div className='layout-user__header-menu-wrap'>
                            <NavLink className='layout-user__header-menu-link' to={`/auth/notifications`}>
                                <div className='layout-user__header-menu-link-icon-wrap'>
                                    <img className='layout-user__header-menu-link-icon' src={notifications} alt='img'/>
                                    {
                                        countUnreadNotifications !== 0 && 
                                            <div className='layout-user__header-count-unread-notifications'>{countUnreadNotifications}</div>
                                    }
                                </div>
                                <div className='layout-user__header-menu-link-text'>Повідомлення</div>
                            </NavLink>
                            <Button
                                aria-haspopup="true"
                                onClick={handleExit}
                                className='layout-user__header-menu-select-wrap'
                                title='Вихід'
                                disableRipple={true}
                            >
                                <img className='layout-user__header-menu-select' src={exitImg} alt='img' />
                            </Button>
                        </div>
                    </div>
                </div>

                <div className='layout-user__main' ref={outletWrapRef}>
                    <div className={`layout-user__sidenav ${!isOpenMenu ? "layout-user__sidenav--open" : "layout-user__sidenav--close"} ${isSafari || isXiaomiRedmi ? 'layout-user__sidenav--is-safari' : ''}`} ref={sidenavWrapRef}>
                        <div className={`layout-user__header-search-wrap layout-user__header-search--mob-wrap`}>
                            <div className='layout-user__header-search--wrap'>
                                <input className='layout-user__header-search' value={searchTel} onChange={(e) => setSearchTel(e.target.value)} onKeyDown={(e) => handleSearchTel(e)} label='Пошук по Номеру замовлення чи Імені'/>
                                <svg onClick={handleSeachPurchases} className='layout-user__header-search-img' fill='#545D69' version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 487.95 487.95" xmlSpace="preserve">
                                    <g>
                                        <g>
                                            <path d="M481.8,453l-140-140.1c27.6-33.1,44.2-75.4,44.2-121.6C386,85.9,299.5,0.2,193.1,0.2S0,86,0,191.4s86.5,191.1,192.9,191.1
                                                c45.2,0,86.8-15.5,119.8-41.4l140.5,140.5c8.2,8.2,20.4,8.2,28.6,0C490,473.4,490,461.2,481.8,453z M41,191.4
                                                c0-82.8,68.2-150.1,151.9-150.1s151.9,67.3,151.9,150.1s-68.2,150.1-151.9,150.1S41,274.1,41,191.4z"/>
                                        </g>
                                    </g>
                                </svg>
                                {
                                    !!searchTel?.length &&
                                        <img className='layout-user__header-search-btn-close' onClick={handleCloseTelSearch} src={closeImgBlack} alt='img'/>
                                }
                            </div>
                        </div>
                            
                        <NavLink className='layout-user__sidenav-link-shop' to='/' target='_blank'>
                            <img className='layout-user__sidenav-link-shop-img' src={houseImg} alt='img'/>
                            <div className={`layout-user__sidenav-link-shop-text-wrap ${isOpenMenu ? 'layout-user__sidenav-link-shop-text-wrap--close' : 'layout-user__sidenav-link-shop-text-wrap--open'}`}>
                                <p className='layout-user__sidenav-link-shop-title'>Перейти на сайт</p>
                            </div>
                            <img className={`layout-user__sidenav-link-shop-arow ${isOpenMenu ? 'layout-user__sidenav-link-shop-arow--close' : 'layout-user__sidenav-link-shop-arow--open'}`} src={arrow2Green} alt='img'/>
                        </NavLink>

                        <NavLink className='layout-user__sidenav-link' to={`/auth/categories`}>
                            <img className='layout-user__sidenav-link-icon' src={category} alt='img'/>
                            <img className='layout-user__sidenav-link-icon-hover' src={category1} alt='img'/>
                            <div className={`layout-user__sidenav-link-text ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>Категорії</div>    
                        </NavLink>
                        <NavLink className='layout-user__sidenav-link' to={`/auth/products`}>
                            <img className='layout-user__sidenav-link-icon' src={products3} alt='img'/>
                            <img className='layout-user__sidenav-link-icon-hover' src={products4} alt='img'/>
                            <div className={`layout-user__sidenav-link-text ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>Товари</div>
                        </NavLink>
                        <NavLink className='layout-user__sidenav-link' to={`/auth/collection`}>
                            <img className='layout-user__sidenav-link-icon layout-user__sidenav-link-icon--collection' src={collection1} alt='img'/>
                            <img className='layout-user__sidenav-link-icon-hover layout-user__sidenav-link-icon--collection' src={collection2} alt='img'/>
                            <div className={`layout-user__sidenav-link-text ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>Колекції</div>
                        </NavLink>
                        <NavLink className='layout-user__sidenav-link' to={`/auth/blocks`}>
                            <img className='layout-user__sidenav-link-icon' src={products} alt='img'/>
                            <img className='layout-user__sidenav-link-icon-hover' src={products1} alt='img'/>
                            <div className={`layout-user__sidenav-link-text ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>Тренди</div>
                        </NavLink>
                        <NavLink className='layout-user__sidenav-link' to={`/auth/vendors`}>
                            <img className='layout-user__sidenav-link-icon' src={manIcon} alt='img'/>
                            <img className='layout-user__sidenav-link-icon-hover layout-user__sidenav-link-icon-hover--vendor' src={manIcon1} alt='img'/>
                            <div className={`layout-user__sidenav-link-text ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>Виробники</div>
                        </NavLink>
                        <NavLink className='layout-user__sidenav-link' to={`/auth/order`}>
                            <img className='layout-user__sidenav-link-icon' src={cartUser} alt='img'/>
                            <img className='layout-user__sidenav-link-icon-hover' src={cartUser1} alt='img'/>
                            <div className={`layout-user__sidenav-link-text ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>
                                <span>Замовлення</span>
                                {
                                    count_unread_orders !== 0 && 
                                        <div className='layout-user__header-sidenav-count-unread-notifications layout-user__header-count-unread-notifications--sidenav-is-open'>{count_unread_orders}</div>
                                }
                            </div>
                            {
                                count_unread_orders !== 0 && isOpenMenu && 
                                    <div className='layout-user__header-sidenav-count-unread-notifications layout-user__header-count-unread-notifications--sidenav-is-close'>{count_unread_orders}</div>
                            }
                        </NavLink>
                        <NavLink className='layout-user__sidenav-link' to={`/auth/requests`}>
                            <img className='layout-user__sidenav-link-icon layout-user__sidenav-link-icon--requests' src={requestsImg} alt='img'/>
                            <img className='layout-user__sidenav-link-icon-hover layout-user__sidenav-link-icon--requests-hover' src={requestsImgHover} alt='img'/>
                            <div className={`layout-user__sidenav-link-text ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>
                                <span>Запити</span>
                                {
                                    count_unread_preorders !== 0 && 
                                        <div className='layout-user__header-sidenav-count-unread-notifications layout-user__header-count-unread-notifications--sidenav-is-open'>{count_unread_preorders}</div>
                                }
                            </div>
                            {
                                count_unread_preorders !== 0 && isOpenMenu && 
                                    <div className='layout-user__header-sidenav-count-unread-notifications layout-user__header-count-unread-notifications--sidenav-is-close'>{count_unread_preorders}</div>
                            }
                        </NavLink>
                        <NavLink className='layout-user__sidenav-link' to={`/auth/notifications`}>
                            <img className='layout-user__sidenav-link-icon' src={notifications1} alt='img'/>
                            <img className='layout-user__sidenav-link-icon-hover layout-user__sidenav-link-icon-hover--notifications' src={notifications2} alt='img'/>
                            <div className={`layout-user__sidenav-link-text ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>
                                <span>Повідомлення</span>
                                {
                                    countUnreadNotifications !== 0 && 
                                        <div className='layout-user__header-sidenav-count-unread-notifications layout-user__header-count-unread-notifications--sidenav-is-open'>{countUnreadNotifications}</div>
                                }
                            </div>
                            {
                                countUnreadNotifications !== 0 && isOpenMenu && 
                                    <div className='layout-user__header-sidenav-count-unread-notifications layout-user__header-count-unread-notifications--sidenav-is-close'>{countUnreadNotifications}</div>
                            }
                        </NavLink>
                        <NavLink className='layout-user__sidenav-link' to={`/auth/settings`}>
                            <img className='layout-user__sidenav-link-icon' src={settings3} alt='img'/>
                            <img className='layout-user__sidenav-link-icon-hover' src={settings4} alt='img'/>
                            <div className={`layout-user__sidenav-link-text ${!isOpenMenu ? 'layout-user__sidenav-link-text--open' : 'layout-user__sidenav-link-text--close'}`}>Налаштування</div>
                        </NavLink>
                        <div className='layout-user__header-menu-avatar--mob-wrap'>
                            <img className='layout-user__header-menu-avatar layout-user__header-menu-avatar--mob' src={avatar} alt='img' />
                            <div className='layout-user__header-menu-avatar--mob-img-wrap' onClick={handleExit}>
                                <span>Вихід</span>
                                <img className='layout-user__header-menu-avatar--mob-img' src={exitImg} alt='img'/>
                                <img className='layout-user__header-menu-avatar--mob-img-hover' src={exitImgHover} alt='img'/>
                            </div>
                        </div>
                    </div>
                    
                    <div className={`layout-user__outlet-wrap ${isOpenMenu ? 'layout-user__outlet-wrap--open' : 'layout-user__outlet-wrap--close'}`}>
                        {
                            <Outlet />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LayoutUser;