import './UserProductCard.css';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGetData, fetchRequest, handlePriceReplace } from '../../helpers/Utils';
import ModalWindow from '../ModalWindow/ModalWindow';
import { deleteProducts, setEditeProduct, setNewBlockProductsId, setNewCollectionProductsId, setShowMessageObj, setVariationsEditeProduct, updateProducts } from '../../store/userSlice';
import arrow2Green from './../../assets/arrow2Green.svg';
import noPhotos from './../../assets/noPhotos.svg';
import ContextMenuProduct from '../ContextMenuProduct/ContextMenuProduct';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import ColorResult from '../ColorResult/ColorResult';
import MaterialResult from '../MaterialResult/MaterialResult';

function UserProductCard ({product, isCreatingBlockView=false, isHideVariations=false, isOrderReadView=false, isUserView=false, isCreatingCollectionView=false, isUserProductsView=false, isCrieteProduct=false}) {
    const newBlockProductsId = useSelector(state => state.userSlice.newBlockProductsId);
    const newCollectionProductsId = useSelector(state => state.userSlice.newCollectionProductsId);
    const editeProduct = useSelector(state => state.userSlice.editeProduct);
    const variationsEditeProduct = useSelector(state => state.userSlice.variationsEditeProduct);
    const windowWidth = useSelector(state => state.userSlice.windowWidth);
    const isOpenMenu = useSelector(state => state.userSlice.isOpenMenu);
    const [isModalDelete, setIsModalDelete] = useState(false);
    const [deleteProductId, setDeleteProductId] = useState('');
    const [isModalHide, setIsModalHide] = useState(false);
    const [isShowVariations, setIsShowVariations] = useState(false);
    const [variationsProducts, setVariationsProducts] = useState([]);
    const [hideProduct, setHideProduct] = useState({});
    const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
    const [productCardWrapWidth, setProductCardWrapWidth] = useState(null);
    const productCardWrapRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let token = localStorage.getItem('token')

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (productCardWrapRef.current) {
            let timerId = setTimeout(() => {
                setProductCardWrapWidth(productCardWrapRef?.current?.clientWidth)
            }, 150);
            return () => clearTimeout(timerId);
        }
    }, [isOpenMenu] )
    
    useEffect(() => {
        if (productCardWrapRef.current) {
            let timerId = setTimeout(() => {
                setProductCardWrapWidth(productCardWrapRef?.current?.clientWidth)
            }, 150);
            return () => clearTimeout(timerId);
        }
    }, )
    
    const handleResize = () => {
        setWindowInnerWidth(window.innerWidth)
        if (productCardWrapRef.current) {
            let timerId = setTimeout(() => {
                setProductCardWrapWidth(productCardWrapRef?.current?.clientWidth)
            }, 150);
            return () => clearTimeout(timerId);
        }
    };

    const handleClickMenu = (obj, str) => {
        if (str === 'hide') {
            handleHideProduct(obj)
            return
        }
        if (str === 'delete') {
            handleDeleteProduct(obj)
            return
        }
    }

    const handleDeleteProduct= (obj) => {
        setIsModalDelete(true)
        setDeleteProductId(obj._id)
    }

    const handleIsDeleteProduct = (boolean) => {
        if (boolean) {
            let isMainDelete = false
            if (location.pathname === '/auth/products') {
                isMainDelete = true
            } else {
                isMainDelete = false
            }
            fetchRequest('DELETE', `${process.env.REACT_APP_BASE_URL}/${isMainDelete ? 'products/main' : 'products'}/${deleteProductId}?token=${token}`, {}, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
                .then(res => {
                    if (res.success) {
                        dispatch(deleteProducts(deleteProductId))
                        dispatch(setVariationsEditeProduct([...variationsEditeProduct.filter(el => el._id !== deleteProductId)]))
                        dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                    } else {
                        console.log('DELETE UserProductCard:', res)
                    }
                })
        } 

        setIsModalDelete(false)
        setDeleteProductId('')
    }

    const handleHideProduct = (product) => {
        setIsModalHide(true)
        setHideProduct(product)
    }
    
    const handleIsHideProduct = (boolean) => {
        if (boolean) {
            let data = {
                is_hide: !hideProduct.is_hide
            }
            fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/products/hide/${hideProduct._id}?token=${token}`, data, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
                .then(res => {
                    if (res.success) {
                        dispatch(updateProducts({...hideProduct, is_hide: !hideProduct.is_hide}))
                        handleupdateProduct({...hideProduct, is_hide: !hideProduct.is_hide})
                        dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                    } else {
                        console.log('PUT UserProductCard:', res)
                    }
                })
        } 
        
        setIsModalHide(false)
        setHideProduct({})
    }

    const handleupdateProduct = (obj) => {
        let res = variationsEditeProduct.map(el => {
            if (el._id === obj._id) {
              el = obj
            }
            return el
          })
        dispatch(setVariationsEditeProduct(res))
    }

    const handleOpenProduct = (obj) => {
        dispatch(setEditeProduct(obj))
    }

    const handleSelectProductForBlock = (id) => {
        if (newBlockProductsId?.length && newBlockProductsId.includes(id)) {
            dispatch(setNewBlockProductsId([...newBlockProductsId.filter(el => el !== id)]))
        } else {
            dispatch(setNewBlockProductsId([...newBlockProductsId, id]))
        }
    }

    const handleSelectProductForCollection = (id) => {
        if (newCollectionProductsId?.length && newCollectionProductsId.includes(id)) {
            dispatch(setNewCollectionProductsId([...newCollectionProductsId.filter(el => el !== id)]))
        } else {
            dispatch(setNewCollectionProductsId([...newCollectionProductsId, id]))
        }
    }

    const handleGetVariations = () => {
        setIsShowVariations(!isShowVariations)
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/products/variations/${product._id}`)
            .then(res => {
                if (res.success && res.data?.length) {
                    setVariationsProducts(res.data.filter(el => el._id !== product._id))
                } else {
                    console.log('GET UserProductCard', res)
                }
            })
    }

    return (
        <>
            {
                isModalHide && <ModalWindow title={hideProduct.is_hide ? 'Відновити даний товар?' : 'Приховати даний товар?'} handleClick={handleIsHideProduct} />
            }   

            {
                isModalDelete && <ModalWindow title={'Видалити даний товар?'} handleClick={handleIsDeleteProduct} />
            }  
            <div className={`user-product-card ${(isUserView && !isCrieteProduct) ? 'user-product-card--variations' : ''} ${isUserProductsView ? 'user-product-card--is-user-products' : ''} ${isCrieteProduct && editeProduct._id === product._id  ? 'user-product-card--selected' : ''} ${isCrieteProduct ? 'user-product-card--is-criete-product' : ''}`} ref={productCardWrapRef}>

                {
                    isCreatingBlockView &&
                        <label className={`catalog-view__products-filter-label ${product.is_hide ? 'user-product-card--hide' : ''}`}>
                            <input className="catalog-view__products-filter-checkbox" type="checkbox" value={product._id} onChange={(e) => handleSelectProductForBlock(product._id)} />
                            <div className={`catalog-view__products-filter-checkbox-custom ${newBlockProductsId.find(el => el === product._id) ? 'catalog-view__products-filter-checkbox-custom-active' : ''}`}></div>
                        </label>
                }

                {
                    isCreatingCollectionView &&
                        <label className={`catalog-view__products-filter-label ${product.is_hide ? 'user-product-card--hide' : ''}`}>
                            <input className="catalog-view__products-filter-checkbox" type="checkbox" value={product._id} onChange={(e) => handleSelectProductForCollection(product._id)} />
                            <div className={`catalog-view__products-filter-checkbox-custom ${newCollectionProductsId.find(el => el === product._id) ? 'catalog-view__products-filter-checkbox-custom-active' : ''}`}></div>
                        </label>
                }

                {
                    isOrderReadView &&
                        <>
                            {
                                windowWidth < 768 ? 
                                    <>
                                        <div className={`user-product-card__text ${product.is_hide ? 'user-product-card--hide' : ''}`}>
                                            <div className='user-product-card__text-img--is-order-read-img-wrap'>
                                                <NavLink onClick={() => handleOpenProduct(product)} to={`/auth/products/${product._id}/?tab=0`}>
                                                    <img className={`user-product-card__text-img user-product-card__text-img--is-order-read`} src={product.images?.length ? product.images[0] : noPhotos} alt='img'/>
                                                </NavLink>
                                                <div className="user-product-card__text-info">
                                                    <div className={`user-product-card__text-info-name-wrap`}>
                                                        <NavLink className={`user-product-card__text-info-name user-product-card__text-info-name--is-order-read`} onClick={() => handleOpenProduct(product)} to={`/auth/products/${product._id}/?tab=0`}>{product.title}</NavLink>
                                                        <div className="user-product-card__text-info-text-wrap--is-order-read">
                                                            <div className="user-product-card__text-info-title--is-order-read"><span className="user-product-card__text-info-text--is-order-read">Виробник:</span> {product.vendor_name}</div>
                                                        </div>
                                                        <div className="user-product-card__text-info-text-wrap--is-order-read">
                                                            <div className="user-product-card__text-info-title--is-order-read"><span className="user-product-card__text-info-text--is-order-read">Код товару:</span> {product.code}</div>
                                                        </div>
                                                        {
                                                           !!product?.variations?.length && product.variations.map(el => (
                                                                <div className="user-product-card__varyations-text-wrap" key={el.name}>
                                                                    <div className='user-product-card__varyations-text'>{el.name}:</div>
                                                                    {
                                                                        el.name === 'Колір' && !!el.value?.length &&
                                                                            <>
                                                                                {product.color_name}
                                                                                <ColorResult color={product.color} isAdminView={true}/>
                                                                            </>
                                                                    }
                                                                    {
                                                                        el.name === 'Матеріал' && !!el.value?.length &&
                                                                            <div className='user-product-card__text-variations-material-title-wrap'>
                                                                                <span className='user-product-card__text-variations-material-title'>{product.material?.title}</span>
                                                                                <MaterialResult variation={product.material} isAdminView={true}/>
                                                                            </div>
                                                                    }
                                                                    {
                                                                        el.name !== 'Матеріал' && el.name !== 'Колір' && !!el.value?.length &&
                                                                            <div>{product.characteristics.find(ell => ell.name === el.name)?.value}</div>
                                                                    }
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="user-product-card__text-info-price-wrap--is-order-read">
                                                <div className="user-product-card__text-info-price--is-order-read">
                                                    <div className="user-product-card__text-info-text--is-order-read">Ціна:</div>
                                                    <div>{handlePriceReplace(product.price)} грн</div>
                                                </div>
                                                <div className="user-product-card__text-info-count--is-order-read">
                                                    <div className="user-product-card__text-info-text--is-order-read">Кількість:</div>
                                                    <p className="user-product-card__text-info-text-count--is-order-read">{product.count}</p>
                                                </div>
                                                <div className="user-product-card__text-info-price--is-order-read">
                                                    <div className="user-product-card__text-info-text--is-order-read user-product-card__text-info-text-right--is-order-read">Сума:</div>
                                                    <div className='user-product-card__text-info-text-right--is-order-read'>{handlePriceReplace(product.count * product.price)} грн</div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className={`user-product-card__text ${product.is_hide ? 'user-product-card--hide' : ''}`}>
                                            <NavLink onClick={() => handleOpenProduct(product)} to={`/auth/products/${product._id}/?tab=0`}>
                                                <img className={`user-product-card__text-img user-product-card__text-img--is-order-read`} src={product.images?.length ? product.images[0] : noPhotos} alt='img'/>
                                            </NavLink>
                                            <div className="user-product-card__text-info">
                                                <div className={`user-product-card__text-info-name-wrap user-product-card__text-info-name--is-order-read`}>
                                                    <NavLink className={`user-product-card__text-info-name user-product-card__text-info-name--is-order-read`} onClick={() => handleOpenProduct(product)} to={`/auth/products/${product._id}/?tab=0`}>{product?.title}</NavLink>
                                                    {
                                                        !!product?.variations?.length && product.variations.map(el => (
                                                            <div className="user-product-card__text-variations" key={el.name}>
                                                                <div className="user-product-card__text-variations-title">{el.name}:</div>
                                                                {
                                                                    el.name === 'Колір' && !!el.value?.length &&
                                                                        <>
                                                                            {product.color_name}
                                                                            <ColorResult color={product.color} isAdminView={true}/>
                                                                        </>
                                                                }
                                                                {
                                                                    el.name === 'Матеріал' && !!el.value?.length &&
                                                                    <div className='user-product-card__text-variations-material-title-wrap'>
                                                                        <span className='user-product-card__text-variations-material-title'>{product.material?.title}</span>
                                                                        <MaterialResult variation={product.material} isAdminView={true}/>
                                                                    </div>
                                                                }
                                                                {
                                                                    el.name !== 'Матеріал' && el.name !== 'Колір' && !!el.value?.length &&
                                                                        <div>{product.characteristics.find(ell => ell.name === el.name)?.value}</div>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <div className={`user-product-card__text-info-category user-product-card__text-info-category--is-order-read`}>
                                                    {
                                                        !isUserView &&
                                                            <div className="user-product-card__text-info-category-text">{product.category_name}</div>
                                                    }
                                                </div>
                                                <div className="user-product-card__text-info-price">
                                                    <div>{product.vendor_name}</div>
                                                </div>
                                                <div className="user-product-card__text-info-price">{handlePriceReplace(product.price)} грн</div>
                                                <div className="user-product-card__text-info-count">
                                                    <div className="user-product-card__text-info-text">Кількість:</div>
                                                    <p>{product.count}</p>
                                                </div>
                                                <div className="user-product-card__text-info-price">
                                                    <div className="user-product-card__text-info-text">Код:</div>
                                                    <div>{product.code}</div>
                                                </div>
                                                <div className="user-product-card__text-info-price">
                                                    <div className="user-product-card__text-info-text">Сума:</div>
                                                    <div>{handlePriceReplace(product.count * product.price)} грн</div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            }
                        </>
                }
                {
                    (isCreatingCollectionView || isCreatingBlockView) &&
                    <>
                        {
                            windowWidth < 768 ? 
                                <>
                                    <div className={`user-product-card__text ${product.is_hide ? 'user-product-card--hide' : ''}`}>
                                        <div className='user-product-card__text-img--is-order-read-img-wrap'>
                                            <NavLink onClick={() => handleOpenProduct(product)} to={`/auth/products/${product._id}/?tab=0`}>
                                                <img className={`user-product-card__text-img user-product-card__text-img--is-order-read`} src={product.images?.length ? product.images[0] : noPhotos} alt='img'/>
                                            </NavLink>
                                            <div className="user-product-card__text-info">
                                                <div className={`user-product-card__text-info-name-wrap`}>
                                                    <NavLink className={`user-product-card__text-info-name user-product-card__text-info-name--is-order-read`} onClick={() => handleOpenProduct(product)} to={`/auth/products/${product._id}/?tab=0`}>{product.title}</NavLink>
                                                    <div className="user-product-card__text-info-text-wrap--is-creating-collection">
                                                        <span className="user-product-card__text-info-text--is-creating-collection">Категорія:</span>
                                                        <div className={`user-product-card__text-info-category`}>
                                                            {
                                                                !isUserView &&
                                                                    <div className="user-product-card__text-info-category-text">{product.category_name}</div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="user-product-card__text-info-text-wrap--is-order-read">
                                                        <div className="user-product-card__text-info-title--is-order-read"><span className="user-product-card__text-info-text--is-order-read">Код товару:</span> {product.code}</div>
                                                    </div>
                                                    <div className="user-product-card__text-info-text-wrap--is-order-read">
                                                        <div className="user-product-card__text-info-title--is-order-read"><span className="user-product-card__text-info-text--is-order-read">Ціна:</span> {handlePriceReplace(product.price)} грн</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className={`user-product-card__text ${product.is_hide ? 'user-product-card--hide' : ''}`}>
                                        <NavLink onClick={() => handleOpenProduct(product)} to={`/auth/products/${product._id}/?tab=0`}>
                                            <img className='user-product-card__text-img' src={product.images?.length ? product.images[0] : noPhotos} alt='img'/>
                                        </NavLink>
                                        <div className="user-product-card__text-info">
                                            <div className={`user-product-card__text-info-name-wrap`}>
                                                <NavLink className={`user-product-card__text-info-name`} onClick={() => handleOpenProduct(product)} to={`/auth/products/${product._id}/?tab=0`}>{product.title}</NavLink>
                                            </div>
                                            <div className={`user-product-card__text-info-category`}>
                                                {
                                                    !isUserView &&
                                                        <div className="user-product-card__text-info-category-text">{product.category_name}</div>
                                                }
                                            </div>
                                            <div className="user-product-card__text-info-price">{handlePriceReplace(product.price)} грн</div>
                                            <div className="user-product-card__text-info-price">
                                                <div className="user-product-card__text-info-text">Код:</div>
                                                <div>{product.code}</div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </>
                }
                {
                    !isOrderReadView && !isCreatingCollectionView && !isCreatingBlockView &&
                        <>
                            <div className={`user-product-card__text--is-user-products ${product.is_hide ? 'user-product-card--hide' : ''}`}>
                                <NavLink onClick={() => handleOpenProduct(product)} to={`/auth/products/${product._id}/?tab=0`}>
                                 <img className='user-product-card__text-img user-product-card__text-img--is-creating' src={product.images?.length ? product.images[0] : noPhotos} alt='img'/>
                                </NavLink>
                                <div className="user-product-card__text-info user-product-card__text-info--product-view">
                                    <div className={`user-product-card__text-info-name-wrap`}>
                                        <NavLink className={`user-product-card__text-info-name user-product-card__text-info-name--is-product`} onClick={() => handleOpenProduct(product)} to={`/auth/products/${product._id}/?tab=0`}>{product.title}</NavLink>
                                        {
                                            !isHideVariations && 
                                                <div className="user-product-card__text-info-variations-wrap user-product-card__text-info-variations-wrap--des">
                                                    {
                                                        !!product?.variations?.length &&
                                                            <button onClick={handleGetVariations} className="user-product-card__text-info-variations user-product-card__text-info-variations--product-view">
                                                                <span>+{product.count_products - 1} Варіантів</span>
                                                                <img className={`user-product-card__text-info-variations-arrow ${!isShowVariations ? 'user-product-card__text-info-variations-arrow--active' : ''}`} src={arrow2Green} alt='img'/>
                                                            </button>
                                                    }
                                                </div>
                                        }
                                    </div>
                                    <div className="user-product-card__text-variations-wrap user-product-card__text-variations-wrap--des">
                                        {
                                            (!isHideVariations || isUserView) && !!product.variations?.length &&
                                                <>
                                                    {
                                                        product.variations.map(el => (
                                                            <div className="user-product-card__text-variations" key={el.name}>
                                                                <div>{el.name}:</div>
                                                                {
                                                                    el.name === 'Колір' && !!el.value?.length &&
                                                                        <>
                                                                            {product.color_name}
                                                                            <ColorResult color={product.color} isAdminView={true}/>
                                                                        </>
                                                                }
                                                                {
                                                                    el.name === 'Матеріал' && !!el.value?.length &&
                                                                        <div className='user-product-card__text-variations-material-title-wrap'>
                                                                            <span className='user-product-card__text-variations-material-title'>{product.material?.title}</span>
                                                                            <MaterialResult variation={product.material} isAdminView={true}/>
                                                                        </div>
                                                                }
                                                                {
                                                                    el.name !== 'Матеріал' && el.name !== 'Колір' && !!el.value?.length &&
                                                                        <div>{product.characteristics.find(ell => ell.name === el.name)?.value}</div>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                        }
                                    </div>
                                    {
                                        (!isCrieteProduct && windowInnerWidth > 768) &&
                                            <div className={`user-product-card__text-info-category`}>
                                                {
                                                    !isUserView &&
                                                        <div className="user-product-card__text-info-category-text">{product.category_name}</div>
                                                }
                                            </div>
                                    }
                                    <div className="user-product-card__text-info-price">{handlePriceReplace(product.price)} грн</div>
                                    <div className="user-product-card__text-info-price">
                                        <div className="user-product-card__text-info-text">Код:</div>
                                        <div>{product.code}</div>
                                    </div>
                                    {
                                        !isHideVariations && 
                                            <div className="user-product-card__text-info-variations-wrap user-product-card__text-info-variations-wrap--mob">
                                                {
                                                    !!product?.variations?.length &&
                                                        <button onClick={handleGetVariations} className="user-product-card__text-info-variations user-product-card__text-info-variations--product-view">
                                                            <span>+{product.count_products - 1} Варіантів</span>
                                                            <img className={`user-product-card__text-info-variations-arrow ${!isShowVariations ? 'user-product-card__text-info-variations-arrow--active' : ''}`} src={arrow2Green} alt='img'/>
                                                        </button>
                                                }
                                            </div>
                                    }
                                    {
                                        (!isHideVariations || isUserView) && !!product.variations?.length &&
                                            <div className="user-product-card__text-variations-wrap user-product-card__text-variations-wrap--mob">
                                                {
                                                    product.variations.map(el => (
                                                        <div className="user-product-card__text-variations" key={el.name}>
                                                            <div>{el.name}:</div>
                                                            {
                                                                el.name === 'Колір' && !!el.value?.length &&
                                                                    <>
                                                                        {el.value[0].color_name}
                                                                        <ColorResult color={product.color} isAdminView={true}/>
                                                                    </>
                                                            }
                                                            {
                                                                el.name === 'Матеріал' && !!el.value?.length &&
                                                                    <div className='user-product-card__text-variations-material-title-wrap'>
                                                                        <span className='user-product-card__text-variations-material-title'>{product.material?.title}</span>
                                                                        <MaterialResult variation={product.material} isAdminView={true}/>
                                                                    </div>
                                                            }
                                                            {
                                                                el.name !== 'Матеріал' && el.name !== 'Колір' && !!el.value?.length &&
                                                                    <div>{product.characteristics.find(ell => ell.name === el.name)?.value}</div>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                            {
                                !!editeProduct._id?.length && product.is_main_product ? <div className="user-product-card__text-main-product user-product-card__text-main-product-text">Основний товар</div>
                                    :
                                    <div className={`${editeProduct._id?.length ? 'user-product-card__text-main-product' : ''}`}>
                                        <ContextMenuProduct product={product} handleClickMenu={handleClickMenu}/>
                                    </div>
                            }
                        </>
                }
            </div>

            <div className='user-product-card__variations-products-wrap'>
                {
                    !!variationsProducts?.length && isShowVariations && variationsProducts.map(el => (
                        <UserProductCard product={el} key={el._id} isHideVariations={true} isUserView={true} isShowAcordeonVariations={true} productWrapWidth={productCardWrapWidth}/>
                    ))
                }
            </div>
        </>
    );
}

export default UserProductCard;