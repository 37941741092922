import './NotFoundView.css';
import notFound from '../../assets/notFound.png';
import MainButton from '../../components/MainButton/MainButton';
import { NavLink } from 'react-router-dom';
import PreloaderProductCustom2 from '../../components/PreloaderProductCustom2/PreloaderProductCustom2';
import { useSelector } from 'react-redux';

function NotFoundView() {
    const isOld_idUrl = useSelector(state => state.homeSlice.isOld_idUrl);

    return (
        <div className="not-found-view">
            {
                isOld_idUrl && <PreloaderProductCustom2 /> 
            }
            <div className="not-found-view--wrap container">
                <div className="not-found-view__main">
                    <img className='not-found-view__main-img' src={notFound} alt='img'/>
                    <div className="not-found-view__main-wrap">
                        <div className="not-found-view__main-title">404</div>
                        <h1 className="not-found-view__main-text">Вибачте, але сторінку яку ви шукаєте було видалено або вона не існує!</h1>
                        <h2 className="not-found-view__main-text">Ви можете повернутись на головну сторінку або перейти у каталог товарів</h2>
                        <div className="not-found-view__main--wrap">
                            <NavLink className="not-found-view__main-wrap" to='/'>
                                <MainButton text={'На головну'} typeBtn={5}/>
                            </NavLink>
                            <NavLink className="not-found-view__main-wrap" to='/catalog'>
                                <MainButton text={'Каталог товарів'} typeBtn={4}/>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFoundView;