import './CollectionProducts.css';
import { useEffect, useState } from 'react';
import MainBanner from '../../components/MainBanner/MainBanner';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchGetData } from '../../helpers/Utils';
import ProductCard from '../../components/ProductCard/ProductCard';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import SeoBlock from '../../components/SeoBlock/SeoBlock';
import PreloaderProductCustom2 from '../../components/PreloaderProductCustom2/PreloaderProductCustom2';
import { useSelector } from 'react-redux';

function CollectionProducts() {
    const scrollPositionBeforeView = useSelector(state => state.homeSlice.scrollPositionBeforeView);
    const [collection, setCollection] = useState({});
    const [isPreloader, setIsPreloader] = useState(true);
    let { collectionId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            window.scroll({top: scrollPositionBeforeView[location.pathname] ? scrollPositionBeforeView[location.pathname] : 0})
        }, 10)
        
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/collections/${collectionId}`)
            .then(res => {
                if (res.success && res.data) {
                    setCollection(res.data)
                } else {
                    navigate('/not-found')
                    console.log('GET CollectionProducts', res)
                }
                setIsPreloader(false)
            })
    }, [])

    const handleNextVariation = (obj, oldProductId) => {
        if (collection?.products?.length) {
            let res = collection.products.map(ell => {
                if (ell._id === oldProductId) {
                    ell = obj
                    return ell
                 }
                 return ell
             })

            setCollection({...collection, products: res}) 
        }
    }

    return (
        <div className="collection-products">
            <SeoBlock title={collection.seo_default_title?.length ? collection.seo_default_title : "Колекція"} description={collection.seo_default_description} keywords={collection.seo_default_keywords}/>
            <div className="collection-products-wrap container">
            {
                isPreloader && <PreloaderProductCustom2 /> 
            }
            <BreadCrumbs collectionName={collection?.title} />
            <h1 className="collection-products__title">{collection.title?.length ? collection.title : ''}</h1>
            <div className="collection-products__collections">
                {
                    !!collection.products?.length && collection.products.map((el, i) => (
                        <div className="home-view__card-wrap" key={el._id + i}>
                            <ProductCard product={el} handleNextVariation={handleNextVariation}/>
                        </div>
                    ))
                }
            </div>
            <div className="collection-products__banner-wrap">
                <MainBanner />
            </div>
            </div>
        </div>
    );
}

export default CollectionProducts;