import './ButtonScroll.css';

function ButtonScroll({scrollBtn}) {
    const handleScroll = () => {
        scrollBtn.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <button className="button-scroll" onClick={handleScroll}>
            <svg className="button-scroll__1" role="presentation" width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="50" height="50" rx="50" fill="#2a7f77" fillOpacity="0.80" stroke="none"></rect><path d="M21 27L25.5 22L30 27" stroke="#ffffff" strokeWidth="1" fill="none"></path></svg>
            <svg className="button-scroll__2" role="presentation" width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="50" height="50" rx="50" fill="#fff" fillOpacity="0.80" stroke="none"></rect><path d="M21 27L25.5 22L30 27" stroke="#2a7f77" strokeWidth="1" fill="none"></path></svg>
        </button>
    );
}

export default ButtonScroll;