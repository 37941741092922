import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  categories: [],
  products: [],
  productsCount: 0,
  vendors: [],
  variations: [],
  showMessageObj: {},
  orders: [],
  blocks: [],
  newBlockProductsId: [],
  editeBlock: {},
  editeCollection: {},
  newCollectionProductsId: [],
  collections: [],
  notifications: [],
  editeProduct: {},
  editeCategory: {},
  parentCategory: {},
  openTooltipUserProductCardId: '',
  variationsEditeProduct: [],
  newVariations: [],
  variationCharacteristicsName: [],
  searchOrders: [],
  isOpenMenu: false,
  isHiddenScroll: false,
  windowWidth: null,
  editeVendor: {},
  requestsStatus: [],
  searchTitle: '',
  searchProductTitle: '',
  countUnreadNotifications: 0,
  count_unread_orders: 0,
  count_unread_preorders: 0,
}

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    getCategories: (state, action) => {
      state.categories = action.payload;
    },
    deleteCategories: (state, action) => {
      if (!action.payload.parent_id || action.payload.parent_id === 'null') {
        state.categories = [...state.categories.filter(el => el._id !== action.payload._id)]
      } else {
        let res = state.categories.map(el => {
          if (el._id === action.payload.parent_id) {
            el.sub_categories = [...el.sub_categories.filter(ell => ell._id !== action.payload._id)]
          }
          return el
        })
        state.categories = [...res]
      }
    },
    setUpdateCategories: (state, action) => {
      if (!action.payload.parent_id || action.payload.parent_id === 'null') {
        state.categories = [...state.categories.map(el => {
          if (el._id === action.payload._id) {
            return action.payload;
          }
          return el;
        })];
      } else {
        let res = state.categories.map(el => {
          if (el._id === action.payload.parent_id) {
            let res2 = el.sub_categories.map(ell => {
              if (ell._id === action.payload._id) {
                return action.payload;
              }
              return ell;
            });
            el.sub_categories = res2;
          }
          return el;
        });
        state.categories = [...res];
      }
    },
    setShowMessageObj: (state, action) => {
      state.showMessageObj = action.payload;
    },
    getProducts: (state, action) => {
      state.products = action.payload;
    },
    setProductsCount: (state, action) => {
      state.productsCount = action.payload;
    },
    deleteProducts: (state, action) => {
      state.products = [...state.products.filter(el => el._id !== action.payload)];
    },
    updateProducts: (state, action) => {
      let res = state.products.map(el => {
        if (el._id === action.payload._id) {
          el = action.payload
        }
        return el
      })
      state.products = [...res];
    },
    getVendors: (state, action) => {
      state.vendors = action.payload;
    },
    updateVendors: (state, action) => {
      let res = state.vendors.map(el => {
        if (el._id === action.payload._id) {
          el = action.payload
        }
        return el
      })
      state.vendors = [...res];
    },
    getVariations: (state, action) => {
      state.variations = action.payload;
    },
    getOrders: (state, action) => {
      state.orders = action.payload;
    },
    updateOrders: (state, action) => {
      let res = state.orders.map(el => {
        if (el._id === action.payload._id) {
          el = action.payload
          return el
        }
        return el
      })
      state.orders = [...res];
    },
    setNewBlockProductsId: (state, action) => {
      state.newBlockProductsId = action.payload;
    },
    getBlocks: (state, action) => {
      state.blocks = action.payload;
    },
    setEditeBlock: (state, action) => {
      state.editeBlock = action.payload;
    },
    setEditeCollection: (state, action) => {
      state.editeCollection = action.payload;
    },
    setNewCollectionProductsId: (state, action) => {
      state.newCollectionProductsId = action.payload;
    },
    getCollections: (state, action) => {
      state.collections = action.payload;
    },
    getNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setEditeProduct: (state, action) => {
      state.editeProduct = action.payload;
    },
    setEditeCategory: (state, action) => {
      state.editeCategory = action.payload;
    },
    setParentCategory: (state, action) => {
      state.parentCategory = action.payload;
    },
    setVariationsEditeProduct: (state, action) => {
      state.variationsEditeProduct = action.payload;
    },
    setNewVariations: (state, action) => {
      state.newVariations = action.payload;
    },
    setSearchOrders: (state, action) => {
      state.searchOrders = action.payload;
    },
    setIsOpenMenu: (state, action) => {
      state.isOpenMenu = action.payload;
    },
    setIsHiddenScroll: (state, action) => {
      state.isHiddenScroll = action.payload;
    },
    setWindowWidth: (state, action) => {
      state.windowWidth = action.payload;
    },
    setEditeVendor: (state, action) => {
      state.editeVendor = action.payload;
    },
    setSearchTitle: (state, action) => {
      state.searchTitle = action.payload;
    },
    setSearchProductTitle: (state, action) => {
      state.searchProductTitle = action.payload;
    },
    setCountUnreadNotifications: (state, action) => {
      state.countUnreadNotifications = action.payload;
    },
    setRequestsStatus: (state, action) => {
      state.requestsStatus = action.payload;
    },
    setCount_unread_orders: (state, action) => {
      state.count_unread_orders = action.payload;
    },
    setCount_unread_preorders: (state, action) => {
      state.count_unread_preorders = action.payload;
    },
  },
})

export const { setCount_unread_preorders, setCount_unread_orders, setRequestsStatus, setCountUnreadNotifications, setSearchProductTitle, setSearchTitle, setEditeVendor, setWindowWidth, setIsHiddenScroll, setIsOpenMenu, setSearchOrders, setNewVariations, setVariationsEditeProduct, setParentCategory, setEditeCategory, setEditeProduct, getNotifications, setEditeCollection, getCollections, setNewCollectionProductsId, setEditeBlock, getBlocks, setNewBlockProductsId, setProductsCount, updateOrders, getOrders, updateVariations, getVariations, updateVendors, getVendors, updateProducts, deleteProducts, getProducts, deleteCategories, setUpdateCategories, setShowMessageObj, getCategories } = userSlice.actions

export default userSlice.reducer