import './ShoppingDelivery.css';
import { useState, useEffect } from 'react';
import { fetchGetData } from '../../helpers/Utils';
import { setShoppingProducts } from '../../store/homeSlice';
import { useDispatch, useSelector } from 'react-redux';

function ShoppingDelivery({vendorId}) {
    const shoppingProducts = useSelector(state => state.homeSlice.shoppingProducts);
    const [payment, setPayment] = useState('');
    const [paymentArr, setPaymentArr] = useState([]);
    const [delivery, setDelivery] = useState('');
    const [deliveryArr, setDeliveryArr] = useState([]);
    const [conditions_delivery, setConditions_delivery] = useState('');
    const [conditions_payment, setConditions_payment] = useState('');
    const [paymentMain, setpPaymentMain] = useState('На розрахунковий рахунок IBAN UA943510050000026001253263700');
    const dispatch = useDispatch();

    useEffect(() => {
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/vendors/${vendorId}`)
            .then(res => {
                if (res.success && res.data) {
                    if (res.data.conditions_delivery?.length) {
                        setConditions_delivery(res.data.conditions_delivery)
                    }
                    if (res.data.conditions_payment?.length) {
                        setConditions_payment(res.data.conditions_payment)
                    }
                    if (res.data.delivery_options?.length) {
                        setDeliveryArr(res.data.delivery_options)
                        setDelivery(res.data.delivery_options[0])
                    }
                    if (res.data.payment_options?.length) {
                        setPaymentArr(res.data.payment_options)
                        setPayment(res.data.payment_options[0])
                    }
                } else {
                    console.log('GET ShoppingDelivery', res)
                }
            })
    }, [])
   
    useEffect(() => {
        if (payment?.length || delivery?.length) {
            let res = shoppingProducts.map(el => {
                if (el.vendor_id === vendorId) {
                    el = {...el, payment_method: payment, delivery_method: delivery}
                }
                return el
            })
            dispatch(setShoppingProducts(res))  
        }
    }, [payment, delivery])
    
    return (
        <>
            <div className="shopping-delivery__input-label">Оплата</div>
            {
                !!conditions_payment?.length &&
                    <div className="product-view__text-tab-1-conditions_delivery">{conditions_payment}</div>
            }
            <div className="shopping-delivery__radio-wrap">
                {
                    !!paymentArr?.length && paymentArr.map(el => (
                        <label className="catalog-view__products-filter-radio-label" key={el}>
                            <input className="catalog-view__products-filter-radio-checkbox" onChange={() => setPayment(el)} value={el} checked={payment === el ? true : false} type="radio" />
                            <div className={`catalog-view__products-filter-radio-checkbox-custom ${payment?.length && payment === el ? 'catalog-view__products-filter-radio-checkbox-custom-active' : ''}`}></div>
                            <span className="catalog-view__products-filter-radio-text">{el}</span>
                        </label>
                    ))
                }
                <label className="catalog-view__products-filter-radio-label">
                    <input className="catalog-view__products-filter-radio-checkbox" onChange={() => setPayment(paymentMain)} value={paymentMain} checked={payment === paymentMain ? true : false} type="radio" />
                    <div className={`catalog-view__products-filter-radio-checkbox-custom ${payment?.length && payment === paymentMain ? 'catalog-view__products-filter-radio-checkbox-custom-active' : ''}`}></div>
                    <span className="catalog-view__products-filter-radio-text">{paymentMain}</span>
                </label>
            </div>
            
            <div className="shopping-delivery__input-label">Доставка</div>
            {
                !!conditions_delivery?.length &&
                    <div className="product-view__text-tab-1-conditions_delivery">{conditions_delivery}</div>
            }
            <div className="shopping-delivery__radio-wrap">
                {
                    !!deliveryArr?.length && deliveryArr.map(el => (
                        <label className="catalog-view__products-filter-radio-label" key={el}>
                            <input className="catalog-view__products-filter-radio-checkbox" onChange={() => setDelivery(el)} value={el} checked={delivery === el ? true : false} type="radio" />
                            <div className={`catalog-view__products-filter-radio-checkbox-custom ${delivery?.length && delivery === el ? 'catalog-view__products-filter-radio-checkbox-custom-active' : ''}`}></div>
                            <span className="catalog-view__products-filter-radio-text">{el}</span>
                        </label>
                    ))
                }
            </div>
        </>
    );
}

export default ShoppingDelivery;