import './UserNotificationsView.css';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGetData, formatPhoneNumber, handleFormattingDate } from '../../helpers/Utils';
import { Button, Menu, MenuItem, Pagination, Select } from '@mui/material';
import { getNotifications } from '../../store/userSlice';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import notifications1 from '../../assets/notifications1.svg';
import envelope from '../../assets/envelope.svg';
import envelopeOpen from '../../assets/envelopeOpen.svg';
import filterProductsImg from '../../assets/filterProductsImg.svg';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import NotificationLogo from '../../components/NotificationLogo/NotificationLogo';
import ukLocale from 'date-fns/locale/uk';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function UserNotificationsView() {
    const [searchParams, setSearchParams] = useSearchParams();
    const newSearchParams = new URLSearchParams(searchParams);
    const typeSearchParams = searchParams.get('type')
    const notificationsPageSearchParams = Number(JSON.parse(searchParams.get('notificationsPage')))
    const startDateSearchParams = JSON.parse(searchParams.get('startDate'))
    const endDateSearchParams = JSON.parse(searchParams.get('endDate'))

    const notifications = useSelector(state => state.userSlice.notifications);
    const [type, setType] = useState(typeSearchParams?.length ? typeSearchParams : '');
    const [notificationsPageCount, setNotificationsPageCount] = useState(0);
    const [notificationsPage, setNotificationsPage] = useState(notificationsPageSearchParams ? notificationsPageSearchParams : 1);
    const [isOpenSelectNotificationsTab, setIsOpenSelectNotificationsTab] = useState(false);
    const [anchorElDate, setAnchorElDate] = useState(null);
    const notificationsWrapRef = useRef(null);
    const [isPreloader, setIsPreloader] = useState(true);
    const [stateDate, setStateDate] = useState([
        {
          startDate: startDateSearchParams ? new Date(startDateSearchParams) : null,
          endDate: endDateSearchParams ? new Date(endDateSearchParams) : null,
          key: 'selection'
        }
    ]);
    const [isNotFirstRender, setIsNotFirstRender] = useState(false);
    const token = localStorage.getItem('token')
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setIsNotFirstRender(true)
        handleGetNotifications()

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    const handleScroll = () => {
        setIsOpenSelectNotificationsTab(false)
        setAnchorElDate(null)
    };

    useEffect(() => {
        if (isNotFirstRender) {
            handleGetNotifications()
        }
    }, [location])

    const handlePagination = (e, num) => {
        setNotificationsPage(num)
        newSearchParams.set('notificationsPage', JSON.stringify(num));
        setSearchParams(newSearchParams);
    }

    const handleSortCleanDate = () => {
        if (stateDate[0].startDate !== null) {
            setStateDate([{
                startDate: null,
                endDate: null,
                key: 'selection'
            }])
            newSearchParams.set('startDate', JSON.stringify(null));
            newSearchParams.set('endDate', JSON.stringify(null));
            setNotificationsPage(1)
            newSearchParams.set('page', 1);
            setSearchParams(newSearchParams);
        }
    }

    const handleNotificationsTab = (str) => {
        setType(str)
        newSearchParams.set('type', str);
        setNotificationsPage(1)
        newSearchParams.set('notificationsPage', JSON.stringify(1));
        setSearchParams(newSearchParams);
    }

    const handleSortDate = () => {
        if (stateDate[0].startDate) {
            setAnchorElDate(null)
            newSearchParams.set('startDate', JSON.stringify(stateDate[0].startDate));
            newSearchParams.set('endDate', JSON.stringify(stateDate[0].endDate));
            setNotificationsPage(1)
            newSearchParams.set('page', 1);
            setSearchParams(newSearchParams);
        }
    }

    const handleGetNotifications = () => {
        setIsPreloader(true)
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/notifications/all?page=${notificationsPageSearchParams ? notificationsPageSearchParams - 1 : notificationsPage - 1}&type=${type}&min_date=${stateDate[0].startDate ? stateDate[0].startDate : ''}&max_date=${stateDate[0].endDate ? stateDate[0].endDate : ''}&token=${token}`)
            .then(res => {
                if (res.success && res.data) {
                    dispatch(getNotifications(res.data))
                    setNotificationsPageCount(res.count % 10 === 0 ? res.count / 10 : Math.ceil(res.count / 10))
                } else {
                    console.log('GET UserNotificationsView', res)
                }
                setIsPreloader(false)
            })
    }

    return (
        <div className="user-notifications">
            <SeoBlock title={"Повідомлення"} description={""} keywords={""}/>
            <div className="user-notifications__header">
                <div className="user-notifications__header-title-wrap">
                    <img className="user-notifications__header-title-img" src={notifications1} alt='img' />
                    <h1 className="user-notifications__header-title">Повідомлення</h1>
                </div>
                <div className="user-notifications__text-tab--wrap">
                    <div className='user-notifications__text-tab-wrap'>
                        <button className={`user-notifications__text-tab ${type === '' ? 'user-order__text-tab-active' : ''}`} onClick={() => handleNotificationsTab('')}>Всі повідомлення</button>
                        <button className={`user-notifications__text-tab ${type === 'contact' ? 'user-order__text-tab-active' : ''}`} onClick={() => handleNotificationsTab('contact')}>Контакти</button>
                        <button className={`user-notifications__text-tab ${type === 'partner' ? 'user-order__text-tab-active' : ''}`} onClick={() => handleNotificationsTab('partner')}>Партнери</button>
                        <button className={`user-notifications__text-tab ${type === 'about' ? 'user-order__text-tab-active' : ''}`} onClick={() => handleNotificationsTab('about')}>Про нас</button>
                        <button className={`user-notifications__text-tab ${type === 'indiviual_order' ? 'user-order__text-tab-active' : ''}`} onClick={() => handleNotificationsTab('indiviual_order')}>Індивідуальне виробництво</button>
                    </div>
                    <Select
                        value={type}
                        className='product-view__variations-select user-notifications__select-wrap'
                        onChange={(e) => handleNotificationsTab(e.target.value)}
                        open={isOpenSelectNotificationsTab}
                        onClose={() => setIsOpenSelectNotificationsTab(false)}
                        onOpen={() => setIsOpenSelectNotificationsTab(true)}
                    >
                        <MenuItem className={`product-view__variations-select-item ${!type?.length ? 'product-view__variations-select-item-active' : ''}`} value={''} disableRipple={true}>Всі повідомлення</MenuItem>
                        <MenuItem className={`product-view__variations-select-item ${type === 'contact' ? 'product-view__variations-select-item-active' : ''}`} value={'contact'} disableRipple={true}>Контакти</MenuItem>
                        <MenuItem className={`product-view__variations-select-item ${type === 'partner' ? 'product-view__variations-select-item-active' : ''}`} value={'partner'} disableRipple={true}>Партнери</MenuItem>
                        <MenuItem className={`product-view__variations-select-item ${type === 'about' ? 'product-view__variations-select-item-active' : ''}`} value={'about'} disableRipple={true}>Про нас</MenuItem>
                        <MenuItem className={`product-view__variations-select-item ${type === 'indiviual_order' ? 'product-view__variations-select-item-active' : ''}`} value={'indiviual_order'} disableRipple={true}>Індивідуальне виробництво</MenuItem>
                    </Select>
                </div>
            </div>

            {
                isPreloader ? <PreloaderCustom />
                    :
                    <>
                        <div className="user-notifications__filter">
                            <div className="user-notifications__filter-wrap">
                                <p className="user-products-filter__filter-lable">Дата</p>
                                <Button
                                    aria-haspopup="true"
                                    onClick={(e) => setAnchorElDate(e.currentTarget)}
                                    className='user-notifications__filter-btn--wrap'
                                    disableRipple={true}
                                >
                                    <div className='user-notifications__filter-btn-wrap'>
                                        <img className='user-products-filter__filter-btn-img' src={filterProductsImg} alt='img' />
                                        <div className='user-notifications__filter-btn-text'>
                                            {
                                                stateDate[0]?.startDate ?
                                                    <div className='user-order__filter-date-select'>
                                                        <div>{stateDate[0]?.startDate ? new Date(stateDate[0]?.startDate).toLocaleString().split(',')[0] : 'дд.мм.рррр'}</div>
                                                        <div>/</div>
                                                        <div>{stateDate[0]?.endDate ? new Date(stateDate[0]?.endDate).toLocaleString().split(',')[0] : 'дд.мм.рррр'}</div>
                                                    </div>
                                                    : <div>Без сортування</div>
                                            }
                                        </div>
                                    </div>
                                </Button>
                                <Menu
                                    anchorEl={anchorElDate}
                                    open={Boolean(anchorElDate)}
                                    onClose={() => setAnchorElDate(null)}
                                    disableRipple={true}
                                >
                                    <MenuItem disableRipple={true}>
                                        <div className="user-products-filter__select-wrap">
                                            <DateRange
                                                editableDateInputs={true}
                                                onChange={item => setStateDate([item.selection])}
                                                moveRangeOnFirstSelection={false}
                                                ranges={stateDate}
                                                weekStartsOn={1}
                                                locale={ukLocale}
                                            />
                                            <div className='user-order__filter-date-btn-wrap'>
                                                <button className='user-order__filter-btn-del' onClick={handleSortCleanDate}>Очистити</button>
                                                <button className='user-order__filter-btn' onClick={handleSortDate}>Показати</button>
                                            </div>
                                        </div>
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>

                        <div className="user-notifications__notifications--wrap">
                            <div className="user-notifications__products-header--wrap">
                                <div className="user-notifications__products-header-wrap">
                                    <div className="user-notifications__products-header-name">Ім'я</div>
                                    <div className="user-notifications__products-header-page">Сторінка</div>
                                    <div className="user-notifications__products-header-tel">Контакти</div>
                                    <div className="user-notifications__products-header-date">Дата</div>
                                </div>
                            </div>
                            <div className="user-notifications__notifications-wrap">
                                {
                                    !!notifications?.length ? notifications.map(el => (
                                        <div className={`user-notifications__notifications ${!el.is_read ? 'user-notifications__notifications--unread' : ''}`} onClick={() => navigate(`${el._id}`)} ref={notificationsWrapRef} key={el._id}>
                                            <div className="user-notifications__notifications-text-wrap">
                                                <div className='user-notifications__notifications-text-logo-wrap'>
                                                    <NotificationLogo notification={el} />
                                                    <div className="user-notifications__notifications-text--wrap">
                                                        <div className="user-notifications__notifications-text-name-wrap">
                                                            <div className="user-notifications__notifications-text-name--wrap">
                                                                <span className="user-notifications__notifications-text-name">{!!el.full_name?.length ? el.full_name : !!el.contact_name?.length ? el.contact_name : el.email}</span>
                                                            </div>
                                                            <div className="user-notifications__notifications-text-message">{!!el.message?.length ? el.message : ''}</div>
                                                        </div>
                                                        <div
                                                            className={`user-notifications__notifications-text-type user-notifications__notifications-text-type--mob ${el.type === 'contact' ? 'user-notifications__notifications-type-1' : el.type === 'partner' ? 'user-notifications__notifications-type-2' : el.type === 'about' ? 'user-notifications__notifications-type-3' : el.type === 'indiviual_order' ? 'user-notifications__notifications-type-4' : 'user-notifications__notifications-type-5'}`}
                                                        >
                                                            {el.type === 'contact' ? 'Контакти' : el.type === 'partner' ? 'Партнери' : el.type === 'about' ? 'Про нас' : el.type === 'indiviual_order' ? 'Інд виробн' : 'Виробник'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`user-notifications__notifications-text-type user-notifications__notifications-text-type--des ${el.type === 'contact' ? 'user-notifications__notifications-type-1' : el.type === 'partner' ? 'user-notifications__notifications-type-2' : el.type === 'about' ? 'user-notifications__notifications-type-3' : el.type === 'indiviual_order' ? 'user-notifications__notifications-type-4' : 'user-notifications__notifications-type-5'}`}>{el.type === 'contact' ? 'Контакти' : el.type === 'partner' ? 'Партнери' : el.type === 'about' ? 'Про нас' : el.type === 'indiviual_order' ? 'Інд виробн' : 'Виробник'}</div>
                                                <div className="user-notifications__notifications-text"><p>{formatPhoneNumber(el.phone)}</p><p>{el.email}</p></div>
                                                <div className="user-notifications__notifications-text-date user-notifications__notifications-text-date--des">{handleFormattingDate(el.created_at)}</div>
                                            </div>
                                            <div className='user-notifications__notifications-btn-wrap'>
                                                <div className="user-notifications__notifications-text-date user-notifications__notifications-text-date--mob">{handleFormattingDate(el.created_at)}</div>
                                                <button className='user-notifications__notifications-btn' onClick={() => navigate(`${el._id}`)}>
                                                    {
                                                        !el.is_read ?
                                                            <svg className='user-notifications__notifications-btn-img' version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                viewBox="0 0 60 60" xmlSpace="preserve">
                                                                <path d="M0,8.5v2.291v38.418V51.5h60v-2.291V10.791V8.5H0z M36.625,30.564l-5.446,5.472c-0.662,0.615-1.698,0.614-2.332,0.026
                                                                    l-5.473-5.498l0.048-0.047L3.647,10.5h52.719L36.577,30.518L36.625,30.564z M20.524,30.533L2,48.355V11.923L20.524,30.533z
                                                                    M21.934,31.95l5.523,5.549c0.709,0.661,1.619,0.993,2.533,0.993c0.923,0,1.85-0.339,2.581-1.02l5.496-5.522L56.304,49.5H3.686
                                                                    L21.934,31.95z M39.477,30.534L58,11.922v36.433L39.477,30.534z"/>
                                                            </svg>
                                                            :
                                                            <svg className='user-notifications__notifications-btn-img' version="1.1" id="Capa_1" fill='#19191D' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                viewBox="0 0 490 490" xmlSpace="preserve">
                                                                <path d="M489.651,174.021c-0.004-4.04-2.689-7.538-6.277-9.264L250.611,1.89c-3.594-2.519-8.376-2.524-11.964,0.014L7.151,164.672
                                                                    C2.992,166.039-0.005,169.96,0,174.58l0.348,305.013c0.006,5.759,4.677,10.414,10.43,10.407l468.815-0.535
                                                                    c5.754-0.007,10.413-4.671,10.406-10.432L489.651,174.021z M185.079,336.446l119.865-0.137l146.781,132.348l-209.826,0.24
                                                                    l-203.302,0.232L185.079,336.446z M324.776,326.131l144.066-129.446l0.297,259.614L324.776,326.131z M244.653,23.153l217.08,151.893
                                                                    L305.461,315.468l-120.948,0.138L27.918,175.544L244.653,23.153z M165.224,326.314L21.159,456.807L20.862,197.2L165.224,326.314z"/>
                                                            </svg>
                                                    }
                                                    <span>Переглянути</span>
                                                </button>
                                            </div>
                                            <img className='user-notifications__notifications-btn-img user-notifications__notifications-btn-img--mob' src={!el.is_read ? envelope : envelopeOpen} alt='img' />
                                        </div>
                                    ))
                                        :
                                        <div className="user-order__order-error">Повідомлення відсутні</div>
                                }
                            </div>
                        </div>
                        {
                            !!notifications?.length &&
                            <div className="user-notifications__notifications-pagination-wrap">
                                <Pagination onChange={(e, num) => handlePagination(e, num)} page={notificationsPage} count={notificationsPageCount} boundaryCount={1} />
                            </div>
                        }
                    </>
            }
        </div>
    );
}

export default UserNotificationsView;