import './OfferView.css';

function OfferView() {
	return (
		<div className='offer-view'>
			<div className='page'>
				<h1>
					Публічний договір (оферта) про надання послуг по розміщенню інформації
				</h1>
				<div className='section'>
					<div className='layoutArea'>
						<div className='column'>
							<p>
								<strong>1.</strong> Загальні положення
							</p>
						</div>
					</div>
					<div className='layoutArea'>
						<div className='column'>
							<p>
								Публічний договір (оферта) про надання послуг по розміщенню
								інформації
							</p>
						</div>
					</div>
					<div className='layoutArea'>
						<div className='column'>
							<p>
								1.1. Цей документ, являє собою відкриту пропозицію (далі -
								"Оферта") Фізичної особи- підприємця КОБЗАР ОЛЕКСАНДРА
								ПЕТРОВИЧА, ідентифікаційний код - 3120201919 (далі -
								"Виконавець"), адресована невизначеному кола осіб укласти -
								Договір про надання послуг (далі - "Договір" ) на викладених в
								цій Оферті умовах.
							</p>
							<p>
								1.2. Оферта набуває чинності з моменту її розміщення на сайті{' '}
								<a href='https://vyroby.com/' rel='noreferrer' target='_blank'>
									https://vyroby.com/
								</a>{' '}
								і діє до моменту її відкликання Виконавцем.
							</p>
							<p>
								1.3. Відповідно до ст. 633, 638, 641 Цивільного Кодексу України,
								цей Договір є публічним договором, і в разі прийняття (акцепту)
								викладених нижче умов будь-яка дієздатна особа стає Замовником
								(далі - "Користувач") і зобов'язується виконувати умови цього
								Договору, доповнень і додатків до нього, які є його невід'ємною
								частиною.
							</p>
							<p>
								1.4. Виконавець залишає за собою право вносити зміни в Оферту і
								/ або відкликати Оферту в будь-який момент на свій розсуд. У
								разі внесення Виконавцем змін в Оферту, такі зміни вступають в
								силу з моменту розміщення такого зміненого тексту Оферти за
								зазначеним в п. 1.2. цього Договору посиланням, якщо інший
								термін вступу змін не передбачений при такому розміщенні.
							</p>
							<p>
								1.5. У цьому Договорі, якщо контекст не вимагає іншого, наведені
								нижче терміни мають таке значення:
								<br /> <br />
								Виконавець - Фізична особа-підприємець КОБЗАР ОЛЕКСАНДР
								ПЕТРОВИЧ, ідентифікаційний код - 3120201919.
							</p>
							<p>
								Сайт - Інтернет-ресурс Виконавця{' '}
								<a href='https://vyroby.com/' target='_blank' rel='noreferrer'>
									https://vyroby.com/
								</a>
								.
								<br /> <br />
								Адміністрація - Фізична особа-підприємець КОБЗАР ОЛЕКСАНДР
								ПЕТРОВИЧ і / або особи, уповноважені належним чином Виконавцем
								на управління Сайтом і надання Сервісів Користувачам при
								використанні Сайту відповідно до цього Договору.
								<br /> <br /> Власник Сайту - Фізична особа-підприємець КОБЗАР
								ОЛЕКСАНДР ПЕТРОВИЧ, ідентифікаційний код - 3120201919, є
								постачальником послуг проміжного характеру в інформаційній сфері
								в розумінні Закону України &laquo;Про електронну комерцію&raquo;
								і не є ініціатором передачі інформації.
								<br /> <br />
								Сервіси - сукупність послуг, що надаються Виконавцем відповідно
								до цього Договору послуг для користувачів на платній, або
								безкоштовній основі, в тому числі, але не виключно, послуги з
								реєстрації користувачів, модерації, розміщення на Сайті товарних
								позицій Продавців і іншої інформації, послуги доступу, послуги з
								розміщення реклами.
								<br /> <br />
								Відвідувач - будь-яка особа, яка взаємодіє з Сайтом будь-яким
								способом, в тому числі і випадкові гості Сайту.
								<br /> <br />
								Користувач - будь-яка дієздатна фізична особа, фізична особа -
								підприємець або юридична особа, яка прийняла умови цього
								Договору і користується Сервісами Виконавця. Користувач може
								зареєструватися на Сайті як Покупець, або Продавець.
								<br /> <br />
								Контент - будь-яка інформація, яка розміщується Користувачем на
								Сайті, включаючи, але не обмежуючись: назва та опис товарних
								позицій, фотографії, характеристики товару, інформація про
								Продавця або Користувача, відгуки, тощо.
								<br /> <br />
								Реєстрація - процедура заповнення Користувачем реєстраційної
								форми на Сайті, після затвердження якої Виконавцем такій особі
								присвоюється відповідний логін і пароль для доступу до Сервісів
								Виконавця і персональній сторінці зареєстрованого Користувача.
								Авторизація - введення зареєстрованим Користувачем своїх логіна
								і пароля для доступу до Сервісів Виконавця і входу в Особистий
								профіль.
								<br /> <br />
								Особистий профіль - персональна сторінка зареєстрованого
								Користувача на Сайті, за допомогою якої зареєстрований
								Користувач здійснює управління своїм обліковим записом
								(акаунтом) та Балансом (якщо є).
							</p>
							<p>
								Логін - ідентифікатор Користувача при авторизації на Сайті, який
								він використовує в процесі користування Сервісами Виконавця
								відповідно до цього Договору.
								<br /> Як Логін використовується адреса електронної пошти
								Користувача. Забороняється реєструвати і використовувати кілька
								Логінів одним і тим же Користувачем. При цьому не допускається
								використання Користувачем Логіна, вже використовуваного іншим
								Користувачем.
							</p>
							<p>
								Акцепт - повне прийняття Користувачем умов Договору, в
								передбаченому Договором порядку. Пароль - символьна комбінація,
								що забезпечує в сукупності з логіном ідентифікацію Користувача
								при використанні Сервісів Виконавця, які надаються відповідно до
								цього Договору.
							</p>
							<p>
								Продавець - будь-яка дієздатна особа, яка здійснює діяльність з
								продажу товарів, зареєстрована на Сайті і що має доступ до
								розділу сайту &laquo;Особистий профіль&raquo;, в якому може
								розповсюджувати інформацію про себе і про пропоновані товари
								відповідно до умов цього Договору. Продавець може бути одночасно
								Покупцем.
							</p>
							<p>
								Покупець - будь-яка дієздатна фізична або юридична особа,
								фізична особа - підприємець, яка прийняла умови цього Договору і
								яка, користуючись можливостями Сайту, купує і / або замовляє
								запропоновані Продавцями, товари. Покупець може користуватися
								можливостями Сайту шляхом реєстрації на Сайті зі створенням
								свого &laquo;Особистого профілю&raquo;. Покупець може бути
								одночасно Продавцем.
							</p>
							<p>
								Товарна позиція - комерційна пропозиція Продавця про продаж
								товару, яке публікується їм на Сайті.
								<br /> Зображення - фотографії (аватари) користувачів,
								прикріплені фото / зображення до Товарним позиціях, додані фото
								/ зображення в чаті на Сайті.
							</p>
							<p>
								Відгук - коментарі Користувача на Сайті з приводу його взаємин з
								іншим Користувачем в процесі покупки товару, що здійснюються за
								сприяння надаються Виконавцем Послуг.
								<br /> Баланс - віртуальний рахунок Користувача на Сайті, що
								відображає суму перерахованих Користувачем Виконавцю коштів в
								якості передоплати за надані Виконавцем Сервіси Користувачеві.
							</p>
							<p>
								Облікова час - Київський час. Всі дати, які вказуються при
								використанні Сайту і Сервісів Виконавця, обліковуються за
								Київським часом.
								<br /> Правила конфіденційності - умови роботи Виконавця з
								конфіденційною інформацією на Сайті. Правила конфіденційності є
								невід'ємною частиною Договору.
							</p>
							<p>
								Правила надання Послуг - докладний опис Сервісів Виконавця,
								включаючи їх вартість, а також правила роботи з Сервісом
								Продавцем і Покупцем. Правила надання Послуг є невід'ємною
								частиною Договору.
								<br /> Рахунок - рахунок - фактура, що надається Виконавцем
								Користувачу для проведення розрахунків за отримані послуги.
							</p>
							<p>
								<strong>2.</strong> Предмет Договору
							</p>
							<p>
								2.1. В порядку та на умовах, викладених в цій Оферті, Виконавець
								надає Користувачеві послуги доступу до Сервісів і послуги
								користування Сервісами.
							</p>
							<p>
								2.2. Перелік послуг, що надається Виконавцем, а також їх
								вартість вказуються в &laquo;Особистому профілі&raquo;.
							</p>{' '}
							<p>
								2.3. Укладення цього Договору здійснюється шляхом прийняття
								(акцепту) Користувачем пропозиції Виконавця, викладеного в цьому
								Договорі. Моментом укладення цього Договору вважається момент
								будь-якої взаємодії Користувача з Сайтом.
							</p>
							<p>
								2.4. Будь-яка взаємодія Користувача з Сайтом (в тому числі, але
								не виключно, реєстрація на Сайті, перерахування Користувачем
								грошових коштів Виконавцю в якості авансового платежу за надані
								Сервіси Виконавця та / або фактичне використання Сервісів
								Виконавця) означає беззастережну згоду Користувача з усіма
								пунктами цього Договору (включаючи його застосування) і
								беззастережне прийняття його умов із зобов'язаннями
								дотримуватись обов'язків, покладені на Користувача за цим
								Договором. Така взаємодія з Сайтом є повним і беззастережним
								акцептом даного Договору, незнання якого не звільняє Користувача
								від відповідальності за недотримання його умов.
							</p>
							<p>
								2.5. У разі незгоди Користувача з будь-якими з положень цього
								Договору, Користувач не має права використовувати Сервіси
								Виконавця і Виконавець просить його покинути Сайт.
							</p>
							<p>
								{' '}
								2.6. Місцем укладення цього Договору Сторони визнають
								місцезнаходження Виконавця.
							</p>
							<p>
								{' '}
								2.7. Крім тексту цього Договору, порядок надання Сервісів
								Виконавцем визначаються Правилами конфіденційності та Правилами
								надання Послуг, які є невід'ємною частиною Договору.
							</p>
							<p>
								2.8. Цей Договір може бути змінений Виконавцем без попереднього
								повідомлення. Продовження використання Сайту Користувачем
								означає згоду такого Користувача із змінами в Договорі.
							</p>
							<p>
								{' '}
								2.9. Виконавець має право в будь-який момент відмовити
								будь-якому Користувачеві в реєстрації і / або використанні Сайту
								в разі порушення ним умов цього Договору.
							</p>
							<p>
								<strong>3.</strong> Опис послуг
							</p>
							<p>
								3.1. Сайт надає послуги, що дозволяють Користувачам
								реєструватися і створювати на Сайті облікові записи, пропонувати
								до продажу товари, ознайомлюватися з пропозиціями Продавців і
								здійснювати покупки.
							</p>
							<p>
								{' '}
								3.2. Після реєстрації на Сайті Продавець отримує доступ до
								розділу сайту &laquo;Особистий профіль&raquo;, якому
								присвоюється унікальний номер і в якому він може публікувати
								інформацію про себе: опис та інформацію про товари, що
								пропонуються Продавцем, іншу інформацію про нього.
							</p>
							<p>
								3.3. Після реєстрації на Сайті Покупець отримує доступ до
								розділу сайту &laquo;Кабінет Покупця&raquo;, в якому
								зберігається інформація про нього, історія замовлень, інша
								інформація і за допомогою якого він може робити замовлення,
								спілкуватися в чаті і т.д., не заповнюючи повторно свої дані.
							</p>
							<p>
								3.4. Будь якій Користувач, користуючись сервісами на Сайті, може
								залишати свої відгуки про інших Користувачів.
							</p>
							<p>
								3.5. Реєстрація та створення на Сайті облікового запису, доступ
								до розділу сайту &laquo;Особистий профіль&raquo;, підрозділами
								Сайту &laquo;Кабінет Продавця-Виробника&raquo; і &laquo;Кабінет
								Покупця&raquo;, розміщення Продавцями товарних позицій на Сайті,
								оформлення Покупцями замовлень здійснюється відповідно до
								тарифу, встановленого для відповідного Користувача.
							</p>
							<p>3.6. Всі Товарні позиції проходять модерацію.</p>
							<p>
								{' '}
								3.7. Власник Сайту не є стороною електронної угоди між Продавцем
								і Покупцем, предметом якої виступають товари, пропоновані
								Продавцями на Сайті. Всі угоди між Користувачами укладаються
								безпосередньо. Власник Сайту не є учасником таких угод, а лише
								надає комунікаційну торгову площадку для розміщення Торгових
								позицій. Власник Сайту не несе відповідальності за зміст
								інформації, що передається або одержуваної інформації і за
								шкоду, заподіяну в результаті використання Користувачами
								результатів сервісів Сайту.
							</p>
							<p>
								<strong>4.</strong> Порядок надання та використання Сервісів
								Виконавця
							</p>
							<p> 4.1. Сервіси надаються Виконавцем тільки для Користувачів.</p>
							<p>
								{' '}
								4.2. Особа, яка бажає стати Користувачем, зобов'язане пройти
								процедуру реєстрації на відповідній сторінці сайту. При
								реєстрації Користувач зобов'язується надати в реєстраційній
								формі (анкеті) достовірну, повну і точну інформацію про себе і
								періодично оновлювати її з метою забезпечення її актуальності і
								повноти. Користувач зобов'язується не вводити Виконавця та / або
								інших користувачів в оману щодо своєї особи / найменування, а
								також не розміщувати на Сайті адреси, номери телефонів, адреси
								електронної пошти, паспортні / реєстраційні дані та іншу
								інформацію будь-яких третіх осіб. Що стосується неправильного
								вказівки Користувачем адреси електронної пошти та контактний
								номер телефону, Виконавець має право відмовити такому
								Користувачеві в подальшому наданні Послуг.
							</p>
							<p>
								{' '}
								4.3. З моменту реєстрації на Сайті Користувач погоджується на
								обробку Виконавцем персональних даних Користувача, зазначених їм
								при реєстрації, (збір, реєстрація, накопичення, зберігання,
								адаптування, зміна, поновлення, уточнення, використання і
								поширення, реалізація, передача, знеособлення, знищення) з метою
								забезпечення можливості надання Послуг, а також з метою
								інформаційного обслуговування Користувача.
							</p>
							<p>
								{' '}
								4.4 . З моменту реєстрації Користувач отримує можливість оплати
								Сервісів Виконавця.
							</p>
							<p>
								{' '}
								4.5. Користувач погоджується з тим, що дії, вчинені на Сайті
								після реєстрації Користувача, визнаються діями Користувача.
								Користувач несе повну відповідальність за збереження
								конфіденційності його облікового запису (в тому числі логіна і
								пароля) і за всю діяльністю, яка відбувається з його обліковим
								записом. Виконавець рекомендує використовувати Паролі (при зміні
								Користувачем самостійно Паролю), які складаються з не менше 6
								(шести) символів і включають одночасно рядкові і великі літери,
								а також цифри.
							</p>
							<p>
								{' '}
								4.6. Користувач не має права передавати, знижувати ціну,
								продавати, передавати в користування і т.п. свої логін і пароль
								для доступу до Сайту і Сервісів Виконавця третім особам без
								згоди Виконавця. У разі передачі логіна і пароля будь-якій
								третій особі, всю відповідальність за дії такої третьої особи
								несе Користувач.
							</p>
							<p>
								{' '}
								4.7. У разі порушення безпеки або несанкціонованого використання
								облікового запису Користувача, він повинен негайно повідомити
								про це Виконавця. Виконавець не несе відповідальності за
								будь-які збитки, спричинені несанкціонованим використанням
								облікового запису Користувача. Користувач не може
								використовувати Пароль і Облікову запис іншого Користувача без
								спеціального дозволу і згоди власника цього облікового запису і
								/ або Паролю. Сайт та Виконавець не несуть відповідальності за
								будь-які збитки чи шкоду, що виникли внаслідок невиконання
								Користувачем цих зобов'язань.
							</p>
							<p>
								{' '}
								4.8. Реєструючись, Користувач погоджується на отримання
								інформаційних повідомлень на вказану при реєстрації адресу
								електронної пошти та / або номер мобільного телефону. Виконавець
								зобов'язується не передавати адресу електронної пошти або номер
								мобільного телефону Користувача третім особам без згоди
								Користувача (за винятком випадків, передбачених чинним
								законодавством України).
							</p>
							<p>
								{' '}
								4.9. Користувач має право в будь-який час припинити використання
								Сервісів Виконавця, повідомивши про це Виконавця.
							</p>
							<p>
								4.10. Виконавець не гарантує доступність Сайту і Сервісів
								цілодобово. Виконавець має право в будь-який момент відмовити
								будь-якому Користувачеві в використанні Сервісів при порушенні
								ним цього Договору.
							</p>
							<p>
								{' '}
								4.11. Користувач несе відповідальність за дотримання прав
								(матеріальних і нематеріальних) третіх осіб щодо інформації,
								переданої (наданої) Виконавцю при реєстрації Користувача,
								використанні Сайту і отриманні Сервісів Виконавця. Користувачі
								самостійно оцінюють правомірність використання ними Сайту і
								наданих Виконавцем Послуг, в тому числі і з точки зору чинного
								законодавства України:
							</p>
							<p>
								- які порушують чинне законодавство України;
								<br /> - містять спам, схеми фінансових "пірамід";
								<br /> - є незаконними, шкідливими, загрозливими, такими, що
								ображають моральність, наклепницькими, такими, що порушують
								авторські права або інші права інтелектуальної власності третіх
								осіб, які пропагують ненависть і / або дискримінацію людей за
								расовою, етнічною, статевою, соціальною ознаками;
								<br />- містять посилання на Інтернет-ресурси, що належать
								Користувачам або третім особам; - містять контактні дані
								Користувача (номер телефону, акаунти соціальних мереж або
								сервісів миттєвих повідомлень);
								<br />- порушують права третіх осіб.
							</p>
							<p>
								Виконавець має право ознайомлення з історією особистих
								повідомлень і в будь-який момент видалити приватне повідомлення,
								яке не відповідає вимогам цього Договору.
							</p>
							<p>
								Користувач зобов'язується:
								<br />- Не вживати ніяких дій, які можуть призвести до
								непропорційно великого навантаження на інфраструктуру Сайту;
								<br />- Не використовувати автоматичні програми для отримання
								доступу на Сайт без письмового дозволу Виконавця;
								<br />- Не перешкоджати і не намагатися перешкоджати роботі та
								іншої діяльності на Сайті; а також не перешкоджати дії
								автоматичних систем або процесів, а також інших заходів, з метою
								перешкодити або обмежити доступ на Сайт;
								<br />- Не використовувати відомості, надані іншими
								Користувачами, в інших цілях, крім як для здійснення угоди
								безпосередньо з цим Користувачем, без письмового дозволу іншого
								Користувача.
							</p>
							<p>
								Доступ Користувача до Сайту і Сервісів Виконавця, що порушує цей
								Договір, може бути обмежений Виконавцем на постійній або
								тимчасовій основі.
							</p>
							<p>
								Надання Виконавцем Послуг здійснюється на безоплатній основі,
								або за плату, відповідно до отриманих рахунків.
							</p>
							<p>
								Всі угоди укладаються між Користувачами (Покупцями і Продавцями)
								безпосередньо. Таким чином, Виконавець не є учасником угод
								користувачів, а лише надає комунікаційну торговельну платформу
								для розміщення оголошень.
							</p>
							<p>
								<strong>5.</strong> Вартість послуг та порядок оплати Сервісів
								Користувачем
							</p>
							<p>
								{' '}
								5.1. Користувач використовує платні Сервіси Виконавця відповідно
								до умов узгоджених на
							</p>
							<p>
								Сайті в &laquo;Особистому профілі&raquo; (який є
								невід&rsquo;ємними додатком до цього договору).
							</p>
							<p>
								<strong>6.</strong> Права та обов'язки Виконавця
							</p>
							<p>
								6.1. Обов'язки Виконавця полягають виключно в забезпеченні
								можливості отримання Користувачем Сервісів Виконавця в порядку,
								визначеному цим Договором.
							</p>
							<p>
								6.2. Виконавець залишає за собою право на свій власний розсуд
								змінювати або видаляти будь- яку опубліковану на Сайті
								інформацію, припиняти, обмежувати або припиняти доступ
								Користувача до Сервісів Виконавця в будь-який час з будь-якої
								причини.
							</p>
							<p>
								{' '}
								6.3. Виконавець має право встановлювати будь-які обмеження у
								використанні Сервісів Користувачами.
							</p>
							<p>
								6.4. Виконавець має право змінювати умови цього Договору.
								Інформація про такі зміни публікується Виконавцем на Сайті і /
								або в інформаційній розсилці. Використання Користувачем Сервісів
								Виконавця після будь-яких змін Договору означає його згоду з
								такими змінами та / або доповненнями.
							</p>
							<p>
								6.5. Виконавець має право розміщувати рекламну і / або іншу
								інформацію в будь-якому розділі сайту без узгодження з
								Користувачем.
							</p>
							<p>
								{' '}
								6.6. У разі порушення Користувачем умов цього Договору,
								Виконавець має право призупиняти, обмежувати або припиняти
								доступ такого Користувача до будь-якого з Сервісів Виконавця в
								односторонньому порядку в будь-який час, не несучи
								відповідальності за будь-яку шкоду, яка може бути заподіяна
								Користувачеві такими діями
							</p>
							<p>
								6.7. Виконавець має право здійснювати розсилання Користувачам
								повідомлень, в тому числі електронних повідомлень на адреси
								електронної пошти Користувача або SMS-повідомлень на номери
								мобільних телефонів Користувача, що містять
								організаційно-технічну, інформаційну чи іншу інформацію про
								можливості Сервісів Виконавця.
							</p>
							<p>
								6.8. Виконавець зобов'язується не використовувати облікові дані
								Користувача, отримані при реєстрації в цілях, не передбачених
								цим Договором та додатками до нього і гарантує нерозголошення
								цих даних, за винятком випадків, коли розкриття такої інформації
								є обов'язком Виконавця в силу законодавства України.
							</p>
							<p>
								6.9. Виконавець зобов'язується надавати Користувачу можливість
								отримання консультацій служби технічної підтримки Виконавця по
								електронній пошті при вказівці Користувачем свого логіна або
								контактних даних. Обсяг консультацій обмежується конкретними
								питаннями, пов'язаними з наданням Сервісу. Виконавець може на
								свій розсуд вимагати від Користувача оформлення запиту до служби
								технічної підтримки по електронній пошті з адреси, вказаної
								Користувачем при реєстрації.
							</p>
							<p>
								6.10. Виконавець має право здійснювати модерацію всіх товарних
								позицій, Зображень і в будь- який момент на свій розсуд видаляти
								їх з розміщення на Сайті без пояснення причин.
							</p>
							<p>
								{' '}
								6.11. Виконавець залишає за собою право видаляти зі своїх
								серверів будь-яку інформацію або матеріали, які, на думку
								Виконавця, є неприйнятними, небажаними або такими, що порушують
								цей Договір.
							</p>
							<p>
								6.12. Виконавець зобов'язана виконувати інші обов'язки,
								встановлені цим Договором. <br />
								<br />
								<strong>7.</strong> Права та обов'язки Користувача
							</p>
							<p>
								7.1. Користувач зобов'язується виконувати умови цього Договору
								та додатків до нього, а також оплачувати надані йому Сервіси в
								порядку і на умовах, передбачених цим Договором та додатків до
								нього.
							</p>
							<p>
								{' '}
								7.2. Користувач зобов'язується самостійно знайомитися з
								інформацією про умови надання йому Послуг Виконавцем та їх
								вартості.
							</p>
							<p>
								7.3. Користувач має право вимагати від Виконавця надання йому
								Послуг відповідно до умов цього Договору.
							</p>
							<p>
								{' '}
								7.4. Користувач має право звертатися в службу технічної
								підтримки Виконавця, повідомляючи при цьому свій логін або
								контактні дані.
							</p>
							<p>
								7.5. Користувач зобов'язується використовувати Сервіси тільки в
								законних цілях, дотримуватися чинного законодавства України, а
								також права та законні інтереси Виконавця.
							</p>
							<p>
								{' '}
								7.6. Користувач зобов'язується не здійснювати дії, спрямовані на
								дестабілізацію роботи Сервісів, здійснення спроб
								несанкціонованого доступу до Сервісів, а також від здійснення
								будь-яких інших дій, що порушують права Виконавця та / або
								третіх осіб.
							</p>
							<p>
								7.7. Користувач не повинен порушувати, блокувати чи іншим чином
								завдавати шкоди будь-яким засобам безпеки Сайту.
							</p>
							<p>
								{' '}
								7.8. Користувач зобов'язується не використовувати Сервіси для
								вчинення дій, спрямованих на підрив мережної безпеки і порушення
								роботи будь-яких програмно-технічних засобів, підключених до
								мережі Інтернет, а також вчинення мережевих атак на будь-які
								ресурси, доступні через мережу Інтернет, включаючи
								програмно-технічні засоби Виконавця, але не обмежуючись ними.
							</p>
							<p>
								7.9. Користувач зобов'язується не здійснювати дій, спрямованих
								на отримання доступу до чужого Особовому профілем, шляхом
								підбору логіна і пароля, злому або інших дій.
							</p>
							<p>
								{' '}
								7.10. Користувача цим підтверджує, що він є повністю дієздатним
								(щодо фізичних осіб), правоздатним (щодо юридичних осіб), над
								ним не встановлено піклування в будь-якій формі.
							</p>
							<p>
								7.11. Користувач зобов'язується регулярно, але не рідше одного
								разу на тиждень, знайомитися на Сайті з інформацією, пов'язаною
								з наданням Послуг, включаючи із змінами і доповненнями до цього
								Договору, що публікуються в порядку, зазначеному в пункті 2.8 та
								в розділі 14 цього Договору. Мовчання і продовження користування
								Сервісом після повідомлення Виконавцем на Сайті про зміну
								поточних умов цього Договору та інших змін розглядається як
								згоду Користувача з внесеними змінами і доповненнями, якщо інше
								прямо не передбачено цим Договором. Всі ризики, пов'язані з
								настанням несприятливих наслідків, внаслідок недотримання
								Користувачем вимог цього пункту, несе Користувач.
							</p>
							<p>
								7.12. Користувач гарантує, що володіє всіма правами на
								використання матеріалів, що розміщуються ним при створенні
								Товарній позиції.
							</p>
							<p>
								{' '}
								7.13. Продавці не можуть розміщувати Товарні позиції, метою яких
								є:
								<br /> -залучення користувачів, а також інших відвідувачів Сайту
								на сторонні ресурси, сайти або реєстрація користувачів, а також
								інших відвідувачів Сайту на таких ресурсах, сайтах;
								<br /> -реклама своїх товарів, робіт і послуг, а також товарів,
								робіт і послуг, що належать третім особам;
								<br /> -накрутка або зміна статистики сайтів, числа
								передплатників в соціальних мережах; -замовлення автоматичної
								або ручної розсилки запрошень і повідомлень користувачам
								соціальних мереж, email-розсилок;
								<p></p>7.14. Користувачі не можуть розміщувати на Сайті Товарні
								позиції, які:
								<br /> - порушують чинне законодавство України;
								<br /> - містять спам, схеми фінансових "пірамід";
								<br /> - є незаконними, шкідливими, загрозливими, такими, що
								ображають моральність, наклепницькими, такими, що порушують
								авторські права або інші права інтелектуальної власності третіх
								осіб, пропагують ненависть і / або дискримінацію людей за
								расовою, етнічною, статевою, соціальною ознаками;
								<br /> - містять посилання на Інтернет-ресурси, що належать
								Користувачам або третім особам;
								<br /> - містять контактні дані Користувача або третіх осіб
								(номер телефону, акаунти соціальних мереж або сервісів миттєвих
								повідомлень);
								<br /> - порушують права третіх осіб;
								<br /> - не мають відношення до обраної категорії товарів.
							</p>
							<p>
								7.15. Покупець при покупці Товарній позиції за допомогою
								використання Сервісів Виконавця усвідомлює і підтверджує, що:
								<br /> - Виконавець і Продавці є абсолютно незалежними
								суб'єктами, що не надають вплив на діяльність один одного;
								<br /> - Виконавець не відповідає за відповідність законодавству
								України та очікуванням Покупця здійснюваної Продавцем діяльності
								з продажу Покупцеві товарних позицій;
								<br /> - Виконавець є тільки майданчиком, що розміщає відповідні
								Товарні позиції;
								<br /> - Виконавець не несе відповідальності за якість і терміни
								доставки товарних позицій;
								<br /> - Виконавець не несе відповідальності за виконання
								гарантійних зобов'язань щодо виконаних / наданих Продавцями
								робіт / послуг Покупцям;
								<br /> -Покупець повинен самостійно оформляти свої договірні
								відносини з вибраними ним Продавцями.
							</p>
							<p>
								{' '}
								7.16. Продавець усвідомлює і підтверджує, при покупці його
								товарних позицій Покупцем за допомогою використання наданих
								Виконавцем Послуг, що:
								<br /> - Виконавець і Покупець є абсолютно незалежними
								суб'єктами, що не надають вплив на діяльність один одного;
								<br /> - Виконавець не відповідає за виконання Покупцем
								договірних зобов'язань щодо Продавця, пов'язаних, в тому числі,
								з оплатою товарних позицій;
								<br /> - Виконавець є тільки майданчиком, що розміщає відповідні
								Товарні позиції;
								<br /> - Продавець повинен самостійно оформляти свої договірні
								відносини з Покупцями.
							</p>
							<p>
								{' '}
								17. Користувач має право направляти інформацію Адміністрації
								Сайту за фактами порушення іншими Користувачами своїх
								зобов'язань, що випливають з умов укладених між ними договорів з
								використанням наданих Виконавцем Послуг.
							</p>
							<p>
								18. Користувач може мати інші права та нести інші обов'язки,
								встановлені цим Договором.
							</p>
							<p>
								<strong>8.</strong> Вимоги до Контенту, розмежування
								відповідальності
							</p>
							<p>
								8.1. Публікація інформації (Контенту, в тому числі Торгових
								позицій) на Сайті повинна здійснюватися в повній відповідності з
								вимогами чинного законодавства України, положень цього Договору.
							</p>
							<p>
								8.2. Пропозиція Продавця про продаж товару (Товарна позиція),
								яке публікується їм на Сайті, має містити всю передбачену
								законодавством України (зокрема, але не виключно, Законом
								України &laquo;Про електронну комерцію&raquo;, Законом України
								&laquo;Про захист прав споживачів&raquo;) інформацію для
								споживачів, зокрема:
								<br />- найменування Продавця, його місцезнаходження та порядок
								прийняття претензії;
								<br />- основні характеристики товару / продукції (назва товару,
								дані про основні властивості продукції, номінальна кількість
								(масу, об'єм тощо) і т.д.);
								<br />- ціну (тариф), включаючи плату за доставку, та умови
								оплати;
								<br />- умови повернення або обміну товару, порядок розірвання
								договору;
								<br />- гарантійні зобов'язання та інші послуги, пов'язані з
								утриманням чи ремонтом продукції; <br />- умови і правила
								придбання продукції, інші умови поставки або виконання договору.
							</p>
							<p>
								Адміністрація / Власник Сайту не здійснює обов'язкову перевірку
								інформації і Контенту, яка розміщується Користувачами на Сайті і
								не несе відповідальності за відповідність такої інформації
								чинному законодавством України.
							</p>
							<p>
								Адміністрація / Власник Сайту не несе відповідальності за
								помилки, неточності, упущення, які були допущені при реєстрації
								або розміщенні інформації Користувачем, а також будь-які
								матеріальні або нематеріальні збитки, що виникли в зв'язку з цим
								(включаючи упущену вигоду).
							</p>
							<p>
								Адміністрація / Власник Сайту не несе відповідальності за
								використання (як правомірне, так і неправомірне) третіми особами
								інформації, розміщеної на Сайті, включаючи їх відтворення і
								поширення, здійснені як в рамках Сайту, так і іншими можливими
								способами.
							</p>
							<p>
								Надаючи Сайту Контент або розміщуючи Контент на Сайті та
								відгуках, Користувач погоджується з тим, що Сайт має право
								використовувати такий Контент на свій розсуд. У разі, якщо
								розміщення Контенту на Сайті і відкликання порушує права (в т.ч.
								права інтелектуальної власності) будь-яких третіх осіб,
								Користувач, який розмістив такий Контент зобов'язується
								самостійно усунути порушення і врегулювати всі суперечки і
								претензії з правовласниками. У разі отримання обґрунтованої
								скарги правовласника і бездіяльності Користувача в усуненні
								порушень, Адміністрація Сайту має право самостійно видалити
								інформацію, яка порушує права власника прав інтелектуальної
								власності.
							</p>
							<p>
								У разі виникнення в зв'язку з розміщенням Користувачем Контенту
								і / або будь-якої інформації, рекламних матеріалів, і / або
								змістом рекламних матеріалів (відповідністю змісту реклами
								вимогам чинного законодавства) та / або якістю товарів, які
								рекламуються, претензій, суперечок застосування штрафних санкцій
								з боку третіх осіб і / або контролюючих органів або за рішенням
								суду, такий Користувач зобов'язується розглядати і вирішувати ці
								питання самостійно без залучення Адміністрації Сайту, а також
								зобов'язується компенсувати Власнику Сайту в повному обсязі
								збитки, витрати, включаючи штрафні санкції, викликані порушенням
								ним умов цього Договору.
							</p>
							<p>
								8.8. Вся розміщена Користувачами на Сайті інформація вважається
								власністю користувачів, які її розмістили, до тих пір, поки не
								буде підстав вважати інакше. Всю відповідальність за
								відповідність такої інформації (Контенту) законодавству України
								несуть такі Користувачі. Користувачі Сайту не отримують ніяких
								прав на використання інформації, розміщеної іншими користувачами
								і несуть повну відповідальність перед власником інформації за її
								неправомірне використання.
							</p>
							<p>
								<strong>9.</strong> Вимоги до якості обслуговування Покупців
							</p>
							<p>
								9.1 . Всі Продавці зобов'язані сумлінно виконувати свої
								зобов'язання перед Покупцями, зокрема Продавці зобов'язуються:
								<br />- своєчасно, але не пізніше ніж протягом 24 годин,
								обробляти замовлення і звернення Покупців щодо товарів Продавця,
								інформація про яких розміщена на Сайті;
								<br />- своєчасно інформувати Покупців про відсутність
								замовленого товару або неможливість його доставки в узгоджені
								сторонами терміни з обов'язковим зазначенням причин і уточненням
								термінів виконання своїх зобов'язань перед Покупцем;
								<br />- своєчасно (не пізніше 7 днів) розглядати і давати
								мотивовану відповідь на скарги, претензії і пропозиції, що
								надходять від Покупців, пов'язані з діяльністю Продавця на
								Сайті. Оперативно взаємодіяти з Адміністрацією Сайту в таких
								випадках, в той же термін повідомляти Адміністрації Сайту про
								результати розгляду таких звернень, а також дотримуватися
								рекомендацій щодо поліпшення якості обслуговування, тощо.
								<br />- виконувати вимоги Закону України &laquo;Про захист прав
								споживачів&raquo; щодо прав покупців (споживачів) при придбанні
								ними товарів на Сайті, в тому числі встановлених статтями 12, 13
								цього Закону: &laquo;Продавці зобов'язані на вимогу Покупця
								протягом 14 (чотирнадцяти) календарних днів, не рахуючи дня
								покупки, обміняти непродовольчий товар належної якості на
								аналогічний, якщо товар не задовольнив споживача за формою,
								габаритами, фасоном, кольором, розміром або з інших причин не
								може бути ним використаний за призначенням, за умови що товар не
								використовувався і збережено його товарний вигляд, споживчі
								властивості, пломби, ярлики, а також підтвердження про покупку
								даного товару (номер замовлення на Сайті та інші документи, що
								підтверджують факт покупки). Якщо на момент обміну аналогічного
								товару, який би задовольняв вимоги споживача, немає в продажу,
								споживач має право або придбати будь-які інші товари з наявного
								асортименту з відповідним перерахуванням вартості, або розірвати
								договір та одержати назад сплачені грошові кошти за товар, або
								здійснити обмін товару на аналогічний при першому ж надходженні
								відповідного товару в продаж. Не підлягають обміну (поверненню)
								товари, зазначені у Переліку, затвердженому постановою Кабінету
								Міністрів України від 19 березня 1994 року No 172 Про реалізацію
								окремих положень Закону України "Про захист прав споживачів" -
								<a
									href='https://zakon.rada.gov.ua/laws/show/172-94-%2525D0%2525BF?_x_tr_sl=ru&_x_tr_tl=uk&_x_tr_hl=ru#Text'
									rel='noreferrer'
									target='_blank'
								>
									http://zakon4.rada.gov.ua/laws/show/172-94-%D0%BF
								</a>
								<br />- Покупець має право розірвати договір шляхом повідомлення
								Продавця про це протягом 14 (чотирнадцяти) днів з моменту
								підтвердження Продавцем замовлення або з моменту отримання
								товару. Повернення товару відбувається відповідно до ст. 13 ч. 5
								ст. 12 Закону України "Про захист прав споживачів". Дія цього
								пункту не поширюється на випадки (в тому числі, але не виключно)
								придбання на Сайті Покупцем товару, виготовленого на замовлення.
								<br />- розміщена Продавцем інформація на Сайті про товар (в
								т.ч. його опис та фото) повинна бути достовірною та повною.
								Інформація про наявність товару і ціною повинна бути актуальною
								постійно. Покупець має право вимагати, а Продавець зобов'язаний
								продати йому товар за ціною, вказаною Продавцем на Сайті
								відповідно до ст 18, 19 Закону України "Про захист прав
								споживачів".
							</p>
							<p>
								9.2. У разі порушення Продавцем викладених в цьому розділі
								Договору вимог, а також при наявності великої кількості
								неврегульованих Продавцем скарг від Покупців, частих скасування
								замовлень, відмову продати Покупцю товар за заявленою на Сайті
								ціною, збільшенні кількості негативних відгуків від Покупців і
								користувачів, Адміністрація Сайту залишає за собою право
								повністю або частково обмежити доступ до інформації про товари
								Продавця на Сайті до моменту усунення Продавцем порушень, або
								видалити розміщений Продавцем Контент або припинити Облікову
								запис Продавця і доступ до сервісів Сайту і / або відмовитися
								від надання послуг Продавцю і розірвати договір в
								односторонньому порядку, оскільки своїми діями Продавець завдає
								шкоди діловій репутації Сайту.
							</p>
							<p>
								<strong>10.</strong> Відповідальність, обмеження
								відповідальності
							</p>
							<p>
								10.1. Виконавець не несе відповідальність за будь-які помилки,
								упущення, переривання, дефекти і затримки в обробці або передачі
								даних, збої в лініях зв'язку, знищення будь-якого обладнання,
								неправомірний доступ третіх осіб до Сайту, що стали причиною
								обмеження доступу Користувача до Сервісів. Виконавець не
								відповідає за будь-які технічні збої або інші проблеми будь-яких
								телефонних мереж або служб, комп'ютерних систем, серверів або
								провайдерів, комп'ютерного або телефонного обладнання,
								програмного забезпечення, збоїв сервісів електронної пошти або
								скриптів з технічних причин, за нормальне функціонування і
								доступність окремих сегментів мережі Інтернет і мереж операторів
								електрозв'язку, задіяних при здійсненні доступу Користувача до
								Сервісів.
							</p>
							<p>
								10.2. Виконавець не відповідає за відповідність Сервісу цілком
								або його частин очікуванням Користувача, безпомилкову та
								безперебійну роботу Сервісу, припинення доступу Користувача до
								Сервісу, а також за збереження логіна і пароля Користувача, що
								забезпечують доступ до Сервісів, з причин, пов'язаних з
								технічними збоями апаратного або програмного забезпечення
								виконавця, і не відшкодовує Користувачу будь-які пов'язані з цим
								збитки.
							</p>
							<p>
								10.3. При порушенні Користувачами умов цього Договору Виконавець
								залишає за собою право обмежити доступ Користувача до Сервісів
								Виконавця на тимчасовій основі, а в разі грубого і / або
								неодноразового порушення умов цього Договору відмовити в доступі
								до Сервісів на постійній основі.
							</p>
							<p>
								10.4. Виконавець не несе відповідальності перед Користувачем за
								обмеження доступу до Сервісів, за припинення доступу до
								Сервісів, якщо ці обмеження і припинення виникли внаслідок
								обставин непереборної сили, які виникли після укладення цього
								Договору та на настання яких Сторони не могли вплинути,
								включаючи, але не обмежуючись, наступним: війна, заколоти,
								страйки, саботаж, ембарго, пожежі, повені, стихійні лиха,
								погіршення радіоелектронної або радіологічної обстановки,
								вибухи, дії або бездіяльність уряду України або іншої країни,
								акти державних органів і / або органів місцевого самоврядування,
								внесення змін в законодавство України, аварії на мережах
								загального користування, зміни умов доступу до лінійно-кабельних
								спорудах зв'язку.
							</p>
							<p>
								10.5. Виконавець не несе відповідальність перед Користувачем або
								будь-якими третіми особами за будь-які прямі та / або непрямі
								збитки, включаючи упущену вигоду або втрачені дані, шкоду честі,
								гідності або діловій репутації, понесені у зв'язку з
								використанням ним Сервісів, або неможливості його використання,
								або несанкціонованого доступу до комунікацій Користувача третіх
								осіб.
							</p>
							<p>
								10.6. Виконавець не несе відповідальності за будь-які збитки
								електронним пристроям Користувача або іншої особи, будь-якого
								іншого обладнання або програмного забезпечення, викликаний або
								пов'язаний з використанням Користувачем Сервісів.
							</p>
							<p>
								10.7. Виконавець не несе відповідальності перед Користувачем або
								будь-якими третіми особами за:
								<br />- зміст і законність, достовірність інформації,
								використовуваної / одержуваної Користувачем при використанні
								Сервісів;
								<br />- якість товарних позицій, проданих Продавцем Покупцеві,
								доставку їх в строк;
								<br />- виконання гарантійних зобов'язань перед Покупцями щодо
								куплених товарних позицій Продавців;
								<br />- відповідність здійснюваної Продавцями діяльності з
								продажу товарних позицій Покупцям законодавству України;
								<br />- виконання Покупцями своїх зобов'язань по оплаті куплених
								ними у Продавців товарних позицій.
							</p>
							<p>
								У разі пред'явлення третіми особами, в тому числі іншим
								Користувачем, претензій до Виконавця, пов'язаних з використанням
								Користувачем Сервісів Виконавця, Користувач зобов'язується
								своїми силами і за свій рахунок урегулювати розміщення в ній
								інформації з третіми особами, захистивши Виконавця від можливих
								збитків і розглядів, або виступити на стороні виконавця в таких
								розглядах, а також відшкодувати збитки виконавця (включаючи
								судові витрати), завдані їй у зв'язку з претензіями і позовами,
								пов'язаними з розміщенням матеріалів і / або діяльністю
								Користувача на Сайті.
							</p>
							<p>
								Виконавець не нестиме відповідальності за будь-які витрати або
								збитки, що прямо або побічно виникли у Покупців або Продавців в
								результаті продажу Продавцями товарних позицій Покупцям.
								Виконавець за жодних обставин не несе відповідальності перед
								Покупцями, Продавцями або третіми особами, за будь-які непрямі
								збитки, в тому числі упущену вигоду. Відповідальність Виконавця
								обмежується прямими документально підтвердженими збитками, що
								наступили в результаті дії або бездіяльності Виконавця в
								розмірі, що не перевищує вартість наданих Послуг окремо взятому
								Користувачеві.
							</p>
							<p>
								Виконавець не контролює якість і терміни поставки Продавцями
								товарних позицій, запропонованих ними за допомогою розміщення
								відповідної інформації на Сайті. Внаслідок цього Користувач
								приймає умову, відповідно до якого всі Товарні позиції,
								запропоновані через Сайт Продавцями, надаються ними під свою
								самостійну відповідальність і продаж даних товарних позицій ніяк
								не пов'язана з діяльністю Виконавця. Покупець бере на себе повну
								відповідальність і ризики, пов'язані з купівлею товарних
								позицій, запропонованих Продавцями за допомогою розміщення
								відповідної інформації на Сайті. Продавець приймає на себе повну
								відповідальність і ризики за оплату товарних позицій.
							</p>
							<p>
								Адміністрація Сайту залишає за собою право видаляти або
								блокувати доступ до інформації, розміщеної Користувачем без
								попередження, в разі:
								<br />- отримання обов'язкових до виконання рішень компетентних
								державних органів України;
								<br />- вимог власників прав інтелектуальної власності, в т.ч.,
								але не обмежуючись, авторських або суміжних прав, прав на знаки
								для товарів і послуг і т.д., про припинення порушень його прав
								Користувачем на Сайті;
								<br />- іншого порушення прав або законних інтересів інших
								користувачів Сайту, юридичних і фізичних осіб за їх мотивованим
								зверненням;
								<br />- виявлення інформації, яка заборонена до розміщення на
								Сайті або не відповідає Правил надання сервісів у відповідності
								до цього Договору.
							</p>
							<p>
								<strong>11.</strong> Інтелектуальна власність
							</p>
							<p>
								11.1. Сукупність програм, даних, торгових марок, об'єктів
								авторського права, і інших об'єктів, що використовуються на
								Сайті, є інтелектуальною власністю його законних правовласників
								і охороняється законодавством про інтелектуальну власність
								України, а також відповідними міжнародними правовими договорами
								і конвенціями. Будь-яке використання входить до складу Сервісу
								елементів, символіки, текстів, графічних зображень, програм та
								інших об'єктів, крім дозволеного в цьому Договорі, без дозволу
								Виконавця чи іншого законного правовласника є незаконним і може
								послужити причиною для судового розгляду і притягнення
								порушників до цивільно-правової, адміністративної та
								кримінальної відповідальності відповідно до законодавства
								України.
							</p>
							<p>
								<strong>12.</strong> Листування Сторін. Сповіщення та
								повідомлення
							</p>
							<p>
								12.1. Робоча листування між Сторонами з приводу виконання цього
								Договору ведеться по електронній пошті, через адреси,
								зареєстровані за кожної із Сторін. Адресою електронної пошти,
								зареєстрованим за Виконавцем, вважається електронну адресу{' '}
								<a href='mailto:info.vyroby@gmail.com'>info.vyroby@gmail.com</a>{' '}
								Адресою електронної пошти, зареєстрованим за Користувачем,
								вважається електронна адреса, вказана Користувачем при
								реєстрації на Сайті.
							</p>
							<p>
								12.2. Сторони погоджуються, що всі повідомлення і листи,
								отримані на адреси електронної пошти, зареєстровані для кожної
								із Сторін в рамках наданих відповідно до цього Договору послуг,
								а також публікація змін та доповнень до цього Договору на Сайті
								вважаються доставленими адресату в належній формі.
							</p>
							<p>
								12.3. Сторони погодилися, що документи, пов'язані з виконанням
								цього Договору, передані з використанням електронної пошти,
								мають юридичну силу.
							</p>
							<p>
								{' '}
								12.4. Кожна із Сторін зобов'язується своєчасно перевіряти
								кореспонденцію, що надходить на адреси електронної пошти,
								зареєстровані для кожної із Сторін.
							</p>
							<p>
								<strong>13.</strong> Порядок вирішення спорів
							</p>
							<p>
								13.1. Користувач і Виконавець погоджуються, що всі спори і
								розбіжності, які можуть виникнути з цього Договору або в зв'язку
								з ним, будуть вирішуватися шляхом переговорів з обов'язковим
								дотриманням досудового порядку вирішення спорів. Претензії
								Користувача відносно наданого Виконавцем Сервісу приймаються до
								розгляду тільки за умови, якщо вони складені в письмовій формі і
								спрямовані на поштову адресу Виконавця протягом трьох місяців з
								дня надання Сервісу або відмови в його наданні.
							</p>
							<p>
								13.2. У разі, якщо згоди не буде досягнуто, всі суперечки з
								приводу виконання цього Договору вирішуються в судовому порядку
								відповідно до чинного законодавства України за місцем
								знаходження Виконавця.
							</p>
							<p>
								{' '}
								13.3. У разі виникнення суперечок між Покупцем і Продавцем,
								пов'язаних з продажем Продавцем товарних позицій Покупцеві або
								їх оплатою, дані спори вирішуються самостійно між Покупцем і
								Продавцем без залучення Виконавця.
							</p>
							<p>
								<strong>14.</strong> Порядок внесення змін і доповнень до
								Договору
							</p>
							<p>
								14.1. Зміни та / або доповнення до Договору вносяться в
								односторонньому порядку за рішенням Виконавця. Датою вступу в
								силу змін і / або доповнень до цього Договору є дата, визначена
								в них Виконавцем, але не раніше дати опублікування даних змін і
								/ або доповнень на Сайті.
							</p>
							<p>
								14.2. Текст змін та / або доповнень до Договору або його нова
								редакція доводиться Виконавцем до загального відома шляхом
								розміщення відповідної інформації на Сайті.
							</p>
							<p>
								{' '}
								14.3. У разі незгоди Користувача з внесеними змінами та / або
								доповненнями до цього Договору, Користувач має право розірвати
								цей Договір шляхом повідомлення Виконавця про відмову
								Користувача від подальшого використання Сервісів Виконавця, а
								також про незгоду з внесеними змінами та / або доповненнями або
								про неприєднання до нової редакції договору або про відмову
								дотримуватися його умови, при цьому погасивши борг на Балансі в
								Особистому профілі Користувача (якщо є).
							</p>
							<p>
								14.4. Сторони домовляються, що мовчання (відсутність письмових
								повідомлень про розірвання Договору або про незгоду з окремими
								положеннями Договору, в тому числі зі зміною тарифів), а також
								продовження використання Сервісів Виконавця, визнаються згодою і
								приєднанням боку до нової редакції Договору, до редакції
								Договору з урахуванням внесених змін і / або доповнень.
							</p>
							<p>
								<strong>15.</strong> Заключні положення
							</p>
							<p>
								15.1. Цей Договір і відносини між Виконавцем і Користувачем
								регулюються законодавством України. Питання, не врегульовані
								Договором, підлягають вирішенню відповідно до законодавства
								України
							</p>
							<p>
								{' '}
								15.2. Якщо з тих чи інших причин будь-які з умов цього Договору
								будуть визнані недійсними, це не впливає на дійсність або
								застосовність інших умов Договору.
							</p>
							<p>
								15.3. Цей Договір щодо кожного з користувачів вступає в силу з
								моменту вчинення Користувачем одного з найбільш ранніх дій,
								зазначених у пункті 2.4 цього Договору, і діє до моменту
								закінчення використання Користувачем Сервісів Виконавця.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OfferView;
