import './HowBuyView.css';
import { useEffect, useState } from 'react';
import search1 from '../../assets/search1.jpg';
import howSlider1 from '../../assets/howSlider1.jpg';
import productSlider1 from '../../assets/productSlider1.png';
import productSlider4 from '../../assets/productSlider4.jpg';
import howToBuy4 from '../../assets/howToBuy4.png';
import howToBuy3 from '../../assets/howToBuy3.jpg';
import howToBuy5 from '../../assets/howToBuy5.jpg';
import howToBuy6 from '../../assets/howToBuy6.png';
import howToBuy from '../../assets/howToBuy.jpg';
import howToBuy2 from '../../assets/howToBuy2.jpg';
import closeImg from '../../assets/closeImgBlack.svg';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from 'swiper/modules';
import Modal from '@mui/material/Modal';
import { useSelector } from 'react-redux';
import { setIsScrollSlider, setOldModalScrollPosition } from '../../store/homeSlice';
import { useDispatch } from 'react-redux';
import SeoBlock from '../../components/SeoBlock/SeoBlock';
import { useLocation } from 'react-router-dom';

function HowBuyView() {
    const seo = useSelector(state => state.homeSlice.seo);
    const scrollPositionBeforeView = useSelector(state => state.homeSlice.scrollPositionBeforeView);
    const [open, setOpen] = useState(false);
    const [zoomImgArr, setZoomImgArr] = useState([]);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const [isNotFirstRender, setIsNotFirstRender] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        setIsNotFirstRender(true)
        setTimeout(() => {
            window.scroll({top: scrollPositionBeforeView[location.pathname] ? scrollPositionBeforeView[location.pathname] : 0})
        }, 10)
        
        return () => {
            dispatch(setIsScrollSlider(false))
            dispatch(setOldModalScrollPosition(0))
        }
    }, [])

    const handleOpen = (imgArr) => {
        setZoomImgArr(imgArr)
        setOpen(true)
    };
    
    const handleClose = () => {
        setZoomImgArr([])
        setOpen(false)
    };

    return (
        <div className="how-buy-view">
            <SeoBlock title={seo.title_how_to_buy} description={seo.description_how_to_buy} keywords={seo.keywords_how_to_buy}/>

            <Modal
                open={open}
                onClose={handleClose}
            >
                <div className={`how-buy-view__modal-zoom-wrap ${isSafari ? 'how-buy-view__modal-zoom-wrap--is-safari' : ''}`}>
                    <Swiper
                        loop={true}
                        spaceBetween={10}
                        modules={[Pagination]}
                        pagination={{
                            clickable: true,
                        }}
                        className="how-buy-view__modal-zoom-swiper-wrap"
                    >
                        {
                            !!zoomImgArr?.length && zoomImgArr.map((img, i) => (
                                <SwiperSlide key={img + i}><img className='how-buy-view__modal-zoom-img' src={img} alt="Зображення"/></SwiperSlide>
                            ))
                        }
                    </Swiper>
                    <img className="how-buy-view__modal-zoom-btn-close-img" onClick={handleClose} src={closeImg} alt='img'/>
                </div>
            </Modal>

            <div className="how-buy-view--wrap">
                <div className="how-buy-view__title-wrap">
                    <h1 className="how-buy-view__title container">Як робити покупки на vyroby.com</h1>
                </div>

                <div className="how-buy-view__content container">
                    <div className="how-buy-view__content-card">
                        <div className="how-buy-view__content-card-text-wrap">
                            <h2 className="how-buy-view__content-card-text-title">Пошук товару</h2>
                            <div className="how-buy-view__content-card-text">Для пошуку виробів необхідно перейти у відповідну категорію товарів, що вказані у верхній панелі, та обрати потрібну підкатегорію.</div>
                        </div>
                        <img className="how-buy-view__content-card-img how-buy-view__content-card-img--1" onClick={() => handleOpen([search1])} src={search1} alt='img'/>
                    </div>
                    <div className="how-buy-view__content-card">
                        <div className="how-buy-view__content-card-text-wrap">
                            <div className="how-buy-view__content-card-text how-buy-view__content-card-text--1">Наприклад, в розділі “Ліжка” можна ознайомитися з усіма представленими ліжками, користуючись фільтром для більш детального вибору, та перейти в карточку товару, який вас зацікавив.</div>
                        </div>
                        <img className="how-buy-view__content-card-img" onClick={() => handleOpen([howToBuy])} src={howToBuy} alt='img'/>
                    </div>
                    <div className="how-buy-view__content-card">
                        <div className="how-buy-view__content-card-text-wrap">
                            <div className="how-buy-view__content-card-text how-buy-view__content-card-text--1">У карточці товару вказана  детальна інформація про виріб:</div>
                            <ul>
                                <li className="how-buy-view__content-card-text-li">фото</li>
                                <li className="how-buy-view__content-card-text-li">ціна</li>
                                <li className="how-buy-view__content-card-text-li">кнопка “ЗАДАТИ ПИТАННЯ” для зв’язку з продавцем</li>
                                <li className="how-buy-view__content-card-text-li">опис виробу</li>
                            </ul>
                        </div>
                        <img className="how-buy-view__content-card-img" onClick={() => handleOpen([howToBuy2])} src={howToBuy2} alt='img'/>
                    </div>
                </div>

                <div className="how-buy-view__product-wrap">
                    <div className="how-buy-view__product container">
                        <div className="how-buy-view__product-text-wrap">
                            <div className="how-buy-view__product-text-title">Інформація про товар</div>
                            <div className="how-buy-view__product-text">Інформація про розміри, матеріал та інші технічні характеристики виробу також вказані на сторінці товару. Якщо вас зацікавив виробник, ви можете перейти на його персональну сторінку й ознайомитися із його каталогом, компанією, умовами співпраці.</div>
                        </div>
                        <div className="how-buy-view__product-swiper-wrap">
                            <Swiper 
                                className="how-buy-view__product-swiper"
                                spaceBetween={30}
                                pagination={{
                                clickable: true,
                                }}
                                modules={[Pagination]}
                                speed={450}
                            >
                                <SwiperSlide className="how-buy-view__product-swiper-img-wrap"><img className="how-buy-view__product-swiper-img" onClick={() => handleOpen([howSlider1, productSlider4, productSlider1])} src={howSlider1} alt='img'/></SwiperSlide>
                                <SwiperSlide className="how-buy-view__product-swiper-img-wrap"><img className="how-buy-view__product-swiper-img" onClick={() => handleOpen([productSlider4, productSlider1, howSlider1])} src={productSlider4} alt='img'/></SwiperSlide>
                                <SwiperSlide className="how-buy-view__product-swiper-img-wrap"><img className="how-buy-view__product-swiper-img" onClick={() => handleOpen([productSlider1, howSlider1, productSlider4])} src={productSlider1} alt='img'/></SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>

                <div className="how-buy-view__content container how-buy-view__content--order">
                    <div className="how-buy-view__content-card">
                        <div className="how-buy-view__content-card-text-wrap">
                            <div className="how-buy-view__content-card-text-title">Зробити замовлення виробу</div>
                            <div className="how-buy-view__content-card-text">Оскільки маркетплейс є платформою, на якій продаються вироби різних компаній й умови продажів також можуть відрізнятися. Тому перед замовленням рекомендуємо ознайомитися з варіантами оплати та доставки виробника, а також уточнити у продавця умови повернення або обміну товару, терміни гарантійного обслуговування, якщо все це не вказано на сторінці товару.</div>
                        </div>
                        <img className="how-buy-view__content-card-img" onClick={() => handleOpen([howToBuy3])} src={howToBuy3} alt='img'/>
                    </div>
                    <div className="how-buy-view__content-card">
                        <div className="how-buy-view__content-card-text-wrap">
                            <div className="how-buy-view__content-card-text">Щоб оформити замовлення виробу, натисніть кнопку додати у кошик, після чого товар з'явиться в кошику.  З'явиться вікно оформлення замовлення, де необхідно заповнити всі поля!</div>
                        </div>
                        <img className="how-buy-view__content-card-img" onClick={() => handleOpen([howToBuy4])} src={howToBuy4} alt='img'/>
                    </div>
                    <div className="how-buy-view__content-card">
                        <div className="how-buy-view__content-card-text-wrap">
                            <div className="how-buy-view__content-card-text">Якщо у вас залишилися запитання до виробника, або ви хочете замовити виріб в індивідуальних розмірах чи кольорі, натисніть “ЗАДАТИ ПИТАННЯ” або “Адаптуй виріб під себе” для зв’язку з продавцем та заповніть форму зворотного зв’язку.</div>
                            <br/>
                            <div className="how-buy-view__content-card-text">Після оформлення замовлення чи заповнення форми з вами зв'яжеться виробник товару для уточнення й узгодження замовлення. Якщо ви оформили замовлення у різних виробників, кожен з них буде зв'язуватися з вами окремо.</div>
                        </div>
                        <img className="how-buy-view__content-card-img" onClick={() => handleOpen([howToBuy5])} src={howToBuy5} alt='img'/>
                    </div>
                </div>

                <div className="how-buy-view__product-wrap">
                    <div className="how-buy-view__product container">
                        <div className="how-buy-view__product-text-wrap">
                            <div className="how-buy-view__product-text-title">Відгуки</div>
                            <div className="how-buy-view__product-text">Ми будемо вдячні вам за відгук про товар і роботу продавця після покупки. Відгук можна залишити на персональній сторінці виробника. Ваша думка щодо якості товару, сервісу виробника, допоможе іншим покупцям зробити правильний вибір!</div>
                            <br/>
                            <div className="how-buy-view__product-text">Відгуки створять рейтинг виробників й стимулюватимуть їх покращувати свій сервіс та якість товару. А нашій платформі ваш відгук допоможе регулювати та поліпшити роботу продавців.</div>
                        </div>
                        <img className="how-buy-view__product-img how-buy-view__content-card-img--2" onClick={() => handleOpen([howToBuy6])} src={howToBuy6} alt='img'/>
                    </div>
                </div>
                
                <div className="how-buy-view__info-wrap container">
                    <div className="how-buy-view__info">
                        <div className="how-buy-view__info-title">Монтаж та установка виробів</div>
                        <div className="how-buy-view__info-text">Якщо ви замовили виріб під індивідуальне виготовлення, і монтаж та установку проводить виробник, то обов'язково прийміть та перевірте роботу одразу після установки в присутності представника виробника, відповідно до договору підписаного з виконавцем.</div>
                    </div>
                    <div className="how-buy-view__info">
                        <div className="how-buy-view__info-title">Оплата</div>
                        <div className="how-buy-view__info-text">VYROBY – маркетплейс, на якому представлені різні виробники (продавці) меблів, тому варіанти оплати та доставки будуть відрізнятися в залежності від умов продавця.
                            <br/>
                            <br/>
                            Рекомендуємо підписувати договір з виробником, якщо товар буде виготовлятися під індивідуальне замовлення, для того, щоб ви отримали виріб належних характеристик, хорошої якості та в зазначений термін.
                            <br/>
                            <br/>
                            Клієнт здійснює оплату безпосередньо виробнику одним із запропонованих ним способів. Найчастіше використовуються: передплата, оплата готівкою при отриманні, післяплата, оплата банківською картою.
                        </div>
                    </div>
                    <div className="how-buy-view__info">
                        <div className="how-buy-view__info-title">Доставка</div>
                        <div className="how-buy-view__info-text">Доставка та встановлення здійснюється виробником, у якого ви купуєте товар. Використовуються такі варіанти доставки: поштова або кур'єрська служба, доставка безпосередньо виробником.</div>
                    </div>
                    <div className="how-buy-view__info">
                        <div className="how-buy-view__info-title">Отримання товару та його встановлення</div>
                        <div className="how-buy-view__info-text">При отриманні товару обов'язково перевіряйте товар на якість та відповідність замовлення безпосередньо в поштовому відділенні, або в присутності кур'єра, або в присутності представника виробника, в залежності від варіанту доставки.
                            <br/>
                            <br/>
                            Якщо товар пошкоджений, у присутності співробітника служби доставки складіть акт про пошкодження.
                            <br/>
                            <br/>
                            Якщо недоліки товару були виявлені пізніше, ви маєте право звернутися до продавця згідно з законом України «Про захист прав споживачів» щодо усунення недоліків, обміну чи повернення товару.
                            <br/>
                            <br/>
                            Обов'язково зберігайте документи, що підтверджують покупку, вони необхідні для гарантійного обслуговування та для підтвердження факту угоди між вами й продавцем, у разі виникнення проблемних ситуацій.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HowBuyView;