import './PreloaderProductCustom2.css';

function PreloaderProductCustom2() {
    return (
        <div id='preloaderProductId' className="preloader-product-custom-wrap">
            <div className="">
            </div>
        </div>
    );
}

export default PreloaderProductCustom2;