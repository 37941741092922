import './OrderStatus.css';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import threedots from '../../assets/threedots.svg';
import threedotsHover from '../../assets/threedotsHover.svg';
import { fetchRequest } from '../../helpers/Utils';
import { updateOrders, getOrders } from '../../store/userSlice';
import { Button, Menu, MenuItem } from '@mui/material';
import { orderStatusArr } from '../../helpers/Config';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

function OrderStatus({order, str=null}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const orderStatusSearchParams = JSON.parse(searchParams.get('orderStatus'))

    const orders = useSelector(state => state.userSlice.orders);
    const [status, setStatus] = useState(order.status);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)
    const dispatch = useDispatch();
    let token = localStorage.getItem('token')

    useEffect(() => {
        if (status?.length && status !== order.status) {
            let data = {
                ...order,
                status: status,
            }
            fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/orders/${order._id}?token=${token}`, data)
                .then(res => {
                    if (res.success && res.data) {
                        dispatch(updateOrders(data))
                        if (orderStatusSearchParams?.length) {
                            dispatch(getOrders([...orders.filter(el => el._id !== data._id)]))
                        }

                        setAnchorEl(null);
                    } else {
                        console.log('POST OrderStatus:', res)
                    }
                })
        }

        window.addEventListener('scroll', handleScroll, { passive: true });
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, [status])

    const handleScroll = () => {
        setAnchorEl(null)
    };

    const handleClickSettings = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    return (
        <>
            <Button
                id={`basic-button-${order._id}`}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickSettings}
                className='order-status__btn-wrap'
                disableRipple={true}
            >
                <span className={`order-status__btn-text ${str?.length ? 'order-status__btn-text--str' : ''}`}>
                    {
                        str?.length ?
                            <div className='order-status__btn-title-wrap'>
                                <div className='order-status__btn-title'>Статус:</div>
                                <div className={`user-order__order-text order-status__btn order-status__order-status user-order__order--${status}`}>{orderStatusArr[`${status}`]}</div>
                            </div>
                            :
                            <>
                                <img className='order-status__btn-img-hover' src={threedotsHover} alt='img'/>
                                <img className='order-status__btn-img' src={threedots} alt='img'/>
                            </>
                    }
                </span>
            </Button>
            <Menu
                disableRipple={true}
                id={`basic-menu-${order._id}`}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                className='order-status__btn-select'
                MenuListProps={{
                'aria-labelledby': `basic-button-${order._id}`,
                }}
            >
                <MenuItem disableRipple={true} onClick={() => setStatus('pending')} >В очікуванні</MenuItem>
                <MenuItem disableRipple={true} onClick={() => setStatus('calculation')}>Переговори</MenuItem>
                <MenuItem disableRipple={true} onClick={() => setStatus('in_progress')}>В роботі</MenuItem>
                <MenuItem disableRipple={true} onClick={() => setStatus('completed')}>Виконано</MenuItem>
                <MenuItem disableRipple={true} onClick={() => setStatus('cancelled')}>Скасовано</MenuItem>
            </Menu>
        </>
    );
}

export default OrderStatus;