import './CollectionsView.css';
import { useEffect, useState } from 'react';
import MainBanner from '../../components/MainBanner/MainBanner';
import { NavLink, useLocation } from 'react-router-dom';
import { fetchGetData } from '../../helpers/Utils';
import { useSelector } from 'react-redux';
import SeoBlock from '../../components/SeoBlock/SeoBlock';
import PreloaderProductCustom2 from '../../components/PreloaderProductCustom2/PreloaderProductCustom2';

function CollectionsView() {
	const seo = useSelector((state) => state.homeSlice.seo);
	const scrollPositionBeforeView = useSelector(
		(state) => state.homeSlice.scrollPositionBeforeView
	);
	const [isPreloader, setIsPreloader] = useState(true);
	const [collections, setCollections] = useState([]);
	const location = useLocation();

	useEffect(() => {
		setTimeout(() => {
			window.scroll({
				top: scrollPositionBeforeView[location.pathname]
					? scrollPositionBeforeView[location.pathname]
					: 0,
			});
		}, 10);

		fetchGetData(`${process.env.REACT_APP_BASE_URL}/collections/all`).then(
			(res) => {
				if (res.success && res.data) {
					setCollections(res.data);
				} else {
					console.log('GET UserCollectionView', res);
				}
				setIsPreloader(false);
			}
		);
	}, []);

	const handleCollectionsNavigate = (obj) => {
		return `/catalog/collections/${obj.alias}`;
	};

	return (
		<div className='collections-view'>
			<SeoBlock
				title={seo.title_collections}
				description={seo.description_collections}
				keywords={seo.keywords_collections}
			/>
			<div className='collections-view-wrap container'>
				{isPreloader && <PreloaderProductCustom2 />}
				<h1 className='collections-view__title'>
					Колекції корпусних меблів для дому
				</h1>
				<div className='collections-view__collections'>
					{!!collections?.length &&
						collections.map((el) => (
							<NavLink
								className='collections-view__collection'
								to={handleCollectionsNavigate(el)}
								key={el._id}
							>
								<div>
									<img
										className='collections-view__collection-img'
										src={el.image}
										alt='img'
									/>
									<div className='collections-view__collection-title'>
										{el.title}
									</div>
								</div>
								<div>
									<button className='main-btn-1'>Переглянути колекцію</button>
								</div>
							</NavLink>
						))}
				</div>
				<div className='collections-view__banner-wrap'>
					<MainBanner />
				</div>
			</div>
		</div>
	);
}

export default CollectionsView;
