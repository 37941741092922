import './UserVendorsView.css';
import { useEffect, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import noPhotos from './../../assets/noPhotos.svg';
import manIcon from './../../assets/manIcon.svg';
import filterProductsImg from './../../assets/filterProductsImg.svg';
import search3 from './../../assets/search3.svg';
import closeImgBlack from './../../assets/closeImgBlack.svg';
import plus from './../../assets/plus.svg';
import { fetchGetData, fetchRequest, handleFormattingDate, handleGetDocName } from '../../helpers/Utils';
import { setShowMessageObj, setEditeVendor, updateVendors } from '../../store/userSlice';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import MainButton from '../../components/MainButton/MainButton';
import ContextMenuVendor from '../../components/ContextMenuVendor/ContextMenuVendor';
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import SeoBlock from '../../components/SeoBlock/SeoBlock';

function UserVendorsView() {
    const [searchParams, setSearchParams] = useSearchParams();
    const newSearchParams = new URLSearchParams(searchParams);
    const sortProductsSearchParams = searchParams.get('sortProducts')
    const searchVendorNameSearchParams = searchParams.get('searchVendorName')

    const [isModalDelete, setIsModalDelete] = useState(false);
    const [isModalHide, setIsModalHide] = useState(false);
    const [hideVendor, setHideVendor] = useState({});
    const [vendors, setVendors] = useState([]);
    const [deleteVendorId, setDeleteVendorId] = useState('');
    const [isPreloader, setIsPreloader] = useState(true);
    const [isDownloadProducts, setIsDownloadProducts] = useState(false);
    const [isUploudProducts, setIsUploudProducts] = useState(false);
    const [isDownloadDoc, setIsDownloadDoc] = useState(false);
    const [downloadVendorId, setDownloadVendorId] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const [uploudVendorId, setUploudVendorId] = useState('');
    const [fileDoc, setFileDoc] = useState({});
    const [searchVendorName, setSearchVendorName] = useState(searchVendorNameSearchParams?.length ? searchVendorNameSearchParams : '');
    const [sortProducts, setSortProducts] = useState(sortProductsSearchParams?.length ? sortProductsSearchParams : '');
    const [downloadVendorDoc, setDownloadVendorDoc] = useState([]);
    const [anchorElSort, setAnchorElSort] = useState(null);
    const [isNotFirstRender, setIsNotFirstRender] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let token = localStorage.getItem('token')

    useEffect(() => {
        setIsNotFirstRender(true)

        handleFilterVendors()
        
        window.addEventListener('scroll', handleScroll, { passive: true });
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    const handleScroll = () => {
        setAnchorElSort(null)
    };

    useEffect(() => {
        if (isNotFirstRender) {
            handleFilterVendors()
        }
    }, [location])
    
    useEffect(() => {
        if (!searchVendorName?.length && isNotFirstRender) {
            setSearchVendorName('')
            newSearchParams.set('searchVendorName', '');
            setSearchParams(newSearchParams);
        }
    }, [searchVendorName])

    const handleFilterVendors = () => {
        setIsPreloader(true)
        fetchGetData(`${process.env.REACT_APP_BASE_URL}/vendors/all?company_name=${searchVendorNameSearchParams?.length ? searchVendorNameSearchParams : searchVendorName}&sort_products=${sortProductsSearchParams?.length ? sortProductsSearchParams : ''}`)
            .then(res => {
                if (res.success && res.data) {
                    setVendors(res.data)
                } else {
                    console.log('GET UserVendorsView', res)
                }
                setIsPreloader(false)
            })
    }

    const handleDeleteVendor = (id) => {
        setIsModalDelete(true)
        setDeleteVendorId(id)
    }
    
    const handleHideVendor = (obj) => {
        setIsModalHide(true)
        setHideVendor(obj)
    }

    const handleIsDeleteVendor = (boolean) => {
        if (boolean) {
            setIsPreloader(true)
            fetchRequest('DELETE', `${process.env.REACT_APP_BASE_URL}/vendors/${deleteVendorId}?token=${token}`, {}, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
                .then(res => {
                    if (res.success) {
                        dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                    } else {
                        console.log('DELETE UserVendorsView:', res)
                    }
                    setIsPreloader(false)
                    setDeleteVendorId('')
                    setIsModalDelete(false)
                    handleFilterVendors()
                })
        } 
    }
    
    const handleEditeVendor = (obj) => {
        dispatch(setEditeVendor(obj))
    }
    
    const handleIsHideVendor = (boolean) => {
        if (boolean) {
            setIsPreloader(true)
            let data = {
                ...hideVendor,
                is_blocked: !hideVendor.is_blocked
            }
            fetchRequest('PUT', `${process.env.REACT_APP_BASE_URL}/vendors/hide/${hideVendor._id}?token=${token}`, data, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
                .then(res => {
                    if (res.success && res.data) {
                            dispatch(updateVendors(res.data))
                            handleFilterVendors()
                            dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                    } else {
                        console.log('PUT UserVendorsView:', res)
                    }
                    setIsPreloader(false)
                })
        } 

        setIsModalHide(false)
        setHideVendor({})
    }

    const handleDownloadProducts = (id) => {
        setIsDownloadProducts(true)
        setDownloadVendorId(id)
    }
    
    const handleDownloadVendorDoc = (arr) => {
        setIsDownloadDoc(true)
        setDownloadVendorDoc(arr)
    }

    const handleIsDownloadDoc = (boolean) => {
        if (boolean) {
        } else {
            deleteExcel()
        }
    }

    const handleIsDownloadProducts = (boolean) => {
        if (boolean) {
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/products/csv?vendor_id=${downloadVendorId}`)
                .then(res => {
                    if (res.success && res.data) {
                        setFileUrl(res.data)
                    } else {
                        console.log('GET UserProductsViews', res)
                    }
                })
        } else {
            deleteExcel()
        }
    }

    const deleteExcel = () => {
        setFileUrl('')
        setIsDownloadProducts(false)
        setIsDownloadDoc(false)
        setDownloadVendorId('')
        setDownloadVendorDoc([])
    };

    const handleClickMenu = (obj, str) => {
        if (str === 'hide') {
            handleHideVendor(obj)
            return
        }
        if (str === 'delete') {
            handleDeleteVendor(obj._id)
            return
        }
        if (str === 'edite') {
            handleEditeVendor(obj)
            navigate(`/auth/vendors/create/${obj._id}`)
            return
        }
        if (str === 'uploudProduct') {
            handleUploudFile(obj._id)
            return
        }
        if (str === 'saveProduct') {
            handleDownloadProducts(obj._id)
            return
        }
        if (str === 'docs') {
            handleDownloadVendorDoc(obj.vendor_docs)
            return
        }
    }

    const handleUploudFile = (id) => {
        setIsUploudProducts(true)
        setUploudVendorId(id)
    }

    const handleDeleteSearch = () => {
        setSearchVendorName('')
        newSearchParams.set('searchVendorName', '');
        setSearchParams(newSearchParams);
    }

    const handleKeyDown = (e) => {
        if(e.key == 'Enter') {
            setSearchVendorName(searchVendorName)
            newSearchParams.set('searchVendorName', searchVendorName);
            setSearchParams(newSearchParams);
        }
    }
    
    const handleSearchByName = () => {
        setSearchVendorName(searchVendorName)
        newSearchParams.set('searchVendorName', searchVendorName);
        setSearchParams(newSearchParams);
    }

    const handleSortByProducts = (str) => {
        setSortProducts(str)
        setAnchorElSort(null)
        newSearchParams.set('sortProducts', str);
        setSearchParams(newSearchParams);
    }
    
    const handleIsUploudProducts = (boolean) => {
        if (boolean && fileDoc?.name?.length) {
            let formData = new FormData();
            formData.append('file', fileDoc);
            formData.append('vendor_id', uploudVendorId);
            fetch(`${process.env.REACT_APP_BASE_URL}/products/import?token=${token}`, {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                    } else {
                        console.log('POST UserVendorsView:', res)
                    }
                    setIsPreloader(false)
                })
        } 
        setIsUploudProducts(false)
        setUploudVendorId('')
        setFileDoc({})
    }
      
    return (
        <div className='user-vendors'>
            <SeoBlock title={"Виробники"} description={""} keywords={""}/>
            {
                isModalHide && <ModalWindow title={hideVendor.is_blocked ? 'Відновити виробника?' : 'Приховати даного виробника?'} handleClick={handleIsHideVendor} />
            }
            {
                isModalDelete && <ModalWindow title={'Видалити даного виробника?'} handleClick={handleIsDeleteVendor} />
            }
            {
                isDownloadProducts && <ModalWindow title={'Зберегти всі товари виробника?'} handleClick={handleIsDownloadProducts} notBtn={!!fileUrl?.length ? true : false}>
                    {!!fileUrl?.length && (
                        <a className='user-vendors__btn-download-link-btn' onClick={deleteExcel} href={fileUrl} download>
                            Download File
                        </a>
                    )}
                </ModalWindow>
            }
            {
                isDownloadDoc && <ModalWindow title={'Зберегти документи виробника?'} handleClick={handleIsDownloadDoc} notBtn={true}>
                    <div className='user-vendors__btn-download-link-wrap'>
                        <div>Для скачування нажміть на силку</div>
                        {
                            !!downloadVendorDoc?.length && downloadVendorDoc.map(el => (
                                <a className='user-vendors__btn-download-link user-vendors__btn-download-link-overflow' onClick={deleteExcel} href={el} download key={el}>
                                    {handleGetDocName(el)}
                                </a>
                            ))
                        }
                    </div>
                </ModalWindow>
            }
            {
                isUploudProducts && <ModalWindow title={'Виберіть файл для завантаження'} handleClick={handleIsUploudProducts} >
                    <label>
                        <MainButton text={'Виберіть файл'} typeBtn={2}/>
                        <input className="user-vendors__modl-uploud-input" onChange={(e) => setFileDoc(e.target.files[0])} type="file" accept=".csv" />
                        {
                            !!fileDoc?.name?.length && <div className="user-vendors__modl-uploud-result">{fileDoc?.name}</div>
                        }
                    </label>
                </ModalWindow>
            }

            <div className={`user-vendors__header`}>
                <div className="user-vendors__header-title-wrap">
                    <img className="user-vendors__header-title-img" src={manIcon} alt='img'/>
                    <h1 className="user-vendors__header-title">Виробники</h1>
                </div>
                <div className="user-vendors__header-btn-wrap">
                    <NavLink className="user-vendors__header-btn" to={'/auth/vendors/create'}>
                        <img className="user-vendors__header-btn-img" src={plus} alt='img'/>
                        <span>Додати виробника</span>
                    </NavLink>
                </div>
            </div>

            {
                isPreloader ? <PreloaderCustom /> : 
                    <>
                        <div className="user-vendors__filter--wrap">
                            <div className="user-vendors__filter-wrap">
                                <p className="user-vendors__filter-lable">Сортування</p>
                                <Button
                                    aria-haspopup="true"
                                    onClick={(e) => setAnchorElSort(e.currentTarget)}
                                    className='user-vendors__filter-btn--wrap'
                                    disableRipple={true}
                                    >
                                    <div className='user-vendors__filter-btn-wrap'>
                                        <img className='user-vendors__filter-btn-img' src={filterProductsImg} alt='img'/>
                                        <p className='user-vendors__filter-btn-text'>{sortProducts === "1" ? 'По зростанню товарів' : sortProducts === "-1" ? "По спаданню товарів" : 'Без сортування'}</p>
                                    </div>
                                </Button>
                                <Menu
                                    anchorEl={anchorElSort}
                                    open={Boolean(anchorElSort)}
                                    onClose={() => setAnchorElSort(null)}
                                    disableRipple={true}
                                >
                                    <MenuItem className='user-vendors__filter-menu-text-item' onClick={() => handleSortByProducts('')} disableRipple={true}>Без сортування</MenuItem>
                                    <MenuItem className='user-vendors__filter-menu-text-item' onClick={() => handleSortByProducts('1')} disableRipple={true}>По зростанню товарів</MenuItem>
                                    <MenuItem className='user-vendors__filter-menu-text-item' onClick={() => handleSortByProducts('-1')} disableRipple={true}>По спаданню товарів</MenuItem>
                                </Menu>
                            </div>
                            <div className='user-vendors2__search-wrap'>
                                <p className="user-vendors__filter-lable">Пошук</p>
                                <div className='user-vendors2__input-wrap'>
                                    <input className='user-vendors2__input user-vendors2__search-input' value={searchVendorName} onChange={(e) => setSearchVendorName(e.target.value)} onKeyDown={(e) => handleKeyDown(e)} placeholder='Введіть назву' type='text' />
                                    <img className='user-vendors2__search-input-img' onClick={handleSearchByName} src={search3} alt='img'/> 
                                    {
                                        !!searchVendorName?.length &&
                                            <img className='layout-user__header-search-btn-close' onClick={() => handleDeleteSearch()} src={closeImgBlack} alt='img'/>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="user-vendors__products-header-wrap">
                            <div className="user-vendors__products-header-vendor">Виробник</div>
                            <div className="user-vendors__products-header-product">Кількість товарів</div>
                            <div className="user-vendors__products-header-date">Дата створення</div>
                        </div>

                        <div className="user-vendors__vendors-wrap">
                            {
                                !!vendors?.length ? vendors.map(el => (
                                    <div className='user-vendors__vendor' key={el._id}>
                                        <div className='user-vendors__vendor--wrap'>
                                            <div className={`user-vendors__vendor-wrap ${el.is_blocked ? 'user-vendors__vendor--is-blocked' : ''}`}>
                                                <div className='user-vendors__vendor-img--wrap'>
                                                    <div className='user-vendors__vendor-img-wrap'>
                                                        {
                                                            !!el.logo_image?.length ? 
                                                                <img className="user-vendors__vendor-img" src={el.logo_image} alt='img'/>
                                                                :
                                                                <img className="user-vendors__vendor-img" src={noPhotos} alt='img'/>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='user-vendors__vendor-text-wrap'>
                                                    <NavLink className="user-vendors__vendor-name" onClick={() => handleEditeVendor(el)} to={`/auth/vendors/create/${el._id}`}>{el.company_name}</NavLink>
                                                    <div className='user-vendors__vendor-products-wrap'>
                                                        <div className="user-vendors__vendor-text-title">Кількість товарів:</div>
                                                        <div className="user-vendors__vendor-text">{el.count_products}</div>
                                                    </div>
                                                    <div className='user-vendors__vendor-date-wrap'>
                                                        <div className="user-vendors__vendor-text-title">Дата:</div>
                                                        <div className="user-vendors__vendor-text">{handleFormattingDate(el.created_at)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <ContextMenuVendor vendor={el}  handleClickMenu={handleClickMenu}/>
                                        </div>
                                    </div>
                                ))
                                :
                                <div className='user-order__order-error'>Список виробників пустий</div>
                            }
                        </div>          
                    </>
                }
        </div>
    );
}

export default UserVendorsView;