import './BannerResult.css';
import { useEffect, useState } from 'react';
import deleteImg2 from '../../assets/deleteImg2.svg';
import { useDispatch } from 'react-redux';
import { setShowMessageObj } from '../../store/userSlice';
import { fetchRequest } from '../../helpers/Utils';

function BannerResult({ obj, setBannersFile, bannersFile, setBanners, banners}) {
    const [banner_text, setBanner_text] = useState(obj.text);
    const [banner_link, setBanner_link] = useState(obj.link);
    const dispatch = useDispatch();
    let token = localStorage.getItem('token')

    useEffect(() => {
        if (obj?.file?.name?.length) {
            let res = [...bannersFile.map(el => {
                if (el.file?.name === obj.file.name) {
                    el = {...el, text: banner_text, link: banner_link}
                    return el
                }
                return el
            })]
            setBannersFile([...res])
        } else {
            let res = [...banners.map(el => {
                if (el._id === obj._id) {
                    el = {...el, text: banner_text, link: banner_link}
                    return el
                }
                return el
            })]
            setBanners([...res])
        }
    }, [banner_text, banner_link ])

    const handleDeleteImg = (obj) => {
        fetchRequest('DELETE', `${process.env.REACT_APP_BASE_URL}/banners/${obj._id}?token=${token}`, {}, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
            .then(res => {
                if (res?.success) {
                    setBanners([...banners.filter(el => el._id !== obj._id)])
                    dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                } else {
                    console.log('DELETE BannerResult:', res)
                }
            })
    }
    
    const handleDeleteUploadImg = (name) => {
        setBannersFile([...bannersFile.filter(el => el.file.name !== name)])
    }

    return (
        <div className='banner-result'>
            <textarea className='creating-category__textarea' value={banner_text} onChange={(e) => setBanner_text(e.target.value)} placeholder='Додати текст на банер' rows={3} type='text' />
            <input className='creating-category__input' value={banner_link} onChange={(e) => setBanner_link(e.target.value)} placeholder='Додати посилання' rows={1} type='text' />
            {
                obj?.file?.name?.length ?
                    <div className='banner-result__result-wrap'>
                        <button className='banner-result__result-del-btn' onClick={() => handleDeleteUploadImg(obj.file.name)}><img src={deleteImg2} alt='img'/></button>
                        <img className='banner-result__img-result' src={URL.createObjectURL(obj.file)} alt='img'/>
                    </div>
                    :
                    <div className='banner-result__result-wrap'>
                        <button className='banner-result__result-del-btn' onClick={() => handleDeleteImg(obj)}><img src={deleteImg2} alt='img'/></button>
                        <img className='banner-result__img-result' src={obj.image} alt='img'/>
                    </div>
            }
        </div> 
    );
}

export default BannerResult;