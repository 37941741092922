import './MainBanner.css';
import { memo, useEffect, useRef, useState } from 'react';
import mainBaner1 from '../../assets/mainBaner1.jpg';
import mainBaner2 from '../../assets/mainBaner2.jpg';
import mainBaner3 from '../../assets/mainBaner3.jpg';
import mainBaner4 from '../../assets/mainBaner4.jpg';
import mainBaner5 from '../../assets/mainBaner5.jpg';
import mainBaner6 from '../../assets/mainBaner6.jpg';
import { NavLink } from 'react-router-dom';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import { setIsScrollSlider, setIsViewPreloader, setOldModalScrollPosition, setScrollPositionBeforeView } from '../../store/homeSlice';
import { useDispatch } from 'react-redux';

function MainBanner() {
    const [windowWidth, setWindowWidth] = useState(0);
    const [isNotFirstRender, setIsNotFirstRender] = useState(false);
    const dispatch = useDispatch();
    let sliderRef = useRef(null);  
    
    useEffect(() => {
        setIsNotFirstRender(true)
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
            dispatch(setIsScrollSlider(false))
            dispatch(setOldModalScrollPosition(0))
        };
    }, []); 

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    const handleClickMore = () => {
        dispatch(setIsViewPreloader(true))
        dispatch(setScrollPositionBeforeView({}))
    }

    return (
        <div className="main-banner--wrap">
            <div className="main-banner-wrap">
                <div className="main-banner__text-wrap">
                    <div className="main-banner__text">Потрібні меблі на замовлення під проєкт чи ескіз? Сервіс індивідуального виготовлення меблів!</div>
                    <NavLink className="main-btn-1" onClick={() => handleClickMore()} to='/pid-zamovlennya'>Детальніше</NavLink>
                </div>
                <Swiper
                    ref={sliderRef}
                    loop={true}
                    spaceBetween={17}
                    navigation={true}
                    pagination={{
                        clickable: true,
                        }}
                    slidesPerView={1}
                    modules={[Autoplay, Navigation, Pagination]}
                    speed={450}
                    breakpoints={{
                        960: {
                        slidesPerView: 2,
                        spaceBetween: 17,
                        },
                        1552: {
                        slidesPerView: 3,
                        spaceBetween: 17,
                        },
                        1680: {
                        slidesPerView: 3,
                        spaceBetween: 17,
                        },
                        2100: {
                        slidesPerView: 4,
                        spaceBetween: 17,
                        },
                    }}
                    className="main-banner__swiper"
                >
                    <SwiperSlide className="main-banner__swiper-slider" key={1}><img className="main-banner__swiper-slider-img" src={mainBaner1} alt='img'/></SwiperSlide>
                    <SwiperSlide className="main-banner__swiper-slider" key={2}><img className="main-banner__swiper-slider-img" src={mainBaner2} alt='img'/></SwiperSlide>
                    <SwiperSlide className="main-banner__swiper-slider" key={3}><img className="main-banner__swiper-slider-img" src={mainBaner3} alt='img'/></SwiperSlide>
                    <SwiperSlide className="main-banner__swiper-slider" key={4}><img className="main-banner__swiper-slider-img" src={mainBaner4} alt='img'/></SwiperSlide>
                    <SwiperSlide className="main-banner__swiper-slider" key={5}><img className="main-banner__swiper-slider-img" src={mainBaner5} alt='img'/></SwiperSlide>
                    <SwiperSlide className="main-banner__swiper-slider" key={6}><img className="main-banner__swiper-slider-img" src={mainBaner6} alt='img'/></SwiperSlide>
                    {
                        windowWidth > 2100 && 
                            <>
                                <SwiperSlide className="main-banner__swiper-slider" key={7}><img className="main-banner__swiper-slider-img" src={mainBaner1} alt='img'/></SwiperSlide>
                                <SwiperSlide className="main-banner__swiper-slider" key={8}><img className="main-banner__swiper-slider-img" src={mainBaner2} alt='img'/></SwiperSlide>
                                <SwiperSlide className="main-banner__swiper-slider" key={9}><img className="main-banner__swiper-slider-img" src={mainBaner3} alt='img'/></SwiperSlide>
                                <SwiperSlide className="main-banner__swiper-slider" key={10}><img className="main-banner__swiper-slider-img" src={mainBaner4} alt='img'/></SwiperSlide>
                                <SwiperSlide className="main-banner__swiper-slider" key={11}><img className="main-banner__swiper-slider-img" src={mainBaner5} alt='img'/></SwiperSlide>
                                <SwiperSlide className="main-banner__swiper-slider" key={12}><img className="main-banner__swiper-slider-img" src={mainBaner6} alt='img'/></SwiperSlide>
                            </>
                    }
                </Swiper>
            </div>
        </div>
    );
}

export default memo(MainBanner);