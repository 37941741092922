import './ProductVariations.css';
import { useState, useEffect, memo, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Modal } from '@mui/material';
import { disabledScroll, enabledScroll, fetchGetData, fetchRequest } from '../../helpers/Utils';
import { setEditeProduct, setShowMessageObj, setVariationsEditeProduct, } from '../../store/userSlice';
import Preloader from '../Preloader/Preloader';
import closeImg from '../../assets/closeImg.svg';
import plusImg2 from '../../assets/plusImg2.svg';
import close2 from '../../assets/close2.svg';
import arrow2 from '../../assets/arrow2.svg';
import plusImg from '../../assets/plusImg.svg';
import UserProductCard from '../UserProductCard/UserProductCard';
import CreatingVariations from '../CreatingVariations/CreatingVariations';

function ProductVariations() {
    const editeProduct = useSelector(state => state.userSlice.editeProduct);
    const variationsEditeProduct = useSelector(state => state.userSlice.variationsEditeProduct);
    const [isPreloader, setIsPreloader] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isMultiCreationVariations, setIsMultiCreationVariations] = useState(true);
    const [modalScrollPosition, setModalScrollPosition] = useState(0);
    const [newVariations, setNewVariations] = useState([{}]);
    const dispatch = useDispatch();
    let token = localStorage.getItem('token')
    
    useEffect(() => {
        handleGetVariations()
    }, [])
    
    const handleGetVariations = () => {
        if (editeProduct._id?.length) {
            fetchGetData(`${process.env.REACT_APP_BASE_URL}/products/variations/${editeProduct._id}?type=main`)
                .then(res => {
                    if (res?.success && res.data) {
                        dispatch(setVariationsEditeProduct(res.data))
                        let resEdite = res.data.find(el => el._id === editeProduct._id) 
                        if (resEdite?._id?.length) {
                            dispatch(setEditeProduct(resEdite))
                        }
                    } else {
                        console.log('GET ProductVariations', res)
                    }
                })
        }
    }

    const handleUpdateVariations = () => {
        if (newVariations?.length) {
            const resColorArr = newVariations.filter(el => el.name === 'Колір');
            const resArr = newVariations.filter(el => el.name !== 'Колір' && el.name !== 'Матеріал');
            const resMaterialArr = newVariations.filter(el => el.name === 'Матеріал');
            let res = [...resArr]
            
            if (resColorArr?.length) {
                const newResColor = {
                    name: 'Колір',
                    value: [...resColorArr.map(el => {
                        return {
                            color: el.value.value,
                            color_name: el.value.name,
                            color_filter: el.value.color_filter,
                        }
                    })],
                };
                res = [...res, newResColor]
            }
            
            if (resMaterialArr?.length) {
                const newResMaterial = {
                    name: 'Матеріал',
                    value: resMaterialArr.map(el => el.value).filter(ell => ell.title?.length && ell.img?.length),
                };
                res = [...res, newResMaterial]
            }

            const data = {
                _id: editeProduct?._id,
                variations: res,
            };
            handleSendCreateVariations(data)
        } 
    }

    const handleSendCreateVariations = (data) => {
        setIsPreloader(true)
        fetchRequest('POST', `${process.env.REACT_APP_BASE_URL}/products_variations/test?isMultiCreationVariations=${isMultiCreationVariations}&token=${token}`, data, () => dispatch(setShowMessageObj({open: true, status: 'error', message: 'Сталася помилка'})))
            .then(res => {
                    if (res?.success) {
                        handleGetVariations()
                        dispatch(setShowMessageObj({open: true, status: 'success', message: 'Дані оновлено'}))
                    } else {
                        console.log('POST ProductVariations:', res)
                    }
                    setIsPreloader(false)
                })
    }

    const handleOpenModal = (e) => {
        e.stopPropagation()
        setIsOpenModal(true)
        disabledScroll(setModalScrollPosition)
    }
    
    const handleCloseModal = () => {
        setIsOpenModal(false)
        setNewVariations([{}])
        enabledScroll(modalScrollPosition)
    }

    const handleClickUpdate = () => {
        handleUpdateVariations()
        handleCloseModal()
    }

    return (
        <Accordion className='creating-product__produc-accordion product-variations__produc-accordion'>
            <AccordionSummary
                expandIcon={<p><img className='creating-product__produc-accordion-arrow' src={arrow2} alt='img'/></p>}
                aria-controls="creating-product__produc"
                id="creating-product__produc"
            >
                <div className='creating-product__produc-accordion-heder-wrap'>
                    <div className='creating-product__produc-accordion-heder'>
                        <div className='creating-product__produc-accordion-heder-title'>Варіанти товару ({editeProduct.count_products})</div>
                        <div className='creating-product__produc-accordion-heder-btn' onClick={(e) => handleOpenModal(e)}><img className='creating-product__produc-accordion-heder-btn-img' src={plusImg} alt='img'/> <span className='creating-product__produc-accordion-heder-btn-text'>Додати варіант</span></div>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className="product-variations">
                    {
                        isPreloader ? <Preloader /> :
                            <>
                                <Modal
                                    open={isOpenModal}
                                    onClose={handleCloseModal}
                                    className="product-variations__modal-wrap"
                                >
                                    <div className="product-variations--wrap">
                                        <img className="product-variations__btn-close product-variations__btn-close--des" onClick={handleCloseModal} src={closeImg} alt='img'/>
                                        <div className="product-variations__select-wrap">
                                            <div className="product-variations__select-title">
                                                <span>Додати варіант товару</span>
                                                <img className="product-variations__btn-close product-variations__btn-close--mob" onClick={handleCloseModal} src={close2} alt='img'/>
                                            </div>
                                            <div className="product-variations__select-sub-title">Виберіть характеристику для створення варіанту товару</div>
                                            <label className="catalog-view__products-filter-label product-variations__label-checkbox">
                                                {/* <input className="catalog-view__products-filter-checkbox" type="checkbox" value={isMultiCreationVariations} onChange={() => setIsMultiCreationVariations(!isMultiCreationVariations)} />
                                                <div className={`catalog-view__products-filter-checkbox-custom ${isMultiCreationVariations ? 'catalog-view__products-filter-checkbox-custom-active' : ''}`}></div>
                                                <div>Мультистворення варіацій</div> */}
                                            </label>
                                            <div className="product-variations__select-variations-wrap">
                                                {
                                                    !!newVariations?.length ? newVariations.map((el, i) => (
                                                        <div key={el + i}>
                                                            <CreatingVariations item={el} indexCharacteristic={i} newVariations={newVariations} setNewVariations={setNewVariations} />
                                                        </div>
                                                    ))
                                                    :
                                                    <div className="creating-variations__select-variations-error">Натисніть "Додати варіант" для створення варіації</div>
                                                }
                                            </div>
                                            <br/>
                                            <br/>
                                            <button className="product-variations__select-btn-wrap" onClick={() => setNewVariations([...newVariations, {}])}>
                                                <img className="product-variations__select-btn-img" src={plusImg2} alt='img'/>
                                                <span>Додати варіант</span>
                                            </button>
                                            <br/>
                                            <br/>
                                            <div className='product-variations__modal-btn-wrap'>
                                                <button className='product-variations__modal-btn-close' onClick={handleCloseModal}>Скасувати</button>
                                                <button className='product-variations__modal-btn-add' onClick={handleClickUpdate}>Зберегти</button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                                {
                                    !!variationsEditeProduct?.length && 
                                        <>
                                            <div className="product-variations__products">
                                                <div className="product-variations__products-header">
                                                    <div className="product-variations__product-text">Товари</div>
                                                    <div className="product-variations__product-text-variation">Варіація</div>
                                                    <div className="product-variations__product-text-price">Ціна</div>
                                                    <div className="product-variations__product-text-code">Код товару</div>
                                                </div>
                                                {
                                                    variationsEditeProduct.map((el, i) => (
                                                        <UserProductCard product={el} isHideVariations={true} isUserView={true} isCrieteProduct={true} key={el._id}/>
                                                    ))
                                                }
                                            </div>
                                        </>
                                }
                            </>
                    }
                </div>
            </AccordionDetails>
        </Accordion>
        
    );
}

export default memo(ProductVariations);